import http from "../http-common";

class SoftwareAssetDataService {
    getAssets(token, askerId, offsetValue, warrantyStatus, expirationStartDate, expirationEndDate, licenseStatus, licenseExpirationStartDate, licenseExpirationEndDate, hasDevicesRemaining, selectedTenantId, selectedAssignedToUser, selectedAssignedAssetId, licenseNumber, searchText, sortBy, sortOrder) {
        return http.get(`/softwareAssets?askerId=${askerId}&offsetValue=${offsetValue}&warrantyStatus=${warrantyStatus}&expirationStartDate=${expirationStartDate}&expirationEndDate=${expirationEndDate}&licenseStatus=${licenseStatus}&licenseExpirationStartDate=${licenseExpirationStartDate}&licenseExpirationEndDate=${licenseExpirationEndDate}&hasDevicesRemaining=${hasDevicesRemaining}&selectedAssignedToUser=${selectedAssignedToUser}&selectedAssignedAssetId=${selectedAssignedAssetId}&licenseNumber=${licenseNumber}&searchText=${searchText}&sortBy=${sortBy}&sortOrder=${sortOrder}&selectedTenantId=${selectedTenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSpecificAsset(token, id, tenantId, askerId) {
        return http.get(`/softwareAssets/${id}?tenantId=${tenantId}&askerId=${askerId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getHistory(id, timeZoneAdjustmentFactor, token) {
        return http.get(`/softwareAssets/getHistory/${id}?timeZoneAdjustmentFactor=${timeZoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSpecificHistoryEventDetails(historyEventId, timeZoneAdjustmentFactor, token) {
        return http.get(`/softwareAssets/getSpecificHistoryEventDetails/${historyEventId}?timeZoneAdjustmentFactor=${timeZoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getPeopleAssignments(id, token) {
        return http.get(`/softwareAssets/getPeopleAssignments/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getHardwareAssetAssignments(id, token) {
        return http.get(`/softwareAssets/getHardwareAssetAssignments/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getPeopleAssignmentSearchResults(searchText, tenantId, token) {
        return http.get(`/softwareAssets/searchResults/getPeopleAssignmentSearchResults?searchText=${searchText}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getHardwareAssignmentSearchResults(searchText, tenantId, token) {
        return http.get(`/softwareAssets/searchResults/getHardwareAssignmentSearchResults?searchText=${searchText}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new software asset
    createNewSoftwareAsset(token, data) {
        return http.post('/softwareAssets', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // edit an existing software asset
    editSoftwareAsset(token, data, assetId) {
        return http.post(`/softwareAssets/editSoftwareAsset/${assetId}`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new history event
    createHistoryEvent(token, data) {
        return http.post('/softwareAssets/createHistoryEvent', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // assign software to a person
    assignToPerson(token, data) {
        return http.post('/softwareAssets/assignToPerson', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // assign software to a hardware asset
    assignToHardware(token, data) {
        return http.post('/softwareAssets/assignToHardware', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    deleteSoftwarePersonAssignment(softwareId, personId, assetName, creatorId, token) {
        return http.delete(`/softwareAssets/deleteSoftwarePersonAssignment?softwareId=${softwareId}&personId=${personId}&assetName=${assetName}&creatorId=${creatorId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    deleteSoftwareHardwareAssignment(softwareId, hardwareId, assetName, creatorId, token) {
        return http.delete(`/softwareAssets/deleteSoftwareHardwareAssignment?softwareId=${softwareId}&hardwareId=${hardwareId}&assetName=${assetName}&creatorId=${creatorId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new contract - if possible
    createContract(token, data) {
        return http.post('/softwareAssets/contracts/add', data, {headers: {Authorization: 'Bearer ' + token}, 'Content-Type': 'multipart/form-data'});
    }

    getCustomers(token, managingTenantId) {
        return http.get(`/softwareAssets/contracts/getCustomers?managingTenantId=${managingTenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get all drop down values except for customers for the drop downs on the add / edit contracts forms - gives values specific to caller's tenant where needed
    getDropdownValuesForContractForm(token, tenantId) {
        return http.get(`/softwareAssets/contracts/getDropdownData?tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getAllContracts(token, tenantId, companyId, contractType, contractStatus, vendorId, expiresBy, searchText, sortBy, sortOrder, offset) {
        return http.get(`/softwareAssets/contracts/getAll?tenantId=${tenantId}&companyId=${companyId}&contractType=${contractType}&contractStatus=${contractStatus}&vendorId=${vendorId}&expiresBy=${expiresBy}&searchText=${searchText}&sortBy=${sortBy}&sortOrder=${sortOrder}&offset=${offset}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSpecificContract(token, askerId, contractId) {
        return http.get(`/softwareAssets/contracts/${contractId}/getDetails?askerId=${askerId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    deleteContract(token, contractId, askerId, tenantId) {
        return http.delete(`/softwareAssets/contracts/${contractId}/deleteContract?askerId=${askerId}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    editContract(token, data, contractId) {
        return http.post(`/softwareAssets/contracts/${contractId}/editContract`, data, {headers: {Authorization: 'Bearer ' + token}, 'Content-Type': 'multipart/form-data'});
    }

    getSoftwareAssetsUpdatedVersion(token, tenantId, companyId, status, managerId, type, publisherId, searchText, sortBy, sortOrder, offset) {
        return http.get(`/softwareAssets/getAllSoftwareAssets/UpdatedVersion?tenantId=${tenantId}&companyId=${companyId}&status=${status}&managerId=${managerId}&type=${type}&publisherId=${publisherId}&searchText=${searchText}&sortBy=${sortBy}&sortOrder=${sortOrder}&offset=${offset}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getDropdownValuesForSoftwareAssetFormAndFilters(token, tenantId) {
        return http.get(`/softwareAssets/getSoftwareAsset/dropDownData?tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    deleteSoftwareAssetUpdatedVersion(token, softwareId, askerId, tenantId) {
        return http.delete(`/softwareAssets/delete/${softwareId}/updatedVersion?askerId=${askerId}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    createCustomer(token, data) {
        return http.post(`/softwareAssets/contracts/customers/createNew`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    getDangerContracts(token, askerId, selectedTenantId) {
        return http.get(`/softwareAssets/contracts/getDangerContracts?askerId=${askerId}&selectedTenantId=${selectedTenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getWarningContracts(token, askerId, selectedTenantId) {
        return http.get(`/softwareAssets/contracts/getWarningContracts?askerId=${askerId}&selectedTenantId=${selectedTenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    deleteContractAttachment(token, id) {
        return http.delete(`/softwareAssets/deleteContractAttachment?id=${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    renewContract(token, data) {
        return http.post(`/softwareAssets/contracts/renewContract`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    reverseContractRenewal(token, id) {
        return http.delete(`/softwareAssets/contracts/reverseContractRenewal?id=${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    assignToNotifications(token, data) {
        return http.post(`/softwareAssets/contracts/assignToNotifications`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    removeFromNotifications(token, contractId, userId) {
        return http.delete(`/softwareAssets/contracts/removeFromNotifications?contractId=${contractId}&userId=${userId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

}

export default new SoftwareAssetDataService();