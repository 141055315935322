import React, { useState } from "react";
// import Dropdown from "react-bootstrap/Dropdown";
// import ButtonGroup from "react-bootstrap/ButtonGroup";
import FormControl from "react-bootstrap/FormControl";
import "bootstrap/dist/css/bootstrap.min.css";

import { connect /*, useDispatch*/ } from 'react-redux';
import { store } from '../app/store';
import {
  Select,
} from '@mantine/core';

import { UPDATE_ACTIVE_TYPE, ALERT_TYPE_CHANGE_FROM_DROPDOWN } from "../features/actions/My-Assigned-Tickets-Actions";

//import { ADD_DEPARTMENT, SHOW_ADD_DEPARTMENT_FORM, CHANGE_ACTIVE_DEPARTMENT, RESET_DEPARTMENT, ALERT_DEPARTMENT_CHANGE_FROM_DROPDOWN } from '../features/actions/Dynamic-Table-Actions';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#chosenType"
    ref={ref}
    id="selectTypeDropdownButton"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto gothamNarrowLightFont"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);


// function resetDepartment() {
//   return {
//     type: 'RESET_DEPARTMENT'
//   }
// }

function updateActiveType(type_name) {
  return {
    type: 'UPDATE_ACTIVE_TYPE',
    payload: type_name
  }
}

function alertAboutTypeChange() {
  return {
    type: 'ALERT_TYPE_CHANGE_FROM_DROPDOWN'
  }
}

function handleTypeChange(type_name) {
  let state = store.getState();

  // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
  if (type_name !== state.myAssignedTickets.type_name) {

    if(type_name === "Unprocessed") {
      type_name = "Unclarified";
    }

    else if(type_name === "Tech Support") {
      type_name = "Hardware";
    }

    //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
    store.dispatch(updateActiveType(type_name));
    store.dispatch(alertAboutTypeChange()); // tell My-Assigned-Tickets.js to run it's componentDidUpdate fxn and get new assigned ticket data
  }


}

function TypeSelector() {
  let state = store.getState();
  //let dispatch = useDispatch();
  console.log("Type name selected = ", state.myAssignedTickets.type_name);

  let defaultValue;

  if(state.myAssignedTickets.type_name === "Hardware") {
    defaultValue = "Tech Support";
  }

  else if(state.myAssignedTickets.type_name === "Unclarified") {
    defaultValue = "Unprocessed";
  }

  else {
    defaultValue = "All";
  }

  return (
    <>
      <Select
        label="Type"
        placeholder="Pick value"
        data={["All", "Tech Support", "Unprocessed"]}
        //defaultValue={defaultValue}
        value={
          state.myAssignedTickets.type_name === "Hardware" ? "Tech Support" : state.myAssignedTickets.type_name === "Unclarified" ? "Unprocessed" : "All"
        }
        display="inline-block"
        onChange={(s) => handleTypeChange(s)}
        w="200px"
        allowDeselect={false}
      />
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    myAssignedTickets: state.myAssignedTickets
  };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_TYPE, ALERT_TYPE_CHANGE_FROM_DROPDOWN })(TypeSelector);