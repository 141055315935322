const initialState = {
    warrantyStatus: "All",   // changed when user picks and confirms a different radio button in filter modal
    expirationStartDate: "",    // will contain date value as yyyy-mm-dd saying what day is selected by user for the start date of filter
    expirationEndDate: "",      // same as above, but for end date
    licenseStatus: "All",   // changed when user picks and confirms a different radio button in filter modal
    licenseExpirationStartDate: "", // will contain date value as yyyy-mm-dd saying what day is selected by user for the start date of filter
    licenseExpirationEndDate: "",   // same as above, but for end date
    hasDevicesRemaining: "All", // changed when user picks and confirms a different radio button in filter modal
    selectedTenantId: 0,    // id of the tenant user has selected 0 = All
    selectedAssignedToUser: 0,      // id of the selected assigned to user in drop down menu of filter modal
    selectedAssignedAssetId: 0,     // id of the selected assigned asset (based on assigned user id selected)
    licenseNumber: "",      // license number entered into field within filter modal
    searchText: "",          // text entered by user in the search by asset name bar
    sortBy: "Name",             // the column name that is currently the active sort criteria for the assets displayed
    sortOrder: "ASC",          // the order that the assets are displayed in ASC or DESC


    // The following pertain to the filters for SOFTWARE CONTRACTS
    selectedContractCustomerId: 0,
    selectedContractCustomerName: "All",
    selectedContractType: "All",
    selectedContractStatus: "All",
    selectedContractVendorId: 0,
    selectedContractVendorName: "All",
    selectedContractEndDate: null,
    contractSearchText: "",
    selectedSoftwareContractDataView: true,  // true = view contracts, false = view software assets
    sortByContract: "contract_name",
    sortOrderContract: "ASC",

    // The following pertain to the filters on the new SOFTWARE asset page (shared page with contracts)
    selectedSoftwareStatus: "All",
    selectedSoftwareManagedById: 0,
    selectedSoftwareManagedByName: "All",
    selectedSoftwareType: "All",
    selectedSoftwarePublisherId: 0,
    selectedSoftwarePublisherName: "All",
    searchTextSoftware: "",
    softwareSortBy: "software_name",
    softwareSortOrder: "ASC"
};

function softwareAssetFilterReducer(state = initialState, action) {
    switch(action.type) {

        case "UPDATE_SELECTED_WARRANTY_STATUS_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    warrantyStatus: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_EXPIRATION_START_DATE_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    expirationStartDate: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_EXPIRATION_END_DATE_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    expirationEndDate: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_LICENSE_STATUS_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    licenseStatus: action.payload
                }
            }
            break;

        case "UPDATE_SELECTED_LICENSE_EXPIRATION_START_DATE_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    licenseExpirationStartDate: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_LICENSE_EXPIRATION_END_DATE_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    licenseExpirationEndDate: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_HAS_DEVICES_REMAINING_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    hasDevicesRemaining: action.payload
                }
            }
            break;

        case "UPDATE_SELECTED_TENANT_ID_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedTenantId: action.payload
                }
            }
            break;

        case "UPDATE_SELECTED_ASSIGNED_TO_USER_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedAssignedToUser: action.payload
                }
            }
            break;

        case "UPDATE_SELECTED_ASSIGNED_TO_ASSET_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedAssignedAssetId: action.payload
                }
            }
            break;

        case "UPDATE_SELECTED_LICENSE_NUMBER_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    licenseNumber: action.payload
                }
            }
            break;
        
        case "UPDATE_SEARCH_TEXT_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    searchText: action.payload
                };
            }
            break;
        
        case "UPDATE_SORT_BY_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    sortBy: action.payload
                };
            }
            break;

        case "UPDATE_SORT_ORDER_SOFTWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    sortOrder: action.payload
                };
            }
            break;      
            
        case "UPDATE_SELECTED_CONTRACT_CUSTOMER":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedContractCustomerId: action.payload.customer_id,
                    selectedContractCustomerName: action.payload.customer_name
                };
            }
            break;
            
        case "UPDATE_SELECTED_CONTRACT_TYPE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedContractType: action.payload
                };
            }
            break;


        case "UPDATE_SELECTED_CONTRACT_STATUS":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedContractStatus: action.payload
                };
            }
            break;
        
        case "UPDATE_SELECTED_CONTRACT_VENDOR":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedContractVendorId: action.payload.vendor_id,
                    selectedContractVendorName: action.payload.vendor_name
                };
            }
            break;
            
        case "UPDATE_SELECTED_CONTRACT_END_DATE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedContractEndDate: action.payload
                };
            }
            break;

        case "UPDATE_CONTRACT_SEARCH_TEXT":
            if(action.payload !== null) {
                return {
                    ...state,
                    contractSearchText: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_SOFTWARE_CONTRACT_DATA_VIEW":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedSoftwareContractDataView: action.payload
                };
            }
            break;

        case "UPDATE_CONTRACT_SORT_BY_AND_ORDER":
            if(action.payload !== null) {
                return {
                    ...state,
                    sortByContract: action.payload.sortBy,
                    sortOrderContract: action.payload.sortOrder
                };
            }
            break;

        case "UPDATE_SOFTWARE_ASSET_STATUS_FILTER":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedSoftwareStatus: action.payload
                };
            }
            break;

        case "UPDATE_SOFTWARE_ASSET_MANAGED_BY_FILTER":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedSoftwareManagedById: action.payload.manager_id,
                    selectedSoftwareManagedByName: action.payload.manager_name
                };
            }
            break;

        case "UPDATE_SOFTWARE_ASSET_TYPE_FILTER":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedSoftwareType: action.payload
                };
            }
            break;

        case "UPDATE_SOFTWARE_PUBLISHER_FILTER":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedSoftwarePublisherId: action.payload.publisher_id,
                    selectedSoftwarePublisherName: action.payload.publisher_name
                };
            }
            break;

        case "UPDATE_SOFTWARE_ASSET_SEARCH_TEXT":
            if(action.payload !== null) {
                return {
                    ...state,
                    searchTextSoftware: action.payload
                };
            }
            break;

        case "UPDATE_SOFTWARE_ASSET_SORT_BY_AND_SORT_ORDER":
            if(action.payload !== null) {
                return {
                    ...state,
                    softwareSortBy: action.payload.softwareSortBy,
                    softwareSortOrder: action.payload.softwareSortOrder
                };
            }
            break;

        case "RESET_CONTRACT_FILTERS":
            return {
                ...state,
                selectedContractType: "All",
                selectedContractStatus: "All",
                selectedContractVendorId: 0,
                selectedContractVendorName: "All",
                selectedContractEndDate: null,
                contractSearchText: "",
                sortByContract: "contract_name",
                sortOrderContract: "ASC"
            };

        case "RESET_SOFTWARE_ASSET_FILTERS":
            return {
                ...state,
                selectedSoftwareStatus: "All",
                selectedSoftwareManagedById: 0,
                selectedSoftwareManagedByName: "All",
                selectedSoftwareType: "All",
                selectedSoftwarePublisherId: 0,
                selectedSoftwarePublisherName: "All",
                searchTextSoftware: "",
                softwareSortBy: "software_name",
                softwareSortOrder: "ASC"
            };
        

        default:
            return state;
    }
}

export default softwareAssetFilterReducer;