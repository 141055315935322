const initialState = {
    activeLink: "userHome"
};

function activeNavLinkReducer(state = initialState, action) {
    switch(action.type) {
        case "UPDATE_ACTIVE_LINK":
            //console.log("Login event detected");
            //console.log(action.payload);
            if(action.payload) {
                return {
                    ...state,
                    activeLink: action.payload 
                };
            }
            break;


        default:
            return state;


    }
}

export default activeNavLinkReducer;