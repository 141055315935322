export const LOGIN = content => ({
    type: "LOGIN",
    payload: content
});

export const LOGOUT = content => ({
    type: "LOGOUT"
});

export const ACCESSTOKEN = content => ({
    type: "ACCESSTOKEN",
    payload: content
});

export const USER_DETAILS = content => ({
    type: "USER_DETAILS",
    payload: content
});

export const SET_REPORT_TENANT_VIEWING = content => ({
    type: "SET_REPORT_TENANT_VIEWING",
    payload: content
});

export const ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN = content => ({
    type: "ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN"
});

export const REPORT_TENANT_CHANGE_RESOLVED = content => ({
    type: "REPORT_TENANT_CHANGE_RESOLVED"
});

export const ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS = content => ({
    type: "ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS"
});

export const REPORT_TENANT_CHANGE_RESOLVED_WARNING_TICKETS = content => ({
    type: "REPORT_TENANT_CHANGE_RESOLVED_WARNING_TICKETS"
});

export const TOTAL_TICKET_COUNT = content => ({
    type: "TOTAL_TICKET_COUNT",
    payload: content
});

export const ENABLE_EMAILS = content => ({
    type: "ENABLE_EMAILS"
});

export const DISABLE_EMAILS = content => ({
    type: "DISABLE_EMAILS"
});

export const SOFTWARE_ENABLE_EMAILS = content => ({
    type: "SOFTWARE_ENABLE_EMAILS"
});

export const SOFTWARE_DISABLE_EMAILS = content => ({
    type: "SOFTWARE_DISABLE_EMAILS"
});

export const OPEN_TICKET_COUNT = content => ({
    type: "OPEN_TICKET_COUNT",
    payload: content
});

export const SET_TIMEZONE_ADJUSTMENT_FACTOR = content => ({
    type: "SET_TIMEZONE_ADJUSTMENT_FACTOR",
    payload: content
});

export const SET_MONITORING_TENANT_VIEWING = content => ({
    type: "SET_MONITORING_TENANT_VIEWING",
    payload: content
});

export const SET_DASHBOARD_TENANT_VIEWING = content => ({
    type: "SET_DASHBOARD_TENANT_VIEWING",
    payload: content
});