import React, { useEffect, useState } from 'react';
import {
    Paper,
    Group,
    Progress,
    Box,
    SimpleGrid,
    RingProgress,
    Center,
    Text
} from '@mantine/core';
import MonitoringDataService from '../../../../services/monitoring-activities';

import { Motion, spring } from "react-motion";

function TaniumLowDiskSpace(props) {
    const [lowDiskSpace, setLowDiskSpace] = useState(0);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        if (props.loggedStatus.loggedIn) {
            props.onAccessTokenRequest(props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token !== undefined && token !== null && token !== "") {
                    MonitoringDataService.getLowDiskSpaceCount(props.selectedTenantId, props.selectedTenant, props.loggedStatus.id, token).then(response => {
                        setLowDiskSpace(response.data);
                    })
                        .catch(e => {
                            console.log(e);
                        });
                    MonitoringDataService.getOSCounts(props.selectedTenantId, props.selectedTenant, props.loggedStatus.id, token).then(response => {
                        var total = 0;
                        response.data.forEach((r) => { total += r.count; });
                        setTotal(total);
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }
            });
        }
    }, []);

    return (
        <Paper withBorder radius="md" p="5px" pt="15px" h="100%" w="100%" style={{ verticalAlign: "top" }}>
            <Group>
                <Motion defaultStyle={{ x: 0 }} style={{ x: spring((lowDiskSpace / (total === 0 ? 1 : total)) * 100) }}>
                    {({ x }) => <RingProgress
                        size={80}
                        roundCaps
                        thickness={8}
                        sections={[{ value: x, color: ((lowDiskSpace / total) > 0.8 ? "red" : "blue") }]}
                        label={
                            <Center>
                                {Math.round((lowDiskSpace / total) * 100)}%
                            </Center>
                        }
                    />
                    }
                </Motion>

                <div>
                    <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                        {"endpoints with low disk space"}
                    </Text>
                    <Text fw={700} size="xl">
                        {lowDiskSpace}
                    </Text>
                </div>
            </Group>
        </Paper>
    );
}

export default TaniumLowDiskSpace;