import http from "../http-common";

class HardwareAssetDataService {

    getAssets(token, askerId, offsetValue, selectedType, warrantyStatus, expirationStartDate, expirationEndDate, selectedAssignedToUser, serialNumber, assetTag, searchText, sortBy, sortOrder, selectedTenantId) {
        return http.get(`/hardwareAssets?askerId=${askerId}&offsetValue=${offsetValue}&selectedType=${selectedType}&warrantyStatus=${warrantyStatus}&expirationStartDate=${expirationStartDate}&expirationEndDate=${expirationEndDate}&selectedAssignedToUser=${selectedAssignedToUser}&serialNumber=${serialNumber}&assetTag=${assetTag}&searchText=${searchText}&sortBy=${sortBy}&sortOrder=${sortOrder}&selectedTenantId=${selectedTenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSpecificAsset(token, assetId) {
        return http.get(`/hardwareAssets/getSpecific/${assetId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getHistory(id, timeZoneAdjustmentFactor, token) {
        return http.get(`/hardwareAssets/getHistory/${id}?timeZoneAdjustmentFactor=${timeZoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSpecificHistoryEventDetails(historyEventId, timeZoneAdjustmentFactor, token) {
        return http.get(`/hardwareAssets/getSpecificHistoryEventDetails/${historyEventId}?timeZoneAdjustmentFactor=${timeZoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of type names and id numbers for the filter drop down menu
    getTypes(token) {
        return http.get("/hardwareAssets/types", {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of users tech is authorized to see for the filter drop down menu
    getUsers(token, asker_id, tenant_id) {
        return http.get(`/hardwareAssets/users?userIdAsking=${asker_id}&tenantId=${tenant_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of users for the add hardware asset assign user drop down (only going to see one tenant at a time unlike view assets version)
    getUsersAddAssetVersion(token, asker_id, tenant_id) {
        return http.get(`/hardwareAssets/usersAddAssetVersion?userIdAsking=${asker_id}&tenantId=${tenant_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of a user's assigned hardware assets for the SOFTWARE assets filter drop down menu
    getUsersAssignedHardwareAssets(token, assignedPersonId, tenantId) {
        return http.get(`/hardwareAssets/usersAssignedHardwareAssets?assignedPersonId=${assignedPersonId}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of tenant names the tech is authorized to see for the filter drop down menu
    getTenants(token, asker_id) {
        return http.get(`/hardwareAssets/tenants?userIdAsking=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of categories for add hardware asset drop down menu
    getCategories(token) {
        return http.get("/hardwareAssets/categories", {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of vendors for add hardware asset drop down menu
    getVendors(token, tenantId) {
        return http.get(`/hardwareAssets/vendors?tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of manufacturers for add hardware asset drop down menu
    getManufacturers(token) {
        return http.get("/hardwareAssets/manufacturers", {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of models based on the type the user has selected in asset type drop down menu
    getModels(token, typeId) {
        return http.get(`/hardwareAssets/models?typeId=${typeId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of sites based on the company the user has selected in company drop down menu
    getSites(token, tenantId) {
        return http.get(`/hardwareAssets/sites?tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of locations in building based on site selected
    getLocations(token, siteId) {
        return http.get(`/hardwareAssets/locations?siteId=${siteId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of assignable assets based on type, category and company selected in add hardware asset form
    getAssignableAssets(token, tenantId, typeId, categoryId) {
        return http.get(`/hardwareAssets/assignableAssets?tenantId=${tenantId}&typeId=${typeId}&categoryId=${categoryId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate hardware asset name before submitting the complete form to backend
    checkForDuplicateAssetName(token, assetName) {
        return http.get(`/hardwareAssets/checkForDuplicateAssetName?assetName=${assetName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate hardware asset name before submitting edit form
    checkForDuplicateAssetNameEditVersion(token, assetName, assetId) {
        return http.get(`/hardwareAssets/checkForDuplicateAssetNameEditVersion?assetName=${assetName}&assetId=${assetId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate asset category name before submitting the form to backend
    checkForDuplicateCategoryName(token, categoryName) {
        return http.get(`/hardwareAssets/checkForDuplicateCategoryName?categoryName=${categoryName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate asset type name before submitting the form to backend
    checkForDuplicateTypeName(token, typeName) {
        return http.get(`/hardwareAssets/checkForDuplicateTypeName?typeName=${typeName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate model name before submitting the form to backend
    checkForDuplicateModelName(token, modelName) {
        return http.get(`/hardwareAssets/checkForDuplicateModelName?modelName=${modelName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate manufacturer before submitting the form to backend
    checkForDuplicateManufacturer(token, manufacturerName, manufacturerType, tenantId) {
        return http.get(`/hardwareAssets/checkForDuplicateManufacturer?manufacturerName=${manufacturerName}&manufacturerType=${manufacturerType}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate site before submitting the form to backend
    checkForDuplicateSiteName(token, siteName) {
        return http.get(`/hardwareAssets/checkForDuplicateSiteName?siteName=${siteName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate location before submitting the form to backend
    checkForDuplicateLocation(token, floorNumber, aisleNumber, deskNumber, siteId) {
        return http.get(`/hardwareAssets/checkForDuplicateLocationInfo?floorNumber=${floorNumber}&aisleNumber=${aisleNumber}&deskNumber=${deskNumber}&siteId=${siteId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // check for duplicate vendor before submitting the form to backend
    checkForDuplicateVendor(token, vendorName, tenantId) {
        return http.get(`/hardwareAssets/checkForDuplicateVendor?vendorName=${vendorName}&tenantId=${tenantId}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new hardware asset
    createNewHardwareAsset(token, data) {
        return http.post('/hardwareAssets', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // edit existing hardware asset
    editHardwareAsset(token, data, assetId) {
        return http.post(`/hardwareAssets/editHardwareAsset/${assetId}`, data, {headers: {Authorization: 'Bearer ' + token}});
    }


    // create a new asset category
    createCategory(token, data) {
        return http.post('/hardwareAssets/createCategory', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new asset type
    createType(token, data) {
        return http.post('/hardwareAssets/createType', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new model
    createModel(token, data) {
        return http.post('/hardwareAssets/createModel', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new manufacturer
    createManufacturer(token, data) {
        return http.post('/hardwareAssets/createManufacturer', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new site
    createSite(token, data) {
        return http.post('/hardwareAssets/createSite', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new location
    createLocation(token, data) {
        return http.post('/hardwareAssets/createLocation', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new vendor
    createVendor(token, data) {
        return http.post('/hardwareAssets/createVendor', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create a new history event
    createHistoryEvent(token, data) {
        return http.post('/hardwareAssets/createHistoryEvent', data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // getAll(token) {
    //     return http.get("/accounts", {headers: {Authorization: 'Bearer ' + token}});
    // }

    // create(data, token) {
    //     return http.post("/accounts", data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // get(id, token) {
    //     return http.get(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // update(id, data, token) {
    //     return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // delete(id, token) {
    //     return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // findByName(name) {
    //     return http.get(`/accounts?name=${name}`);
    // }

}


export default new HardwareAssetDataService();