import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';

import { SET_DASHBOARD_TENANT_VIEWING } from '../../features/actions/Is-Logged-Actions';
import HardwareAssetDataService from '../../services/hardware-asset-activities';

import { Button, Container, Flex, Paper } from '@mantine/core';


import DashboardGrid from "./DashboardGrid";

import Navbar from '../../components/Navbar';
import TicketsByMonth from "./Widgets/Tickets/TicketsByMonth";

const defaultWidgets = [
    {
        pos: { x: -480, y: 0 },
        size: { x: 4, y: 3 },
        dataType: "ticketsByMonth",
        graphType: "line"
    },
    {
        pos: { x: -480, y: 0 },
        size: { x: 4, y: 3 },
        dataType: "ticketsByDay",
        graphType: "line"
    },
    {
        pos: { x: 160, y: 0 },
        size: { x: 4, y: 3 },
        dataType: "ticketsByHour",
        graphType: "line"
    },
    {
        pos: { x: 160, y: 0 },
        size: { x: 4, y: 3 },
        dataType: "SLAPerformance",
        graphType: "line"
    }
];


function AdminDashboard(props) {
    const [tenants, setTenants] = useState([]);
    const [selectedTenant, setSelectedTenant] = useState("");
    const [selectedTenantId, setSelectedTenantId] = useState(0);
    const [cursor, setCursor] = useState(0);
    const [selectedPageButton, setSelectedPageButton] = useState(1);
    const [resultsPerPage, setResultsPerPage] = useState(5);

    function handleDashboardTenantChange(name_of_tenant) {

        // if this is a new selection, update the global state
        if (name_of_tenant !== selectedTenant) {
            let newTenantId = tenants.find(o => o.name_of_tenant === name_of_tenant).id;
            props.SET_DASHBOARD_TENANT_VIEWING({ dashboard_tenant_id_selected: newTenantId, dashboard_tenant_name_selected: name_of_tenant });

            setSelectedTenant(name_of_tenant);
            setSelectedTenantId(newTenantId);
            setCursor(0);
            setSelectedPageButton(1);
        }
    }

    useEffect(() => {
        if (props.loggedStatus.loggedIn) {
            props.onAccessTokenRequest(props.loggedStatus.username).then((token) => {

                HardwareAssetDataService.getTenants(token, props.loggedStatus.id).then(response => {
                    console.log(response);
                    setTenants(response.data.results);
                    if (props.loggedStatus.dashboard_tenant_name_selected === "") {

                        if (response.data.results && response.data.results.length > 0) {
                            props.SET_DASHBOARD_TENANT_VIEWING({ dasboard_tenant_id_selected: response.data.results[0].id, dashboard_tenant_name_selected: response.data.results[0].name_of_tenant });
                            setSelectedTenant(response.data.results[0].name_of_tenant);
                            setSelectedTenantId(response.data.results[0].id);
                            setCursor((selectedPageButton - 1) * resultsPerPage);
                        }
                    }

                    else {
                        // we've landed on this page before, so set the local state to the global one

                        setSelectedTenant(props.loggedStatus.dashboard_tenant_name_selected);
                        setSelectedTenantId(props.loggedStatus.dashboard_tenant_id_selected);
                        setCursor((selectedPageButton - 1) * resultsPerPage);
                    }
                })
                    .catch(e => {
                        console.log(e);
                    });

            });
        }
    }, []);

    return <>
        <Navbar pageTitle={"User Home"} />
        <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
        <Container fluid>
            <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                <Flex
                    justify="flex-start"
                    align="flex-start"
                    direction="row"
                    gap="md"
                >
                    {tenants && tenants.map((tenant) => (
                        <Button
                            h="36px"
                            variant={selectedTenant === tenant.name_of_tenant ? "filled" : "default"}
                            onClick={() => handleDashboardTenantChange(tenant.name_of_tenant)}
                        >
                            {tenant.name_of_tenant}
                        </Button>))}
                </Flex>
            </Paper>
            <DashboardGrid {...props}
                selectedTenant={selectedTenant}
                selectedTenantId={selectedTenantId}
                draggingEnabled={true}
                dashboardName="userHomeDashboard"
                defaultWidgets={defaultWidgets} />
        </Container>
    </>;
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { SET_DASHBOARD_TENANT_VIEWING })(AdminDashboard);