import http from "../http-common";

class DashboardService {

    getUnresolvedTicketCount(token, user_id_asking) {
        return http.get(`/dashboard?userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getUnresolvedTicketsByPriority(token, user_id_asking, tenant) {
        return http.get(`/dashboard?userIdAsking=${user_id_asking}&tenant=${tenant}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getWidgets(token, user_id_asking, dashboardName) {
        return http.get(`/dashboard/getwidgets?associatedUserId=${user_id_asking}&dashboardName=${dashboardName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    setWidgets(token, user_id_asking, widgetsData, dashboardName) {
        return http.post(`/dashboard/setwidgets?associatedUserId=${user_id_asking}&dashboardName=${dashboardName}`, widgetsData, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTicketsIndividualMonth(token, user_id_asking, tenant_id, year, month, timezone_adjustment_factor) {
        return http.get(`/dashboard/ticketsByMonthWidget?userIdAsking=${user_id_asking}&tenantId=${tenant_id}&year=${year}&month=${month}&timezoneAdjustmentFactor=${timezone_adjustment_factor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTicketsByDayOfWeek(token, user_id_asking, tenant_id, month, year, timezone_adjustment_factor) {
        return http.get(`/dashboard/ticketsByDayOfWeek?userIdAsking=${user_id_asking}&tenantId=${tenant_id}&month=${month}&year=${year}&timezoneAdjustmentFactor=${timezone_adjustment_factor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTicketsByHourOfDay(token, user_id_asking, tenant_id, day, month, year, timezone_adjustment_factor) {
        return http.get(`/dashboard/ticketsByHourOfDay?userIdAsking=${user_id_asking}&tenantId=${tenant_id}&day=${day}&month=${month}&year=${year}&timezoneAdjustmentFactor=${timezone_adjustment_factor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTicketsBySLA(token, user_id_asking, tenant_id, day, month, year, timezone_adjustment_factor) {
        return http.get(`/dashboard/ticketsBySLA?userIdAsking=${user_id_asking}&tenantId=${tenant_id}&day=${day}&month=${month}&year=${year}&timezoneAdjustmentFactor=${timezone_adjustment_factor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSLAPerformance(token, user_id_asking, tenant_id, day, month, year, timezone_adjustment_factor) {
        return http.get(`/dashboard/slaPerformance?userIdAsking=${user_id_asking}&tenantId=${tenant_id}&day=${day}&month=${month}&year=${year}&timezoneAdjustmentFactor=${timezone_adjustment_factor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

}


export default new DashboardService();