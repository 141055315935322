// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavbarMinimalColored_navbar__Kwfor {
    width: 80px;
    height: 100%;
    padding: var(--mantine-spacing-md);
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    flex-direction: column;
    z-index: 10;
    background-color: #14344d;
  }
  
  .NavbarMinimalColored_navbarMain__8Nl51 {
    flex: 1 1;
  }
  
  .NavbarMinimalColored_link__4lgWX {
    width: 50px;
    height: 50px;
    border-radius: var(--mantine-radius-md);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--mantine-color-white);
  
    &:hover {
      background-color: var(--mantine-color-blue-7);
    }
  
    &[data-active] {
      &,
      &:hover {
        box-shadow: var(--mantine-shadow-sm);
        background-color: var(--mantine-color-white);
        color: var(--mantine-color-blue-6);
      }
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/NavbarMinimalColored.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,aAAa;IACb,eAAe;IACf,MAAM;IACN,OAAO;IACP,sBAAsB;IACtB,WAAW;IACX,yBAAyB;EAC3B;;EAEA;IACE,SAAO;EACT;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,uCAAuC;IACvC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iCAAiC;;IAEjC;MACE,6CAA6C;IAC/C;;IAEA;MACE;;QAEE,oCAAoC;QACpC,4CAA4C;QAC5C,kCAAkC;MACpC;IACF;EACF","sourcesContent":[".navbar {\n    width: 80px;\n    height: 100%;\n    padding: var(--mantine-spacing-md);\n    display: flex;\n    position: fixed;\n    top: 0;\n    left: 0;\n    flex-direction: column;\n    z-index: 10;\n    background-color: #14344d;\n  }\n  \n  .navbarMain {\n    flex: 1;\n  }\n  \n  .link {\n    width: 50px;\n    height: 50px;\n    border-radius: var(--mantine-radius-md);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: var(--mantine-color-white);\n  \n    &:hover {\n      background-color: var(--mantine-color-blue-7);\n    }\n  \n    &[data-active] {\n      &,\n      &:hover {\n        box-shadow: var(--mantine-shadow-sm);\n        background-color: var(--mantine-color-white);\n        color: var(--mantine-color-blue-6);\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `NavbarMinimalColored_navbar__Kwfor`,
	"navbarMain": `NavbarMinimalColored_navbarMain__8Nl51`,
	"link": `NavbarMinimalColored_link__4lgWX`
};
export default ___CSS_LOADER_EXPORT___;
