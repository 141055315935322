import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';

import UserDataService from '../services/user-activities';
import { withRouter } from 'react-router';

import { TOTAL_TICKET_COUNT, OPEN_TICKET_COUNT } from '../features/actions/Is-Logged-Actions';

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

class MyTicketsTable extends Component {

    constructor(props) {
        super(props); 
 
        this.state = {
            selectedTicketTab: "open",   // switches between open and all to display different tickets
            myOpenHardwareTickets: [],
            myOpenSoftwareTickets: [],
            myOpenTickets: [],
            allMyHardwareTickets: [],
            allMySoftwareTickets: [],
            allMyTickets: []
        };

        this.getMyOpenTickets = this.getMyOpenTickets.bind(this);
        this.getAllMyTickets = this.getAllMyTickets.bind(this);
        this.setTicketTimesSinceCreated = this.setTicketTimesSinceCreated.bind(this);
        this.goToSpecificTicket = this.goToSpecificTicket.bind(this);
    }

    componentDidMount() {
        // var res = this.getMyOpenTickets();
        // var total = this.getAllMyTickets();
        this.getMyOpenTickets();
        this.getAllMyTickets();
        // console.log("res");
        // console.log(res);
        // console.log("total");
        // console.log(total);
        // setTimeout(() => {
        //     var res = this.getMyOpenTickets();
        //     var total = this.getAllMyTickets();
        //     console.log("res");
        //     console.log(res);
        //     console.log("total");
        //     console.log(total);
        //     if (total === 0 ){
        //         console.log('in 0');
        //         this.props.TOTAL_TICKET_COUNT(0)
        //         this.props.OPEN_TICKET_COUNT(0); 
        //     }
        //     else {
        //         console.log('more than 0');
        //         //this.props.TOTAL_TICKET_COUNT(total);
        //         this.props.OPEN_TICKET_COUNT(res); 
        //         this.props.TOTAL_TICKET_COUNT(total);
        //     } 
        // }, 1000);
        // console.log("res");
        // console.log(res);
        // if (res === 0 ){
        //     this.props.OPEN_TICKET_COUNT(0); 
        // }
        // else {
        //     this.props.OPEN_TICKET_COUNT(res); 
        // }
        // this.props.OPEN_TICKET_COUNT(10);
        //this.getAllMyTickets();
    }

    setTicketTimesSinceCreated(tickets) {
        for(var item of tickets) {
            if(item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? " years ago" : " year ago");
            }

            else if(item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? " months ago" : " month ago");
            }

            else if(item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? " days ago" : " day ago");
            }

            else if(item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? " hours ago" : " hour ago");
            }

            else if(item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? " minutes ago" : " minute ago");
            }

            else {
                item.time_since_sent = "moments ago";
            }
        }
    }

    getMyOpenTickets() {    // Get open hardware tickets then get open software tickets, then combine into one array and sort by date asked 
        
        //const wait=ms=>new Promise(resolve => setTimeout(resolve, ms));
        UserDataService.getMyOpenTickets(this.props.loggedStatus.id, this.props.loggedStatus.accessToken)
        .then(response => {
            this.setState({
                myOpenTickets: response.data.results.sort((a, b) => b.minutes_since_sent > a.minutes_since_sent ? 1 : -1)
            });
            //console.log("open tick reducer:");
            //console.log(response.data.results);
            //console.log(response.data.results.length);
            // wait(4*1000).then(() => {
            //     if (response.data.results.length === 0 ){
            //         this.props.OPEN_TICKET_COUNT(0); 
            //     }
            //     else {
            //         this.props.OPEN_TICKET_COUNT(response.data.results.length); 
            //     }
            // }).catch(e => {
            //     console.log(e);
            // });

            // setTimeout(() => {
            if (response.data.results.length === 0 ){
                //console.log('in 0');
                this.props.OPEN_TICKET_COUNT(0); 
            }
            else {
                //console.log('more than 0');
                //this.props.TOTAL_TICKET_COUNT(total);
                this.props.OPEN_TICKET_COUNT(response.data.results.length); 
            } 
            // }, 100);

            //return response.data.results.length;
            //this.props.OPEN_TICKET_COUNT(response.data.results.length); // store the number of open tickets for use elsewhere
        })
        .catch(e => {
            console.log(e);
        })
        // UserDataService.getMyOpenHardwareTickets(this.props.loggedStatus.id, this.props.loggedStatus.accessToken)
        // .then(response => {
        //     // Add a property to each ticket saying it's of type hardware
        //     let hardwareTickets = response.data.results;
        //     for(var item of hardwareTickets) {
        //         item.typeOfTicket = "Hardware";
        //     }
        //     this.setState({
        //         myOpenHardwareTickets: hardwareTickets
        //     });

        //     // Get open software tickets here

        //     // Combine the two so we can display them in the table
        //     let openTickets = hardwareTickets.concat(this.state.myOpenSoftwareTickets);
        //     this.setState({
        //         // sort results by date here (sorting by minutes since sent in descending order so oldest listed first)
        //         myOpenTickets: openTickets.sort((a, b) => b.minutes_since_sent > a.minutes_since_sent ? 1 : -1)
        //     });
        //     //console.log("got the tickets!");
        // })
        // .catch(e => {
        //     console.log(e);
        // });

    }

    getAllMyTickets() {     // Similar to getMyOpenTickets, but get all, not just open ones
        //const wait=ms=>new Promise(resolve => setTimeout(resolve, ms));
        UserDataService.getAllMyTickets(this.props.loggedStatus.id, this.props.loggedStatus.accessToken)
        .then(response => {
            this.setState({
                // sort results by date here (sorting by minutes since sent in descending order so oldest listed first)
                allMyTickets: response.data.results.sort((a, b) => b.minutes_since_sent > a.minutes_since_sent ? 1 : -1)
            });
            //console.log("total ticks reducer:");
            //console.log(response.data.results);

            // wait(4*1000).then(() => {
            //     if (response.data.results.length === 0 ){
            //         this.props.TOTAL_TICKET_COUNT(0); 
            //     }
            //     else {
            //         this.props.TOTAL_TICKET_COUNT(response.data.results.length); 
            //     }
            // }).catch(e => {
            //     console.log(e);
            // });
            // setTimeout(() => {
            if (response.data.results.length === 0 ){
                //console.log('in total0');
                this.props.TOTAL_TICKET_COUNT(0); 
            }
            else {
                //console.log('total more than 0');
                //this.props.TOTAL_TICKET_COUNT(total);
                this.props.TOTAL_TICKET_COUNT(response.data.results.length); 
            } 
            // }, 100);
            //return response.data.results.length;
            // this.props.TOTAL_TICKET_COUNT(response.data.results.length);    // Store total number of tickets for future use
        })
        .catch(e => {
            console.log(e);
        })
        // UserDataService.getAllMyHardwareTickets(this.props.loggedStatus.id, this.props.loggedStatus.accessToken)
        // .then(response => {
        //     // Add a property to each ticket saying it's of type hardware
        //     let hardwareTickets = response.data.results;
        //     for(var item of hardwareTickets) {
        //         item.typeOfTicket = "Hardware";
        //     }
        //     this.setState({
        //         allMyHardwareTickets: hardwareTickets
        //     });

        //     // Get all software tickets here

        //     // Combine the two so we can display them in the table
        //     let allTickets = hardwareTickets.concat(this.state.allMySoftwareTickets);
        //     this.setState({
        //         // sort results by date here (sorting by minutes since sent in descending order so oldest listed first)
        //         allMyTickets: allTickets.sort((a, b) => b.minutes_since_sent > a.minutes_since_sent ? 1 : -1)
        //     });
        //     //console.log("got the tickets!");
        // })
        // .catch(e => {
        //     console.log(e);
        // });
    }

    goToSpecificTicket(id, typeOfTicket) {   // Travel to the specific ticket's information

        if(typeOfTicket === "Hardware") {
            this.props.history.push({
                pathname: "/hardwareTickets/" + id,
                state: {
                    accessToken: this.props.loggedStatus.accessToken,
                    loggedIn: this.props.loggedStatus.loggedIn
                }
            });
        }

        else if(typeOfTicket === "Software") {
            this.props.history.push({
                pathname: "/softwareTickets/" + id,
                state: {
                    accessToken: this.props.loggedStatus.accessToken,
                    loggedIn: this.props.loggedStatus.loggedIn
                }
            });
        }
    }


    render() {

        const { myOpenTickets, allMyTickets } = this.state;
        //const { history } = this.props;

        this.setTicketTimesSinceCreated(myOpenTickets); // Add the time since sent values to each ticket
        this.setTicketTimesSinceCreated(allMyTickets);
        
        let tableToDisplay;
        
        switch(this.state.selectedTicketTab) {
            case "open":
                if(myOpenTickets.length === 0) {
                    tableToDisplay = 
                    <>
                    <tr>
                        <td style={{textAlign: "center", width: "100%"}} className="gothamNarrowLightFont">You have no open tickets.</td>
                    </tr>
                    </>
                }

                else {
                    tableToDisplay = 
                    <>
                        {myOpenTickets && myOpenTickets.map((ticket) => (
                        <tr key={`${ticket.type_of_ticket}-${ticket.id}`} className="myTicketTableRow" onClick={() => this.goToSpecificTicket(ticket.id, ticket.type_of_ticket)}>
                            <td style={{width: "60%"}} className="myTicketTableText gothamNarrowLightFont">{ticket.ticket_title}</td>
                            <td style={{width: "20%"}} className="myTicketTableText gothamNarrowLightFont">{ticket.type_of_ticket}</td>
                            <td style={{width: "20%"}} className="myTicketTableText gothamNarrowLightFont">{ticket.time_since_sent}</td>
                        </tr>
                        ))}
                    </>
                }
            break;

            case "all":
                if(allMyTickets.length === 0) {
                    tableToDisplay = 
                    <>
                    <tr>
                        <td style={{textAlign: "center", width: "100%"}} className="gothamNarrowLightFont">You have no tickets to show.</td>
                    </tr>
                    </>
                }

                else {
                    tableToDisplay = 
                    <>
                        {allMyTickets && allMyTickets.map((ticket) => (
                            <tr key={`${ticket.type_of_ticket}-${ticket.id}`} className="myTicketTableRow" onClick={() => this.goToSpecificTicket(ticket.id, ticket.type_of_ticket)}>
                            <td style={{width: "60%"}} className="myTicketTableText gothamNarrowLightFont">{ticket.ticket_title}</td>
                            <td style={{width: "20%"}} className="myTicketTableText gothamNarrowLightFont">{ticket.type_of_ticket}</td>
                            <td style={{width: "20%"}} className="myTicketTableText gothamNarrowLightFont">{ticket.time_since_sent}</td>
                        </tr>
                        ))}
                    </>
                }
            break;

            default:
                tableToDisplay = <></>
        }

        return (
            <Card className="text-center" style={{flex: "1", height: "300px"}}>
                <Card.Header className="cardHeader" /* style={{backgroundColor: "#343a40"}} */>
                    <Nav variant="tabs" defaultActiveKey="#open">
                        <Nav.Item>
                            <Nav.Link href="#open" onClick={() => this.setState({selectedTicketTab: "open"})} className="gothamNarrowFont">My Open Tickets</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#all" onClick={() => this.setState({selectedTicketTab: "all"})} className="gothamNarrowFont">All My Tickets</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>                  
                    <div style={{height: "215px", overflow: "auto"}}>
                        <Table>
                            <thead>
                                <tr>
                                    <th style={{width: "60%", textAlign: "left"}} className="gothamNarrowFont">Ticket Title</th>                                   
                                    <th style={{width: "20%", textAlign: "left"}} className="gothamNarrowFont">Type</th>
                                    <th style={{width: "20%", textAlign: "left"}} className="gothamNarrowFont">Asked</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableToDisplay}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav    // Use global state to control which link is highlighted
    };
}

export default withRouter(connect(mapStateToProps, {TOTAL_TICKET_COUNT, OPEN_TICKET_COUNT})(MyTicketsTable));