import React, { Component } from "react";
import Switch from "react-switch";

import SettingsDataService from "../services/settings-activities";
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import { ACCESSTOKEN, ENABLE_EMAILS, DISABLE_EMAILS, SOFTWARE_ENABLE_EMAILS, SOFTWARE_DISABLE_EMAILS, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import "bootstrap/dist/css/bootstrap.min.css";



class Settings extends Component {
    constructor(props) {
        super(props);

        this.handleHardwareChange = this.handleHardwareChange.bind(this);
        this.handleSoftwareChange = this.handleSoftwareChange.bind(this);
        this.getEmailStatus = this.getEmailStatus.bind(this);
        this.getEmailStatusSoftware = this.getEmailStatusSoftware.bind(this);

        this.state = {
            hardware_checked: true,
            software_checked: true
        };
    }

    componentDidMount() {           // Could pass the token into retrieveAccounts and gate it here?   token passed into props from the App.js file
        
        if(this.props.loggedStatus.loggedIn){
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    
                    this.getEmailStatus(); // getting status of enable or disable email for hardware
                    this.getEmailStatusSoftware();  // getting status of enable or disable email for software
                }
            });
        }

        this.props.UPDATE_ACTIVE_LINK("settings");

    }

    // on mount get email status 
    // after change, change email status in front and back 

    // getting status of enable or disable email for hardware
    getEmailStatus() {
        console.log("in here");
        this.setState({ hardware_checked: (this.props.loggedStatus.getEmails === true) ? true : false });
        console.log(this.state.hardware_checked);
        if(this.state.hardware_checked){
            this.props.ENABLE_EMAILS();
        }
        else {
            this.props.DISABLE_EMAILS();
        }
    }

    // getting status of enable or disable email for software
    getEmailStatusSoftware() {
        console.log("in software ready here");
 
        this.setState({ software_checked: (this.props.loggedStatus.getEmailsSoftware === true) ? true : false });
        console.log(this.state.software_checked);
        if(this.state.software_checked){
            this.props.SOFTWARE_ENABLE_EMAILS();
        }
        else {
            this.props.SOFTWARE_DISABLE_EMAILS();
        }
    }

    handleHardwareChange(hardware_checked) {
        this.setState({ hardware_checked });
        if(hardware_checked){
            this.props.ENABLE_EMAILS();
        }
        else {
            this.props.DISABLE_EMAILS();
        }
        let obj = {}
        obj["emailNotif_status"] = hardware_checked ? 1 : 0;
        setTimeout(() => {
            // changing email status for hardware tickets
            SettingsDataService.changeEmailsStatus(this.props.loggedStatus.id, obj, this.props.loggedStatus.accessToken)
            .then(response => {
                console.log("in primise");
                console.log("changing");
            })
            .catch(e => {
                console.log(e);
            });
        }, 1000);


    }

    handleSoftwareChange(software_checked) {
        this.setState({ software_checked });
        if(software_checked){
            this.props.SOFTWARE_ENABLE_EMAILS();
        }
        else {
            this.props.SOFTWARE_DISABLE_EMAILS();
        }
        let obj = {}
        obj["software_emailNotif_status"] = software_checked ? 1 : 0;
        setTimeout(() => {
            // changing email status for software tickets
            SettingsDataService.changeSoftwareEmailsStatus(this.props.loggedStatus.id, obj, this.props.loggedStatus.accessToken)
            .then(response => {
                console.log("in primise software");
                console.log("changing software");
                
            })
            .catch(e => {
                console.log(e);
            });
        }, 1000);
    }



    render() {

        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }

        return (
            <>
                <div className="emailDisableHardwareSwitch">
                    <label>
                        <span id="HardwareGlobalLabel">{this.state.hardware_checked ? "Hardware Ticketing Emails Enabled" : "Hardware Ticketing Emails Disabled"}</span>
                        <Switch id="HardwareGlobalSwitch" onChange={this.handleHardwareChange} checked={this.state.hardware_checked} />
                    </label>
                </div>

                <div className="emailDisableSoftwareSwitch">
                    <label>
                        <span id="SoftwareGlobalLabel">{this.state.software_checked ? "Software Ticketing Emails Enabled" : "Software Ticketing Emails Disabled"}</span>
                        <Switch id="SoftwareGlobalSwitch" onChange={this.handleSoftwareChange} checked={this.state.software_checked} />
                    </label>
                </div>                
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        //getEmails: state.getEmails
    };
}

export default connect(mapStateToProps, {ACCESSTOKEN, LOGOUT, ENABLE_EMAILS, DISABLE_EMAILS, SOFTWARE_ENABLE_EMAILS, SOFTWARE_DISABLE_EMAILS, UPDATE_ACTIVE_LINK})(Settings);
//export default connect(mapStateToProps, {ACCESSTOKEN})(AccountsList);