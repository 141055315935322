import http from "../http-common";

class AssignTechsTicketsCalendarService {

    // get tech events and display events on calendar (gets events in time range specified by argument values)
    getTechEventsOnCalendar(token, start_date, end_date, timezone_adjustment_factor) {
        return http.get(`/techAssignment?startDate=${start_date}&endDate=${end_date}&timezoneAdjustmentFactor=${timezone_adjustment_factor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

     // get tech users to fill in searchable drop down 
     getUsers(token) {
        return http.get(`/techAssignment/users`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getGroupId(id, token) {
        return http.get(`/techAssignment/getGroupId?id=${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getCountAndTechIds(groupId, findDate, timezoneAdjustmentFactor, token) {
        return http.get(`/techAssignment/getCountAndTechIds?groupId=${groupId}&findDate=${findDate}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Add a new calendar event for tech
    createNewTechEvent(data, token) {
        return http.post("/techAssignment/addTechEvent", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // update tech event's start time/end time 
    updateTechEventTime(guid, data, token) {
        return http.put(`/techAssignment/${guid}/updateTechEventTime`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    updateTechRecurringEventTimes(id, data, token) {
        return http.put(`/techAssignment/${id}/updateTechRecurringEventTimes`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // update tech event's name/description
    updateTechEventName(guid, data, token) {
        return http.put(`/techAssignment/${guid}/updateTechEventName`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // update recurring tech events' name/description
    updateRecurrTechEventsName(group_id, data, token) {
        return http.put(`/techAssignment/${group_id}/updateRecurrTechEventsName`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // update tech event's date and times (start and end)
    updateTechEventMoved(guid, data, token) {
        return http.put(`/techAssignment/${guid}/updateTechEventMoved`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    updateMoveAllRecurEvents(data, token) {
        return http.put(`/techAssignment/updateMoveAllRecurEvents`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // update single tech event into recurring event
    updateSingleEventToRecurringEvents(guid, data, token) {
        return http.put(`/techAssignment/${guid}/updateSingleEventToRecurringEvents`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // delete tech event
    deleteTechEvent(guid, token) {
        return http.delete(`/techAssignment/${guid}`, {headers: {Authorization: 'Bearer ' + token}});
    }
    
    // delete all recurring events 
    deleteRecurrTechEvents (groupdId, date, timezoneAdjustmentFactor, token) {
        return http.delete(`/techAssignment/${groupdId}/deleteRecurrTechEvents?dateToCompare=${date}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get user id to edit 
    getEditableUserIdRecurrence(event_id, timezoneAdjustmentFactor, token) {
        return http.get(`/techAssignment/${event_id}/editEvent?timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // add a recurring tech assignment
    addRecurringAssignment(data, token) {
        return http.post(`/techAssignment/addRecurringAssignment`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of techs as "Last, First MI" for the assigned to dropdown of My Assigned Tickets page (used for "super techs" who can view all techs)
    getTechsForMyTicketsTableDropdown(asker_id, token) {
        return http.get(`/techAssignment/getTechsForMyTicketsTableDropdown?askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }
}


export default new AssignTechsTicketsCalendarService();