import React, { Component } from "react";
// import AccountDataService from "../services/account-activities";
// import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
// import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';
// import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from '../../components/Navbar';
import {
    Paper,
    Button,
} from '@mantine/core';

//import Card from 'react-bootstrap/Card';

//import {Link} from 'react-router-dom';

//import ListGroup from 'react-bootstrap/ListGroup';

import { Redirect } from 'react-router';

import HardwareAssetDataService from '../../services/hardware-asset-activities';

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';

import Card from 'react-bootstrap/Card';
import Table from 'react-bootstrap/Table';

import Form from 'react-bootstrap/Form';
//import {Link} from 'react-router-dom';
//import ListGroup from 'react-bootstrap/ListGroup';


class HardwareAssetDetails extends Component {

    // this.props.match.params.id => to get id number from the URL
    constructor(props) {
        super(props);

        // function registrations
        this.getAssetDetails = this.getAssetDetails.bind(this);
        this.calculateWarrantyLeaseDaysRemaining = this.calculateWarrantyLeaseDaysRemaining.bind(this);
        this.getHistoryDetails = this.getHistoryDetails.bind(this);

        this.closeHistoryNoteModal = this.closeHistoryNoteModal.bind(this);
        this.openHistoryNoteModal = this.openHistoryNoteModal.bind(this);
        this.handleCreateHistoryNoteTextChange = this.handleCreateHistoryNoteTextChange.bind(this);
        this.handleSelectedEventTypeChange = this.handleSelectedEventTypeChange.bind(this);
        this.submitCreateHistoryNoteForm = this.submitCreateHistoryNoteForm.bind(this);

        this.closeHistoryNoteDetailsModal = this.closeHistoryNoteDetailsModal.bind(this);
        this.openHistoryNoteDetailsModal = this.openHistoryNoteDetailsModal.bind(this);

        this.goToEditPage = this.goToEditPage.bind(this);

        this.state = {
            asset: {
                id: 0,
                asset_type_id: 0,
                asset_type_name: "",
                name_of_asset: "",
                model_id: 0,
                model_name: "",
                vendor_id: 0,
                vendor_name: "",
                asset_tag: "",
                asset_serial_number: "",
                ip_address: "",
                mac_address: "",
                ram: "",
                cpu: "",
                asset_bar_code: "",
                acquisition_date: "",
                warranty_start_date: "",
                warranty_expiration_date: "",
                site_id: 0,
                name_of_site: "",
                assigned_person_id: 0,
                //assigned_person_name: "",
                first_name: "",     // the names refer to the name of the person assigned this asset
                middle_name: "",
                last_name: "",
                assigned_location_at_site_id: 0,
                //assigned_location_at_site_text: "",
                floor_number: "",
                aisle_number: "",
                desk_number: "",
                lease_start_date: "",
                lease_end_date: "",
                manufacturer_website: "",
                asset_category_id: 0,
                asset_category_name: "",
                isTracked: false,
                core_asset_assigned_to_id: 0,
                core_asset_assigned_to_name: "",
                tenant_id: 0,
                tenant_name: ""
            },

            warranty_days_remaining: null,
            lease_days_remaining: null,

            history: [],     // constains history items for that asset

            show_history_note_modal: false,
            history_note_text_entered: "",
            canSeeErrorMessageCreateHistoryNote: false,
            eventTypes: [       // a curated list of types that the history entities will accept - used in drop down on modal
                {event_type_name: "Assignment"},
                {event_type_name: "Creation"},
                {event_type_name: "Lease Update"},
                {event_type_name: "Location Change"},
                {event_type_name: "Reassignment"},
                {event_type_name: "Software Deployment"},
                {event_type_name: "Ticket Created"},
                {event_type_name: "Warranty Update"}
            ],
            selected_event_type_name: "",    // controls which name is sent to backend upon creation 


            show_history_note_details_modal: false,  // set to true if user clicks a speciifc note in history table
            note_details: {}
        }

    }

    goToEditPage() {
        this.props.history.push(`/editHardwareAsset/${this.props.match.params.id}`);  
    }

    closeHistoryNoteModal() {
        this.setState({
            show_history_note_modal: false,
            history_note_text_entered: ""
        });
    }

    openHistoryNoteModal() {
        this.setState({
            show_history_note_modal: true,
            selected_event_type_name: this.state.eventTypes[0].event_type_name
        });
    }

    closeHistoryNoteDetailsModal() {
        this.setState({
            show_history_note_details_modal: false
        });
    }

    openHistoryNoteDetailsModal(noteId) {
        // this.setState({
        //     show_history_note_details_modal: true
        // });

        HardwareAssetDataService.getSpecificHistoryEventDetails(noteId, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken).then(response => {
            console.log(response.data.results[0]);
            // if the record has a ticket id associated with it, navigate to that ticket details screen
            if(response.data.results[0].associated_ticket_id !== null) {
                this.props.history.push(`/hardwareTickets/${response.data.results[0].associated_ticket_id}`);
            }

            else {
                this.setState({
                    show_history_note_details_modal: true,
                    note_details: response.data.results[0]
                }, () => console.log(this.state.note_details));

            }
        })
        .catch(e => {
            console.log(e);
        });

    }

    handleCreateHistoryNoteTextChange(e) {
        this.setState({
            history_note_text_entered: e.target.value
        });
    }

    handleSelectedEventTypeChange(newName) {
        this.setState({
            selected_event_type_name: newName
        });
    }

    submitCreateHistoryNoteForm() {
        console.log("Values submitting to backend: ");
        console.log("Note Text: ", this.state.history_note_text_entered);
        console.log("Type Name; ", this.state.selected_event_type_name);

        // make sure they are submitting required fields
        if(this.state.history_note_text_entered === "" || this.state.selected_event_type_name === "") {
            this.setState({
                canSeeErrorMessageCreateHistoryNote: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateHistoryNote: false
            });

            // get data ready to send to backend
            let data = {
                assetId: this.props.match.params.id,
                creatorId: this.props.loggedStatus.id, 
                //creationTime: new Date().toISOString().slice(0, 19).replace('T', ' '), 
                itemText: this.state.history_note_text_entered, 
                itemType: this.state.selected_event_type_name
            };

            HardwareAssetDataService.createHistoryEvent(this.props.loggedStatus.accessToken, data).then(response => {
                // get the updated history items list
                this.getHistoryDetails(this.props.match.params.id, this.props.loggedStatus.accessToken).then(response => {
                    this.setState({
                        history: response.results
                    });

                    this.closeHistoryNoteModal();
                })
                .catch(e => {
                    console.log(e);
                });
            })
            .catch(e => {
                console.log(e);
            });
        }
    }

    getAssetDetails(id, token) {
        return new Promise ((resolve, reject) => {
  
            HardwareAssetDataService.getSpecificAsset(token, id)
            .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                resolve(response.data);
            })
            .catch(e => {
                console.log(e);
                reject(e);
            });
        });        
    }

    calculateWarrantyLeaseDaysRemaining() {
        // if a warranty expiration date exists, calculate its distance from today. if it doesn't exist the days remaining stays null
        if(this.state.asset.warranty_expiration_date && this.state.asset.warranty_expiration_date !== null && this.state.asset.warranty_expiration_date !== "") {
            // create a date object with today's date
            var today = new Date();
            // var m = q.getMonth()+1;
            // var d = q.getDay();
            // var y = q.getFullYear();

            // var today = new Date(y,m,d);

            // compare today's date with the date of warranty expiration
            var expiration = new Date(this.state.asset.warranty_expiration_date.split("T")[0]);

            console.log(today);
            //console.log(today);
            console.log(expiration);

            // To calculate the time difference of two dates
            var Difference_In_Time = expiration.getTime() - today.getTime();
            
            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            //console.log("Difference in days is: ", Math.round(Difference_In_Days));

            this.setState({
                warranty_days_remaining: Math.ceil(Difference_In_Days)
            });
        }

        // if a lease expiration date exists, calculate its distance from today. if it doesn't exist the days remaining stays null
        if(this.state.asset.lease_end_date && this.state.asset.lease_end_date !== null && this.state.asset.lease_end_date !== "") {
            var todayForLease = new Date();
            var leaseExpiration = new Date(this.state.asset.lease_end_date.split("T")[0]);

            var Difference_In_Time_Lease = leaseExpiration.getTime() - todayForLease.getTime();
            var Difference_In_Days_Lease = Difference_In_Time_Lease / (1000 * 3600 * 24);

            this.setState({
                lease_days_remaining: Math.ceil(Difference_In_Days_Lease)
            });
        }
    }

    getHistoryDetails(id, token) {
        return new Promise ((resolve, reject) => {
  
            HardwareAssetDataService.getHistory(id, this.props.loggedStatus.timezone_adjustment_factor, token)
            .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                resolve(response.data);
            })
            .catch(e => {
                console.log(e);
                reject(e);
            });
        });
    }

    componentDidMount() {
        if(this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

    console.log(new Date().toISOString().slice(0, 19).replace('T', ' '));
                    this.getAssetDetails(this.props.match.params.id, token).then(response => {
                        //console.log(response.results);
                        this.setState({
                            asset: response.results[0]
                        }, () => this.calculateWarrantyLeaseDaysRemaining());

                        this.getHistoryDetails(this.props.match.params.id, token).then(response => {
                            this.setState({
                                history: response.results
                            }, () => console.log(this.state.history));
                        })
                        .catch(e => {
                            console.log(e);
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }

            });
        }
    }



    render() {
        if(!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_assets && !this.props.userPermissions.can_edit_assets && !this.props.userPermissions.can_add_assets && !this.props.userPermissions.can_delete_assets)) {
            return (
                <Redirect to='/'/>
            );
        }


        return (
            <>
            <Navbar pageTitle={"Hardware Asset Details"} />
                <div className="container mt-3" style={{paddingTop: "15px"}}></div>
                <Container fluid>
                    <Row>
                        <Col md={{span: 8, offset: 2}}>
                            <Button onClick={() => this.goToEditPage()}>Edit</Button>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                            {/* <Col md={{span: 4, offset: 2}}> */}
                            <Col md={{span: 4, offset: 2}}>
                                <Card style={{flex: "1", height: "300px"}}>
                                    <Card.Header style={{color: "white", textAlign: "center", backgroundColor: "#343a40"}} className="gothamNarrowFont">Basic Details</Card.Header>
                                    <Card.Body>                  
                                        <div style={{height: "190px", overflow: "auto"}}>
                                            <Table striped>
                                                <tbody>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Asset Name</td>
                                                        {this.state.asset.name_of_asset && this.state.asset.name_of_asset !== "" ?
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.name_of_asset}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Category</td>
                                                        {this.state.asset.asset_category_name && this.state.asset.asset_category_name !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.asset_category_name}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Type</td>
                                                        {this.state.asset.asset_type_name && this.state.asset.asset_type_name !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.asset_type_name}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Model</td>
                                                        {this.state.asset.model_name && this.state.asset.model_name !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.model_name}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Acquisition Date</td>
                                                        {this.state.asset.acquisition_date && this.state.asset.acquisition_date !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.acquisition_date.split("T")[0]}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Tag</td>
                                                        {this.state.asset.asset_tag && this.state.asset.asset_tag !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.asset_tag}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Barcode</td>
                                                        {this.state.asset.asset_bar_code && this.state.asset.asset_bar_code !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.asset_bar_code}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Serial Number</td>
                                                        {this.state.asset.asset_serial_number && this.state.asset.asset_serial_number !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.asset_serial_number}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">IP Address</td>
                                                        {this.state.asset.ip_address && this.state.asset.ip_address !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.ip_address}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">MAC Address</td>
                                                        {this.state.asset.mac_address && this.state.asset.mac_address !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.mac_address}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">CPU</td>
                                                        {this.state.asset.cpu && this.state.asset.cpu !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.cpu}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">RAM</td>
                                                        {this.state.asset.ram && this.state.asset.ram !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.ram}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Manufacturer Website</td>
                                                        {this.state.asset.manufacturer_website && this.state.asset.manufacturer_website !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.manufacturer_website}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Vendor Name</td>
                                                        {this.state.asset.vendor_name && this.state.asset.vendor_name !== "" ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.vendor_name}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Is Tracked Asset</td>
                                                        {this.state.asset.isTracked !== null && this.state.asset.isTracked ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">Yes</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">No</td>
                                                        }
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={{span: 4}}>
                                <Card style={{flex: "1", height: "300px"}}>
                                    <Card.Header style={{color: "white", textAlign: "center", backgroundColor: "#343a40"}} className="gothamNarrowFont">Assignment / Location</Card.Header>
                                    <Card.Body>                  
                                        <div style={{height: "190px", overflow: "auto"}}>
                                            <Table striped>
                                                <tbody>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Assigned To</td>
                                                        {this.state.asset.assigned_person_id && this.state.asset.assigned_person_id !== null && this.state.asset.middle_name !== null ?
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{`${this.state.asset.first_name} ${this.state.asset.middle_name} ${this.state.asset.last_name}`}</td>
                                                            :
                                                            this.state.asset.assigned_person_id && this.state.asset.assigned_person_id !== null && this.state.asset.middle_name === null ?
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{`${this.state.asset.first_name} ${this.state.asset.last_name}`}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">Unassigned</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Company</td>
                                                        {this.state.asset.tenant_name && this.state.tenant_name !== "" ?
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.tenant_name}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Site</td>
                                                        {this.state.asset.name_of_site && this.state.asset.name_of_site !== "" ?
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.name_of_site}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    <tr>
                                                        <td style={{width: "50%"}} className="gothamNarrowLightFont">Location at Site</td>
                                                        {this.state.asset.assigned_location_at_site_id && this.state.asset.assigned_location_at_site_id !== null ? 
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{`Floor(${this.state.asset.floor_number}) Aisle(${this.state.asset.aisle_number}) Desk(${this.state.asset.desk_number})`}</td>
                                                            :
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                        }
                                                    </tr>
                                                    {this.state.asset.core_asset_assigned_to_id && this.state.asset.core_asset_assigned_to_id !== null ?
                                                        <tr>
                                                            <td style={{width: "50%"}} className="gothamNarrowLightFont">Core Asset Assigned To</td>
                                                            <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.core_asset_assigned_to_name}</td>
                                                        </tr>
                                                        :
                                                        undefined
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            {/* <Col md={{span: 4}}>
                                <ChartWidget/>
                            </Col> */}
                    </Row>
                    <Row className="mt-4">
                        {/* <Col md={{span: 4, offset: 2}}> */}
                        <Col md={{span: 4, offset: 2}}>
                            <Card style={{flex: "1", height: "300px"}}>
                                <Card.Header style={{color: "white", textAlign: "center", backgroundColor: "#343a40"}} className="gothamNarrowFont">Warranty / Lease</Card.Header>
                                <Card.Body>                  
                                    <div style={{height: "190px", overflow: "auto"}}>
                                        <Table striped>
                                            <tbody>
                                                <tr>
                                                    <td style={{width: "50%"}} className="gothamNarrowLightFont">Warranty Start Date</td>
                                                    {this.state.asset.warranty_start_date && this.state.asset.warranty_start_date !== null ? 
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.warranty_start_date.split("T")[0]}</td>
                                                        :
                                                        <td style={{width: "50%", color: "red"}} className="gothamNarrowFont">None Active</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{width: "50%"}} className="gothamNarrowLightFont">Warranty End Date</td>
                                                    {this.state.asset.warranty_expiration_date && this.state.asset.warranty_expiration_date !== null ? 
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.warranty_expiration_date.split("T")[0]}</td>
                                                        :
                                                        <td style={{width: "50%", color: "red"}} className="gothamNarrowFont">None Active</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{width: "50%"}} className="gothamNarrowLightFont">Warranty Days Remaining</td>
                                                    {this.state.warranty_days_remaining !== null && this.state.warranty_days_remaining <= 0 ?
                                                        <td style={{width: "50%", color: "red"}} className="gothamNarrowFont">0</td>
                                                        :
                                                        this.state.warranty_days_remaining !== null && this.state.warranty_days_remaining <= 30 ? 
                                                        <td style={{width: "50%", color: "orangered"}} className="gothamNarrowFont">{this.state.warranty_days_remaining}</td>
                                                        :
                                                        this.state.warranty_days_remaining !== null && this.state.warranty_days_remaining > 30 ?
                                                        <td style={{width: "50%", color: "green"}} className="gothamNarrowFont">{this.state.warranty_days_remaining}</td>
                                                        :
                                                        <td style={{width: "50%", color: "red"}} className="gothamNarrowFont">None Active</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{width: "50%"}} className="gothamNarrowLightFont">Lease Start Date</td>
                                                    {this.state.asset.lease_start_date && this.state.asset.lease_start_date !== null ?
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.lease_start_date.split("T")[0]}</td>
                                                        :
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{width: "50%"}} className="gothamNarrowLightFont">Lease End Date</td>
                                                    {this.state.asset.lease_end_date && this.state.asset.lease_end_date !== null ? 
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">{this.state.asset.lease_end_date.split("T")[0]}</td>
                                                        :
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <td style={{width: "50%"}} className="gothamNarrowLightFont">Lease Days Remaining</td>
                                                    {this.state.lease_days_remaining !== null && this.state.lease_days_remaining <= 0 ?
                                                        <td style={{width: "50%", color: "red"}} className="gothamNarrowFont">0</td>
                                                        :
                                                        this.state.lease_days_remaining !== null && this.state.lease_days_remaining <= 30 ? 
                                                        <td style={{width: "50%", color: "orangered"}} className="gothamNarrowFont">{this.state.lease_days_remaining}</td>
                                                        :
                                                        this.state.lease_days_remaining !== null && this.state.lease_days_remaining > 30 ?
                                                        <td style={{width: "50%", color: "green"}} className="gothamNarrowFont">{this.state.lease_days_remaining}</td>
                                                        :
                                                        <td style={{width: "50%"}} className="gothamNarrowFont">-</td>
                                                    }
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={{span: 4}}>
                            <Card style={{flex: "1", height: "300px"}}>
                                <Card.Header style={{color: "white", textAlign: "center", backgroundColor: "#343a40"}} className="gothamNarrowFont">
                                    History
                                    <span style={{display: "inline-block", paddingLeft: "15px"}}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon style={{color: "white", cursor: "pointer", fontSize: "10pt"}} icon={faPlus} onClick={() => this.openHistoryNoteModal()}/>
                                        </OverlayTrigger>
                                    </span>
                                </Card.Header>
                                <Card.Body>                  
                                    <div style={{height: "190px", overflow: "auto"}}>
                                        <Table striped hover className="hardwareAssetTable">
                                            <tbody>
                                                {this.state.history && this.state.history.map((item) => (
                                                    <tr key={`historyItem${item.id}`} onClick={() => this.openHistoryNoteDetailsModal(item.id)}>
                                                        <td style={{width: "25%"}} className="gothamNarrowLightFont">{item.date_time_created.split(" ")[0]}</td>
                                                        <td style={{width: "75%"}} className="gothamNarrowFont">
                                                            {item.is_user_created ? 
                                                                `[Note] ${item.item_text}`
                                                                :
                                                                `${item.item_text}`
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>      
                    </Row>      
                </Container>

                {/* This modal is for the add history note form */}
                <Modal
                    show={this.state.show_history_note_modal}
                    onHide={() => this.closeHistoryNoteModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{textAlign: "center", cursor: "default"}} className="gothamNarrowFont">Create History Note</div>
                                <div style={{cursor: "default", fontSize: "15px"}} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{width: "100%"}}>
                                <span style={{display: "inline-block", width: "90%"}}>
                                    <Form.Group controlId="addHistoryNoteText" className="mb-3">
                                        <Form.Label>Note Text<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Deployed Software..." required value={this.state.history_note_text_entered} onChange={this.handleCreateHistoryNoteTextChange}/>
                                    </Form.Group>
                                </span>
                                <span style={{display: "inline-block", width: "90%"}}>
                                                <Form.Group controlId="addHistoryNoteType" className="mb-3">
                                                    <Form.Label>Event Type<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control as="select" required>
                                                        {this.state.eventTypes && this.state.eventTypes.map((type) => (
                                                            <>
                                                                {this.state.selected_event_type_name === type.event_type_name ?
                                                                    <option key={`eventName_${type.event_type_name}`} onClick={() => this.handleSelectedEventTypeChange(type.event_type_name)} selected>{type.event_type_name}</option>
                                                                    :
                                                                    <option key={`eventName_${type.event_type_name}`} onClick={() => this.handleSelectedEventTypeChange(type.event_type_name)}>{type.event_type_name}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                            </div>
                            <div style={{textAlign: "center", marginTop: "15px"}}>
                                {this.state.canSeeErrorMessageCreateHistoryNote ?
                                <p style={{color: "red"}}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                :
                                undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{paddingRight: "15px"}}>
                                <Button variant="secondary" onClick={() => this.closeHistoryNoteModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>     
                                <Button id="newDepartmentFormOkButton" onClick= {() => {this.submitCreateHistoryNoteForm()}} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for the history note details when user clicks a note in history table */}
                <Modal
                    show={this.state.show_history_note_details_modal}
                    onHide={() => this.closeHistoryNoteDetailsModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div style={{textAlign: "center", cursor: "default"}} className="gothamNarrowFont">History Event Details</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <p>
                                {this.state.note_details.is_user_created && this.state.note_details.middle_name && this.state.note_details.middle_name !== null ?
                                    <span><span className="gothamNarrowLightFont">Initiated By: </span> <span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.first_name} ${this.state.note_details.middle_name} ${this.state.note_details.last_name}`}</span></span>
                                    :
                                    this.state.note_details.is_user_created && this.state.note_details.middle_name === null ?
                                    <span><span className="gothamNarrowLightFont">Initiated By: </span><span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.first_name} ${this.state.note_details.last_name}`}</span></span>
                                    :
                                    <span><span className="gothamNarrowLightFont">Initiated By: </span><span style={{marginLeft: "25px"}} className="gothamNarrowFont">Auto Generated By System</span></span>
                                }
                            </p>
                            <p>
                                {this.state.note_details.date_time_created && this.state.note_details.date_time_created !== null ?
                                    <span><span className="gothamNarrowLightFont">Time: </span><span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.date_time_created.split(" ")[0]}`}</span> <span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.date_time_created.split(" ")[1].split(".")[0]}`}</span></span>
                                    :
                                    undefined
                                }
                            </p>
                            <p>
                                {this.state.note_details.item_type && this.state.note_details.item_type !== null ? 
                                    <span><span className="gothamNarrowLightFont">Event Type: </span><span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.item_type}`}</span></span>
                                    :
                                    undefined
                                }
                            </p>
                            <p>
                                {this.state.note_details.name_of_asset && this.state.note_details.name_of_asset !== null ?
                                    <span><span className="gothamNarrowLightFont">Asset Involved: </span><span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.name_of_asset}`}</span></span>
                                    :
                                    undefined
                                }
                            </p>
                            <p>
                                {this.state.note_details.item_text && this.state.note_details.item_text !== null ?
                                    <span><span className="gothamNarrowLightFont">Note: </span><span style={{marginLeft: "25px"}} className="gothamNarrowFont">{`${this.state.note_details.item_text}`}</span></span>
                                    :
                                    undefined
                                }
                            </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <div>     
                            <Button id="newDepartmentFormOkButton" onClick= {() => this.closeHistoryNoteDetailsModal()} className="gothamNarrowFont">Ok</Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT })(HardwareAssetDetails);