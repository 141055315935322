// this widget shows the % change in number of tickets since the last week (displays N/A if previous week's ticket count was 0)

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Paper,
} from '@mantine/core';

import {
  connect, /*, useDispatch*/
  useSelector
} from 'react-redux';

import { store } from '../../app/store';

import "./Widgets.css";

import HardwareTicketDataService from '../../services/hardware-ticket-activities';


function PercentChangeTicketsLastWeek(props) {
  let state = store.getState();
  //let dispatch = useDispatch();
  const accessToken = useSelector((state) => state.logged.accessToken);
  const username = useSelector((state) => state.logged.username);
  const accessTokenRequest = props.onAccessTokenRequest
  const tenantId = props.loggedStatus.report_tenant_id_selected;
  const userId = props.loggedStatus.id;
  const [numberResult, setNumberResult] = React.useState(0);   // start result at 0 in the functional component's state

  React.useEffect(() => {

    accessTokenRequest(username).then((token) => {
        HardwareTicketDataService.getNumberOfTicketsLastWeek(tenantId, userId, token).then(response => {
            console.log(response);
            if(response.data.results && response.data.results.length > 0) {
              let valueToUse = response.data.percent_change;
              if(valueToUse !== null) {
                valueToUse = Math.round(valueToUse);
              }
                setNumberResult(valueToUse);
            }
        })
        .catch(e => {
            console.log(e);
        });

    // HardwareTicketDataService.getSlas(token)
    //     .then(response => {
    //     console.log("Hello from get slas!");
    //     console.log(response);

    //     let updatedPriorityList = [...priorityList];

    //     response.data.results.map((sla) => {
    //         updatedPriorityList.push(sla);
    //         return updatedPriorityList;
    //     });

    //     setPriorityList(updatedPriorityList);
    //     })
    //     .catch(e => {
    //     console.log(e);
    //     });

    })
    

  }, [numberResult, setNumberResult, accessTokenRequest, username, tenantId, userId]);    // any state changes now have the number result as a dependency to watch out for

  //const [prioritySelected, setPrioritySelected] = useState("All");
  //console.log("Priority Name = ", state.myAssignedTickets.priority_name);


  return (
    <>
        <Paper withBorder p="md" radius="md" display="block" style={{width: "175px", height: "175px"}}>
            <div className="widgetHeader">% Change From Previous Week</div>
            {numberResult > 0 &&
                <div className="widgetDataTextGreen">{`+${numberResult}%`}</div>
            }
            {numberResult < 0 && 
                <div className="widgetDataTextRed">{`${numberResult}%`}</div>
            }
            {numberResult === 0 && 
                <div className="widgetDataText">0%</div>
            }
            {numberResult === null && 
                <div className="widgetDataText">N/A</div>
            }
        </Paper>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    loggedStatus: state.logged,
    myAssignedTickets: state.myAssignedTickets
  };
}

export default connect(mapStateToProps)(PercentChangeTicketsLastWeek);