// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* adjusting the built-in theme */

/* calendar event */
.calendar_default_event_inner {
    background: #2e78d6;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 10pt;
    padding: 5px;
    opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Admin-Dashboard/CalendarStyles.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC,mBAAmB;AACnB;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,YAAY;AAChB","sourcesContent":["/* adjusting the built-in theme */\n\n/* calendar event */\n.calendar_default_event_inner {\n    background: #2e78d6;\n    color: #fff;\n    border: none;\n    border-radius: 5px;\n    font-size: 10pt;\n    padding: 5px;\n    opacity: 0.8;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
