// This file exports the function that asks the backend database for the list of user permissions and saves the data to the global store
// Gets called within ComponentDidMount of each major component of the app

import UserDataService from './services/user-activities';

export async function getUserPermissions(user_id, token) {
    //console.log(user_id);
    //console.log(token);

    return new Promise ((resolve, reject) => {
        UserDataService.getPermissions(user_id, token).then(response => {
            //console.log(response);
            resolve(response);
        })
        .catch(e => {
            //console.log(e);
            reject(e);
        });
    });
}