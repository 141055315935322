import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Grid,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Paper,
    Pill,
    Modal,
    Select,
    Switch,
    Tooltip,
    Flex,
    Accordion,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import contractClasses from './View-Contracts-and-Software.module.css';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconMenu2, IconFilterX, IconEdit, IconX } from '@tabler/icons-react';

import Navbar from '../../components/Navbar';
//import classes from '../TableSort.module.css';

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
//import Modal from 'react-bootstrap/Modal';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
//import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
//import { Form } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

import HardwareAssetDataService from '../../services/hardware-asset-activities';
import SoftwareAssetDataService from '../../services/software-asset-activities';

class ViewSpecificContract extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contract: {
                /* id: 1,
                customer_id: 2,
                customer_name: "LPA",
                contract_name: "Test Contract",
                description: "Test Description",
                customer_id_number_csn: "123ert",
                serial_number: "abcdefg",
                contract_number: "CNT-9",
                vendor_id: 2,
                vendor_name: "Test Vendor",
                contract_type: "Software License",
                contract_status: "Active",
                cost: 25.99,
                approver_id: 2373,
                approver_name: "Bob Johnson",
                start_date: "2024-03-25",
                end_date: "2025-03-25",
                software_id: 3,
                software_name: "Microsoft 365",
                service_name: "Test Service",
                pricing_model: "Fixed",
                count: 3,
                billing_cycle: "Annual",
                license_type: "Test",
                license_key: "Test Key",
                renewal_status: "Complete",
                managing_tenant_id: 2,
                days_left: 30 */
            },

            people: [],     // this gets populated with the details of users in this tenant - you can later search these values to find a user to assign for notifications
            alreadyAssigned: [],    // people who are already assigned to receive notifications so should not appear in the list of search results
            peopleToDisplay: [],        // these are the entries that will actually be displayed in the notification assignment modal after searching

            greenZoneDays: 90,      // if this number of days or above, display the expiry date in green
            redZoneDays: 60,        // if this number of days or less, display the expiry date in red (everything in between values is warning zone orange)


            show_delete_modal: false,

            show_delete_child_modal: false,

            show_renew_modal: false,

            show_assign_notifications_modal: false,

            showSaving: false,

            searchText: "",         // this is the value entered by the user in the search bar for notification assignments

            typingTimeout: 0,    // We need to wait for delay before sending search query to backend
            //resetOffsetTimeout: 0,  // handles the delay in changing ticket offset to 0 while user is typing

            childIdChosenToDelete: 0,

            attachments: [],

            accordion_details: [],  // this will contain an array of contract items just the name, id number and end_date for display in accordion items

            child_contract_to_display: [],        // this will have an array with just one child contract info to display (the one actively shown) 

            main_email_address: "",

            /*
            [
                {
                    contract: {

                    },

                    attachments: {

                    }
                }
            ]
            */
           
        }
    }

    componentDidMount() {

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.setState({
                        showSaving: false
                    });

                    this.props.ACCESSTOKEN(token);
                    this.getUserList(token, this.props.loggedStatus.id, this.props.loggedStatus.primary_tenant_id).then(response => {

                        console.log("Response from get users: ", response);

                        this.setState({
                            people: response.results
                        });
                    
                        SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, this.props.match.params.id).then(response => {
                            console.log(response);
                            if(response.data.results && response.data.results.length > 0) {
                                this.setState({
                                    contract: response.data.results[0],
                                    
                                });
                            }

                            if(response.data.attachments && response.data.attachments.length > 0) {
                                console.log("Attachments returned!");
                                this.setState({
                                    attachments: [...response.data.attachments]
                                });
                            }

                            if(response.data.assigned_user_ids && response.data.assigned_user_ids.length > 0) {
                                this.setState({
                                    alreadyAssigned: response.data.assigned_user_ids
                                });
                            }

                            if(response.data.children && response.data.children.length > 0) {
                                // this contract has children so we need to load up their details into the state
                                let childCount = response.data.children.length
                                for(let c=0; c < childCount; c++) {
                                    SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, response.data.children[c].id).then(response => {
                                        if(response.data.results && response.data.results.length > 0) {
                                            this.setState({
                                                accordion_details: [...this.state.accordion_details, {contract: {
                                                    id: response.data.results[0].id,
                                                    contract_name: response.data.results[0].contract_name,
                                                    end_date: response.data.results[0].end_date
                                                }}]
                                                //child_contracts: [...this.state.child_contracts, {contract: response.data.results[0], attachments: response.data.attachments}]
                                            });
                                        }

                                        if(c === childCount - 1) {
                                            console.log("After children added, value of accordion details state is: ", this.state.accordion_details);

                                            //console.log("To access a single child: ", this.state.child_contracts[0].contract.id);
                                        }
                                    })
                                }
                            }

                            if(response.data.main_email_address && response.data.main_email_address !== null) {
                                this.setState({
                                    main_email_address: response.data.main_email_address
                                });
                            }
                        });
                    });
                }
            });
        }
    }


    openDeleteModal() {
        this.setState({
            show_delete_modal: true
        });
    }

    closeDeleteModal() {
        this.setState({
            show_delete_modal: false
        });
    }

    openDeleteChildModal(id) {
        this.setState({
            show_delete_child_modal: true,
            childIdChosenToDelete: id
        });
    }

    closeDeleteChildModal() {
        this.setState({
            show_delete_child_modal: false,
            childIdChosenToDelete: 0
        });
    }

    openRenewModal() {
        this.setState({
            show_renew_modal: true
        });
    }

    closeRenewModal() {
        this.setState({
            show_renew_modal: false
        });
    }


    openAssignNotificationsModal() {
        this.setState({
            show_assign_notifications_modal: true,
            searchText: "",
            peopleToDisplay: []
        });
    }

    closeAssignNotificationsModal() {
        this.setState({
            show_assign_notifications_modal: false,
            searchText: "",
            peopleToDisplay: []
        });
    }

    getUserList(token, asker_id, tenant_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getUsersAddAssetVersion(token, asker_id, tenant_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    filterPeople = (people, searchText) => {
        if (!searchText) return people; // Return all if search is empty
      
        const lowerSearchText = searchText.toLowerCase();
        const assignedIds = new Set(this.state.alreadyAssigned.map(person => person.user_id_to_notify));
      
        return people.filter(
          ({ id, email, full_name }) =>
            !assignedIds.has(id) && // Exclude already assigned people
            (email.toLowerCase().includes(lowerSearchText) ||
            full_name.toLowerCase().includes(lowerSearchText))
        );
    };

    // using arrow notation in onChangeSearchText so we can reference this.state within the internal timeout function call
    // function will execute a search after user has stopped typing for 1.5 seconds
    onChangeSearchText = (e) => {
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        // if (this.state.resetOffsetTimeout) {
        //     clearTimeout(this.state.resetOffsetTimeout);
        // }

        this.setState({
            searchText: e.target.value,

            // resetOffsetTimeout: setTimeout(() => {
            //     this.resetTicketOffset();   // reset the ticket offset in state back to 0 (they have changed their search criteria so new list)
            // }, 1500),

            typingTimeout: setTimeout(() => {

                console.log("Get Users Here");

                if(this.state.searchText !== "") {
                    this.setState({
                        peopleToDisplay: this.filterPeople(this.state.people, this.state.searchText)
                    });
                }

                else {
                    this.setState({
                        peopleToDisplay: []
                    });
                }
                //this.getAssignedTickets();
                // if(this.state.selectedFilterTicketCategory === "All Categories"){    // user has no category selected but is searching        
                //     this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);    // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                // }

                // else if(this.state.searchText === "") {    // User emptied search bar, but still has category selected
                //     this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => { // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                //         this.setState({     // save the new tickets and inform state that we asked for first 10
                //             tickets: response.results,
                //             rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                //             ticketOffsetValue: 10
                //         });
                //     })
                //     .catch(e => {
                //         console.log(e);
                //     });
                // }

                // else {  // User has both category and search bar letters
                //     this.searchKnowledgebaseByCategory(this.state.searchText, 0, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken); // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                // }
            }, 1500)
        });
    }

    // this version of delete contract is for the parent contract, so we also need to delete any children first
    deleteContract() {
        this.setState({
            show_delete_modal: false,
            showSaving: true
        });

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
            this.props.ACCESSTOKEN(token);

            if(this.state.accordion_details.length > 0) {
                // first we need to loop through the child contracts and delete them - once we're sure all children are deleted, then we can delete the actual parent
                // child contract ids are stored in the accordion_details state array variable
                for(let z = 0; z < this.state.accordion_details.length; z++) {
                    SoftwareAssetDataService.deleteContract(token, this.state.accordion_details[z].contract.id, this.props.loggedStatus.id, this.state.contract.managing_tenant_id).then(response => {
                        if(z === this.state.accordion_details.length - 1) {
                            // this was the last child, so go ahead and delete the actual parent
                            SoftwareAssetDataService.deleteContract(token, this.props.match.params.id, this.props.loggedStatus.id, this.state.contract.managing_tenant_id).then(response => {
                                // after deleting the contract, navigate back to the software assets list screen
                                this.setState({
                                    showSaving: false
                                });

                                this.props.history.push(`/softwareAssets`);
                            });
                        }
                    })
                }
            }

            else {
                // there were no children associated with this contract, so delete the contract outright
                SoftwareAssetDataService.deleteContract(token, this.props.match.params.id, this.props.loggedStatus.id, this.state.contract.managing_tenant_id).then(response => {
                    // after deleting the contract, navigate back to the software assets list screen
                    this.setState({
                        showSaving: false
                    });

                    this.props.history.push(`/softwareAssets`);
                });
            }

        });
    }

    deleteChildContract() {
        this.setState({
            show_delete_child_modal: false,
            showSaving: true
        });

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
            this.props.ACCESSTOKEN(token);

            SoftwareAssetDataService.deleteContract(this.props.loggedStatus.accessToken, this.state.childIdChosenToDelete, this.props.loggedStatus.id, this.state.contract.managing_tenant_id).then(response => {
                // after deleting the contract, navigate back to the software assets list screen
                this.setState({
                    showSaving: false,
                    accordion_details: []
                }, () => {
                    //this.props.history.push(`/softwareAssets`);
                    // in this case we want to refetch the child contract list
                    SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, this.props.match.params.id).then(response => {
                        if(response.data.children && response.data.children.length > 0) {
                            // this contract has children so we need to load up their details into the state
                            let childCount = response.data.children.length
                            for(let c=0; c < childCount; c++) {
                                SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, response.data.children[c].id).then(response => {
                                    if(response.data.results && response.data.results.length > 0) {
                                        this.setState({
                                            accordion_details: [...this.state.accordion_details, {contract: {
                                                id: response.data.results[0].id,
                                                contract_name: response.data.results[0].contract_name,
                                                end_date: response.data.results[0].end_date
                                            }}]
                                            //child_contracts: [...this.state.child_contracts, {contract: response.data.results[0], attachments: response.data.attachments}]
                                        });
                                    }

                                    if(c === childCount - 1) {
                                        console.log("After children added, value of accordion details state is: ", this.state.accordion_details);

                                        //console.log("To access a single child: ", this.state.child_contracts[0].contract.id);
                                    }
                                })
                            }
                        }

                    })
                });
            });
        });
    }

    handleAccordionChange = (value) => {
        console.log(value);
        if(value !== null) {
            this.setState({
                child_contract_to_display: []
            }, () => {
                let id = parseInt(value);
                this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
                    this.props.ACCESSTOKEN(token);

                    SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, id).then(response => {
                        this.setState({
                            child_contract_to_display: [{contract: response.data.results[0], attachments: response.data.attachments}]
                        });
                    })
                })
            })
        }

        else {
            this.setState({
                child_contract_to_display: []
            });
        }
    }

    renewContract() {
        // create a new child contract based off of the current parent
        let data = {
            id: parseInt(this.props.match.params.id)
        };

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
            this.props.ACCESSTOKEN(token);

            SoftwareAssetDataService.renewContract(token, data).then(response => {
                if(response.data.results === "Update Successful!") {
                    // we've created the record, now they need to be taken to the edit contract screen to change details for the new parent
                    // when they return to this page, it should handle getting the new child contract list, etc.
                    this.navigateToEditScreen();
                }
            })
            .catch(error => {
                console.log(error);
            });

        });
    }

    reverseContractRenewal() {
        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
            this.props.ACCESSTOKEN(token);
            SoftwareAssetDataService.reverseContractRenewal(token, this.props.match.params.id).then(response => {
                if(response.data.results === "Done!") {
                    // essentially, refresh the data displayed
                    this.setState({
                        contract: {},
                        attachments: [],
                        alreadyAssigned: [],
                        accordion_details: [],
                        show_delete_modal: false,
                        showSaving: true
                    }, () => {
                        SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, this.props.match.params.id).then(response => {
                            console.log(response);
                            if(response.data.results && response.data.results.length > 0) {
                                this.setState({
                                    contract: response.data.results[0],
                                    
                                });
                            }

                            if(response.data.attachments && response.data.attachments.length > 0) {
                                console.log("Attachments returned!");
                                this.setState({
                                    attachments: [...response.data.attachments]
                                });
                            }

                            if(response.data.assigned_user_ids && response.data.assigned_user_ids.length > 0) {
                                this.setState({
                                    alreadyAssigned: response.data.assigned_user_ids
                                });
                            }

                            if(response.data.children && response.data.children.length > 0) {
                                // this contract has children so we need to load up their details into the state
                                let childCount = response.data.children.length
                                for(let c=0; c < childCount; c++) {
                                    SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, response.data.children[c].id).then(response => {
                                        if(response.data.results && response.data.results.length > 0) {
                                            this.setState({
                                                accordion_details: [...this.state.accordion_details, {contract: {
                                                    id: response.data.results[0].id,
                                                    contract_name: response.data.results[0].contract_name,
                                                    end_date: response.data.results[0].end_date
                                                }}]
                                                //child_contracts: [...this.state.child_contracts, {contract: response.data.results[0], attachments: response.data.attachments}]
                                            });
                                        }

                                        if(c === childCount - 1) {
                                            console.log("After children added, value of accordion details state is: ", this.state.accordion_details);
                                            this.setState({
                                                showSaving: false
                                            });
                                            //console.log("To access a single child: ", this.state.child_contracts[0].contract.id);
                                        }
                                    })
                                }
                            }

                            else {
                                // no children, so we're done
                                this.setState({
                                    showSaving: false
                                });
                            }
                        });
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    assignToNotifications(userId) {
        let data = {
            userId: userId,
            contractId: this.props.match.params.id
        };

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
            this.props.ACCESSTOKEN(token);

            SoftwareAssetDataService.assignToNotifications(token, data).then(response => {
                if(response.data.results === "Added!") {
                    // update the assigned list to include this new person
                    this.setState({
                        alreadyAssigned: [...this.state.alreadyAssigned, {user_id_to_notify: userId}]
                    }, () => {
                        this.setState({
                            peopleToDisplay: this.filterPeople(this.state.people, this.state.searchText)
                        });
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
        });
    }

    removeFromAssigned = (alreadyAssigned, userId) => {
        return alreadyAssigned.filter(person => person.user_id_to_notify !== userId);
    }

    removeFromNotifications(userId) {
        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then(token => {
            this.props.ACCESSTOKEN(token);

            SoftwareAssetDataService.removeFromNotifications(token, this.props.match.params.id, userId).then(response => {
                if(response.data.results === "Done!") {
                    // remove this person from the already assigned array
                    this.setState({
                        alreadyAssigned: this.removeFromAssigned(this.state.alreadyAssigned, userId)
                    }, () => {
                        if(this.state.searchText !== "") {
                            this.setState({
                                peopleToDisplay: this.filterPeople(this.state.people, this.state.searchText)
                            });
                        }

                        else {
                            this.setState({
                                peopleToDisplay: []
                            });
                        }
                    });
                }
            })
            .catch(error => {
                console.log(error);
            });
        })
        .catch(error => {
            console.log(error);
        });
    }

    navigateToEditScreen() {
        this.props.history.push(`/editContract/${this.props.match.params.id}`);
    }

    navigateToEditChildScreen(id) {
        this.props.history.push(`/editContract/${id}`);
    }


    render() {
        if (!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_assets /* && !this.props.userPermissions.can_edit_assets && !this.props.userPermissions.can_add_assets && !this.props.userPermissions.can_delete_assets */)) {
            return (
                <Redirect to='/' />
            );
        }

        return (
            <>
                <Navbar pageTitle={`Contract Details - ${this.state.contract.contract_name}`} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                <Container fluid pt="20px" >
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Grid>
                            <Grid.Col span={10}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    {/* <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Name</Table.Td>
                                        <Table.Td c="blue">Test Contract</Table.Td>
                                    </Table.Tr> */}
                                    <Table.Tr>
                                        <Table.Td style={{width: "10%"}}>Status</Table.Td>
                                        <Table.Td><Pill className={this.state.contract.contract_status === "Active" ? [contractClasses.rootActive, contractClasses.labelActive] : [contractClasses.rootInActive, contractClasses.labelInActive]}>{this.state.contract.contract_status}</Pill></Table.Td>
                                    </Table.Tr>
                                    {/* <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Type</Table.Td>
                                        <Table.Td c="blue">Software License</Table.Td>
                                    </Table.Tr> */}
                                    <Table.Tr>
                                        <Table.Td style={{width: "10%"}}>Date of Expiry</Table.Td>
                                            {this.state.contract.days_left >= this.state.greenZoneDays &&
                                                <Table.Td style={{cursor: "pointer", color: "green"}}>
                                                    {this.state.contract.end_date !== null ? 
                                                        <Pill className={[contractClasses.rootActive, contractClasses.labelActive]}>{this.state.contract.end_date}</Pill>
                                                        :
                                                        undefined
                                                    }
                                                </Table.Td>
                                            }
                                            {this.state.contract.days_left < this.state.greenZoneDays && this.state.contract.days_left > this.state.redZoneDays && 
                                                <Table.Td style={{cursor: "pointer", color: "orange"}}>
                                                    {this.state.contract.end_date !== null ? 
                                                        <Pill className={[contractClasses.rootWarningZone, contractClasses.labelWarningZone]}>{this.state.contract.end_date}</Pill>
                                                        :
                                                        undefined
                                                    }
                                                </Table.Td>
                                            }
                                            {this.state.contract.days_left <= this.state.redZoneDays && this.state.contract.days_left > 0 &&
                                                <Table.Td style={{cursor: "pointer", color: "red"}}>
                                                    {this.state.contract.end_date !== null ? 
                                                        <Pill className={[contractClasses.rootInActive, contractClasses.labelInActive]}>{this.state.contract.end_date}</Pill>
                                                        :
                                                        undefined
                                                    }
                                                </Table.Td>
                                            }
                                            {this.state.contract.days_left <= 0 && 
                                                <Table.Td style={{cursor: "pointer"}}>
                                                {this.state.contract.end_date !== null ?
                                                    <Pill className={[contractClasses.rootExpired, contractClasses.labelExpired]}>{this.state.contract.end_date}</Pill>
                                                    :
                                                    undefined
                                                }
                                                </Table.Td>
                                            }
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td style={{width: "10%"}}>
                                            {this.props.userPermissions.can_edit_assets ?
                                                <span>Notify  <IconEdit className="roleYesText" style={{cursor: "pointer", marginTop: "-5px"}} onClick={() => this.openAssignNotificationsModal()}/></span>
                                                :
                                                <span>Notify</span>
                                            }
                                        </Table.Td>
                                        <Table.Td>
                                            {this.state.main_email_address !== "" && this.state.alreadyAssigned && this.state.alreadyAssigned.length > 0 ?
                                                <span>
                                                    <span className="roleYesText">{this.state.main_email_address}</span>
                                                    <span>, </span>
                                                </span>
                                                :
                                                <span className="roleYesText">{this.state.main_email_address}</span>
                                            }
                                             
                                            {this.state.alreadyAssigned && this.state.alreadyAssigned.map(({ user_id_to_notify }) => {
                                                const person = this.state.people.find(({ id }) => id === user_id_to_notify);
                                                return person
                                                  ? `${person.full_name} (${person.email})` : null;
                                              }).filter(Boolean)
                                              .join(", ") // Join into a comma-separated string
                                            }
                                        </Table.Td>
                                    </Table.Tr>
                                    {/* <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Cost</Table.Td>
                                        <Table.Td c="blue">$25.00</Table.Td>
                                    </Table.Tr> */}
                                </Table>
                            </Grid.Col>
                            <Grid.Col span={2} align="right">
                                <Group justify="flex-end" gap="xl">
                                    {this.props.userPermissions.can_edit_assets && 
                                        <Button color="teal" onClick={() => this.openRenewModal()}>Renew</Button>

                                    }
                                    {this.props.userPermissions.can_edit_assets &&
                                        <Button onClick={() => this.navigateToEditScreen()}><IconEdit/></Button>
                                    }
                                    {this.props.userPermissions.can_delete_assets &&
                                        <Button color="red" onClick={() => this.openDeleteModal()}><IconX/></Button>
                                    }
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <div style={{marginTop: "20px"}}>
                            <Grid>
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Basic Info</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Name</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_name !== null ? this.state.contract.contract_name : "-"}</Table.Td>   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Company Name</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.customer_name}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Number</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_number !== null ? this.state.contract.contract_number : "-"}</Table.Td>                                                   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Software</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.software_name !== null ? this.state.contract.software_name : "-"}</Table.Td>    
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Vendor</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.vendor_name !== null ? this.state.contract.vendor_name : "-"}</Table.Td>  
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Type</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_type !== null ? this.state.contract.contract_type : "-"}</Table.Td>  
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Status</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_status !== null ? this.state.contract.contract_status : "-"}</Table.Td>   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Start Date</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.start_date !== null ? this.state.contract.start_date : "-"}</Table.Td>   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>End Date</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.end_date !== null ? this.state.contract.end_date : "-"}</Table.Td>                                                      
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                            
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Pricing and Renewal</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Cost</Table.Td>                                     
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.cost !== null ? `$ ${this.state.contract.cost}` : "-"}</Table.Td>                                                       
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Pricing Model</Table.Td>                                     
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.pricing_model !== null ? this.state.contract.pricing_model : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Billing Cycle</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.billing_cycle !== null ? this.state.contract.billing_cycle : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Renewal Status</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.renewal_status !== null ? this.state.contract.renewal_status : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Term</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.term !== null ? this.state.contract.term : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>{`Cancel Window`}</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.cancel_window !== null ? `${this.state.contract.cancel_window} days` : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>{`Remind Me`}</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.remind_me !== null ? `${this.state.contract.remind_me} days` : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Turn Notifications Red</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.red_window !== null ? `${this.state.contract.red_window} days`: "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Notifications Disabled</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.is_resolved === 0 ? "No" : "Yes"}</Table.Td>
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                            
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>License</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>License Type</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.license_type !== null ? this.state.contract.license_type : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>License Key</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.license_key !== null ? this.state.contract.license_key : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Count</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.count !== null ? this.state.contract.count : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Misc</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>{"Customer Id Number"}</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.customer_id_number_csn !== null ? this.state.contract.customer_id_number_csn : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Serial Number</Table.Td>                                                
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.serial_number !== null ? this.state.contract.serial_number : "-"}</Table.Td>                                                       
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Approver</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">
                                                        {this.state.contract.approver_id !== null ? 
                                                            this.state.contract.approver_middle_name === null ?
                                                                `${this.state.contract.approver_first_name} ${this.state.contract.approver_last_name}`
                                                                :
                                                                `${this.state.contract.approver_first_name} ${this.state.contract.approver_middle_name} ${this.state.contract.approver_last_name}`
                                                            : 
                                                            "-"
                                                        }
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Service Name</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.service_name !== null ? this.state.contract.service_name : "-"}</Table.Td>
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                            </Grid>
                        </div>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Text td="underline" style={{cursor: "default", marginBottom: "10px"}}>Description</Text>
                        <div className="roleYesText" style={{cursor: "default"}}>{this.state.contract.description}</div>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Text td="underline" style={{cursor: "default", marginBottom: "10px"}}>Attachments</Text>
                            <div>
                                <ol>
                                    {this.state.attachments && this.state.attachments.map((attachment, index) => ( 
                                        <li key={`attachment${index}`} className="gothamNarrowLightFont">
                                            <a href={attachment.attachment_url} target="_blank" rel="noopener noreferrer">{attachment.filename}</a>
                                        </li>
                                    ))}
                                </ol>
                            </div>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Text td="underline" style={{cursor: "default", marginBottom: "10px"}}>Previous Contracts</Text>
                        <Accordion onChange={this.handleAccordionChange}>
                            {this.state.accordion_details && this.state.accordion_details.sort((a, b) => b.contract.id - a.contract.id).map((child, index) => (
                                <Accordion.Item key={`child-${index}`} value={child.contract.id.toString()}>
                                    {child.contract.end_date !== null ? 
                                        <Accordion.Control>{`${child.contract.contract_name} - ${child.contract.end_date}`}</Accordion.Control>
                                        :
                                        <Accordion.Control>{`${child.contract.contract_name}`}</Accordion.Control>
                                    }
                                    <Accordion.Panel>
                                        {this.state.child_contract_to_display && this.state.child_contract_to_display.length > 0 ?
                                            <div>
                                                <div style={{marginBottom: "15px"}}>
                                                    <Group justify="flex-end" gap="xl">
                                                        {this.props.userPermissions.can_edit_assets &&
                                                            <Button onClick={() => this.navigateToEditChildScreen(child.contract.id)}><IconEdit/></Button>
                                                        }
                                                        {this.props.userPermissions.can_delete_assets &&
                                                            <Button color="red" onClick={() => this.openDeleteChildModal(child.contract.id)}><IconX/></Button>
                                                        }
                                                    </Group>
                                                </div>
                                                <Grid>
                                                    <Grid.Col span={3}>
                                                        <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                            <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Basic Info</Text>
                                                            <Table withRowBorders={false} style={{cursor: "default"}}>
                                                                <Table.Tbody>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Contract Name</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.contract_name !== null ? this.state.child_contract_to_display[0].contract.contract_name : "-"}</Table.Td>   
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Company Name</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.customer_name}</Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Contract Number</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.contract_number !== null ? this.state.child_contract_to_display[0].contract.contract_number : "-"}</Table.Td>                                                   
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Software</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.software_name !== null ? this.state.child_contract_to_display[0].contract.software_name : "-"}</Table.Td>    
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Vendor</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.vendor_name !== null ? this.state.child_contract_to_display[0].contract.vendor_name : "-"}</Table.Td>  
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Contract Type</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.contract_type !== null ? this.state.child_contract_to_display[0].contract.contract_type : "-"}</Table.Td>  
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Contract Status</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.contract_status !== null ? this.state.child_contract_to_display[0].contract.contract_status : "-"}</Table.Td>   
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>Start Date</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.start_date !== null ? this.state.child_contract_to_display[0].contract.start_date : "-"}</Table.Td>   
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "35%"}}>End Date</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.end_date !== null ? this.state.child_contract_to_display[0].contract.end_date : "-"}</Table.Td>                                                      
                                                                    </Table.Tr>
                                                                </Table.Tbody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid.Col>
                                                
                                                    <Grid.Col span={3}>
                                                        <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                            <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Pricing and Renewal</Text>
                                                            <Table withRowBorders={false} style={{cursor: "default"}}>
                                                                <Table.Tbody>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Cost</Table.Td>                                     
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.cost !== null ? `$ ${this.state.child_contract_to_display[0].contract.cost}` : "-"}</Table.Td>                                                       
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Pricing Model</Table.Td>                                     
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.pricing_model !== null ? this.state.child_contract_to_display[0].contract.pricing_model : "-"}</Table.Td>                                                        
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Billing Cycle</Table.Td>                                                    
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.billing_cycle !== null ? this.state.child_contract_to_display[0].contract.billing_cycle : "-"}</Table.Td>                                                        
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Renewal Status</Table.Td>                                                    
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.renewal_status !== null ? this.state.child_contract_to_display[0].contract.renewal_status : "-"}</Table.Td>                                                        
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Term</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.term !== null ? this.state.child_contract_to_display[0].contract.term : "-"}</Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>{`Cancel Window`}</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.cancel_window !== null ? `${this.state.child_contract_to_display[0].contract.cancel_window} days` : "-"}</Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>{`Remind Me`}</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.remind_me !== null ? `${this.state.child_contract_to_display[0].contract.remind_me} days` : "-"}</Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Turn Notifications Red</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.red_window !== null ? `${this.state.child_contract_to_display[0].contract.red_window} days`: "-"}</Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Notifications Disabled</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.is_resolved === 0 ? "No" : "Yes"}</Table.Td>
                                                                    </Table.Tr>
                                                                </Table.Tbody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid.Col>
                                                
                                                    <Grid.Col span={3}>
                                                        <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                            <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>License</Text>
                                                            <Table withRowBorders={false} style={{cursor: "default"}}>
                                                                <Table.Tbody>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>License Type</Table.Td>                                                    
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.license_type !== null ? this.state.child_contract_to_display[0].contract.license_type : "-"}</Table.Td>                                                        
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>License Key</Table.Td>                                                    
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.license_key !== null ? this.state.child_contract_to_display[0].contract.license_key : "-"}</Table.Td>                                                        
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Count</Table.Td>                                                    
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.count !== null ? this.state.child_contract_to_display[0].contract.count : "-"}</Table.Td>                                                        
                                                                    </Table.Tr>
                                                                </Table.Tbody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid.Col>
                                                    <Grid.Col span={3}>
                                                        <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                            <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Misc</Text>
                                                            <Table withRowBorders={false} style={{cursor: "default"}}>
                                                                <Table.Tbody>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>{"Customer Id Number"}</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.customer_id_number_csn !== null ? this.state.child_contract_to_display[0].contract.customer_id_number_csn : "-"}</Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Serial Number</Table.Td>                                                
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.serial_number !== null ? this.state.child_contract_to_display[0].contract.serial_number : "-"}</Table.Td>                                                       
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Approver</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">
                                                                            {this.state.child_contract_to_display[0].contract.approver_id !== null ? 
                                                                                this.state.child_contract_to_display[0].contract.approver_middle_name === null ?
                                                                                    `${this.state.child_contract_to_display[0].contract.approver_first_name} ${this.state.child_contract_to_display[0].contract.approver_last_name}`
                                                                                    :
                                                                                    `${this.state.child_contract_to_display[0].contract.approver_first_name} ${this.state.child_contract_to_display[0].contract.approver_middle_name} ${this.state.child_contract_to_display[0].contract.approver_last_name}`
                                                                                : 
                                                                                "-"
                                                                            }
                                                                        </Table.Td>
                                                                    </Table.Tr>
                                                                    <Table.Tr>
                                                                        <Table.Td style={{width: "50%"}}>Service Name</Table.Td>
                                                                        <Table.Td className="roleYesText" align="right">{this.state.child_contract_to_display[0].contract.service_name !== null ? this.state.child_contract_to_display[0].contract.service_name : "-"}</Table.Td>
                                                                    </Table.Tr>
                                                                </Table.Tbody>
                                                            </Table>
                                                        </Paper>
                                                    </Grid.Col>
                                                </Grid>
                                                <div style={{marginTop: "25px"}}></div>
                                                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                                                        <Text td="underline" style={{cursor: "default", marginBottom: "10px"}}>Description</Text>
                                                        <div className="roleYesText" style={{cursor: "default"}}>{this.state.child_contract_to_display[0].contract.description}</div>
                                                    </Paper>
                                                    <div style={{marginTop: "25px"}}></div>
                                                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                                                        <Text td="underline" style={{cursor: "default", marginBottom: "10px"}}>Attachments</Text>
                                                            <div>
                                                                <ol>
                                                                    {this.state.child_contract_to_display[0].attachments && this.state.child_contract_to_display[0].attachments.map((attachment, index) => ( 
                                                                        <li key={`attachment${index}`} className="gothamNarrowLightFont">
                                                                            <a href={attachment.attachment_url} target="_blank" rel="noopener noreferrer">{attachment.filename}</a>
                                                                        </li>
                                                                    ))}
                                                                </ol>
                                                            </div>
                                                    </Paper>
                                            </div>
                                            :
                                            <div>Loading...</div>
                                        }
                                    </Accordion.Panel>
                                </Accordion.Item>
                            ))}
                        </Accordion>
                    </Paper>
                </Container>

                <Modal
                    opened={this.state.show_delete_modal}
                    onClose={() => this.closeDeleteModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    {this.state.accordion_details && this.state.accordion_details.length > 0 ? 
                        <>
                            <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl"><div>Are you sure you want to delete this contract? Since this is a parent contract all child contracts will also be removed.</div><div style={{marginTop: "10px"}}>Note: Reverse Renewal will delete the current parent contract and return the most recent contract before that to parent status.</div></Text></div>
                            <div style={{textAlign: 'center', marginTop: "50px"}}>
                                <span style={{marginRight: "50px"}}><Button variant="filled" color="gray" onClick={() => this.closeDeleteModal()}>Cancel</Button></span>
                                <span style={{marginRight: "50px"}}><Button variant="filled" color="orange" onClick={() => this.reverseContractRenewal()}>Reverse Renewal</Button></span>
                                <span><Button color="red" onClick={() => this.deleteContract()}>Delete</Button></span>
                            </div>
                        </>
                        :
                        <>
                            <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Are you sure you want to delete this contract? Since this is a parent contract all child contracts will also be removed.</Text></div>
                            <div style={{textAlign: 'center', marginTop: "50px"}}>
                                <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeDeleteModal()}>Cancel</Button></span>
                                <span><Button color="red" onClick={() => this.deleteContract()}>Delete</Button></span>
                            </div>
                        </>
                    }
                </Modal>

                <Modal
                    opened={this.state.show_delete_child_modal}
                    onClose={() => this.closeDeleteChildModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Are you sure you want to delete this child contract?</Text></div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeDeleteChildModal()}>Cancel</Button></span>
                        <span><Button color="red" onClick={() => this.deleteChildContract()}>Delete</Button></span>
                    </div>
                </Modal>

                {/* This modal is for the saving screen */}
                <Modal
                    opened={this.state.showSaving}
                    onClose={() => { this.setState({ showSaving: false }) }}
                    withCloseButton={false}
                    size="xl"
                    centered
                >
                    <Modal.Body>
                        <div className="gothamNarrowLightFont" style={{ cursor: "default", textAlign: "center" }}>Saving... Please wait.</div>
                    </Modal.Body>

                </Modal>

                <Modal
                    opened={this.state.show_renew_modal}
                    onClose={() => this.closeRenewModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Are you sure you want to renew this contract? This will convert the current contract into a child contract.</Text></div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeRenewModal()}>Cancel</Button></span>
                        <span><Button color="teal" onClick={() => this.renewContract()}>Renew</Button></span>
                    </div>
                </Modal>

                {/* This modal is for the email notification assignments */}
                <Modal
                    opened={this.state.show_assign_notifications_modal}
                    onClose={() => this.closeAssignNotificationsModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Notification Assignments For This Contract</Text></div>
                    <div style={{textAlign: 'center'}}>
                        <div style={{marginTop: "15px", marginBottom: "30px"}}>
                            <div className="roleYesText" style={{marginBottom: "5px"}}>Always Assigned</div>
                            <div>{this.state.main_email_address}</div>
                        </div>
                        <div style={{marginTop: "15px", marginBottom: "15px", textAlign: "center"}}>
                            <div className="roleYesText" style={{marginBottom: "15px", textAlign: "center"}}>Additional Assignments</div>  
                            {this.state.alreadyAssigned && this.state.alreadyAssigned.map(({ user_id_to_notify }) => {
                                const person = this.state.people.find(({ id }) => id === user_id_to_notify);
                                return person
                                    ? <div style={{textAlign: "left"}}><span style={{ paddingRight: "10px"}}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)", cursor: "pointer" }} icon={faTimes} onClick={() => this.removeFromNotifications(person.id)} /></span>
                                    {`${person.full_name} (${person.email})`}
                                    </div> : null;
                                }).filter(Boolean)
                            }
                        </div>
                        <TextInput
                            placeholder="Search"
                            mb="md"
                            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            value={this.state.searchText}
                            onChange={this.onChangeSearchText}
                        />
                        {this.state.peopleToDisplay && this.state.peopleToDisplay.map((person) => (
                            <Paper withBorder p="md" radius="md" display="block" m="5px" style={{cursor: "pointer"}} onClick={() => this.assignToNotifications(person.id)}>
                                {/* <span style={{ paddingRight: "10px"}}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)", cursor: "pointer" }} icon={faTimes} onClick={() => this.openDeleteModal(file.id)} />
                                </span> */}
                                <div className="roleYesText">{person.full_name}</div>
                                <div>{person.email}</div>
                            </Paper>
                        ))}
                        {/* <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeAssignNotificationsModal()}>Cancel</Button></span> */}
                        <div style={{marginTop: "30px"}}><Button onClick={() => this.closeAssignNotificationsModal()}>Done</Button></div>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        softwareAssetFilter: state.softwareAssetFilter
    };
}


export default connect(mapStateToProps, {
    UPDATE_ACTIVE_LINK,
    ACCESSTOKEN,
    LOGOUT
})(ViewSpecificContract);