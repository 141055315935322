// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.widgetHeader {
    font-size: 15px;
    text-align: center;
    margin-bottom: 15px;
}

.widgetDataText {
    font-size: 48px;
    text-align: center; 
    font-weight: bold; 
    color: #228be6;
}

.widgetDataTextGreen {
    font-size: 48px;
    text-align: center; 
    font-weight: bold; 
    color: green;
}

.widgetDataTextRed {
    font-size: 48px;
    text-align: center; 
    font-weight: bold; 
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/Dashboard-Widgets/Widgets.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,UAAU;AACd","sourcesContent":[".widgetHeader {\n    font-size: 15px;\n    text-align: center;\n    margin-bottom: 15px;\n}\n\n.widgetDataText {\n    font-size: 48px;\n    text-align: center; \n    font-weight: bold; \n    color: #228be6;\n}\n\n.widgetDataTextGreen {\n    font-size: 48px;\n    text-align: center; \n    font-weight: bold; \n    color: green;\n}\n\n.widgetDataTextRed {\n    font-size: 48px;\n    text-align: center; \n    font-weight: bold; \n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
