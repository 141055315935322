import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FormControl from "react-bootstrap/FormControl";
import "bootstrap/dist/css/bootstrap.min.css";

import { connect /*, useDispatch*/ } from 'react-redux';
import { store } from '../app/store';

//import AssignTechsTicketsCalendarService from '../services/assign-techs_tickets-calendar-activities';

import UserDataService from "../services/user-activities";

//import { UPDATE_ACTIVE_ASSIGNMENT, ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN } from "../features/actions/My-Assigned-Tickets-Actions";
import { UPDATE_ACTIVE_REQUESTER, ALERT_REQUESTER_CHANGE_FROM_DROPDOWN, REQUESTER_CHANGE_RESOLVED } from "../features/actions/Ticket-Requester-Actions";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href="#requesterInfo"
      ref={ref}
      id="selectRequesterButton"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));
  
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
      const [value, setValue] = useState('');
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto gothamNarrowLightFont"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled" style={{maxHeight: "200px", overflowX: "hidden", overflowY: "scroll"}}>
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().includes(value),
            )}
          </ul>
        </div>
      );
    },
);


// function resetDepartment() {
//   return {
//     type: 'RESET_DEPARTMENT'
//   }
// }

function updateTicketRequester(tenant_id, requester_name, requester_id, requester_email) {
  return {
    type: 'UPDATE_ACTIVE_REQUESTER',
    payload: {tenantId: tenant_id, requesterName: requester_name, requesterId: requester_id, requesterEmail: requester_email}
  }
}

// will be used to signal the hosting component to trigger its component did update and ask for the newly selected requester's asset details
function alertAboutRequesterChange() {
  return {
    type: 'ALERT_REQUESTER_CHANGE_FROM_DROPDOWN'
  }
}

function handleRequesterChange(requester_to_change_to) {
  let state = store.getState();


  // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
  if(requester_to_change_to.id !== state.ticketRequester.requester_id_selected) {

    //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
    store.dispatch(updateTicketRequester(requester_to_change_to.tenantId, requester_to_change_to.name, requester_to_change_to.id, requester_to_change_to.email));
    store.dispatch(alertAboutRequesterChange()); // tell Ticket form to run it's componentDidUpdate fxn and get new assigned asset data
  }

   
}

function RequesterSelector({loggedStatus, ticketRequester}) {
    let state = store.getState();
    //let dispatch = useDispatch();

    const [requesterList, setRequesterList] = React.useState([]);   // create the empty user array in the functional component's state

    React.useEffect(() => {

      if(requesterList.length === 0) {

        // put in the "All default information" into drop down
        //setTechList([...techList, {tech_full_name: "All", id: 0}]);

        //requesterList.push({requester_full_name: "All", id: 0});
        //requesterList.push({requester_full_name: "Unassigned", id: -1});    // backend will recognize -1 as meaning where assigned tech id is null
        //requesterList.push({requester_full_name: "Hello There", id: 1});
        //requesterList.push({requester_full_name: "General Kenobi", id: 2});

        // fetch the list of techs from the backend
        //console.log("Trying to print access token from assigned to drop down:");
        //console.log(loggedStatus.accessToken);


        UserDataService.getRequesterList(ticketRequester.tenant_id_selected, loggedStatus.id, loggedStatus.accessToken)
        .then(response => {
          console.log("Response from backend for requester list was: ");
          console.log(response);

          let updatedRequesterList = [...requesterList]

          if(response.data.results && response.data.results.length > 0 && response.data.results !== "User not allowed to view this tenant") {
            response.data.results.map((requester) => {
              updatedRequesterList.push(requester);
              return updatedRequesterList;   // included to appease compile warning. not actually needed
            });
          }

          setRequesterList(updatedRequesterList);

        })
        .catch(e => {
          console.log(e);
        });
      }

    }, [requesterList, setRequesterList, loggedStatus.id, loggedStatus.accessToken, ticketRequester.tenant_id_selected]);    // any state changes now have the tech list as a dependency to watch out for
    
    return (
      <>
        <Dropdown as={ButtonGroup}>
          <Dropdown.Toggle as={CustomToggle}>{`${state.ticketRequester.requester_name_selected} - (${state.ticketRequester.requester_email_selected})`}</Dropdown.Toggle>
          <Dropdown.Menu as={CustomMenu}>
              {requesterList && requesterList.map((requester) => (
                <Dropdown.Item key={requester.id} onClick={() => handleRequesterChange({name: requester.requester_full_name, id: requester.id, tenantId: requester.primary_tenant_id, email: requester.email})}>{`${requester.requester_full_name} - (${requester.email})`}</Dropdown.Item>
              ))}
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
}


const mapStateToProps = (state) => {
    return {
      loggedStatus: state.logged,
      ticketRequester: state.ticketRequester
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_REQUESTER, ALERT_REQUESTER_CHANGE_FROM_DROPDOWN, REQUESTER_CHANGE_RESOLVED})(RequesterSelector);