import React, { Component } from 'react';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
//import Card from 'react-bootstrap/Card';
//import Nav from 'react-bootstrap/Nav';
import {
    Table,
    Text,
    keys,
    Group,
    UnstyledButton,
    rem,
    Center,
    Divider,
    Flex,
    Collapse,
    Timeline,
    Space,
    Loader,
    Select
} from '@mantine/core';
import dayjs from 'dayjs';

import classes from '../TableSort.module.css';
import { IconSelector, IconChevronDown, IconChevronUp, IconLineDashed } from '@tabler/icons-react';
import HardwareTicketDataService from '../../services/hardware-ticket-activities';
import HoldingTableDataService from '../../services/holding-table-activities';

import AssignTechsTicketsCalendarService from '../../services/assign-techs_tickets-calendar-activities';

import { REPORT_TENANT_CHANGE_RESOLVED } from '../../features/actions/Is-Logged-Actions';

import { DatePickerInput } from '@mantine/dates';
import { store } from '../../app/store';


// const chartData = {
//     labels: ['January', 'February', 'March',
//              'April', 'May'],
//     datasets: [
//       {
//         label: 'Rainfall',
//         backgroundColor: [
//           '#B21F00',
//           '#C9DE00',
//           '#2FDE00',
//           '#00A6B4',
//           '#6800B4'
//         ],
//         hoverBackgroundColor: [
//         '#501800',
//         '#4B5000',
//         '#175000',
//         '#003350',
//         '#35014F'
//         ],
//         data: [65, 59, 80, 81, 56]
//       }
//     ]
//   }

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

function updateTimespan(timespan) {
    return {
        type: 'UPDATE_TIMESPAN',
        payload: timespan
    }
}

function updateTimespanType(type) {
    return {
        type: 'UPDATE_TIMESPAN_TYPE',
        payload: type
    }
}

function updateTimespanAssignedTo(assignedTo) {
    return {
        type: 'UPDATE_TIMESPAN_ASSIGNEDTO',
        payload: assignedTo
    }
}

function handleTimespanChange(timespan) {
    let state = store.getState();

    // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
    if (timespan !== state.reports.timespan) {

        //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
        store.dispatch(updateTimespan(timespan));
    }
    console.log(timespan);
}

function handleTimespanTypeChange(type) {
    let state = store.getState();

    // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
    if (type !== state.reports.timespanType) {

        //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
        store.dispatch(updateTimespanType(type));
    }
    console.log(type);
}

function handleTimespanAssignedToChange(assignedTo) {
    let state = store.getState();

    // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
    if (assignedTo !== state.reports.timespanAssignedTo) {

        //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
        store.dispatch(updateTimespanAssignedTo(assignedTo));
    }
    console.log(assignedTo);
}

class TimespanTickets extends Component {


    constructor(props) {
        super(props);
        let state = store.getState();

        this.getTimespanTickets = this.getTimespanTickets.bind(this);
        this.goToHardwareTicketDetails = this.goToHardwareTicketDetails.bind(this);
        this.goToHoldingTicketDetails = this.goToHoldingTicketDetails.bind(this);

        var timespan = [state.reports.timespan[0] ? dayjs(Date.parse(state.reports.timespan[0])).$d : null, state.reports.timespan[1] ? dayjs(Date.parse(state.reports.timespan[1])).$d : null]

        this.state = {
            timespanTickets: [],
            itemCount: 0,
            timespan: timespan,
            timespanType: state.reports.timespanType,
            timespanAssignedTo: state.reports.timespanAssignedTo,
            techList: []
        };

    }

    setSorting(field) {
        const reversed = field === this.state.sortBy ? !this.state.reverseSortDirection : false;
        const search = this.state.search;
        this.setState({
            reverseSortDirection: reversed,
            sortBy: field,
            sortedData: sortData(this.state.data, { sortBy: field, reversed, search })
        });
    };

    componentDidMount() {
        this.getTimespanTickets();

        AssignTechsTicketsCalendarService.getTechsForMyTicketsTableDropdown(this.props.loggedStatus.id, this.props.loggedStatus.accessToken)
            .then(response => {
                console.log("Response from backend for tech list was: ");
                console.log(response);

                let updatedTechList = []

                response.data.results.map((tech) => {
                    updatedTechList.push(tech);
                    return updatedTechList;   // included to appease compile warning. not actually needed
                });
                this.setState({ techList: [{id: 0, primary_tenant_id: 0, tech_full_name: "All"}, {id: -1, primary_tenant_id: 0, tech_full_name: "Unassigned"}, ...updatedTechList] });

            })
            .catch(e => {
                console.log(e);
                console.log("You're in the CATCH clause of the assigned tech selector component. The line printed above should be the error text");
                console.log("Should hopefully contain the status code of the failed attempt: ", e.response.status);

                if (e.response.status === 401) {   // the token was stale, so try again after the state updates with the new token
                    console.log("The assigned tech selector is asking the API again since the token was stale");
                }
            });
    }

    getHistory(id, timezone_adjustment_factor, accessToken, source) {
        if (this.state[`collapse${id}`]) {
            this.setState({
                [`collapse${id}`]: false
            });
        } else {
            if(source === "Hardware") {
                HardwareTicketDataService.getTicketHistory(id, timezone_adjustment_factor, accessToken)
                .then(response => {
                    console.log("Response from history endpoint was: ", response);
                    this.setState({
                        [`history${id}`]: response.data.results,
                        [`loading${id}`]: true
                    }, () => {
                        this.setState({
                            [`collapse${id}`]: true,
                            [`loading${id}`]: false
                        })
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            }

            else {
                HoldingTableDataService.getTicketHistory(id, timezone_adjustment_factor, accessToken)
                .then(response => {
                    console.log("Response from history endpoint was: ", response);
                    this.setState({
                        [`history${id}`]: response.data.results,
                        [`loading${id}`]: true
                    }, () => {
                        this.setState({
                            [`collapse${id}`]: true,
                            [`loading${id}`]: false
                        })
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            }
        }
    }

    componentDidUpdate() {
        if (this.props.loggedStatus.report_tenant_change_detected) {
            this.props.REPORT_TENANT_CHANGE_RESOLVED();

            // get the new list of timespan tickets
            this.getTimespanTickets();
        }
    }


    // use the global state value for the selected tenant from the report component to get the necessary overdue ticket info
    getTimespanTickets() {
        console.log(this.state.timespan);
        HardwareTicketDataService.getTimespanTickets(this.props.loggedStatus.report_tenant_id_selected, this.state.timespan, this.state.timespanType, this.state.timespanAssignedTo, this.props.loggedStatus.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken)
            .then(response => {
                console.log("Timespan ticket list returned: ", response);
                this.setState({
                    timespanTickets: response.data.results,
                    itemCount: response.data.rowCount
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    goToHardwareTicketDetails(id) {
        this.props.history.push(`/hardwareTickets/${id}`);
    }

    goToHoldingTicketDetails(id) {
        this.props.history.push(`/clarifyTicket/${id}`);
    }


    render() {
        const rows = this.state.timespanTickets.map((ticket) => {
            //if (ticket.source === "Hardware") {
                return <>
                    <Divider />
                    <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                        <Table.Tbody>
                            <Table.Tr key={`${ticket.source}_${ticket.id}`} onClick={() => this.getHistory(ticket.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken, ticket.source)}>
                                {this.state[`loading${ticket.id}`] ?
                                    <>
                                        <Table.Td w="14%"><Loader /></Table.Td>
                                    </>
                                    : <>
                                        {ticket.source === "Hardware" ? 
                                            <Table.Td w="14%">{`# ${ticket.id}`}</Table.Td>
                                            :
                                            <Table.Td w="14%">{`Temp# ${ticket.id}`}</Table.Td>
                                        }
                                        <Table.Td w="14%">{ticket.sla_name ? ticket.sla_name : "Not Yet Set"}</Table.Td>
                                        <Table.Td w="16%">{ticket.asker_name}</Table.Td>
                                        <Table.Td w="16%">{ticket.assigned_tech_name ? ticket.assigned_tech_name : "Unassigned"}</Table.Td>
                                        <Table.Td w="15%">{ticket.formatted_date_time_created}</Table.Td>
                                        <Table.Td w="15%">{ticket.ticket_title}</Table.Td>
                                    </>}
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                    {this.state[`history${ticket.id}`] ?
                        <Table>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Table.Td w="100%" py="0" px="xl">
                                        <Collapse in={this.state[`collapse${ticket.id}`]}>
                                            <Timeline active={this.state[`history${ticket.id}`].length} bulletSize={24} lineWidth={2} my="lg">
                                                {this.state[`history${ticket.id}`].map((event, index) => (
                                                    <Timeline.Item>
                                                        <div>{event.formatted_date_time_created}<IconLineDashed color="#00abfb" />{event.description} by {`${event.creator_first_name} ${event.creator_last_name}`}</div>
                                                    </Timeline.Item>
                                                ))}
                                            </Timeline>
                                        </Collapse>
                                    </Table.Td>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table> : <></>}
                </>
            //}

            // else {
            //     return <>
            //         <Divider />
            //         <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
            //             <Table.Tbody>
            //                 <Table.Tr key={`unclarified_${ticket.id}`} onClick={() => this.getHistory(ticket.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken, ticket.source)}>
            //                     <Table.Td w="14%">{`Temp# ${ticket.id}`}</Table.Td>
            //                     <Table.Td w="14%">{ticket.sla_name ? ticket.sla_name : "Not Yet Set"}</Table.Td>
            //                     <Table.Td w="16%">{ticket.asker_name}</Table.Td>
            //                     <Table.Td w="16%">{ticket.assigned_tech_name ? ticket.assigned_tech_name : "Unassigned"}</Table.Td>
            //                     <Table.Td w="15%">{ticket.formatted_date_time_created}</Table.Td>
            //                     <Table.Td w="15%">{ticket.ticket_title ? ticket.ticket_title : "TBD"}</Table.Td>
            //                 </Table.Tr>
            //             </Table.Tbody>
            //         </Table>
            //     </>
            // }
        })


        return (
            <>
                <Flex
                    gap="lg"
                >
                    <DatePickerInput
                        placeholder="Pick date"
                        w="300px"
                        type="range"
                        value={this.state.timespan}
                        onChange={(e) => {
                            handleTimespanChange(e);
                            this.setState({ timespan: e }, () => this.getTimespanTickets())
                        }}
                        allowDeselect={false}
                    />
                    <Select
                        placeholder="Pick type"
                        value={this.state.timespanType}
                        onChange={(e) => {
                            handleTimespanTypeChange(e);
                            this.setState({ timespanType: e }, () => this.getTimespanTickets())
                        }}
                        data={['All', 'Open', 'Resolved']}
                        allowDeselect={false}
                    />
                    <Select
                        placeholder="Assigned to"
                        value={
                            this.state.techList && this.state.techList.length > 0 && this.state.techList.find(v => v.id === this.state.timespanAssignedTo) &&
                            this.state.techList.find(v => v.id === this.state.timespanAssignedTo).tech_full_name
                        }
                        data={this.state.techList.map((tech) => (tech.tech_full_name))}
                        onChange={(e) => {
                            let newAssignedToId = this.state.techList.find(t => t.tech_full_name === e).id;
                            handleTimespanAssignedToChange(newAssignedToId);
                            this.setState({ timespanAssignedTo: newAssignedToId }, () => this.getTimespanTickets())
                        }}
                        allowDeselect={false}
                    />
                </Flex>
                <Space m="md" />
                <div /*style={{textAlign:"center"}}*/ className="gothamNarrowFont">{`Tickets ( ${this.state.itemCount} )`}</div>
                <Table>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td fw="bold" w="14%">ID</Table.Td>
                            <Table.Td fw="bold" w="14%">Priority</Table.Td>
                            <Table.Td fw="bold" w="16%">Requester</Table.Td>
                            <Table.Td fw="bold" w="16%">Assigned To</Table.Td>
                            <Table.Td fw="bold" w="15%">Time Created</Table.Td>
                            <Table.Td fw="bold" w="15%">Title</Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
                {rows.length > 0 ? (
                    rows
                ) : (
                    <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td>
                                    <Text fw={500} ta="center">
                                        Nothing found
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav    // Use global state to control which link is highlighted
    };
}

export default connect(mapStateToProps, { REPORT_TENANT_CHANGE_RESOLVED })(TimespanTickets);