import http from "../http-common";

class MonitoringDataService {

    get(tenant_id, asker_id, token) {
        return http.get(`/monitoring/iFrameUrls?tenantId=${tenant_id}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of operating systems and their version numbers along with device count of each from Tanium
    // tenant id and asker id to be used later for multi-tenant option
    // returns [{osName: "Windows 10 Home", count: 1}, {...}]
    getOSVersions(tenant_id, tenant_name, asker_id, token) {
        return http.get(`/monitoring/osVersions?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }


    // get the count of devices on each OS platform (ie; number of Windows devices vs Linux, etc.)
    // tenant id and asker id to be used later for multi-tenant option
    // returns [{osPlatform: "Windows", count: 4},  {osPlatform: "Linux", count: 14}]
    getOSCounts(tenant_id, tenant_name, asker_id, token) {
        return http.get(`/monitoring/osCounts?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }
    getLowDiskSpace(tenant_id, tenant_name, asker_id, token) {
        return http.get(`/monitoring/lowDiskSpace?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }
    // getLastSeen(tenant_id, tenant_name, asker_id, token) {
    //     return http.get(`/monitoring/lastSeen?tenantId=${tenant_id}&tenantName=${tenant_name}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }
    getAssetInfo(tenant_id, tenant_name, asker_id, token) {
        return http.get(`/monitoring/assetInfo?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }


    // get the count of endpoints that have low disk space remaining (low disk space is considered anything < 10% free)
    // returns a standard integer
    // MAY BE REPEAT OF getLowDiskSpace !!!!
    getLowDiskSpaceCount(tenant_id, tenant_name, asker_id, token) {
        return http.get(`/monitoring/lowDiskSpace?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }


    // get the count of endpoints that have not checked in in the last 24 hours
    // returns a standard integer
    // MAY BE DUPLICATE OF getLastSeen above
    getLastSeen(tenant_id, tenant_name, asker_id, token) {
        return http.get(`/monitoring/lastSeen?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }


    // get the general asset info for the display table on monitoring page
    // returns an array of objects (note all values are strings) - each "page" will have 50 assets returned
    /*
        cursor: 'string',       // determines the next set of records to be returned in pagination
        hasNextPage: True,
        Data: [
            {
                name: data.node.name,
                make: data.node.manufacturer,
                model: data.node.model,
                serialNumber: data.node.serialNumber,
                ipAddress: data.node.ipAddress,
                natIpAddress: data.node.discover.natIpAddress,
                lastUser: data.node.lastLoggedInUser,
                freeDiskSpace: freeDiskSpace.free
            },
            {...},
            {...}
        ]
    
    */
    getAssetInfoForTable(tenant_id, tenant_name, asker_id, cursor, hasNextPage, resultsPerPage, sortOrder, sortBy, searchText, token) {
        return http.get(`/monitoring/assetInfo?tenantId=${tenant_id}&tenantName=${encodeURIComponent(tenant_name)}&askerId=${asker_id}&cursor=${cursor}&hasNextPage=${hasNextPage}&resultsPerPage=${resultsPerPage}&sortOrder=${sortOrder}&sortBy=${sortBy}&searchText=${searchText}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // return list of last logged in devices for the ticket creator
    getLastLoggedIn(tenant_name, username, token) {
        return http.get(`/monitoring/userDevices?tenantName=${encodeURIComponent(tenant_name)}&userName=${username}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // getAll(token) {
    //     return http.get("/accounts", {headers: {Authorization: 'Bearer ' + token}});
    // }

    // create(data, token) {
    //     return http.post("/accounts", data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // get(id, token) {
    //     return http.get(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // update(id, data, token) {
    //     return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // delete(id, token) {
    //     return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // findByName(name) {
    //     return http.get(`/accounts?name=${name}`);
    // }

}


export default new MonitoringDataService();
