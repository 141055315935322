import  { Component } from "react";
// import Switch from "react-switch";
//import AccountDataService from "../services/account-activities";

//import { useLocation } from "react-router-dom";

export default class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        // this.getAccount = this.getAccount.bind(this);
        // this.onChangeName = this.onChangeName.bind(this);
        // this.updateAccount = this.updateAccount.bind(this);
        // this.deleteAccount = this.deleteAccount.bind(this);

        this.state = {
            checked: false
        //     currentAccount: {
        //         id: null,
        //         name_of_account: ""
        //     },
        //     message: ""
        };
    }

    componentDidMount() {
        // GET THE ACCESS TOKEN SENT FROM ACCOUNTS LIST PAGE WITH
        //const location = useLocation();
        // const accessToken = this.props.location.state.accessToken;
        // this.getAccount(this.props.match.params.id, accessToken);
    }

    handleChange(checked) {
        this.setState({ checked });
    }

    // onChangeName(e) {   // have to have this to change the form field rendered with a value. otherwise it's read only
    //     const name = e.target.value;

    //     this.setState(function(prevState) {
    //         return {
    //             currentAccount: {
    //                 ...prevState.currentAccount,
    //                 name_of_account: name
    //             }
    //         };
    //     });
    // }

    // getAccount(id, accessToken) {
    //     AccountDataService.get(id, accessToken)
    //     .then(response => {
    //         this.setState({
    //             currentAccount: response.data.results[0]
    //         });
    //         console.log(response.data.results[0]);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // updateAccount() {
    //     AccountDataService.update(
    //         this.state.currentAccount.id,
    //         this.state.currentAccount,
    //         this.props.location.state.accessToken
    //     )
    //     .then(response => {
    //         this.props.history.push('/accounts');
    //         // this.setState({
    //         //     message: "The account was updated successfully!"
    //         // });
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // deleteAccount() {
    //     AccountDataService.delete(this.state.currentAccount.id, this.props.location.state.accessToken)
    //     .then(response => {
    //         this.props.history.push('/accounts');   // alternate way to redirect
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // render() {
        // const { currentAccount } = this.state;

        // return (
            // <div>
            //     <label>
            //         <span>Switch with default style</span>
            //         <Switch onChange={this.handleChange} checked={this.state.checked} />
            //     </label>
            // </div>
            // <div>               
            //     <div className="edit-form">
            //         <h4>Account</h4>
            //         <form>
            //             <div className="form-group">
            //                 <label htmlFor="name">Name</label>
            //                 <input
            //                 type="text"
            //                 className="form-control"
            //                 id="name"
            //                 value={currentAccount.name_of_account}
            //                 onChange={this.onChangeName}
            //                 />
            //             </div>
            //         </form>
            //         <button
            //         className="badge badge-danger mr-2"
            //         onClick={this.deleteAccount}
            //         >
            //             Delete
            //         </button>
            //         <button
            //         type="submit"
            //         className="badge badge-success"
            //         onClick={this.updateAccount}>
            //             Update
            //         </button>
            //         <p>{this.state.message}</p>
            //     </div>
            // </div>
        // );
    // }
}