// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (max-width: 1625px) {

    .Hardware-Ticket-Details_showHideMiniKnowledgebase__Wxil5 {
        display: none;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Hardware-Ticket-Details.module.css"],"names":[],"mappings":"AAAA;;IAEI;QACI,aAAa;IACjB;EACF","sourcesContent":["@media only screen and (max-width: 1625px) {\n\n    .showHideMiniKnowledgebase {\n        display: none;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"showHideMiniKnowledgebase": `Hardware-Ticket-Details_showHideMiniKnowledgebase__Wxil5`
};
export default ___CSS_LOADER_EXPORT___;
