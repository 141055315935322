// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TableSort_th__vgJuE {
    padding: 0;
  }
  
  .TableSort_control__818wI {
    width: 100%;
    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
  
    @mixin hover {
      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
    }
  }
  
  .TableSort_icon__L2aiJ {
    width: rem(21px);
    height: rem(21px);
    border-radius: rem(21px);
  }`, "",{"version":3,"sources":["webpack://./src/components/TableSort.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;EACZ;;EAEA;IACE,WAAW;IACX,4DAA4D;;IAE5D;MACE,sFAAsF;IACxF;EACF;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,wBAAwB;EAC1B","sourcesContent":[".th {\n    padding: 0;\n  }\n  \n  .control {\n    width: 100%;\n    padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);\n  \n    @mixin hover {\n      background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));\n    }\n  }\n  \n  .icon {\n    width: rem(21px);\n    height: rem(21px);\n    border-radius: rem(21px);\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th": `TableSort_th__vgJuE`,
	"control": `TableSort_control__818wI`,
	"icon": `TableSort_icon__L2aiJ`
};
export default ___CSS_LOADER_EXPORT___;
