// import useState hook to create menu collapse state
import React, { useEffect, useState } from "react";

//import { useSelector } from 'react-redux';

// import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent
} from "react-pro-sidebar";

import { useHistory } from 'react-router-dom';

import {
    IconHome2,
    IconTicket,
    IconBook2,
    IconClipboardText,
    IconUser,
    IconCode,
    IconUsers,
    IconCalendar,
    IconActivityHeartbeat,
    IconLogout,
    IconSun,
    IconMoon,
} from '@tabler/icons-react';
import { RingProgress, Text, SimpleGrid, Paper, Center, Group, Space, rem, useMantineColorScheme, useComputedColorScheme, ActionIcon } from '@mantine/core';
import { Progress, Box, Tooltip, UnstyledButton, Stack } from '@mantine/core';
import classes from './NavbarMinimalColored.module.css';
import cx from 'clsx';

import logo from "../../images/Cumulus_Logo-Gray.png";

// import icons from react icons
import { FiHome, FiLogOut, FiArrowLeftCircle, FiArrowRightCircle, FiServer } from "react-icons/fi";

// import { BiCog } from "react-icons/bi";
import { MdComputer, MdMouse, MdPeopleOutline } from "react-icons/md";

import { FaCompactDisc, FaTicketAlt, FaCalendarAlt, FaSignal, FaFileContract } from "react-icons/fa";


import { LOGOUT } from "../../features/actions/Is-Logged-Actions";

import { RESET_HARDWARE_ASSET_FILTERS } from "../../features/actions/Hardware-Asset-Filter-Actions";

import { RESET_USER_LIST_FILTERS } from "../../features/actions/User-List-Filter-Actions";

//import sidebar css from react-pro-sidebar module and our custom css 
import "react-pro-sidebar/dist/css/styles.css";
import "./Sidebar.css";

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { store } from "../../app/store";

//import { nagiosLocation } from '../../Nagios-Location';

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

//class Sidebar extends Component {

// constructor(props) {
//     super(props); 

//     this.state = {
//         activeLink: "userHome"        // Will control the background color of list item that is selected
//     };

//     this.changeActiveLink = this.changeActiveLink.bind(this);
// }

// changeActiveLink(pressedLink) {
//     this.setState({
//         activeLink: pressedLink
//     });
// }


//     render() {
//         //const isLoggedIn = this.props.loggedStatus.loggedIn;

//         return (
//             <div className="sidebarContainer">
//                 <nav>
//                     {/* {
//                         isLoggedIn 
//                         ?   <Link to={"/userHome"} className="navbar-brand">
//                                 ProGroup
//                             </Link>
//                         :   <Link to={"/"} className="navbar-brand">
//                                 ProGroup
//                             </Link>
//                     } */}

//                     <div className="sidebarNav">
//                         <li className={this.props.activeNavLinkStatus.activeLink === "userHome" ? "sideNavItem activeSideNavLinkBox" : "sideNavItem"}>
//                             <Link to={"/userHome"} 
//                                 className="nav-link sideNavLink gothamNarrowFont" 
//                                 id={this.props.activeNavLinkStatus.activeLink === "userHome" ? "activeSideNavLink" : ""}>
//                                 My Dashboard
//                             </Link>
//                         </li>
//                         <li className={this.props.activeNavLinkStatus.activeLink === "ticketLanding" ? "sideNavItem activeSideNavLinkBox" : "sideNavItem"}>
//                             <Link to={"/ticketLanding"} 
//                                 className="nav-link sideNavLink gothamNarrowFont" 
//                                 id={this.props.activeNavLinkStatus.activeLink === "ticketLanding" ? "activeSideNavLink" : ""}>
//                                 Ticket Landing
//                             </Link>
//                         </li>
//                         <li className={this.props.activeNavLinkStatus.activeLink === "hardwareTickets" ? "sideNavItem activeSideNavLinkBox" : "sideNavItem"}>
//                             <Link to={"/hardwareTickets"} 
//                                 className="nav-link sideNavLink gothamNarrowFont" 
//                                 id={this.props.activeNavLinkStatus.activeLink === "hardwareTickets" ? "activeSideNavLink" : ""}>
//                                 Hardware Tickets
//                             </Link>
//                         </li>
//                         <li className={this.props.activeNavLinkStatus.activeLink === "softwareTickets" ? "sideNavItem activeSideNavLinkBox" : "sideNavItem"}>
//                             <Link to={"/softwareTickets"} 
//                                 className="nav-link sideNavLink gothamNarrowFont" 
//                                 id={this.props.activeNavLinkStatus.activeLink === "softwareTickets" ? "activeSideNavLink" : ""}>
//                                 Software Tickets
//                             </Link>
//                         </li>
//                         {/* <li className="nav-item">
//                             <Link to={""} className="nav-link" onClick={() => {this.props.onLogout(this.props.loggedStatus.username);}}>
//                             Logout
//                             </Link>
//                         </li> */}
//                     </div>
//                 </nav>
//             </div>
//         );
//     }

//}

function NavbarLink(props) {
    return (
        <Tooltip label={props.label} position="right" transitionProps={{ duration: 100 }}>
            <UnstyledButton onClick={props.onClick} className={classes.link} data-active={props.active || undefined}>
                <props.icon style={{ width: rem(20), height: rem(20) }} stroke={1.5} />
            </UnstyledButton>
        </Tooltip>
    );
}

function logOutOfState() {
    return {
        type: 'LOGOUT'
    }
}

function resetHardwareAssetFilters() {
    return {
        type: 'RESET_HARDWARE_ASSET_FILTERS'
    }
}

function resetUserListFilters() {
    return {
        type: 'RESET_USER_LIST_FILTERS'
    }
}

function logOutOfStateWrapper() {

    store.dispatch(logOutOfState());
    store.dispatch(resetHardwareAssetFilters());
    store.dispatch(resetUserListFilters());
}

const Sidebar = ({ onLogout, loggedStatus, activeNavLinkStatus, userPermissions }) => {
    //create initial menuCollapse state using useState hook
    const [menuCollapse, setMenuCollapse] = useState(true);
    //const [roleId, setRoleId] = useState(0);
    const [can_see_assets, setCanSeeAssets] = useState(0);
    const [can_edit_assets, setCanEditAssets] = useState(0);
    const [can_delete_assets, setCanDeleteAssets] = useState(0);
    const [can_add_users, setCanAddUsers] = useState(0);
    const [can_edit_users, setCanEditUsers] = useState(0);
    const [can_delete_users, setCanDeleteUsers] = useState(0);
    const [can_see_users, setCanSeeUsers] = useState(0);
    const [can_add_assets, setCanAddAssets] = useState(0);
    const [can_process_tickets, setCanProcessTickets] = useState(0);
    const [can_see_admin_portal, setCanSeeAdminPortal] = useState(0);
    const [can_manage_assignments, setCanManageAssignments] = useState(0);
    const [active, setActive] = useState(-1);

    //create a custom function that will change menucollapse state from false to true and true to false
    const menuIconClick = () => {
        //condition checking to change state from true to false and vice versa (only if window is big enough to support opened menu)
        if (window.innerWidth >= 1500) {
            menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
        }
    };

    const { setColorScheme } = useMantineColorScheme();
    const computedColorScheme = useComputedColorScheme('light', { getInitialValueInEffect: true });

    //const testRoleId = useSelector((store) => store.logged);

    // This is the equivalent of component did mount for functional components. update the local state's value of role_id when menu loads
    useEffect(() => {
        /* if(loggedStatus.role_id !== undefined) {
            //console.log("This is your role_id: ", loggedStatus.role_id);
            setRoleId(loggedStatus.role_id);
        } */

        if (userPermissions.can_see_assets !== undefined) {
            setCanSeeAssets(userPermissions.can_see_assets);
        }

        if (userPermissions.can_edit_assets !== undefined) {
            setCanEditAssets(userPermissions.can_edit_assets);
        }

        if (userPermissions.can_delete_assets !== undefined) {
            setCanDeleteAssets(userPermissions.can_delete_assets);
        }

        if (userPermissions.can_add_users !== undefined) {
            setCanAddUsers(userPermissions.can_add_users);
        }

        if (userPermissions.can_edit_users !== undefined) {
            setCanEditUsers(userPermissions.can_edit_users);
        }

        if (userPermissions.can_delete_users !== undefined) {
            setCanDeleteUsers(userPermissions.can_delete_users);
        }

        if (userPermissions.can_see_users !== undefined) {
            setCanSeeUsers(userPermissions.can_see_users);
        }

        if (userPermissions.can_add_assets !== undefined) {
            setCanAddAssets(userPermissions.can_add_assets);
        }

        if (userPermissions.can_process_tickets !== undefined) {
            setCanProcessTickets(userPermissions.can_process_tickets);
        }

        if (userPermissions.can_see_admin_portal !== undefined) {
            setCanSeeAdminPortal(userPermissions.can_see_admin_portal);
        }

        if (userPermissions.can_manage_assignments !== undefined) {
            setCanManageAssignments(userPermissions.can_manage_assignments);
        }

    }, [loggedStatus.role_id, userPermissions.can_see_assets, userPermissions.can_edit_assets, userPermissions.can_delete_assets, userPermissions.can_add_users, userPermissions.can_edit_users, userPermissions.can_delete_users, userPermissions.can_see_users, userPermissions.can_add_assets, userPermissions.can_process_tickets, userPermissions.can_see_admin_portal, userPermissions.can_manage_assignments]);

    // This use effect listens for screen size changes and shrinks the sidebar if needed
    // useEffect(() => {
    //     function handleResize() {
    //         console.log("resize detected");
    //         if (!menuCollapse && window.innerWidth < 1500) {
    //             setMenuCollapse(true);
    //         }
    //     }

    //     window.addEventListener('resize', handleResize)

    //     return _ => {
    //         window.removeEventListener('resize', handleResize)
    //     }
    // }, [menuCollapse]);

    const history = useHistory();

    return (
        <nav className={classes.navbar}>
            <div className={classes.navbarMain}>
                <Link to={"/userHome"} style={{ maxWidth: "60px" }}>
                    <div id="logoWhiteCircle"><img src={logo} alt="Progroup Symbol" style={{ width: "100%" }} /></div>
                </Link>
                <Stack justify="center" gap={0}>
                    <Space h="50px" />
                    <NavbarLink
                        label="Home"
                        icon={IconHome2}
                        linkTo="/userHome"
                        key="Home"
                        active={0 === active}
                        onClick={() => {
                            setActive(0);
                            history.push("/userHome");
                        }}
                    />
                    {can_process_tickets || can_manage_assignments ? <NavbarLink
                        label="My Assigned Tickets"
                        icon={IconTicket}
                        linkTo="/myAssignedTickets"
                        key="My Assigned Tickets"
                        active={1 === active}
                        onClick={() => {
                            setActive(1);
                            history.push("/myAssignedTickets");
                        }}
                    /> : undefined}
                    {can_process_tickets ? <NavbarLink
                        label="Knowledgebase"
                        icon={IconBook2}
                        linkTo="/hardwareTickets"
                        key="Knowledgebase"
                        active={2 === active}
                        onClick={() => {
                            setActive(2);
                            history.push("/hardwareTickets");
                        }}
                    /> : undefined}
                    {can_process_tickets ? <NavbarLink
                        label="Reports"
                        icon={IconClipboardText}
                        linkTo="/reports"
                        key="Reports"
                        active={3 === active}
                        onClick={() => {
                            setActive(3);
                            history.push("/reports");
                        }}
                    /> : undefined}
                    {can_see_assets || can_add_assets || can_edit_assets || can_delete_assets ? <NavbarLink
                        label="Hardware Assets"
                        icon={IconCalendar}
                        linkTo="/hardwareAssets"
                        key="Hardware Assets"
                        active={4 === active}
                        onClick={() => {
                            setActive(4);
                            history.push("/hardwareAssets");
                        }}
                    /> : undefined}
                    {can_see_assets || can_add_assets || can_edit_assets || can_delete_assets ? <NavbarLink
                        label="Software Assets"
                        icon={IconCode}
                        linkTo="/softwareAssets"
                        key="Software Assets"
                        active={5 === active}
                        onClick={() => {
                            setActive(5);
                            history.push("/softwareAssets");
                        }}
                    /> : undefined}
                    {can_see_users || can_add_users || can_edit_users || can_delete_users ? <NavbarLink
                        label="User List"
                        icon={IconUsers}
                        linkTo="/userList"
                        key="User List"
                        active={6 === active}
                        onClick={() => {
                            setActive(6);
                            history.push("/userList");
                        }}
                    /> : undefined}
                    {can_see_admin_portal ? <NavbarLink
                        label="Tech Assignment"
                        icon={IconCalendar}
                        linkTo="/techAssignment"
                        key="Tech Assignment"
                        active={7 === active}
                        onClick={() => {
                            setActive(7);
                            history.push("/techAssignment");
                        }}
                    /> : undefined}
                    {can_see_admin_portal ? <NavbarLink
                        label="Monitoring"
                        icon={IconActivityHeartbeat}
                        linkTo="/monitoring"
                        key="Monitoring"
                        active={8 === active}
                        onClick={() => {
                            setActive(8);
                            history.push("/monitoring");
                        }}
                    /> : undefined}
                </Stack>
            </div>

            <Stack justify="center" gap={0}>
                <NavbarLink
                    label="Account"
                    icon={IconUser}
                    linkTo="/accounts"
                    key="Account"
                    active={9 === active}
                    onClick={() => {
                        setActive(9);
                        history.push("/accounts");
                    }}
                />
            </Stack>
            <Stack justify="center" gap={0}>
                <NavbarLink icon={IconLogout} label="Logout" onClick={() => { logOutOfStateWrapper(); onLogout(loggedStatus.username); }} />
            </Stack>
        </nav>
    );
};


const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,    // Use global state to control which link is highlighted
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { LOGOUT, RESET_HARDWARE_ASSET_FILTERS, RESET_USER_LIST_FILTERS })(Sidebar);