import { createStore } from 'redux';
import reducers from '../features/reducers/all-reducers';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

// export default () => {
//     let store = createStore(persistedReducer)
//     let persistor = persistStore(store)
//     return { store, persistor }
// }

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);

// const store = createStore(reducers);

// export default store;


/* import { configureStore } from '@reduxjs/toolkit';

import isLoggedReducer from '../features/isLogged/isLoggedSlice';

export default configureStore({
    reducer: {
        isLogged: isLoggedReducer
    }
}); */