import React, { Component } from "react";
import AccountDataService from "../services/account-activities";
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

class AddAccount extends Component {
    constructor(props) {
        super(props);
        this.onChangeName = this.onChangeName.bind(this);
        this.saveAccount = this.saveAccount.bind(this);

        this.state = {
            //id: null,
            name: "",
            //redirect: false
        };
    }

    componentDidMount() {
        this.props.UPDATE_ACTIVE_LINK("add_account");
    }

    onChangeName(e) {
        this.setState({
            name: e.target.value
        });
    }

    saveAccount() {
        var data = {
            name: this.state.name
        };

        if(this.props.loggedStatus.loggedIn){
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    AccountDataService.create(data, token)
                    .then(response => {
                        this.props.history.push('/accounts');
                    //console.log(response.data);
                    // this.setState({
                    //     redirect: true
                    // });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
            });
        }



        // AccountDataService.create(data)
        // .then(response => {
        //     //console.log(response.data);
        //     // this.setState({
        //     //     redirect: true
        //     // });
        // })
        // .catch(e => {
        //     console.log(e);
        // });
    }


    render() {
        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }

        // const { redirect } = this.state;

        // if(redirect) {
        //     return <Redirect to='/accounts'/>   // To redirect back after submission
        // }

        return (
            <div className="submit-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                    type="text"
                    className="form-control"
                    id="name"
                    required
                    value={this.state.name}
                    onChange={this.onChangeName}
                    name="name"
                    />     
                </div>
                <button onClick={this.saveAccount} className="btn btn-success">
                    Submit
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav
    };
}

export default connect(mapStateToProps, {ACCESSTOKEN, UPDATE_ACTIVE_LINK, LOGOUT})(AddAccount);