export const UPDATE_SELECTED_TYPE = content => ({
    type: "UPDATE_SELECTED_TYPE",
    payload: content
});

export const UPDATE_SELECTED_WARRANTY_STATUS = content => ({
    type: "UPDATE_SELECTED_WARRANTY_STATUS",
    payload: content
});

export const UPDATE_SELECTED_EXPIRATION_START_DATE = content => ({
    type: "UPDATE_SELECTED_EXPIRATION_START_DATE",
    payload: content
});

export const UPDATE_SELECTED_EXPIRATION_END_DATE = content => ({
    type: "UPDATE_SELECTED_EXPIRATION_END_DATE",
    payload: content
});

export const UPDATE_SELECTED_TENANT_ID = content => ({
    type: "UPDATE_SELECTED_TENANT_ID",
    payload: content
});

export const UPDATE_SELECTED_ASSIGNED_TO_USER = content => ({
    type: "UPDATE_SELECTED_ASSIGNED_TO_USER",
    payload: content
});

export const UPDATE_SELECTED_SERIAL_NUMBER = content => ({
    type: "UPDATE_SELECTED_SERIAL_NUMBER",
    payload: content
});

export const UPDATE_SELECTED_ASSET_TAG = content => ({
    type: "UPDATE_SELECTED_ASSET_TAG",
    payload: content
});

export const UPDATE_SEARCH_TEXT_HARDWARE_ASSET = content => ({
    type: "UPDATE_SEARCH_TEXT_HARDWARE_ASSET",
    payload: content
});

export const UPDATE_SORT_BY = content => ({
    type: "UPDATE_SORT_BY",
    payload: content
});

export const UPDATE_SORT_ORDER = content => ({
    type: "UPDATE_SORT_ORDER",
    payload: content
});

export const RESET_HARDWARE_ASSET_FILTERS = content => ({
    type: "RESET_HARDWARE_ASSET_FILTERS",
    payload: content
});

export const UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE = content => ({
    type: "UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE",
    payload: content
})