import React, { Component } from "react";
import SoftwareTicketDataService from "../services/software-ticket-activities";

//import HardwareTicketDataService from "../services/hardware-ticket-activities";     // REMOVE WHEN CONVERTED TO SOFTWARE

import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';

import {Link} from 'react-router-dom';

class CreateSoftwareTicket extends Component {
    constructor(props) {
        super(props);
        // this.onChangeName = this.onChangeName.bind(this);
        // this.saveAccount = this.saveAccount.bind(this);

        this.state = {
            assets_assigned: [],
            issue_types: [],
            site_name: "",
            site_id: 0,
            asset_id_selected: 0,   // Will change based on dropdown menu choice
            asset_name_selected: "",
            ticket_category_id_selected: 0, // Will change based on dropdown menu choice
            ticket_category_selected: "",
            ticket_priority_selected: "Low",
            entered_title_text: "",
            typing_timeout: 0,  // controls when the backend search based on typed title occurs
            access_denied: false,  // If they don't have a site, or they don't have software assets, they can't ask a question
            edit_ticket: false,
            SoftwareTicketAsset: "",
            SoftwareTicketTypeIssue: ""
        };

        this.goBackToDashboard = this.goBackToDashboard.bind(this);
        this.onSelectTicketCategory = this.onSelectTicketCategory.bind(this);
        this.onSelectAssetInvolved = this.onSelectAssetInvolved.bind(this);
        this.setEditTicket = this.setEditTicket.bind(this);
        this.setEditAssetId = this.setEditAssetId.bind(this);
        this.setEditTicketCategoryId = this.setEditTicketCategoryId.bind(this);
        this.handleEditAssetChange = this.handleEditAssetChange.bind(this);
        this.handleEditTypeIssueChange = this.handleEditTypeIssueChange.bind(this);
    }

    componentDidMount() {
        if(this.props.loggedStatus.loggedIn) {

            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {

                this.props.ACCESSTOKEN(token);

                SoftwareTicketDataService.getNewTicketFormData(this.props.loggedStatus.id, token)
                .then(response => {
                    if(response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                        /* this.setState({
                            site_name: "Unknown",
                            access_denied: true
                        }); */

                        console.log("Error retrieving site data");
                    }

                    else {
                        this.setState({
                            site_name: response.data.siteData[0].name_of_site,
                            site_id: response.data.siteData[0].site_id
                        });
                    }

                    if(response.data.assignedSoftwareAssets.length === 0) { // User has no software assets assigned to them
                        /* this.setState({
                            access_denied: true
                        }); */

                        this.setState({
                            assets_assigned: [{software_id: 1, hardware_id: 1, name_of_software: "Not Applicable"}],    // this is hardcoded in the database and is available to all users
                            asset_id_selected: 1,
                            asset_name_selected: "Not Applicable"
                        });
                    }

                    else {

                        let basicArray = [{software_id: 1, hardware_id: 1, name_of_software: "Not Applicable"}];
                        let assignedAssets = response.data.assignedSoftwareAssets;

                        this.setState({
                            assets_assigned: [...basicArray, ...assignedAssets],
                            asset_id_selected: 1,
                            asset_name_selected: "Not Applicable" }, () => {
                                if ( this.props.location.state ){
                                    this.setEditAssetId(this.props.location.state.SoftwareTicketAsset);
                                }
                            //asset_id_selected: response.data.assignedSoftwareAssets[0].software_id,  // This will account for the case where user just picks first value without clicking
                            //asset_name_selected: response.data.assignedSoftwareAssets[0].name_of_software  // This will account for the case where user just picks first value without clicking
                        });
                    }

                    if(response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                        console.log("Error getting ticket categories from database");
                        /* this.setState({
                            access_denied: true
                        }); */
                    }

                    else {
                        this.setState({
                            issue_types: response.data.ticketCategories,
                            ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                            ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                        }, () => {
                            if ( this.props.location.state ){
                                this.setEditTicketCategoryId(this.props.location.state.SoftwareTicketCategoryTypeIssue);
                            }
                        });
                    }
                    //console.log(this.state);
                })
                .catch(e => {
                    console.log(e);
                });
            });

            if ( this.props.location.state ){
                this.setEditTicket(this.props.location.state.enableEditSoftwareTicket, this.props.location.state.SoftwareTicketAsset, this.props.location.state.SoftwareTicketCategoryTypeIssue, this.props.location.state.SoftwareTicketPriority);
            }
        }
    }

    onSelectAssetInvolved(asset_id, asset_name) {
        this.setState({
            asset_id_selected: asset_id,
            asset_name_selected: asset_name
        });
    }

    onSelectTicketCategory(category_id, category_name) {
        this.setState({
            ticket_category_id_selected: category_id,
            ticket_category_selected: category_name
        });
    }

    onChangeTicketTitle = (e) => {

        if(this.state.typing_timeout) {
            clearTimeout(this.state.typing_timeout);
        }

        this.setState({
            entered_title_text: e.target.value,
            typing_timeout: setTimeout( () => { // wait some time before sending the typed text to the backend (prevent having a bunch of calls)
                SoftwareTicketDataService.findRelatedTickets(this.state.entered_title_text, this.props.loggedStatus.accessToken, this.props.loggedStatus.id)
                .then(response => {
                    console.log(response);
                })
                .catch(e => {
                    console.log(e);
                });
            }, 1500)
        });

    }

    createSoftwareTicket = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let formDataObj = Object.fromEntries(formData.entries());
        formDataObj["getSoftwareEmails"] = this.props.loggedStatus.getEmailsSoftware;
        console.log(formDataObj);

        SoftwareTicketDataService.createSoftwareTicket(formDataObj, this.props.loggedStatus.accessToken)
        .then(response => {
            this.props.history.push('/userHome');   // return user back to user home upon success
        })
        .catch(e => {
            console.log(e);
        })
        
        console.log(formDataObj);
    }

    editSoftwareTicket = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let formDataObj = Object.fromEntries(formData.entries());
        console.log(formDataObj);
        //console.log(this.props.loggedStatus.getEmails);
        SoftwareTicketDataService.editSoftwareTicket(this.props.location.state.SoftwareTicketId, formDataObj, this.props.loggedStatus.accessToken)
        .then(response => {
            this.props.history.push("/softwareTickets/" + this.props.location.state.SoftwareTicketId);   // return user back to user home upon success
        })
        .catch(e => {
            console.log(e);
        })
        
        console.log(formDataObj);
    }

    goBackToDashboard() {
        this.props.history.push('/userHome');
    }

    setEditTicket( editSoftwareTicket, SoftwareTicket_Asset, SoftwareTicket_TypeIssue, SoftwareTicket_Priority ) {
        this.setState({
            edit_ticket: editSoftwareTicket,
            SoftwareTicketAsset: SoftwareTicket_Asset,
            asset_name_selected: SoftwareTicket_Asset,
            ticket_category_selected: SoftwareTicket_TypeIssue,
            ticket_priority_selected: SoftwareTicket_Priority,
            SoftwareTicketTypeIssue: SoftwareTicket_TypeIssue
        });
    }

    setEditAssetId(edit_asset_name) {
        // console.log(edit_asset_name);
        this.setState({
            asset_id_selected: (this.state.assets_assigned.find(o => o.name_of_software === edit_asset_name)).software_id
        });
    }

    setEditTicketCategoryId(edit_issue_name) {
        this.setState({
            ticket_category_id_selected: (this.state.issue_types.find(o => o.name_of_category === edit_issue_name)).id
        });
    }

    handleEditAssetChange( assets_assigned, e ) {
        // console.log("Fruit Selected!!");
        // console.log(assets_assigned);
        this.setState({ 
            SoftwareTicketAsset: e.target.value,
            asset_id_selected: (assets_assigned.find(o => o.name_of_software === e.target.value)).software_id,
            asset_name_selected: e.target.value
        });
    }

    handleEditTypeIssueChange( issue_types, e ) {
        // console.log("Veggies Selected!!");
        // console.log(issue_types);
        this.setState({ 
            SoftwareTicketTypeIssue: e.target.value,
            ticket_category_id_selected: (issue_types.find(o => o.name_of_category === e.target.value)).id,
            ticket_category_selected: e.target.value 
        });
    }

    handleTicketPriorityChange = e => {
        console.log("Software Priority Changed!");
        console.log(e.target.value);
        this.setState({
            // HardwareTicketPriority: e.target.value,
            ticket_priority_selected: e.target.value
        });
    }

    

    render() {
        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }

        const { assets_assigned, issue_types } = this.state;

        // const { redirect } = this.state;

        // if(redirect) {
        //     return <Redirect to='/accounts'/>   // To redirect back after submission
        // }

        return (
            <>
                <div className="container mt-3" style={{paddingTop: "75px"}}>
                {/* <div className="miniKnowledgebaseWrapper showHideMiniKnowledgebase">
                        <p className="suggestedPostsTitle gothamNarrowFont">Your topic might be similar to...</p>
                        <span className="miniKnowledgebase showHideMiniKnowledgebase"> */}
                            {/* <p className="suggestedPostsTitle gothamNarrowFont">Suggested Related Posts</p> */}
                            {/* <Table className="miniKnowledgebaseTable" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Hello there</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Category</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">One long incredibly unbroken sentence moving from topic to topic so that no one had the chance to interrupt it was really quite hypnotic...(more)</div> */}
                                            {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                                        {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Hello there</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Category</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">One long incredibly unbroken sentence moving from topic to topic so that no one had the chance to interrupt it was really quite hypnotic...(more)</div> */}
                                            {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                                        {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Hello there</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Category</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">One long incredibly unbroken sentence moving from topic to topic so that no one had the chance to interrupt it was really quite hypnotic...(more)</div> */}
                                            {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                                        {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Hello there</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Category</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">One long incredibly unbroken sentence moving from topic to topic so that no one had the chance to interrupt it was really quite hypnotic...(more)</div> */}
                                            {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                                        {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Hello there</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Category</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">One long incredibly unbroken sentence moving from topic to topic so that no one had the chance to interrupt it was really quite hypnotic...(more)</div> */}
                                            {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                                        {/* </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </span>
                    </div> */}
                    <Container fluid>
                        <Row>
                            <Col md={{span: 9, offset: 1}}>
                                <h1 style={{textAlign: "center"}} className="gothamNarrowFont">{this.state.edit_ticket ? "Edit Software Ticket" : "Create Software Ticket"}</h1>
                            </Col>
                        </Row>
                        <Row style={{marginTop: "40px"}}>
                            <Col md={{span: 4, offset: 1}}>
                                <div>
                                    <p className="gothamNarrowFont">Requester: <b>{this.props.loggedStatus.fullName}</b></p>
                                </div>
                            </Col>
                            <Col md={{span: 5}}>
                                <div style={{textAlign: "right"}}>
                                    <p className="gothamNarrowFont">Site: <b>{this.state.site_name}</b></p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{span: 9, offset: 1}}>
                                <div style={{marginTop: "10px"}}>
                                    <Form onSubmit={this.state.edit_ticket ? this.editSoftwareTicket : this.createSoftwareTicket}>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridRequesterId">
                                                <Form.Control type="hidden" value={this.props.loggedStatus.id} name="requesterId"/>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridSiteId">
                                                <Form.Control type="hidden" value={this.state.site_id} name="siteId"/> 
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAssetId">
                                                <Form.Control type="hidden" value={this.state.asset_id_selected} name="assetId"/> 
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCategoryId">
                                                <Form.Control type="hidden" value={this.state.ticket_category_id_selected} name="categoryId"/> 
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridAssetInvolved">
                                                <Form.Label className="gothamNarrowFont">Asset Involved</Form.Label>
                                                { this.state.edit_ticket ?
                                                    <Form.Control as="select" name="nameOfAsset" value={this.state.SoftwareTicketAsset} onChange={(e) => this.handleEditAssetChange(assets_assigned, e)} className="gothamNarrowLightFont" required>
                                                        {assets_assigned && assets_assigned.map((asset) => (
                                                        <option key={asset.software_id} onClick={() => this.onSelectAssetInvolved(asset.software_id, asset.name_of_software)} className="gothamNarrowLightFont">{asset.name_of_software}</option> 
                                                        ))}
                                                    </Form.Control> :
                                                     <Form.Control as="select" name="nameOfAsset" onChange={(e) => this.handleEditAssetChange(assets_assigned, e)} className="gothamNarrowLightFont" required>
                                                        {assets_assigned && assets_assigned.map((asset) => (
                                                        <option key={asset.software_id} onClick={() => this.onSelectAssetInvolved(asset.software_id, asset.name_of_software)} className="gothamNarrowLightFont">{asset.name_of_software}</option> 
                                                        ))}
                                                    </Form.Control>
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridTypeOfIssue">
                                                <Form.Label className="gothamNarrowFont">Type of Issue</Form.Label>
                                                { this.state.edit_ticket ?
                                                    <Form.Control as="select" name="typeOfIssue" value={this.state.SoftwareTicketTypeIssue} onChange={ (e) => this.handleEditTypeIssueChange(issue_types, e)} className="gothamNarrowLightFont" required>
                                                        {issue_types && issue_types.map((category) => (
                                                            <option key={category.id} onClick={() => this.onSelectTicketCategory(category.id, category.name_of_category)} className="gothamNarrowLightFont">{category.name_of_category}</option>   
                                                        ))}
                                                    </Form.Control> :
                                                    <Form.Control as="select" name="typeOfIssue" onChange={ (e) => this.handleEditTypeIssueChange(issue_types, e)} className="gothamNarrowLightFont" required>
                                                    {issue_types && issue_types.map((category) => (
                                                        <option key={category.id} onClick={() => this.onSelectTicketCategory(category.id, category.name_of_category)} className="gothamNarrowLightFont">{category.name_of_category}</option>   
                                                    ))}
                                                </Form.Control> 
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPriority">
                                                <Form.Label className="gothamNarrowFont">Priority</Form.Label>
                                                {this.state.edit_ticket ?
                                                     <Form.Control as="select" value={this.state.ticket_priority_selected} onChange={(e) => this.handleTicketPriorityChange(e)} name="priority" className="gothamNarrowLightFont" required>
                                                     <option value="Low" className="gothamNarrowLightFont">Low</option>
                                                     <option value="Moderate" className="gothamNarrowLightFont">Moderate</option>
                                                     <option value="High" className="gothamNarrowLightFont">High</option>
                                                    </Form.Control> :
                                                     <Form.Control as="select" onChange={(e) => this.handleTicketPriorityChange(e)} name="priority" className="gothamNarrowLightFont" required>
                                                     <option value="Low" className="gothamNarrowLightFont">Low</option>
                                                     <option value="Moderate" className="gothamNarrowLightFont">Moderate</option>
                                                     <option value="High" className="gothamNarrowLightFont">High</option>
                                                    </Form.Control> 
                                                }
                                               
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridTicketTitle">
                                                <Form.Label className="gothamNarrowFont">Title</Form.Label>
                                                <Form.Control placeholder="Type ticket title here" name="ticketTitle" className="gothamNarrowLightFont" defaultValue={this.state.edit_ticket ? this.props.location.state.SoftwareTicketTitle : ""} onChange={this.onChangeTicketTitle} required/>
                                                <Form.Text className="text-muted gothamNarrowLightFont">
                                                    Note: This will be used as the email subject sent to the technician.
                                                </Form.Text>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridIssueText">
                                                <Form.Label className="gothamNarrowFont">Describe Your Issue</Form.Label>
                                                <Form.Control as="textarea" style={{height: "185px"}} placeholder="Type issue text here" defaultValue={this.state.edit_ticket ? this.props.location.state.SoftwareTicketIssue : ""} name="issueText" className="gothamNarrowLightFont" required/>
                                                <Form.Text className="text-muted gothamNarrowLightFont">
                                                    Note: This will be used as the email body sent to the technician.
                                                </Form.Text>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <div style={{margin: "auto"}}>
                                                <span style={{marginRight: "200px"}}>
                                                <Link to={this.state.edit_ticket ? ("/softwareTickets/" + this.props.location.state.SoftwareTicketId) : "/softwareTickets"}>
                                                    <Button variant="secondary" className="knowledgebaseButton, gothamNarrowFont">
                                                        Cancel
                                                    </Button>
                                                </Link>
                                                </span>
                                                {/* <div style={{textAlign: "center"}}> */}
                                                <span>
                                                <Button variant="primary" type="submit" className="gothamNarrowFont">
                                                    Submit
                                                </Button>
                                                </span>
                                            </div>
                                            {/* </div> */}
                                        </Form.Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Modal
                    show={this.state.access_denied}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                    <Modal.Title className="gothamNarrowFont">You Can't Ask A Question Yet</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <p className="gothamNarrowLightFont">You either haven't specified your work site, your administrator has not input any possible ticket categories, or there are no software assets assigned to you.</p>
                    <p className="gothamNarrowLightFont">To specify your work site, please visit the "Update My Account Information" page.</p>
                    <p className="gothamNarrowLightFont">Otherwise, ask an administrator to assign your software assets in the system, and/or check that ticket categories have been input.</p>
                    <p className="gothamNarrowLightFont">Click the button below to return to the dashboard.</p>
                    </Modal.Body>
                    <Button variant="primary" onClick={() => this.goBackToDashboard()} className="gothamNarrowFont">Understood</Button>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged
    };
}

export default connect(mapStateToProps, {ACCESSTOKEN})(CreateSoftwareTicket);