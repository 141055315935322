import http from "../http-common";

class HoldingTableDataService {
    getAll(token) { // get ALL the tickets currently stored in the holding table (assuming this number will never be extremely large, so no pagination)
        return http.get(`/createTicketFromEmail`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getSpecific(id, timezoneAdjustmentFactor, token) {    // get just the details for the specified ticket with id passed in
        return http.get(`/createTicketFromEmail/${id}?timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTicketHistory(id, timezoneAdjustmentFactor, token) {
        return http.get(`/createTicketFromEmail/${id}/history?timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    savePrivateNote(id, creator_id, data, token) {  // save tech's private note to holding ticket record
        return http.post(`/createTicketFromEmail/${id}/privateNotes/${creator_id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // this is called when user clicks red delete button (ie they aren't clarifying the ticket, just outright deleting it)
    deleteTicketOutright(id, token) {
        return http.delete(`/createTicketFromEmail/${id}/deleteOutright`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // sending through the new ticket id for the hardware / software ticket that was just created before calling this endpoint
    deleteTicket(id, new_ticket_id, type_of_new_ticket, token) {
        return http.delete(`/createTicketFromEmail/${id}?newTicketId=${new_ticket_id}&newTicketType=${type_of_new_ticket}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    changeIsPrivate(id, data, token) {  // toggle the holding ticket's is_private between 0 and 1 (called when toggle is clicked on clarify form)
        return http.put(`/createTicketFromEmail/${id}/changeIsPrivate`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // sends designated ticket id and new assigned tech id to update the backend record of that ticket
    updateTechAssignment(id, data, token) {
        return http.put(`/createTicketFromEmail/${id}/updateTechAssignment`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // updates values from clarify ticket form fields any time there's a change on the front end
    updateHoldingTicketInfo(id, data, token) {
        return http.put(`/createTicketFromEmail/${id}/updateHoldingTicketInfo`, data, {headers: {Authorization: 'Bearer ' + token}});
    }
}

export default new HoldingTableDataService();