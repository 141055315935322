import React from 'react';
import './App.css';
//import { LandingPage } from './components/LandingPage';
import LandingPage from './components/LandingPage';
//import { UserHome, loggedIn as userLogin} from './components/UserHome';
import UserHome from './components/UserHome';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/tiptap/styles.css';
import "@mantine/dates/styles.css";
import { MantineProvider, createTheme } from '@mantine/core';

import Sidebar from './components/Sidebar/Sidebar';

//import Sidebar from './components/Sidebar';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';

import AccountsList from "./components/Accounts-List";
import AccountDetails from "./components/Account-Details";
import AddAccount from "./components/Add-Account";

import TicketsHomepage from "./components/Tickets-Homepage";
//import HardwareTicketsLandingPage from "./components/Hardware-Tickets-Landing-Page";
import TicketsLandingPage from "./components/Tickets-Landing-Page";
import SoftwareTicketsLandingPage from "./components/Software-Tickets-Landing-Page";
import HardwareTicketDetails from "./components/Hardware-Ticket-Details";
import SoftwareTicketDetails from "./components/Software-Ticket-Details";
import CreateHardwareTicket from "./components/Create-Hardware-Ticket";
import CreateSoftwareTicket from "./components/Create-Software-Ticket";
import Settings from './components/Settings';

import CurrentUserList from "./components/Admin-Dashboard/Current-User-List";
import UserDetails from "./components/User-Details";
import EditUser from "./components/Admin-Dashboard/Edit-User";
import HardwareAssetList from './components/Admin-Dashboard/Hardware-Asset-List';
import CreateHardwareAsset from './components/Admin-Dashboard/Create-Hardware-Asset';
import EditHardwareAsset from './components/Admin-Dashboard/Edit-Hardware-Asset';
//import SoftwareAssetList from './components/Admin-Dashboard/Software-Asset-List';   // replaced with ViewContractsAndSoftware.js 3/2024
import ViewContractsAndSoftware from './components/Admin-Dashboard/View-Contracts-and-Software';
import ViewSpecificContract from './components/Admin-Dashboard/View-Specific-Contract';
import ViewSpecificSoftwareAsset from './components/Admin-Dashboard/View-Specific-Software-Asset';
import CreateContract from './components/Admin-Dashboard/Create-Contract';
import EditContract from './components/Admin-Dashboard/Edit-Contract';
import CreateSoftwareAsset from './components/Admin-Dashboard/Create-Software-Asset';
import EditSoftwareAsset from './components/Admin-Dashboard/Edit-Software-Asset';
import TicketHoldingArea from "./components/Admin-Dashboard/Ticket-Holding-Area";
import ClarifyTicketForm from './components/Admin-Dashboard/Clarify-Ticket-Form';
import Calendar from "./components/Admin-Dashboard/Tech-Assignment";
import Monitoring from './components/Admin-Dashboard/Monitoring';

import HardwareAssetDetails from './components/Admin-Dashboard/Hardware-Asset-Details';
//import SoftwareAssetDetails from './components/Admin-Dashboard/Software-Asset-Details';   // replaced with View-Specific-Software-Asset.js 3/2024

import MyAssignedTickets from './components/My-Assigned-Tickets';

import Reports from './components/Admin-Dashboard/Reports';

import { LOGOUT } from '../src/features/actions/Is-Logged-Actions';

import { connect } from 'react-redux';

// import {selectLogInStatus} from "./features/isLogged/isLoggedSlice";
// import { useSelector, useDispatch } from 'react-redux';

import "bootstrap/dist/css/bootstrap.min.css";
//import { BrowserAuthError } from '@azure/msal-browser'

class App extends React.Component {

  constructor(props) {
    super(props);
    this.onLogIn = this.props.onLogIn;  // had to extract the passed in function from this.props to be able to send to child fxn component
    this.onLogOut = this.props.onLogOut;
    this.onAccessTokenRequest = this.props.onAccessTokenRequest;

    // this.state = {
    //   appLoggedIn: selectLogInStatus
    // };

  }


  render() {
    // const appLoggedIn = <useSelector>{selectLogInStatus}</useSelector>;
    //console.log(userLogin);

    let sidebarTag;
    if (this.props.loggedStatus.loggedIn) {
      sidebarTag = <div><Sidebar onLogout={this.onLogOut} /></div>
    }

    else {
      sidebarTag = <div></div>
    };

    window.addEventListener("storage", (e) => {
      try {
        if (e.newValue.includes('logged')/*JSON.parse(e.newValue).hasOwnProperty('logged')*/) {
          let outerObject = JSON.parse(e.newValue).logged;

          if (JSON.parse(outerObject).loggedIn) {
            console.log("Looks like you're still logged in");
          }

          else {
            console.log("The logout state was caught from previous component, you'll handle logout of this tab here!");
            //this.props.LOGOUT();

            this.onLogOut();
            //window.location.reload();
          }

          //console.log("Found good value of e.newValue! Trying to parse the logged part of the state object: ", JSON.parse(outerObject));

        }

      }

      catch {
        console.log("Unusable value of e.newValue was; ", e.newValue);
      }

      //let newStateValue = JSON.parse(e.newValue);
      //console.log(newStateValue);
      //localStorage.setItem("persist:root", e.newValue);   // update the local storage state if another tab has changed something
    });

    //let navTag = <div> <Navbar onLogout={this.onLogOut}/></div>
    /* if(userLogin){
      navTag = <div> <Navbar /></div>
    }
    else {
      navTag = <div></div>
    } */

    return (
      <MantineProvider
      theme={{
        white: "#f8f9fa",
        focusRing: "never",
      }}
      >
        <Router>
          {sidebarTag}
          <div style={{ marginLeft: this.props.loggedStatus.loggedIn ? "80px" : "0", marginTop: this.props.loggedStatus.loggedIn ? "80px" : "", color: "var(--mantine-color-text)" }}>
            <Switch>
              <Route
                exact path='/'
                render={(props) => (
                  <LandingPage {...props} onLogin={this.onLogIn} />
                )}
              />
              <Route
                exact path='/userHome'
                render={(props) => (
                  <UserHome {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/accounts"
                render={(props) => (
                  <AccountsList {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/settings"
                render={(props) => (
                  <Settings {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/addAccount"
                render={(props) => (
                  <AddAccount {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route path="/accounts/:id" component={AccountDetails} />
              <Route path="/ticketLanding" component={TicketsHomepage} />
              <Route
                exact path="/hardwareTickets"
                render={(props) => (
                  <TicketsLandingPage {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/softwareTickets"
                render={(props) => (
                  <SoftwareTicketsLandingPage {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                path="/hardwareTickets/:id"
                render={(props) => (
                  <HardwareTicketDetails {...props} key={props.match.params.id} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                path="/softwareTickets/:id"
                render={(props) => (
                  <SoftwareTicketDetails {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/createHardwareTicket"
                render={(props) => (
                  <CreateHardwareTicket {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/createSoftwareTicket"
                render={(props) => (
                  <CreateSoftwareTicket {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/userList"
                render={(props) => (
                  <CurrentUserList {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/hardwareAssets"
                render={(props) => (
                  <HardwareAssetList {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/createHardwareAsset"
                render={(props) => (
                  <CreateHardwareAsset {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/softwareAssets"
                render={(props) => (
                  <ViewContractsAndSoftware {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/contracts/:id"
                render={(props) => (
                  <ViewSpecificContract {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/editContract/:id"
                render={(props) => (
                  <EditContract {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/holdingArea"
                render={(props) => (
                  <TicketHoldingArea {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/clarifyTicket/:id"
                render={(props) => (
                  <ClarifyTicketForm {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/techAssignment"
                render={(props) => (
                  <Calendar {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/myAssignedTickets"
                render={(props) => (
                  <MyAssignedTickets {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/hardwareAssetDetails/:id"
                render={(props) => (
                  <HardwareAssetDetails {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/editHardwareAsset/:id"
                render={(props) => (
                  <EditHardwareAsset {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/softwareAssetDetails/:id"
                render={(props) => (
                  <ViewSpecificSoftwareAsset {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/createSoftwareAsset"
                render={(props) => (
                  <CreateSoftwareAsset {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/editSoftwareAsset/:id"
                render={(props) => (
                  <EditSoftwareAsset {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/createContract"
                render={(props) => (
                  <CreateContract {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/reports"
                render={(props) => (
                  <Reports {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/monitoring"
                render={(props) => (
                  <Monitoring {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route
                exact path="/userProfile/:id"
                render={(props) => (
                  <UserDetails {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
              <Route 
                exact path="/editUser/:id"
                render={(props) => (
                  <EditUser {...props} onLogout={this.onLogOut} onAccessTokenRequest={this.onAccessTokenRequest} />
                )} />
            </Switch>
          </div>
        </Router>
      </MantineProvider>
    );
  }

}

//export default App;
const mapStateToProps = (state) => {
  return {
    loggedStatus: state.logged
  };
}

export default connect(mapStateToProps, { LOGOUT })(App);