import React, { useEffect, useState } from 'react';
import {
    Paper,
    Group,
    Progress,
    Box,
    SimpleGrid,
    RingProgress,
    Center,
    Text
} from '@mantine/core';
import DashboardDataService from '../../../../services/dashboard-activities';
import { BarChart, AreaChart, PieChart } from '@mantine/charts';
const itemHeight = 120;

export default function SLAPerformance(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.loggedStatus.loggedIn) {
            props.onAccessTokenRequest(props.loggedStatus.username).then((token) => {
                if (token !== undefined && token !== null && token !== "") {
                    var date = new Date();
                    var currentMonth = date.getMonth();
                    var currentYear = date.getFullYear();
                    var currentDay = date.getDay();
                    DashboardDataService.getSLAPerformance(token, props.loggedStatus.id, 0, currentDay, currentMonth, currentYear, props.loggedStatus.timezone_adjustment_factor).then(response => {
                        console.log(response);
                        for (var i = 0; i < response.data.results.length; i++) {
                            response.data.results[i]["Bad"] = response.data.results[i]["badCount"];
                            delete response.data.results[i]["badCount"];
                            response.data.results[i]["Good"] = response.data.results[i]["goodCount"];
                            delete response.data.results[i]["goodCount"];
                            response.data.results[i]["Warning"] = response.data.results[i]["warningCount"];
                            delete response.data.results[i]["warningCount"];
                        }
                        setData(response.data.results);
                    }).catch(e => {
                        console.log(e);
                    })
                }
            });
        }
    }, []);

    return (
        <Paper withBorder radius="md" h="100%" w="100%" style={{ verticalAlign: "top" }}>
            <Text p="20px">SLA Performance</Text>
            <AreaChart
                h={props.widget.size.y * itemHeight - 90}
                m={0}
                pr="20px"
                data={data}
                dataKey="hour"
                series={[
                    {
                        name: 'Bad',
                        color: 'red.6',
                    },
                    {
                        name: 'Good',
                        color: 'green.6',
                    },
                    {
                        name: 'Warning',
                        color: 'yellow.6',
                    },
                ]}
                tickLine="y"
            />
        </Paper>
    );
}
