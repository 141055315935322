const initialState = {
    can_see_assets: false,
    can_see_tickets: false,
    can_add_assets: false,
    can_edit_assets: false,
    can_edit_tickets: false,
    can_add_users: false,
    can_edit_users: false,
    can_add_solutions: false,
    can_edit_solutions: false,
    can_edit_priority: false,
    can_delete_assets: false,
    can_delete_tickets: false,
    can_delete_users: false,
    can_delete_comments: false,
    can_see_users: false,
    can_see_admin_portal: false,
    can_process_tickets: false,
    can_manage_assignments: false,
    can_see_attachments: false
};

function userPermissionsReducer(state = initialState, action) {
    switch(action.type) {
        case "UPDATE_PERMISSIONS":
            if(action.payload) {
//console.log("I'm in the permissions reducer and this is what I see");
//console.log(action.payload);
                return {
                    ...state,
                    can_see_assets: action.payload.can_see_assets,
                    can_see_tickets: action.payload.can_see_tickets,
                    can_add_assets: action.payload.can_add_assets,
                    can_edit_assets: action.payload.can_edit_assets,
                    can_edit_tickets: action.payload.can_edit_tickets,
                    can_add_users: action.payload.can_add_users,
                    can_edit_users: action.payload.can_edit_users,
                    can_add_solutions: action.payload.can_add_solutions,
                    can_edit_solutions: action.payload.can_edit_solutions,
                    can_edit_priority: action.payload.can_edit_priority,
                    can_delete_assets: action.payload.can_delete_assets,
                    can_delete_tickets: action.payload.can_delete_tickets,
                    can_delete_users: action.payload.can_delete_users,
                    can_delete_comments: action.payload.can_delete_comments,
                    can_see_users: action.payload.can_see_users,
                    can_see_admin_portal: action.payload.can_see_admin_portal,
                    can_process_tickets: action.payload.can_process_tickets,
                    can_manage_assignments: action.payload.can_manage_assignments,
                    can_see_attachments: action.payload.can_see_attachments
                }
            }
            break;

        default:
            return state;
    }
}

export default userPermissionsReducer;