import http from "../http-common";

class HardwareTicketDataService {
    getAll(token, offsetValue, sortBy, sortOrder, user_id_asking) {    // get the next chunk of 10 tickets
        return http.get(`/hardwareTickets?offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getAttachments(id, token) {       // get the attachments associated with a specific hardware ticket
        return http.get(`/hardwareTickets/${id}/getAttachments`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getUnresolvedTicketCount(token) {
        return http.get(`/hardwareTickets/unresolvedCount`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTicketHistory(id, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/${id}/history?timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // User clicked a new category in filter menu, but had no search text dialog
    filterByCategory(categoryID, offsetValue, sortBy, sortOrder, token, user_id_asking) {
        return http.get(`/hardwareTickets?categoryID=${categoryID}&offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // User clicked a new category with search text or entered search text while on category != All Categories
    filterByCategoryAndSearchText(categoryName, offsetValue, searchText, sortBy, sortOrder, token, user_id_asking) {
        return http.get(`/hardwareTickets?categoryName=${categoryName}&searchText=${searchText}&offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Add a new hardware ticket to the database
    createHardwareTicket(data, token) {
        return http.post("/hardwareTickets", data, {headers: {Authorization: 'Bearer ' + token, 'Content-Type': `multipart/form-data`}});
    }

    // Get specific ticket (also increments view count)
    get(id, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/${id}?timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Get all comments associated with a ticket
    getComments(id, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/${id}/comments?timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get like or dislike or none based on ticket
    getLikesDislikes(id, uid, token){
        return http.get(`/hardwareTickets/${id}/likesdislikes?uid=${uid}`, {headers: {Authorization: 'Bearer ' + token}}); 
    }

    // get Hardware ticket email status based on ticket and user 
    getBoolIndividualHardwareTicketEmails(id, uid, globalHardwareEmailStatus, token) {
        return http.get(`/hardwareTickets/${id}/getIndividualHardwareEmailStatus?uid=${uid}&globalHardwareEmailStatus=${globalHardwareEmailStatus}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get list of assignable parent tickets (for parent ticket drop down component)
    getAssignableParentTickets(user_id_asking, searchText, token) {
        return http.get(`/hardwareTickets/parentTickets/getAssignable?userIdAsking=${user_id_asking}&searchText=${searchText}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // changes  disabling or enabling of emails based on user id and hardware ticket
    changeIndividualHardwareTicketEmailStatus(id, uid, data, token) {
        return http.put(`/hardwareTickets/${id}/updateIndividualHardwareTicketEmailStatus?uid=${uid}`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    addLike(id, uid, token) {
        return http.get(`/hardwareTickets/${id}/like?uid=${uid}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    addDislike(id, uid, token) {
        return http.get(`/hardwareTickets/${id}/dislike?uid=${uid}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    createHardwareTicketComment(data, token) {
        return http.post("/hardwareTickets/comments", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    createHardwareTicketTechNote(data, token) {
        return http.post("/hardwareTickets/techNotes", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    addSolution(id, data, token) {
        return http.put(`/hardwareTickets/${id}/addSolution`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Data not used here, but expected by put operation. All values updating are hardcoded to null
    removeSolution(id, data, token) {
        return http.put(`/hardwareTickets/${id}/removeSolution`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Get data to populate the create new hardware ticket form
    // (user's assigned hardware assets, ticket categories, name/id of site)
    getNewTicketFormData(id, token) {
        return http.get(`/hardwareTickets/${id}/newTicketFormData`, {headers: {Authorization: 'Bearer ' + token}});
    }

    editHardwareTicket(id, data, token) {
        return http.put(`/hardwareTickets/${id}/editHardwareTicket`, data, {headers: {Authorization: 'Bearer ' + token}});
    }
    // update(id, data, token) {
    //     return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // delete(id, token) {
    //     return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // User typed in search criteria but had All Categories selected

    findByText(searchText, offsetValue, sortBy, sortOrder, token, user_id_asking) {
        return http.get(`/hardwareTickets?searchText=${searchText}&offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // User typed in content into the ticket title form input. Note: "all" was required in the the endpoint to distinguish from /hardwareTickets/:id on backend
    findRelatedTickets(titleText, token, user_id_asking) {
        return http.get(`/hardwareTickets/all/relatedTickets?titleText=${titleText}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // sends designated ticket id and new assigned tech id to update the backend record of that ticket
    updateTechAssignment(id, data, token) {
        return http.put(`/hardwareTickets/${id}/updateTechAssignment`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // return the list of sla's with their characteristics
    getSlas(token) {
        return http.get('/hardwareTickets/slas/all', {headers: {Authorization: 'Bearer ' + token}});
    }

    // return the list of overdue tickets for the selected tenant
    getTimespanTickets(tenant_id, timespan, timespanType, timespanAssignedTo, userIdAsking, timezoneAdjustmentFactor, token) {

        let timespanToUse = [];

        if(timespan[0] !== null && timespan[0] !== "") {
            timespanToUse[0] = timespan[0].toISOString().split('T')[0];
            timespanToUse[0] = timespanToUse[0] + " 00:00:00";
        }
        if(timespan[1] !== null && timespan[1] !== "") {
            timespanToUse[1] = timespan[1].toISOString().split('T')[0];
            timespanToUse[1] = timespanToUse[1] + " 23:59:59";
        }

        console.log("The converted timespan: ", timespanToUse);
        return http.get(`/hardwareTickets/reports/timespanTickets?tenantId=${tenant_id}&timespan=${timespanToUse}&timespanType=${timespanType}&timespanAssignedTo=${timespanAssignedTo}&userIdAsking=${userIdAsking}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // return the list of overdue tickets for the selected tenant
    getOverdueTickets(tenant_id, userIdAsking, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/reports/overdueTickets?tenantId=${tenant_id}&userIdAsking=${userIdAsking}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // return the list of tickets in the warning zone for the selected tenant
    getWarningTickets(tenant_id, userIdAsking, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/reports/warningTickets?tenantId=${tenant_id}&userIdAsking=${userIdAsking}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of overdue tickets belonging to the normal tech calling in for the notification center
    getOverdueTicketsRegularTechNotificationCenter(tenant_id, userIdAsking, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/reports/getOverdueTicketsRegularTechNotificationCenter?tenantId=${tenant_id}&userIdAsking=${userIdAsking}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of warning tickets belonging to the normal tech calling in for the notification center
    getWarningTicketsRegularTechNotificationCenter(tenant_id, userIdAsking, timezoneAdjustmentFactor, token) {
        return http.get(`/hardwareTickets/reports/getWarningTicketsRegularTechNotificationCenter?tenantId=${tenant_id}&userIdAsking=${userIdAsking}&timezoneAdjustmentFactor=${timezoneAdjustmentFactor}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // change assigned parent on a ticket (note: this will also work on email created tickets)
    changeAssignedParent(ticket_id, data, token) {
        return http.put(`/hardwareTickets/${ticket_id}/changeAssignedParent`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    allowToBeParent(ticket_id, data, token) {
        return http.put(`/hardwareTickets/${ticket_id}/allowToBeParent`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    removeAbilityToBeParent(ticket_id, data, token) {
        return http.put(`/hardwareTickets/${ticket_id}/removeAbilityToBeParent`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // this endpoint is used by the corresponding widget
    getNumberOfTicketsLast24Hours(tenant_id, userIdAsking, token) {
        return http.get(`/hardwareTickets/widget/getNumberOfTicketsLast24Hours?tenantId=${tenant_id}&userIdAsking=${userIdAsking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // this endpoint is used by the corresponding widget
    getNumberOfTicketsLastWeek(tenant_id, userIdAsking, token) {
        return http.get(`/hardwareTickets/widget/getNumberOfTicketsLastWeek?tenantId=${tenant_id}&userIdAsking=${userIdAsking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

}


export default new HardwareTicketDataService();
