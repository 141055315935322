const initialState = {
    selectedType: 0,     // id of the selected type from the selected type drop down menu in filter
    warrantyStatus: "All",   // changed when user picks and confirms a different radio button in filter modal
    expirationStartDate: "",    // will contain date value as yyyy-mm-dd saying what day is selected by user for the start date of filter
    expirationEndDate: "",      // same as above, but for end date
    selectedTenantId: 0,    // id of the tenant user has selected 0 = All
    selectedAssignedToUser: 0,      // id of the selected assigned to user in drop down menu of filter modal
    serialNumber: "",           // the serial number entered into the filter modal
    assetTag: "",                // the asset tag entered into the filter modal
    searchTextHardwareAsset: "",          // text entered by user in the search by asset name bar
    sortBy: "Name",             // the column name that is currently the active sort criteria for the assets displayed
    sortOrder: "ASC",          // the order that the assets are displayed in ASC or DESC

    selectedCompanyInHardwareAssetAddForm: 0     // this value determines what the default company showed in the add asset form is - will keep your previous value on each asset add to reduce clicks
};

function hardwareAssetFilterReducer(state = initialState, action) {
    switch(action.type) {
        case "UPDATE_SELECTED_TYPE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedType: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_WARRANTY_STATUS":
            if(action.payload !== null) {
                return {
                    ...state,
                    warrantyStatus: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_EXPIRATION_START_DATE":
            if(action.payload !== null) {
                return {
                    ...state,
                    expirationStartDate: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_EXPIRATION_END_DATE":
            if(action.payload !== null) {
                return {
                    ...state,
                    expirationEndDate: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_TENANT_ID":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedTenantId: action.payload
                }
            }
            break;

        case "UPDATE_SELECTED_ASSIGNED_TO_USER":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedAssignedToUser: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_SERIAL_NUMBER":
            if(action.payload !== null) {
                return {
                    ...state,
                    serialNumber: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_ASSET_TAG":
            if(action.payload !== null) {
                return {
                    ...state,
                    assetTag: action.payload
                };
            }
            break;

        case "UPDATE_SEARCH_TEXT_HARDWARE_ASSET":
            if(action.payload !== null) {
                console.log("Search text got payload of: ", action.payload);
                return {
                    ...state,
                    searchTextHardwareAsset: action.payload
                };
            }
            break;

        case "UPDATE_SORT_BY":
            if(action.payload !== null) {
                return {
                    ...state,
                    sortBy: action.payload
                };
            }
            break;

        case "UPDATE_SORT_ORDER":
            if(action.payload !== null) {
                return {
                    ...state,
                    sortOrder: action.payload
                };
            }
            break;

        case "UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE":
            if(action.payload !== null) {
                return {
                    ...state,
                    selectedCompanyInHardwareAssetAddForm: action.payload
                };
            }
            break;

        case "RESET_HARDWARE_ASSET_FILTERS":        // returns everything to default values (gets called upon logout from service, or reset filters button)
            return {
                ...state,
                selectedType: 0,     // id of the selected type from the selected type drop down menu in filter
                warrantyStatus: "All",   // changed when user picks and confirms a different radio button in filter modal
                expirationStartDate: "",    // will contain date value as yyyy-mm-dd saying what day is selected by user for the start date of filter
                expirationEndDate: "",      // same as above, but for end date
                selectedTenantId: 0,    // id of the tenant user has selected 0 = All
                selectedAssignedToUser: 0,      // id of the selected assigned to user in drop down menu of filter modal
                serialNumber: "",           // the serial number entered into the filter modal
                assetTag: "",                // the asset tag entered into the filter modal
                searchTextHardwareAsset: "",          // text entered by user in the search by asset name bar
                sortBy: "Name",             // the column name that is currently the active sort criteria for the assets displayed
                sortOrder: "ASC",          // the order that the assets are displayed in ASC or DESC
            }



        default:
            return state;


    }
}

export default hardwareAssetFilterReducer;