// This widget displays an integer showing the number of tickets created in the last 24 hours from time user lands on the page

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Paper,
} from '@mantine/core';

import {
  connect, /*, useDispatch*/
  useSelector
} from 'react-redux';

import { store } from '../../app/store';

import "./Widgets.css";

import HardwareTicketDataService from '../../services/hardware-ticket-activities';


function NumberOfTicketsLast24Hours(props) {
  let state = store.getState();
  //let dispatch = useDispatch();
  const accessToken = useSelector((state) => state.logged.accessToken);
  const username = useSelector((state) => state.logged.username);
  const accessTokenRequest = props.onAccessTokenRequest
  const tenantId = props.loggedStatus.report_tenant_id_selected;
  const userId = props.loggedStatus.id;
  const [numberResult, setNumberResult] = React.useState(0);   // start result at 0 in the functional component's state

  React.useEffect(() => {

    accessTokenRequest(username).then((token) => {
        HardwareTicketDataService.getNumberOfTicketsLast24Hours(tenantId, userId, token).then(response => {
            console.log(response);
            if(response.data.results && response.data.results.length > 0) {
                setNumberResult(response.data.results[0].ticket_count);
            }
        })
        .catch(e => {
            console.log(e);
        });

    // HardwareTicketDataService.getSlas(token)
    //     .then(response => {
    //     console.log("Hello from get slas!");
    //     console.log(response);

    //     let updatedPriorityList = [...priorityList];

    //     response.data.results.map((sla) => {
    //         updatedPriorityList.push(sla);
    //         return updatedPriorityList;
    //     });

    //     setPriorityList(updatedPriorityList);
    //     })
    //     .catch(e => {
    //     console.log(e);
    //     });

    })
    

  }, [numberResult, setNumberResult, accessTokenRequest, username, tenantId, userId]);    // any state changes now have the number result as a dependency to watch out for

  //const [prioritySelected, setPrioritySelected] = useState("All");
  //console.log("Priority Name = ", state.myAssignedTickets.priority_name);


  return (
    <>
        <Paper withBorder p="md" radius="md" display="block" style={{width: "175px", height: "175px"}}>
            <div className="widgetHeader">Tickets Created Last 24 Hours</div>
            <div className="widgetDataText">{numberResult}</div>
        </Paper>
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    loggedStatus: state.logged,
    myAssignedTickets: state.myAssignedTickets
  };
}

export default connect(mapStateToProps)(NumberOfTicketsLast24Hours);