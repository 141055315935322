import React, { Component, Fragment } from "react";
import HardwareTicketDataService from "../services/hardware-ticket-activities";
import MonitoringDataService from "../services/monitoring-activities";

import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import {
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    Button,
    Switch,
    Table,
    rem,
    keys,
    Paper,
    Space,
    Select,
    Modal,
    Grid,
    Flex,
} from '@mantine/core';
import { IconEdit, IconPencil } from "@tabler/icons-react";
import Navbar from '../components/Navbar';

import parse from 'html-react-parser';

import { cleanHTML } from '../html-cleaner';

import { removeTags } from '../remove-html-tags';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ListGroup } from "react-bootstrap";

import Form from 'react-bootstrap/Form';

import ReassignTechSelector from './Reassign-Tech-Selector';

import { UPDATE_ACTIVE_ASSIGNMENT, ASSIGNMENT_CHANGE_RESOLVED } from '../features/actions/Reassign-Tech-Actions';

import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faEye, faEdit, faCheckCircle } from '@fortawesome/free-regular-svg-icons';


import { faTimes } from "@fortawesome/free-solid-svg-icons";

import MAX_ATTACHMENT_SIZE from "../max-attachment-size";

import ParentTicketSelector from "./Admin-Dashboard/Parent-Ticket-Selector";

import { Link } from 'react-router-dom';

import classes from './Hardware-Ticket-Details.module.css';
import RichTextInput from "./RichTextInput";
import PossiblyAffectedDevices from "./Tanium-Widgets/Possibly-Affected-Devices";

import TicketSubmitterDetails from "./Dashboard-Widgets/Ticket-Submitter-Details";

// import CreateNewHardwareTicket from './Create-Hardware-Ticket';


class HardwareTicketDetails extends Component {
    constructor(props) {
        super(props);
        this.getTicket = this.getTicket.bind(this);
        this.getTicketComments = this.getTicketComments.bind(this);
        this.getLikesDis = this.getLikesDis.bind(this);
        this.getBoolIndividualHardwareTicketEmails = this.getBoolIndividualHardwareTicketEmails.bind(this);
        this.getAttachments = this.getAttachments.bind(this);
        this.addALike = this.addALike.bind(this);
        this.addADislike = this.addADislike.bind(this);
        this.setTicketTimeSinceCreated = this.setTicketTimeSinceCreated.bind(this);
        this.setCommentsTimeSinceCreated = this.setCommentsTimeSinceCreated.bind(this);
        this.replyToOriginalPost = this.replyToOriginalPost.bind(this);
        this.replyToComment = this.replyToComment.bind(this);
        this.cancelReply = this.cancelReply.bind(this);
        this.selectSolution = this.selectSolution.bind(this);
        this.removeSolution = this.removeSolution.bind(this);
        this.handleIndivdualHardwareChange = this.handleIndivdualHardwareChange.bind(this);
        this.openEditHardwareTicket = this.openEditHardwareTicket.bind(this);
        this.updateTechAssignment = this.updateTechAssignment.bind(this);

        this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this);
        this.handleAttachmentUploadChange = this.handleAttachmentUploadChange.bind(this);
        this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);

        this.closeTechNotesModal = this.closeTechNotesModal.bind(this);
        this.openTechNotesModal = this.openTechNotesModal.bind(this);
        this.onChangePrivateNote = this.onChangePrivateNote.bind(this);
        this.handleUploadButtonClickTechNote = this.handleUploadButtonClickTechNote.bind(this);
        this.showViewTechNote = this.showViewTechNote.bind(this);
        this.hideViewTechNote = this.hideViewTechNote.bind(this);

        this.hideCantPickSolutionMessage = this.hideCantPickSolutionMessage.bind(this);
        this.showCantPickSolutionMessage = this.showCantPickSolutionMessage.bind(this);
        this.hideRemoveParentSolutionWarning = this.hideRemoveParentSolutionWarning.bind(this);
        this.showRemoveParentSolutionWarning = this.showRemoveParentSolutionWarning.bind(this);
        this.hideStaleTokenError = this.hideStaleTokenError.bind(this);

        this.changeParentAbility = this.changeParentAbility.bind(this);
        this.handleRespondToParentGroupChange = this.handleRespondToParentGroupChange.bind(this);
        this.handleImmediateMarkAsSolutionChange = this.handleImmediateMarkAsSolutionChange.bind(this);

        this.getHistory = this.getHistory.bind(this);

        console.log("CONSTRUCTOR THIS IN BEGIN:", this.props.loggedStatus.getEmails);
        this.state = {
            currentTicket: {
                id: null,
                name_of_asset: "",
                name_of_requester: "",
                date_created: "",
                resolution_status: "",
                ticket_category: "",
                ticket_priority: "",
                up_votes: 0,
                down_votes: 0,
                time_of_response: "",
                date_time_solved: "",
                // button_liked: 0,
                // button_disliked: 0,
                total_clicks_all_time: 0,
                ticket_title: "",
                issue_text: "",
                solution_text: "",
                is_parent: 0,
                parent_id: 0,
                years_since_sent: 0,
                days_since_sent: 0,
                hours_since_sent: 0,
                minutes_since_sent: 0,
                time_since_sent: "",
                solver_id: null,
                solver_name: "",
                solver_solution_count: 0
            },

            comments: [],

            attachments: [],

            history: [],

            accessToken: this.props.loggedStatus.accessToken,
            //accessToken: this.props.location.state.accessToken,

            // Elemetns for the reply form
            show_reply_form: false,
            ticket_id_responding_to: 0,
            name_replying_to: "",
            issue_text_responding_to: "",
            comment_id_responding_to: null,
            button_liked: 0,
            button_disliked: 0,
            individual_hardware_checked: true,// this.props.loggedStatus.getEmails,
            editButtonEnabled: true,
            msg: "",

            attachmentFileNameList: [],

            showSaving: false,       // controls whether the saving modal is displayed or not

            showCantPickSolutionError: false,      // controls whether the remaining unclarified child error displays or not when picking a solution
            showRemoveParentSolutionWarning: false, // controls whether the remove parent solution confirmation modal appears
            showAddParentSolutionWarning: false,    // controls whether the add parent solution confirmation modal appears
            showChangeParentAbilityWarning: false,  // controls whether the change parent ability confirmation modal appears
            showStaleTokenError: false,     // contorls if the stale token error modal appears
            showImageDetectedError: false,  // gets set to true if user tries to submit a response with an image directly pasted in the text box
            typeAffected: "",   // gets set when the stale token error appears - determines what form to return to after user closes the error modal
            // the following state variables are used to submit solution information for parent from the warning modal
            solutionTextForParent: "",
            ticketIdForParent: 0,
            commenterIdForParent: 0,
            commenterNameForParent: "",
            isParentForParent: 0,
            commenterSolutionCountForParent: 0,

            // ****************************************

            immediateMarkAsSolution: false,     // if true, when user submits a reply, it will immediately mark that item as a solution for that ticket


            showTechNotesModal: false,      // determines if the create new / view tech notes table modal appears
            private_note_text: "",
            attachmentFileNameListTechNote: [],
            tech_notes: [],  // array of objects with past private notes {id: 1, creator_name: Test McTesterson, ticket_id: 1, note_text: "I'm demo text from a fake tech note.", formatted_date_time_created: '02/25/22 12:35:42 PM'}
            show_tech_note: false,   // determines if the tech note details modal appears
            tech_note_viewing_time_created: "", // Set when user clicks a note in timeline table
            tech_note_viewing_creator_name: "", // Set when user clicks a note in timeline table
            tech_note_viewing_note_text: "",     // Set when user clicks a note in timeline table
            tech_note_viewing_attachment_list: [],   // contains all attachment links that match the "comment_id" or note_id of that note being viewed

            reply_text: "",      // the text the user has typed into the reply box (used for replies to base ticket and replies to comments)
            showNoResponseError: false,  // determines if the input validation error message displays for the tech response (if tried to submit empty string)

            trigger: 0,      // going to be used to signal to the parent selector child component that it needs to get the list of parent tickets again (anything > 0 will fire on child side)

            replyToParentGroup: false,   // if true, a tech's reply will go to the entire group of parent + children tickets
            //showParentAssigner: false   // can see the parent assigner if the ticket is NOT a parent itself AND person logged in is a tech

            lastLoggedInDevices: null
        };
        // console.log(this.props.loggedStatus.getEmails);
    }

    componentDidMount() {

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    //console.log("TEST THIS IN BEGIN:", this.props.loggedStatus.getEmails);
                    // GET THE ACCESS TOKEN SENT FROM ACCOUNTS LIST PAGE WITH
                    //const location = useLocation();
                    //const accessToken = this.props.location.state.accessToken;
                    const accessToken = token; //this.props.loggedStatus.accessToken;

                    this.getTicket(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, accessToken);
                    this.getTicketComments(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, accessToken);
                    this.getLikesDis(this.props.match.params.id, this.props.loggedStatus.id, accessToken);
                    this.getBoolIndividualHardwareTicketEmails(this.props.match.params.id, this.props.loggedStatus.id, true /*this.props.loggedStatus.getEmails*/, accessToken);
                    this.getAttachments(this.props.match.params.id, accessToken);
                    //console.log(this.props.loggedStatus.getEmails);
                    this.getHistory(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, accessToken);
                }

            });
        }
    }

    componentDidUpdate() {
        if (this.props.reassignTech.assignment_change_detected) {
            console.log("We have picked up the assignment change. The value you chose was: ", this.props.reassignTech.active_assignment_name);

            this.props.ASSIGNMENT_CHANGE_RESOLVED();

            this.updateTechAssignment(this.props.match.params.id, this.props.reassignTech.active_assignment_id, this.props.loggedStatus.accessToken);
        }
    }


    // This will also add a view to the ticket retreived
    getTicket(id, timezone_adjustment_factor, accessToken) {
        HardwareTicketDataService.get(id, timezone_adjustment_factor, accessToken)
            .then(response => {
                // console.log("getTicket");

                console.log("Result from getTicket");
                console.log(response.data.results[0]);
                this.setState({
                    currentTicket: response.data.results[0],
                    tech_notes: response.data.results[0].tech_notes

                });
                console.log(this.state.currentTicket);

                if (response.data.results[0] && response.data.results[0].tech_assigned_id) {
                    this.props.UPDATE_ACTIVE_ASSIGNMENT({ name: response.data.results[0].assigned_tech_name, id: response.data.results[0].tech_assigned_id });
                }

                else {
                    this.props.UPDATE_ACTIVE_ASSIGNMENT({ name: "Unassigned", id: 0 });
                }

                // get the list of assets this ticket creator last logged into according to tanium
                let usernameToSend = "";
                if (response.data.results[0].requester_email) {
                    usernameToSend = response.data.results[0].requester_email.split("@")[0];    // everything before the @ sign
                    console.log("username detected: ", usernameToSend);
                }

                MonitoringDataService.getLastLoggedIn(response.data.results[0].name_of_tenant, usernameToSend, accessToken).then(response => {

                    console.log("Response from last logged in = ", response);
                    this.setState({
                        lastLoggedInDevices: response.data
                    });


                })
                    .catch(e => {
                        console.log(e);
                    });
            })
            .catch(e => {
                console.log(e);
            });

    }

    getHistory(id, timezone_adjustment_factor, accessToken) {
        HardwareTicketDataService.getTicketHistory(id, timezone_adjustment_factor, accessToken)
            .then(response => {
                console.log("Response from history endpoint was: ", response);
                this.setState({
                    history: response.data.results
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    // this will get the ticket's button liked or disliked based on user 
    getLikesDis(id, uid, accessToken) {
        HardwareTicketDataService.getLikesDislikes(id, uid, accessToken)
            .then(response => {
                // console.log("likesdislikes");
                // console.log(response.data.results);
                this.setState({
                    button_liked: response.data.results[0].button_liked,
                    button_disliked: response.data.results[0].button_disliked
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    // this will get the ticket's email notification status based on user 
    getBoolIndividualHardwareTicketEmails(id, uid, globalHardwareEmailStatus, accessToken) {
        // console.log(globalHardwareEmailStatus);
        let numBoolGlobalHardwareEmailStatus = (globalHardwareEmailStatus === true) ? 1 : 0;
        HardwareTicketDataService.getBoolIndividualHardwareTicketEmails(id, uid, numBoolGlobalHardwareEmailStatus, accessToken)
            .then(response => {
                console.log("getting email status");
                console.log(response.data.results);
                this.setState({
                    individual_hardware_checked: response.data.results[0].get_ind_hardware_asset_emails
                });
            })
            .catch(e => {
                console.log(e);
            });

    }

    // NOTE: comment_id that is returned within the comments object is just a placeholder - it was needed for the left join to work
    // If need to refer to a comment's id number, just use the id column in the comments object
    getTicketComments(id, timezone_adjustment_factor, accessToken) {
        HardwareTicketDataService.getComments(id, timezone_adjustment_factor, accessToken)
            .then(response => {
                console.log(response.data.results);
                this.setState({
                    comments: response.data.results
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    // get the attachment list for display (will include data about associated comment id if necessary)
    getAttachments(id, accessToken) {
        HardwareTicketDataService.getAttachments(id, accessToken)
            .then(response => {
                console.log(response.data.results);
                this.setState({
                    attachments: response.data.results
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    // Create copy of original state, but update the up votes to the incremented value from database
    addALike(id, uid, accessToken) {
        HardwareTicketDataService.addLike(id, uid, accessToken)
            .then(response => {
                console.log("liked");
                console.log(response.data.results)
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        up_votes: response.data.results[0].up_votes,
                        down_votes: response.data.results[0].down_votes,
                        // button_liked: response.data.results[0].button_liked,
                        // button_disliked: response.data.results[0].button_disliked
                    },
                    button_liked: response.data.results[0].button_liked,
                    button_disliked: response.data.results[0].button_disliked
                }));
            })
            .catch(e => {
                console.log(e);
            });
    }


    // Create copy of original state, but update the down votes to the incremented value from database
    addADislike(id, uid, accessToken) {

        HardwareTicketDataService.addDislike(id, uid, accessToken)
            .then(response => {
                console.log("disliked");
                console.log(response.data.results);
                this.setState(prevState => ({
                    currentTicket: {
                        ...prevState.currentTicket,
                        up_votes: response.data.results[0].up_votes,
                        down_votes: response.data.results[0].down_votes,
                        // button_liked: response.data.results[0].button_liked,
                        // button_disliked: response.data.results[0].button_disliked
                    },
                    button_liked: response.data.results[0].button_liked,
                    button_disliked: response.data.results[0].button_disliked
                }));
            })
            .catch(e => {
                console.log(e);
            });
    }

    // change the local hardware ticket email status 
    handleIndivdualHardwareChange(individual_hardware_checked) {
        this.setState({ individual_hardware_checked });
        let ind_HW_EmailNotif_Status_obj = {}
        ind_HW_EmailNotif_Status_obj["IND_HardwareTicketEmailNotif_status"] = individual_hardware_checked ? 1 : 0;
        setTimeout(() => {
            // changing email status for hardware tickets
            HardwareTicketDataService.changeIndividualHardwareTicketEmailStatus(this.props.match.params.id, this.props.loggedStatus.id, ind_HW_EmailNotif_Status_obj, this.props.loggedStatus.accessToken)
                .then(response => {
                    console.log("in promise, change ind hw tick");
                })
                .catch(e => {
                    console.log(e);
                });
        }, 1000);
    }

    setTicketTimeSinceCreated(currentTicket) {
        if (currentTicket.years_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.years_since_sent + (currentTicket.years_since_sent > 1 ? " years ago" : " year ago");
        }

        else if (currentTicket.months_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.months_since_sent + (currentTicket.months_since_sent > 1 ? " months ago" : " month ago");
        }

        else if (currentTicket.days_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.days_since_sent + (currentTicket.days_since_sent > 1 ? " days ago" : " day ago");
        }

        else if (currentTicket.hours_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.hours_since_sent + (currentTicket.hours_since_sent > 1 ? " hours ago" : " hour ago");
        }

        else if (currentTicket.minutes_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.minutes_since_sent + (currentTicket.minutes_since_sent > 1 ? " minutes ago" : " minute ago");
        }

        else {
            currentTicket.time_since_sent = "moments ago";
        }
    }

    setCommentsTimeSinceCreated(comments) {
        for (var item of comments) {
            if (item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? " years ago" : " year ago");
            }

            else if (item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? " months ago" : " month ago");
            }

            else if (item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? " days ago" : " day ago");
            }

            else if (item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? " hours ago" : " hour ago");
            }

            else if (item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? " minutes ago" : " minute ago");
            }

            else {
                item.time_since_sent = "moments ago";
            }
        }
    }

    replyToOriginalPost(name_of_requester, issue_text, ticket_id) {     // User pushed reply button on the orignal post at the top
        this.setState({
            showNoResponseError: false,
            show_reply_form: true,   // Display the modal with the form
            ticket_id_responding_to: ticket_id,
            name_replying_to: name_of_requester,
            issue_text_responding_to: issue_text,
            comment_id_responding_to: null,     // Just making extra sure to say we're not responding to a comment
            attachmentFileNameList: [],      // reset the attachment list for new comment
            reply_text: "",      // reset the reply text field
            immediateMarkAsSolution: false
        });
    }

    replyToComment(name_of_commenter, comment_text, ticket_id, comment_id) {      // User pushed reply button on a comment down in the replies section
        this.setState({
            showNoResponseError: false,
            show_reply_form: true,   // Display the modal with the form
            ticket_id_responding_to: ticket_id,
            name_replying_to: name_of_commenter,
            issue_text_responding_to: comment_text,
            comment_id_responding_to: comment_id,
            attachmentFileNameList: [],
            reply_text: "",      // reset the reply text field
            immediateMarkAsSolution: false
        });
    }

    createTechNote = e => {

        e.preventDefault();
        if (this.state.private_note_text !== null && removeTags(this.state.private_note_text) !== "") {

            // as long as you don't find the image tag you're ok to continue, otherwise, they tried to paste in an image into their direct reply so we're going to reject it
            if (!this.state.private_note_text.toLowerCase().includes("<img")) {

                this.setState({
                    showNoResponseError: false,
                    showImageDetectedError: false,
                    showTechNotesModal: false,
                    showSaving: true
                });

                //e.preventDefault();
                const formDataObj = new FormData(e.target);


                for (let z = 0; z < this.state.attachmentFileNameListTechNote.length; z++) {
                    formDataObj.append('attachmentListTechNote', this.state.attachmentFileNameListTechNote[z]);
                }

                formDataObj.append('privateNote', this.state.private_note_text);

                // call the create tech note for hardware tickets endpoint here
                HardwareTicketDataService.createHardwareTicketTechNote(formDataObj, this.props.loggedStatus.accessToken)

                    .then(response => {
                        this.getTicket(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);   // get the new list of tech notes
                        this.getAttachments(this.state.currentTicket.id, this.props.loggedStatus.accessToken);      // update the list of attachments

                        this.setState({
                            showSaving: false,
                            private_note_text: "",      // note has been saved, so reset to default
                            attachmentFileNameListTechNote: [],     // note has been saved, so reset to default
                            showTechNotesModal: true
                        });
                    })
                    .catch(e => {
                        console.log(e);
                        // Show the stale token error message HERE !!!
                        this.setState({
                            showSaving: false,
                            typeAffected: "Tech Note",
                            showStaleTokenError: true
                        });

                    });
            }

            else {      // caught case where image was pasted directly into reply form, so reject
                this.setState({
                    showImageDetectedError: true,
                    showNoResponseError: false
                });
            }

        }

        else {
            this.setState({
                showNoResponseError: true,
                showImageDetectedError: false
            });
        }

    }

    submitReply = e => {

        e.preventDefault();

        if (this.state.reply_text !== null && removeTags(this.state.reply_text) !== "") {

            // as long as you don't find the image tag you're ok to continue, otherwise, they tried to paste in an image into their direct reply so we're going to reject it
            if (!this.state.reply_text.toLowerCase().includes("<img")) {

                // show the saving modal
                this.setState({
                    showNoResponseError: false,
                    showImageDetectedError: false,
                    show_reply_form: false,
                    showSaving: true
                });

                //e.preventDefault();
                const formDataObj = new FormData(e.target);

                for (let z = 0; z < this.state.attachmentFileNameList.length; z++) {
                    formDataObj.append('attachmentList', this.state.attachmentFileNameList[z]);
                }


                formDataObj.append('getEmailComments', this.props.loggedStatus.getEmails);
                formDataObj.append('IND_HardwareTicketEmailsStatus', this.state.individual_hardware_checked);
                formDataObj.append('responseText', this.state.reply_text);
                formDataObj.append('replyToParentGroup', this.state.replyToParentGroup);
                formDataObj.append('ticketTitle', this.state.currentTicket.ticket_title);
                //formDataObj.append('timeOfResponse', this.state.currentTicket.time_of_response);    // used if replying to parent group to know if need to update timestamp
                //let formDataObj = Object.fromEntries(formData.entries());
                //formDataObj["getEmailComments"] = this.props.loggedStatus.getEmails;
                //formDataObj["IND_HardwareTicketEmailsStatus"] = this.state.individual_hardware_checked;
                console.log(formDataObj);

                //formDataObj.responseText = "@ " + this.state.name_replying_to + "\n\n" + formDataObj.responseText

                HardwareTicketDataService.createHardwareTicketComment(formDataObj, this.props.loggedStatus.accessToken)
                    .then(response => {
                        //this.setState({show_reply_form: false});    // Hide the form
                        this.getTicket(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);
                        this.getTicketComments(this.state.currentTicket.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);   // update the tickets
                        this.getAttachments(this.state.currentTicket.id, this.props.loggedStatus.accessToken);      // update the list of attachments

                        this.setState({
                            showSaving: false,
                            replyToParentGroup: false
                        });


                        // If the new mark as solution checkbox was selected, call the mark as solution endpoint here !!!
                        // The commenter solution count (final parameter to this function may need changing)

                        // Determine the commenter's current solve count in possible
                        let commenterSolutionCountToUse = this.state.commenterSolutionCountForParent;
                        for (let i = 0; i < this.state.comments.length; i++) {
                            if (this.state.comments[i].commenter_id === this.props.loggedStatus.id) {
                                commenterSolutionCountToUse = this.state.comments[i].accepted_solution_count;
                                break;
                            }
                        }

                        if (this.state.immediateMarkAsSolution) {
                            this.selectSolution(this.state.reply_text, this.props.match.params.id, this.props.loggedStatus.id, this.props.loggedStatus.fullName, this.state.currentTicket.is_parent, commenterSolutionCountToUse);
                        }

                    })
                    .catch(e => {
                        console.log(e);
                        // Show the stale token error message HERE !!!
                        this.setState({
                            showSaving: false,
                            typeAffected: "Comment",
                            showStaleTokenError: true
                        });

                    });

                // // If the new mark as solution checkbox was selected, call the mark as solution endpoint here !!!
                // // The commenter solution count (final parameter to this function may need changing)

                // // Determine the commenter's current solve count in possible
                // let commenterSolutionCountToUse = this.state.commenterSolutionCountForParent;
                // for(let i = 0; i < this.state.comments.length; i++) {
                //     if(this.state.comments[i].commenter_id === this.props.loggedStatus.id) {
                //         commenterSolutionCountToUse = this.state.comments[i].accepted_solution_count;
                //         break;
                //     }
                // }

                // if(this.state.immediateMarkAsSolution) {
                //     this.selectSolution(this.state.reply_text, this.props.match.params.id, this.props.loggedStatus.id, this.props.loggedStatus.fullName, this.state.currentTicket.is_parent, commenterSolutionCountToUse);
                // }

            }

            else {  // caught case where image was pasted directly into reply form, so reject

                this.setState({
                    showImageDetectedError: true,
                    showNoResponseError: false
                });
            }

        }

        else {
            this.setState({
                showNoResponseError: true,
                showImageDetectedError: false
            });
        }
    }

    cancelReply() {         // User pushed the cancel button on the reply form, put everything back to default
        this.setState({
            showNoResponseError: false,
            showImageDetectedError: false,
            show_reply_form: false,
            ticket_id_responding_to: 0,
            name_replying_to: "",
            issue_text_responding_to: "",
            comment_id_responding_to: null,
            reply_text: "",
            replyToParentGroup: false
        });
    }

    handleRespondToParentGroupChange() {
        this.setState({
            replyToParentGroup: !this.state.replyToParentGroup
        });
    }

    handleImmediateMarkAsSolutionChange() {
        this.setState({
            immediateMarkAsSolution: !this.state.immediateMarkAsSolution
        });
    }

    handleAttachmentUploadChange(e) {
        console.log(e.target.files)

        try {

            let newAttachmentList = this.state.attachmentFileNameList;

            for (let i = 0; i < e.target.files.length; i++) {

                let file_extension = e.target.files[i].name.substring(e.target.files[i].name.lastIndexOf('.') + 1, e.target.files[i].name.length) || e.target.files[i].name;

                if (e.target.files[i].size <= MAX_ATTACHMENT_SIZE && (file_extension.toLowerCase() === "jpeg" || file_extension.toLowerCase() === "png" || file_extension.toLowerCase() === "jpg" || file_extension.toLowerCase() === "bmp" || file_extension.toLowerCase() === "heic" || file_extension.toLowerCase() === "pdf" || file_extension.toLowerCase() === "doc" || file_extension.toLowerCase() === "docx" || file_extension.toLowerCase() === "xls" || file_extension.toLowerCase() === "xlsx" || file_extension.toLowerCase() === "ppt" || file_extension.toLowerCase() === "pptx" || file_extension.toLowerCase() === "dwg" || file_extension.toLowerCase() === "txt")) {

                    console.log(e.target.files[i].name);

                    //let filename = e.target.files[i].name

                    newAttachmentList.push(e.target.files[i]);

                    // let reader = new FileReader();
                    // reader.readAsDataURL(e.target.files[i]);
                    // reader.onloadend = (event) => {
                    //     // The contents of the BLOB are in reader.result:
                    //     console.log(reader.result);
                    //     newAttachmentList.push({filename: filename, contents: reader.result});
                    // }

                }

                else {
                    console.log("This file was either too big or an incorrect file type");
                }

            }

            this.setState({
                attachmentFileNameList: newAttachmentList
            }, () => console.log(this.state.attachmentFileNameList));

        }

        catch {
            console.log("No files seen")
        }
    }

    handleUploadButtonClick() {
        document.getElementById('hiddenUploadButton').click();
    }

    handleAttachmentUploadChangeTechNote(e) {
        console.log(e.target.files)

        try {

            let newAttachmentList = this.state.attachmentFileNameListTechNote;

            for (let i = 0; i < e.target.files.length; i++) {

                let file_extension = e.target.files[i].name.substring(e.target.files[i].name.lastIndexOf('.') + 1, e.target.files[i].name.length) || e.target.files[i].name;

                if (e.target.files[i].size <= MAX_ATTACHMENT_SIZE && (file_extension.toLowerCase() === "jpeg" || file_extension.toLowerCase() === "png" || file_extension.toLowerCase() === "jpg" || file_extension.toLowerCase() === "bmp" || file_extension.toLowerCase() === "heic" || file_extension.toLowerCase() === "pdf" || file_extension.toLowerCase() === "doc" || file_extension.toLowerCase() === "docx" || file_extension.toLowerCase() === "xls" || file_extension.toLowerCase() === "xlsx" || file_extension.toLowerCase() === "ppt" || file_extension.toLowerCase() === "pptx" || file_extension.toLowerCase() === "dwg" || file_extension.toLowerCase() === "txt")) {

                    console.log(e.target.files[i].name);

                    //let filename = e.target.files[i].name

                    newAttachmentList.push(e.target.files[i]);

                    // let reader = new FileReader();
                    // reader.readAsDataURL(e.target.files[i]);
                    // reader.onloadend = (event) => {
                    //     // The contents of the BLOB are in reader.result:
                    //     console.log(reader.result);
                    //     newAttachmentList.push({filename: filename, contents: reader.result});
                    // }

                }

                else {
                    console.log("This file was either too big or an incorrect file type");
                }

            }

            this.setState({
                attachmentFileNameList: newAttachmentList
            }, () => console.log(this.state.attachmentFileNameList));

        }

        catch {
            console.log("No files seen")
        }
    }

    handleUploadButtonClickTechNote() {
        document.getElementById('hiddenUploadButtonTechNote').click();
    }

    showViewTechNote(time_created, creator_name, note_text) {
        this.setState({
            showTechNotesModal: false,
            show_tech_note: true,
            tech_note_viewing_time_created: time_created,
            tech_note_viewing_creator_name: creator_name,
            tech_note_viewing_note_text: note_text
        });
    }

    hideViewTechNote() {
        this.setState({
            showTechNotesModal: true,
            show_tech_note: false,
            tech_note_viewing_time_created: "",
            tech_note_viewing_creator_name: "",
            tech_note_viewing_note_text: ""
        });
    }

    hideStaleTokenError() {

        // if user was previously trying to create a ticket comment, return them to the create comment form
        if (this.state.typeAffected === "Comment") {
            this.setState({
                showStaleTokenError: false,
                show_reply_form: true
            });
        }

        else {      // the person was previously trying to make a tech note, so return to the tech note form
            this.setState({
                showStaleTokenError: false,
                showTechNotesModal: true
            });
        }

    }

    // this fxn removes the specified attachment from the list of attachments to send to backend for saving
    handleAttachmentDelete(fileInfo) {
        console.log(fileInfo);

        let newAttachmentList = [];

        let foundDuplicate = false;     // if there's another instance of that one in the list, only remove one of them

        for (let i = 0; i < this.state.attachmentFileNameList.length; i++) {
            if (!foundDuplicate && this.state.attachmentFileNameList[i].lastModified === fileInfo.lastModified && this.state.attachmentFileNameList[i].name === fileInfo.name && this.state.attachmentFileNameList[i].size === fileInfo.size && this.state.attachmentFileNameList[i].type === fileInfo.type) {
                // if you found the match of the one to delete, don't include it in the new attachment array
                foundDuplicate = true;
                continue;
            }

            else {
                newAttachmentList.push(this.state.attachmentFileNameList[i]);
            }
        }

        this.setState({
            attachmentFileNameList: newAttachmentList
        });
    }

    handleAttachmentDeleteTechNote(fileInfo) {
        console.log(fileInfo);

        let newAttachmentList = [];

        let foundDuplicate = false;     // if there's another instance of that one in the list, only remove one of them

        for (let i = 0; i < this.state.attachmentFileNameListTechNote.length; i++) {
            if (!foundDuplicate && this.state.attachmentFileNameListTechNote[i].lastModified === fileInfo.lastModified && this.state.attachmentFileNameListTechNote[i].name === fileInfo.name && this.state.attachmentFileNameListTechNote[i].size === fileInfo.size && this.state.attachmentFileNameListTechNote[i].type === fileInfo.type) {
                // if you found the match of the one to delete, don't include it in the new attachment array
                foundDuplicate = true;
                continue;
            }

            else {
                newAttachmentList.push(this.state.attachmentFileNameListTechNote[i]);
            }
        }

        this.setState({
            attachmentFileNameListTechNote: newAttachmentList
        });
    }

    openTechNotesModal() {
        this.setState({
            showNoResponseError: false,
            showTechNotesModal: true,
            private_note_text: "",
            attachmentFileNameListTechNote: []
        });
    }

    closeTechNotesModal() {
        this.setState({
            showNoResponseError: false,
            showImageDetectedError: false,
            showTechNotesModal: false,
            private_note_text: "",
            attachmentFileNameListTechNote: []
        });
    }

    selectSolution(solutionText, ticketId, commenterId, commenterName, isParent, commenterSolutionCount) {
        this.hideAddParentSolutionWarning();

        if (this.state.currentTicket.is_parent && this.state.currentTicket.unclarified_children.length > 0) {
            this.showCantPickSolutionMessage();
        }

        else {
            // Send an update to the backend with the new solution text and resolved status so it gets saved for future use
            HardwareTicketDataService.addSolution(ticketId, { solutionText: solutionText, commenterId: commenterId, isParent: isParent, initiatorId: this.props.loggedStatus.id }, this.state.accessToken)
                .then(response => {
                    console.log(response);
                    // Set the solution text on the front end so we see it right away
                    this.setState(prevState => {
                        let currentTicket = Object.assign({}, prevState.currentTicket); // create a copy of state variable currentTicket
                        currentTicket.solution_text = solutionText; // update the copy's property
                        currentTicket.resolution_status = "Resolved";
                        currentTicket.solver_id = commenterId;
                        currentTicket.solver_name = commenterName;
                        currentTicket.solver_solution_count = commenterSolutionCount++;     // increment on front end for immediate viewing (already updating permanently on backend)
                        return { currentTicket };   // return updated object so it gets saved in the state
                    });

                    let commentsList = this.state.comments;

                    for (var comment of commentsList) {
                        if (comment.commenter_id === commenterId) {
                            comment.accepted_solution_count++;
                        }
                    }

                    this.setState({             // Update comments list with new decremented value (already saved on backend)
                        comments: commentsList
                    });

                    // grab the list of children again to update checkmarks as needed
                    this.getTicket(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);

                    // Grab history deatils again HERE !!!
                    this.getHistory(ticketId, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);

                })
                .catch(e => {
                    console.log(e);
                });
        }

    }

    // Called if the ticket owner clicks the select different solution button after previously picking a comment as the solution
    removeSolution(ticketId, isParent) {
        // Send an update to the backend with the blank solution text and open resolved status so it gets saved for future use
        // (all data is hardcoded on backend as null, but PUT expects a data object so send an empty one) - note added isParent at a later date
        this.hideRemoveParentSolutionWarning(); // hide the warning if it was present

        // Grab solver id for later updates
        let solver_id = this.state.currentTicket.solver_id;

        console.log("You're in remove solution!");

        HardwareTicketDataService.removeSolution(ticketId, { isParent: isParent, initiatorId: this.props.loggedStatus.id }, this.state.accessToken)
            .then(response => {
                // Set the solution text on the front end so we see it right away
                this.setState(prevState => {
                    let currentTicket = Object.assign({}, prevState.currentTicket); // create a copy of state variable currentTicket
                    currentTicket.solution_text = null; // update the copy's property (back to blank)
                    currentTicket.resolution_status = "Open";
                    currentTicket.solver_id = null;
                    currentTicket.solver_name = "";
                    currentTicket.solver_solution_count = 0;
                    return { currentTicket };   // return updated object so it gets saved in the state
                });

                let commentsList = this.state.comments;

                for (var comment of commentsList) {
                    if (comment.commenter_id === solver_id) {
                        comment.accepted_solution_count--;
                    }
                }

                this.setState({             // Update comments list with new decremented value (already saved on backend)
                    comments: commentsList
                });

                // grab the list of children again to update checkmarks as needed
                this.getTicket(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);

                // Grab ticket history again HERE !!!
                this.getHistory(ticketId, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);
            })
            .catch(e => {
                console.log(e);
            });


    }

    // calls the backend to change the designated ticket's assigned tech id to the one passed in as an argument
    updateTechAssignment(ticket_id, assigned_tech_id, token) {
        HardwareTicketDataService.updateTechAssignment(ticket_id, { techAssignedId: assigned_tech_id, requesterEmail: this.state.currentTicket.requester_email, assignerId: this.props.loggedStatus.id }, token)
            .then(response => {
                console.log("Tech successfully updated!");

                // fetch the history data again HERE !!!
                this.getHistory(ticket_id, this.props.loggedStatus.timezone_adjustment_factor, token);
            })
            .catch(e => {
                console.log(e);
            });
    }

    // displays the error that tech can't close ticket yet because the parent ticket still has unclarified children
    showCantPickSolutionMessage() {
        this.setState({
            showCantPickSolutionError: true
        });
    }

    // hides the error that tech can't close ticket yet because the parent ticket still has unclarified children
    hideCantPickSolutionMessage() {
        this.setState({
            showCantPickSolutionError: false
        });
    }

    // hides the confirmation window about removing a solution from a parent ticket with children
    hideRemoveParentSolutionWarning() {
        this.setState({
            showRemoveParentSolutionWarning: false
        });
    }

    // shows the confirmation window about removing a solution from a parent ticket with children
    showRemoveParentSolutionWarning() {
        this.setState({
            showRemoveParentSolutionWarning: true
        });
    }

    // closes the warning modal and also sets all temporary state values back to default so they're ready for next use
    hideAddParentSolutionWarning() {
        this.setState({
            showAddParentSolutionWarning: false,
            solutionTextForParent: "",
            ticketIdForParent: 0,
            commenterIdForParent: 0,
            commenterNameForParent: "",
            isParentForParent: 0,
            commenterSolutionCountForParent: 0
        });
    }

    // sets temporary state variables for use in the confirmation modal when user tries to add a parent solution
    showAddParentSolutionWarning(comment_text, ticket_id, commenter_id, name_of_commenter, is_parent, accepted_solution_count) {
        this.setState({
            showAddParentSolutionWarning: true,
            solutionTextForParent: comment_text,
            ticketIdForParent: ticket_id,
            commenterIdForParent: commenter_id,
            commenterNameForParent: name_of_commenter,
            isParentForParent: is_parent,
            commenterSolutionCountForParent: accepted_solution_count
        });
    }

    // hides the change parent ability warning modal
    hideChangeParentAbilityWarning() {
        this.setState({
            showChangeParentAbilityWarning: false
        });
    }

    // displays the change parent ability warning modal
    showChangeParentAbilityWarning() {
        this.setState({
            showChangeParentAbilityWarning: true
        });
    }

    openEditHardwareTicket(e, currentHardwareTicketId, currentHardwareTicketTitle, currentHardwareTicketIssue, currentHardwareTicketCategoryTypeIssue, currentHardwareTicketAsset, currentHardwareTicketPriority) {

        this.setState({
            editButtonEnabled: (this.state.editButtonEnabled === true) ? false : true,
            msg: "this was passed"
        });
        if (this.state.editButtonEnabled) {
            this.props.history.push('/createHardwareTicket', {
                HardwareTicketId: currentHardwareTicketId,
                HardwareTicketTitle: currentHardwareTicketTitle,
                HardwareTicketIssue: currentHardwareTicketIssue,
                HardwareTicketCategoryTypeIssue: currentHardwareTicketCategoryTypeIssue,
                HardwareTicketAsset: currentHardwareTicketAsset,
                HardwareTicketPriority: currentHardwareTicketPriority,
                enableEditHardwareTicket: true
            });
        }
    }

    onChangePrivateNote = (value) => {
        this.setState({
            private_note_text: value
        });

    }

    onChangeReplyText = (value) => {
        this.setState({
            reply_text: value
        });
    }

    changeParentAbility() {
        this.hideChangeParentAbilityWarning();
        // check the current value of is_parent (whatever it currently is, we're going to perform the opposite operation ie: if currently parent, we'll remove parent ability and v.v.)
        if (this.state.currentTicket.is_parent === 1) {
            // it's currently a parent, so we're going to remove that ability
            HardwareTicketDataService.removeAbilityToBeParent(this.state.currentTicket.id, { initiatorId: this.props.loggedStatus.id }, this.props.loggedStatus.accessToken)
                .then(response => {
                    console.log("Removed ability to be a parent");
                    // update the current state accordingly
                    this.setState(prevState => ({
                        currentTicket: {
                            ...prevState.currentTicket,
                            is_parent: 0
                        }
                    }));

                    // get history items HERE !!!
                    this.getHistory(this.state.currentTicket.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);

                })
                .catch(e => {
                    console.log(e);
                });
        }

        else {
            // this ticket was NOT currently a parent, so we're going to ADD that ability
            HardwareTicketDataService.allowToBeParent(this.state.currentTicket.id, { initiatorId: this.props.loggedStatus.id, currentParentId: this.state.currentTicket.parent_id }, this.props.loggedStatus.accessToken)
                .then(response => {
                    console.log("Added ability to be a parent");
                    // update the current state accordingly
                    this.setState(prevState => ({
                        currentTicket: {
                            ...prevState.currentTicket,
                            is_parent: 1,
                            parent_id: null
                        }
                    }));

                    // get history items HERE !!!
                    this.getHistory(this.state.currentTicket.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);
                })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }

        const { currentTicket, comments, accessToken } = this.state;

        // Figure out time to display for current ticket

        this.setTicketTimeSinceCreated(currentTicket);




        // Figure out time to display for all the comments to this ticket
        this.setCommentsTimeSinceCreated(comments);


        return (
            <>
                <Navbar pageTitle={currentTicket.id ? "Ticket # " + currentTicket.id : "  "} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                <Container fluid display="flex">
                    <Paper withBorder p="md" radius="md" ml="20px" w="325px" style={{ float: "right" }} className={classes.showHideMiniKnowledgebase}>
                        <p className="suggestedPostsTitle gothamNarrowFont"><u>Ticket History Timeline</u></p>
                        <span className="miniKnowledgebase showHideMiniKnowledgebase">
                            {this.state.history && this.state.history.map((event, index) => (
                                <div style={{ textAlign: "center" }} className="gothamNarrowLightFont" key={`history_item-${event.id}`}>
                                    <div><b>{event.description}</b></div>
                                    <div style={{ fontSize: "10pt" }}>{`${event.creator_first_name} ${event.creator_last_name}`}</div>
                                    <div style={{ fontSize: "10pt" }}>{event.formatted_date_time_created}</div>
                                    {index === this.state.history.length - 1 ?
                                        undefined
                                        :
                                        <div style={{ paddingTop: "10px", paddingBottom: "10px", color: "blue" }}>|</div>
                                    }
                                </div>
                            ))}
                        </span>
                    </Paper>
                    <Paper withBorder p="md" radius="md" mr="20px" w="235px" style={{ float: "left" }}>
                        <Button
                            fullWidth
                            justify="space-between"
                            p="5px"
                            mb="10px"
                            leftSection={<IconEdit />}
                            rightSection={<span />}
                            onClick={(e) => this.openEditHardwareTicket(e, currentTicket.id, currentTicket.ticket_title, currentTicket.issue_text, currentTicket.ticket_category, currentTicket.name_of_asset, currentTicket.ticket_priority)}
                        >
                            Edit Ticket
                        </Button>
                        {this.props.userPermissions.can_process_tickets ?
                            <>
                                {this.state.tech_notes && this.state.tech_notes.length > 0 ?
                                    <Button
                                        p="5px"
                                        justify="space-between"
                                        fullWidth
                                        leftSection={<IconPencil />}
                                        rightSection={<span />}
                                        onClick={() => this.openTechNotesModal()}
                                    >
                                        Tech Notes
                                    </Button>
                                    :
                                    <Button
                                        p="5px"
                                        justify="space-between"
                                        fullWidth
                                        leftSection={<IconPencil />}
                                        rightSection={<span />}
                                        onClick={() => this.openTechNotesModal()}
                                    >
                                        Tech Notes
                                    </Button>
                                }
                            </>
                            :
                            undefined
                        }
                        {this.props.userPermissions.can_manage_assignments ?
                            <ReassignTechSelector />
                            :
                            <div className="gothamNarrowLightFont">Technician Assigned: <div>{currentTicket.assigned_tech_name}</div></div>
                        }
                        {this.props.userPermissions.can_process_tickets ?
                            <div style={{ marginTop: "20px" }}>
                                <div>
                                    <Switch
                                        label="Allow Ticket to be a Parent"
                                        checked={this.state.currentTicket.is_parent}
                                        onChange={() => this.showChangeParentAbilityWarning()}
                                    />
                                </div>
                                {!this.state.currentTicket.is_parent ?
                                    <div>
                                        <div className="gothamNarrowFont" style={{ marginTop: "10px" }}>
                                            {`Parent Ticket (optional):`}
                                        </div>
                                        <div>
                                            {this.state.currentTicket.parent_ticket_resolution_status !== "Resolved" && /* this.state.currentTicket.parent_id !== null &&  */ /* this.state.currentTicket.resolution_status !== "Resolved"  && */ /* this.state.currentTicket.parent_ticket_resolution_status !== "Resolved" && */
                                                <ParentTicketSelector ticket_type={"Hardware"} ticket_id={currentTicket.id} parent_id={currentTicket.parent_id} initiator_id={this.props.loggedStatus.id} timezone_adjustment_factor={this.props.loggedStatus.timezone_adjustment_factor} getHistory={this.getHistory} /* trigger={this.state.trigger} */ />
                                            }
                                            {this.state.currentTicket.parent_ticket_resolution_status === "Resolved" &&
                                                <div>
                                                    <span>{`Resolved Parent: `} <Link to={`/hardwareTickets/${this.state.currentTicket.parent_id}`}>{`${this.state.currentTicket.parent_ticket_title} - (#${this.state.currentTicket.parent_id})`}</Link></span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="gothamNarrowFont" style={{ marginTop: "10px" }}>
                                            Assigned Child Tickets:
                                        </div>
                                        {this.state.currentTicket.unclarified_children && this.state.currentTicket.unclarified_children.length > 0 ?
                                            <div className="gothamNarrowLightFont" style={{ marginTop: "10px" }}>Unclarified</div>
                                            :
                                            undefined
                                        }
                                        {this.state.currentTicket.unclarified_children && this.state.currentTicket.unclarified_children.length > 0 && this.state.currentTicket.unclarified_children.map((child) => (
                                            <div key={`Unclarified-Child-${child.id}`}>
                                                <Link to={`/clarifyTicket/${child.id}`}>{`${child.ticket_title} - (# ${child.id})`}</Link>
                                            </div>
                                        ))
                                        }
                                        {this.state.currentTicket.hardware_children && this.state.currentTicket.hardware_children.length > 0 ?
                                            <div className="gothamNarrowLightFont" style={{ marginTop: "10px" }}>Hardware</div>
                                            :
                                            undefined
                                        }
                                        {this.state.currentTicket.hardware_children && this.state.currentTicket.hardware_children.length > 0 && this.state.currentTicket.hardware_children.map((child) => (
                                            <div key={`Hardware-Child-${child.id}`}>
                                                <Link to={`/hardwareTickets/${child.id}`}>{`${child.ticket_title} - (# ${child.id})`}</Link>
                                                {child.solution_text !== null && child.solution_text !== "null" && <span style={{ marginLeft: "10px" }}><FontAwesomeIcon style={{ color: "green" }} icon={faCheckCircle} /></span>}
                                            </div>
                                        ))
                                        }
                                        <Space m="15px" />
                                    </div>
                                }
                            </div>
                            :
                            undefined
                        }
                        {this.props.userPermissions.can_process_tickets && this.state.lastLoggedInDevices ?
                            <div style={{ marginTop: "30px" }}><PossiblyAffectedDevices deviceList={this.state.lastLoggedInDevices} /></div>
                            :
                            undefined
                        }
                    </Paper>
                    <Paper withBorder p="md" radius="md" m="5px" style={{ overflow: "auto" }}>
                        <Row>
                            <Col>
                                <Table>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <div style={{ display: "flex" }}>
                                                <div>
                                                    <Text display="inline-block" m="5px" size="50px">{currentTicket.ticket_title}</Text>
                                                    <Text display="block" m="5px" size="20px" c="gray">{currentTicket.ticket_category}</Text>
                                                </div>
                                                <div style={{ marginLeft: "auto", marginTop: "4px" }}>
                                                    {
                                                        currentTicket.resolution_status === "Resolved" ?
                                                            <Text style={{ whiteSpace: "nowrap" }} span size="25px">Status: <Text size="25px" span c="green">{currentTicket.resolution_status}</Text></Text> :
                                                            <Text style={{ whiteSpace: "nowrap" }} span size="25px">Status: <Text size="25px" span c="orange">{currentTicket.resolution_status}</Text></Text>
                                                    }
                                                </div>
                                            </div>
                                            <Space m="20px" />
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Col style={{ color: "rgba(75,75,75,1)" }}>
                                                <div className="gothamNarrowFont"><b>{currentTicket.name_of_requester}</b><span style={{ position: "absolute", paddingLeft: "10px" }}>{currentTicket.requester_id ? <TicketSubmitterDetails submitterId={currentTicket.requester_id} token={this.props.loggedStatus.accessToken} /> : undefined}</span></div>
                                            </Col>
                                            <Col style={{ display: "flex", justifyContent: "flex-end", marginBottom: "25px" }}>
                                                <div className="gothamNarrowLightFont">{`${currentTicket.formatted_date_time_created} -- (${currentTicket.time_since_sent})`}</div>
                                            </Col>
                                            <Row>
                                                <Col>
                                                    <div className="gothamNarrowLightFont">{parse(cleanHTML(currentTicket.issue_text))}</div>
                                                    {/* <div className="gothamNarrowLightFont">{currentTicket.issue_text.split('\n').map(str => <p>{str}</p>)}</div> */}
                                                </Col>
                                            </Row>
                                            { /*If the ticket has a solution currently selected display it here */
                                                currentTicket.solution_text !== null ?
                                                    <Row>
                                                        <Col>
                                                            <div className="acceptedSolutionWrapper">
                                                                <div>
                                                                    <div className="gothamNarrowFont" style={{ paddingLeft: "5px" }}>Accepted Solution:</div>
                                                                    <div className="gothamNarrowLightFont" style={{ marginTop: "5px", paddingLeft: "25px" }}>{parse(cleanHTML(currentTicket.solution_text))}</div>
                                                                    {/* <div className="gothamNarrowLightFont" style={{marginTop: "5px", paddingLeft: "25px"}}>{currentTicket.solution_text.split('\n').map(str => <p>{str}</p>)}</div> */}
                                                                    {/* Display information about the person who solved it */
                                                                        currentTicket.solver_id !== null ?
                                                                            <div>
                                                                                <div className="gothamNarrowLightFont" style={{ paddingLeft: "5px", marginTop: "5px" }}>Solved by: {currentTicket.solver_name}</div>
                                                                                <div className="gothamNarrowLightFont" style={{ paddingLeft: "5px" }}>User's solve count: {currentTicket.solver_solution_count}</div>
                                                                            </div> :
                                                                            undefined
                                                                    }
                                                                    {/*If the user is the owner, give them the option to select a different solution */
                                                                        currentTicket.isTicketOwner || this.props.userPermissions.can_add_solutions ?
                                                                            <div style={{ textAlign: "right", marginRight: "15px", paddingBottom: "15px" }}>
                                                                                {this.state.currentTicket.hardware_children && this.state.currentTicket.hardware_children.length > 0 ?
                                                                                    <Button variant="outline-secondary" onClick={() => this.showRemoveParentSolutionWarning()}>Pick A Different Solution</Button>
                                                                                    :
                                                                                    <Button variant="outline-secondary" onClick={() => this.removeSolution(currentTicket.id, currentTicket.is_parent)}>Pick A Different Solution</Button>
                                                                                }
                                                                            </div> :
                                                                            undefined
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row> :
                                                    undefined
                                            }
                                            <Row>
                                                <Col>
                                                    {this.state.attachments && this.state.attachments.some(e => e.comment_id === null) &&
                                                        <div className="gothamNarrowFont">Attachments</div>
                                                    }
                                                    <div>
                                                        <ol>
                                                            {(currentTicket.isTicketOwner || this.props.userPermissions.can_see_attachments) && this.state.attachments && this.state.attachments.map((attachment) => {
                                                                return attachment.comment_id == null ?
                                                                    <li key={`attachment${attachment.id}`} className="gothamNarrowLightFont">
                                                                        <a href={attachment.attachment_url} target="_blank" rel="noopener noreferrer">{attachment.filename}</a>
                                                                    </li>
                                                                    :
                                                                    undefined
                                                            })}
                                                        </ol>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row style={{ marginTop: "15px" }}>
                                                <Col>
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <Button variant="outline-success" onClick={() => this.addALike(currentTicket.id, this.props.loggedStatus.id, accessToken)} className="gothamNarrowFont" style={{
                                                            backgroundColor: this.state.button_liked ? 'green' : 'white',
                                                            color: this.state.button_liked ? 'white' : 'green'
                                                        }}>Helpful</Button>{' '}
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div style={{ textAlign: "right" }}>
                                                        {currentTicket.resolution_status === "Open" ?
                                                            <Button variant="outline-secondary" onClick={() => this.replyToOriginalPost(currentTicket.name_of_requester, currentTicket.issue_text, currentTicket.id)} className="gothamNarrowFont">Reply</Button>
                                                            : undefined
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Space m="15px" />
                                        </Table.Tr>
                                        <div style={{ marginBottom: "10px", color: "rgba(75,75,75,1)" }} className="gothamNarrowFont"><b>Replies</b></div>
                                        {comments && comments.map((comment) => {
                                            return (
                                                <Table.Tr>
                                                    <table style={{ width: "100%" }}>
                                                        <tr>
                                                            <td>
                                                                <div style={{ color: "rgba(75,75,75,1)" }} className="gothamNarrowFont"><b>{comment.name_of_commenter}</b></div>
                                                                <div>(Solution count: {comment.accepted_solution_count})</div>
                                                            </td>
                                                            {/* <td style={{display: "flex", justifyContent: "flex-end"}}> */}
                                                            <td>
                                                                <div style={{ textAlign: "right" }} className="gothamNarrowLightFont">{`${comment.formatted_date_time_created} -- (${comment.time_since_sent})`}</div>
                                                            </td>
                                                            {/* {comment.associated_comment_id === null ? <td>{comment.comment_text}</td> : <td>{comment.comment_text}</td>} */}
                                                        </tr>
                                                        <tr>
                                                            <td colSpan="2" className="commentReplyingToTableRow">
                                                                {/*<div className="commentReplyingToWrapper">*/} {/*If associated comment id is null, it means they replied to base ticket, if not null, they replied to another commment */}

                                                                {/*comment.associated_comment_id !== null ? 
                                                            <div>
                                                                <div className="gothamNarrowLightFont">Replying to: {comment.name_of_reply_commenter} (comment)</div>
                                                                <div className="gothamNarrowLightFont" style={{marginTop: "5px", paddingLeft: "25px"}}>{comment.comment_text_replying_to.split('\n').map(str => <p>{str}</p>)}</div>
                                                            </div>
                                                             :
                                                            <div>
                                                                <div className="gothamNarrowLightFont">Replying to: {comment.name_of_ticket_creator} (base ticket)</div>
                                                                <div className="gothamNarrowLightFont" style={{marginTop: "5px", paddingLeft: "25px"}}>{comment.ticket_text_replying_to.split('\n').map(str => <p>{str}</p>)}</div>
                                                            </div>    
                                                            */}

                                                                {/*</div>*/}
                                                                <div className="gothamNarrowLightFont">{parse(cleanHTML(comment.comment_text))}</div>
                                                                {/* <div className="gothamNarrowLightFont">{comment.comment_text.split('\n').map(str => <p>{str}</p>)}</div> */}
                                                                {this.state.attachments && this.state.attachments.some(e => e.comment_id === comment.id) &&
                                                                    <div className="gothamNarrowFont">Attachments</div>
                                                                }
                                                                <ol>
                                                                    {(currentTicket.isTicketOwner || this.props.userPermissions.can_see_attachments) && this.state.attachments && this.state.attachments.map((attachment) => {
                                                                        return attachment.comment_id === comment.id ?
                                                                            <li key={`attachment${attachment.id}`} className="gothamNarrowLightFont">
                                                                                <a href={attachment.attachment_url} target="_blank" rel="noopener noreferrer">{attachment.filename}</a>
                                                                            </li>
                                                                            :
                                                                            undefined
                                                                    })}
                                                                </ol>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td className="replyButtonTableRow">
                                                                {
                                                                    currentTicket.solution_text === null && (currentTicket.isTicketOwner || this.props.userPermissions.can_add_solutions) ?
                                                                        <div style={{ textAlign: "left" }}>
                                                                            {this.state.currentTicket.hardware_children && this.state.currentTicket.hardware_children.length > 0 ?
                                                                                <Button variant="outline-success" onClick={() => this.showAddParentSolutionWarning(comment.comment_text, currentTicket.id, comment.commenter_id, comment.name_of_commenter, currentTicket.is_parent, comment.accepted_solution_count)}>Mark As Solution</Button>
                                                                                :
                                                                                <Button variant="outline-success" onClick={() => this.selectSolution(comment.comment_text, currentTicket.id, comment.commenter_id, comment.name_of_commenter, currentTicket.is_parent, comment.accepted_solution_count)}>Mark As Solution</Button>
                                                                            }
                                                                        </div> :
                                                                        <div></div>
                                                                }
                                                            </td>
                                                            <td className="replyButtonTableRow">
                                                                <div style={{ textAlign: "right" }}>
                                                                    {
                                                                        currentTicket.resolution_status === "Open" ?
                                                                            <Button variant="outline-secondary" onClick={() => this.replyToComment(comment.name_of_commenter, comment.comment_text, currentTicket.id, comment.id)} className="gothamNarrowFont">Reply</Button>
                                                                            : undefined
                                                                    }
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                    <Space m="15px" />
                                                </Table.Tr>
                                            );
                                        })}
                                    </Table.Tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Paper>
                </Container>
                <Modal
                    opened={this.state.show_reply_form}
                    onClose={() => this.cancelReply()}
                    size="xl"
                    centered
                    title={`Reply to ${this.state.name_replying_to}`}
                >
                    <Modal.Body>
                        <div>
                            <Container style={{ overflow: "scroll", height: "150px" }}>
                                <Row>
                                    <Col>
                                        <div style={{ textAlign: "left" }} className="gothamNarrowFont">Ticket / Comment Replying To: </div>
                                    </Col>
                                    <Col>
                                        <div style={{ textAlign: "left" }} className="gothamNarrowLightFont">{parse(cleanHTML(this.state.issue_text_responding_to))}</div>
                                        {/* <div style={{textAlign: "left"}} className="gothamNarrowLightFont">{this.state.issue_text_responding_to.split('\n').map(str => <p>{str}</p>)}</div> */}
                                    </Col>
                                </Row>
                            </Container>
                            <hr />
                            <Form onSubmit={this.submitReply}>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCommenterId">
                                        <Form.Control type="hidden" value={this.props.loggedStatus.id} name="commenterId" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAssociatedTicketId">
                                        <Form.Control type="hidden" value={this.state.currentTicket.id} name="associatedTicketId" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAssociatedCommentId">
                                        <Form.Control type="hidden" value={this.state.comment_id_responding_to} name="associatedCommentId" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridTimeofResponse">
                                        <Form.Control type="hidden" value={this.state.currentTicket.time_of_response} name="timeOfResponse" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridReplyText">
                                        <Form.Label className="gothamNarrowFont">Your Response</Form.Label>
                                        <div data-text-editor="name">
                                            <RichTextInput defaultValue={this.state.reply_text} onChange={e => this.onChangeReplyText(e)} />
                                        </div>
                                        {/* <Form.Control as="textarea" style={{height: "250px"}} className="gothamNarrowLightFont" placeholder="Type your reply here" name="responseText" required/> */}
                                        <Form.Text className="text-muted gothamNarrowLightFont">
                                            Note: If you are referencing a specific part of the user's comment, consider copy/pasting the relevant section from the issue text above into this box for others' reference.
                                        </Form.Text>
                                        {this.state.showNoResponseError ?
                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: No Response Provided</Form.Text>
                                            :
                                            undefined
                                        }
                                        {this.state.showImageDetectedError ?
                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: Image detected in response. Please remove the image from the text box and use the Upload Attachments button below</Form.Text>
                                            :
                                            undefined
                                        }
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="attachmentUpload">
                                        <Button variant="info" onClick={() => this.handleUploadButtonClick()}>Upload Attachments</Button>
                                        {/* <Form.Label className="gothamNarrowLightFont">Attachment(s) (optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, or .txt and less than 50MB)</Form.Label> */}
                                        <Form.Text className="gothamNarrowLightFont">(optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, or .txt and less than 50MB)</Form.Text>
                                        <Form.Control className="gothamNarrowLightFont" type="file" accept=".jpeg, .jpg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, .txt" multiple onChange={(e) => this.handleAttachmentUploadChange(e)} style={{ display: "none" }} id="hiddenUploadButton" />
                                    </Form.Group>
                                </Form.Row>
                                {this.state.attachmentFileNameList && this.state.attachmentFileNameList.length > 0 ?
                                    <div style={{ paddingBottom: "20px" }}>
                                        <div className="gothamNarrowFont">Included Attachments:</div>
                                        {this.state.attachmentFileNameList && this.state.attachmentFileNameList.map(file => (
                                            <div key={`${file.name}`} className="gothamNarrowLightFont">
                                                <span style={{ paddingRight: "10px" }}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)" }} icon={faTimes} onClick={() => this.handleAttachmentDelete(file)} /></span>{file.name}
                                            </div>
                                        ))

                                        }
                                    </div>
                                    :
                                    undefined
                                }
                                {this.props.userPermissions.can_process_tickets && currentTicket.is_parent ?
                                    <div>
                                        <Form.Switch
                                            id="replyToParentGroupToggle"
                                            label="Reply to Parent Group"
                                            onClick={() => this.handleRespondToParentGroupChange()}
                                            checked={this.state.replyToParentGroup}
                                        />
                                    </div>
                                    :
                                    undefined
                                }

                                {this.props.userPermissions.can_process_tickets ?
                                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                                        <Form.Switch
                                            id="immediateMarkAsSolution"
                                            label="Mark As Solution"
                                            onClick={() => this.handleImmediateMarkAsSolutionChange()}
                                            checked={this.state.immediateMarkAsSolution}
                                        />
                                    </div>
                                    :
                                    undefined
                                }
                                <div style={{ textAlign: "center" }}>
                                    <Button variant="primary" type="submit" className="gothamNarrowFont">
                                        Submit
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>

                {/* This modal is for the loading screen */}
                <Modal
                    opened={this.state.showSaving}
                    onClose={() => { this.setState({ showSaving: false }) }}
                    size="xl"
                    centered
                >


                    <Modal.Body>
                        <div className="gothamNarrowLightFont" style={{ cursor: "default", textAlign: "center" }}>Saving... Please wait.</div>
                    </Modal.Body>

                </Modal>

                {/* This modal is for the stale token warning */}
                <Modal
                    opened={this.state.showStaleTokenError}
                    onClose={() => { this.setState({ showStaleTokenError: false }) }}
                    size="xl"
                    centered
                    title="Operation failed due to stale token"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Error: The token used to authenticate with Microsoft has become stale.</p>
                            <p className="gothamNarrowLightFont">Please copy any information needed on the form, refresh the page, and try submitting again.</p>
                        </span>
                    </Modal.Body>
                    <div>
                        <Button variant="primary" onClick={() => this.hideStaleTokenError()} className="gothamNarrowFont">Ok</Button>
                    </div>
                </Modal>

                {/* This modal is for the can't mark solution with unclarified children warning */}
                <Modal
                    opened={this.state.showCantPickSolutionError}
                    onClose={() => { this.setState({ showCantPickSolutionError: false }) }}
                    size="xl"
                    centered
                    title="Can not close parent ticket yet"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Error: This parent ticket still has unclarified children.</p>
                            <p className="gothamNarrowLightFont">Please clarify these children before closing the parent ticket.</p>
                        </span>
                    </Modal.Body>
                    <div>
                        <Button variant="primary" onClick={() => this.hideCantPickSolutionMessage()} className="gothamNarrowFont">Ok</Button>
                    </div>
                </Modal>

                {/* This modal is for the remove solution from parent warning */}
                <Modal
                    opened={this.state.showRemoveParentSolutionWarning}
                    onClose={() => { this.setState({ showRemoveParentSolutionWarning: false }) }}
                    size="xl"
                    centered
                    title="Are you sure you want to remove this solution?"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Note: Removing the solution from a parent will affect all children with the identical chosen solution.</p>
                        </span>
                    </Modal.Body>
                    <div>
                        <Button variant="secondary" onClick={() => this.hideRemoveParentSolutionWarning()}style={{marginRight: "15px"}}  className="gothamNarrowFont">Cancel</Button>
                        <Button variant="primary" onClick={() => this.removeSolution(this.state.currentTicket.id, this.state.currentTicket.is_parent)} className="gothamNarrowFont">Ok</Button>
                    </div>
                </Modal>

                {/* This modal is for the add solution to parent warning */}
                <Modal
                    opened={this.state.showAddParentSolutionWarning}
                    onClose={() => { this.setState({ showAddParentSolutionWarning: false }) }}
                    size="xl"
                    centered
                    title="Are you sure you want to select this solution?"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Note: Selecting a solution on a parent ticket will give that solution to and close all remaining child tickets.</p>
                        </span>
                    </Modal.Body>
                    <div>
                        <Button variant="secondary" onClick={() => this.hideAddParentSolutionWarning()} style={{marginRight: "15px"}} className="gothamNarrowFont">Cancel</Button>
                        <Button variant="primary" onClick={() => this.selectSolution(this.state.solutionTextForParent, this.state.ticketIdForParent, this.state.commenterIdForParent, this.state.commenterNameForParent, this.state.isParentForParent, this.state.commenterSolutionCountForParent)} className="gothamNarrowFont">Ok</Button>
                    </div>
                </Modal>

                {/* This modal is for the change parent ability warning */}
                <Modal
                    opened={this.state.showChangeParentAbilityWarning}
                    onClose={() => { this.setState({ showChangeParentAbilityWarning: false }) }}
                    size="md"
                    centered
                    title="Are you sure you want to change the parent status of this ticket?"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Note: If a child ticket is made a parent, it will no longer be a child of that original parent.</p>
                            <p className="gothamNarrowLightFont">If a parent ticket has its parent ability turned off, the parent group will be disbanded.</p>
                        </span>
                    </Modal.Body>
                    <Button variant="secondary" onClick={() => this.hideChangeParentAbilityWarning()} className="gothamNarrowFont">Cancel</Button>
                    <Button variant="primary" onClick={() => this.changeParentAbility()} style={{ marginLeft: "10px" }} className="gothamNarrowFont">Ok</Button>
                </Modal>

                {/* This modal is the view tech note pop up */}
                <Modal
                    opened={this.state.show_tech_note}
                    onClose={this.hideViewTechNote}
                    size="xl"
                    centered
                    title="Viewing Tech Note"
                >
                    <Modal.Body>
                        <div style={{ maxHeight: "200px", overflow: "scroll", cursor: "default" }}>
                            <p className="gothamNarrowLightFont"><b>Time Created:</b> {this.state.tech_note_viewing_time_created}</p>
                            <p className="gothamNarrowLightFont"><b>Creator Name:</b> {this.state.tech_note_viewing_creator_name}</p>
                            <p className="gothamNarrowLightFont"><b>Note Text:</b></p>
                            <p className="gothamNarrowLightFont">{parse(cleanHTML(this.state.tech_note_viewing_note_text))}</p>
                            {/* <p className="gothamNarrowLightFont">{this.state.tech_note_viewing_note_text.split('\n').map(str => <p>{str}</p>)}</p> */}
                        </div>
                    </Modal.Body>
                    <div>
                        <Button variant="primary" onClick={() => this.hideViewTechNote()} className="gothamNarrowFont">Ok</Button>
                    </div>
                </Modal>

                {/* This modal is for the tech notes screen */}
                <Modal
                    opened={this.state.showTechNotesModal}
                    onClose={() => this.closeTechNotesModal()}
                    size="xl"
                    centered
                    title="Technician Notes"
                >
                    <Modal.Body>
                        <div>
                            <Form style={{ maxWidth: "100%", maxHeight: "100%", paddingLeft: "1px" }} onSubmit={this.createTechNote}>
                                <   Form.Row>
                                    <Form.Group as={Col} controlId="formGridCommenterId">
                                        <Form.Control type="hidden" value={this.props.loggedStatus.id} name="commenterIdTechNote" />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAssociatedTicketId">
                                        <Form.Control type="hidden" value={this.state.currentTicket.id} name="associatedTicketIdTechNote" />
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="techResponseTechNote">
                                        <Form.Label>Private Note Text</Form.Label>
                                        <div data-text-editor="name">
                                            <RichTextInput defaultValue={this.state.private_note_text} onChange={e => this.onChangePrivateNote(e)} />
                                        </div>
                                        {this.state.showNoResponseError ?
                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: No Response Provided</Form.Text>
                                            :
                                            undefined
                                        }
                                        {this.state.showImageDetectedError ?
                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: Image detected in response. Please remove the image from the text box and use the Upload Attachments button below</Form.Text>
                                            :
                                            undefined
                                        }
                                        {/* <Form.Control as="textarea" style={{height: "125px"}} name="privateNote" value={this.state.private_note_text} className="gothamNarrowLightFont" onChange={this.onChangePrivateNote} defaultValue={this.state.private_note_text} required></Form.Control> */}
                                    </Form.Group>

                                </Form.Row>
                                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="attachmentUploadTechNote">
                                            <Button variant="info" onClick={() => this.handleUploadButtonClickTechNote()}>Upload Attachments</Button>
                                            {/* <Form.Label className="gothamNarrowLightFont">Attachment(s) (optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, or .txt and less than 50MB)</Form.Label> */}
                                            <Form.Text className="gothamNarrowLightFont">(optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, or .txt and less than 50MB)</Form.Text>
                                            <Form.Control className="gothamNarrowLightFont" type="file" accept=".jpeg, .jpg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, .txt" multiple onChange={(e) => this.handleAttachmentUploadChangeTechNote(e)} style={{ display: "none" }} id="hiddenUploadButtonTechNote" />
                                        </Form.Group>

                                    </Form.Row>
                                </div>
                                {this.state.attachmentFileNameListTechNote && this.state.attachmentFileNameListTechNote.length > 0 ?
                                    <div style={{ paddingBottom: "20px" }}>
                                        <div className="gothamNarrowFont">Included Attachments:</div>
                                        {this.state.attachmentFileNameListTechNote && this.state.attachmentFileNameListTechNote.map(file => (
                                            <div key={`${file.name}`} className="gothamNarrowLightFont">
                                                <span style={{ paddingRight: "10px" }}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)" }} icon={faTimes} onClick={() => this.handleAttachmentDeleteTechNote(file)} /></span>{file.name}
                                            </div>
                                        ))

                                        }
                                    </div>
                                    :
                                    undefined
                                }
                                <div className="containterClarifyTicketFormSubmitButton">
                                    <Button variant="primary" type="submit" className="gothamNarrowFont">Submit</Button>
                                </div>
                            </Form>
                        </div>

                        <div className="scrollTableWrapperTechNotes">
                            <Table striped bordered hover>
                                <thead>
                                    <tr className="techNoteTableHead">
                                        <th style={{ position: "sticky", top: "0", width: "50%" }} className="gothamNarrowFont">Note Preview</th>
                                        <th style={{ position: "sticky", top: "0", width: "25%" }} className="gothamNarrowFont">Created By</th>
                                        <th style={{ position: "sticky", top: "0", width: "25%" }} className="gothamNarrowFont">Time Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.tech_notes && this.state.tech_notes.map((note) => (
                                        <tr key={note.id} onClick={() => this.showViewTechNote(note.formatted_date_time_created, note.creator_name, note.note_text)}>
                                            <td className="gothamNarrowLightFont">{note.note_text.length > 40 ? `${note.note_text.substring(0, 40)}...(more)` : note.note_text}</td>
                                            <td className="gothamNarrowLightFont">{note.creator_name.length > 17 ? `${note.creator_name.substring(0, 17)}...` : note.creator_name}</td>
                                            <td className="gothamNarrowLightFont">{note.formatted_date_time_created}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                        <div>
                            <p className="gothamNarrowLightFont" style={{ textAlign: "center" }}>Click on a table row to view the full note</p>
                        </div>
                    </Modal.Body>

                </Modal>

            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        userPermissions: state.userPermissions,
        reassignTech: state.reassignTech
    };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_ASSIGNMENT, ASSIGNMENT_CHANGE_RESOLVED, ACCESSTOKEN, LOGOUT })(HardwareTicketDetails);