const initialState = {
    active_assignment_name: "All",        // The name of the person currently selected in assigned to drop down menu (of reassignment drop down)
    active_assignment_id: 0,          // The user id of the person selected in the assigned to drop down (of reassignment drop down)
    active_assignment_name_assigned_tickets_filter: "All",      // The name of the person currently selected in my assigned tickets filter drop down
    active_assignment_id_assigned_tickets_filter: 0,    // The user id of the person selected in the my assigned tickets filter drop down
    assignment_change_detected: false,   // gets set to true if user clicks new option in the assigned to drop down menu of my assigned tickets table
    status_name: "Open",     // Value selected in the status drop down menu, default to Open
    status_change_detected: false,       // gets set to true if user clicks new option in the status drop down menu of my assigned tickets table
    type_name: "All",    // Value selected in the type drop down menu, default to All (Holding Table tickets + Hardware)
    type_change_detected: false, // gets set to true if user clicks new option in the type drop down menu of my assigned tickets table
    priority_name: "All",       // Value selected in priority drop down menu, default to All
    priority_id: 0,     // the id of the SLA the user currently has selected in the drop down
    priority_change_detected: false,     // gets set to true if user clicks new option in the priority drop down menu of my assigned tickets table

    search_text_entered: "",     // the text that user has entered into the search bar of the my assigned tickets page
    sort_by: "date",     // the column being sorted on in the my assigned tickets page
    sort_order: "asc"      // the order being sorted on in the my assigned tickets page
};

function myAssignedTicketsReducer(state = initialState, action) {
    switch(action.type) {
        case "UPDATE_ACTIVE_ASSIGNMENT":
            //console.log("Login event detected");
            //console.log(action.payload);
            if(action.payload && action.payload.type === "Assigned Tickets Filter") {        // this was coming from the filter on my assigned tickets so affect those values
                return {
                    ...state,
                    active_assignment_name_assigned_tickets_filter: action.payload.name,
                    active_assignment_id_assigned_tickets_filter: action.payload.id 
                };
            }
            else if(action.payload) {   // this came from the reassign tech selector on the ticket details page, etc, so affect those values
                return {
                    ...state,
                    active_assignment_name: action.payload.name,
                    active_assignment_id: action.payload.id 
                };
            }
            break;

        case "UPDATE_ACTIVE_STATUS":
            if(action.payload) {
                return {
                    ...state,
                    status_name: action.payload
                };
            }
            break;

        case "UPDATE_ACTIVE_PRIORITY":
            if(action.payload) {
                return {
                    ...state,
                    priority_name: action.payload.name,
                    priority_id: action.payload.id
                };
            }
            break;

        case "UPDATE_ACTIVE_TYPE":
            if(action.payload === "Unclarified") {
                return {
                    ...state,
                    type_name: action.payload,
                    priority_name: "All"        // unclarified does not have priorities
                };
            }
            else if(action.payload) {
                return {
                    ...state,
                    type_name: action.payload
                }
            }
            break;

        case "UPDATE_SEARCH_TEXT":
            if(action.payload === null || action.payload === "") {
                return {
                    ...state,
                    search_text_entered: ""
                }
            }
            else {
                return {
                    ...state,
                    search_text_entered: action.payload
                }
            }
            //break;

        case "UPDATE_SORT_BY":
            if(action.payload) {
                return {
                    ...state,
                    sort_by: action.payload
                }
            }
            break;

        case "UPDATE_SORT_ORDER":
            if(action.payload) {
                return {
                    ...state,
                    sort_order: action.payload
                }
            }
            break;

        case "ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN":   // only gets fired from selecting new assigned to from dropdown menu
            return {
                ...state,
                assignment_change_detected: true    // will prompt componentDidUpdate to repopulate assigned tickets table
            }

        case "ASSIGNMENT_CHANGE_RESOLVED":      // gets called from My-Assigned-Tickets.js as soon as assigned to change detected so only fires function once
            return {
                ...state,
                assignment_change_detected: false
            }

        case "ALERT_STATUS_CHANGE_FROM_DROPDOWN":
            return {
                ...state,
                status_change_detected: true    // will prompt componentDidUpdate to repopulate assigned tickets table
            }

        case "STATUS_CHANGE_RESOLVED":
            return {
                ...state,
                status_change_detected: false
            }

        case "ALERT_PRIORITY_CHANGE_FROM_DROPDOWN":
            return {
                ...state,
                priority_change_detected: true    // will prompt componentDidUpdate to repopulate assigned tickets table
            }

        case "PRIORITY_CHANGE_RESOLVED":
            return {
                ...state,
                priority_change_detected: false
            }

        case "ALERT_TYPE_CHANGE_FROM_DROPDOWN":
            return {
                ...state,
                type_change_detected: true  // will prompt componentDidUpdate to repopulate assigned tickets table
            }

        case "TYPE_CHANGE_RESOLVED":
            return {
                ...state,
                type_change_detected: false
            }

        case "RESET_FILTERS":   // puts everything back to their default values. called on page load of my assigned tickets in case any left over data
            return {
                ...state,
                active_assignment_name: "All",        // The name of the person currently selected in assigned to drop down menu
                active_assignment_id: 0,          // The user id of the person selected in the assigned to drop down
                active_assignment_name_assigned_tickets_filter: "All",      // The name of the person currently selected in my assigned tickets filter drop down
                active_assignment_id_assigned_tickets_filter: 0,    // The user id of the person selected in the my assigned tickets filter drop down
                assignment_change_detected: false,   // gets set to true if user clicks new option in the assigned to drop down menu of my assigned tickets table
                status_name: "Open",     // Value selected in the status drop down menu, default to Open
                status_change_detected: false,       // gets set to true if user clicks new option in the status drop down menu of my assigned tickets table
                type_name: "All",    // Value selected in the type drop down menu, default to All (Holding Table tickets + Hardware Tickets)
                type_change_detected: false, // gets set to true if user clicks new option in the type drop down menu of my assigned tickets table
                priority_name: "All",       // Value selected in priority drop down menu, default to All
                priority_id: 0,
                priority_change_detected: false,     // gets set to true if user clicks new option in the priority drop down menu of my assigned tickets table

                search_text_entered: "",     // the text that user has entered into the search bar of the my assigned tickets page
                sort_by: "date",     // the column being sorted on in the my assigned tickets page
                sort_order: "asc"      // the order being sorted on in the my assigned tickets page
            }


        default:
            return state;


    }
}

export default myAssignedTicketsReducer;
