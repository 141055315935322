import React, { Component } from "react";
// import AccountDataService from "../services/account-activities";
import MonitoringDataService from '../../services/monitoring-activities';
import HardwareAssetDataService from '../../services/hardware-asset-activities';
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { ACCESSTOKEN, SET_MONITORING_TENANT_VIEWING, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

import { Motion, spring } from "react-motion";

import { RingProgress, Text, SimpleGrid, Paper, Center, Group, rem } from '@mantine/core';
import {
    Progress,
    Box,
    Table,
    TextInput,
    Grid,
    keys,
    UnstyledButton,
    Container,
    Flex,
    Button
} from '@mantine/core';
import classes from '../TableSort.module.css';

import Navbar from '../../components/Navbar';

import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';

import DashboardGrid from "./DashboardGrid";

//import Card from 'react-bootstrap/Card';

//import {Link} from 'react-router-dom';

//import ListGroup from 'react-bootstrap/ListGroup';

//import ChartWidget from '../Chart-Widget';       // WILL END UP BEING SOMETHING DIFFERENT FOR ADMIN

class Monitoring extends Component {

    constructor(props) {
        super(props);


        this.state = {
            tenants: [],     // [{id: 1, name_of_tenant: "LPA"}]
            selectedTenant: "",
            selectedTenantId: 0,
        };
    }

    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {

                HardwareAssetDataService.getTenants(token, this.props.loggedStatus.id).then(response => {
                    console.log(response);
                    this.setState({
                        tenants: response.data.results
                    }, () => {
                        //this.setReportTenant(this.state.tenants);   // update the global state with the tenant selected

                        // if this is first time landing on this page, we need to update the global state and local one
                        if (this.props.loggedStatus.dashboard_tenant_name_selected === "") {

                            if (response.data.results && response.data.results.length > 0) {
                                this.props.SET_DASHBOARD_TENANT_VIEWING({ dasboard_tenant_id_selected: response.data.results[0].id, dashboard_tenant_name_selected: response.data.results[0].name_of_tenant });
                                this.setState({
                                    selectedTenant: response.data.results[0].name_of_tenant,
                                    selectedTenantId: response.data.results[0].id
                                });
                            }
                        }

                        else {
                            // we've landed on this page before, so set the local state to the global one

                            this.setState({
                                selectedTenant: this.props.loggedStatus.dashboard_tenant_name_selected,
                                selectedTenantId: this.props.loggedStatus.dashboard_tenant_id_selected
                            });
                        }
                    });
                })
                    .catch(e => {
                        console.log(e);
                    });

            });
        }
    }

    handleDashboardTenantChange(name_of_tenant) {

        // if this is a new selection, update the global state
        if (name_of_tenant !== this.state.selectedTenant) {
            let newTenantId = this.state.tenants.find(o => o.name_of_tenant === name_of_tenant).id;
            this.props.SET_DASHBOARD_TENANT_VIEWING({ dashboard_tenant_id_selected: newTenantId, dashboard_tenant_name_selected: name_of_tenant });

            // this is new tenant selection so ask for data
            this.setState({
                selectedTenant: name_of_tenant,
                selectedTenantId: newTenantId,
            });
        }
    }

    render() {
        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }


        return (
            <>
                <Navbar pageTitle="Monitoring" />
                <Container fluid pt="20px" >
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                            {this.state.tenants && this.state.tenants.map((tenant) => (
                                <Button
                                    h="36px"
                                    variant={this.state.selectedTenant === tenant.name_of_tenant ? "filled" : "default"}
                                    onClick={() => this.handleMonitoringTenantChange(tenant.name_of_tenant)}
                                >
                                    {tenant.name_of_tenant}
                                </Button>))}
                        </Flex>
                    </Paper>
                    <DashboardGrid {...this.props} selectedTenant={this.state.selectedTenant} selectedTenantId={this.state.selectedTenantId} draggingEnabled={true} dashboardName="monitoringDashboard" />
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_LINK, ACCESSTOKEN, SET_MONITORING_TENANT_VIEWING, LOGOUT })(Monitoring);