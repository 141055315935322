import React, { Component } from "react";
//import HardwareTicketDataService from "../services/hardware-ticket-activities";
//import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import {Link} from 'react-router-dom';

import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { ACCESSTOKEN } from "../features/actions/Is-Logged-Actions";

import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Container,
    Paper,
    Tabs,
    Select,
    Grid,
    Flex,
} from '@mantine/core';

import { IconEdit } from "@tabler/icons-react";

//import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';
import "bootstrap/dist/css/bootstrap.min.css";
//import Card from 'react-bootstrap/Card';
//import CardDeck from 'react-bootstrap/CardDeck';

//import server_image from '../images/server_image.jpeg';
//import software_image from '../images/software_image.jpeg';

import Navbar from '../components/Navbar';

import UserDataService from "../services/user-activities";
import MonitoringDataService from "../services/monitoring-activities";
import PossiblyAffectedDevices from "./Tanium-Widgets/Possibly-Affected-Devices";



class UserDetails extends Component {
    // constructor(props) {
    //     super(props);
    //     //this.retrieveTickets = this.retrieveTickets.bind(this);
    //     //this.setActiveTicket = this.setActiveTicket.bind(this);
    //     //this.onChangeSearchName = this.onChangeSearchName.bind(this);
    //     //this.searchName = this.searchName.bind(this);

    //     this.state = {
    //         tickets: [],
    //         //currentTicket: null,
    //         //currentIndex: -1,
    //         //searchName: ""
    //     };
    // }

    // componentDidMount() {           // token passed into props from the App.js file
        
    //     if(this.props.loggedStatus.loggedIn){
    //             this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //             this.props.ACCESSTOKEN(token);
    //             this.retrieveTickets(token);    // PASS TOKEN HERE
    //         });
    //     }

    // }

    // // onChangeSearchName(e) {
    // //     const searchName = e.target.value;

    // //     this.setState({
    // //         searchName: searchName
    // //     });
    // // }

    // retrieveTickets(token) {
    //     HardwareTicketDataService.getAll(token)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // setActiveTicket(ticket, index) {
    //     this.setState({
    //         currentTicket: ticket,
    //         currentIndex: index
    //     });
    // }

    // searchName() {
    //     TicketDataService.findByName(this.state.searchName)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    constructor(props) {
        super(props);

        this.state = {
            user_details: {},
            role_details: {},
            device_list: null,     // list of last logged in devices from Tanium
            openTicketList: []
        };
    }


    componentDidMount() {
        //this.props.UPDATE_ACTIVE_LINK("ticketLanding");
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    //console.log(this.props.match.params.id);
                    // get user details for item population
                    UserDataService.getUserProfileData(this.props.match.params.id, this.props.loggedStatus.id, token).then(response => {
                        console.log(response);
                        if(response && response.data.user_details && response.data.user_details.length > 0) {

                            this.setState({
                                user_details: response.data.user_details[0]
                            });

                            if(response && response.data.role_details && response.data.role_details.length > 0) {
                                this.setState({
                                    role_details: response.data.role_details[0]
                                });
                            }

                            // get the user's last logged in assets
                            MonitoringDataService.getLastLoggedIn(response.data.user_details[0].company_name, response.data.user_details[0].email_address.split("@")[0], token).then(response => {
                                console.log("Monitoring response was: ", response);
                                this.setState({
                                    device_list: response.data
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                        }

                        // get the user's tickets
                        UserDataService.getMyOpenTickets(this.props.match.params.id, token).then(response => {
                            console.log("Get users tickets response: ", response);
                            this.setState({
                                openTicketList: response.data.results
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        });

                    })
                    .catch(e => {
                        console.log(e);
                    });
                }

            });

        }
    }


    render() {

        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }


        return (
            <>
                <Navbar pageTitle="User Profile"/>
                <div style={{ height: "15px" }} />
                <Container fluid>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Grid>
                            <Grid.Col span={6}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Name</Table.Td>
                                        <Table.Td c="blue">
                                            {this.state.user_details.first_name ? 
                                                this.state.user_details.middle_name ? 
                                                `${this.state.user_details.first_name} ${this.state.user_details.middle_name} ${this.state.user_details.last_name}` 
                                                : 
                                                `${this.state.user_details.first_name} ${this.state.user_details.last_name}`
                                                :
                                                undefined
                                            }
                                        </Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Email</Table.Td>
                                        <Table.Td c="blue">{this.state.user_details.email_address && this.state.user_details.email_address}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Role</Table.Td>
                                        <Table.Td c="blue">{this.state.user_details.name_of_role && this.state.user_details.name_of_role}</Table.Td>
                                    </Table.Tr>
                                </Table>
                            </Grid.Col>
                            <Grid.Col span={6} align="right">
                                <div><Button onClick={() => this.props.history.push({pathname: "/editUser/" + this.props.match.params.id})}><IconEdit/></Button></div>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Tabs radius="md" defaultValue="Basic Info">
                            <Tabs.List>
                                <Tabs.Tab value="Basic Info">Basic Info</Tabs.Tab>
                                <Tabs.Tab value="Permissions">Permissions</Tabs.Tab>
                                <Tabs.Tab value="Devices">Devices</Tabs.Tab>
                                {/* <Tabs.Tab value="Open Tickets">Open Tickets</Tabs.Tab> */}
                            </Tabs.List>
                            <Tabs.Panel value="Basic Info">
                                <div style={{marginTop: "20px"}}>
                                    <Grid>
                                        <Grid.Col span={6}>
                                            <Table withRowBorders={false} style={{cursor: "default"}}>
                                                <Table.Tbody>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>First Name</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.first_name && this.state.user_details.first_name}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Middle Name</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.middle_name && this.state.user_details.middle_name}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Last Name</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.last_name && this.state.user_details.last_name}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Email</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.email_address && this.state.user_details.email_address}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Company</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.company_name && this.state.user_details.company_name}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Job Title</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.job_title && this.state.user_details.job_title}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Role</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.name_of_role && this.state.user_details.name_of_role}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Site</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.name_of_site && this.state.user_details.name_of_site}</Table.Td>
                                                    </Table.Tr>
                                                </Table.Tbody>
                                            </Table>
                                        </Grid.Col>
                                        <Grid.Col span={6}>
                                            <Table withRowBorders={false} style={{cursor: "default"}}>
                                                <Table.Tbody>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Department</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.name_of_department && this.state.user_details.name_of_department}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Location in Building</Table.Td>
                                                        <Table.Td c="blue">
                                                            {
                                                                this.state.user_details.floor_number && 
                                                                this.state.user_details.aisle_number && 
                                                                this.state.user_details.desk_number && 
                                                                `Floor ${this.state.user_details.floor_number}, Aisle ${this.state.user_details.aisle_number}, Desk ${this.state.user_details.desk_number}`
                                                            }
                                                        </Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Phone Number</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.phone_number && this.state.user_details.phone_number}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Mobile Number</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.mobile_number && this.state.user_details.mobile_number}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Cost per Hour</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.cost_per_hour && this.state.user_details.cost_per_hour}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Is on Call</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.is_on_call && this.state.user_details.is_on_call}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Accepted Solution Count</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.accepted_solution_count && this.state.user_details.accepted_solution_count}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "40%"}}>Number of Tickets Assigned</Table.Td>
                                                        <Table.Td c="blue">{this.state.user_details.number_of_tickets_assigned && this.state.user_details.number_of_tickets_assigned}</Table.Td>
                                                    </Table.Tr>
                                                </Table.Tbody>
                                            </Table>
                                        </Grid.Col>
                                    </Grid>         
                                </div>
                            </Tabs.Panel>
                            <Tabs.Panel value="Permissions">
                                <div style={{marginTop: "20px"}}>
                                    <Grid>
                                        <Grid.Col span={3}>
                                            <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                <Text td="underline" style={{textAlign: "center", cursor: "default"}}>Tickets</Text>
                                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                                    <Table.Tbody>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>See Tickets</Table.Td>
                                                            {this.state.role_details.can_see_tickets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Process Tickets</Table.Td>
                                                            {this.state.role_details.can_process_tickets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Edit Tickets</Table.Td>
                                                            {this.state.role_details.can_edit_tickets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Delete Tickets</Table.Td>
                                                            {this.state.role_details.can_delete_tickets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Add Solutions</Table.Td>
                                                            {this.state.role_details.can_add_solutions ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Edit Solutions</Table.Td>
                                                            {this.state.role_details.can_edit_solutions ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Delete Comments</Table.Td>
                                                            {this.state.role_details.can_delete_comments ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Edit Priority</Table.Td>
                                                            {this.state.role_details.can_edit_priority ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Manage Assignments</Table.Td>
                                                            {this.state.role_details.can_manage_assignments ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                    </Table.Tbody>
                                                </Table>
                                            </Paper>
                                        </Grid.Col>
                                    
                                        <Grid.Col span={3}>
                                            <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                <Text td="underline" style={{textAlign: "center", cursor: "default"}}>Assets</Text>
                                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                                    <Table.Tbody>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>See Assets</Table.Td>
                                                            {this.state.role_details.can_see_assets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Add Assets</Table.Td>
                                                            {this.state.role_details.can_add_assets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Edit Assets</Table.Td>
                                                            {this.state.role_details.can_edit_assets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Delete Assets</Table.Td>
                                                            {this.state.role_details.can_delete_assets ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                    </Table.Tbody>
                                                </Table>
                                            </Paper>
                                        </Grid.Col>
                                    {/* </Grid> */}

                                    {/* <Grid> */}
                                        <Grid.Col span={3}>
                                            <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                <Text td="underline" style={{textAlign: "center", cursor: "default"}}>Users</Text>
                                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                                    <Table.Tbody>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>See Users</Table.Td>
                                                            {this.state.role_details.can_see_users ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Add Users</Table.Td>
                                                            {this.state.role_details.can_add_users ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Edit Users</Table.Td>
                                                            {this.state.role_details.can_edit_users ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Delete Users</Table.Td>
                                                            {this.state.role_details.can_delete_users ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                    </Table.Tbody>
                                                </Table>
                                            </Paper>
                                        </Grid.Col>
                                        <Grid.Col span={3}>
                                            <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                                <Text td="underline" style={{textAlign: "center", cursor: "default"}}>General</Text>
                                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                                    <Table.Tbody>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>Role Name</Table.Td>
                                                            <Table.Td c="blue" align="right">{this.state.role_details.name_of_role}</Table.Td>
                                                        </Table.Tr>
                                                        <Table.Tr>
                                                            <Table.Td style={{width: "50%"}}>See Admin Portal</Table.Td>
                                                            {this.state.role_details.can_see_admin_portal ?
                                                                <Table.Td className="roleYesText" align="right">Yes</Table.Td>
                                                                :
                                                                <Table.Td className="roleNoText" align="right">No</Table.Td>
                                                            }
                                                        </Table.Tr>
                                                    </Table.Tbody>
                                                </Table>
                                            </Paper>
                                        </Grid.Col>
                                    </Grid>
                                </div>
                            </Tabs.Panel>
                            <Tabs.Panel value="Devices">
                                <div style={{marginTop: "20px", paddingLeft: "5px"}}>
                                    {this.state.device_list ? 
                                        <PossiblyAffectedDevices deviceList={this.state.device_list}/>
                                        :
                                        undefined
                                    }
                                    
                                    {/* <Table withRowBorders={false} highlightOnHover="true" style={{cursor: "pointer"}}>
                                        <Table.Thead>
                                            <Table.Tr>
                                                <Table.Th>Name</Table.Th>
                                                <Table.Th>Type</Table.Th>
                                            </Table.Tr>
                                        </Table.Thead>
                                        <Table.Tbody>
                                            <Table.Tr>
                                                <Table.Td>A-1004</Table.Td>
                                                <Table.Td>Computer</Table.Td>
                                            </Table.Tr>
                                            <Table.Tr>
                                                <Table.Td>M-999</Table.Td>
                                                <Table.Td>Printer</Table.Td>
                                            </Table.Tr>
                                        </Table.Tbody>
                                    </Table> */}
                                </div>
                            </Tabs.Panel>
                            {/* <Tabs.Panel value="Open Tickets">
                                <div style={{marginTop: "20px"}}>
                                    <Table withRowBorders={false} highlightOnHover="true" style={{cursor: "pointer"}}>
                                        <Table.Thead>
                                            <Table.Tr>
                                                <Table.Th style={{width: "10%"}}>Id</Table.Th>
                                                <Table.Th style={{width: "90%"}}>Title</Table.Th>
                                            </Table.Tr>
                                        </Table.Thead>
                                        <Table.Tbody>
                                            {this.state.openTicketList && this.state.openTicketList.map((ticket) => (
                                                <Table.Tr key={`openTicket-${ticket.id}`} onClick={() => this.goToTicketDetails(ticket.id)}>
                                                    <Table.Td>{ticket.id}</Table.Td>
                                                    <Table.Td>{ticket.ticket_title}</Table.Td>
                                                </Table.Tr>
                                            ))}
                                        </Table.Tbody>
                                    </Table>
                                </div>
                            </Tabs.Panel> */}
                        </Tabs>
                    </Paper>
                </Container>
            </>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_LINK, ACCESSTOKEN})(UserDetails);