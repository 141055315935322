import React, { useEffect, useState } from 'react';
import {
    Paper,
    Group,
    Progress,
    Box,
    SimpleGrid,
    RingProgress,
    Center,
    Table,
    TextInput,
    UnstyledButton,
    rem,
    Flex,
    Button,
    Text
} from '@mantine/core';
import MonitoringDataService from '../../../../services/monitoring-activities';

import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import classes from './TaniumAssetInfo.module.css';

import { Motion, spring } from "react-motion";

function Th(props) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function TaniumAssetInfo(props) {
    const [searchText, setSearchText] = useState("");
    const [searchTextApplied, setSearchTextApplied] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [selectedPageButton, setSelectedPageButton] = useState(1);
    const [cursor, setCursor] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [resultsPerPage, setResultsPerPage] = useState(12);
    const [maxPages, setMaxPages] = useState(22);
    const [assetInfo, setAssetInfo] = useState([]);
    const [pageButtons, setPageButtons] = useState([]);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [endpointTotal, setEndpointTotal] = useState(0);

    const createPageButtonsArray = (numPerPage, total) => {
        let buttons = [];
        let numButtons = Math.ceil(total / numPerPage);
        if (numButtons > maxPages) numButtons = maxPages;
        console.log("Number of buttons should be = ", numButtons)
        for (let i = 1; i <= numButtons; i++) {
            buttons.push(i);
        }

        setPageButtons(buttons);
    }

    const setSorting = (field) => {
        let reversed;

        if (reverseSortDirection === false) {
            reversed = true;
            setReverseSortDirection(true);
        }

        else {
            reversed = false;
            setReverseSortDirection(false);
        }

        setReverseSortDirection(reversed);
        setSortBy(field);
        setSelectedPageButton(1);
        setCursor(0);
        /*this.setState({
            reverseSortDirection: reversed,
            sortBy: field,
            selectedPageButton: 1,
            cursor: 0
        }, () => this.autoRefresh());*/
    };

    const handleSearchChange = (e) => {
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        console.log(e.target.value);
        setSearchText(e.target.value);
        setTypingTimeout(setTimeout(() => {
            // call backend with updated filters for new results
            //this.grabUpdatedUserList();
            //console.log("Would be searching with text: ", this.state.searchText);
            // this is a new set of query results, so reset the page button selected and cursor
            setSelectedPageButton(1);
            setCursor(0);
            setSearchTextApplied(searchText);
        }, 1500));
    };

    const handlePageButtonChange = (num) => {
        setSelectedPageButton(num);
        setCursor((num - 1) * resultsPerPage);
        /*this.setState({
            selectedPageButton: num,
            cursor: (num - 1) * this.state.resultsPerPage 
        }, () => this.autoRefresh());*/
    }
    const getAssetInfoForTable = () => {
        if (props.loggedStatus.loggedIn) {
            props.onAccessTokenRequest(props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token !== undefined && token !== null && token !== "") {

                    // check the sort order information before sending request to backend
                    let sortOrder;
                    if (reverseSortDirection === false) {
                        // they want the results ASC
                        sortOrder = "ASC";
                    }

                    else {
                        sortOrder = "DESC";
                    }

                    console.log("searchText: " + searchText);
                    MonitoringDataService.getAssetInfoForTable(props.selectedTenantId, props.selectedTenant, props.loggedStatus.askerId, cursor, hasNextPage, resultsPerPage, sortOrder, sortBy, searchText, token).then(response => {
                        console.log(response.data.data)
                        //const search = this.state.search;
                        //const reverseSortDirection = this.state.reverseSortDirection;
                        //const sortBy = this.state.sortBy;
                        setCursor(response.data.cursor);
                        setHasNextPage(response.data.hasNextPage);
                        setEndpointTotal(response.data.endpointTotal);
                        setAssetInfo(response.data.data);
                        /*setTableInfo({
                            assetInfo: response.data.data,
                            cursor: response.data.cursor,
                            hasNextPage: response.data.hasNextPage,
                            endpointTotal: response.data.endpointTotal,
                            data: response.data.data,
                            sortedData: response.data.data
                        }, () => createPageButtonsArray(resultsPerPage, endpointTotal));*/
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }
            });
        }
    }

    useEffect(() => {
        createPageButtonsArray(resultsPerPage, endpointTotal);
    }, [assetInfo]);

    useEffect(() => {
        getAssetInfoForTable()
    }, [selectedPageButton, searchTextApplied]);

    const rows = assetInfo.map((row) => (
        <Table.Tr key={row.localIpAddress + row.name + row.serialNumber}>
            <Table.Td>{row.name}</Table.Td>
            <Table.Td>{row.localIpAddress}</Table.Td>
            <Table.Td>{row.model}</Table.Td>
            <Table.Td>{row.freeDiskSpace}</Table.Td>
            <Table.Td>{row.serialNumber}</Table.Td>
        </Table.Tr>
    ));

    return (
        <Paper withBorder radius="md" p="5px" pt="15px" h="100%" w="100%" style={{ verticalAlign: "top" }}>
            <TextInput
                placeholder="Search by any field"
                mb="md"
                leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                value={searchText}
                onChange={handleSearchChange}
            />

            <Flex
                justify="flex-start"
                align="flex-start"
                direction="row"
                gap="md"
            >
                {pageButtons && pageButtons.map((num) => (
                    <Button
                        size="compact-xs"
                        variant={selectedPageButton === num ? "filled" : "default"}
                        onClick={() => handlePageButtonChange(num)}
                    >
                        {num}
                    </Button>
                ))}
            </Flex>

            <div style={{ marginTop: "20px" }}></div>

            <Table horizontalSpacing="md" verticalSpacing="xs" miw={700} layout="fixed">
                <Table.Thead>
                    <Table.Tr>
                        <Th
                            sorted={sortBy === 'name'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('name')}
                        >
                            <div className={sortBy === 'name' ? "activeMyAssignedTicketsHeader" : ""}>Name</div>
                        </Th>
                        <Th
                            sorted={sortBy === 'ipAddress'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('ipAddress')}
                        >
                            <div className={sortBy === 'ipAddress' ? "activeMyAssignedTicketsHeader" : ""}>IP Address</div>
                        </Th>
                        <Th
                            sorted={sortBy === 'model'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('model')}
                        >
                            <div className={sortBy === 'model' ? "activeMyAssignedTicketsHeader" : ""}>Model</div>
                        </Th>
                        <Th
                            sorted={sortBy === 'freeDiskSpace'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('freeDiskSpace')}
                        >
                            <div className={sortBy === 'freeDiskSpace' ? "activeMyAssignedTicketsHeader" : ""}>Free Disk Space</div>
                        </Th>
                        <Th
                            sorted={sortBy === 'serialNumber'}
                            reversed={reverseSortDirection}
                            onSort={() => setSorting('serialNumber')}
                        >
                            <div className={sortBy === 'serialNumber' ? "activeMyAssignedTicketsHeader" : ""}>Serial Number</div>
                        </Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    {rows.length > 0 ? (
                        rows
                    ) : (
                        <Table.Tr>
                            <Table.Td>
                                <Text fw={500} ta="center">
                                    Nothing found
                                </Text>
                            </Table.Td>
                        </Table.Tr>
                    )}
                </Table.Tbody>
            </Table>
        </Paper>
    );
}

export default TaniumAssetInfo;