import React, { Component } from "react";
// import Switch from "react-switch";

// import AccountDataService from "../services/account-activities";
//import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import { ACCESSTOKEN, ENABLE_EMAILS, DISABLE_EMAILS, SOFTWARE_ENABLE_EMAILS, SOFTWARE_DISABLE_EMAILS, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

// import { Container } from 'reactstrap';
// import DataTable from '@bit/adeoy.utils.data-table';
import "bootstrap/dist/css/bootstrap.min.css";

//import { withRouter } from 'react-router';


class AccountsList extends Component {
    constructor(props) {
        super(props);

        // this.handleHardwareChange = this.handleHardwareChange.bind(this);
        // this.handleSoftwareChange = this.handleSoftwareChange.bind(this);
        // this.retrieveAccounts = this.retrieveAccounts.bind(this);
        // this.setActiveAccount = this.setActiveAccount.bind(this);
        // this.onChangeSearchName = this.onChangeSearchName.bind(this);
        // this.searchName = this.searchName.bind(this);
        // this.getEmailStatus = this.getEmailStatus.bind(this);
        // this.getEmailStatusSoftware = this.getEmailStatusSoftware.bind(this);

        this.state = {
            // hardware_checked: true,
            // software_checked: true
            // accounts: [],
            // currentAccount: null,
            // currentIndex: -1,
            // searchName: ""
        };
    }

    componentDidMount() {           // Could pass the token into retrieveAccounts and gate it here?   token passed into props from the App.js file
        
        if(this.props.loggedStatus.loggedIn){
                this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    // this.setState({ checked: (this.props.loggedStatus.getEmails === 1) ? true : false });

                    // if(this.state.checked){
                    //     this.props.ENABLE_EMAILS();
                    // }
                    // else {
                    //     this.props.DISABLE_EMAILS();
                    // }
                    // this.retrieveAccounts(token);    // PASS TOKEN HERE
                    
                    // this.getEmailStatus(); // getting status of enable or disable email for hardware
                    // this.getEmailStatusSoftware();  // getting status of enable or disable email for software
                }
            });
        }

        this.props.UPDATE_ACTIVE_LINK("accounts");

        // this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
        //     this.props.ACCESSTOKEN(token);
        //     this.retrieveAccounts(token);    // PASS TOKEN HERE
        // });
        //console.log(this.props.loggedStatus.accessToken);
        
        //this.retrieveAccounts();
    }

    // on mount get email status 
    // after change, change email status in front and back 

    // getting status of enable or disable email for hardware
    // getEmailStatus() {
    //     console.log("in here");
        // let obj = {};
        // obj["user_id"] = this.props.loggedStatus.id;
        //console.log(token);
        // this.setState({ hardware_checked: (this.props.loggedStatus.getEmails === true) ? true : false });
        // console.log(this.state.hardware_checked);
        // if(this.state.hardware_checked){
        //     this.props.ENABLE_EMAILS();
        // }
        // else {
        //     this.props.DISABLE_EMAILS();
        // }
        //this.setState({ checked: (this.props.loggedStatus.getEmails === 1) ? true : false });
        // AccountDataService.getEmailsStatus(this.props.loggedStatus.id, token)
        // .then(response => {
        //     let temp_check = response.data.results[0]["get_Emails"];
        //     this.setState({
        //         checked: (temp_check == 1) ? true : false
        //     });
        //     console.log(response.data.results[0]["get_Emails"]);
        // })
        // .catch(e => {
        //     console.log(e);
        // });
    // }

    // getting status of enable or disable email for software
    // getEmailStatusSoftware() {
    //     console.log("in software ready here");
 
    //     this.setState({ software_checked: (this.props.loggedStatus.getEmailsSoftware === true) ? true : false });
    //     console.log(this.state.software_checked);
    //     if(this.state.software_checked){
    //         this.props.SOFTWARE_ENABLE_EMAILS();
    //     }
    //     else {
    //         this.props.SOFTWARE_DISABLE_EMAILS();
    //     }
    // }

    // handleHardwareChange(hardware_checked) {
    //     this.setState({ hardware_checked });
    //     if(hardware_checked){
    //         this.props.ENABLE_EMAILS();
    //     }
    //     else {
    //         this.props.DISABLE_EMAILS();
    //     }
    //     let obj = {}
    //     //obj["user_id"] = this.props.loggedStatus.id;
    //     obj["emailNotif_status"] = hardware_checked ? 1 : 0;
    //     // return new Promise ((resolve, reject) => {
    //     setTimeout(() => {
    //         // changing email status for hardware ticket 
    //         AccountDataService.changeEmailsStatus(this.props.loggedStatus.id, obj, this.props.loggedStatus.accessToken)
    //         .then(response => {
    //             console.log("in primise");
    //             console.log("changing");
    //             //resolve();
    //             // this.setState({
    //             //     checked: response.data.results[0]
    //             // });
    //             // console.log(response.data.results);
    //         })
    //         .catch(e => {
    //             console.log(e);
    //             //reject();
    //         });
    //     }, 1000);
    //     // });


    // }

    // handleSoftwareChange(software_checked) {
    //     this.setState({ software_checked });
    //     if(software_checked){
    //         this.props.SOFTWARE_ENABLE_EMAILS();
    //     }
    //     else {
    //         this.props.SOFTWARE_DISABLE_EMAILS();
    //     }
    //     let obj = {}
    //     obj["software_emailNotif_status"] = software_checked ? 1 : 0;
    //     setTimeout(() => {
    //         // changing email status for software tickets
    //         AccountDataService.changeSoftwareEmailsStatus(this.props.loggedStatus.id, obj, this.props.loggedStatus.accessToken)
    //         .then(response => {
    //             console.log("in primise software");
    //             console.log("changing software");
                
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //     }, 1000);
    // }

    // onChangeSearchName(e) {
    //     const searchName = e.target.value;

    //     this.setState({
    //         searchName: searchName
    //     });
    // }

    // retrieveAccounts(token) {
    //     AccountDataService.getAll(token)
    //     .then(response => {
    //         this.setState({
    //             accounts: response.data.results
    //         });
    //         console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // setActiveAccount(account, index) {
    //     this.setState({
    //         currentAccount: account,
    //         currentIndex: index
    //     });
    // }

    // searchName() {
    //     AccountDataService.findByName(this.state.searchName)
    //     .then(response => {
    //         this.setState({
    //             accounts: response.data.results
    //         });
    //         console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }


    render() {
        //console.log(this.props.loggedStatus);

        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }
        //console.log(this.props.loggedStatus.getEmails);

        // const { /*searchName, */accounts /*currentAccount, currentIndex*/ } = this.state;

        // const columns = [
        //     { title: "#", data: "id" },
        //     { title: "Name of Account", data: "name_of_account" }
        // ];

        // const click = (row) => {
        //     //console.log(this.props.loggedStatus);
        //     //this.props.history.push("/accounts/" + row.id);

        //     // REPLACE ABOVE LINE WITH THIS TO SEND ACCESS TOKEN TO BACKEND TO GET REQUESTED INFO
            
        //     this.props.history.push({
        //         pathname: "/accounts/" + row.id,
        //         state: {
        //             accessToken: this.props.loggedStatus.accessToken
        //         }
        //     })
            


        //     //window.location = "/accounts/" + row.id;
        //     //console.log(row);
        // };

        return (
            <label>Accounts</label>
            // <>
            //     <div className="emailDisableHardwareSwitch">
            //         <label>
            //             <span id="HardwareGlobalLabel">{this.state.hardware_checked ? "Click to Disable Hardware Ticketing Email Notifications" : "Click to Enable Hardware Ticketing Emails Notifications"}</span>
            //             <Switch id="HardwareGlobalSwitch" onChange={this.handleHardwareChange} checked={this.state.hardware_checked} />
            //         </label>
            //     </div>

            //     <div className="emailDisableSoftwareSwitch">
            //         <label>
            //             <span id="SoftwareGlobalLabel">{this.state.software_checked ? "Click to Disable Software Ticketing Email Notifications" : "Click to Enable Software Ticketing Emails Notifications"}</span>
            //             <Switch id="SoftwareGlobalSwitch" onChange={this.handleSoftwareChange} checked={this.state.software_checked} />
            //         </label>
            //     </div>                
            // </>
            

            // <>
            //     <link // Tweak for Bit.dev, you need to add and import Bootstrap styles
            //         rel='stylesheet'
            //         href='https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css'
            //     />
            //     <Container>
            //     <DataTable 
            //         data={accounts}
            //         columns={columns}
            //         striped={true}
            //         hover={true}
            //         responsive={true}
            //         onClickRow={click}
            //         //onClickRow={(row) => {return <Redirect to={"/accounts/" + row.id}/>}}
            //     />
            //     </Container>
            // </>
            // <div className="list row">
            //     <div className="col-md-8">
            //         <div className="input-group mb-3">
            //             <input
            //                 type="text"
            //                 className="form-control"
            //                 placeholder="Search by account name"
            //                 value={searchName}
            //                 onChange={this.onChangeSearchName}
            //             />
            //             <div className="input-group-append">
            //                 <button 
            //                 className="btn btn-outline-secondary"
            //                 type="button"
            //                 onClick={this.searchName}
            //                 >
            //                     Search
            //                 </button>
            //             </div>
            //         </div>
            //     </div>
            //     <div className="col-md-6">
            //         <h4>Accounts List</h4>

            //         <ul className="list-group">
            //             {accounts && 
            //             accounts.map((account, index) => (
            //                 <li
            //                 className={"list-group-item" + 
            //                 (index === currentIndex ? "active" : "")
            //                 }
            //                 onClick={() => this.setActiveAccount(account, index)}
            //                 key={index}
            //                 >
            //                     {account.name_of_account}
            //                 </li>
            //             ))}
            //         </ul>
            //     </div>
            //     <div className="col-md-6">
            //         {currentAccount ? (
            //             <div>
            //                 <h4>Account</h4>
            //                 <div>
            //                 <label>
            //                     <strong>Name:</strong>
            //                 </label>{" "}
            //                 {currentAccount.name_of_account}
            //                 </div>
            //                 <Link
            //                 to={"/accounts/" + currentAccount.id}
            //                 className="badge badge-warning"
            //                 >
            //                     Edit
            //                 </Link>
            //             </div>
            //         ) : (
            //             <div>
            //                 <br/>
            //                 <p>Please click on an Account...</p>
            //             </div>
            //         )}
            //     </div>
            // </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        //getEmails: state.getEmails
    };
}

export default connect(mapStateToProps, {ACCESSTOKEN, ENABLE_EMAILS, DISABLE_EMAILS, SOFTWARE_ENABLE_EMAILS, SOFTWARE_DISABLE_EMAILS, UPDATE_ACTIVE_LINK, LOGOUT})(AccountsList);
//export default connect(mapStateToProps, {ACCESSTOKEN})(AccountsList);