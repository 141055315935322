// This page gets displayed when technician clicks a ticket in the holding area. Used to clarify final details to make ticket complete

import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

import { connect } from 'react-redux';
//import {Link} from 'react-router-dom';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { cleanHTML } from '../../html-cleaner';
import parse from 'html-react-parser';
import Navbar from '../../components/Navbar';

import classes from './Clarify-Ticket-Form.module.css';

import { removeTags } from '../../remove-html-tags';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import {
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    Modal,
    Table,
    rem,
    keys,
    Paper,
    Select,
    Grid,
    Flex,
} from '@mantine/core';
import RichTextInput from '../RichTextInput';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import MAX_ATTACHMENT_SIZE from "../../max-attachment-size";

import ReassignTechSelector from '../Reassign-Tech-Selector';
import { UPDATE_ACTIVE_ASSIGNMENT, ASSIGNMENT_CHANGE_RESOLVED } from '../../features/actions/Reassign-Tech-Actions';

import RequesterSelector from '../Ticket-Requester-Selector';
import { UPDATE_ACTIVE_REQUESTER, REQUESTER_CHANGE_RESOLVED } from '../../features/actions/Ticket-Requester-Actions';


import HoldingTableDataService from '../../services/holding-table-activities';
import HardwareTicketDataService from "../../services/hardware-ticket-activities";
import SoftwareTicketDataService from "../../services/software-ticket-activities";
import MonitoringDataService from "../../services/monitoring-activities";

import ParentTicketSelector from "../Admin-Dashboard/Parent-Ticket-Selector";

import PossiblyAffectedDevices from "../Tanium-Widgets/Possibly-Affected-Devices";
import TicketSubmitterDetails from "../Dashboard-Widgets/Ticket-Submitter-Details";

class ClarifyTicketForm extends Component {

    constructor(props) {
        super(props);

        this.createFinalTicket = this.createFinalTicket.bind(this);
        this.onSelectTicketType = this.onSelectTicketType.bind(this);
        this.showCreationConfirmation = this.showCreationConfirmation.bind(this);
        this.hideCreationConfirmation = this.hideCreationConfirmation.bind(this);
        this.showDeleteConfirmation = this.showDeleteConfirmation.bind(this);
        this.hideDeleteConfirmation = this.hideDeleteConfirmation.bind(this);
        this.deleteTicket = this.deleteTicket.bind(this);
        this.showViewTechNote = this.showViewTechNote.bind(this);
        this.hideViewTechNote = this.hideViewTechNote.bind(this);
        this.addPrivateNote = this.addPrivateNote.bind(this);
        this.handleEditAssetChange = this.handleEditAssetChange.bind(this);
        this.handleEditTypeIssueChange = this.handleEditTypeIssueChange.bind(this);
        this.hideStaleTokenError = this.hideStaleTokenError.bind(this);

        this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this);
        this.handleAttachmentUploadChange = this.handleAttachmentUploadChange.bind(this);
        this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);
        this.getHistory = this.getHistory.bind(this);

        this.state = {
            ticketDetails: {},
            ticketTypeSelected: "Hardware",      // determines if we ask for the user's assigned hardware or software. updated when tech choses type option

            assets_assigned: [],
            issue_types: [],
            ticket_slas: [],    // these values will populate the "Priority" drop down menu [{id: 0, sla_name: "Priority 1", target_resolution_time_unit: "Hours", target_resolution_time_value: 4, target_response_time_unit: "Business Days", target_response_time_value: 2}]
            site_name: "",
            site_id: 0,
            tech_assigned_id: 0,    // The id of the tech assigned to this ticket
            asset_id_selected: 0,   // Will change based on dropdown menu choice
            asset_name_selected: "",
            ticket_category_id_selected: 0, // Will change based on dropdown menu choice
            ticket_category_selected: "",
            ticket_priority_selected: "Low",
            ticket_sla_id_selected: 0,      // Will change based on "Priority" dropdown menu choice
            ticket_sla_name_selected: "",   // Will change based on "Priority" dropdown menu choice
            tech_response_text: "",     // Will contain the tech's direct response to the ticket (if any)
            private_note_text: "",      // Will contain the private notes from tech (if any). This does NOT get sent to ticket creator
            typing_timeout: 0,          // Controls when tech's typed response in private note box gets sent to backend for saving
            is_private: 0,          // Controls whether tech is typing a direct response, or a private note to the ticket (will be controlled by toggle switch)
            show_creation_confirmation: false,   // modal that confirms user's submission choice
            show_delete_confirmation: false,    // modal that confirms user wants to delete message
            form_information: {},    // holds the value of formObj after user first clicks submit (to allow for final submission on modal submit button)
            adminBoolTogPrivatePublic: "public",   // whether public/private is selected for response
            attachment_list: [],    // a list of attachment urls that have comment_id = NULL (aka these were attachments on base ticket)
            tech_notes: [],  // array of objects with past private notes {id: 1, creator_name: Test McTesterson, ticket_id: 1, note_text: "I'm demo text from a fake tech note.", formatted_date_time_created: '02/25/22 12:35:42 PM'} 
            issue_text: "", // saves new ticket issue text after changes 
            show_tech_note: false,   // determines if view tech note modal is dispalyed
            tech_note_viewing_time_created: "", // Set when user clicks a note in timeline table
            tech_note_viewing_creator_name: "", // Set when user clicks a note in timeline table
            tech_note_viewing_note_text: "",     // Set when user clicks a note in timeline table
            tech_note_viewing_attachment_list: [],   // contains all attachment links that match the "comment_id" or note_id of that note being viewed

            attachmentFileNameList: [],

            history: [],

            lastLoggedInDevices: null,

            showSaving: false,       // controls whether the saving modal is displayed or not
            showStaleTokenError: false,     // controls whether the stale token error modal appears
            showNoResponseError: false,  // determines if the input validation error message displays for the tech response (if tried to submit empty string)
            showImageDetectedError: false,  // gets set to true if user tries to submit a response with an image directly pasted in the text box
        }
    }

    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // ask for the history here
                    this.getHistory(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken);

                    // first get the details user has already entered for that ticket
                    HoldingTableDataService.getSpecific(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, token)
                        .then(response => {
                            console.log("Response from backend was: ");
                            console.log(response);

                            this.setState({
                                ticketDetails: response.data.results[0],
                                is_private: response.data.results[0].is_private,
                                private_note_text: response.data.results[0].private_text,
                                tech_notes: response.data.results[0].tech_notes,
                                issue_text: response.data.results[0].issue_text,
                                attachment_list: response.data.results[0].attachment_list,
                                asset_id_selected: response.data.results[0].asset_id_selected,
                                ticket_category_id_selected: response.data.results[0].ticket_category_id_selected,
                                //ticket_priority_selected: response.data.results[0].ticket_priority_selected
                                ticket_sla_id_selected: response.data.results[0].ticket_sla_id
                            });
                            //console.log("On page load, is private is: ", this.state.is_private);

                            // Update the tech assigned dropdown with the returned values
                            if (response.data.results[0] && response.data.results[0].tech_assigned_id) {
                                this.props.UPDATE_ACTIVE_ASSIGNMENT({ name: response.data.results[0].assigned_tech_name, id: response.data.results[0].tech_assigned_id });
                            }

                            else {
                                this.props.UPDATE_ACTIVE_ASSIGNMENT({ name: "Unassigned", id: 0 });
                            }

                            // Update the requester selected drop down with returned values
                            if (response.data.results[0]) {
                                this.props.UPDATE_ACTIVE_REQUESTER({ tenantId: response.data.results[0].tenant_id, requesterId: response.data.results[0].requester_id, requesterName: response.data.results[0].requester_name, requesterEmail: response.data.results[0].requester_email });
                            }

                            // save the requester id
                            let requesterIdToSend = response.data.results[0].requester_id;

                            // get the list of assets this ticket creator last logged into according to tanium
                            let usernameToSend = "";
                            if (response.data.results[0].requester_email) {
                                usernameToSend = response.data.results[0].requester_email.split("@")[0];    // everything before the @ sign
                            }

                            MonitoringDataService.getLastLoggedIn(response.data.results[0].name_of_tenant, usernameToSend, token).then(response => {

                                console.log("Response from last logged in = ", response);
                                this.setState({
                                    lastLoggedInDevices: response.data
                                });

                                // Using the returned requester_id, get the list of assets they have assigned, along with the possible ticket categories
                                // since this is first time asking, we know "Hardware" is selected as type (default value), so ask the backend for the user's hardware assets
                                HardwareTicketDataService.getNewTicketFormData(requesterIdToSend, token)
                                    .then(response => {
                                        console.log(response);
                                        this.setState({
                                            ticketTypeSelected: "Hardware"
                                        });

                                        if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                                            /* this.setState({
                                                site_name: "Unknown",
                                                access_denied: true
                                            }); */
                                            console.log("Error retrieving site data");
                                        }

                                        else {
                                            this.setState({
                                                site_name: response.data.siteData[0].name_of_site,
                                                site_id: response.data.siteData[0].site_id
                                            });
                                        }

                                        if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                                            /* this.setState({
                                                access_denied: true
                                            }); */
                                            this.setState({
                                                assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                                asset_id_selected: 1,
                                                asset_name_selected: "Not Applicable"
                                            });

                                        }

                                        else {

                                            let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                                            let assignedAssets = response.data.assignedHardwareAssets;

                                            this.setState({
                                                assets_assigned: [...basicArray, ...assignedAssets],
                                                //asset_id_selected: 1,
                                                //asset_name_selected: "Not Applicable"

                                                // assets_assigned: response.data.assignedHardwareAssets,
                                                // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                                                // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                                            }, () => {
                                                // search the asset list for the name that should be selected
                                                for (let i = 0; i < this.state.assets_assigned.length; i++) {
                                                    if (this.state.assets_assigned[i].id === this.state.asset_id_selected) {
                                                        this.setState({
                                                            asset_name_selected: this.state.assets_assigned[i].name_of_asset
                                                        });

                                                        break;
                                                    }
                                                }
                                            });
                                        }

                                        if (response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                                            console.log("Error getting ticket categories from database");
                                            /* this.setState({
                                                access_denied: true
                                            }); */
                                        }

                                        else {
                                            console.log(response.data.ticketCategories);
                                            this.setState({
                                                issue_types: response.data.ticketCategories,
                                                //ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                                                //ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                                            }, () => {
                                                for (let i = 0; i < this.state.issue_types.length; i++) {
                                                    if (this.state.issue_types[i].id === this.state.ticket_category_id_selected) {
                                                        this.setState({
                                                            ticket_category_selected: this.state.issue_types[i].name_of_category
                                                        });

                                                        break;
                                                    }
                                                }
                                            });
                                        }

                                        // check for the priorities array here!
                                        if (response.data.ticketSlas && response.data.ticketSlas.length === 0) {     // Company has no slas in their system
                                            console.log("This company either has no SLAs or there was an error retrieving them");
                                        }

                                        else if (response.data.ticketSlas) {
                                            console.log("TICKET SLAS RETURNED: ", response.data.ticketSlas);
                                            let selected_name = "";
                                            if (this.state.ticket_sla_id_selected !== null) {
                                                selected_name = (response.data.ticketSlas.find(s => s.id === this.state.ticket_sla_id_selected)).sla_name;
                                            }
                                            this.setState({
                                                ticket_slas: response.data.ticketSlas,
                                                // set the active selected values here!
                                                //asset_id_selected: (this.state.assets_assigned.find(o => o.name_of_asset === edit_asset_name)).id
                                                ticket_sla_name_selected: selected_name

                                            });
                                        }
                                        //console.log(this.state);
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });

                            })
                                .catch(e => {
                                    console.log(e);
                                });
                        })
                        .catch(e => {
                            console.log(e);
                        });

                    //console.log("The id of this ticket was: ");
                    //console.log(this.props.match.params.id);
                }
            })
                .catch(e => {
                    console.log(e)
                });
        }
    }

    componentDidUpdate() {
        if (this.props.reassignTech.assignment_change_detected) {
            console.log("We have picked up the assignment change. The value you chose was: ", this.props.reassignTech.active_assignment_name);

            this.props.ASSIGNMENT_CHANGE_RESOLVED();

            this.updateTechAssignment(this.props.match.params.id, this.props.reassignTech.active_assignment_id, this.props.loggedStatus.accessToken);

            this.setState(prevState => ({
                ticketDetails: {
                    ...prevState.ticketDetails,
                    tech_assigned_id: this.props.reassignTech.active_assignment_id,
                    assigned_tech_name: this.props.reassignTech.active_assignment_name
                }
                //ticketDetails.tech_assigned_id: this.props.reassignTech.active_assignment_id
            }));
        }

        if (this.props.ticketRequester.requester_change_detected) {
            this.props.REQUESTER_CHANGE_RESOLVED();

            let updatedData = {
                requesterId: this.props.ticketRequester.requester_id_selected,
                assetId: 1,
                categoryId: this.state.ticket_category_id_selected,
                priority: this.state.ticket_priority_selected
            };

            // send an update to backend with new requester id and reset the asset_id of the ticket to 1 (the generic Not Applicable option)
            HoldingTableDataService.updateHoldingTicketInfo(this.props.match.params.id, updatedData, this.props.loggedStatus.accessToken)
                .then(response => {

                    // update the front end form with new drop down values (also get the new requester email address so we can use it for tech assigned notification if needed)
                    HardwareTicketDataService.getNewTicketFormData(this.props.ticketRequester.requester_id_selected, this.props.loggedStatus.accessToken)
                        .then(response => {
                            console.log(response);
                            this.setState({
                                ticketTypeSelected: "Hardware"
                            });
                            // update the requester email (for use if we need to send a tech assigned notification email)
                            this.setState(prevState => ({
                                ticketDetails: {
                                    ...prevState.ticketDetails,
                                    requester_email: response.data.requesterEmail
                                }
                                //ticketDetails.tech_assigned_id: this.props.reassignTech.active_assignment_id
                            }));

                            if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                                /* this.setState({
                                    site_name: "Unknown",
                                    access_denied: true
                                }); */
                                console.log("Error retrieving site data");
                            }

                            else {
                                this.setState({
                                    site_name: response.data.siteData[0].name_of_site,
                                    site_id: response.data.siteData[0].site_id
                                });
                            }

                            if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                                /* this.setState({
                                    access_denied: true
                                }); */
                                this.setState({
                                    assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                    asset_id_selected: 1,
                                    asset_name_selected: "Not Applicable"
                                });

                            }

                            else {

                                let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                                let assignedAssets = response.data.assignedHardwareAssets;

                                this.setState({
                                    assets_assigned: [...basicArray, ...assignedAssets],
                                    asset_id_selected: 1,
                                    asset_name_selected: "Not Applicable"
                                    // assets_assigned: response.data.assignedHardwareAssets,
                                    // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                                    // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                                });
                            }

                            // update the list of last logged in devices
                            let usernameToSend = "";

                            usernameToSend = response.data.requesterEmail.split("@")[0];    // everything before the @ sign


                            MonitoringDataService.getLastLoggedIn(response.data.name_of_tenant, usernameToSend, this.props.loggedStatus.accessToken).then(response => {

                                console.log("Response from last logged in = ", response);
                                this.setState({
                                    lastLoggedInDevices: response.data
                                });

                            })
                                .catch(e => {
                                    console.log(e);
                                });

                            //console.log(this.state);
                        })
                        .catch(e => {
                            console.log(e);
                        });

                })
                .catch(e => {

                });
        }
    }

    getHistory(id, timezone_adjustment_factor, accessToken) {

        console.log("You're in the get history function");

        HoldingTableDataService.getTicketHistory(id, timezone_adjustment_factor, accessToken)
            .then(response => {
                console.log("Response from history endpoint was: ", response);
                this.setState({
                    history: response.data.results
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    // calls the backend to change the designated ticket's assigned tech id to the one passed in as an argument
    // now also sending the requester email address for use with sending notification to requester when a technician is assigned to their ticket
    updateTechAssignment(ticket_id, assigned_tech_id, token) {
        HoldingTableDataService.updateTechAssignment(ticket_id, { techAssignedId: assigned_tech_id, requesterEmail: this.state.ticketDetails.requester_email, assignerId: this.props.loggedStatus.id }, token)
            .then(response => {
                console.log("Tech successfully updated!");

                // Fetch the history data again HERE !!!
                this.getHistory(ticket_id, this.props.loggedStatus.timezone_adjustment_factor, token);
            })
            .catch(e => {
                console.log(e);
            });
    }

    showCreationConfirmation() {
        if (this.state.tech_response_text !== null && removeTags(this.state.tech_response_text) !== "") {

            // as long as you don't find the image tag you're ok to continue, otherwise, they tried to paste in an image into their direct reply so we're going to reject it
            if (!this.state.tech_response_text.toLowerCase().includes("<img")) {
                console.log("Value after remove tags is: ", removeTags(this.state.tech_response_text));
                // e.preventDefault();
                const formDataObj = new FormData();
                //let formDataObj = Object.fromEntries(formData.entries());

                for (let z = 0; z < this.state.attachmentFileNameList.length; z++) {
                    formDataObj.append('attachmentList', this.state.attachmentFileNameList[z]);
                }

                //console.log("TICKET DETAILS TO BE SENT TO BACKEND");
                //console.log(this.state.asset_id_selected);
                //console.log(this.state.ticket_category_id_selected);
                //console.log(this.state.issue_text);
                //console.log(this.state.ticket_priority_selected);
                // console.log(this.state.)
                //formDataObj["getEmails"] = this.props.loggedStatus.getEmails;
                formDataObj.append("slaId", this.state.ticket_sla_id_selected);
                formDataObj.append("ticketTitle", this.state.ticketDetails.ticket_title);
                formDataObj.append("assetId", this.state.asset_id_selected);
                formDataObj.append("requesterId", this.props.ticketRequester.requester_id_selected); //this.state.ticketDetails.requester_id;
                formDataObj.append("siteId", this.state.ticketDetails.site_id);
                formDataObj.append("categoryId", this.state.ticket_category_id_selected);
                formDataObj.append("techResponse", this.state.tech_response_text);
                formDataObj.append("dateTimeCreated", this.state.ticketDetails.date_time_created);
                formDataObj.append("techUsername", this.props.loggedStatus.username);     // will only be used if tech responds directly to the ticket
                formDataObj.append("issueText", this.state.issue_text);
                formDataObj.append("techAssignedId", this.state.ticketDetails.tech_assigned_id);
                formDataObj.append("dateTimeResponded", this.state.ticketDetails.time_of_response);
                formDataObj.append("oldTicketId", this.props.match.params.id);      // the old id for this ticket so we can search for parent on backend
                formDataObj.append("clarifierId", this.props.loggedStatus.id);  // the id of the tech actually clarifying the ticket for use in history event

                console.log("complete form data:");
                console.log(formDataObj);
                this.setState({
                    showNoResponseError: false,
                    showImageDetectedError: false,
                    show_creation_confirmation: true,
                    form_information: formDataObj
                });
            }

            else {      // caught case where image was pasted directly into reply form, so reject
                this.setState({
                    showImageDetectedError: true,
                    showNoResponseError: false
                });
            }

        }

        else {
            this.setState({
                showNoResponseError: true,
                showImageDetectedError: false
            });
        }
    }

    hideCreationConfirmation() {
        this.setState({
            show_creation_confirmation: false,
            form_information: {}
        });
    }

    showDeleteConfirmation() {
        this.setState({
            show_delete_confirmation: true
        });
    }

    hideDeleteConfirmation() {
        this.setState({
            show_delete_confirmation: false
        });
    }

    showViewTechNote(time_created, creator_name, note_text) {
        this.setState({
            show_tech_note: true,
            tech_note_viewing_time_created: time_created,
            tech_note_viewing_creator_name: creator_name,
            tech_note_viewing_note_text: note_text
        });
    }

    hideViewTechNote() {
        this.setState({
            show_tech_note: false,
            tech_note_viewing_time_created: "",
            tech_note_viewing_creator_name: "",
            tech_note_viewing_note_text: ""
        });
    }

    hideStaleTokenError() {
        this.setState({
            showStaleTokenError: false
        });
    }

    // This function gets called if the user clicks the red Delete button on the page.
    // It will delete the holding ticket and its related data without transfering anything
    deleteTicketOutright() {
        HoldingTableDataService.deleteTicketOutright(this.props.match.params.id, this.props.loggedStatus.accessToken)
            .then(response => {
                this.props.history.push('/myAssignedTickets');    // return user back to holding area upon success
            })
            .catch(e => {
                console.log(e);
            });
    }


    // delete ticket with id specified in the query string of URL (also delete associated tech notes in the holding table table - transfer to hard/software notes table?)
    // new_ticket_id is the id the system assigned to the hardware or software ticket we created just before calling this function
    // (will be used to transfer any tech notes and recipient assignments to the hardware / software variant tables)
    deleteTicket(new_ticket_id, type_of_new_ticket) {
        HoldingTableDataService.deleteTicket(this.props.match.params.id, new_ticket_id, type_of_new_ticket, this.props.loggedStatus.accessToken)
            .then(response => {
                this.props.history.push('/myAssignedTickets');   // return user back to holding area upon success
            })
            .catch(e => {
                console.log(e);
            });
    }

    createFinalTicket() {
        // e.preventDefault();
        // const formData = new FormData(e.target);
        // let formDataObj = Object.fromEntries(formData.entries());
        // //formDataObj["getEmails"] = this.props.loggedStatus.getEmails;
        // formDataObj["requesterId"] = this.state.ticketDetails.requester_id;
        // formDataObj["siteId"] = this.state.ticketDetails.site_id;
        // formDataObj["assetId"] = this.state.asset_id_selected;
        // formDataObj["categoryId"] = this.state.ticket_category_id_selected;
        //console.log(formDataObj);

        this.setState({
            show_creation_confirmation: false,
            showSaving: true
        });

        if (this.state.ticketTypeSelected === "Hardware") {

            HardwareTicketDataService.createHardwareTicket(this.state.form_information, this.props.loggedStatus.accessToken)
                .then(response => {

                    let locationHeader = response.headers.location;

                    // Process location header to be just the integer id of the newly created hardware ticket
                    let processedLocationHeader = locationHeader.split('/')[2];

                    // Call Delete Endpoint here! (delete ticket from holding area)
                    this.deleteTicket(processedLocationHeader, "Hardware");

                })
                .catch(e => {
                    console.log(e);

                    // Show stale token modal HERE !!!
                    this.setState({
                        showSaving: false,
                        showStaleTokenError: true
                    });
                });

            //console.log(formDataObj);
        }

        else if (this.state.ticketTypeSelected === "Software") {
            SoftwareTicketDataService.createSoftwareTicket(this.state.form_information, this.props.loggedStatus.accessToken)
                .then(response => {

                    let locationHeader = response.headers.location;

                    // Process location header to be just the integer id of the newly created software ticket
                    let processedLocationHeader = locationHeader.split('/')[2];

                    // Call Delete Endpoint here! (delete ticket from holding area)
                    this.deleteTicket(processedLocationHeader, "Software");

                })
                .catch(e => {
                    console.log(e);
                })

            //console.log(formDataObj);
        }
    }

    onSelectAssetInvolved(asset_id, asset_name /*e*/) {
        //console.log(e.target.value);
        console.log("Asset ID: ", asset_id);
        console.log("Asset Name: ", asset_name);
        this.setState({
            asset_id_selected: asset_id,
            asset_name_selected: asset_name,
            //HardwareTicketAsset: e.target.value 
        });

        // do an auto save operation on the backend to preserve this new value
        let updatedData = {
            requesterId: this.props.ticketRequester.requester_id_selected,
            assetId: asset_id,
            categoryId: this.state.ticket_category_id_selected,
            priority: this.state.ticket_priority_selected
        };

        // send an update to backend with new requester id and reset the asset_id of the ticket to 1 (the generic Not Applicable option)
        HoldingTableDataService.updateHoldingTicketInfo(this.props.match.params.id, updatedData, this.props.loggedStatus.accessToken)
            .then(response => {
                // at this point it's been updated on backend
            })
            .catch(e => {
                console.log(e);
            });
    }

    onSelectTicketCategory(category_id, category_name) {
        console.log("Category ID: ", category_id);
        console.log("Category Name: ", category_name);
        this.setState({
            ticket_category_id_selected: category_id,
            ticket_category_selected: category_name
        });

        // perform an auto save operation on backend
        let updatedData = {
            requesterId: this.props.ticketRequester.requester_id_selected,
            assetId: this.state.asset_id_selected,
            categoryId: category_id,
            priority: this.state.ticket_priority_selected
        };

        // send an update to backend with new requester id and reset the asset_id of the ticket to 1 (the generic Not Applicable option)
        HoldingTableDataService.updateHoldingTicketInfo(this.props.match.params.id, updatedData, this.props.loggedStatus.accessToken)
            .then(response => {
                // at this point it's been updated on backend
            })
            .catch(e => {
                console.log(e);
            });
    }

    onSelectTicketType(type_selected) {
        console.log(type_selected);
        switch (type_selected) {
            case "Hardware":
                //console.log("Hardware selected");
                if (this.state.ticketTypeSelected !== "Hardware") {  // user selected Hardware from drop down, but setting was previously set to "Software"
                    HardwareTicketDataService.getNewTicketFormData(this.state.ticketDetails.requester_id, this.props.loggedStatus.accessToken)
                        .then(response => {
                            //console.log(response);
                            this.setState({
                                ticketTypeSelected: "Hardware"
                            });

                            if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                                /* this.setState({
                                    site_name: "Unknown",
                                    access_denied: true
                                }); */
                                console.log("Error retrieving site data");
                            }

                            else {
                                this.setState({
                                    site_name: response.data.siteData[0].name_of_site,
                                    site_id: response.data.siteData[0].site_id
                                });
                            }

                            if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                                /* this.setState({
                                    access_denied: true
                                }); */
                                this.setState({
                                    assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                    asset_id_selected: 1,
                                    asset_name_selected: "Not Applicable"
                                });

                            }

                            else {

                                let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                                let assignedAssets = response.data.assignedHardwareAssets;

                                this.setState({
                                    assets_assigned: [...basicArray, ...assignedAssets],
                                    asset_id_selected: 1,
                                    asset_name_selected: "Not Applicable"
                                    // assets_assigned: response.data.assignedHardwareAssets,
                                    // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                                    // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                                });
                            }

                            if (response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                                console.log("Error getting ticket categories from database");
                                /* this.setState({
                                    access_denied: true
                                }); */
                            }

                            else {
                                this.setState({
                                    issue_types: response.data.ticketCategories,
                                    ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                                    ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                                });
                            }
                            //console.log(this.state);
                        })
                        .catch(e => {
                            console.log(e);
                        });
                }

                break;

            case "Software":
                //console.log("Software selected");
                //console.log(this.state.ticketTypeSelected);
                if (this.state.ticketTypeSelected !== "Software") {  // user selected Software from drop down, but setting was previously set to "Hardware"
                    //console.log("Software conditional triggered");
                    // Get the Software specific details for the drop down menus and update the type selected value
                    SoftwareTicketDataService.getNewTicketFormData(this.state.ticketDetails.requester_id, this.props.loggedStatus.accessToken)
                        .then(response => {
                            //console.log(response);
                            this.setState({
                                ticketTypeSelected: "Software"
                            });

                            if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                                /* this.setState({
                                    site_name: "Unknown",
                                    access_denied: true
                                }); */

                                console.log("Error retrieving site data");
                            }

                            else {
                                this.setState({
                                    site_name: response.data.siteData[0].name_of_site,
                                    site_id: response.data.siteData[0].site_id
                                });
                            }

                            if (response.data.assignedSoftwareAssets.length === 0) { // User has no software assets assigned to them
                                /* this.setState({
                                    access_denied: true
                                }); */

                                this.setState({
                                    assets_assigned: [{ software_id: 1, hardware_id: 1, name_of_software: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                    asset_id_selected: 1,
                                    asset_name_selected: "Not Applicable"
                                });
                            }

                            else {

                                let basicArray = [{ software_id: 1, hardware_id: 1, name_of_software: "Not Applicable" }];
                                let assignedAssets = response.data.assignedSoftwareAssets;

                                this.setState({
                                    assets_assigned: [...basicArray, ...assignedAssets],
                                    asset_id_selected: 1,
                                    asset_name_selected: "Not Applicable"
                                    //asset_id_selected: response.data.assignedSoftwareAssets[0].software_id,  // This will account for the case where user just picks first value without clicking
                                    //asset_name_selected: response.data.assignedSoftwareAssets[0].name_of_software  // This will account for the case where user just picks first value without clicking
                                });
                            }

                            if (response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                                console.log("Error getting ticket categories from database");
                                /* this.setState({
                                    access_denied: true
                                }); */
                            }

                            else {
                                this.setState({
                                    issue_types: response.data.ticketCategories,
                                    ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                                    ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                                });
                            }

                        })
                        .catch(e => {
                            console.log(e);
                        });
                }

                break;
            default:
                console.log("Default case");
        }
    }

    updateTechResponse = value => {
        this.setState({
            tech_response_text: value
        });
    }

    handleUploadButtonClick() {
        document.getElementById('hiddenUploadButton').click();
    }

    handleAttachmentUploadChange(e) {
        console.log(e.target.files)

        try {

            let newAttachmentList = this.state.attachmentFileNameList;

            for (let i = 0; i < e.target.files.length; i++) {

                let file_extension = e.target.files[i].name.substring(e.target.files[i].name.lastIndexOf('.') + 1, e.target.files[i].name.length) || e.target.files[i].name;

                if (e.target.files[i].size <= MAX_ATTACHMENT_SIZE && (file_extension.toLowerCase() === "jpeg" || file_extension.toLowerCase() === "png" || file_extension.toLowerCase() === "jpg" || file_extension.toLowerCase() === "bmp" || file_extension.toLowerCase() === "heic" || file_extension.toLowerCase() === "pdf" || file_extension.toLowerCase() === "doc" || file_extension.toLowerCase() === "docx" || file_extension.toLowerCase() === "xls" || file_extension.toLowerCase() === "xlsx" || file_extension.toLowerCase() === "ppt" || file_extension.toLowerCase() === "pptx" || file_extension.toLowerCase() === "dwg" || file_extension.toLowerCase() === "txt")) {

                    console.log(e.target.files[i].name);

                    let filename = e.target.files[i].name

                    newAttachmentList.push(e.target.files[i]);

                    // let reader = new FileReader();
                    // reader.readAsDataURL(e.target.files[i]);
                    // reader.onloadend = (event) => {
                    //     // The contents of the BLOB are in reader.result:
                    //     console.log(reader.result);
                    //     newAttachmentList.push({filename: filename, contents: reader.result});
                    // }

                }

                else {
                    console.log("This file was either too big or an incorrect file type");
                }

            }

            this.setState({
                attachmentFileNameList: newAttachmentList
            }, () => console.log(this.state.attachmentFileNameList));

        }

        catch {
            console.log("No files seen")
        }
    }

    // this fxn removes the specified attachment from the list of attachments to send to backend for saving
    handleAttachmentDelete(fileInfo) {
        console.log(fileInfo);

        let newAttachmentList = [];

        let foundDuplicate = false;     // if there's another instance of that one in the list, only remove one of them

        for (let i = 0; i < this.state.attachmentFileNameList.length; i++) {
            if (!foundDuplicate && this.state.attachmentFileNameList[i].lastModified === fileInfo.lastModified && this.state.attachmentFileNameList[i].name === fileInfo.name && this.state.attachmentFileNameList[i].size === fileInfo.size && this.state.attachmentFileNameList[i].type === fileInfo.type) {
                // if you found the match of the one to delete, don't include it in the new attachment array
                foundDuplicate = true;
                continue;
            }

            else {
                newAttachmentList.push(this.state.attachmentFileNameList[i]);
            }
        }

        this.setState({
            attachmentFileNameList: newAttachmentList
        });
    }


    // on is public, off is private 
    handleAdminPrivatePublicToggleChange = e => {
        console.log("change toggle");
        // console.log(this.state.adminBoolTogPrivatePublic);
        // console.log(e.target.value);
        this.setState({
            //adminBoolTogPrivatePublic: ( (this.state.adminBoolTogPrivatePublic === "private") ? "public" : "private")
            is_private: (this.state.is_private === 0 ? 1 : 0)

        }, () => {
            console.log("Value of is_private is: ", this.state.is_private);
            HoldingTableDataService.changeIsPrivate(this.props.match.params.id, { is_private: this.state.is_private }, this.props.loggedStatus.accessToken)
                .then(response => {
                    // you've swapped the is private value at this point

                })
                .catch(e => {
                    console.log(e);
                });
        }
        );


    }

    // fired when tech clicks save button in create new note area. saves data to tech notes holding table table
    addPrivateNote() {
        if (this.state.private_note_text !== null && removeTags(this.state.private_note_text) !== "") {

            // as long as you don't find the image tag you're ok to continue, otherwise, they tried to paste in an image into their direct reply so we're going to reject it
            if (!this.state.private_note_text.toLowerCase().includes("<img")) {

                console.log("Value after remove tags is: ", removeTags(this.state.private_note_text));

                // show the saving modal
                this.setState({
                    showNoResponseError: false,
                    showImageDetectedError: false,
                    showSaving: true
                });

                // create formDataObj for sending text and file contents over to backend
                const formDataObj = new FormData();

                for (let z = 0; z < this.state.attachmentFileNameList.length; z++) {
                    formDataObj.append('attachmentList', this.state.attachmentFileNameList[z]);
                }

                formDataObj.append('private_text', this.state.private_note_text);

                HoldingTableDataService.savePrivateNote(this.props.match.params.id, this.props.loggedStatus.id, formDataObj, this.props.loggedStatus.accessToken)
                    .then(response => {
                        console.log("message saved");
                        // note successfully added, so make the note text disappear and the modal hide
                        this.setState({
                            show_creation_confirmation: false,
                            private_note_text: "",
                            attachmentFileNameList: [],
                            showImageDetectedError: false,
                            showNoResponseError: false
                        });

                        // now get the list of tech notes again to refresh the history table
                        HoldingTableDataService.getSpecific(this.props.match.params.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken)
                            .then(response => {
                                this.setState({
                                    tech_notes: response.data.results[0].tech_notes,
                                    attachment_list: response.data.results[0].attachment_list,
                                    showSaving: false
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            })
                    })
                    .catch(e => {
                        console.log(e);

                        // Show stale token modal HERE !!!
                        this.setState({
                            showSaving: false,
                            showStaleTokenError: true
                        });
                    });
            }

            else {  // caught case where image was pasted directly into reply form, so reject

                this.setState({
                    showImageDetectedError: true,
                    showNoResponseError: false
                });
            }
        }

        else {
            this.setState({
                showNoResponseError: true,
                showImageDetectedError: false
            });
        }
    }

    // saves tech's input in the private note box
    onChangePrivateNote = (value) => {
        this.setState({
            private_note_text: value
        });
        //console.log("You changed the private text");
        // if(this.state.typing_timeout) {
        //     clearTimeout(this.state.typing_timeout);
        // }

        // this.setState({
        //     private_note_text: e.target.value,
        //     typing_timeout: setTimeout( () => {
        //         // call backend endpoint here!
        //         HoldingTableDataService.savePrivateNote(this.props.match.params.id, {private_text: this.state.private_note_text}, this.props.loggedStatus.accessToken)
        //         .then(response => {
        //             console.log("message saved");
        //         })
        //         .catch(e => {
        //             console.log(e);
        //         });
        //         //console.log("Your private note sent to backend will be: ", this.state.private_note_text);
        //     }, 1500)
        // });
    }

    changePriority = sla_name => {
        console.log(sla_name);
        this.setState({
            ticket_sla_name_selected: sla_name,
            ticket_sla_id_selected: (this.state.ticket_slas.find(s => s.sla_name === sla_name)).id
        });

        // perform an auto save on the backend
        let updatedData = {
            requesterId: this.props.ticketRequester.requester_id_selected,
            assetId: this.state.asset_id_selected,
            categoryId: this.state.ticket_category_id_selected,
            slaId: (this.state.ticket_slas.find(s => s.sla_name === sla_name)).id
        };

        // send an update to backend with new requester id and reset the asset_id of the ticket to 1 (the generic Not Applicable option)
        HoldingTableDataService.updateHoldingTicketInfo(this.props.match.params.id, updatedData, this.props.loggedStatus.accessToken)
            .then(response => {
                // at this point it's been updated on backend
            })
            .catch(e => {
                console.log(e);
            });

    }

    changeIssueText = value => {
        //console.log(e.target.value);
        if (value !== this.state.issue_text) {
            this.setState({
                issue_text: value
            });
        }
    }

    onChangeTicketTitle = (e) => {

        let newTitle = e.target.value

        this.setState(prevState => ({
            ticketDetails: {
                ...prevState.ticketDetails,
                ticket_title: newTitle
            }
        }));

        if (this.state.typing_timeout) {
            clearTimeout(this.state.typing_timeout);
        }

        this.setState({
            entered_title_text: e.target.value,
            typing_timeout: setTimeout(() => { // wait some time before sending the typed text to the backend (prevent having a bunch of calls)
                HardwareTicketDataService.findRelatedTickets(this.state.entered_title_text, this.props.loggedStatus.accessToken, this.props.loggedStatus.id)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }, 1500)
        });

    }

    handleEditAssetChange(assets_assigned, asset_name) {
        console.log("assets Selected!!");
        console.log(assets_assigned);
        // console.log(assets_assigned.indexOf(assets_assigned.find(o => o.name_of_asset === e.target.value)) + 1);
        this.setState({
            asset_id_selected: (this.state.ticketTypeSelected === "Hardware") ? (assets_assigned.find(o => o.name_of_asset === asset_name)).id : (assets_assigned.find(o => o.name_of_software === asset_name)).software_id,
            asset_name_selected: asset_name
        });

        // perform an auto save operation on backend
        let updatedData = {
            requesterId: this.props.ticketRequester.requester_id_selected,
            assetId: (this.state.ticketTypeSelected === "Hardware") ? (assets_assigned.find(o => o.name_of_asset === asset_name)).id : (assets_assigned.find(o => o.name_of_software === asset_name)).software_id,
            categoryId: this.state.ticket_category_id_selected,
            slaId: this.state.ticket_sla_id_selected
        };

        // send an update to backend with new requester id and reset the asset_id of the ticket to 1 (the generic Not Applicable option)
        HoldingTableDataService.updateHoldingTicketInfo(this.props.match.params.id, updatedData, this.props.loggedStatus.accessToken)
            .then(response => {
                // at this point it's been updated on backend
            })
            .catch(e => {
                console.log(e);
            });
    }

    handleEditTypeIssueChange(issue_types, category) {
        console.log("issues Selected!!");
        console.log(category);
        console.log(issue_types);
        console.log(issue_types.find(o => o.name_of_category === category));
        this.setState({
            ticket_category_id_selected: (issue_types.find(o => o.name_of_category === category)).id,
            ticket_category_selected: category
        });

        // perform an auto save operation on backend
        let updatedData = {
            requesterId: this.props.ticketRequester.requester_id_selected,
            assetId: this.state.asset_id_selected,
            categoryId: (issue_types.find(o => o.name_of_category === category)).id,
            slaId: this.state.ticket_sla_id_selected
        };

        // send an update to backend with new requester id and reset the asset_id of the ticket to 1 (the generic Not Applicable option)
        HoldingTableDataService.updateHoldingTicketInfo(this.props.match.params.id, updatedData, this.props.loggedStatus.accessToken)
            .then(response => {
                // at this point it's been updated on backend
            })
            .catch(e => {
                console.log(e);
            });
    }


    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }

        const { assets_assigned, issue_types, ticket_slas } = this.state;

        return (
            <>
                <Navbar pageTitle={`Clarify Emailed Ticket (Temporary # ${this.props.match.params.id})`} />
                <div style={{ paddingTop: "15px" }} />
                <Container fluid display="flex">
                    <Paper withBorder p="md" radius="md" ml="20px" w="325px" style={{ float: "right" }} className={classes.showHideMiniKnowledgebase}>
                        <p className="suggestedPostsTitle gothamNarrowFont"><u>Ticket History Timeline</u></p>
                        <span className="miniKnowledgebase showHideMiniKnowledgebase">
                            {/* <p className="suggestedPostsTitle gothamNarrowFont">Suggested Related Posts</p> */}
                            {this.state.history && this.state.history.map((event, index) => (
                                <div style={{ textAlign: "center" }} className="gothamNarrowLightFont" key={`history_item-${event.id}`}>
                                    <div><b>{event.description}</b></div>
                                    <div style={{ fontSize: "10pt" }}>{`${event.creator_first_name} ${event.creator_last_name}`}</div>
                                    <div style={{ fontSize: "10pt" }}>{event.formatted_date_time_created}</div>
                                    {index === this.state.history.length - 1 ?
                                        undefined
                                        :
                                        <div style={{ paddingTop: "10px", paddingBottom: "10px", color: "blue" }}>|</div>
                                    }
                                </div>
                            ))

                            }
                            {/* <Table className="miniKnowledgebaseTable" borderless>
                            <tbody>
                                <tr>
                                    <th>
                                        Event
                                    </th>
                                    <th>
                                        By
                                    </th>
                                    <th>
                                        Time
                                    </th>
                                </tr>
                                {this.state.history && this.state.history.map((event) => (
                                    <tr key={`history_item-${event.id}`}>
                                        <td>
                                            {event.description}
                                        </td>
                                        <td>
                                            {`${event.creator_first_name} ${event.creator_last_name}`}
                                        </td>
                                        <td>
                                            {event.formatted_date_time_created}
                                        </td>
                                    </tr>
                                ))} */}
                            {/* <tr>
                                    <td>
                                        Ticket Edited
                                    </td>
                                    <td>
                                        Tom Johnson
                                    </td>
                                    <td>
                                        3/3/23
                                    </td>
                                </tr> */}
                            {/* </tbody>
                        </Table> */}
                        </span>
                    </Paper>
                    <Paper withBorder p="md" radius="md" m="5px" style={{ overflow: "auto" }}>
                        <Row style={{ marginTop: "20px" }}>
                            <Col md={{ span: 4 }} style={{ textAlign: "center" }}>
                                <div className="gothamNarrowFont defaultCursor">
                                    Requester
                                    {this.props.userPermissions.can_edit_tickets ?
                                        <div><RequesterSelector /><span style={{ position: "absolute", paddingLeft: "10px" }}><TicketSubmitterDetails submitterId={this.props.ticketRequester.requester_id_selected} token={this.props.loggedStatus.accessToken} /></span></div>
                                        :
                                        <div><b>{this.state.ticketDetails.requester_name}</b><span style={{ position: "absolute", top: "-4px", paddingLeft: "10px" }}><TicketSubmitterDetails submitterId={this.props.ticketRequester.requester_id_selected} token={this.props.loggedStatus.accessToken} /></span></div>
                                    }
                                </div>
                            </Col>
                            <Col md={{ span: 4 }} style={{ textAlign: "center" }}>
                                <div className="gothamNarrowFont defaultCursor">
                                    Site <div><b>{this.state.site_name}</b></div>
                                </div>
                            </Col>
                            <Col md={{ span: 4 }} style={{ textAlign: "center" }}>

                                {this.props.userPermissions.can_manage_assignments ?
                                    <div className="gothamNarrowFont defaultCursor"><ReassignTechSelector /></div> :
                                    <div className="gothamNarrowFont defaultCursor">Technician Assigned <div>{this.state.ticketDetails.assigned_tech_name}</div></div>
                                }

                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <Form /*onSubmit={this.createFinalTicket this.showCreationConfirmation}*/ >
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridRequesterId">
                                                <Form.Control type="hidden" /*value={this.props.loggedStatus.id}*/ name="requesterId" />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridSiteId">
                                                <Form.Control type="hidden" /*value={this.state.site_id}*/ name="siteId" />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAssetId">
                                                <Form.Control type="hidden" /*value={this.state.asset_id_selected}*/ name="assetId" />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCategoryId">
                                                <Form.Control type="hidden" /*value={this.state.ticket_category_id_selected}*/ name="categoryId" />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="typeOfTicket">
                                                <Select
                                                    label="Ticket Type"
                                                    placeholder="Pick value"
                                                    data={[{ label: "Tech Support", value: "Hardware" }]}
                                                    //defaultValue={"Unassigned"}
                                                    value={this.state.ticketTypeSelected}
                                                    onChange={(s) => {
                                                        this.onSelectTicketType(s);
                                                    }}
                                                    allowDeselect={false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAssetInvolved">
                                                <Select
                                                    label="Asset Involved"
                                                    placeholder="Pick value"
                                                    data={assets_assigned.map((asset) => (asset.name_of_asset))}
                                                    //defaultValue={"Unassigned"}
                                                    value={this.state.asset_name_selected}
                                                    onChange={(s) => {
                                                        this.handleEditAssetChange(assets_assigned, s);
                                                    }}
                                                    allowDeselect={false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridTypeOfIssue">
                                                <Select
                                                    label="Type of Issue"
                                                    placeholder="Pick value"
                                                    data={issue_types.map((category) => (category.name_of_category))}
                                                    //defaultValue={"Unassigned"}
                                                    value={this.state.ticket_category_selected}
                                                    onChange={(s) => {
                                                        this.handleEditTypeIssueChange(issue_types, s);
                                                    }}
                                                    allowDeselect={false}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPriority">
                                                <Select
                                                    label="Priority"
                                                    placeholder="Pick value"
                                                    data={ticket_slas.map((sla) => (sla.sla_name))}
                                                    //defaultValue={"Unassigned"}
                                                    value={this.state.ticket_sla_name_selected}
                                                    onChange={(s) => {
                                                        this.changePriority(s);
                                                    }}
                                                    allowDeselect={false}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridTicketTitle">
                                                <TextInput
                                                    label="Title"
                                                    defaultValue={this.state.ticketDetails.ticket_title}
                                                    onChange={this.onChangeTicketTitle}
                                                />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridIssueText">
                                                <Form.Label className="gothamNarrowFont">Describe Your Issue</Form.Label>
                                                <div data-text-editor="name">
                                                    <RichTextInput defaultValue={cleanHTML(this.state.ticketDetails.issue_text)} readOnly onChange={(e) => this.changeIssueText(e)} />
                                                </div>
                                                {/* <Form.Control as="textarea" style={{height: "120px", cursor: "default"}} defaultValue={parse(cleanHTML(this.state.ticketDetails.issue_text))} name="issueText" readOnly className="gothamNarrowLightFont" onChange={(e) => this.changeIssueText(e)} required/> */}
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridAttachmentList">
                                                {this.state.attachment_list && this.state.attachment_list.length > 0 &&
                                                    <Form.Label className="gothamNarrowFont">Attachments</Form.Label>
                                                }
                                                <ol>
                                                    {this.state.attachment_list && this.state.attachment_list.map((attachment) => (
                                                        <li key={attachment.id} className="gothamNarrowLightFont">
                                                            <a href={attachment.attachment_url} target="_blank" rel="noopener noreferrer">{attachment.filename}</a>
                                                        </li>
                                                    ))}
                                                </ol>
                                            </Form.Group>
                                        </Form.Row>
                                        {/* <Form.Row>
                                                            <div className="clarifyFormButtonContainer">
                                                                <div>
                                                                    <Link to="/myAssignedTickets">
                                                                        <Button variant="secondary" className="knowledgebaseButton, gothamNarrowFont">
                                                                            Cancel
                                                                        </Button>
                                                                    </Link>
                                                                </div>
                                                                <span>
                                                                    <Button variant="danger" onClick={() => this.showDeleteConfirmation()} className="gothamNarrowFont">
                                                                        Delete
                                                                    </Button>
                                                                </span>
                                                            </div>
                                                            
                                                        </Form.Row> */}
                                    </Form>
                                    {this.state.ticketDetails.original_cc_list && this.state.ticketDetails.original_cc_list.length > 0 ?
                                        <div style={{ marginBottom: "15px", marginBottom: "15px" }}>
                                            <div style={{ marginBottom: "10px", cursor: "default" }}><Text className="gothamNarrowFont" size="16px">Addresses in CC list of Original Message:</Text></div>
                                            {this.state.ticketDetails.original_cc_list && this.state.ticketDetails.original_cc_list.map((item) => {
                                                if (item.email_address.toLowerCase() !== this.state.ticketDetails.requester_email.toLowerCase()) {
                                                    return <div style={{ cursor: "default" }}><Text className="gothamNarrowLightFont" size="14px">{item.email_address}</Text></div>
                                                }
                                            })}
                                        </div>
                                        :
                                        undefined
                                    }
                                    <div className="gothamNarrowFont">
                                        Parent Ticket:
                                    </div>
                                    <div>
                                        <ParentTicketSelector ticket_type={"Unclarified"} ticket_id={this.props.match.params.id} parent_id={this.state.ticketDetails.parent_id} initiator_id={this.props.loggedStatus.id} timezone_adjustment_factor={this.props.loggedStatus.timezone_adjustment_factor} getUnclarifiedHistory={this.getHistory} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ marginBottom: "20px", marginTop: "30px" }}>
                                    {this.state.lastLoggedInDevices ? <PossiblyAffectedDevices deviceList={this.state.lastLoggedInDevices} /> : undefined}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <label className="adminPrivatePublicSwitch">
                                        <input type="checkbox" id="adminTogPrivatePublicBtn" onClick={this.handleAdminPrivatePublicToggleChange} checked={(this.state.is_private === 1) ? 0 : 1} />
                                        <div className="adminTogPrivatePublicSlider round">
                                            <span className="on gothamNarrowFont">Public</span>
                                            <span className="off gothamNarrowFont">Private</span>
                                        </div>
                                    </label>
                                    {/* <p>Note entering form would go here</p> */}
                                    <p className="suggestedPostsTitle gothamNarrowFont" style={{ marginBottom: "0" }}>
                                        {this.state.is_private ?
                                            "Your Notes (optional)" :
                                            "Your Response (optional)"
                                        }
                                    </p>
                                    <p className="suggestedPostsTitle gothamNarrowLightFont" style={{ fontSize: "12px", fontStyle: "italic" }}>
                                        {this.state.is_private ?
                                            "*Private notes will be seen by all technicians, and will not be sent to ticket creator. Switch to public mode to respond to the ticket." :
                                            "*Your response will be sent to ticket creator. Switch to private mode in order to add private notes."
                                        }
                                    </p>
                                    <span className="miniKnowledgebase showHideMiniKnowledgebase" style={{ maxWidth: "100%" }}>
                                        {this.state.is_private ?


                                            // <Form.Control as="textarea" style={{height: "125px"}} name="privateNote" value={this.state.private_note_text} className="gothamNarrowLightFont" onChange={this.onChangePrivateNote} defaultValue={this.state.private_note_text}></Form.Control> :
                                            <div data-text-editor="name">
                                                <RichTextInput defaultValue={cleanHTML(this.state.private_note_text)} onChange={e => this.onChangePrivateNote(e)} />
                                            </div> :
                                            // <Form.Control as="textarea" style={{height: "125px"}} name="techResponse" value={this.state.tech_response_text} className="gothamNarrowLightFont" onChange={this.updateTechResponse}></Form.Control>
                                            <div data-text-editor="name">
                                                <RichTextInput defaultValue={cleanHTML(this.state.private_note_text)} onChange={e => this.updateTechResponse(e)} />
                                            </div>




                                        }
                                        <div style={{ maxWidth: "100%", overflowX: "hidden" }}>
                                            <Form style={{ maxWidth: "100%", maxHeight: "100%", paddingLeft: "1px" }}>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="techResponse">


                                                        {this.state.showNoResponseError ?
                                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: No Response Provided</Form.Text>
                                                            :
                                                            undefined
                                                        }
                                                        {this.state.showImageDetectedError ?
                                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: Image detected in response. Please remove the image from the text box and use the Upload Attachments button below</Form.Text>
                                                            :
                                                            undefined
                                                        }
                                                    </Form.Group>

                                                </Form.Row>
                                                <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="attachmentUpload">
                                                            <Button variant="info" onClick={() => this.handleUploadButtonClick()}>Upload Attachments</Button>
                                                            {/* <Form.Label className="gothamNarrowLightFont">Attachment(s) (optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, or .txt and less than 50MB)</Form.Label> */}
                                                            <Form.Text className="gothamNarrowLightFont">(optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, or .txt and less than 50MB)</Form.Text>
                                                            <Form.Control className="gothamNarrowLightFont" type="file" accept=".jpeg, .jpg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, .txt" multiple onChange={(e) => this.handleAttachmentUploadChange(e)} style={{ display: "none" }} id="hiddenUploadButton" />
                                                        </Form.Group>

                                                    </Form.Row>
                                                </div>
                                                {this.state.attachmentFileNameList && this.state.attachmentFileNameList.length > 0 ?
                                                    <div style={{ paddingBottom: "20px" }}>
                                                        <div className="gothamNarrowFont">Included Attachments:</div>
                                                        {this.state.attachmentFileNameList && this.state.attachmentFileNameList.map(file => (
                                                            <div key={`${file.name}`} className="gothamNarrowLightFont">
                                                                <span style={{ paddingRight: "10px" }}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)" }} icon={faTimes} onClick={() => this.handleAttachmentDelete(file)} /></span>{file.name}
                                                            </div>
                                                        ))

                                                        }
                                                    </div>
                                                    :
                                                    undefined
                                                }
                                            </Form>
                                        </div>
                                    </span>
                                    <div className="containterClarifyTicketFormSubmitButton">
                                        {this.state.is_private ?
                                            <Button variant="primary" onClick={() => this.addPrivateNote()} className="gothamNarrowFont clarifyTicketFormSubmitButton" /*disabled={( (this.state.is_private === 1) ? true : false)}*/ >
                                                Save
                                            </Button>

                                            :

                                            <Button variant="primary" onClick={(e) => this.showCreationConfirmation(e)} className="gothamNarrowFont clarifyTicketFormSubmitButton" /*disabled={( (this.state.is_private === 1) ? true : false)}*/ >
                                                Submit
                                            </Button>
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="scrollTableWrapperTechNotes">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr className="techNoteTableHead">
                                                <th style={{ position: "sticky", top: "0", width: "50%" }} className="gothamNarrowFont">Note Preview</th>
                                                <th style={{ position: "sticky", top: "0", width: "25%" }} className="gothamNarrowFont">Created By</th>
                                                <th style={{ position: "sticky", top: "0", width: "25%" }} className="gothamNarrowFont">Time Created</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.tech_notes && this.state.tech_notes.map((note) => (
                                                <tr key={note.id} onClick={() => this.showViewTechNote(note.formatted_date_time_created, note.creator_name, note.note_text)}>
                                                    <td className="gothamNarrowLightFont">{note.note_text.length > 40 ? `${note.note_text.substring(0, 40)}...(more)` : note.note_text}</td>
                                                    <td className="gothamNarrowLightFont">{note.creator_name.length > 17 ? `${note.creator_name.substring(0, 17)}...` : note.creator_name}</td>
                                                    <td className="gothamNarrowLightFont">{note.formatted_date_time_created}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                                <div>
                                    <p className="gothamNarrowLightFont" style={{ textAlign: "center" }}>Click on a table row to view the full note</p>
                                </div>
                            </Col>
                        </Row>
                    </Paper>
                </Container>
                {/* This modal is the confirmation message for creating a ticket */}
                <Modal
                    opened={this.state.show_creation_confirmation}
                    onClose={() => { this.setState({ show_creation_confirmation: false }) }}
                    size="xl"
                    centered
                    title="Confirm your selection"
                >
                    <Modal.Body>
                        {this.state.is_private ?
                            <span>
                                <p className="gothamNarrowLightFont">Are you sure you want to save this private note?</p>
                                <p className="gothamNarrowLightFont">Note: this can not be changed later</p>
                            </span> :
                            <span>
                                {this.state.ticket_sla_id_selected !== null ?
                                    <span><p className="gothamNarrowLightFont">Are you sure you want to create this as a <b>{"Tech Support"/* this.state.ticketTypeSelected */}</b> ticket?</p>
                                        <p className="gothamNarrowLightFont">Note: this can not be changed later</p></span>
                                    :
                                    <p>Error: You have not yet selected a priority for this ticket. Please assign it a value</p>
                                }
                            </span>
                        }
                    </Modal.Body>
                    <div>
                        <Button variant="secondary" onClick={() => this.hideCreationConfirmation()} style={{ marginRight: "15px" }} className="gothamNarrowFont">Cancel</Button>
                        {this.state.is_private ?
                            <Button variant="primary" onClick={() => this.addPrivateNote()} className="gothamNarrowFont">Save</Button> :
                            <span>
                                {this.state.ticket_sla_id_selected !== null &&
                                    <Button variant="primary" onClick={() => this.createFinalTicket()} className="gothamNarrowFont">Submit</Button>
                                }
                            </span>
                        }
                    </div>
                </Modal>

                {/* This modal is the confirmation message for deleting a ticket */}
                <Modal
                    opened={this.state.show_delete_confirmation}
                    onClose={() => { this.setState({ show_delete_confirmation: false }) }}
                    size="xl"
                    centered
                    title="Confirm deletion"
                >
                    <Modal.Body>
                        <p className="gothamNarrowLightFont">Are you sure you want to delete this message?</p>
                        <p className="gothamNarrowLightFont">Note: this can not be undone</p>
                    </Modal.Body>
                    <div>
                        <Button variant="secondary" onClick={() => this.hideDeleteConfirmation()} style={{marginRight: "15px"}} className="gothamNarrowFont">Cancel</Button>
                        <Button variant="danger" onClick={() => this.deleteTicketOutright()} className="gothamNarrowFont">Delete</Button>
                    </div>
                </Modal>

                {/* This modal is the view tech note pop up */}
                <Modal
                    opened={this.state.show_tech_note}
                    onClose={() => { this.hideViewTechNote() }}
                    size="xl"
                    centered
                    title="Viewing tech note"
                >
                    <Modal.Body>
                        <div style={{ maxHeight: "200px", overflow: "scroll", cursor: "default" }}>
                            <p className="gothamNarrowLightFont"><b>Time Created:</b> {this.state.tech_note_viewing_time_created}</p>
                            <p className="gothamNarrowLightFont"><b>Creator Name:</b> {this.state.tech_note_viewing_creator_name}</p>
                            <p className="gothamNarrowLightFont"><b>Note Text:</b></p>
                            <p className="gothamNarrowLightFont">{parse(cleanHTML(this.state.tech_note_viewing_note_text))}</p>
                            {/* <p className="gothamNarrowLightFont">{this.state.tech_note_viewing_note_text.split('\n').map(str => <p>{str}</p>)}</p> */}
                        </div>
                    </Modal.Body>
                    <Button variant="primary" onClick={() => this.hideViewTechNote()} className="gothamNarrowFont">Ok</Button>
                </Modal>

                {/* This modal is for the stale token warning */}
                <Modal
                    opened={this.state.showStaleTokenError}
                    onClose={() => { this.setState({ showStaleTokenError: false }) }}
                    size="xl"
                    centered
                    title="Operation failed due to stale token"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Error: The token used to authenticate with Microsoft has become stale.</p>
                            <p className="gothamNarrowLightFont">Please copy any information needed on the form, refresh the page, and try submitting again.</p>
                        </span>
                    </Modal.Body>
                    <Button variant="primary" onClick={() => this.hideStaleTokenError()} className="gothamNarrowFont">Ok</Button>
                </Modal>

                {/* This modal is for the loading screen */}
                <Modal
                    opened={this.state.showSaving}
                    onClose={() => { this.setState({ showSaving: false }) }}
                    size="xl"
                    centered
                >


                    <Modal.Body>
                        <div className="gothamNarrowLightFont" style={{ cursor: "default", textAlign: "center" }}>Saving... Please wait.</div>
                    </Modal.Body>

                </Modal>
            </>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        userPermissions: state.userPermissions,
        ticketRequester: state.ticketRequester,
        reassignTech: state.reassignTech
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, UPDATE_ACTIVE_ASSIGNMENT, ASSIGNMENT_CHANGE_RESOLVED, UPDATE_ACTIVE_REQUESTER, REQUESTER_CHANGE_RESOLVED, LOGOUT })(ClarifyTicketForm);