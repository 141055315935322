import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Grid,
    Text,
    Textarea,
    Tabs,
    Center,
    TextInput,
    NumberInput,
    rem,
    keys,
    Button,
    Modal,
    Paper,
    Pill,
    Select,
    Switch,
    Tooltip,
    Flex,
    Checkbox
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import contractClasses from './View-Contracts-and-Software.module.css';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconMenu2, IconFilterX, IconEdit, IconPlus } from '@tabler/icons-react';

import Navbar from '../../components/Navbar';

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//import classes from '../TableSort.module.css';

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
//import Modal from 'react-bootstrap/Modal';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { faSearch, faFilter, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
//import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
//import { Form } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

import HardwareAssetDataService from '../../services/hardware-asset-activities';
import SoftwareAssetDataService from '../../services/software-asset-activities';
//import { Tabs } from "react-bootstrap";

import MAX_ATTACHMENT_SIZE from "../../max-attachment-size";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";



class CreateContract extends Component {
    constructor(props) {
        super(props);

        this.state = { 
            customers: [],       // this will come from the backend as an array of objects [{id: 1, name: "LPA"}, {id: 2, name: "ProGroup"}]
        
            software: [{id: 0, name: "None"}],       // from software table [{id: 1, name: "Microsoft 365"}, {id: 2, name: "Revit"}] - id of 0 is hardcoded so backend knows to not save anything there (in case field ever not required)

            vendors: [{id: 0, name_of_vendor: "None"}],  // from vendors table [{id: 1, name: "Ingram Micro"}] - id of 0 is hardcoded so backend knows to not save anything there (in case field ever not required)

            approvers: [{id: 0, name: "None"}], // from uncreated approvers table? - currently using user list from caller's tenant [{id: 1, name: "Bob Johnson"}] - id 0 is hardcoded so backend knows to use empty value

            selected_customer_id: 0,
            selected_customer_name: "",

            selected_form_tab: "Basic Info",

            show_customer_modal: false,
            canSeeErrorMessage: false,
            canSeeDuplicateNameError: false,        /* combo of company + contract name must be unique */
            show_vendor_modal: false,


            /* Form field values */
            contract_name_entered: "",
            description_entered: "",
            customer_id_number_csn_entered: "",
            serial_number_entered: "",
            contract_number_entered: "",
            selected_vendor_id: 0,
            selected_vendor_name: "",
            selected_contract_type: "", /* drop down - fixed values */
            selected_contract_status: "",   /* drop down - fixed values */
            cost_entered: null,
            selected_approver_id: 0,
            selected_approver_name: "",
            start_date_entered: "",
            end_date_entered: "",
            selected_software_id: 0,
            selected_software_name: "",
            service_name_entered: "",
            selected_pricing_model: "",  /* drop down - fixed values */
            count_entered: null,
            selected_billing_cycle: "",  /* drop down - fixed values */
            selected_term: "",           /* drop down - fixed values */
            selected_license_type: "",      /* drop down - fixed values */
            license_key_entered: "",
            selected_renewal_status: "",     /* drop down - fixed values */

            cancelation_notification_window_entered: 0,
            remind_me_notification_window_entered: 0,
            red_notification_window_entered: 0,
            is_resolved: 0,     /* Indicator that we do or do not need to display this item in the notification center when date approaches */


            /* This value is for the create vendor modal */
            create_vendor_name_entered: "",
            canSeeErrorMessageCreateVendor: false,
            canSeeDuplicateNameErrorCreateVendor: false,

            attachmentFileNameList: [],

            showSaving: false       // determines if the saving modal is displayed
        }
    }

    componentDidMount() {
        
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.setState({
                        showSaving: false
                    });

                    this.props.ACCESSTOKEN(token);

                    // get the customer list for this tenant
                    SoftwareAssetDataService.getCustomers(token, this.props.loggedStatus.primary_tenant_id).then(response => {
                        console.log(response);

                        this.setState({
                            customers: response.data.results
                        }, () => {
                            // if we've come to this form having previously selected All as the customer, we'll need to adapt - pick the first customer in the list, or leave blank if none in list
                            if(this.props.softwareAssetFilter.selectedContractCustomerName === "All") {
                                if(this.state.customers && this.state.customers.length > 0) {
                                    this.setState({
                                        selected_customer_id: this.state.customers[0].id,
                                        selected_customer_name: this.state.customers[0].name,
                                        show_customer_modal: true       // present them with the customer modal since they were on All
                                    });
                                }
                            }

                            // they came in having selected a definitive customer, so use that one by default
                            else {
                                this.setState({
                                    selected_customer_id: this.props.softwareAssetFilter.selectedContractCustomerId,
                                    selected_customer_name: this.props.softwareAssetFilter.selectedContractCustomerName
                                });
                            }

                            // any remaining boot up code goes here!
                            SoftwareAssetDataService.getDropdownValuesForContractForm(token, this.props.loggedStatus.primary_tenant_id).then(response => {
                                console.log(response);

                                this.setState({
                                    software: [...this.state.software, ...response.data.software_list],
                                    vendors: [...this.state.vendors, ...response.data.vendor_list],
                                    approvers: [...this.state.approvers, ...response.data.approver_list]
                                });
                            })
                        });
                    });
                }
            });
        }
        
        
    }

    openCustomerModal() {
        this.setState({
            show_customer_modal: true
        });
    }

    closeCustomerModal() {
        this.setState({
            show_customer_modal: false
        });
    }

    openVendorModal() {
        this.setState({
            show_vendor_modal: true,
            create_vendor_name_entered: "",
            canSeeErrorMessageCreateVendor: false,
            canSeeDuplicateNameErrorCreateVendor: false
        });
    }

    closeVendorModal() {
        this.setState({
            show_vendor_modal: false,
            create_vendor_name_entered: "",
            canSeeErrorMessageCreateVendor: false,
            canSeeDuplicateNameErrorCreateVendor: false
        });
    }

    handleCustomerChange(newCustomerId, newCustomerName) {
        

        this.setState({
            selected_customer_id: newCustomerId,
            selected_customer_name: newCustomerName
        });
    }

    handleContractNameChange(newName) {
        this.setState({
            contract_name_entered: newName
        });
    }


    handleDescriptionChange(newDescription) {
        this.setState({
            description_entered: newDescription
        });
    }

    handleCustomerIdCSNChange(newId) {
        this.setState({
            customer_id_number_csn_entered: newId
        });
    }

    handleSerialNumberChange(newNumber) {
        this.setState({
            serial_number_entered: newNumber
        });
    }

    handleContractNumberChange(newNumber) {
        this.setState({
            contract_number_entered: newNumber
        });
    }

    handleVendorChange(newVendorName, newVendorId) {
        this.setState({
            selected_vendor_name: newVendorName,
            selected_vendor_id: newVendorId
        });
    }

    handleTypeChange(newType) {
        this.setState({
            selected_contract_type: newType
        });
    }

    handleStatusChange(newStatus) {
        this.setState({
            selected_contract_status: newStatus
        });
    }

    handleCostChange(newCost) {
        this.setState({
            cost_entered: newCost
        });
    }

    handleApproverChange(newApproverName, newApproverId) {
        this.setState({
            selected_approver_name: newApproverName,
            selected_approver_id: newApproverId
        });
    }

    handleStartDateChange(newDate) {
        this.setState({
            start_date_entered: newDate
        });
    }

    handleEndDateChange(newDate) {
        this.setState({
            end_date_entered: newDate
        });
    }

    handleSoftwareChange(newSoftwareName, newSoftwareId) {
        this.setState({
            selected_software_name: newSoftwareName,
            selected_software_id: newSoftwareId
        });
    }

    handleServiceNameChange(newName) {
        this.setState({
            service_name_entered: newName
        });
    }

    handlePricingModelChange(newModel) {
        this.setState({
            selected_pricing_model: newModel
        });
    }

    handleCountChange(newNumber) {
        this.setState({
            count_entered: newNumber
        });
    }

    handleBillingCycleChange(newName) {
        this.setState({
            selected_billing_cycle: newName
        });
    }

    handleTermChange(newName) {
        this.setState({
            selected_term: newName
        });
    }

    handleCancelationWindowChange(newValue) {
        this.setState({
            cancelation_notification_window_entered: newValue
        });
    }

    handleRemindMeWindowChange(newValue) {
        this.setState({
            remind_me_notification_window_entered: newValue
        });
    }

    handleRedWindowChange(newValue) {
        this.setState({
            red_notification_window_entered: newValue
        });
    }

    handleIsResolvedChange() {
        // switches between true and false
        let newValue;

        if (this.state.is_resolved === 0) {
            newValue = 1;
        }

        else {
            newValue = 0;
        }

        this.setState({
            is_resolved: newValue
        });
    }

    handleLicenseTypeChange(newName) {
        this.setState({
            selected_license_type: newName
        });
    }

    handleLicenseKeyChange(newKey) {
        this.setState({
            license_key_entered: newKey
        });
    }

    handleRenewalStatusChange(newName) {
        this.setState({
            selected_renewal_status: newName
        });
    }

    handleCreateVendorNameChange(newName) {
        this.setState({
            create_vendor_name_entered: newName
        });
    }

    // gets list of vendors for the add contract drop down menu
    getVendors(token, tenantId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getVendors(token, tenantId)
                .then(response => { // take response and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    submitCreateVendorForm() {
        console.log("Values submitting to backend")
        console.log("Name: ", this.state.create_vendor_name_entered);

        if (this.state.create_vendor_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateVendor: false,
                canSeeErrorMessageCreateVendor: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateVendor: false
            });

            // check for duplicate vendor
            // NOW ALSO PASSING TENANT ID BECAUSE TENANT + NAME MUST BE UNIQUE
            HardwareAssetDataService.checkForDuplicateVendor(this.props.loggedStatus.accessToken, this.state.create_vendor_name_entered, this.props.loggedStatus.primary_tenant_id).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateVendor: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateVendor: false
                    });

                    // send data to backend for processing
                    let data = {
                        vendorName: this.state.create_vendor_name_entered,
                        tenantId: this.props.loggedStatus.primary_tenant_id     // we're now saving vendors by tenant for better multitenancy
                    };

                    HardwareAssetDataService.createVendor(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getVendors(this.props.loggedStatus.accessToken, this.props.loggedStatus.primary_tenant_id).then(response => {
                            this.setState({
                                vendors: [{ id: 0, name_of_vendor: "None" }, ...response.results],
                                selected_vendor_id: 0,
                                selected_vendor_name: ""
                            });

                            this.closeVendorModal();
                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        })

                }
            })
                .catch(e => {
                    console.log(e);
                });

        }
    }


    handleUploadButtonClick() {
        document.getElementById('hiddenUploadButton').click();
    }

    handleAttachmentUploadChange(e) {
        console.log(e.target.files)

        try {

            let newAttachmentList = this.state.attachmentFileNameList;

            for (let i = 0; i < e.target.files.length; i++) {

                let file_extension = e.target.files[i].name.substring(e.target.files[i].name.lastIndexOf('.') + 1, e.target.files[i].name.length) || e.target.files[i].name;

                if (e.target.files[i].size <= MAX_ATTACHMENT_SIZE && (file_extension.toLowerCase() === "jpeg" || file_extension.toLowerCase() === "png" || file_extension.toLowerCase() === "jpg" || file_extension.toLowerCase() === "bmp" || file_extension.toLowerCase() === "heic" || file_extension.toLowerCase() === "pdf" || file_extension.toLowerCase() === "doc" || file_extension.toLowerCase() === "docx" || file_extension.toLowerCase() === "xls" || file_extension.toLowerCase() === "xlsx" || file_extension.toLowerCase() === "ppt" || file_extension.toLowerCase() === "pptx" || file_extension.toLowerCase() === "dwg" || file_extension.toLowerCase() === "txt")) {

                    console.log(e.target.files[i].name);

                    let filename = e.target.files[i].name

                    newAttachmentList.push(e.target.files[i]);

                    // let reader = new FileReader();
                    // reader.readAsDataURL(e.target.files[i]);
                    // reader.onloadend = (event) => {
                    //     // The contents of the BLOB are in reader.result:
                    //     console.log(reader.result);
                    //     newAttachmentList.push({filename: filename, contents: reader.result});
                    // }

                }

                else {
                    console.log("This file was either too big or an incorrect file type");
                }

            }

            this.setState({
                attachmentFileNameList: newAttachmentList
            }, () => console.log(this.state.attachmentFileNameList));

        }

        catch {
            console.log("No files seen")
        }
    }


    // this fxn removes the specified attachment from the list of attachments to send to backend for saving
    handleAttachmentDelete(fileInfo) {
        console.log(fileInfo);

        let newAttachmentList = [];

        let foundDuplicate = false;     // if there's another instance of that one in the list, only remove one of them

        for (let i = 0; i < this.state.attachmentFileNameList.length; i++) {
            if (!foundDuplicate && this.state.attachmentFileNameList[i].lastModified === fileInfo.lastModified && this.state.attachmentFileNameList[i].name === fileInfo.name && this.state.attachmentFileNameList[i].size === fileInfo.size && this.state.attachmentFileNameList[i].type === fileInfo.type) {
                // if you found the match of the one to delete, don't include it in the new attachment array
                foundDuplicate = true;
                continue;
            }

            else {
                newAttachmentList.push(this.state.attachmentFileNameList[i]);
            }
        }

        this.setState({
            attachmentFileNameList: newAttachmentList
        });
    }    


    submitNewContract() {
        console.log("Submitting contract with the following details");

        let startDateToUse;
        if(this.state.start_date_entered === null || this.state.start_date_entered === "") {
            startDateToUse = "";
        }

        else {
            startDateToUse = this.state.start_date_entered.toISOString();
            startDateToUse = startDateToUse.split("T")[0];  // save everything to the left of the T - old format was 2024-03-30T00:00:00.000Z
        }

        let endDateToUse;
        if(this.state.end_date_entered === null || this.state.end_date_entered === "") {
            endDateToUse = "";
        }

        else {
            endDateToUse = this.state.end_date_entered.toISOString();
            endDateToUse = endDateToUse.split("T")[0];
        }

        const formDataObj = new FormData();

        for(let z = 0; z < this.state.attachmentFileNameList.length; z++) {
            formDataObj.append('attachmentList', this.state.attachmentFileNameList[z]);
        }

        // let data = {
        //     customer_id: this.state.selected_customer_id,
        //     customer_name: this.state.selected_customer_name,
        //     contract_name: this.state.contract_name_entered,
        //     description: this.state.description_entered,
        //     customer_id_number_csn: this.state.customer_id_number_csn_entered,
        //     serial_number: this.state.serial_number_entered,
        //     contract_number: this.state.contract_number_entered,
        //     vendor_id: this.state.selected_vendor_id,
        //     vendor_name: this.state.selected_vendor_name,
        //     contract_type: this.state.selected_contract_type,
        //     contract_status: this.state.selected_contract_status,
        //     cost: this.state.cost_entered,
        //     approver_id: this.state.selected_approver_id,
        //     approver_name: this.state.selected_approver_name,
        //     start_date: startDateToUse,
        //     end_date: endDateToUse,
        //     software_id: this.state.selected_software_id,
        //     software_name: this.state.selected_software_name,
        //     service_name: this.state.service_name_entered,
        //     pricing_model: this.state.selected_pricing_model,
        //     count: this.state.count_entered,
        //     billing_cycle: this.state.selected_billing_cycle,
        //     license_type: this.state.selected_license_type,
        //     license_key: this.state.license_key_entered,
        //     renewal_status: this.state.selected_renewal_status,
        //     //user_id_of_creator: this.props.loggedStatus.id,      // using this to get the tenant id of the asker, so we know the managing tenant id for the asset
        //     managing_tenant_id: this.props.loggedStatus.primary_tenant_id,

        //     term: this.state.selected_term,
        //     cancelation_window: this.state.cancelation_notification_window_entered,
        //     remind_me_window: this.state.remind_me_notification_window_entered,
        //     red_notification_window: this.state.red_notification_window_entered,
        //     is_resolved: this.state.is_resolved
        // }
        formDataObj.append('customer_id', this.state.selected_customer_id);
        formDataObj.append('customer_name', this.state.selected_customer_name);
        formDataObj.append('contract_name', this.state.contract_name_entered);
        formDataObj.append('description', this.state.description_entered);
        formDataObj.append('customer_id_number_csn', this.state.customer_id_number_csn_entered);
        formDataObj.append('serial_number', this.state.serial_number_entered);
        formDataObj.append('contract_number', this.state.contract_number_entered);
        formDataObj.append('vendor_id', this.state.selected_vendor_id);
        formDataObj.append('vendor_name', this.state.selected_vendor_name);
        formDataObj.append('contract_type', this.state.selected_contract_type);
        formDataObj.append('contract_status', this.state.selected_contract_status);
        formDataObj.append('cost', this.state.cost_entered);
        formDataObj.append('approver_id', this.state.selected_approver_id);
        formDataObj.append('approver_name', this.state.selected_approver_name);
        formDataObj.append('start_date', startDateToUse);
        formDataObj.append('end_date', endDateToUse);
        formDataObj.append('software_id', this.state.selected_software_id);
        formDataObj.append('software_name', this.state.selected_software_name);
        formDataObj.append('service_name', this.state.service_name_entered);
        formDataObj.append('pricing_model', this.state.selected_pricing_model);
        formDataObj.append('count', this.state.count_entered);
        formDataObj.append('billing_cycle', this.state.selected_billing_cycle);
        formDataObj.append('license_type', this.state.selected_license_type);
        formDataObj.append('license_key', this.state.license_key_entered);
        formDataObj.append('renewal_status', this.state.selected_renewal_status);
        formDataObj.append('managing_tenant_id', this.props.loggedStatus.primary_tenant_id);
        formDataObj.append('term', this.state.selected_term);
        formDataObj.append('cancelation_window', this.state.cancelation_notification_window_entered);
        formDataObj.append('remind_me_window', this.state.remind_me_notification_window_entered);
        formDataObj.append('red_notification_window', this.state.red_notification_window_entered);
        formDataObj.append('is_resolved', this.state.is_resolved);

        for (let [key, value] of formDataObj.entries()) {
            console.log(key, value);
        }

        // first, make sure that required fields are filled out
        if(this.state.selected_customer_id === 0 || this.state.contract_name_entered === "" || this.state.contract_number_entered === "" || this.state.selected_software_id === 0 || this.state.selected_vendor_id === 0 || this.state.selected_contract_type === "" || this.state.selected_contract_status === "") {
            this.setState({
                canSeeErrorMessage: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessage: false
            });

            this.setState({
                showSaving: true
            });

            // try to submit contract, but first, check for duplicate company + contract name
            SoftwareAssetDataService.createContract(this.props.loggedStatus.accessToken, formDataObj).then(response => {
                console.log(response);
                if(response.data.results === "Duplicate Found!") {
                    this.setState({
                        showSaving: false,
                        canSeeDuplicateNameError: true
                    });
                }

                else if(response.data.results === "Request completed") {  // we've sucessfully added the entry
                    console.log("would be navigating back to software assets here");
                    this.setState({
                        showSaving: false,
                        canSeeDuplicateNameError: false
                    });

                    // navigate back to the software assets page
                    this.props.history.push(`/softwareAssets`);
                }
            });
        }
    }


    render() {
        if (!this.props.loggedStatus.loggedIn || (/* !this.props.userPermissions.can_see_assets && !this.props.userPermissions.can_edit_assets &&  */ !this.props.userPermissions.can_add_assets /* && !this.props.userPermissions.can_delete_assets */)) {
            return (
                <Redirect to='/' />
            );
        }

        return (
            <>
                <Navbar pageTitle={"Create Contract"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                <Container fluid pt="20px" >
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Grid>
                            <Grid.Col span={6}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Customer</Table.Td>
                                        <Table.Td c="blue">{this.state.selected_customer_name}</Table.Td>
                                    </Table.Tr>
                                </Table>
                            </Grid.Col>
                            <Grid.Col span={6} align="right">
                                <div style={{position: "relative"}}>
                                    <span style={{position: "absolute", right: "100px", top: "10px"}}><Checkbox 
                                        label="Disable Notifications"
                                        //description="Determines if this item will appear in the notification center when cutoff dates approach"
                                        //style={{marginTop: "32px"}}
                                        checked={this.state.is_resolved}
                                        onChange={() => this.handleIsResolvedChange()}
                                    />
                                    </span>
                                    <span><Button onClick={() => this.openCustomerModal()}><IconEdit/></Button></span>
                                </div>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block" style={{height: "425px"}}>
                        <Tabs radius="md" defaultValue="Basic Info">
                            <Tabs.List>
                                <Tabs.Tab value="Basic Info" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Basic Info</Tabs.Tab>
                                <Tabs.Tab value="Pricing and Renewal" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Pricing and Renewal</Tabs.Tab>
                                <Tabs.Tab value="License" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>License</Tabs.Tab>
                                <Tabs.Tab value="Misc" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Misc</Tabs.Tab>
                                <Tabs.Tab value="Attachments" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Attachments</Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value="Basic Info">
                                {this.state.selected_form_tab === "Basic Info" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractName">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="Contract Name" placeholder="Enter Name Here" value={this.state.contract_name_entered} onChange={(e) => this.handleContractNameChange(e.target.value)} required/>
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractNumber">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="Contract Number" placeholder="Enter Number Here" value={this.state.contract_number_entered} onChange={(e) => this.handleContractNumberChange(e.target.value)} required/>
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractSoftware">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Software"
                                                            placeholder="Pick a Value"
                                                            data={this.state.software && this.state.software.map((software) => (
                                                                software.name
                                                            ))}
                                                            value={
                                                                this.state.selected_software_id !== 0 && this.state.software && this.state.software.length > 0 && this.state.software.find((e) => e.id === this.state.selected_software_id) ? 
                                                                    this.state.software.find((e) => e.id === this.state.selected_software_id).name
                                                                    :
                                                                    ""
                                                            }
                                                            onChange={(s) => {
                                                                var softwareId = this.state.software.find((e) => e.name === s).id;
                                                                if(softwareId !== null) {
                                                                    this.handleSoftwareChange(s, softwareId);
                                                                }
                                                            }}
                                                            allowDeselect={false}
                                                            searchable
                                                            required
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractVendor">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Vendor"
                                                            placeholder="Pick a Value"
                                                            data={this.state.vendors && this.state.vendors.map((vendor) => (
                                                                vendor.name_of_vendor
                                                            ))}
                                                            value={
                                                                this.state.selected_vendor_id !== 0 && this.state.vendors && this.state.vendors.length > 0 && this.state.vendors.find((e) => e.id === this.state.selected_vendor_id) ? 
                                                                    this.state.vendors.find((e) => e.id === this.state.selected_vendor_id).name_of_vendor
                                                                    :
                                                                    ""
                                                            }
                                                            onChange={(s) => {
                                                                var vendorId = this.state.vendors.find((e) => e.name_of_vendor === s).id;
                                                                if(vendorId !== null) {
                                                                    this.handleVendorChange(s, vendorId);
                                                                }
                                                            }}
                                                            allowDeselect={false}
                                                            searchable
                                                            required
                                                        />
                                                    </span>
                                                    <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                        <Tooltip label="Click to create a new vendor"><IconPlus color="#00abfb" onClick={() => this.openVendorModal()} /></Tooltip>
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractType">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Contract Type"
                                                            placeholder="Pick a Value"
                                                            data={["Lease", "Maintenance", "Software License", "Warranty"]}
                                                            value={this.state.selected_contract_type}
                                                            onChange={(s) => {this.handleTypeChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                            required
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractStatus">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Contract Status"
                                                            placeholder="Pick a Value"
                                                            data={["Active", "Inactive"]}
                                                            value={this.state.selected_contract_status}
                                                            onChange={(s) => {this.handleStatusChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                            required
                                                        />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractStartDate">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <DateInput 
                                                            label="Contract Start Date"
                                                            placeholder="Pick a Date"
                                                            onChange={(s) => {
                                                                let dateString;
                                                                if(s === null) {
                                                                    dateString = "";
                                                                }

                                                                else {
                                                                    dateString = s;
                                                                }
                                                                this.handleStartDateChange(dateString);
                                                            }}
                                                            value={this.state.start_date_entered}
                                                            clearable
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractEndDate">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <DateInput 
                                                            label="Contract End Date"
                                                            placeholder="Pick a Date"
                                                            onChange={(s) => {
                                                                let dateString;
                                                                if(s === null) {
                                                                    dateString = "";
                                                                }

                                                                else {
                                                                    dateString = s;
                                                                }
                                                                this.handleEndDateChange(dateString);
                                                            }}
                                                            value={this.state.end_date_entered}
                                                            clearable
                                                        />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="Pricing and Renewal">
                                {this.state.selected_form_tab === "Pricing and Renewal" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractCost">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <NumberInput
                                                            label="Cost"
                                                            placeholder="Enter a Dollar Amount"
                                                            allowNegative={false}
                                                            decimalScale={2}
                                                            fixedDecimalScale
                                                            step={0.01}
                                                            prefix="$"
                                                            hideControls
                                                            value={this.state.cost_entered}
                                                            onChange={(e) => {
                                                                //console.log(e);
                                                                if(e === null || e === "") {
                                                                    this.handleCostChange(null);
                                                                }
                                                                else {
                                                                    this.handleCostChange(e);
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractPricingModel">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Pricing Model"
                                                            placeholder="Pick a Value"
                                                            data={["Fixed", "One Time", "Per Unit"]}
                                                            value={this.state.selected_pricing_model}
                                                            onChange={(s) => {this.handlePricingModelChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractBillingCycle">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Billing Cycle"
                                                            placeholder="Pick a Value"
                                                            data={["Annual", "Annual Paid Monthly", "2 Year", "3 Year", "Half Yearly", "Monthly", "Monthly Paid Monthly", "One Time", "Quarterly"]}
                                                            value={this.state.selected_billing_cycle}
                                                            onChange={(s) => {this.handleBillingCycleChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractRenewalStatus">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Renewal Status"
                                                            placeholder="Pick a Value"
                                                            data={["Auto Renew", "Cancelled", "Complete", "In Progress", "On Hold"]}
                                                            value={this.state.selected_renewal_status}
                                                            onChange={(s) => {this.handleRenewalStatusChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractTerm">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Term"
                                                            placeholder="Pick a Value"
                                                            data={["Annual", "Annual Paid Monthly", "2 Year", "3 Year", "Half Yearly", "Monthly", "Monthly Paid Monthly", "One Time", "Quarterly"]}
                                                            value={this.state.selected_term}
                                                            onChange={(s) => {this.handleTermChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractCancelWindow">
                                                    <span style={{ display: "inline-block", width: "95%"}}>
                                                        <NumberInput 
                                                            label="Cancelation Window (Number of Days)"
                                                            placeholder="Enter an Integer"
                                                            allowDecimal={false}
                                                            allowNegative={false}
                                                            min={1}
                                                            value={this.state.cancelation_notification_window_entered}
                                                            onChange={(e) => this.handleCancelationWindowChange(e)}
                                                        />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractRemindMeWindow">
                                                    <span style={{ display: "inline-block", width: "95%"}}>
                                                        <NumberInput 
                                                            label="Remind Me Window (Number of Days)"
                                                            placeholder="Enter an Integer"
                                                            allowDecimal={false}
                                                            allowNegative={false}
                                                            min={1}
                                                            value={this.state.remind_me_notification_window_entered}
                                                            onChange={(e) => this.handleRemindMeWindowChange(e)}
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractRedWindow">
                                                    <span style={{ display: "inline-block", width: "95%"}}>
                                                        <NumberInput 
                                                            label="Turn Notifications Red (Number of Days)"
                                                            placeholder="Enter an Integer"
                                                            allowDecimal={false}
                                                            allowNegative={false}
                                                            min={1}
                                                            value={this.state.red_notification_window_entered}
                                                            onChange={(e) => this.handleRedWindowChange(e)}
                                                        />
                                                    </span>
                                                </Form.Group>   
                                            </Form.Row>
                                            {/* <Form.Row>
                                                <Form.Group as={Col} controlId="addContractIsResolved" xs={6}>
                                                    <Checkbox 
                                                        label="Disable Notifications"
                                                        //description="Determines if this item will appear in the notification center when cutoff dates approach"
                                                        style={{marginTop: "32px"}}
                                                        checked={this.state.is_resolved}
                                                        onChange={() => this.handleIsResolvedChange()}
                                                    />
                                                </Form.Group>
                                            </Form.Row> */}
                                        </Form>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="License">
                                {this.state.selected_form_tab === "License" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractLicenseType">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="License Type"
                                                            placeholder="Pick a Value"
                                                            data={["CSP", "Enterprise", "Free", "Open Source", "Open Value", "Trial", "Volume"]}
                                                            value={this.state.selected_license_type}
                                                            onChange={(s) => {this.handleLicenseTypeChange(s)}}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractLicenseKey">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="License Key" placeholder="Enter Key Here" value={this.state.license_key_entered} onChange={(e) => this.handleLicenseKeyChange(e.target.value)} />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractCount" xs={6}>
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <NumberInput
                                                            label="Count"
                                                            placeholder="Enter a Whole Number"
                                                            allowNegative={false}
                                                            decimalScale={0}
                                                            fixedDecimalScale
                                                            //step={0.01}
                                                            //prefix="$"
                                                            hideControls
                                                            value={this.state.count_entered}
                                                            onChange={(e) => {
                                                                if(e === null || e === "") {
                                                                    this.handleCountChange(null);
                                                                }

                                                                else {
                                                                    this.handleCountChange(e);
                                                                }
                                                            }}
                                                        />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="Misc">
                                {this.state.selected_form_tab === "Misc" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractCSNNumber">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="Customer Id Number (CSN#)" placeholder="Enter Value Here" value={this.state.customer_id_number_csn_entered} onChange={(e) => this.handleCustomerIdCSNChange(e.target.value)} />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractSerialNumber">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="Serial Number" placeholder="Enter Value Here" value={this.state.serial_number_entered} onChange={(e) => this.handleSerialNumberChange(e.target.value)} />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractApprover">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Select 
                                                            label="Approver"
                                                            placeholder="Pick a Value"
                                                            data={this.state.approvers && this.state.approvers.map((approver) => (
                                                                approver.name
                                                            ))}
                                                            value={
                                                                this.state.selected_approver_id !== 0 && this.state.approvers && this.state.approvers.length > 0 && this.state.approvers.find((e) => e.id === this.state.selected_approver_id) ? 
                                                                    this.state.approvers.find((e) => e.id === this.state.selected_approver_id).name
                                                                    :
                                                                    ""
                                                            }
                                                            onChange={(s) => {
                                                                var approverId = this.state.approvers.find((e) => e.name === s).id;
                                                                if(approverId !== null) {
                                                                    this.handleApproverChange(s, approverId);
                                                                }
                                                            }}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addContractServiceName">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="Service Name" placeholder="Enter Value Here" value={this.state.service_name_entered} onChange={(e) => this.handleServiceNameChange(e.target.value)} />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addContractDescription" xs={6}>
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <Textarea label="Description" placeholder="Enter Description Here" value={this.state.description_entered} onChange={(e) => this.handleDescriptionChange(e.target.value)}/>
                                                    </span>
                                                </Form.Group> 
                                            </Form.Row>  
                                        </Form>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="Attachments">
                                {this.state.selected_form_tab === "Attachments" ?
                                    <Form>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="addContractAttachments">
                                                <span style={{ display: "inline-block", width: "95%", marginTop: "32px"}}>
                                                    <Button variant="info" onClick={() => this.handleUploadButtonClick()}>Upload Attachments</Button>
                                                    <Form.Text className="gothamNarrowLightFont">(optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, or .txt and less than 50MB)</Form.Text>
                                                    <Form.Control className="gothamNarrowLightFont" type="file" accept=".jpeg, .jpg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, .txt" multiple onChange={(e) => this.handleAttachmentUploadChange(e)} style={{ display: "none" }} id="hiddenUploadButton" />
                                                </span>
                                            </Form.Group>
                                        </Form.Row>
                                        {this.state.attachmentFileNameList && this.state.attachmentFileNameList.length > 0 ?
                                            <div style={{ paddingBottom: "20px" }}>
                                                <div className="gothamNarrowFont">Included Attachments:</div>
                                                {this.state.attachmentFileNameList && this.state.attachmentFileNameList.map(file => (
                                                    <div key={`${file.name}`} className="gothamNarrowLightFont">
                                                        <span style={{ paddingRight: "10px" }}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)" }} icon={faTimes} onClick={() => this.handleAttachmentDelete(file)} /></span>{file.name}
                                                    </div>
                                                ))

                                                }
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Form>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                        </Tabs>
                    </Paper>
                    <div style={{textAlign: 'center'}}><Text c="dimmed" size="sm" style={{cursor: "default"}}>Note: Only the fields in the basic info tab are required</Text></div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.props.history.push(`/softwareAssets`)}>Cancel</Button></span>
                        <span><Button onClick={() => this.submitNewContract()}>Submit</Button></span>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                        {this.state.canSeeErrorMessage ?
                            <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields before submitting.</Text>
                            :
                            undefined
                        }
                        {this.state.canSeeDuplicateNameError ?
                            <Text c="red">Error: A contract with this name already exists. Please try a different name.</Text>
                            :
                            undefined
                        }
                    </div>
                </Container>

                <Modal
                    opened={this.state.show_customer_modal}
                    onClose={() => this.closeCustomerModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Let's Start With the Following Data</Text></div>
                        {/* THE HIDDEN ELEMENT WAS NEEDED AT THE TOP OF THE FORM TO ALLOW THE FIRST ELEMENT TO BE SEARCHABLE - WAS CAUSING ERROR OTHERWISE */}
                        <Form.Group as={Col} controlId="hiddenElementCustomerModal"><TextInput hidden></TextInput></Form.Group>
                        <Form.Group as={Col} controlId="createContractCustomer">
                            <Select 
                                label="What company does this contract belong to?"
                                placeholder="Pick a Value"
                                data={this.state.customers && this.state.customers.map((customer) => (
                                    customer.name
                                ))}
                                value={
                                    this.state.selected_customer_id !== 0 && this.state.customers && this.state.customers.length > 0 && this.state.customers.find((e) => e.id === this.state.selected_customer_id) ? 
                                        this.state.customers.find((e) => e.id === this.state.selected_customer_id).name
                                        :
                                        ""
                                }
                                onChange={(s) => {
                                    var customerId = this.state.customers.find((e) => e.name === s).id;
                                    if(customerId !== null) {
                                        this.handleCustomerChange(customerId, s);
                                    }
                                }}
                                allowDeselect={false}
                                searchable
                            />
                        </Form.Group>
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span><Button onClick={() => this.closeCustomerModal()}>Ok</Button></span>
                        </div>
                    </Form>
                </Modal>
                <Modal
                    opened={this.state.show_vendor_modal}
                    onClose={() => this.closeVendorModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create a Vendor</Text></div>
                        <Form.Group as={Col} controlId="addContractFormCreateVendorName" required>
                            <TextInput label="Vendor Name" placeholder="Enter Value Here" value={this.state.create_vendor_name_entered} onChange={(e) => this.handleCreateVendorNameChange(e.target.value)} />
                        </Form.Group>
                    </Form>
                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                        {this.state.canSeeErrorMessageCreateVendor ?
                            <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                            :
                            undefined
                        }
                        {this.state.canSeeDuplicateNameErrorCreateVendor ?
                            <Text c="red">Error: A vendor with this name already exists. Please try again.</Text>
                            :
                            undefined
                        }
                    </div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeVendorModal()}>Cancel</Button></span>
                        <span><Button onClick={() => this.submitCreateVendorForm()}>Submit</Button></span>
                    </div>
                </Modal>

                {/* This modal is for the saving screen */}
                <Modal
                    opened={this.state.showSaving}
                    onClose={() => { this.setState({ showSaving: false }) }}
                    withCloseButton={false}
                    size="xl"
                    centered
                >
                    <Modal.Body>
                        <div className="gothamNarrowLightFont" style={{ cursor: "default", textAlign: "center" }}>Saving... Please wait.</div>
                    </Modal.Body>

                </Modal>
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        softwareAssetFilter: state.softwareAssetFilter
    };
}

export default connect(mapStateToProps, {
    UPDATE_ACTIVE_LINK,
    ACCESSTOKEN,
    LOGOUT
})(CreateContract);
