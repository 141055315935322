import http from "../http-common";

class SoftwareTicketDataService {
    getAll(token, offsetValue, sortBy, sortOrder, user_id_asking) {    // get the next chunk of 10 tickets
        return http.get(`/softwareTickets?offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // User clicked a new category in filter menu, but had no search text dialog
    filterByCategory(categoryID, offsetValue, sortBy, sortOrder, token, user_id_asking) {
        return http.get(`/softwareTickets?categoryID=${categoryID}&offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // User clicked a new category with search text or entered search text while on category != All Categories
    filterByCategoryAndSearchText(categoryName, offsetValue, searchText, sortBy, sortOrder, token, user_id_asking) {
        return http.get(`/softwareTickets?categoryName=${categoryName}&searchText=${searchText}&offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Add a new software ticket to the database
    createSoftwareTicket(data, token) {
        return http.post("/softwareTickets", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Get specific ticket (also increments view count)
    get(id, token) {
        return http.get(`/softwareTickets/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Get all comments associated with a ticket
    getComments(id, token) {
        return http.get(`/softwareTickets/${id}/comments`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get like or dislike or none based on ticket
    getLikesDislikes(id, uid, token){
        return http.get(`/softwareTickets/${id}/likesdislikes?uid=${uid}`, {headers: {Authorization: 'Bearer ' + token}}); 
    }

    // get Software ticket email status based on ticket and user 
    getBoolIndividualSoftwareTicketEmails(id, uid, globalSoftwareEmailStatus, token) {
        return http.get(`/softwareTickets/${id}/getIndividualSoftwareEmailStatus?uid=${uid}&globalSoftwareEmailStatus=${globalSoftwareEmailStatus}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // changes  disabling or enabling of emails based on user id and software ticket
    changeIndividualSoftwareTicketEmailStatus(id, uid, data, token) {
        return http.put(`/softwareTickets/${id}/updateIndividualSoftwareTicketEmailStatus?uid=${uid}`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // add a like to ticket
    addLike(id, uid, token) {
        return http.get(`/softwareTickets/${id}/like?uid=${uid}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // add a dislike to ticket
    addDislike(id, uid, token) {
        return http.get(`/softwareTickets/${id}/dislike?uid=${uid}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    createSoftwareTicketComment(data, token) {
        return http.post("/softwareTickets/comments", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    addSolution(id, data, token) {
        return http.put(`/softwareTickets/${id}/addSolution`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Data not used here, but expected by put operation. All values updating are hardcoded to null
    removeSolution(id, data, token) {
        return http.put(`/softwareTickets/${id}/removeSolution`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Get data to populate the create new software ticket form
    // (user's assigned software assets, ticket categories, name/id of site)
    getNewTicketFormData(id, token) {
        return http.get(`/softwareTickets/${id}/newTicketFormData`, {headers: {Authorization: 'Bearer ' + token}});
    }

    editSoftwareTicket(id, data, token) {
        return http.put(`/softwareTickets/${id}/editSoftwareTicket`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // // update(id, data, token) {
    // //     return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    // // }

    // // delete(id, token) {
    // //     return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // // }

    // User typed in search criteria but had All Categories selected

    findByText(searchText, offsetValue, sortBy, sortOrder, token, user_id_asking) {
        return http.get(`/softwareTickets?searchText=${searchText}&offset=${offsetValue}&sortBy=${sortBy}&sortOrder=${sortOrder}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // User typed in content into the ticket title form input. Note: "all" was required in the the endpoint to distinguish from /softwareTickets/:id on backend
    findRelatedTickets(titleText, token, user_id_asking) {
        return http.get(`/softwareTickets/all/relatedTickets?titleText=${titleText}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // sends designated ticket id and new assigned tech id to update the backend record of that ticket
    updateTechAssignment(id, data, token) {
        return http.put(`/softwareTickets/${id}/updateTechAssignment`, data, {headers: {Authorization: 'Bearer ' + token}});
    }
}


export default new SoftwareTicketDataService();