import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { ACCESSTOKEN, USER_DETAILS, SET_TIMEZONE_ADJUSTMENT_FACTOR, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { UPDATE_PERMISSIONS } from '../features/actions/User-Permissions-Actions';

import { RESET_FILTERS } from '../features/actions/My-Assigned-Tickets-Actions';

import MyTicketsTable from './My-Tickets-Table';
import ChartWidget from './Chart-Widget';
import AdminDashboard from './Admin-Dashboard/AdminDashboard';
import Navbar from '../components/Navbar';

import UserDataService from '../services/user-activities';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import Card from 'react-bootstrap/Card';
//import CardDeck from 'react-bootstrap/CardDeck';
import { Link } from 'react-router-dom';

import ListGroup from 'react-bootstrap/ListGroup';

import { getUserPermissions } from '../getUserPermissions';



class UserHome extends Component {

    constructor(props) {
        super(props);

        this.determineTimezoneOffset = this.determineTimezoneOffset.bind(this);

        this.state = {
            userExists: "",
            userDetails: {},
            errorMessage: "",        // Will display any errors returned by the API call
            role_id: null,
            ready_to_load: false,    // determines if the chosen dashboard is ready to view
        };
    }

    determineTimezoneOffset() {
        return new Promise((resolve, reject) => {

            const date = new Date();
            const offset = date.getTimezoneOffset();

            let hourOffset = offset / 60;

            let offsetString = "";

            if (hourOffset > 0) {        // if offset is greater than 0, it means the timezone is BEHIND UTC, so it will be a - adjustment
                offsetString += "%2D";  // the URL encoding of "-"

                if (hourOffset < 10) {   // if less than 10, include a leading 0 in the hour part of the string
                    offsetString += "0";
                    offsetString += hourOffset.toString();
                    offsetString += ":00";
                }

                else {  // greater than 10, so no need to include leading 0
                    offsetString += hourOffset.toString();
                    offsetString += ":00";
                }
            }

            else {  // if offset is less than 0, it means the timezone is AHEAD of UTC, so it will be a + adjustment
                offsetString += "%2B";      // the URL encoding of "+"

                if (hourOffset > -10) {   // if less than 10, include a leading 0 in the hour part of the string
                    offsetString += "0";
                    offsetString += Math.abs(hourOffset).toString();
                    offsetString += ":00";
                }

                else {  // greater than 10, so no need to include leading 0
                    offsetString += Math.abs(hourOffset).toString();
                    offsetString += ":00";
                }
            }

            //console.log(offsetString);

            this.props.SET_TIMEZONE_ADJUSTMENT_FACTOR(offsetString);

            resolve();

            // HardwareAssetDataService.getHistory(id, token)
            // .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
            //     resolve(response.data);
            // })
            // .catch(e => {
            //     console.log(e);
            //     reject(e);
            // });
        });
    }


    // When component mounts, ask the backend who this user is based on their oid from token
    // If not found, prompt user to create / verify user details

    componentDidMount() {
        console.log("You're in did mount!");
        console.log("Value of logged in = ", this.props.loggedStatus.loggedIn);
        console.log("Value of username = ", this.props.loggedStatus.username);
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    // Send token to check user status here
                    UserDataService.checkStatus(token)
                        .then(response => {
                            //console.log("You just checked the status of user via the component did mount fx: ", response);
                            console.log("You're in check status");
                            console.log(response);

                            // check if this is a fresh login or the person is different than was last logged in (only factors in if user logged out incorrectly)
                            if (this.props.loggedStatus.email === '' || this.props.loggedStatus.email !== response.data.userDetails.email) {
                                // if this is a fresh login, or a brand new user, reset the filter defaults
                                // otherwise it's the same person who was here before, so no need to reset the filters
                                console.log("You'd reset filters here!");
                                this.props.RESET_FILTERS();
                            }

                            this.props.USER_DETAILS({
                                id: response.data.userDetails.id,
                                fullName: response.data.userDetails.first_name + " " + response.data.userDetails.last_name,
                                email: response.data.userDetails.email,
                                getEmails: response.data.userDetails.get_Emails,

                                getEmailsSoftware: response.data.userDetails.get_Software_Emails,

                                role_id: response.data.userDetails.role_id,  // set role globally
                                primary_tenant_id: response.data.userDetails.primary_tenant_id

                            });

                            this.setState({
                                userExists: response.data.userExists,
                                userDetails: response.data.userDetails,
                                role_id: response.data.userDetails.role_id,  // set role for this page
                                //ready_to_load: true
                            },
                                function () { }
                            );

                            getUserPermissions(this.props.loggedStatus.id, this.props.loggedStatus.accessToken).then(response => {
                                //console.log("Here's the response as seen by front end");
                                //console.log(response.data.results);
                                if (response.data.results) {
                                    this.props.UPDATE_PERMISSIONS({ // save the permissions to global state
                                        can_see_assets: response.data.results.can_see_assets,
                                        can_see_tickets: response.data.results.can_see_tickets,
                                        can_add_assets: response.data.results.can_add_assets,
                                        can_edit_assets: response.data.results.can_edit_assets,
                                        can_edit_tickets: response.data.results.can_edit_tickets,
                                        can_add_users: response.data.results.can_add_users,
                                        can_edit_users: response.data.results.can_edit_users,
                                        can_add_solutions: response.data.results.can_add_solutions,
                                        can_edit_solutions: response.data.results.can_edit_solutions,
                                        can_edit_priority: response.data.results.can_edit_priority,
                                        can_delete_assets: response.data.results.can_delete_assets,
                                        can_delete_tickets: response.data.results.can_delete_tickets,
                                        can_delete_users: response.data.results.can_delete_users,
                                        can_delete_comments: response.data.results.can_delete_comments,
                                        can_see_users: response.data.results.can_see_users,
                                        can_see_admin_portal: response.data.results.can_see_admin_portal,
                                        can_process_tickets: response.data.results.can_process_tickets,
                                        can_manage_assignments: response.data.results.can_manage_assignments,
                                        can_see_attachments: response.data.results.can_see_attachments
                                    });
                                }

                                this.determineTimezoneOffset().then(response => {
                                    console.log("Value of timezone adjustment factor in global state is: ");
                                    console.log(this.props.loggedStatus.timezone_adjustment_factor);

                                    this.setState({
                                        ready_to_load: true
                                    }, function () { })

                                })
                                    .catch(e => {

                                    });


                            })
                                .catch(e => {
                                    console.log(e);
                                });

                            // this.props.USER_DETAILS({
                            //     id: response.data.userDetails.id, 
                            //     fullName: response.data.userDetails.first_name + " " + response.data.userDetails.last_name,
                            //     email: response.data.userDetails.email
                            // });


                        })
                        .catch(e => {
                            console.log(e);
                        });
                }

            });

            this.props.UPDATE_ACTIVE_LINK("userHome");  // Tell global state that this is the active page now (controls nav bar highlighting)
        }
    }

    registerNewUser = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let formDataObj = Object.fromEntries(formData.entries());
        UserDataService.registerNewUser(formDataObj, this.props.loggedStatus.accessToken)
            .then(response => {
                // New user is registered, now fetch their data to re-render the page
                window.location.reload();
                // UserDataService.checkStatus(this.props.loggedStatus.accessToken)
                //     .then(response => {
                //         //console.log("You just checked the user status from the register new user function: ", response);
                //         this.props.USER_DETAILS({
                //             id: response.data.userDetails.id, 
                //             fullName: response.data.userDetails.first_name + " " + response.data.userDetails.last_name,
                //             email: response.data.userDetails.email,
                //             getEmails: response.data.userDetails.get_Emails,

                //             getEmailsSoftware: response.data.userDetails.get_Software_Emails,

                //             role_id: response.data.userDetails.role_id  // set role globally

                //         });

                //         this.setState({
                //             userExists: response.data.userExists,
                //             userDetails: response.data.userDetails,
                //             role_id: response.data.userDetails.role_id,  // set role for this page
                //             ready_to_load: true
                //         },
                //         function () {}
                //         );



                //         // this.props.USER_DETAILS({
                //         //     id: response.data.userDetails.id, 
                //         //     fullName: response.data.userDetails.first_name + " " + response.data.userDetails.last_name,
                //         //     email: response.data.userDetails.email
                //         // });
                //     })
                //     .catch(e => {
                //         console.log(e);
                //     });
            })
            .catch(e => {   // Error from trying to register new user
                console.log(e);
            })
    }

    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }


        if (!this.state.userExists && this.state.ready_to_load) {    // This is user's first time using the system so get more details
            return (
                <>
                    <Navbar pageTitle={"User Home"} />
                    <div className="container mt-3" style={{ paddingTop: "75px" }}>
                        <Container fluid>
                            <Row>
                                <Col>
                                    <h1 style={{ textAlign: "center" }} className="gothamNarrowFont">Welcome to Cumulus Desk</h1>
                                </Col>
                            </Row>
                            <div style={{ marginTop: "45px" }}>
                                <Row>
                                    <Col>
                                        <h3 style={{ textAlign: "center" }} className="gothamNarrowFont">We notice this is your first time logging in.</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h3 style={{ textAlign: "center" }} className="gothamNarrowFont">Please take a moment to confirm some of your details.</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <h5 style={{ textAlign: "center" }} className="gothamNarrowFont">(You'll be able to update further details later.)</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div style={{ marginTop: "50px" }}>
                                            <Form onSubmit={this.registerNewUser}>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridFirstName">
                                                        <Form.Label className="gothamNarrowLightFont">First Name</Form.Label>
                                                        <Form.Control name="firstName" className="gothamNarrowLightFont" defaultValue={this.state.userDetails.suggestedFirstName} />
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="formGridLastName">
                                                        <Form.Label className="gothamNarrowLightFont">Last Name</Form.Label>
                                                        <Form.Control name="lastName" className="gothamNarrowLightFont" defaultValue={this.state.userDetails.suggestedLastName} />
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="formGridEmail">
                                                        <Form.Label className="gothamNarrowLightFont">Email Address</Form.Label>
                                                        <Form.Control name="email" type="email" className="gothamNarrowLightFont" defaultValue={this.state.userDetails.suggestedEmail} />
                                                        <Form.Text className="text-muted gothamNarrowLightFont">
                                                            Note: This email address will be used to send and receive tickets in the system.
                                                        </Form.Text>
                                                    </Form.Group>
                                                </Form.Row>
                                                <div style={{ textAlign: "center" }}>
                                                    <Button variant="primary" type="submit" className="gothamNarrowLightFont">
                                                        Submit
                                                    </Button>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {this.state.errorMessage && <p>{this.state.errorMessage}</p>}
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                    </div>
                </>
            );
        }

        // if user is an admin (role_id = 1), show the admin dashboard instead of user dashboard
        else if (/*this.state.role_id !== null && this.state.role_id === 1*/ this.props.userPermissions.can_see_admin_portal && this.state.ready_to_load) {
            //console.log("Admin found");
            return (
                <AdminDashboard onAccessTokenRequest={this.props.onAccessTokenRequest}/>
            );
        }

        // user either hasn't been assigned a role yet, or they are something other than admin
        else if (this.state.ready_to_load) {
            return (
                <>
                    <Navbar pageTitle={"User Home"} />
                    <div className="container mt-3" style={{ paddingTop: "75px" }}></div>

                    {/* <div style={{paddingLeft: "30px", display: "inline-block"}} className="smallCumulusLogoContainer">
                        <img src="Cumulus_Logo.png" alt="server room" style={{width: "10%"}}/>
                    </div> */}
                    {/* <span className="smallCumulusLogoContainer"><img src="Cumulus_Logo.png" alt="server room" style={{width: "10%", float: "right"}}/></span> */}
                    <Container fluid>
                        <Row>
                            <Col>
                                <h2 className="gothamNarrowFont" style={{ textAlign: "center", width: "60%", marginLeft: "auto", marginRight: "auto", color: "#808080" }}>My Dashboard</h2>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            {/* <Col md={{span: 4, offset: 2}}> */}
                            <Col md={{ span: 8, offset: 4 }}>
                                <Card bg="dark" style={{ border: "none", width: "50%" }} className="wholeCard">
                                    <Card.Header style={{ color: "white", textAlign: "center" }} className="gothamNarrowFont cardHeader">Popular Activities</Card.Header>
                                    <ListGroup variant="flush" style={{ textAlign: "center" }}>
                                        {/* <Link to="" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Update My Account Information</ListGroup.Item></Link> */}
                                        {/* <Link to="/hardwareTickets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Search the Knowledgebase</ListGroup.Item></Link> */}
                                        {/* <Link to="/softwareTickets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Search the Software Knowledgebase</ListGroup.Item></Link> */}
                                        <Link to="/createHardwareTicket" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Ask a Question</ListGroup.Item></Link>
                                        {/* <Link to="/createSoftwareTicket" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Ask a Software Question</ListGroup.Item></Link> */}
                                    </ListGroup>
                                </Card>
                            </Col>
                        </Row>
                        {/* <Row className="mt-4">
                            <Col md={{span: 8, offset: 2}}>
                                <ChartWidget/>
                            </Col>
                        </Row> */}
                        <Row className="mt-4">
                            <Col md={{ span: 8, offset: 2 }}>
                                <MyTicketsTable />
                            </Col>
                        </Row>
                    </Container>
                </>
            );
        }

        else {      // page is empty until chosen dashboard loads
            return (
                <>
                    <div className="container mt-3" style={{ paddingTop: "75px" }}></div>
                </>
            );
        }


    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, USER_DETAILS, SET_TIMEZONE_ADJUSTMENT_FACTOR, UPDATE_ACTIVE_LINK, UPDATE_PERMISSIONS, RESET_FILTERS, LOGOUT })(UserHome);