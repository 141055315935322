import React, { useState, /* useCallback, */ useEffect } from 'react';
//import Select from 'react-select';

import AsyncSelect from 'react-select/async';

import HardwareTicketDataService from '../../services/hardware-ticket-activities';

import { /* connect, */ useSelector } from 'react-redux';

import { store } from '../../app/store';
import {
  Select, Space, Modal
} from '@mantine/core';

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useHistory } from "react-router";

import { Link } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

//import { useNavigate } from 'react-router-dom';

let options = [
  /* { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' }, */
];

/* const filterColors = (inputValue) => {
    return options.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
}; */

const promiseOptions = (e/* inputValue */, askerId, accessToken) =>
  new Promise((resolve, reject) => {
    console.log("Hello from promise options!");
    console.log(e);
    // let state = store.getState();
    // const accessToken = useSelector((state) => state.logged.accessToken);
    // const askerId = useSelector((state) => state.logged.id);
    console.log("ASKER ID = ", askerId);

    HardwareTicketDataService.getAssignableParentTickets(askerId, e, accessToken)
      .then(results => {
        console.log(results);

        let newOptions = []
        for (let i = 0; i < results.data.results.length; i++) {
          newOptions.push({ value: results.data.results[i].id.toString(), label: `${results.data.results[i].ticket_title} - (#${results.data.results[i].id})` })
        }

        options = newOptions

        resolve(newOptions);
      }
      )
      .catch(e => {
        console.log(e);
        reject(e);
      });
    /* setTimeout(() => {
      resolve(filterColors(e));
    }, 1000); */
  });

export default function ParentTicketSelector(props) {

  let state = store.getState();
  const accessToken = useSelector((state) => state.logged.accessToken);
  const askerId = useSelector((state) => state.logged.id);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedLabel, setSelectedLabel] = useState(null);
  const [data, setData] = useState([]);

  const [showRemoveParentFromChildWarning, setShowRemoveParentFromChildWarning] = useState(0);  // controls when the remove parent from child confirmation modal appears

  const history = useHistory();
  //const navigate = useNavigate();
  //const [options, setOptions] = useState([]);

  useEffect(() => {
    console.log("Parent selector received ticket type = ", props.ticket_type);
    console.log("Parent selector received id number = ", props.ticket_id);
    console.log("Parent ID at time of load is = ", props.parent_id);
    getOptions();

    if (props.parent_id === null) {
      setSelectedLabel("None");
    }

    else {
      for (let i = 0; i < options.length; i++) {
        if (options[i].value == props.parent_id) {
          setSelectedLabel(options[i].label);
          setSelectedOption(options[i].value);

          break;
        }
      }
    }

  }, [props.ticket_id, props.ticket_type, props.parent_id, props.initiator_id, props.timezone_adjustment_factor]);

  const getOptions = () => {
    HardwareTicketDataService.getAssignableParentTickets(askerId, "", accessToken)
      .then(results => {
        console.log(results);

        let newOptions = []
        for (let i = 0; i < results.data.results.length; i++) {
          newOptions.push({ value: results.data.results[i].id.toString(), label: `${results.data.results[i].ticket_title} - (#${results.data.results[i].id})` })
        }

        setData(newOptions);
        options = newOptions
      }
      )
      .catch(e => {
        console.log(e);
      });
  };


  /* useEffect(() => {
    if(props.trigger) {
      console.log("You are in the trigger function of child component!");
      promiseOptions("", askerId, accessToken);
    }
  }, [props.trigger]); */

  // useEffect(() => {
  //   //setSelectedOption(selectedOption);
  //   console.log("SELECTED OPTION = ", selectedOption);
  //   // for(let i = 0; i < options.length; i++) {
  //   //   if(selectedOption && options[i].value === selectedOption.value) {
  //   //     setSelectedLabel(options[i].label);
  //   //     break;
  //   //   }
  //   // }
  //   console.log("SELECTED Label = ", selectedLabel);
  // }, [selectedOption, selectedLabel, options]);

  const updateSelection = (e) => {
    console.log(e);
    setSelectedOption(e);
    const label = data.find((s) => s.value === e).label;
    setSelectedLabel(label);

    // update the record on the backend
    HardwareTicketDataService.changeAssignedParent(props.ticket_id, { parent_id: e, ticket_type: props.ticket_type, initiatorId: props.initiator_id }, accessToken)
      .then(response => {
        console.log("Updated parent record");
        // get history list here
        if (props.ticket_type === "Hardware") {
          props.getHistory(props.ticket_id, props.timezone_adjustment_factor, accessToken);
        }

        else {
          props.getUnclarifiedHistory(props.ticket_id, props.timezone_adjustment_factor, accessToken);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  // this fxn gets called when user clicks the red x / delete parent button
  // it sends a 0 for parent_id to the API which knows that means to set the column back to null
  const removeParentAssignment = () => {

    hideRemoveParentFromChildWarning();

    setSelectedOption(null);
    setSelectedLabel("None");

    HardwareTicketDataService.changeAssignedParent(props.ticket_id, { parent_id: 0, ticket_type: props.ticket_type, initiatorId: props.initiator_id }, accessToken)
      .then(response => {
        console.log("Parent removed");

        // get history list here
        if (props.ticket_type === "Hardware") {
          props.getHistory(props.ticket_id, props.timezone_adjustment_factor, accessToken);
        }

        else {
          props.getUnclarifiedHistory(props.ticket_id, props.timezone_adjustment_factor, accessToken);
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  const navigateToParent = () => {
    console.log("You'd navigate to parent here");

    history.push(`/hardwareTickets/${selectedOption}`);

    //navigate(`/hardwareTickets/${selectedOption}`);
    // history.push({
    //   pathname: `/hardwareTickets/${selectedOption}`
    // });
  }

  const showRemoveParentFromChildMessage = () => {
    setShowRemoveParentFromChildWarning(1);
  }

  const hideRemoveParentFromChildWarning = () => {
    setShowRemoveParentFromChildWarning(0);
  }

  // const fetchTickets = (e/* inputValue *//* , askerId, accessToken */) => {
  //   new Promise((resolve, reject) => {
  //     console.log("Hello from promise options!");
  //     console.log(e);
  //     // let state = store.getState();
  //     // const accessToken = useSelector((state) => state.logged.accessToken);
  //     // const askerId = useSelector((state) => state.logged.id);
  //     console.log("ASKER ID = ", askerId);

  //     HardwareTicketDataService.getAssignableParentTickets(askerId, e, accessToken)
  //     .then(results => {
  //         console.log(results);
  //         //setOptions(results.data.results);
  //         //console.log("OPTIONS ARRAY: ", options);
  //         let newOptions = []
  //         for(let i = 0; i < results.data.results.length; i++) {
  //           newOptions.push({value: results.data.results[i].id.toString(), label: results.data.results[i].ticket_title})
  //         }
  //         console.log("NEW OPTIONS: ", newOptions);
  //         setOptions(newOptions);
  //         // setTimeout(() => {
  //         //   resolve();
  //         // }, 1000);
  //         //setOptions(results.data.results);
  //         resolve();

  //     }
  //     )
  //     .catch(e => {
  //         console.log(e);
  //         reject(e);
  //     });
  //     /* setTimeout(() => {
  //       resolve(filterColors(inputValue));
  //     }, 1000); */
  //   });
  // }

  console.log(options);

  return (
    <div style={{ textAlign: "left" }}>
      {/* <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={options}
      /> */}
      <div>
        {selectedLabel !== "None" ?
          <span>{`Currently Selected: `} <Link to={`/hardwareTickets/${selectedOption}`}>{`${selectedLabel}`}</Link></span>
          :
          <span>{`Currently Selected: ${selectedLabel}`}</span>
        }

        {selectedLabel !== "None" &&
          <span style={{ marginLeft: "10px" }}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)" }} icon={faTimes} onClick={() => showRemoveParentFromChildMessage()} /></span>}
      </div>
      <Select
        placeholder="Select parent"
        data={data}
        onChange={(s) => updateSelection(s)}
        value={selectedOption}
        display="inline-block"
        w="200px"
        allowDeselect={false}
      />
      {/* This modal is for the remove parent assignment on child warning */}
      <Modal
        opened={showRemoveParentFromChildWarning}
        onClose={() => { hideRemoveParentFromChildWarning()/* this.setState({ showRemoveParentFromChildWarning: false }) */ }}
        size="xl"
        centered
        title="Are you sure you want to remove this ticket as a child?"
      >
        <Modal.Header>
          <Modal.Title className="gothamNarrowFont">Are you sure you want to remove this ticket as a child?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            <p className="gothamNarrowLightFont">Note: Removing a child ticket from a parent will prevent this ticket from receiving messages and closing with the ticket group.</p>
          </span>
        </Modal.Body>
        <div>
          <Button variant="secondary" style={{ marginRight: "15px" }} onClick={() => hideRemoveParentFromChildWarning()} className="gothamNarrowFont">Cancel</Button>
          <Button variant="primary" onClick={() => removeParentAssignment()} className="gothamNarrowFont">Ok</Button>
        </div>
      </Modal>
    </div>
  );
}

