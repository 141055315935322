import http from "../http-common";

class TicketCategoryDataService {
    getAll(type, token) {
        return http.get(`/ticketCategories?type=${type}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // create(data, token) {
    //     return http.post("/accounts", data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // get(id, token) {
    //     return http.get(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // update(id, data, token) {
    //     return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // delete(id, token) {
    //     return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // findByText(searchText, token) {
    //     return http.get(`/hardwareTickets?searchText=${searchText}`, {headers: {Authorization: 'Bearer ' + token}});
    // }
}


export default new TicketCategoryDataService();