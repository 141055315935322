import React, { useEffect, useState } from 'react';
import {
    Paper,
    Group,
    Progress,
    Box,
    SimpleGrid,
    RingProgress,
    Center,
    Text
} from '@mantine/core';
import DashboardDataService from '../../../../services/dashboard-activities';
import { BarChart, AreaChart } from '@mantine/charts';
const itemHeight = 120;

export default function TicketsByMonth(props) {
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.loggedStatus.loggedIn) {
            props.onAccessTokenRequest(props.loggedStatus.username).then((token) => {
                if (token !== undefined && token !== null && token !== "") {
                    var currentYear = new Date().getFullYear();
                    var currentMonth = new Date().getMonth();
                    //console.log("Current month is: ", currentMonth);
                    DashboardDataService.getTicketsIndividualMonth(token, props.loggedStatus.id, 0, currentYear, currentMonth, props.loggedStatus.timezone_adjustment_factor).then(response => {
                        for (var i = 0; i < response.data.results.length; i++) {
                            switch (response.data.results[i].month) {
                                case 1:
                                    response.data.results[i].month = "Jan";
                                    break;
                                case 2:
                                    response.data.results[i].month = "Feb";
                                    break;
                                case 3:
                                    response.data.results[i].month = "Mar";
                                    break;
                                case 4:
                                    response.data.results[i].month = "Apr";
                                    break;
                                case 5:
                                    response.data.results[i].month = "May";
                                    break;
                                case 6:
                                    response.data.results[i].month = "Jun";
                                    break;
                                case 7:
                                    response.data.results[i].month = "Jul";
                                    break;
                                case 8:
                                    response.data.results[i].month = "Aug";
                                    break;
                                case 9:
                                    response.data.results[i].month = "Sep";
                                    break;
                                case 10:
                                    response.data.results[i].month = "Oct";
                                    break;
                                case 11:
                                    response.data.results[i].month = "Nov";
                                    break;
                                case 12:
                                    response.data.results[i].month = "Dec";
                                    break;
                            }
                            response.data.results[i]["Tickets Created"] = response.data.results[i]["createdCount"];
                            delete response.data.results[i]["createdCount"];
                            response.data.results[i]["Tickets Solved"] = response.data.results[i]["solvedCount"];
                            delete response.data.results[i]["solvedCount"];
                        }
                        setData(response.data.results);
                    }).catch(e => {
                        console.log(e);
                    })
                }
            });
        }
    }, []);

    return (
        <Paper withBorder radius="md" h="100%" w="100%" style={{ verticalAlign: "top" }}>
            <Text p="20px">Tickets by Month <span style={{fontSize: "8pt"}}><i>{`(current month is projected)`}</i></span></Text>
            <AreaChart
                h={props.widget.size.y * itemHeight - 90}
                m={0}
                pr="20px"
                data={data}
                dataKey="month"
                series={[
                    {
                        name: 'Tickets Created',
                        color: 'blue.6',
                    },
                    {
                        name: 'Tickets Solved',
                        color: 'green.6',
                    },
                ]}
                tickLine="y"
            />
        </Paper>
    );
}
