import React, { Component } from "react";
import HardwareTicketDataService from "../services/hardware-ticket-activities";
import TicketCategoryDataService from "../services/ticket-category-activities";
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Container,
    Paper,
    Select,
    Grid,
    Flex,
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch } from '@tabler/icons-react';
import classes from './TableSort.module.css';

import Navbar from '../components/Navbar';

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { Link } from 'react-router-dom';


import "bootstrap/dist/css/bootstrap.min.css";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

function Th(props) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}


class HardwareTicketsLandingPage extends Component {
    _scroll = false;

    constructor(props) {
        super(props);
        this.retrieveTickets = this.retrieveTickets.bind(this);
        this.retrieveFilteredTickets = this.retrieveFilteredTickets.bind(this);
        this.retrieveTicketCategories = this.retrieveTicketCategories.bind(this);
        this.updateSelectedCategoryValue = this.updateSelectedCategoryValue.bind(this);
        this.searchKnowledgebaseByCategory = this.searchKnowledgebaseByCategory.bind(this);
        this.onChangeSearchText = this.onChangeSearchText.bind(this);
        this.handleColumnSort = this.handleColumnSort.bind(this);
        this.goToSpecificTicket = this.goToSpecificTicket.bind(this);

        this.setTicketTimesSinceCreated = this.setTicketTimesSinceCreated.bind(this);
        this.createTicketsToDisplay = this.createTicketsToDisplay.bind(this);

        this.resetTicketOffset = this.resetTicketOffset.bind(this);
        this.getSortedTickets = this.getSortedTickets.bind(this);


        this.state = {
            tickets: [],
            rowCount: 0,        // The TOTAL number of tickets that could be displayed based on current search params
            ticketSortOrder: "",    // will be either asc or desc (will pass directly to backend sql to be part of query for sorting)
            sortTicketsBy: "",      // will contain the name of the sql table column we plan to sort by (will pass directly to backend sql to be part of query for sorting)
            searchText: "",
            selectedFilterTicketCategory: "",
            selectedFilterTicketCategoryID: 0,
            categories: [],
            typingTimeout: 0,
            resetOffsetTimeout: 0,  // handles the delay in changing ticket offset to 0 while user is typing
            scrolled: false,
            ticketsToDisplay: [],
            ticketOffsetValue: 0,   // the value passed to database to know how many records we've already retrieved by scrolling (increments by 10 each database call)
            numberOfRecordsToShow: 10,   // Will increment by 10 each time user scrolls to bottom of page
            allTicketsShown: false,  // Controls whether to ask for more records
            currentForumColumnSorting: ""    // Controls what forum table header is highlighted. changed onclick.
            //currentTicket: null,
            //currentIndex: -1,
            //searchName: ""
        };
    }

    componentDidMount() {           // token passed into props from the App.js file


        if (this.props.loggedStatus.loggedIn) {

            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // retrieve first 10 tickets ordered by descending id (newest tickets first)
                    this.retrieveTickets(token, this.state.ticketOffsetValue, "id", "desc").then(response => {
                        console.log(response.results);

                        this.setState({
                            tickets: response.results,     // the first 10 tickets returned
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: this.state.ticketOffsetValue + 10,
                            selectedFilterTicketCategory: "All Categories",
                            ticketSortOrder: "desc",    // we start by ordering in descending order (newest tickets first)
                            sortTicketsBy: "id",    // we start by ordering by id column of table (reflects time created)
                            currentForumColumnSorting: "date"   // start with "Created" column highlighted
                        });

                        this.retrieveTicketCategories(token);
                        setTimeout(() => {

                            let ticketsToDisplayTemp = [];
                            for (let i = 0; i < this.state.numberOfRecordsToShow; i++) {
                                if (this.state.tickets && this.state.tickets.length > 0 && i <= this.state.tickets.length) { // make sure we're asking for a valid ticket
                                    ticketsToDisplayTemp.push(this.state.tickets[i]);
                                }
                            }
                            this.setState({ ticketsToDisplay: ticketsToDisplayTemp });


                        }, 1000);
                    })
                }
            });


            this.props.UPDATE_ACTIVE_LINK("hardwareTickets");

            window.addEventListener('scroll', this.handleScroll);

        }


    }

    componentWillUnmount() {

        window.removeEventListener('scroll', this.handleScroll);
    }

    setSorting(field) {
        const reversed = field === this.state.sortBy ? !this.state.reverseSortDirection : false;
        const search = this.state.search;
        this.setState({
            reverseSortDirection: reversed,
            sortBy: field,
            sortedData: sortData(this.state.data, { sortBy: field, reversed, search })
        });
    };


    setTicketTimesSinceCreated(tickets) {
        for (var item of tickets) {
            if (item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? "y ago" : "y ago");
            }

            else if (item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? "mon ago" : "mon ago");
            }

            else if (item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? "d ago" : "d ago");
            }

            else if (item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? "h ago" : "h ago");
            }

            else if (item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? "min ago" : "min ago");
            }

            else {
                item.time_since_sent = "moments ago";
            }
        }
    }

    goToSpecificTicket(id) {   // Travel to the specific ticket's information
        this.props.history.push({
            pathname: "/hardwareTickets/" + id,
            state: {
                accessToken: this.props.loggedStatus.accessToken,
                loggedIn: this.props.loggedStatus.loggedIn
            }
        })
    }

    // this function gets called within handle column sort. based on updated state values, it gets the new ticket data in the new sorted order
    getSortedTickets() {
        // Now that state is correctly set, retrieve tickets using new search criteria
        if (this.state.searchText === "") {   // User changed category filter but had no search text

            if (this.state.selectedFilterTicketCategory === "All Categories") {
                this.retrieveTickets(this.props.loggedStatus.accessToken, 0, this.state.sortTicketsBy, this.state.ticketSortOrder).then(response => { // ticket offset manual 0 since this is a chang to search criteria
                    this.setState({
                        tickets: response.results,     // the first 10 tickets returned
                        rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                        ticketOffsetValue: 10
                    });
                })
                    .catch(e => {
                        console.log(e);
                    });
            }

            else {  // Category selected was anything but All Categories and they had no search text (offset manual 0 since this is a change to search criteria)
                this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {
                    this.setState({
                        tickets: response.results,     // the first 10 tickets returned
                        rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                        ticketOffsetValue: 10
                    });
                })
                    .catch(e => {
                        console.log(e);
                    });
            }

        }

        else {
            if (this.state.selectedFilterTicketCategory === "All Categories") {  // User had search text but clicked All Categories - send 0 for offset to reset the list of tickets returned
                this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
            }

            else {  // User had search text AND picked a specific category
                this.searchKnowledgebaseByCategory(this.state.searchText, 0, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
            }
        }
    }

    // every time user clicks column header, it changes search criteria so resets ticket offset
    // flips asc to desc and v.v.
    // sets the name of the column sorted (will use the name of the ticket table column we will sort on to put directly into query)

    handleColumnSort(columnToSort) {
        if (this.state.ticketSortOrder === "desc") {     // This will put the title column in ascending order
            switch (columnToSort) {
                case "title":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "title",
                            sortTicketsBy: "ticket_title",
                            reverseSortDirection: false,
                            sortBy: "title",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "asker":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "asker",
                            sortTicketsBy: "asker_first_name",
                            reverseSortDirection: false,
                            sortBy: "askedBy",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "assigned":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "assigned",
                            sortTicketsBy: "tech_assigned_first_name",
                            reverseSortDirection: false,
                            sortBy: "assignedTo",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "views":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "views",
                            sortTicketsBy: "total_clicks_all_time",
                            reverseSortDirection: false,
                            sortBy: "views",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "date":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "date",
                            sortTicketsBy: "id",
                            reverseSortDirection: false,
                            sortBy: "created",
                        },
                        this.getSortedTickets
                    );
                    break;
                default:
                    console.log("Bad Column Name Given");
            }

        }

        else if (this.state.ticketSortOrder === "asc") { // This will put the title column in descending order
            switch (columnToSort) {
                case "title":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "title",
                            sortTicketsBy: "ticket_title",
                            reverseSortDirection: true,
                            sortBy: "title",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "asker":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "asker",
                            sortTicketsBy: "asker_first_name",
                            reverseSortDirection: true,
                            sortBy: "askedBy",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "assigned":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "assigned",
                            sortTicketsBy: "tech_assigned_first_name",
                            reverseSortDirection: true,
                            sortBy: "assignedTo",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "views":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "views",
                            sortTicketsBy: "total_clicks_all_time",
                            reverseSortDirection: true,
                            sortBy: "views",
                        },
                        this.getSortedTickets
                    );
                    break;
                case "date":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "date",
                            sortTicketsBy: "id",
                            reverseSortDirection: true,
                            sortBy: "created",
                        },
                        this.getSortedTickets
                    );
                    break;
                default:
                    console.log("Bad Column Name Given");
            }
        }

    }

    // gets called after 1.5 second timeout after user types something into the search bar
    // since search criteria has changed, user gets a new list with a starting offset value
    resetTicketOffset() {
        this.setState({
            ticketOffsetValue: 0
        });
    }

    // using arrow notation in onChangeSearchText so we can reference this.state within the internal timeout function call
    // function will execute a search after user has stopped typing for 1.5 seconds
    onChangeSearchText = (e) => {

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        if (this.state.resetOffsetTimeout) {
            clearTimeout(this.state.resetOffsetTimeout);
        }

        //const searchText = e.target.value;

        this.setState({
            searchText: e.target.value,

            resetOffsetTimeout: setTimeout(() => {
                this.resetTicketOffset();   // reset the ticket offset in state back to 0 (they have changed their search criteria so new list)
            }, 1500),
            typingTimeout: setTimeout(() => {
                if (this.state.selectedFilterTicketCategory === "All Categories") {    // user has no category selected but is searching        
                    this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);    // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                }

                else if (this.state.searchText === "") {    // User emptied search bar, but still has category selected
                    this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => { // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                        this.setState({     // save the new tickets and inform state that we asked for first 10
                            tickets: response.results,
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: 10
                        });
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

                else {  // User has both category and search bar letters
                    this.searchKnowledgebaseByCategory(this.state.searchText, 0, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken); // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                }
            }, 1500)
        });

    }

    retrieveTickets(token, ticketOffsetValue, sortBy, sortOrder) {
        return new Promise((resolve, reject) => {

            HardwareTicketDataService.getAll(token, ticketOffsetValue, sortBy, sortOrder, this.props.loggedStatus.id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // called when user has a category other than All Categories selected, but has no search text
    retrieveFilteredTickets(categoryID, ticketOffsetValue, sortTicketsBy, ticketSortOrder, token) {
        return new Promise((resolve, reject) => {

            HardwareTicketDataService.filterByCategory(categoryID, ticketOffsetValue, sortTicketsBy, ticketSortOrder, token, this.props.loggedStatus.id)
                .then(response => {
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // Saying we want hardware categories by sending in type = hardware
    retrieveTicketCategories(token) {
        TicketCategoryDataService.getAll("hardware", token)
            .then(response => {
                this.setState({
                    categories: response.data.results
                });
            })
            .catch(e => {
                console.log(e);
            });
    }


    searchKnowledgebase(searchText, ticketOffset, sortTicketsBy, ticketSortOrder, token) {
        HardwareTicketDataService.findByText(searchText, ticketOffset, sortTicketsBy, ticketSortOrder, token, this.props.loggedStatus.id)
            .then(response => {
                if (ticketOffset === 0) {    // this was the first search, so results returned are complete package and grab the table count
                    this.setState({
                        tickets: response.data.results,
                        rowCount: response.data.row_count,
                        ticketOffsetValue: 10   // we pulled the first 10 tickets after returning the response back so update value
                    });

                }

                else {      // we got here from a scroll, so add these new tickets to the end of the current ticket array (NOTE: offset update is handed by scroll fxn)
                    this.setState(prevState => ({
                        tickets: [...prevState.tickets, ...response.data.results]
                    }));
                }

            })
            .catch(e => {
                console.log(e);
            });
    }

    searchKnowledgebaseByCategory(searchText, ticketOffset, categoryName, sortTicketsBy, ticketSortOrder, token) {
        HardwareTicketDataService.filterByCategoryAndSearchText(categoryName, ticketOffset, searchText, sortTicketsBy, ticketSortOrder, token, this.props.loggedStatus.id)
            .then(response => {
                if (ticketOffset === 0) {    // this was the first search, so results returned are complete package and grab the table count
                    this.setState({
                        tickets: response.data.results,
                        rowCount: response.data.row_count,
                        ticketOffsetValue: 10   // we pulled the first 10 tickets after returning the response back so update value
                    });
                }

                else {      // we got here from a scroll, so add these new tickets to the end of the current ticket array (NOTE: offset update is handed by scroll fxn)
                    this.setState(prevState => ({
                        tickets: [...prevState.tickets, ...response.data.results]
                    }));
                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    updateSelectedCategoryValue(categoryID, newValue) { // User clicks new category filter

        // if they have picked a different category than current one selected, run this code
        if (categoryID !== this.state.selectedFilterTicketCategoryID) {
            this.setState({
                selectedFilterTicketCategory: newValue,
                selectedFilterTicketCategoryID: categoryID,
                ticketOffsetValue: 0        // reset ticket offset value since we'll have a new list
            });


            if (this.state.searchText === "") {   // User changed category filter but had no search text

                if (newValue === "All Categories") {
                    this.retrieveTickets(this.props.loggedStatus.accessToken, 0, this.state.sortTicketsBy, this.state.ticketSortOrder).then(response => { // ticket offset manual 0 since this is a chang to search criteria
                        this.setState({
                            tickets: response.results,     // the first 10 tickets returned
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: 10
                        });
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

                else {  // Category selected was anything but All Categories and they had no search text (offset manual 0 since this is a change to search criteria)
                    this.retrieveFilteredTickets(categoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {
                        this.setState({
                            tickets: response.results,     // the first 10 tickets returned
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: 10
                        });
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

            }

            else {
                if (newValue === "All Categories") {  // User had search text but clicked All Categories - send 0 for offset to reset the list of tickets returned
                    this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                }

                else {  // User had search text AND picked a specific category
                    this.searchKnowledgebaseByCategory(this.state.searchText, 0, newValue, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                }
            }
        }
    }

    createTicketsToDisplay() {
        let ticketsToDisplayTemp = [];

        for (let i = 0; i < this.state.tickets.length; i++) {
            if (this.state.tickets.length > 0 && i < this.state.tickets.length) { // make sure we're asking for a valid ticket
                ticketsToDisplayTemp.push(this.state.tickets[i]);
            }
        }


        const tableRows = ticketsToDisplayTemp.map((ticket) => {
            return (
                <Table.Tr key={ticket.id} onClick={() => {
                    this.props.history.push(`/hardwareTickets/${ticket.id}`);
                }}>
                    <Table.Td>
                        {ticket.resolution_status === "Resolved" ?
                            <div className="gothamNarrowFont" style={{color: "var(--mantine-color-blue-5)"}}>{`${ticket.ticket_title} (Ticket # ${ticket.id})`} <FontAwesomeIcon style={{ color: "green" }} icon={faCheckCircle} /></div> :
                            <div className="gothamNarrowFont" style={{color: "var(--mantine-color-blue-5)"}}>{`${ticket.ticket_title} (Ticket # ${ticket.id})`}</div>}
                        <div className="ticketCategoryName gothamNarrowFont">{ticket.ticket_category}</div>
                        {/* ticket.issue_text ?
                            <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 100 ? `${ticket.issue_text.substring(0, 100)}...(more)` : ticket.issue_text}</div>
                            :
                            <div className="ticketIssuePreview gothamNarrowLightFont">No Text Entered</div> */
                        }
                    </Table.Td>
                    <Table.Td style={{ width: "200px" }} id="ticketRequesterText" className="gothamNarrowFont"><div>{ticket.asker_first_name} {ticket.asker_last_name}</div><div>{`(${ticket.name_of_tenant})`}</div></Table.Td>
                    <Table.Td style={{ width: "150px" }} id="ticketViews" className="gothamNarrowFont">{ticket.total_clicks_all_time}</Table.Td>
                    <Table.Td style={{ width: "200px", fontSize: '13px' }} id="techAssignedText" className="gothamNarrowFont"><div>{ticket.tech_assigned_first_name} {ticket.tech_assigned_last_name}</div><div>{`(${ticket.name_of_tenant})`}</div></Table.Td>
                    <Table.Td style={{ width: "150px" }} id="ticketDateText" className="gothamNarrowFont">{ticket.time_since_sent}</Table.Td>
                </Table.Tr>

            )
        })

        return tableRows;

    }

    handleScroll = e => {     // Fetch the next results if you reach the bottom of the page

        setTimeout(() => {
            if (!this._scroll) {
                this._scroll = true

                if ((window.innerHeight + window.pageYOffset) >= document.body.scrollHeight) {  // User at bottom, load more items
                    this._isMounted = true;

                    if (this.state.ticketOffsetValue >= this.state.rowCount) {    // The number we've tried to return already exceeds the max
                        this.setState({ allTicketsShown: true });     // Don't need to show more
                    }

                    // if we previously had all tickets shown and we reset the search criteria, we need to let system know we no longer have all tickets shown
                    else if (this.state.ticketOffsetValue < this.state.rowCount && this.state.allTicketsShown) {
                        this.setState({ allTicketsShown: false });
                    }

                    if (!this.state.allTicketsShown) {   // Only ask for more records if we're not at the end of the list yet

                        if (this.state.selectedFilterTicketCategory === "All Categories") {    // user has no category selected but is searching 

                            // user scrolled to bottom of page with no special category and no special search (ie: standard retrieval)
                            if (this.state.searchText === "") {  // they had no search text and all categories selected so just do a standard ticket retrieval

                                this.retrieveTickets(this.props.loggedStatus.accessToken, this.state.ticketOffsetValue, this.state.sortTicketsBy, this.state.ticketSortOrder).then(response => {

                                    this.setState(prevState => ({
                                        tickets: [...prevState.tickets, ...response.results]
                                    }));
                                });
                            }

                            // user scrolled to bottom of page with no special category, but did have search text
                            else {
                                this.searchKnowledgebase(this.state.searchText, this.state.ticketOffsetValue, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                            }
                        }

                        else if (this.state.searchText === "") {    // User emptied search bar, but still has category selected (as long as category id is not 0 - all categories)



                            this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, this.state.ticketOffsetValue, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {
                                // add new tickets to end of current array and then update the value of offset
                                this.setState(prevState => ({
                                    tickets: [...prevState.tickets, ...response.results]
                                }));

                                this.setState({
                                    ticketOffsetValue: this.state.ticketOffsetValue + 10
                                });
                            })
                                .catch(e => {
                                    console.log(e);
                                });
                        }

                        else {  // User has both category and search bar letters
                            this.searchKnowledgebaseByCategory(this.state.searchText, this.state.ticketOffsetValue, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                        }

                        this.setState({ // we grabbed 10 more tickets so go ahead and increment this value
                            ticketOffsetValue: this.state.ticketOffsetValue + 10
                        });
                    }
                }

                setTimeout(() => {
                    this._scroll = false;
                }, 500);
            }

        }, 500);

    }


    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }

        const { tickets, searchText, categories } = this.state;

        // Figure out time to display for all the returned tickets
        if (tickets) {
            this.setTicketTimesSinceCreated(tickets);
        }

        /*const rows = this.state.tickets.map((ticket) => (
            <Table.Tr key={`${ticket.ticket_type}-${ticket.id}`}>
                <Table.Td>{this.props.myAssignedTickets.type_name === "Hardware" &&
                    <div className="gothamNarrowFont"><Link to={`/hardwareTickets/${ticket.id}`}>{ticket.ticket_title.length > 34 ? `${ticket.ticket_title.substring(0, 34)}...` : ticket.ticket_title}</Link></div>
                }
                    {this.props.myAssignedTickets.type_name === "Software" &&
                        <div className="gothamNarrowFont"><Link to={`/softwareTickets/${ticket.id}`}>{ticket.ticket_title.length > 34 ? `${ticket.ticket_title.substring(0, 34)}...` : ticket.ticket_title}</Link></div>
                    }
                    {this.props.myAssignedTickets.type_name === "Unclarified" &&
                        <div className="gothamNarrowFont"><Link to={`/clarifyTicket/${ticket.id}`}>{ticket.ticket_title.length > 34 ? `${ticket.ticket_title.substring(0, 34)}...` : ticket.ticket_title}</Link></div>
                    }
                    {this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Hardware" &&
                        <div className="gothamNarrowFont"><Link to={`/hardwareTickets/${ticket.id}`}>{ticket.ticket_title.length > 34 ? `${ticket.ticket_title.substring(0, 34)}...` : ticket.ticket_title}</Link></div>
                    }
                    {this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Unclarified" &&
                        <div className="gothamNarrowFont"><Link to={`/clarifyTicket/${ticket.id}`}>{ticket.ticket_title.length > 34 ? `${ticket.ticket_title.substring(0, 34)}...` : ticket.ticket_title}</Link></div>
                    }
                    {this.props.myAssignedTickets.type_name === "Unclarified" || (this.props.myAssignedTickets.type_name === "All" && ticket.ticket_type === "Unclarified") ?
                        <div className="gothamNarrowLightFont" style={{ cursor: "default" }}>{`Temporary # ${ticket.id}`}</div>
                        :
                        <div className="gothamNarrowLightFont" style={{ cursor: "default" }}>{`Ticket # ${ticket.id}`}</div>
                    }
                    {ticket.ticket_category && this.props.myAssignedTickets.type_name !== "Unclarified" ?
                        <div className="gothamNarrowLightFont" style={{ cursor: "default" }}>{ticket.ticket_category.length > 34 ? `${ticket.ticket_category.substring(0, 34)}...` : ticket.ticket_category}</div>
                        :
                        <div className="gothamNarrowLightFont" style={{ cursor: "default" }}>Unprocessed</div>
                    }
                    {ticket.assigned_tech_name !== null ?
                        <div className="gothamNarrowLightFont" style={{ cursor: "default" }}>{ticket.assigned_tech_name.length > 34 ? `${ticket.assigned_tech_name.substring(0, 34)}...` : ticket.assigned_tech_name}</div>
                        :
                        <div className="gothamNarrowLightFont" style={{ cursor: "default", color: "red" }}>Unassigned</div>
                    }</Table.Td>
                <Table.Td>{ticket.resolution_status}</Table.Td>
                {ticket.sla_name ?
                    <Table.Td style={{ cursor: "default" }}>{ticket.sla_name}</Table.Td>
                    :
                    <Table.Td style={{ cursor: "default" }}>Unset</Table.Td>
                }
                {this.props.myAssignedTickets.type_name !== "All" ?
                    <Table.Td style={{ cursor: "default" }}>{this.props.myAssignedTickets.type_name === "Hardware" ? "Tech Support" : "Unprocessed"}</Table.Td>
                    :
                    ticket.ticket_type === "Hardware" ?
                        <Table.Td style={{ cursor: "default" }}>Tech Support</Table.Td>
                        :
                        <Table.Td style={{ cursor: "default" }}>Unprocessed</Table.Td>
                }
                {ticket.asker_name === "Unregistered Unregistered" ?
                    <Table.Td style={{ cursor: "default" }}><div>Unregistered</div><div>{`(${ticket.name_of_tenant})`}</div></Table.Td> :
                    <Table.Td style={{ cursor: "default" }}>{ticket.asker_name.length > 25 ? <div><div>{`${ticket.asker_name.substring(0, 25)}...`}</div><div>{`(${ticket.name_of_tenant})`}</div></div> : <div><div>{ticket.asker_name}</div><div>{`(${ticket.name_of_tenant})`}</div></div>}</Table.Td>
                }
                {ticket.color ?
                    <Table.Td style={{ cursor: "default", color: `${ticket.color}` }}>{ticket.time_since_sent}</Table.Td>
                    :
                    <Table.Td style={{ cursor: "default" }}>{ticket.time_since_sent}</Table.Td>
                }
            </Table.Tr>
        ))*/


        return (
            <>
                <Navbar pageTitle="Knowledgebase" />
                <div style={{ height: "15px" }} />
                <Container fluid >
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                            h="50px"
                        >
                            <Button
                                h="36px"
                                onClick={() => this.props.history.push("/createHardwareTicket")}
                            >
                                + Create New Ticket
                            </Button>
                            <Select
                                placeholder="Pick value"
                                data={[{ label: "All Categories", value: "0" }].concat(categories.map((category) => ({ label: category.name_of_category, value: category.id.toString() })))}
                                display="inline-block"
                                defaultValue="0"
                                onChange={(id) => {
                                    if (id == 0) {
                                        this.updateSelectedCategoryValue(0, "All Categories");
                                    } else {
                                        var category = categories.find((e) => e.id == id);
                                        this.updateSelectedCategoryValue(category.id, category.name_of_category);
                                    }
                                }}
                                w="200px"
                                allowDeselect={false}
                            />
                        </Flex>
                        <TextInput
                            placeholder="Search"
                            mb="md"
                            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            value={this.state.searchText}
                            onChange={this.onChangeSearchText}
                        />
                        <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Th
                                        sorted={this.state.sortBy === 'title'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort("title")}
                                    >
                                        Title / Category
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'askedBy'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('asker')}
                                    >
                                        Asked By
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'views'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('views')}
                                    >
                                        Views
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'assignedTo'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('assigned')}
                                    >
                                        Assigned To
                                    </Th>
                                    <Th
                                        sorted={this.state.sortBy === 'created'}
                                        reversed={this.state.reverseSortDirection}
                                        onSort={() => this.handleColumnSort('date')}
                                    >
                                        Created
                                    </Th>
                                </Table.Tr>
                            </Table.Tbody>
                            <Table.Tbody>
                                {tickets && this.createTicketsToDisplay()}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </Container>
            </>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT, UPDATE_ACTIVE_LINK })(HardwareTicketsLandingPage);