import React, { Component } from 'react';
import { DayPilot, DayPilotCalendar, DayPilotNavigator } from "@daypilot/daypilot-lite-react";
import { Modal } from '@daypilot/modal';
import "./CalendarStyles.css";
import AssignTechsTicketsCalendarService from "../../services/assign-techs_tickets-calendar-activities";
import { RRule /*, RRuleSet, rrulestr*/ } from 'rrule';

import Navbar from '../../components/Navbar';


import { connect } from 'react-redux';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';
import { Redirect } from 'react-router';

// events in compoundDidMount before:
// events: [
//   {
//     id: 1,
//     text: "John McDougal",
//     start: "2022-03-14T10:30:00",
//     end: "2022-03-14T13:00:00"
//   },
//   {
//     id: 2,
//     text: "Jenny Jones",
//     start: "2022-03-15T09:30:00",
//     end: "2022-03-15T11:30:00",
//     backColor: "#6aa84f"
//   },
//   {
//     id: 3,
//     text: "Larry Bird",
//     start: "2022-03-15T12:00:00",
//     end: "2022-03-15T15:00:00",
//     backColor: "#f1c232"
//   },
//   {
//     id: 4,
//     text: "Tom Johnson",
//     start: "2022-03-13T11:30:00",
//     end: "2022-03-13T14:30:00",
//     backColor: "#cc4125"
//   },
// ]
// ]

const styles = {
  wrap: {
    display: "flex"
  },
  left: {
    marginRight: "10px"
  },
  main: {
    flexGrow: "1"
  }
};

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.retrieveTechEvents = this.retrieveTechEvents.bind(this);
    this.getEditableUser = this.getEditableUser.bind(this);
    this.createRrule = this.createRrule.bind(this);
    this.handleEventDelete = this.handleEventDelete.bind(this);

    this.state = {
      startDateViewing: '',
      endDateViewing: '',
      events: [],
      users: [],
      chosen_delete: '',
      delete_clicked: false,     // only gets set to true if user clicks the delete x icon (controls which modal user sees - edit or delete)
      form: [
        {
          type: 'searchable',
          id: 'searchable',
          name: 'Technician Name',
          options: [
            // {
            //   name: 'Hermain Hanif',
            //   id: '1',
            // },
            // {
            //   name: 'Micheal Childress',
            //   id: '2',
            // },
          ],
        },

        {
          type: 'recurrable',
          id: 'recurrable',
          name: 'Recurrence Frequency',
          options: [
            {
              name: 'None',
              id: "none"
            },
            {
              name: 'Monthly',
              id: "monthly"
            },
            {
              name: 'Weekly',
              id: "weekly"
            },
          ],
        }
      ],
      move_recurring_event_form: [
        {
          type: 'radio',
          id: 'radio_choose_event_move',
          name: 'Move just this event or this event and all following it in series?',
          options: [
            {
              name: 'Move Only This Event',
              id: 'move_this_event'
            },
            {
              name: 'Move All Recurring Events',
              id: 'move_all_events'
            }
          ]
        }
      ],
      delete_form: [
        {
          type: 'radio',
          id: 'radio_choose_event_delete',
          name: 'Delete just this or all recurring events?',
          options: [
            {
              name: 'Delete Only This Event',
              id: 'delete_this_event',
            },
            {
              name: 'Delete All Recurring Events',
              id: 'delete_all_events',
            },
          ],
        },
      ],
      delete_single_nonrecur_event_confirmation: [
        {
          type: 'title',
          id: 'delete_single_nonrecur_event_confirmation_title',
          name: 'Are you sure you want to delete this event?'
        }
      ],
      viewType: "Week",
      durationBarVisible: false,  // makes a blue bar run down the length of the event box if true
      timeRangeSelectedHandling: "Enabled",
      onTimeRangeSelected: async args => {
        const dp = this.calendar;
        var lowest = Number.POSITIVE_INFINITY;
        var tmp;
        for (var i = this.state.users.length - 1; i >= 0; i--) {
          tmp = this.state.users[i].id;
          if (tmp < lowest) lowest = tmp;
        }
        const data = { searchable: lowest, recurrable: 'none' };
        let modal_result = {}; // saving results from modal form 
        await Modal.form(this.state.form, data).then(modal => {
          //console.log(modal.result);
          modal_result = modal.result;
          //console.log("MODAL RESULTS: ", modal.result);
          //console.log("data: ", data);
        });

        if (!modal_result) {
          dp.clearSelection();
          return;
        }


        let form_options = this.state.form[0]['options'];
        let modal_result_user_id = modal_result['searchable'];

        let modal_result_recurring_type = modal_result['recurrable'];

        // saving event id locally from daypilot giud generator so can be saved in backend for event guid and in calendar events
        let event_id = DayPilot.guid();
        dp.events.add({   // code to add event to calendar (could perhaps talk to database here)
          start: args.start,
          end: args.end,
          id: event_id,
          text: form_options.find(x => x.id === modal_result_user_id).name,  // saving user name based on id found from searchable drop down
          groupId: modal_result_recurring_type !== 'none' ? event_id : null,
          isRecurring: modal_result_recurring_type
        });
        //console.log(event_id);

        let tech_name = form_options.find(x => x.id === modal_result_user_id).name;
        let tech_id = modal_result_user_id;

        // Front end calendar expects date time string in format YYYY-MM-DDThh:mm:ss
        // Back end MySQL expects date time string in format YYYY-MM-DD hh:mm:ss
        // Format with T or space accordingly

        // To save recurrence, have array of objects with start times and end times to send to backend (also guid value for calendar)
        // Group id to be determined on backend

        if (modal_result_recurring_type !== 'none') {

          let event_data_to_save = [];    // will be array of objects
          /*
              guid: "65687-aufa-...",
              description_of_event: "Test A McTesterson",
              tech_id: 4,
              start_time: "2022-04-18 10:30:00",
              end_time: "2022-04-18 11:00:00",
              scheduled_date: "2022-04-18",
              is_recurring: "none" (or "monthly", or "weekly"),
              group_id: "65687-aufa-..."
          */

          let scheduled_date = (args.start.value.split('T')[0] === args.end.value.split('T')[0]) ? args.start.value.split('T')[0] : '0000-00-00'; // looks like: 2022-05-05T11:30:00, check if date before T is the same for start and end 
          let scheduled_date_array = scheduled_date.split('-');
          //console.log("DATE: ", scheduled_date.split('-'));

          //console.log("TIME: ", (args.start.value.split('T')[1]).split(':')); 
          let start_time_array = (args.start.value.split('T')[1]).split(':'); // get time after T 
          //console.log(scheduled_date_array[1]-1);
          //console.log(scheduled_date_array[2]+1);
          let rule = new RRule();
          if (modal_result_recurring_type === 'weekly') {
            rule =
              new RRule({
                freq: RRule.WEEKLY,
                dtstart: new Date(Date.UTC(scheduled_date_array[0], scheduled_date_array[1] - 1, parseInt(scheduled_date_array[2]), start_time_array[0], start_time_array[1], start_time_array[2])),
                //tzid: 'America/Los_Angeles',
                count: 1040,
                interval: 1
              })
          } else if (modal_result_recurring_type === 'monthly') {
            rule =
              new RRule({
                freq: RRule.MONTHLY,
                dtstart: new Date(Date.UTC(scheduled_date_array[0], scheduled_date_array[1] - 1, parseInt(scheduled_date_array[2]), start_time_array[0], start_time_array[1], start_time_array[2])),
                //tzid: 'America/Los_Angeles',
                count: 240,
                interval: 1
              })
          }

          //console.log(rule.all());
          let dateTimeRules = rule.all();
          console.log(dateTimeRules);
          let endTime = args.end.value.split('T')[1]; // getting just the time ''13:30:00'

          let group_id = event_id;   // can use the very first guid generated as the group_id for all the rest of them 

          // Michael - changed i = 1 to i = 0 to also capture the first event element
          for (let i = 0; i < dateTimeRules.length; i++) {

            let dateTimeRule = dateTimeRules[i].toJSON().replace('.000Z', ''); // (changed 2022-04-14T13:30:00.000Z to 2022-04-14T13:30:00)

            let dateTimeRuleArray = dateTimeRule.split('T'); // ['2022-04-14', '13:30:00']

            let endDateTimeRule = dateTimeRuleArray[0] + 'T' + endTime;

            if (i > 0) {
              event_id = DayPilot.guid();   // we're preserving the first event_id for the item created before entering this for loop
            }

            //console.log(event_id);
            //console.log(dateTimeRule);
            //console.log(endDateTimeRule);

            let guid_to_save;



            guid_to_save = event_id;

            let description_of_event_to_save = tech_name;
            let tech_id_to_save = tech_id;
            let start_time_to_save = dateTimeRuleArray[0] + " " + dateTimeRuleArray[1];   // [0] = year, month, day component, [1] = time component space between for mysql
            let end_time_to_save = dateTimeRuleArray[0] + " " + endTime;
            let scheduled_date_to_save = dateTimeRuleArray[0];
            let is_recurring_to_save = modal_result_recurring_type;

            // add the constructed event data to object data for sending to backend
            event_data_to_save.push({
              guid: guid_to_save,
              description_of_event: description_of_event_to_save,
              tech_id: tech_id_to_save,
              start_time: start_time_to_save,
              end_time: end_time_to_save,
              scheduled_date: scheduled_date_to_save,
              is_recurring: is_recurring_to_save,
              group_id: group_id,
              timezone_adjustment_factor: this.props.loggedStatus.timezone_adjustment_factor
            });

          }

          console.log(event_data_to_save);

          //console.log(event_data_to_save);
          //console.log(JSON.stringify(event_data_to_save));

          // backend can only handle 350 events at a time, so split the events array into chunks to send to backend

          const chunkSize = 250;
          for (let i = 0; i < event_data_to_save.length; i += chunkSize) {
            const chunk = event_data_to_save.slice(i, i + chunkSize);
            //console.log(chunk);
            // Send the recurring event data to backend for processing
            AssignTechsTicketsCalendarService.addRecurringAssignment(JSON.stringify(chunk), this.props.loggedStatus.accessToken)
              .then(response => {
                console.log("successfully added recurring event");
              })
              .catch(e => {
                console.log(e);
              });
          }
        }
        else {  // they selected to create just a single event

          // args date and time value: 2022-04-03T12:00:00
          // splitting from T so get array of ['2022-04-03', '12:00:00']
          // replacing 2022-04-03T12:00:00 with 2022-04-03 12:00:00 to be datetime format
          const formData = new FormData();
          let formDataObj = Object.fromEntries(formData.entries());
          formDataObj["guid"] = event_id;
          formDataObj["descriptionOfEvent"] = form_options.find(x => x.id === modal_result_user_id).name;
          formDataObj["techId"] = modal_result_user_id;

          formDataObj["startTime"] = args.start.value.replace('T', ' ');

          formDataObj["endTime"] = args.end.value.replace('T', ' ');
          formDataObj["scheduledDate"] = (args.start.value.split('T')[0] === args.end.value.split('T')[0]) ? args.start.value.split('T')[0] : '0000-00-00';
          formDataObj["isRecurring"] = modal_result_recurring_type;

          // Add timezone adjustment for saving
          formDataObj["timezone_adjustment_factor"] = this.props.loggedStatus.timezone_adjustment_factor;

          AssignTechsTicketsCalendarService.createNewTechEvent(formDataObj, this.props.loggedStatus.accessToken)
            .then(response => {
              console.log("successfully added tech event");
            })
            .catch(e => {
              console.log(e);
            })
        }

      },
      eventDeleteHandling: "Update",    // hover delete option
      onEventClick: async args => {    // update name/description of event code
        // figure out how to update recurring events, when none, weekly and monthly (delete and update prev events, or 
        //    leave prev ones and add new recurring events based on update)
        // what happens when weekly/monthly to none? 

        const dp = this.calendar;

        var data = {};

        setTimeout(() => {
          this.getEditableUser(args.e.data['id']).then(response => { // gettin back all event details 
            //console.log("RESPONSE: ", response);
            data = {
              searchable: response.results[0].tech_id,
              recurrable: response.results[0].is_recurring,
              radio_choose_event_s_edit: 'edit_one_event'
            };
            // make sure edit mode not already saved for recurring events, otherise form repeats making radio section 
            if (this.state.form.length !== 3 && response.results[0].is_recurring !== "none") {
              this.setState({
                form: [
                  ...this.state.form,
                  {
                    type: 'radio',
                    id: 'radio_choose_event_s_edit',
                    name: 'Reassign just this or all recurring events in series?',
                    options: [
                      {
                        name: 'One Event',
                        id: 'edit_one_event',
                      },
                      {
                        name: 'All Recurring Events',
                        id: 'edit_all_events',
                      },
                    ],
                  },
                ]
              });

              if (response.results[0].is_recurring === "weekly") {
                //console.log("this is a weekly event");
                let tempForm = this.state.form.slice();
                tempForm.splice(1, 1); // remove that question for which type of recurrence they want
                tempForm.splice(1, 0, { name: 'These are weekly recurring events.' })
                this.setState({
                  form: tempForm
                });
              }
              else { // recurring events are monthly 
                //console.log("this is a monthly event");
                let tempForm = this.state.form.slice();
                tempForm.splice(1, 1); // remove that question for which type of recurrence they want
                tempForm.splice(1, 0, { name: 'These are monthly recurring events.' });
                this.setState({
                  form: tempForm
                });
              }
            }

            if (!this.state.delete_clicked) {

              Modal.form(this.state.form, data, this.state.form[0]['options']).then(modal => {
                //console.log("EDIT MODAL", modal.result); // MODAL RESULT (new user and recurring type): {searchable(id of user): 3, recurrable: 'none'}

                //console.log("MODAL RESULTS: ", modal.result);
                if (modal.canceled) {
                  //console.log("canceled");
                  if (response.results[0].is_recurring !== "none") {
                    const temp_form = [...this.state.form];
                    temp_form.splice(2, 1); // remove the edit part of the form for recurring events, which will be second part of index
                    temp_form.splice(1, 1); // remove the label informing which type of recurrence it is 
                    temp_form[1] = {
                      type: 'recurrable',
                      id: 'recurrable',
                      name: 'Recurrable',
                      options: [
                        {
                          name: 'None',
                          id: "none"
                        },
                        {
                          name: 'Monthly',
                          id: "monthly"
                        },
                        {
                          name: 'Weekly',
                          id: "weekly"
                        },
                      ],
                    };
                    this.setState({
                      form: temp_form
                    });
                  }
                  return;
                }
                //console.log("ARGS.E.DATA: ", args.e.data); 
                // ARGS.DATA (object after user selects (using table id and guid to update event)):  
                /*
                {id: 'dd914ad2-ab4e-71e9-fa14-8f576a856434', text: 'Hermain Hanif', start: p…h../…t.min.js.D…t.Date, end: p…h../…t.min.js.D…t.Date, table_id: 11, …}
                end: push../node_modules/@daypilot/daypilot-lite-react/daypilot-react.min.js.DayPilot.Date {value: "2022-05-03T11:30:00"}
                groupId: null
                id: "dd914ad2-ab4e-71e9-fa14-8f576a856434"
                isRecurring: "none"
                scheduledDate: "2022-05-03"
                start: push../node_modules/@daypilot/daypilot-lite-react/daypilot-react.min.js.DayPilot.Date {value: '2022-05-03T11:00:00'}
                table_id: 11
                techId: 3
                text: "Hermain Hanif"
                */
                let form_options = this.state.form[0]['options'];
                let name = form_options.find(x => x.id === modal.result['searchable']).name;
                //console.log("GET NAME FROM OPTIONS: ", name);

                let descriptionObj = {};
                descriptionObj['descriptionOfEvent'] = form_options.find(x => x.id === modal.result['searchable']).name;
                descriptionObj['techId'] = modal.result['searchable'];
                descriptionObj['isRecurrable'] = modal.result['recurrable'];
                descriptionObj['timezone_adjustment_factor'] = this.props.loggedStatus.timezone_adjustment_factor;

                let tech_id = modal.result['searchable'];
                let tech_name = form_options.find(x => x.id === modal.result['searchable']).name;
                let modal_result_recurring_type = modal.result['recurrable'];
                let event_id = response.results[0].guid;

                if (descriptionObj['techId'] !== response.results[0].tech_id) { // if user has chosen a different user than found in the backend 
                  if (modal.result['radio_choose_event_s_edit'] === 'edit_one_event' || response.results[0].is_recurring === 'none') { // if user has chosen to only change one event in recurring events 
                    // or change the name of just the ONE event 
                    setTimeout(() => {
                      AssignTechsTicketsCalendarService.updateTechEventName(args.e.data.id, descriptionObj, this.props.loggedStatus.accessToken)
                        .then(response => {
                          //console.log("in promise, change tech event name");
                          let findUpdateEvent = this.state.events.indexOf(this.state.events.find(x => x.id === args.e.data.id));
                          //console.log(findUpdateEvent);

                          let newEvents = this.state.events.slice();
                          newEvents[findUpdateEvent]['text'] = descriptionObj['descriptionOfEvent'];
                          newEvents[findUpdateEvent]['techId'] = descriptionObj['techId'];

                          this.setState({
                            events: newEvents
                          });
                          //console.log("UPDATE NAME EVENTS UPDATE: ", this.state.events);
                        })
                        .catch(e => {
                          console.log(e);
                        });

                    }, 1000);
                  } else {    // user either didn't choose to change just one or all, or they deliberately selected the change all button
                    // if radio_choose_event_s_edit is null, it means they didn't select a radio. FIND A AWAY TO AUTO SELECT A RADIO BUTTON ON FORM LOAD
                    //console.log('CHANGE ALL NAMES OF EVENTS: ', modal.result['radio_choose_event_s_edit']);
                    descriptionObj['first_event'] = (args.e.data.start.value).replace('T', ' ')
                    setTimeout(() => {
                      AssignTechsTicketsCalendarService.updateRecurrTechEventsName(args.e.data.groupId, descriptionObj, this.props.loggedStatus.accessToken)
                        .then(response => {
                          //console.log("args.e.data: ", args.e.data);
                          //console.log("ALL NAME EVENTS: ", this.state.events);
                          //console.log("ALL DP EVENTS NAME: ", dp.events);
                          let findUpdateNameEvents = dp.events.list.filter(x => {
                            return x.groupId === args.e.data.groupId && x.start.value >= args.e.data.start.value
                          });
                          let newNameEvents = this.state.events.slice();
                          for (let i = 0; i < findUpdateNameEvents.length; i++) {
                            let index = newNameEvents.findIndex(x => {
                              return x.id === findUpdateNameEvents[i].id
                            });
                            newNameEvents[index]['text'] = descriptionObj['descriptionOfEvent'];
                            newNameEvents[index]['techId'] = descriptionObj['techId'];
                            dp.events.update(newNameEvents[index].id);
                          }
                          this.setState({
                            events: newNameEvents
                          });

                          console.log("sucessfully updated recurring events");
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }, 1000);
                  }
                }

                // if they picked a recurring option that was not what was currently selected, and it was previously set to none, add recurrence
                if (descriptionObj['isRecurrable'] !== response.results[0].is_recurring && response.results[0].is_recurring === 'none') {
                  let newRecurEvents = this.state.events.slice();
                  let index = newRecurEvents.findIndex(x => {
                    return x.id === args.e.data.id
                  });
                  newRecurEvents[index]['groupId'] = args.e.data.id;
                  dp.events.update(newRecurEvents[index].id);
                  dp.events.update(args.e);
                  let event_data_to_update = [];    // will be array of objects
                  console.log(response);
                  //console.log(response.results);
                  /*response.results[0] has this data 
                      guid: "65687-aufa-...",
                      description_of_event: "Test A McTesterson",
                      tech_id: 4,
                      start_time: "2022-04-18 10:30:00",
                      end_time: "2022-04-18 11:00:00",
                      scheduled_date: "2022-04-18",
                      is_recurring: "none" (or "monthly", or "weekly"),
                      group_id: "65687-aufa-... (can be null when single event)"
                  */


                  let dateTimeRules = this.createRrule(response, descriptionObj);

                  let response_end_time = response.results[0].end_time;
                  console.log(response_end_time);
                  let end_time = response_end_time.toJSON().replace('.000Z', ''); // (changed 2022-04-14T13:30:00.000Z to 2022-04-14T13:30:00)
                  let endTime = end_time.split(' ')[1]; // getting just the time '13:30:00'

                  let group_id = response.results[0].guid;   // can use the very first guid generated as the group_id for all the rest of them 

                  // hermain - changed i = 1 since creating other events and only updating current event 
                  for (let i = 1; i < dateTimeRules.length; i++) {
                    //console.log("in for");
                    //console.log(dateTimeRules[i].toJSON());
                    let dateTimeRule = dateTimeRules[i].toJSON().replace('.000Z', ''); // (changed 2022-04-14T13:30:00.000Z to 2022-04-14T13:30:00)
                    //console.log("dateTimeRule: ", dateTimeRule);
                    let dateTimeRuleArray = dateTimeRule.split('T'); // ['2022-04-14', '13:30:00']
                    //console.log("datTimRuleArray: ", dateTimeRuleArray);
                    let endDateTimeRule = dateTimeRuleArray[0] + 'T' + endTime;

                    if (i > 0) {
                      event_id = DayPilot.guid();   // we're preserving the first event_id for the item created before entering this for loop
                    }

                    //console.log(event_id);
                    //console.log(dateTimeRule);
                    //console.log(endDateTimeRule);

                    let guid_to_save;



                    guid_to_save = event_id;


                    let description_of_event_to_save = tech_name;
                    let tech_id_to_save = tech_id;
                    let start_time_to_save = dateTimeRuleArray[0] + " " + dateTimeRuleArray[1];   // [0] = year, month, day component, [1] = time component space between for mysql
                    let end_time_to_save = dateTimeRuleArray[0] + " " + endTime;
                    let scheduled_date_to_save = dateTimeRuleArray[0];
                    let is_recurring_to_save = modal_result_recurring_type;

                    // add the constructed event data to object data for sending to backend
                    event_data_to_update.push({
                      guid: guid_to_save,
                      description_of_event: description_of_event_to_save,
                      tech_id: tech_id_to_save,
                      start_time: start_time_to_save,
                      end_time: end_time_to_save,
                      scheduled_date: scheduled_date_to_save,
                      is_recurring: is_recurring_to_save,
                      group_id: group_id,
                      timezone_adjustment_factor: this.props.loggedStatus.timezone_adjustment_factor
                    });

                  }

                  console.log(event_data_to_update);

                  //console.log(event_data_to_update);
                  //console.log(JSON.stringify(event_data_to_update));

                  // backend can only handle 350 events at a time, so split the events array into chunks to send to backend
                  const chunkSize = 250;
                  for (let i = 0; i < event_data_to_update.length; i += chunkSize) {
                    const chunk = event_data_to_update.slice(i, i + chunkSize);
                    //console.log(chunk);

                    // Send the recurring event data to backend for processing
                    AssignTechsTicketsCalendarService.addRecurringAssignment(JSON.stringify(chunk), this.props.loggedStatus.accessToken)
                      .then(response => {
                        console.log("successfully added recurring event");
                      })
                      .catch(e => {
                        console.log(e);
                      });
                  }
                  //console.log("STPID ARGS tabid: ",args.e.data );
                  AssignTechsTicketsCalendarService.updateSingleEventToRecurringEvents(args.e.data.id, descriptionObj, this.props.loggedStatus.accessToken)
                    .then(response => {
                      console.log("successfully updated single event into recurring event");
                      //  have to update front end event
                      let findUpdateEvent = this.state.events.indexOf(this.state.events.find(x => x.id === args.e.data.id));
                      //console.log(findUpdateEvent);

                      let newEvents = this.state.events.slice();
                      newEvents[findUpdateEvent]['isRecurring'] = descriptionObj['isRecurrable'];

                      this.setState({
                        events: newEvents
                      });
                      //console.log("NOW RECUR EVENTS UPDATE: ", this.state.events);

                    })
                    .catch(e => {
                      console.log(e);
                    });


                }

              });

            }

            const temp_form = [...this.state.form];
            temp_form.splice(2, 1); // remove the edit part of the form for recurring events, which will be second part of index
            temp_form.splice(1, 1); // remove the label informing which type of recurrence it is 
            temp_form[1] = {
              type: 'recurrable',
              id: 'recurrable',
              name: 'Recurrable',
              options: [
                {
                  name: 'None',
                  id: "none"
                },
                {
                  name: 'Monthly',
                  id: "monthly"
                },
                {
                  name: 'Weekly',
                  id: "weekly"
                },
              ],
            };
            this.setState({
              form: temp_form
            });
          });

        }, 100);

      },

      onEventResized: async args => { // update time code
        let dp = this.calendar;
        const e = args.e;
        //console.log(e.data);
        let timesObj = {};
        //console.log("TIME RESIZED: ", e.data);
        if (e.data.isRecurring === "none") {
          // replacing 2022-04-03T12:00:00 with 2022-04-03 12:00:00 to be datetime format
          timesObj['startTime'] = e.data.start.value.replace('T', ' ');
          timesObj['endTime'] = e.data.end.value.replace('T', ' ');
          timesObj['timezone_adjustment_factor'] = this.props.loggedStatus.timezone_adjustment_factor;
          setTimeout(() => {
            AssignTechsTicketsCalendarService.updateTechEventTime(e.data.id, timesObj, this.props.loggedStatus.accessToken)
              .then(response => {
                //console.log("in promise, change tech event time(s)");
              })
              .catch(e => {
                console.log(e);
              });
          }, 1000);
        } else {    // the event was recurring from the beginning, so need to display dialog first
          let resize_form = [
            {
              type: 'radio',
              id: 'radio_choose_event_resize',
              name: 'Change just this or all recurring events?',
              options: [
                {
                  name: 'This Event',
                  id: 'resize_this_event',
                },
                {
                  name: 'All Recurring Events',
                  id: 'resize_all_events',
                },
              ],
            },
          ];
          let data = { radio_choose_event_resize: 'resize_this_event' }

          await Modal.form(resize_form, data).then(modal => {
            //console.log("AFTER RESIZE, MODAL RESULTS: ", modal.result);
            if (modal.canceled) {

              //console.log("RESIZE canceled");

              //console.log("THIS EVENT CANCELED: ", e.data);
              //console.log("ALL DOP EVENTS: ", dp.events.list);
              //console.log("ALL EVENTS: ", this.state.events);
              dp.clearSelection();
              dp.events.update(this.state.events);
              //console.log("IS THIS EVEN GOIN TO CANCEL");

              // refresh the data so the resize didn't happen
              this.retrieveTechEvents(this.props.loggedStatus.accessToken, this.state.startDateViewing, this.state.endDateViewing).then(response => {
                if (response.results.length !== 0) {
                  let tempEvents = [];
                  for (let i = 0; i < response.results.length; i++) {
                    tempEvents.push(
                      {
                        id: response.results[i].guid, // id made from divpilot guid
                        text: response.results[i].description_of_event,
                        start: response.results[i].start_time,
                        end: response.results[i].end_time,
                        table_id: response.results[i].id, // incremental id in backend mysql table
                        techId: response.results[i].tech_id,
                        scheduledDate: response.results[i].scheduled_date,
                        isRecurring: response.results[i].is_recurring,
                        groupId: response.results[i].group_id,  // id to group recurring events 
                      },
                    );
                  }
                  this.setState({
                    events: tempEvents
                  });
                  //console.log(this.state.events);
                  if (dp) {
                    dp.update();
                  }

                }
              })
                .catch(e => {
                  console.log(e);
                });

              return;
            }
            // call this to update ALL events with same group id
            //console.log(e.data.id);

            // if they choose to resize all events
            if (modal.result['radio_choose_event_resize'] === "resize_all_events") {
              setTimeout(() => {
                AssignTechsTicketsCalendarService.getGroupId(e.data.id, this.props.loggedStatus.accessToken)
                  .then(response => {
                    //console.log("got GROUP id)");
                    //console.log("GET GROUPID: ", response.data.results[0]);
                    let groupId;
                    groupId = response.data.results[0].group_id;
                    //console.log("E START VALUE TO COMPARE: ", e.data.start.value);
                    let findUpdateEvents = dp.events.list.filter(x => {
                      return x.groupId === groupId && x.start.value >= e.data.start.value
                    });
                    //console.log("groupId: ", groupId);
                    //console.log("ALL EVENTS", this.state.events);
                    //console.log("DP EVENTS: ", dp.events);
                    //console.log("FIND EVENTS TO UPDATE: ", findUpdateEvents);
                    let changedDateTimes = [
                      {

                      },
                    ]
                    for (let i = 0; i < findUpdateEvents.length; i++) {
                      // getting 2022-04-03T12:00:00 and making array [2022-04-03, 12:00:00]. need only date 
                      //console.log("NAME OF CHANGE EVENT: ",  findUpdateEvents[i].text);
                      //console.log("ID OF CHANGE EVENT: ",  findUpdateEvents[i].id);
                      //console.log("GROUP ID OF CHANGE EVENT: ",  findUpdateEvents[i].groupId);
                      let getRecurStartDate = (findUpdateEvents[i].start.value.split('T'))[0];
                      //console.log("START DATE FROM EVENTS: ", getRecurStartDate);

                      // getting array for 2022-04-03T12:00:00 with [2022-04-03, 12:00:00]. need only time 
                      let updateStartTime = (e.data.start.value.split('T'))[1];
                      //console.log("updateStartTime: ", updateStartTime);

                      // datetime format: '2022-04-03 12:00:00'
                      timesObj['startTime'] = getRecurStartDate + ' ' + updateStartTime;
                      //console.log("timesObj['startTime']: ", timesObj['startTime']);

                      // getting 2022-04-03T12:00:00 and making array [2022-04-03, 12:00:00]. need only date 
                      let getRecurEndDate = (findUpdateEvents[i].end.value.split('T'))[0];
                      // getting array for 2022-04-03T12:00:00 with [2022-04-03, 12:00:00]. need only time 
                      let updateEndTime = (e.data.end.value.split('T'))[1];
                      // datetime format: '2022-04-03 12:00:00'
                      timesObj['endTime'] = getRecurEndDate + ' ' + updateEndTime;
                      //console.log("EVENT ID: ", findUpdateEvents[i].id);
                      changedDateTimes.push({
                        id: findUpdateEvents[i].id,
                        start_time: timesObj['startTime'],
                        end_time: timesObj['endTime'],
                        first_event: (e.data.start.value).replace('T', ' ')
                      });

                      let newTimeEvents = this.state.events.slice();
                      //console.log(newTimeEvents[i]['start']);
                      let index = newTimeEvents.findIndex(x => {
                        return x.id === findUpdateEvents[i].id
                      });
                      newTimeEvents[index]['start'] = timesObj['startTime'].replace(' ', 'T');
                      newTimeEvents[index]['end'] = timesObj['endTime'].replace(' ', 'T');
                      dp.events.update(newTimeEvents[index].id);


                      this.setState({
                        events: newTimeEvents
                      });

                      //console.log("EVETNS RETIMED: ", this.state.events);

                    }
                    let updateStartTime = `${e.data.start.value.split("T")[0]} ${e.data.start.value.split("T")[1]}`;
                    let updateEndTime = `${e.data.end.value.split("T")[0]} ${e.data.end.value.split("T")[1]}`;

                    let dataToSend = {
                      newStartTime: updateStartTime,
                      newEndTime: updateEndTime,
                      idOfEventChanged: e.data.id,
                      groupIdEffected: groupId,
                      dateTimeOfAffectedElement: e.data.start.value,
                      timezone_adjustment_factor: this.props.loggedStatus.timezone_adjustment_factor
                    }

                    //console.log("UPDATED DP EVENTS: ", dp.events);
                    //console.log("CHANGED DATE TIME JSON: ", JSON.stringify(changedDateTimes));
                    //console.log("GROUP ID: ", groupId);
                    AssignTechsTicketsCalendarService.updateTechRecurringEventTimes(groupId, dataToSend /*JSON.stringify(changedDateTimes)*/, this.props.loggedStatus.accessToken)
                      .then(response => {
                        //console.log("in promise, change tech event time(s)");
                      })
                      .catch(e => {
                        console.log(e);
                      });

                  })
                  .catch(e => {
                    console.log(e);
                  });
              }, 1000);

            } else { // only update one event currently changed 
              timesObj['startTime'] = e.data.start.value.replace('T', ' ');
              timesObj['endTime'] = e.data.end.value.replace('T', ' ');
              timesObj['timezone_adjustment_factor'] = this.props.loggedStatus.timezone_adjustment_factor;
              setTimeout(() => {
                AssignTechsTicketsCalendarService.updateTechEventTime(e.data.id, timesObj, this.props.loggedStatus.accessToken)
                  .then(response => {
                    //console.log("in promise, change tech event time(s)");
                  })
                  .catch(e => {
                    console.log(e);
                  });
              }, 1000);
            }


          });
        }

      },
      onEventMoved: async args => { // move events to other date/times code
        const e = args.e;
        //console.log("MOVED EVENT: ", e.data);
        let dateAndTimesObject = {};
        dateAndTimesObject['scheduledDate'] = (e.data.start.value.split('T')[0] === e.data.end.value.split('T')[0]) ? e.data.start.value.split('T')[0] : '0000-00-00';
        dateAndTimesObject['startTime'] = e.data.start.value.replace('T', ' ');
        dateAndTimesObject['endTime'] = e.data.end.value.replace('T', ' ');
        dateAndTimesObject['timezone_adjustment_factor'] = this.props.loggedStatus.timezone_adjustment_factor;
        if (e.data.isRecurring === 'none') {
          setTimeout(() => {
            AssignTechsTicketsCalendarService.updateTechEventMoved(e.data.id, dateAndTimesObject, this.props.loggedStatus.accessToken)
              .then(response => {
                //console.log("in promise, change tech event movement");
              })
              .catch(e => {
                console.log(e);
              });
          }, 1000);
        } else {    // the event being moved belongs to a recurring event
          //console.log('MOVE ALL EVENTS');
          //console.log('MOVE ALL EVENTS ARGS: ', args.e.data);

          // descriptionObj['first_event'] = (args.e.data.start.value).replace('T', ' ')
          // get changed event info (args)
          // get count of how many events to change (for rrules)
          // make new rrules for recurring eveents with same group id
          // replace events start time and end time and schedule date

          // Offer form for user to select affect one or all events in series
          await Modal.form(this.state.move_recurring_event_form, { radio_choose_event_move: 'move_this_event' }).then(modal => {
            if (modal.canceled) {
              args.preventDefault();
              return;
            }

            else {
              if (modal.result['radio_choose_event_move'] === 'move_this_event') {
                console.log("We'll move just this event");
                setTimeout(() => {
                  AssignTechsTicketsCalendarService.updateTechEventMoved(e.data.id, dateAndTimesObject, this.props.loggedStatus.accessToken)
                    .then(response => {
                      //console.log("in promise, change tech event movement");
                    })
                    .catch(e => {
                      console.log(e);
                    });
                }, 1000);
              }

              else {    // user selected to affect all events in series
                console.log("Going to affect ALL in series");


                // getCountAndTechIds returns the ids (guid) of all events from that time period on (based on passed in group id and date)
                AssignTechsTicketsCalendarService.getCountAndTechIds(args.e.data.groupId, (args.e.data.start.value).replace('T', ' '), this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken)
                  .then(response => {

                    // response contains array of guids and value for is recurring (seems to return all items in series except the one directly moved)
                    //console.log("get count and tech ids");
                    //console.log("COUNT & TECH IDS RESPONSE", response.data.results);
                    // make rrules with length 
                    let scheduled_date = args.e.data.start.value.split('T')[0];
                    //console.log("SCHEDULED DATE: ", scheduled_date);
                    let scheduled_date_array = scheduled_date.split('-');
                    //console.log("MOVE DATE ARRAY: ", scheduled_date.split('-'));

                    // scheduled_date_array now contains array of [yyyy, mm, dd]

                    let start_time = args.e.data.start.value
                    //console.log("START TIME: ", start_time);
                    let start_time_array = (start_time.split('T')[1]).split(':'); // get time after T 
                    //console.log("MOVE START TIME ARRAY: ", (start_time.split('T')[1]).split(':')); 

                    // start_time_array now has array [hh, mm, ss]

                    //console.log(scheduled_date_array[1]-1);
                    //console.log(scheduled_date_array[2]+1);
                    let rule = new RRule();
                    if (e.data.isRecurring === 'weekly' || response.data.results[0].is_recurring === 'weekly') {
                      //console.log("IN WEEKLY");
                      rule =
                        new RRule({
                          freq: RRule.WEEKLY,
                          dtstart: new Date(Date.UTC(scheduled_date_array[0], scheduled_date_array[1] - 1, parseInt(scheduled_date_array[2]), start_time_array[0], start_time_array[1], start_time_array[2])),
                          //tzid: 'America/Los_Angeles',
                          count: response.data.results.length + 1,
                          interval: 1
                        })
                    } else if (e.data.isRecurring === 'monthly' || response.data.results[0].is_recurring === 'monthly') {
                      //console.log("IN MONTHLY");
                      rule =
                        new RRule({
                          freq: RRule.MONTHLY,
                          dtstart: new Date(Date.UTC(scheduled_date_array[0], scheduled_date_array[1] - 1, parseInt(scheduled_date_array[2]), start_time_array[0], start_time_array[1], start_time_array[2])),
                          //tzid: 'America/Los_Angeles',
                          count: response.data.results.length + 1,
                          interval: 1
                        })
                    }
                    //console.log("MOVE RULES: ", rule.all());
                    let dateAndTimeRules = rule.all();
                    let endTime = args.e.data.end.value.split('T')[1]; // getting just the time ''13:30:00'
                    let move_event_data_to_update = [];

                    move_event_data_to_update.push({
                      guid: args.e.data.id,
                      start_time: args.e.data.start.value.replace('T', ' '),
                      end_time: args.e.data.end.value.replace('T', ' '),
                      scheduled_date: args.e.data.start.value.split('T')[0],
                      group_id: args.e.data.groupId,
                      timezone_adjustment_factor: this.props.loggedStatus.timezone_adjustment_factor
                    });


                    let counter_for_response = 0;
                    // first event has already been added 
                    for (let i = 1; i < dateAndTimeRules.length && counter_for_response < response.data.results.length; i++) {
                      //console.log("in for");
                      //console.log(dateAndTimeRules[i].toJSON());
                      let dateTimeRule = dateAndTimeRules[i].toJSON().replace('.000Z', ''); // (changed 2022-04-14T13:30:00.000Z to 2022-04-14T13:30:00)
                      //console.log("dateTimeRule: ", dateTimeRule);
                      let dateTimeRuleArray = dateTimeRule.split('T'); // ['2022-04-14', '13:30:00']
                      //console.log("datTimRuleArray: ", dateTimeRuleArray);
                      let endDateTimeRule = dateTimeRuleArray[0] + 'T' + endTime;

                      //console.log(dateTimeRule);
                      //console.log(endDateTimeRule);                

                      let start_time_to_save = dateTimeRuleArray[0] + " " + dateTimeRuleArray[1];   // [0] = year, month, day component, [1] = time component space between for mysql
                      let end_time_to_save = dateTimeRuleArray[0] + " " + endTime;
                      let scheduled_date_to_save = dateTimeRuleArray[0];

                      //console.log(response.data.results[counter_for_response].guid)
                      if (args.e.data.id === response.data.results[counter_for_response].guid) {
                        counter_for_response += 1;
                      }

                      // add the constructed event data to object data for sending to backend
                      move_event_data_to_update.push({
                        guid: response.data.results[counter_for_response].guid,
                        start_time: start_time_to_save,
                        end_time: end_time_to_save,
                        scheduled_date: scheduled_date_to_save,
                        group_id: args.e.data.groupId,
                        timezone_adjustment_factor: this.props.loggedStatus.timezone_adjustment_factor
                      });
                      counter_for_response += 1;

                    }
                    //console.log("MOVE move_event_data_to_update: ", move_event_data_to_update);
                    // go through and update
                    // send data in chunks

                    const chunkSize = 250;
                    for (let i = 0; i < move_event_data_to_update.length; i += chunkSize) {
                      const chunk = move_event_data_to_update.slice(i, i + chunkSize);
                      //console.log(chunk);
                      // Send the recurring event data to backend for processing
                      AssignTechsTicketsCalendarService.updateMoveAllRecurEvents(JSON.stringify(chunk), this.props.loggedStatus.accessToken)
                        .then(response => {
                          console.log("successfully MOVED recurring event");
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }


                  })
                  .catch(e => {
                    console.log(e);
                  });

              }
            }
          });

        }
      },
      onEventDelete: async args => { // on deleting event
        //console.log("DELETE ARGS: ", args);

        if (args.e.data.isRecurring === 'none') {
          /* if(!window.confirm("Do you really want to delete this event?")) {
            args.preventDefault();
          } */

          args.preventDefault();

          this.setState({
            delete_clicked: true
          });

          await Modal.form(this.state.delete_single_nonrecur_event_confirmation).then(modal => {
            if (modal.canceled) {
              args.preventDefault();
              this.setState({
                delete_clicked: false
              });
              return;
            }

            else {
              this.setState({
                delete_clicked: false
              }, () => this.handleEventDelete(args));
            }
          });
        }
        else { //create form here (Do you want to delete just this or all events)
          /* if(!window.confirm("Do you really want to delete all recurring events?")) {
            args.preventDefault();
          } */

          args.preventDefault();

          this.setState({
            delete_clicked: true
          });

          await Modal.form(this.state.delete_form, { radio_choose_event_delete: 'delete_this_event' }).then(modal => {
            //console.log("DELETE MODAL RESULT: ", modal.result);

            if (modal.canceled) {
              args.preventDefault();
              //console.log("canceled");
              this.setState({
                delete_clicked: false
              });
              return;
            }

            else {
              //console.log("User pressed ok");
              this.setState({
                delete_clicked: false,
                chosen_delete: modal.result['radio_choose_event_delete'],   // expects string as "2022-12-05"
              }, () => { this.handleEventDelete(args) });
            }
          });

        }
      },
      // we're using custom handleEventDelete function for all activities previously assigned to onEventDeleted 
      // onEventDeleted: args => { // after deleting event, and returns event id
      //   let dp = this.calendar; 
      //   console.log("DELETE args.e.data.isRecurring: ", args.e.data.isRecurring);
      //   console.log("DELETE this.state.chosen_delete: ", this.state.chosen_delete);
      //   if(args.e.data.isRecurring === 'none' || this.state.chosen_delete === 'delete_this_event'){ // add logic/form that user picks deleting ONLY one event 
      //     console.log("You deleted event with id: ", args.e.id());
      //     setTimeout(() => {
      //       AssignTechsTicketsCalendarService.deleteTechEvent(args.e.id(), this.props.loggedStatus.accessToken)
      //           .then(response => {
      //               console.log("in promise, delete tech event");
      //           })
      //           .catch(e => {
      //               console.log(e);
      //           });
      //     }, 1000);
      //   } 
      //   // else if (this.state.chosen_delete === '') {
      //   //   return;
      //   // }
      //   else { // delete all recurring events 
      //     console.log('IN DELETED, USER CHOSE THIS: ', this.state.chosen_delete);
      //     let compareDeleteDate = {}
      //     compareDeleteDate['dateToCompare'] = (args.e.data.start.value).replace('T', ' '); 
      //     setTimeout(() => {
      //       AssignTechsTicketsCalendarService.deleteRecurrTechEvents(args.e.data.groupId, compareDeleteDate['dateToCompare'], this.props.loggedStatus.accessToken)
      //           .then(response => {
      //               console.log("in promise, delete ALL tech events");
      //               // update in front end so deletes reflected in calendar
      //               let newDeleteEvents = this.state.events.slice();
      //               let tempDeleteArray = newDeleteEvents.filter(x => {
      //                 return x.groupId === args.e.data.groupId && x.start.value >= args.e.data.start.value});
      //                 console.log('BEFORE-DP EVENTS ARRAY: ', dp.events);
      //                 console.log('BEFORE-NEW MOVE EVENTS ARRAY: ', newDeleteEvents);
      //                 console.log("TEMP DELTE ARRAY: ", tempDeleteArray);
      //               for(let e = 0; e < tempDeleteArray.length; e++) {
      //                 newDeleteEvents = newDeleteEvents.filter((item) => item.id !== tempDeleteArray[e].id);
      //                 // let index = newDeleteEvents.indexOf(x => {
      //                 //   return x.id === tempDeleteArray[e].id});
      //                 //   newDeleteEvents = newDeleteEvents.splice(index,1);
      //                 console.log("AFTER- newDeleteEvents: ", newDeleteEvents);
      //                 dp.events.update();

      //               }
      //               console.log("FINAL- newDeleteEvents: ", newDeleteEvents);
      //               this.setState({
      //                 events: newDeleteEvents
      //               });
      //           })
      //           .catch(e => {
      //               console.log(e);
      //           });
      //     }, 1000);
      //   }
      // }
    };
  }

  componentDidMount() {

    if (this.props.loggedStatus.loggedIn) {
      this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
        // if the token can't be refreshed, force a logout
        if (token === undefined || token === null || token === "") {
          console.log("Token was expired and can't refresh, so force logging out this user!");
          this.props.LOGOUT();        // logout of global state
          this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
        }

        else {
          this.props.ACCESSTOKEN(token);

          this.setState({
            startDate: DayPilot.Date.today(),   // expects string as "2022-12-05"
            selectionDay: DayPilot.Date.today()
          });


          this.retreiveUsers(token).then(response => {

            if (response.results.length !== 0) {
              for (let i = 0; i < response.results.length; i++) {
                this.setState({
                  users: [
                    ...this.state.users,
                    {
                      name: response.results[i].tech_full_name, //response.results[i].first_name + " " + ((response.results[i].middle_name === null) ? "" : response.results[i].middle_name + " ") + response.results[i].last_name,
                      id: response.results[i].id
                    }
                  ],
                });
                if (this.state.users.length !== 0) {
                  const newFormUserData = this.state.form.slice(); //copy the array
                  newFormUserData[0]['options'] = this.state.users; //execute the manipulations
                  this.setState({ form: newFormUserData }); //set the new state
                }
              }
            }
          })
        }
      });


      this.props.UPDATE_ACTIVE_LINK("techAssignment");  // Tell global state that this is the active page now (controls nav bar highlighting)
    }
  }

  // this fxn gets called after user presses OK on delete modal. will route delete operation to either the delete one or series endpoints
  // this was previously handled by onEventDeleted which would not work since we had to preventDefault on the x icon
  handleEventDelete(args) {
    //console.log("args received by handle delete fxn was: ", args);
    //console.log("Value of bubble chosen on delete form was: ", this.state.chosen_delete);
    let dp = this.calendar;
    //console.log("DELETE args.e.data.isRecurring: ", args.e.data.isRecurring);
    //console.log("DELETE this.state.chosen_delete: ", this.state.chosen_delete);
    if (args.e.data.isRecurring === 'none' || this.state.chosen_delete === 'delete_this_event') { // add logic/form that user picks deleting ONLY one event 
      //console.log("You deleted event with id: ", args.e.id());
      setTimeout(() => {
        AssignTechsTicketsCalendarService.deleteTechEvent(args.e.id(), this.props.loggedStatus.accessToken)
          .then(response => {
            //console.log("in promise, delete tech event");
            // refresh the calendar to show the delete
            this.retrieveTechEvents(this.props.loggedStatus.accessToken, this.state.startDateViewing, this.state.endDateViewing).then(response => {
              if (response.results.length !== 0) {

                let tempEvents = [];
                for (let i = 0; i < response.results.length; i++) {
                  tempEvents.push(
                    {
                      id: response.results[i].guid, // id made from divpilot guid
                      text: response.results[i].description_of_event,
                      start: response.results[i].start_time,
                      end: response.results[i].end_time,
                      table_id: response.results[i].id, // incremental id in backend mysql table
                      techId: response.results[i].tech_id,
                      scheduledDate: response.results[i].scheduled_date,
                      isRecurring: response.results[i].is_recurring,
                      groupId: response.results[i].group_id,  // id to group recurring events 
                    },
                  );
                }
                this.setState({
                  events: tempEvents
                });
                //console.log(this.state.events);
                if (dp) {
                  dp.update();
                }

              }
              else {    // that was the last event, so just load up a blank calendar
                let tempEvents = [];
                this.setState({
                  events: tempEvents
                });

                if (dp) {
                  dp.update();
                }
              }
            })
              .catch(e => {
                console.log(e);
              });
          })
          .catch(e => {
            console.log(e);
          });
      }, 1000);
    }

    else { // delete all recurring events
      //console.log('IN DELETED, USER CHOSE THIS: ', this.state.chosen_delete);
      let compareDeleteDate = {}
      compareDeleteDate['dateToCompare'] = (args.e.data.start.value).replace('T', ' ');
      setTimeout(() => {
        AssignTechsTicketsCalendarService.deleteRecurrTechEvents(args.e.data.groupId, compareDeleteDate['dateToCompare'], this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken)
          .then(response => {
            //console.log("in promise, delete ALL tech events");
            // update in front end so deletes reflected in calendar
            let newDeleteEvents = this.state.events.slice();
            let tempDeleteArray = newDeleteEvents.filter(x => {
              return x.groupId === args.e.data.groupId && x.start.value >= args.e.data.start.value
            });
            //console.log('BEFORE-DP EVENTS ARRAY: ', dp.events);
            //console.log('BEFORE-NEW MOVE EVENTS ARRAY: ', newDeleteEvents);
            //console.log("TEMP DELTE ARRAY: ", tempDeleteArray);
            for (let e = 0; e < tempDeleteArray.length; e++) {
              newDeleteEvents = newDeleteEvents.filter((item) => item.id !== tempDeleteArray[e].id);
              //console.log("AFTER- newDeleteEvents: ", newDeleteEvents);
              dp.events.update();

            }
            //console.log("FINAL- newDeleteEvents: ", newDeleteEvents);
            this.setState({
              events: newDeleteEvents
            });
          })
          .catch(e => {
            console.log(e);
          });
      }, 1000);
    }
  }

  // This function is used to calculate the recurrence dates when user edits an event that was previously NOT recurring
  createRrule(response, descriptionObj) {
    let scheduled_date = response.results[0].scheduled_date;
    let scheduled_date_array = scheduled_date.split('-');
    //console.log("UPDATE DATE: ", scheduled_date.split('-'));

    let response_start_time = response.results[0].start_time;
    let start_time = response_start_time.toJSON().replace('.000Z', ''); // (changed 2022-04-14T13:30:00.000Z to 2022-04-14T13:30:00)
    let start_time_array = (start_time.split(' ')[1]).split(':'); // get time after T 
    //console.log("UPDATE TIME: ", (start_time.split('T')[1]).split(':')); 

    //console.log(scheduled_date_array[1]-1);
    //console.log(scheduled_date_array[2]+1);
    let rule = new RRule();
    if (descriptionObj['isRecurrable'] === 'weekly') {
      rule =
        new RRule({
          freq: RRule.WEEKLY,
          dtstart: new Date(Date.UTC(scheduled_date_array[0], scheduled_date_array[1] - 1, parseInt(scheduled_date_array[2]), start_time_array[0], start_time_array[1], start_time_array[2])),
          //tzid: 'America/Los_Angeles',
          count: 1040,
          interval: 1
        })
    } else if (descriptionObj['isRecurrable'] === 'monthly') {
      rule =
        new RRule({
          freq: RRule.MONTHLY,
          dtstart: new Date(Date.UTC(scheduled_date_array[0], scheduled_date_array[1] - 1, parseInt(scheduled_date_array[2]), start_time_array[0], start_time_array[1], start_time_array[2])),
          //tzid: 'America/Los_Angeles',
          count: 240,
          interval: 1
        })
    }

    return rule.all();
  }

  retrieveTechEvents(token, start_date, end_date) {
    return new Promise((resolve, reject) => {

      AssignTechsTicketsCalendarService.getTechEventsOnCalendar(token, start_date, end_date, this.props.loggedStatus.timezone_adjustment_factor)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        });
    });
  }

  retreiveUsers(token) {
    return new Promise((resolve, reject) => {

      //AssignTechsTicketsCalendarService.getUsers(token)
      AssignTechsTicketsCalendarService.getTechsForMyTicketsTableDropdown(this.props.loggedStatus.id, token)
        .then(response => {
          resolve(response.data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        });
    });
  }

  getEditableUser(event_id) {
    return new Promise((resolve, reject) => {
      AssignTechsTicketsCalendarService.getEditableUserIdRecurrence(event_id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken)
        .then(response => {
          //console.log("successfully received user id");
          resolve(response.data);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        })
    });
  }

  render() {

    if (!this.props.loggedStatus.loggedIn || !this.props.userPermissions.can_see_admin_portal) {
      return (
        <Redirect to='/' />
      );
    }

    const { ...config } = this.state;
    return (
      <>
        <Navbar pageTitle={"Tech Assignment"} />
        <div className="container mt-3" style={{ paddingTop: "60px", textAlign: "left" }}>
          <div style={styles.wrap}>
            <div style={styles.left}>
              <DayPilotNavigator
                selectMode={"week"}
                showMonths={3}
                skipMonths={3}
                startDate={DayPilot.Date.today()}     // expects string as "2022-12-05"
                selectionDay={config.selectionDay}  // expects string as "2022-12-05"
                onTimeRangeSelected={args => {
                  let dp = this.calendar;
                  //console.log("You clicked a calendar week this is the args stuff: ", args);
                  //console.log("Start date of week: ", args.start.value);
                  //console.log("End date of week: ", args.end.value);
                  this.setState({
                    startDate: args.day,
                    selectionDay: args.day
                  });
                  this.retrieveTechEvents(this.props.loggedStatus.accessToken, args.start.value, args.end.value).then(response => {
                    if (response.results.length !== 0) {

                      let tempEvents = [];
                      for (let i = 0; i < response.results.length; i++) {
                        tempEvents.push(
                          {
                            id: response.results[i].guid, // id made from divpilot guid
                            text: response.results[i].description_of_event,
                            start: response.results[i].start_time,
                            end: response.results[i].end_time,
                            table_id: response.results[i].id, // incremental id in backend mysql table
                            techId: response.results[i].tech_id,
                            scheduledDate: response.results[i].scheduled_date,
                            isRecurring: response.results[i].is_recurring,
                            groupId: response.results[i].group_id,  // id to group recurring events 
                          },
                        );
                      }
                      this.setState({
                        events: tempEvents,
                        startDateViewing: args.start.value,
                        endDateViewing: args.end.value
                      });
                      //console.log(this.state.events);
                      if (dp) {
                        dp.update();
                      }

                    }
                  })
                    .catch(e => {
                      console.log(e);
                    });
                }}
              />
            </div>
            <div style={styles.main}>
              <DayPilotCalendar
                {...config}
                ref={component => {
                  this.calendar = component && component.control;
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedStatus: state.logged,
    activeNavLinkStatus: state.activeNav,
    userPermissions: state.userPermissions
  };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_LINK, ACCESSTOKEN, LOGOUT })(Calendar);
