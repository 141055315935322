import {combineReducers} from 'redux';
import isLoggedReducer from './Is-Logged-Reducers';
import activeNavLinkReducer from './Active-Nav-Link-Reducers';
import userPermissionsReducer from './User-Permissions-Reducers';
import myAssignedTicketsReducer from './My-Assigned-Tickets-Reducers';
import reassignTechReducer from './Reassign-Tech-Reducers';
import hardwareAssetFilterReducer from './Hardware-Asset-Filter-Reducers';
import softwareAssetFilterReducer from './Software-Asset-Filter-Reducer';
import ticketRequesterReducer from './Ticket-Requester-Reducers';
import userListFilterReducer from './User-List-Filter-Reducers';
import reportsReducer from './Reports-Reducers';

export default combineReducers({
    logged: isLoggedReducer,
    activeNav: activeNavLinkReducer,
    userPermissions: userPermissionsReducer,
    myAssignedTickets: myAssignedTicketsReducer,
    reassignTech: reassignTechReducer,
    hardwareAssetFilter: hardwareAssetFilterReducer,
    softwareAssetFilter: softwareAssetFilterReducer,
    ticketRequester: ticketRequesterReducer,
    userListFilter: userListFilterReducer,
    reports: reportsReducer
});