const initialState = {
    timespan: [],        // Timespan for timespan section
    timespanType: "All", // Type of ticket to show in timespan section
    timespanAssignedTo: 0, // Tech assigned to selector in timespan section (starts with default id 0, which tells backend to ignore that filter)
};

function reportsReducer(state = initialState, action) {
    switch (action.type) {
        case "UPDATE_TIMESPAN":
            if (action.payload) {
                return {
                    ...state,
                    timespan: action.payload
                };
            }
            break;
        case "UPDATE_TIMESPAN_TYPE":
            if (action.payload) {
                return {
                    ...state,
                    timespanType: action.payload
                };
            }
            break;
        case "UPDATE_TIMESPAN_ASSIGNEDTO":
            if (action.payload !== null) {
                return {
                    ...state,
                    timespanAssignedTo: action.payload
                };
            }
            break;

        default:
            return state;


    }
}

export default reportsReducer;
