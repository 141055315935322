// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.View-Contracts-and-Software_rootActive__Xr9DO {
    background-color: #aaf0d1;
    font-size: 11pt;
}

.View-Contracts-and-Software_labelActive__oPOsl {
    color: green;
}


.View-Contracts-and-Software_rootInActive__H32U\\+ {
    background-color: #ff7f7f
}

.View-Contracts-and-Software_labelInActive__tgpii {
    color: #980001;
    font-size: 11pt;
}


.View-Contracts-and-Software_rootWarningZone__OWlvZ {
    background-color: #ffff66;
}

.View-Contracts-and-Software_labelWarningZone__TATjB {
    color: #ff6700;
    font-size: 11pt;
}`, "",{"version":3,"sources":["webpack://./src/components/Admin-Dashboard/View-Contracts-and-Software.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;;AAGA;IACI;AACJ;;AAEA;IACI,cAAc;IACd,eAAe;AACnB;;;AAGA;IACI,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,eAAe;AACnB","sourcesContent":[".rootActive {\n    background-color: #aaf0d1;\n    font-size: 11pt;\n}\n\n.labelActive {\n    color: green;\n}\n\n\n.rootInActive {\n    background-color: #ff7f7f\n}\n\n.labelInActive {\n    color: #980001;\n    font-size: 11pt;\n}\n\n\n.rootWarningZone {\n    background-color: #ffff66;\n}\n\n.labelWarningZone {\n    color: #ff6700;\n    font-size: 11pt;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rootActive": `View-Contracts-and-Software_rootActive__Xr9DO`,
	"labelActive": `View-Contracts-and-Software_labelActive__oPOsl`,
	"rootInActive": `View-Contracts-and-Software_rootInActive__H32U+`,
	"labelInActive": `View-Contracts-and-Software_labelInActive__tgpii`,
	"rootWarningZone": `View-Contracts-and-Software_rootWarningZone__OWlvZ`,
	"labelWarningZone": `View-Contracts-and-Software_labelWarningZone__TATjB`
};
export default ___CSS_LOADER_EXPORT___;
