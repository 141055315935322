export const UPDATE_SELECTED_TENANT_USER_LIST_FILTER = content => ({
    type: "UPDATE_SELECTED_TENANT_USER_LIST_FILTER",
    payload: content
});


export const UPDATE_SELECTED_ROLE_USER_LIST_FILTER = content => ({
    type: "UPDATE_SELECTED_ROLE_USER_LIST_FILTER",
    payload: content
});


export const UPDATE_SEARCH_TEXT_USER_LIST_FILTER = content => ({
    type: "UPDATE_SEARCH_TEXT_USER_LIST_FILTER",
    payload: content
});


export const RESET_USER_LIST_FILTERS = content => ({
    type: "RESET_USER_LIST_FILTERS",
    payload: content
});