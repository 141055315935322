export const UPDATE_SELECTED_WARRANTY_STATUS_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_WARRANTY_STATUS_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_EXPIRATION_START_DATE_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_EXPIRATION_START_DATE_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_EXPIRATION_END_DATE_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_EXPIRATION_END_DATE_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_LICENSE_STATUS_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_LICENSE_STATUS_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_LICENSE_EXPIRATION_START_DATE_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_LICENSE_EXPIRATION_START_DATE_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_LICENSE_EXPIRATION_END_DATE_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_LICENSE_EXPIRATION_END_DATE_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_HAS_DEVICES_REMAINING_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_HAS_DEVICES_REMAINING_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_TENANT_ID_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_TENANT_ID_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_ASSIGNED_TO_USER_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_ASSIGNED_TO_USER_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_ASSIGNED_TO_ASSET_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_ASSIGNED_TO_ASSET_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_LICENSE_NUMBER_SOFTWARE = content => ({
    type: "UPDATE_SELECTED_LICENSE_NUMBER_SOFTWARE",
    payload: content
});

export const UPDATE_SEARCH_TEXT_SOFTWARE = content => ({
    type: "UPDATE_SEARCH_TEXT_SOFTWARE",
    payload: content
});

export const UPDATE_SORT_BY_SOFTWARE = content => ({
    type: "UPDATE_SORT_BY_SOFTWARE",
    payload: content
});

export const UPDATE_SORT_ORDER_SOFTWARE = content => ({
    type: "UPDATE_SORT_ORDER_SOFTWARE",
    payload: content
});

export const UPDATE_SELECTED_CONTRACT_CUSTOMER = content => ({
    type: "UPDATE_SELECTED_CONTRACT_CUSTOMER",
    payload: content
});

export const UPDATE_SELECTED_CONTRACT_TYPE = content => ({
    type: "UPDATE_SELECTED_CONTRACT_TYPE",
    payload: content
});

export const UPDATE_SELECTED_CONTRACT_STATUS = content => ({
    type: "UPDATE_SELECTED_CONTRACT_STATUS",
    payload: content
});

export const UPDATE_SELECTED_CONTRACT_VENDOR = content => ({
    type: "UPDATE_SELECTED_CONTRACT_VENDOR",
    payload: content
});

export const UPDATE_SELECTED_CONTRACT_END_DATE = content => ({
    type: "UPDATE_SELECTED_CONTRACT_END_DATE",
    payload: content
});

export const UPDATE_CONTRACT_SEARCH_TEXT = content => ({
    type: "UPDATE_CONTRACT_SEARCH_TEXT",
    payload: content
});

export const UPDATE_SELECTED_SOFTWARE_CONTRACT_DATA_VIEW = content => ({
    type: "UPDATE_SELECTED_SOFTWARE_CONTRACT_DATA_VIEW",
    payload: content
});

export const UPDATE_CONTRACT_SORT_BY_AND_ORDER = content => ({
    type: "UPDATE_CONTRACT_SORT_BY_AND_ORDER",
    payload: content
});

export const UPDATE_SOFTWARE_ASSET_STATUS_FILTER = content => ({
    type: "UPDATE_SOFTWARE_ASSET_STATUS_FILTER",
    payload: content
});

export const UPDATE_SOFTWARE_ASSET_MANAGED_BY_FILTER = content => ({
    type: "UPDATE_SOFTWARE_ASSET_MANAGED_BY_FILTER",
    payload: content
});

export const UPDATE_SOFTWARE_ASSET_TYPE_FILTER = content => ({
    type: "UPDATE_SOFTWARE_ASSET_TYPE_FILTER",
    payload: content
});

export const UPDATE_SOFTWARE_PUBLISHER_FILTER = content => ({
    type: "UPDATE_SOFTWARE_PUBLISHER_FILTER",
    payload: content
});

export const UPDATE_SOFTWARE_ASSET_SEARCH_TEXT = content => ({
    type: "UPDATE_SOFTWARE_ASSET_SEARCH_TEXT",
    payload: content
});

export const UPDATE_SOFTWARE_ASSET_SORT_BY_AND_SORT_ORDER = content => ({
    type: "UPDATE_SOFTWARE_ASSET_SORT_BY_AND_SORT_ORDER",
    payload: content
});

export const RESET_CONTRACT_FILTERS = content => ({
    type: "RESET_CONTRACT_FILTERS"
});

export const RESET_SOFTWARE_ASSET_FILTERS = content => ({
    type: "RESET_SOFTWARE_ASSET_FILTERS"
});