import http from "../http-common";

class AccountDataService {
    getAll(token) {
        return http.get("/accounts", {headers: {Authorization: 'Bearer ' + token}});
    }

    create(data, token) {
        return http.post("/accounts", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    get(id, token) {
        return http.get(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    update(id, data, token) {
        return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    delete(id, token) {
        return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    findByName(name) {
        return http.get(`/accounts?name=${name}`);
    }

    // // Returns the bool value of if user wants to get emails or not
    // getEmailsStatus(user_id, token) {
    //     return http.get(`/accounts/${user_id}/emailStatus`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // // changes  disabling or enabling of emails based on user setting for email status for hardware tickets 
    // changeEmailsStatus(user_id, data, token) {
    //     return http.put(`/accounts/${user_id}/updateEmailStatus`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // // changes  disabling or enabling of emails based on user setting for email status for software tickets 
    // changeSoftwareEmailsStatus(user_id, data, token) {
    //     return http.put(`/accounts/${user_id}/updateSoftwareEmailStatus`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }
}


export default new AccountDataService();