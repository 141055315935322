import React, { useState, useEffect, useRef } from 'react';
import TaniumOSCount from './Widgets/Tanium/TaniumOSCount';
import TaniumLowDiskSpace from './Widgets/Tanium/TaniumLowDiskSpace';
import TaniumLastSeen from './Widgets/Tanium/TaniumLastSeen';
import TaniumAssetInfo from './Widgets/Tanium/TaniumAssetInfo';
import TicketsByMonth from './Widgets/Tickets/TicketsByMonth';
import TicketsByHour from './Widgets/Tickets/TicketsByHour';
import TicketsByDay from './Widgets/Tickets/TicketsByDay';
import SLAPerformance from './Widgets/Tickets/SLAPerformance';
import dashboardActivities from '../../services/dashboard-activities';
import { useSelector /*, useDispatch*/ } from 'react-redux';
import { Switch, Paper, Button } from '@mantine/core';

const gridGap = 5;

const itemWidth = 160;
const itemHeight = 120;
const defaultWidgets = [
    {
        pos: { x: 0, y: 0 },
        size: { x: 3, y: 2 },
        dataType: "taniumOSCount",
        graphType: "line"
    },
    {
        pos: { x: 160 * 3, y: 0 },
        size: { x: 3, y: 1 },
        dataType: "taniumLowDiskSpace",
        graphType: "line"
    },
    {
        pos: { x: 160 * 3, y: 0 },
        size: { x: 3, y: 1 },
        dataType: "taniumLastSeen",
        graphType: "line"
    },
    {
        pos: { x: 0, y: 0 },
        size: { x: 6, y: 6 },
        dataType: "taniumAssetInfo",
        graphType: "line"
    }
];

const SortableItem = (props) => {

    var widget = null;
    if (props.widget.dataType == "taniumOSCount") {
        widget = <TaniumOSCount {...props} />;
    } else if (props.widget.dataType == "taniumLowDiskSpace") {
        widget = <TaniumLowDiskSpace {...props} />;
    } else if (props.widget.dataType == "taniumLastSeen") {
        widget = <TaniumLastSeen {...props} />;
    } else if (props.widget.dataType == "taniumAssetInfo") {
        widget = <TaniumAssetInfo {...props} />;
    } else if (props.widget.dataType == "ticketsByMonth") {
        widget = <TicketsByMonth {...props} />;
    } else if (props.widget.dataType == "ticketsByDay") {
        widget = <TicketsByDay {...props} />;
    } else if (props.widget.dataType == "ticketsByHour") {
        widget = <TicketsByHour {...props} />;
    } else if (props.widget.dataType == "SLAPerformance") {
        widget = <SLAPerformance {...props} />;
    } else {
        return <></>;
    };

    if (props.editModeEnabled) {
        widget = <Paper withBorder radius="md" h="100%" w="100%" style={{ verticalAlign: "top" }}>
            {props.widget.dataType}
            <Button pos="absolute" className="dashboardResizeButton" bottom={0} right={0} size="xs" style={{ cursor: "nw-resize" }} />
        </Paper>;
    }

    return <div
        style={{
            position: 'absolute',
            transformOrigin: '50% 50%',
            transform: `translate(${props.widget.pos.x - gridGap + props.xOffset}px,${props.widget.pos.y - gridGap}px)`,
            transitionDuration: `${props.beingDragged ? 0 : 100}ms`,
            width: `${props.widget.size.x * itemWidth}px`,
            padding: `${gridGap}px`,
            height: `${props.widget.size.y * itemHeight}px`,
            userSelect: `${props.beingDragged ? "none" : "auto"}`,
            zIndex: `${props.beingDragged ? 2 : 0}`
        }}
        dashboardDragId={props.dashboardDragId}
    >
        {widget}
    </div>
}

const DashboardGrid = (props) => {
    const mousePosition = useRef({ x: null, y: null });

    const username = useSelector((state) => state.logged.username);
    const askerId = useSelector((state) => state.logged.id);

    const divRef = useRef(null);

    const [startDragPosMouse, setStartDragPosMouse] = useState({ x: 0, y: 0 });
    const [startDragPosItem, setStartDragPosItem] = useState({ x: 0, y: 0 });
    const [startDragSizeItem, setStartDragSizeItem] = useState({ x: 0, y: 0 });
    const [xOffset, setXOffset] = useState(0);

    const [widgets, setWidgets] = useState([]);

    const [resizing, setResizing] = useState(false);

    const [beingDraggedId, setBeingDraggedId] = useState(null);

    const [editModeEnabled, setEditModeEnabled] = useState(false);

    // 2D AABB collision detection
    const solveCollision = (priorityIndex, lastMoved, edit) => {
        for (var i = 0; i < edit.length; i++) {
            for (var j = 0; j < edit.length; j++) {
                if (i == j) break;
                if (
                    edit[i].pos.x < edit[j].pos.x + (edit[j].size.x * itemWidth) &&
                    edit[i].pos.x + (edit[i].size.x * itemWidth) > edit[j].pos.x &&
                    edit[i].pos.y < edit[j].pos.y + (edit[j].size.y * itemHeight) &&
                    edit[i].pos.y + (edit[i].size.y * itemHeight) > edit[j].pos.y
                ) {
                    if (i != priorityIndex) {
                        edit[i].pos.y += (edit[j].pos.y + (edit[j].size.y * itemHeight)) - edit[i].pos.y;
                        return { collided: true, movedId: i };
                    } else {
                        edit[j].pos.y += (edit[i].pos.y + (edit[i].size.y * itemHeight)) - edit[j].pos.y;
                        return { collided: true, movedId: j };
                    }
                }
            }
        }
        return { collided: false }
    }

    const solveGrid = (widgetsReference, priorityIndex) => {
        var minX = 10000;
        var maxX = 0;
        for (var i = 0; i < widgetsReference.length; i++) {
            widgetsReference[i].pos.x = Math.round(widgetsReference[i].pos.x / itemWidth) * itemWidth;
            widgetsReference[i].pos.y = Math.round(widgetsReference[i].pos.y / itemHeight) * itemHeight;

            const minxPos = widgetsReference[i].pos.x;
            const maxxPos = minxPos + (widgetsReference[i].size.x * itemWidth);
            if (minxPos < minX) minX = minxPos;
            if (maxxPos > maxX) maxX = maxxPos;
        }
        const widthDifference = divRef.current.offsetWidth - (maxX - minX);
        console.log((maxX - minX));
        setXOffset((widthDifference / 2) - minX);
        var lastMoved = -1;
        do {
            var collision = solveCollision(priorityIndex, lastMoved, widgetsReference);
            if (collision.collided) lastMoved = collision.movedId;
            else lastMoved = -1;
        } while (collision.collided);
        console.log(widgetsReference);

        return widgetsReference;
    }

    useEffect(() => {
        props.onAccessTokenRequest(username).then((token) => {
            dashboardActivities.getWidgets(token, askerId, props.dashboardName).then(response => {
                if (response.data.results && response.data.results.length > 40) {
                    const receivedWidgets = [];
                    for (var i = 0; i < response.data.results.length; i++) {
                        const w = response.data.results[i];
                        receivedWidgets.push({ pos: { x: w.posX, y: w.posY }, size: { x: w.sizeX, y: w.sizeY }, dataType: w.widgetName, graphType: w.graphType });
                    }
                    setWidgets(solveGrid(receivedWidgets));
                } else {
                    console.log("setting default dashboard");
                    setWidgets(solveGrid(props.defaultWidgets ? props.defaultWidgets : defaultWidgets));
                    props.onAccessTokenRequest(username).then((token) => {
                        dashboardActivities.setWidgets(token, askerId, defaultWidgets, props.dashboardName).then(response => {
                            console.log(response);
                        });
                    });
                }
            });
        });
    }, [props.dashboardName]);

    useEffect(() => {
        const mouseMoveHandler = ev => {
            mousePosition.current = { x: ev.clientX, y: ev.clientY };
            if (beingDraggedId != null) {
                if (!resizing) {
                    const movement = { x: mousePosition.current.x - startDragPosMouse.x, y: mousePosition.current.y - startDragPosMouse.y };
                    const edit = [...widgets];
                    edit[beingDraggedId].pos = { x: startDragPosItem.x + movement.x, y: startDragPosItem.y + movement.y }
                    setWidgets(edit); // dont save any changes here because this is repeatedly fired while dragging stuff around
                } else {
                    const movement = { x: mousePosition.current.x - startDragPosMouse.x, y: mousePosition.current.y - startDragPosMouse.y };
                    const desiredSize = { x: (movement.x / itemWidth) + startDragSizeItem.x, y: (movement.y / itemHeight) + startDragSizeItem.y }

                    const edit = [...widgets];
                    edit[beingDraggedId].size = { x: Math.round(desiredSize.x), y: Math.round(desiredSize.y) }
                    setWidgets(edit); // dont save any changes here because this is repeatedly fired while dragging stuff around
                }
            }
        };
        const mouseDownHandler = e => {
            if (editModeEnabled) {
                var target = e.target;

                if (target != null) {
                    // loop through parents looking for one with the "dragId" attribute
                    var resize = false;
                    do {
                        if (target.className.includes("dashboardResizeButton")) {
                            resize = true;
                        }
                        const id = target.attributes?.dashboarddragid?.value;
                        if (id != null && id < widgets.length) {
                            setStartDragPosMouse({ x: mousePosition.current.x, y: mousePosition.current.y });
                            setStartDragPosItem({ x: widgets[id].pos.x, y: widgets[id].pos.y });
                            setStartDragSizeItem(widgets[id].size);
                            setResizing(resize);
                            setBeingDraggedId(id);
                            break;
                        }
                        target = target.parentElement
                    } while (target.parentElement !== null);
                }
            }
        }
        const mouseUpHandler = e => {
            if (beingDraggedId != null) {
                setBeingDraggedId(null);
                if (!resizing) {
                    const movement = { x: mousePosition.current.x - startDragPosMouse.x, y: mousePosition.current.y - startDragPosMouse.y };
                    const edit = [...widgets];
                    edit[beingDraggedId].pos = { x: startDragPosItem.x + movement.x, y: startDragPosItem.y + movement.y }
                    setWidgets(solveGrid(edit, beingDraggedId));
                    props.onAccessTokenRequest(username).then((token) => {
                        dashboardActivities.setWidgets(token, askerId, edit, props.dashboardName).then(response => {
                            console.log(response);
                        });
                    });
                } else {
                    const movement = { x: mousePosition.current.x - startDragPosMouse.x, y: mousePosition.current.y - startDragPosMouse.y };
                    const desiredSize = { x: (movement.x / itemWidth) + startDragSizeItem.x, y: (movement.y / itemHeight) + startDragSizeItem.y }
                    const edit = [...widgets];
                    edit[beingDraggedId].size = { x: Math.round(desiredSize.x), y: Math.round(desiredSize.y) }
                    setWidgets(solveGrid(edit, beingDraggedId));
                    props.onAccessTokenRequest(username).then((token) => {
                        dashboardActivities.setWidgets(token, askerId, edit, props.dashboardName).then(response => {
                            console.log(response);
                        });
                    });
                }
            }
        }
        window.addEventListener('mousedown', mouseDownHandler);
        window.addEventListener('mouseup', mouseUpHandler);
        window.addEventListener('mousemove', mouseMoveHandler);
        return () => {
            window.removeEventListener('mousedown', mouseDownHandler);
            window.removeEventListener('mouseup', mouseUpHandler);
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, [widgets, startDragPosMouse, startDragPosItem, beingDraggedId, editModeEnabled, xOffset]);

    return (
        <>
            {/*<Switch onChange={(event) => setEditModeEnabled(!editModeEnabled)} />*/}
            <div ref={divRef}>
                {widgets.map((e, index) => (
                    <SortableItem
                        widget={e}
                        dashboardDragId={index}
                        beingDragged={beingDraggedId == index ? true : false}
                        xOffset={xOffset}
                        editModeEnabled={editModeEnabled}
                        {...props}
                    />
                ))}
            </div>
        </>
    );
};

export default DashboardGrid;
