import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Grid,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Paper,
    Pill,
    Modal,
    Select,
    Switch,
    Tooltip,
    Flex,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import contractClasses from './View-Contracts-and-Software.module.css';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconMenu2, IconFilterX, IconEdit, IconX } from '@tabler/icons-react';

import Navbar from '../../components/Navbar';
//import classes from '../TableSort.module.css';

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
//import Modal from 'react-bootstrap/Modal';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import { faSearch, faFilter, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
//import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
//import { Form } from "react-bootstrap";
//import Form from 'react-bootstrap/Form';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

//import HardwareAssetDataService from '../../services/hardware-asset-activities';
import SoftwareAssetDataService from '../../services/software-asset-activities';

class ViewSpecificContract extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contract: {
                /* id: 1,
                customer_id: 2,
                customer_name: "LPA",
                contract_name: "Test Contract",
                description: "Test Description",
                customer_id_number_csn: "123ert",
                serial_number: "abcdefg",
                contract_number: "CNT-9",
                vendor_id: 2,
                vendor_name: "Test Vendor",
                contract_type: "Software License",
                contract_status: "Active",
                cost: 25.99,
                approver_id: 2373,
                approver_name: "Bob Johnson",
                start_date: "2024-03-25",
                end_date: "2025-03-25",
                software_id: 3,
                software_name: "Microsoft 365",
                service_name: "Test Service",
                pricing_model: "Fixed",
                count: 3,
                billing_cycle: "Annual",
                license_type: "Test",
                license_key: "Test Key",
                renewal_status: "Complete",
                managing_tenant_id: 2,
                days_left: 30 */
            },

            greenZoneDays: 90,      // if this number of days or above, display the expiry date in green
            redZoneDays: 60,        // if this number of days or less, display the expiry date in red (everything in between values is warning zone orange)


            show_delete_modal: false
        }
    }

    componentDidMount() {

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    SoftwareAssetDataService.getSpecificContract(token, this.props.loggedStatus.id, this.props.match.params.id).then(response => {
                        console.log(response);
                        if(response.data.results && response.data.results.length > 0) {
                            this.setState({
                                contract: response.data.results[0]
                            });
                        }
                    });
                }
            });
        }
    }


    openDeleteModal() {
        this.setState({
            show_delete_modal: true
        });
    }

    closeDeleteModal() {
        this.setState({
            show_delete_modal: false
        })
    }

    deleteContract() {
        SoftwareAssetDataService.deleteContract(this.props.loggedStatus.accessToken, this.props.match.params.id, this.props.loggedStatus.id, this.state.contract.managing_tenant_id).then(response => {
            // after deleting the contract, navigate back to the software assets list screen
            this.props.history.push(`/softwareAssets`);
        });
    }

    navigateToEditScreen() {
        this.props.history.push(`/editContract/${this.props.match.params.id}`);
    }


    render() {
        if (!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_assets /* && !this.props.userPermissions.can_edit_assets && !this.props.userPermissions.can_add_assets && !this.props.userPermissions.can_delete_assets */)) {
            return (
                <Redirect to='/' />
            );
        }

        return (
            <>
                <Navbar pageTitle={`Contract Details - ${this.state.contract.contract_name}`} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                <Container fluid pt="20px" >
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Grid>
                            <Grid.Col span={6}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    {/* <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Name</Table.Td>
                                        <Table.Td c="blue">Test Contract</Table.Td>
                                    </Table.Tr> */}
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Status</Table.Td>
                                        <Table.Td><Pill className={this.state.contract.contract_status === "Active" ? [contractClasses.rootActive, contractClasses.labelActive] : [contractClasses.rootInActive, contractClasses.labelInActive]}>{this.state.contract.contract_status}</Pill></Table.Td>
                                    </Table.Tr>
                                    {/* <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Type</Table.Td>
                                        <Table.Td c="blue">Software License</Table.Td>
                                    </Table.Tr> */}
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Date of Expiry</Table.Td>
                                            {this.state.contract.days_left >= this.state.greenZoneDays &&
                                                <Table.Td style={{cursor: "pointer", color: "green"}}>
                                                    {this.state.contract.end_date !== null ? 
                                                        <Pill className={[contractClasses.rootActive, contractClasses.labelActive]}>{this.state.contract.end_date}</Pill>
                                                        :
                                                        undefined
                                                    }
                                                </Table.Td>
                                            }
                                            {this.state.contract.days_left < this.state.greenZoneDays && this.state.contract.days_left > this.state.redZoneDays && 
                                                <Table.Td style={{cursor: "pointer", color: "orange"}}>
                                                    {this.state.contract.end_date !== null ? 
                                                        <Pill className={[contractClasses.rootWarningZone, contractClasses.labelWarningZone]}>{this.state.contract.end_date}</Pill>
                                                        :
                                                        undefined
                                                    }
                                                </Table.Td>
                                            }
                                            {this.state.contract.days_left <= this.state.redZoneDays && 
                                                <Table.Td style={{cursor: "pointer", color: "red"}}>
                                                    {this.state.contract.end_date !== null ? 
                                                        <Pill className={[contractClasses.rootInActive, contractClasses.labelInActive]}>{this.state.contract.end_date}</Pill>
                                                        :
                                                        undefined
                                                    }
                                                </Table.Td>
                                            }
                                    </Table.Tr>
                                    {/* <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Cost</Table.Td>
                                        <Table.Td c="blue">$25.00</Table.Td>
                                    </Table.Tr> */}
                                </Table>
                            </Grid.Col>
                            <Grid.Col span={6} align="right">
                                <Group justify="flex-end" gap="xl">
                                    {this.props.userPermissions.can_edit_assets &&
                                        <Button onClick={() => this.navigateToEditScreen()}><IconEdit/></Button>
                                    }
                                    {this.props.userPermissions.can_delete_assets &&
                                        <Button color="red" onClick={() => this.openDeleteModal()}><IconX/></Button>
                                    }
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <div style={{marginTop: "20px"}}>
                            <Grid>
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Basic Info</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Name</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_name !== null ? this.state.contract.contract_name : "-"}</Table.Td>   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Company Name</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.customer_name}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Number</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_number !== null ? this.state.contract.contract_number : "-"}</Table.Td>                                                   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Software</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.software_name !== null ? this.state.contract.software_name : "-"}</Table.Td>    
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Vendor</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.vendor_name !== null ? this.state.contract.vendor_name : "-"}</Table.Td>  
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Type</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_type !== null ? this.state.contract.contract_type : "-"}</Table.Td>  
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Contract Status</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.contract_status !== null ? this.state.contract.contract_status : "-"}</Table.Td>   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>Start Date</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.start_date !== null ? this.state.contract.start_date : "-"}</Table.Td>   
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "35%"}}>End Date</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.end_date !== null ? this.state.contract.end_date : "-"}</Table.Td>                                                      
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                            
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Pricing and Renewal</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Cost</Table.Td>                                     
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.cost !== null ? `$ ${this.state.contract.cost}` : "-"}</Table.Td>                                                       
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Pricing Model</Table.Td>                                     
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.pricing_model !== null ? this.state.contract.pricing_model : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Billing Cycle</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.billing_cycle !== null ? this.state.contract.billing_cycle : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Renewal Status</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.renewal_status !== null ? this.state.contract.renewal_status : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Term</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.term !== null ? this.state.contract.term : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>{`Cancel Window`}</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.cancel_window !== null ? `${this.state.contract.cancel_window} days` : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>{`Remind Me`}</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.remind_me !== null ? `${this.state.contract.remind_me} days` : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Turn Notifications Red</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.red_window !== null ? `${this.state.contract.red_window} days`: "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Notifications Disabled</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.is_resolved === 0 ? "No" : "Yes"}</Table.Td>
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                            
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>License</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>License Type</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.license_type !== null ? this.state.contract.license_type : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>License Key</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.license_key !== null ? this.state.contract.license_key : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Count</Table.Td>                                                    
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.count !== null ? this.state.contract.count : "-"}</Table.Td>                                                        
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                                <Grid.Col span={3}>
                                    <Paper withBorder p="md" radius="md" display="block" m="5px" h="400px">
                                        <Text td="underline" style={{textAlign: "center", cursor: "default", marginBottom: "20px"}}>Misc</Text>
                                        <Table withRowBorders={false} style={{cursor: "default"}}>
                                            <Table.Tbody>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>{"Customer Id Number"}</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.customer_id_number_csn !== null ? this.state.contract.customer_id_number_csn : "-"}</Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Serial Number</Table.Td>                                                
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.serial_number !== null ? this.state.contract.serial_number : "-"}</Table.Td>                                                       
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Approver</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">
                                                        {this.state.contract.approver_id !== null ? 
                                                            this.state.contract.approver_middle_name === null ?
                                                                `${this.state.contract.approver_first_name} ${this.state.contract.approver_last_name}`
                                                                :
                                                                `${this.state.contract.approver_first_name} ${this.state.contract.approver_middle_name} ${this.state.contract.approver_last_name}`
                                                            : 
                                                            "-"
                                                        }
                                                    </Table.Td>
                                                </Table.Tr>
                                                <Table.Tr>
                                                    <Table.Td style={{width: "50%"}}>Service Name</Table.Td>
                                                    <Table.Td className="roleYesText" align="right">{this.state.contract.service_name !== null ? this.state.contract.service_name : "-"}</Table.Td>
                                                </Table.Tr>
                                            </Table.Tbody>
                                        </Table>
                                    </Paper>
                                </Grid.Col>
                            </Grid>
                        </div>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Text td="underline" style={{cursor: "default", marginBottom: "10px"}}>Description</Text>
                        <div className="roleYesText" style={{cursor: "default"}}>{this.state.contract.description}</div>
                    </Paper>
                </Container>

                <Modal
                    opened={this.state.show_delete_modal}
                    onClose={() => this.closeDeleteModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Are you sure you want to delete this contract?</Text></div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeDeleteModal()}>Cancel</Button></span>
                        <span><Button color="red" onClick={() => this.deleteContract()}>Delete</Button></span>
                    </div>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        softwareAssetFilter: state.softwareAssetFilter
    };
}


export default connect(mapStateToProps, {
    UPDATE_ACTIVE_LINK,
    ACCESSTOKEN,
    LOGOUT
})(ViewSpecificContract);