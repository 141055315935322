import React, { Component } from "react";
import SoftwareTicketDataService from "../services/software-ticket-activities";

import TicketCategoryDataService from "../services/ticket-category-activities";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import {Link} from 'react-router-dom';

import "bootstrap/dist/css/bootstrap.min.css";

import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";


class SoftwareTicketsLandingPage extends Component {
    _scroll = false;

    constructor(props) {
        super(props);
        this.retrieveTickets = this.retrieveTickets.bind(this);
        this.retrieveFilteredTickets = this.retrieveFilteredTickets.bind(this);
        this.retrieveTicketCategories = this.retrieveTicketCategories.bind(this);
        this.updateSelectedCategoryValue = this.updateSelectedCategoryValue.bind(this);
        this.searchKnowledgebaseByCategory = this.searchKnowledgebaseByCategory.bind(this);
        this.onChangeSearchText = this.onChangeSearchText.bind(this);
        this.handleColumnSort = this.handleColumnSort.bind(this);
        this.goToSpecificTicket = this.goToSpecificTicket.bind(this);

        this.setTicketTimesSinceCreated = this.setTicketTimesSinceCreated.bind(this);
        this.createTicketsToDisplay = this.createTicketsToDisplay.bind(this);

        this.resetTicketOffset = this.resetTicketOffset.bind(this);
        this.getSortedTickets = this.getSortedTickets.bind(this);
        

        this.state = {
            tickets: [],
            rowCount: 0,    // The TOTAL number of tickets that could be displayed based on current search params
            ticketSortOrder: "",    // will be either asc or desc (will pass directly to backend sql to be part of query for sorting)
            sortTicketsBy: "",      // will contain the name of the sql table column we plan to sort by (will pass directly to backend sql to be part of query for sorting)
            searchText: "",
            selectedFilterTicketCategory: "",
            selectedFilterTicketCategoryID: 0,
            categories: [],
            typingTimeout: 0,
            resetOffsetTimeout: 0,  // handles the delay in changing ticket offset to 0 while user is typing
            scrolled: false,
            ticketsToDisplay: [],
            ticketOffsetValue: 0,   // the value passed to database to know how many records we've already retrieved by scrolling (increments by 10 each database call)
            numberOfRecordsToShow: 10,   // Will increment by 10 each time user scrolls to bottom of page
            allTicketsShown: false,  // Controls whether to ask for more records
            currentForumColumnSorting: ""    // Controls what forum table header is highlighted. changed onclick.
            //currentTicket: null,
            //currentIndex: -1,
            //searchName: ""
        };
    }

    componentDidMount() {           // token passed into props from the App.js file
        
        if(this.props.loggedStatus.loggedIn){
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    this.retrieveTickets(token, this.state.ticketOffsetValue, "id", "desc").then(response => {
                        this.setState({
                            tickets: response.results,     // the first 10 tickets returned
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: this.state.ticketOffsetValue + 10,
                            selectedFilterTicketCategory: "All Categories",
                            ticketSortOrder: "desc",    // we start by ordering in descending order (newest tickets first)
                            sortTicketsBy: "id",    // we start by ordering the id column of table (reflects time created)
                            currentForumColumnSorting: "date"   // start with "Created" column highlighted
                        });

                        this.retrieveTicketCategories(token);
                        setTimeout(() => {
                            let ticketsToDisplayTemp = [];
                            for(let i =0; i < this.state.numberOfRecordsToShow; i++) {
                                if(this.state.tickets && this.state.tickets.length > 0 && i <= this.state.tickets.length) { // make sure we're asking for a valid ticket
                                    ticketsToDisplayTemp.push(this.state.tickets[i]);
                                }
                            }

                            this.setState({ticketsToDisplay: ticketsToDisplayTemp});
                        }, 1000);
                    })
                }

            });
        

            this.props.UPDATE_ACTIVE_LINK("softwareTickets");
 
            window.addEventListener('scroll', this.handleScroll);
        }
        

    }

    componentWillUnmount() {  
        window.removeEventListener('scroll', this.handleScroll);
    }

    setTicketTimesSinceCreated(tickets) {
        for(var item of tickets) {
            if(item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? "y ago" : "y ago");
            }

            else if(item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? "mon ago" : "mon ago");
            }

            else if(item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? "d ago" : "d ago");
            }

            else if(item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? "h ago" : "h ago");
            }

            else if(item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? "min ago" : "min ago");
            }

            else {
                item.time_since_sent = "moments ago";
            }
        }
    }

    goToSpecificTicket(id) {   // Travel to the specific ticket's information
        this.props.history.push({
            pathname: "/softwareTickets/" + id,
            state: {
                accessToken: this.props.loggedStatus.accessToken,
                loggedIn: this.props.loggedStatus.loggedIn
            }
        })
    }

     // this function gets called within handle column sort. based on updated state values, it gets the new ticket data in the new sorted order
    getSortedTickets() {
        // Now that state is correctly set, retrieve tickets using new search criteria
        if(this.state.searchText === "") {   // User changed category filter but had no search text

            if(this.state.selectedFilterTicketCategory === "All Categories") {
                this.retrieveTickets(this.props.loggedStatus.accessToken, 0, this.state.sortTicketsBy, this.state.ticketSortOrder).then(response => { // ticket offset manual 0 since this is a chang to search criteria
                    this.setState({
                        tickets: response.results,     // the first 10 tickets returned
                        rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                        ticketOffsetValue: 10
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            }

            else {  // Category selected was anything but All Categories and they had no search text (offset manual 0 since this is a change to search criteria)
                this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {
                    this.setState({
                        tickets: response.results,     // the first 10 tickets returned
                        rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                        ticketOffsetValue: 10
                    });
                })
                .catch(e => {
                    console.log(e);
                });
            }

        }

        else {
            if(this.state.selectedFilterTicketCategory === "All Categories") {  // User had search text but clicked All Categories - send 0 for offset to reset the list of tickets returned
                this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
            }

            else {  // User had search text AND picked a specific category
                this.searchKnowledgebaseByCategory(this.state.searchText, 0, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
            }
        }
    }

    // every time user clicks column header, it changes search criteria so resets ticket offset
    // flips asc to desc and v.v.
    // sets the name of the column sorted (will use the name of the ticket table column we will sort on to put directly into query)

    handleColumnSort(columnToSort) {
        if(this.state.ticketSortOrder === "desc") {     // This will put the title column in ascending order
            switch(columnToSort) {
                case "title":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "title",
                            sortTicketsBy: "ticket_title"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "asker":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "asker",
                            sortTicketsBy: "asker_first_name"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "votes":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "votes",
                            sortTicketsBy: "up_votes"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "views":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "views",
                            sortTicketsBy: "total_clicks_all_time"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "date":
                    this.setState(
                        {
                            ticketSortOrder: "asc",
                            currentForumColumnSorting: "date",
                            sortTicketsBy: "id"
                        },
                        this.getSortedTickets
                    );
                    break;
                default:
                    console.log("Bad Column Name Given");
            }
            
        }

        else if(this.state.ticketSortOrder === "asc") { // This will put the title column in descending order
            switch(columnToSort) {
                case "title":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "title",
                            sortTicketsBy: "ticket_title"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "asker":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "asker",
                            sortTicketsBy: "asker_first_name"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "votes":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "votes",
                            sortTicketsBy: "up_votes"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "views":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "views",
                            sortTicketsBy: "total_clicks_all_time"
                        },
                        this.getSortedTickets
                    );
                    break;
                case "date":
                    this.setState(
                        {
                            ticketSortOrder: "desc",
                            currentForumColumnSorting: "date",
                            sortTicketsBy: "id"
                        },
                        this.getSortedTickets
                    );
                    break;
                default:
                    console.log("Bad Column Name Given");
            }
            
        }
    }

    // gets called after 1.5 second timeout after user types something into the search bar
    // since search criteria has changed, user gets a new list with a starting offset value
    resetTicketOffset() {
        this.setState({
            ticketOffsetValue: 0
        });
    }

    // using arrow notation in onChangeSearchText so we can reference this.state within the internal timeout function call
    // function will execute a search after user has stopped typing for 1.5 seconds
    onChangeSearchText = (e) => {

        if(this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        if(this.state.resetOffsetTimeout) {
            clearTimeout(this.state.resetOffsetTimeout);
        }


        this.setState({
            searchText: e.target.value,
            resetOffsetTimeout: setTimeout( () => {
                this.resetTicketOffset();   // reset the ticket offset in state back to 0 (they have changed their search criteria so new list)
            }, 1500),
            typingTimeout: setTimeout( () => {
                if(this.state.selectedFilterTicketCategory === "All Categories"){    // user has no category selected but is searching        
                    this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);    // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                }
        
                else if(this.state.searchText === "") {    // User emptied search bar, but still has category selected
                    this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {  // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                        this.setState({     // save the new tickets and inform state that we asked for first 10
                            tickets: response.results,
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: 10
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
        
                else {  // User has both category and search bar letters
                    this.searchKnowledgebaseByCategory(this.state.searchText, 0, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken); // we know offset is 0 so manually sending through (when they change search criteria, the list of tickets shown resets)
                }
            }, 1500)
        });

    }

    retrieveTickets(token, ticketOffsetValue, sortBy, sortOrder) {
        return new Promise ((resolve, reject) => {
            SoftwareTicketDataService.getAll(token, ticketOffsetValue, sortBy, sortOrder, this.props.loggedStatus.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                console.log(e);
                reject(e);
            });
        });
    }

    retrieveFilteredTickets(categoryID, ticketOffsetValue, sortTicketsBy, ticketSortOrder, token) {
        return new Promise ((resolve, reject) => {
            SoftwareTicketDataService.filterByCategory(categoryID, ticketOffsetValue, sortTicketsBy, ticketSortOrder, token, this.props.loggedStatus.id)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                console.log(e);
                reject(e);
            });
        });
        
    }

    // Saying we want software categories by sending in type = software
    retrieveTicketCategories(token) {
        TicketCategoryDataService.getAll("software", token)
        .then(response => {
            this.setState({
                categories: response.data.results
            });
        })
        .catch(e => {
            console.log(e);
        });
    }


    searchKnowledgebase(searchText, ticketOffset, sortTicketsBy, ticketSortOrder, token) {
        SoftwareTicketDataService.findByText(searchText, ticketOffset, sortTicketsBy, ticketSortOrder, token, this.props.loggedStatus.id)
        .then(response => {
            if(ticketOffset === 0) {    // this was the first search, so results returned are complete package and grab the table count
                this.setState({
                    tickets: response.data.results,
                    rowCount: response.data.row_count,
                    ticketOffsetValue: 10   // we pulled the first 10 tickets after returning the response back so update value
                });

            }

            else {      // we got here from a scroll, so add these new tickets to the end of the current ticket array (NOTE: offset update is handed by scroll fxn)
                this.setState(prevState => ({
                    tickets: [...prevState.tickets, ...response.data.results]
                }));
            }
            
        })
        .catch(e => {
            console.log(e);
        });
    }

    searchKnowledgebaseByCategory(searchText, ticketOffset, categoryName, sortTicketsBy, ticketSortOrder, token) {
        SoftwareTicketDataService.filterByCategoryAndSearchText(categoryName, ticketOffset, searchText, sortTicketsBy, ticketSortOrder, token, this.props.loggedStatus.id)
        .then(response => {
            if(ticketOffset === 0) {    // this was the first search, so results returned are complete package and grab the table count
                this.setState({
                    tickets: response.data.results,
                    rowCount: response.data.row_count,
                    ticketOffsetValue: 10   // we pulled the first 10 tickets after returning the response back so update value
                });
            }

            else {      // we got here from a scroll, so add these new tickets to the end of the current ticket array (NOTE: offset update is handed by scroll fxn)
                this.setState(prevState => ({
                    tickets: [...prevState.tickets, ...response.data.results]
                }));
            }
        })
        .catch(e => {
            console.log(e);
        });
    }

    updateSelectedCategoryValue(categoryID, newValue) { // User clicks new category filter
        // if they have picked a different category than current one selected, run this code
        if(categoryID !== this.state.selectedFilterTicketCategoryID) {
            this.setState({
                selectedFilterTicketCategory: newValue,
                selectedFilterTicketCategoryID: categoryID,
                ticketOffsetValue: 0        // reset ticket offset value since we'll have a new list
            });
        

            if(this.state.searchText === "") {   // User changed category filter but had no search text
            
                if(newValue === "All Categories") {
                    this.retrieveTickets(this.props.loggedStatus.accessToken, 0, this.state.sortTicketsBy, this.state.ticketSortOrder).then(response => { // ticket offset manual 0 since this is a chang to search criteria
                        this.setState({
                            tickets: response.results,     // the first 10 tickets returned
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: 10
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }

                else {  // Category selected was anything but All Categories and they had no search text (offset manual 0 since this is a change to search criteria)
                    this.retrieveFilteredTickets(categoryID, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {
                        this.setState({
                            tickets: response.results,     // the first 10 tickets returned
                            rowCount: response.row_count,   // TOTAL number of tickets possible for those search params
                            ticketOffsetValue: 10
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }

            }

            else {
                if(newValue === "All Categories") {  // User had search text but clicked All Categories - send 0 for offset to reset the list of tickets returned
                    this.searchKnowledgebase(this.state.searchText, 0, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                }

                else {  // User had search text AND picked a specific category
                    this.searchKnowledgebaseByCategory(this.state.searchText, 0, newValue, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                }
            }
        }
    }

    createTicketsToDisplay() {
        let ticketsToDisplayTemp = [];

        for(let i =0; i < this.state.tickets.length; i++) {
            if(this.state.tickets.length > 0 && i < this.state.tickets.length) { // make sure we're asking for a valid ticket
                ticketsToDisplayTemp.push(this.state.tickets[i]);
            }
        }


        const tableRows = ticketsToDisplayTemp.map((ticket) => {
            return (
                <tr key={ticket.id}>
                    <td style={{width: "60%", cursor: "default"}}>
                        {ticket.resolution_status === "Resolved" ? 
                        <div className="ticketTitleText gothamNarrowFont"><Link to={`/softwareTickets/${ticket.id}`} className="ticketTitleText">{ticket.ticket_title}</Link> <FontAwesomeIcon style={{color: "green"}} icon={faCheckCircle} /></div> : 
                        <div className="ticketTitleText gothamNarrowFont"><Link to={`/softwareTickets/${ticket.id}`} className="ticketTitleText">{ticket.ticket_title}</Link></div>}
                        <div className="ticketCategoryName gothamNarrowFont">{ticket.ticket_category}</div>
                        {ticket.issue_text ? 
                            <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div>
                            :
                            <div className="ticketIssuePreview gothamNarrowLightFont">No Text Entered</div>
                        }
                    </td>
                    <td style={{width: "20%", cursor: "default"}} id="ticketRequesterText" className="gothamNarrowFont"><div>{ticket.asker_first_name} {ticket.asker_last_name}</div><div>{`(${ticket.name_of_tenant})`}</div></td>
                    <td style={{width: "5%", cursor: "default"}} id="ticketViews" className="gothamNarrowFont">{ticket.total_clicks_all_time}</td>
                    <td style={{width: "5%", textAlign: "center", cursor: "default"}}>   
                        <div id="upVotesText" className="gothamNarrowFont">{ticket.up_votes} <FontAwesomeIcon style={{color: "gray"}} icon={faThumbsUp} /></div>
                        <div id="downVotesText" className="gothamNarrowFont">{ticket.down_votes} <FontAwesomeIcon style={{color: "gray"}} icon={faThumbsDown} /></div>    
                    </td>
                    <td style={{width: "10%", textAlign: "center", cursor: "default"}} id="ticketDateText" className="gothamNarrowFont">{ticket.time_since_sent}</td>      
                </tr>
                
            )
        })

        return tableRows;
        
    }

    handleScroll = e => {     // Fetch the next results if you reach the bottom of the page
        
        setTimeout(() => {
            if(!this._scroll) {
                this._scroll = true
    
                if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {  // User at bottom, load more items
                    this._isMounted = true;
                    
                    if(this.state.ticketOffsetValue >= this.state.rowCount) {   // The number we've tried to return already exceeds the max
                        this.setState({allTicketsShown: true});     // Don't need to show more
                    }

                    // if we previously had all tickets shown and we reset the search criteria, we need to let system know we no longer have all tickets shown
                    else if(this.state.ticketOffsetValue < this.state.rowCount && this.state.allTicketsShown) {
                        this.setState({allTicketsShown: false});
                    }

                    if(!this.state.allTicketsShown) {   // Only ask for more records if we're not at the end of the list yet
                        if(this.state.selectedFilterTicketCategory === "All Categories"){    // user has no category selected but is searching 

                            // user scrolled to bottom of page with no special category and no special search (ie: standard retrieval)
                            if(this.state.searchText === "") {  // they had no search text and all categories selected so just do a standard ticket retrieval
                                this.retrieveTickets(this.props.loggedStatus.accessToken, this.state.ticketOffsetValue, this.state.sortTicketsBy, this.state.ticketSortOrder).then(response => {
                                    this.setState(prevState => ({
                                        tickets: [...prevState.tickets, ...response.results]
                                    }));
                                });
                            }

                            // user scrolled to bottom of page with no special category, but did have search text
                            else {
                                this.searchKnowledgebase(this.state.searchText, this.state.ticketOffsetValue, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                            }
                        }

                        else if(this.state.searchText === "") {    // User emptied search bar, but still has category selected (as long as category id is not 0 - all categories)



                            this.retrieveFilteredTickets(this.state.selectedFilterTicketCategoryID, this.state.ticketOffsetValue, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken).then(response => {
                                // add new tickets to end of current array and then update the value of offset
                                this.setState(prevState => ({
                                    tickets: [...prevState.tickets, ...response.results]
                                }));

                                this.setState({
                                    ticketOffsetValue: this.state.ticketOffsetValue + 10
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                        }

                        else {  // User has both category and search bar letters
                            this.searchKnowledgebaseByCategory(this.state.searchText, this.state.ticketOffsetValue, this.state.selectedFilterTicketCategory, this.state.sortTicketsBy, this.state.ticketSortOrder, this.props.loggedStatus.accessToken);
                        }

                        this.setState({ // we grabbed 10 more tickets so go ahead and increment this value
                            ticketOffsetValue: this.state.ticketOffsetValue + 10
                        });
                        
                    }
                }

                setTimeout(() => {
                    this._scroll = false;
                }, 500);
            }
    
        }, 500);
        
      }


    render() {

        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }

        const { tickets, searchText, categories } = this.state;

        // Figure out time to display for all the returned tickets
        if(tickets) {
            this.setTicketTimesSinceCreated(tickets);
        }


        return (
            <>
                <div className="container mt-3" style={{paddingTop: "75px"}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <h1 id="ticketForumPageHeader" className="gothamNarrowFont">Software Knowledgebase</h1>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={3}>
                            {/* <Link to="/createSoftwareTicket">
                                <Button className="knowledgebaseButton, gothamNarrowFont">
                                    + Create New Ticket
                                </Button>
                            </Link> */}                         
                        </Col>
                        <Col md={3}>
                            <DropdownButton id="dropdown-item-button" className="gothamNarrowFont" title={this.state.selectedFilterTicketCategory}>
                                <Dropdown.Item as="button" className="knowledgebaseButton gothamNarrowFont" onClick={() => this.updateSelectedCategoryValue(0, "All Categories")}>All Categories</Dropdown.Item>
                                {categories && categories.map((category) => (
                                    <Dropdown.Item as="button" key={category.id} className="knowledgebaseButton gothamNarrowFont" onClick={() => this.updateSelectedCategoryValue(category.id, category.name_of_category)}>
                                        {category.name_of_category}
                                    </Dropdown.Item>
                                ))}
                            </DropdownButton>
                        </Col>
                        <Col md={6}>
                        <input
                            type="text"
                            className="form-control gothamNarrowFont"
                            placeholder="Search Knowledgebase"
                            value={searchText}
                            onChange={this.onChangeSearchText}
                        />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table>
                                <thead>
                                    <tr className="ticketTableHead">
                                        <th 
                                            style={{width: "50%"}} 
                                            onClick={() => this.handleColumnSort("title")}
                                            id={this.state.currentForumColumnSorting === "title" ? "activeForumHeader" : ""}
                                            className="gothamNarrowFont"
                                        >
                                            Title / Category <span>{this.state.currentForumColumnSorting === "title" ? 
                                            <FontAwesomeIcon style={{color: "black"}} icon={this.state.ticketSortOrder === "desc" ? faCaretDown : faCaretUp} /> : undefined}</span>
                                        </th>
                                        <th 
                                            style={{width: "15%"}} 
                                            onClick={() => this.handleColumnSort("asker")}
                                            id={this.state.currentForumColumnSorting === "asker" ? "activeForumHeader" : ""}
                                            className="gothamNarrowFont"
                                        >
                                            Asked By <span>{this.state.currentForumColumnSorting === "asker" ? 
                                            <FontAwesomeIcon style={{color: "black"}} icon={this.state.ticketSortOrder === "desc" ? faCaretDown : faCaretUp} /> : undefined}</span>
                                        </th>
                                        <th 
                                            style={{width: "10%", textAlign: "center"}} 
                                            onClick={() => this.handleColumnSort("views")}
                                            id={this.state.currentForumColumnSorting === "views" ? "activeForumHeader" : ""}
                                            className="gothamNarrowFont"
                                        >
                                            Views <span>{this.state.currentForumColumnSorting === "views" ? 
                                            <FontAwesomeIcon style={{color: "black"}} icon={this.state.ticketSortOrder === "desc" ? faCaretDown : faCaretUp} /> : undefined}</span>
                                        </th>
                                        <th 
                                            style={{width: "10%"}} 
                                            onClick={() => this.handleColumnSort("votes")}
                                            id={this.state.currentForumColumnSorting === "votes" ? "activeForumHeader" : ""}
                                            className="gothamNarrowFont"
                                        >
                                            Votes <span>{this.state.currentForumColumnSorting === "votes" ? 
                                            <FontAwesomeIcon style={{color: "black"}} icon={this.state.ticketSortOrder === "desc" ? faCaretDown : faCaretUp} /> : undefined}</span>
                                        </th>
                                        <th 
                                            style={{width: "15%", whiteSpace: "nowrap"}} 
                                            onClick={() => this.handleColumnSort("date")}
                                            id={this.state.currentForumColumnSorting === "date" ? "activeForumHeader" : ""}
                                            className="gothamNarrowFont"
                                        >
                                            Created <span>{this.state.currentForumColumnSorting === "date" ? 
                                            <FontAwesomeIcon style={{color: "black"}} icon={this.state.ticketSortOrder === "desc" ? faCaretDown : faCaretUp} /> : undefined}</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="ticketTableBody">
                                    {tickets && this.createTicketsToDisplay()}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                </div>
            </>
        );

    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav
    };
}

export default connect(mapStateToProps, {ACCESSTOKEN, LOGOUT, UPDATE_ACTIVE_LINK})(SoftwareTicketsLandingPage);