import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Button,
    Paper,
    Select,
    Grid,
    Flex,
} from '@mantine/core';
import { IconSelector, IconChevronDown, IconChevronUp, IconSearch, IconMenu2 } from '@tabler/icons-react';

import Navbar from '../../components/Navbar';
import classes from '../TableSort.module.css';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

//import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSearch, faFilter, faQuestionCircle, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/dist/css/bootstrap.min.css";
//import { Form } from "react-bootstrap";
import Form from 'react-bootstrap/Form';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import {
    UPDATE_SELECTED_TYPE,
    UPDATE_SELECTED_WARRANTY_STATUS,
    UPDATE_SELECTED_EXPIRATION_START_DATE,
    UPDATE_SELECTED_EXPIRATION_END_DATE,
    UPDATE_SELECTED_TENANT_ID,
    UPDATE_SELECTED_ASSIGNED_TO_USER,
    UPDATE_SELECTED_SERIAL_NUMBER,
    UPDATE_SELECTED_ASSET_TAG,
    UPDATE_SEARCH_TEXT_HARDWARE_ASSET,
    UPDATE_SORT_BY,
    UPDATE_SORT_ORDER
} from '../../features/actions/Hardware-Asset-Filter-Actions';

import HardwareAssetDataService from '../../services/hardware-asset-activities';

function Th(props) {
    const Icon = props.sorted ? (props.reversed ? IconChevronUp : IconChevronDown) : IconSelector;
    return (
        <Table.Th w={props.w} className={classes.th}>
            <UnstyledButton onClick={props.onSort} className={classes.control}>
                <Group justify="space-between">
                    <Text fw={500} fz="sm">
                        {props.children}
                    </Text>
                    <Center className={classes.icon}>
                        <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
                    </Center>
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}


function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}


class HardwareAssetList extends Component {

    constructor(props) {
        super(props);

        this.onChangeSearchText = this.onChangeSearchText.bind(this);
        this.openFilterModal = this.openFilterModal.bind(this);
        this.toggleFilterWarrantyDateInputVisible = this.toggleFilterWarrantyDateInputVisible.bind(this);
        this.toggleAddFormAssignedAssetInputVisible = this.toggleAddFormAssignedAssetInputVisible.bind(this);
        this.getHardwareAssetTypes = this.getHardwareAssetTypes.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.handleWarrantyStatusFilterChange = this.handleWarrantyStatusFilterChange.bind(this);
        this.handleWarrantyStartDateChange = this.handleWarrantyStartDateChange.bind(this);
        this.handleWarrantyEndDateChange = this.handleWarrantyEndDateChange.bind(this);
        this.handleSelectedTypeChange = this.handleSelectedTypeChange.bind(this);
        this.handleSelectedAssignedUserChange = this.handleSelectedAssignedUserChange.bind(this);
        this.handleSerialNumberChange = this.handleSerialNumberChange.bind(this);
        this.handleAssetTagChange = this.handleAssetTagChange.bind(this);
        this.resetFiltersToPreviousValue = this.resetFiltersToPreviousValue.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.handleColumnClick = this.handleColumnClick.bind(this);
        this.handleAssetTableScroll = this.handleAssetTableScroll.bind(this);
        this.getAssets = this.getAssets.bind(this);
        this.handleSelectedTenantChange = this.handleSelectedTenantChange.bind(this);

        this.calculateWarrantyDaysRemaining = this.calculateWarrantyDaysRemaining.bind(this);

        this.goToAssetDetails = this.goToAssetDetails.bind(this);

        this.scrollerRef = React.createRef();


        // the global state will be definitive copy of filter options chosen. local state handles changes to filter modal not necessarily confirmed
        // global values actually get sent to backend for processing
        this.state = {
            searchText: "",
            typingTimeout: 0,
            resetOffsetTimeout: 0,  // handles the delay in changing asset offset to 0 while user is typing
            show_filter_modal: false,
            enable_warranty_date_input: false,   // determines if the form field for specifying exp date is active in filter modal
            enable_assigned_asset_input: false,  // determines if the form field for assigned asset is active in the add asset modal
            show_add_modal: false,
            types_list: [{ id: 0, name_of_type: "All" }],          // will contain array of types {id: 0, name_of_type: "All"}
            tenant_list: [{ id: 0, name_of_tenant: "All" }],     // will contain array of tenants {id: 0, name_of_tenant: "All"}
            user_list: [{ id: 0, full_name: "All", name_of_tenant: "" }, { id: -1, full_name: "Unassigned", name_of_tenant: "" }],      // will contain array of users tech is authorized to see {id: 0, full_name: "All", name_of_tenant: "All Tenants"}

            // local state for filter modal (global state sets default values on componentDidMount)
            selected_type: 0,
            selected_warranty_status: "",
            selected_expiration_start_date: "",
            selected_expiration_end_date: "",
            selected_tenant: 0,
            selected_assigned_to_user: 0,
            selected_serial_number: "",
            selected_asset_tag: "",

            // local state for table related sort items (search items are at top of the state object above)
            sort_by: "",        // which column is highlighted / which column is sorted on
            sort_order: "",      // ASC or DESC

            // asset list variables
            assets: [],
            rowCount: 0,        // The TOTAL number of assets that could be displayed based on current search params
            scrolled: false,
            assetsToDisplay: [],
            assetOffsetValue: 0,   // the value passed to database to know how many records we've already retrieved by scrolling (increments by 10 each database call)
            numberOfRecordsToShow: 10,   // Will increment by 10 each time user scrolls to bottom of page
            allAssetsShown: false,  // Controls whether to ask for more records

            todaysDate: ""      // will contain the date of today (for comparison with warranty expirations for red text color)
        };
    }

    handleAssetTableScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
        if (bottom) {
            if (this.state.assetOffsetValue < this.state.rowCount) {        // if we haven't gotten all the assets yet, ask for the next 10
                //this.getAssignedTickets();
                this.getAssets();
            }
        }
    }

    getAssets() {
        HardwareAssetDataService.getAssets(this.props.loggedStatus.accessToken, this.props.loggedStatus.id, this.state.assetOffsetValue, this.props.hardwareAssetFilter.selectedType, this.props.hardwareAssetFilter.warrantyStatus, this.props.hardwareAssetFilter.expirationStartDate, this.props.hardwareAssetFilter.expirationEndDate, this.props.hardwareAssetFilter.selectedAssignedToUser, this.props.hardwareAssetFilter.serialNumber, this.props.hardwareAssetFilter.assetTag, this.props.hardwareAssetFilter.searchTextHardwareAsset, this.props.hardwareAssetFilter.sortBy, this.props.hardwareAssetFilter.sortOrder, this.props.hardwareAssetFilter.selectedTenantId)
            .then(response => {
                console.log("You'll be processing returned data here");
                console.log(response);

                // if this is first get with these params, set rowcount and override the previous assets array
                if (this.state.assetOffsetValue === 0) {     // if this was first retreival with these filter params, set the rowCount returned (total possible with those criteria)
                    this.setState({
                        rowCount: response.data.row_count,
                        assets: response.data.results,
                        assetOffsetValue: this.state.assetOffsetValue + 10
                    });
                }

                else {  // this was arrived at by a scroll, so add the new results to the previous array

                    this.setState(prevState => ({
                        assets: [...prevState.assets, ...response.data.results]
                    }));

                    this.setState({
                        assetOffsetValue: this.state.assetOffsetValue + 10
                    });

                }
            })
            .catch(e => {
                console.log(e);
            });
    }

    onChangeSearchText(e) {
        //const searchText = e.target.value;
        //let searchTextTemp = e.target.value;

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        if (this.state.resetOffsetTimeout) {
            clearTimeout(this.state.resetOffsetTimeout);
        }

        this.setState({
            searchText: e.target.value,

            resetOffsetTimeout: setTimeout(() => {
                console.log("The offset value has been reset");  //this.resetTicketOffset();   // reset the asset offset in state back to 0 (they have changed their search criteria so new list)
            }, 1500),

            typingTimeout: setTimeout(() => {
                // call asset retrieval fxns here
                this.props.UPDATE_SEARCH_TEXT_HARDWARE_ASSET(e.target.value/* this.state.searchText */);
                console.log("You'd be calling the asset retrieval here");
                // this is going to be a new set of filter criteria, so reset the offset
                this.setState({
                    assetOffsetValue: 0
                }, () => this.getAssets());
                //this.getAssets();
            }, 1500)
        });
    }

    // called if user clicks the filter icon. opens filter modal
    openFilterModal() {
        this.setState({
            show_filter_modal: true
        });
    }

    // called when user clicks cancel or x within the filter modal
    closeFilterModal() {
        this.setState({
            show_filter_modal: false
        });

        // after hiding modal reset values to the ones they were before user clicked cancel or x (not necessarily default values)
        this.resetFiltersToPreviousValue();
    }

    openAddModal() {
        this.props.history.push('/createHardwareAsset');
        // this.setState({
        //     show_add_modal: true
        // });
    }

    closeAddModal() {
        this.setState({
            show_add_modal: false
        });
    }

    // determines whether user sees form field for specific warranty exp date within filter modal
    toggleFilterWarrantyDateInputVisible(newValue) {

        if (this.state.enable_warranty_date_input && newValue === false) {
            this.setState({
                enable_warranty_date_input: false
            });
        }

        else if (!this.state.enable_warranty_date_input && newValue === true) {
            this.setState({
                enable_warranty_date_input: true
            });
        }

    }

    // determines whether user sees form field for specific asset a peripheral is assigned to
    toggleAddFormAssignedAssetInputVisible(newValue) {

        if (this.state.enable_assigned_asset_input && newValue === false) {
            this.setState({
                enable_assigned_asset_input: false
            });
        }

        else if (!this.state.enable_assigned_asset_input && newValue === true) {
            this.setState({
                enable_assigned_asset_input: true
            });
        }

    }

    // changeWarrantyDateInputVisible(event) {
    //     console.log(event.target.value);
    // }

    // componentDidMount() {           // token passed into props from the App.js file

    //     if(this.props.loggedStatus.loggedIn){
    //             this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //             this.props.ACCESSTOKEN(token);
    //             this.retrieveTickets(token);    // PASS TOKEN HERE
    //         });
    //     }

    // }

    // // onChangeSearchName(e) {
    // //     const searchName = e.target.value;

    // //     this.setState({
    // //         searchName: searchName
    // //     });
    // // }

    // retrieveTickets(token) {
    //     HardwareTicketDataService.getAll(token)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // setActiveTicket(ticket, index) {
    //     this.setState({
    //         currentTicket: ticket,
    //         currentIndex: index
    //     });
    // }

    // searchName() {
    //     TicketDataService.findByName(this.state.searchName)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }


    componentDidMount() {

        if (this.props.loggedStatus.loggedIn) {
            this.scrollerRef.current.scrollTop = 0;
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // create a date object with today's date (going to use to determine if warranty expiration should be in red font or not)
                    var q = new Date();
                    // var m = q.getMonth()+1;
                    // var d = q.getDay();
                    // var y = q.getFullYear();

                    // var today = new Date(y,m,d);

                    //console.log(today);

                    this.setState({
                        todaysDate: q
                    });

                    this.getHardwareAssetTypes(token).then(response => {
                        //console.log(response);
                        this.setState({
                            types_list: [...this.state.types_list, ...response.results]
                        }, () => {
                            // get the list of users this tech is authorized to view (send the currently selected tenant id through as well)
                            this.getUserList(token, this.props.loggedStatus.id, this.props.hardwareAssetFilter.selectedTenantId).then(response => {
                                this.setState({
                                    user_list: [...this.state.user_list, ...response.results]
                                }, () => {
                                    // get the list of tenant names this tech is authorized to view
                                    this.getTenantList(token, this.props.loggedStatus.id).then(response => {
                                        this.setState({
                                            tenant_list: [...this.state.tenant_list, ...response.results]
                                        }, () => {
                                            this.getAssets();
                                        })
                                    })
                                        .catch(e => {
                                            console.log(e);
                                        });
                                });
                            })
                                .catch(e => {
                                    console.log(e);
                                });
                        });


                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

            });

            // set the local state values for the filter modal based on global state (also search text, sort by and sort order)
            this.setState({
                selected_type: this.props.hardwareAssetFilter.selectedType,
                selected_warranty_status: this.props.hardwareAssetFilter.warrantyStatus,
                selected_expiration_start_date: this.props.hardwareAssetFilter.expirationStartDate,
                selected_expiration_end_date: this.props.hardwareAssetFilter.expirationEndDate,
                selected_tenant: this.props.hardwareAssetFilter.selectedTenantId,
                selected_assigned_to_user: this.props.hardwareAssetFilter.selectedAssignedToUser,
                selected_serial_number: this.props.hardwareAssetFilter.serialNumber,
                selected_asset_tag: this.props.hardwareAssetFilter.assetTag,
                searchText: this.props.hardwareAssetFilter.searchTextHardwareAsset,
                sort_by: this.props.hardwareAssetFilter.sortBy,
                sort_order: this.props.hardwareAssetFilter.sortOrder
            });

            this.props.UPDATE_ACTIVE_LINK("hardwareAssetList");
        }



    }

    getHardwareAssetTypes(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTypes(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of users this tech is authorized to see for the filter drop down menu
    getUserList(token, asker_id, tenant_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getUsers(token, asker_id, tenant_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of tenant names this tech is authorized to see for the filter drop down menu
    getTenantList(token, asker_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTenants(token, asker_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // resets the filters to the values they were before user changed the filter modal (not necessarily default values)
    // called when user clicks the cancel button or x within modal
    resetFiltersToPreviousValue() {
        this.setState({
            selected_type: this.props.hardwareAssetFilter.selectedType,
            selected_warranty_status: this.props.hardwareAssetFilter.warrantyStatus,
            selected_expiration_start_date: this.props.hardwareAssetFilter.expirationStartDate,
            selected_expiration_end_date: this.props.hardwareAssetFilter.expirationEndDate,
            selected_tenant: this.props.hardwareAssetFilter.selectedTenantId,
            selected_assigned_to_user: this.props.hardwareAssetFilter.selectedAssignedToUser,
            selected_serial_number: this.props.hardwareAssetFilter.serialNumber,
            selected_asset_tag: this.props.hardwareAssetFilter.assetTag
        });
    }

    // resets filters to their default values [essentially any "All" values, etc.] (in order to save changes user should click apply to save to global state, etc.)
    resetFiltersToDefaultValues() {
        this.setState({
            //user_list: [{id: 0, full_name: "All", name_of_tenant: ""}, {id: -1, full_name: "Unassigned", name_of_tenant: ""}],

            selected_type: 0,
            selected_warranty_status: "All",
            selected_expiration_start_date: "",
            selected_expiration_end_date: "",
            selected_tenant: 0,
            selected_assigned_to_user: 0,
            selected_serial_number: "",
            selected_asset_tag: "",

            enable_warranty_date_input: false
        });
    }

    // gets called when user clicks apply button in the filter modal
    applyFilters() {
        //save local state values to global state
        this.props.UPDATE_SELECTED_TYPE(this.state.selected_type);
        this.props.UPDATE_SELECTED_WARRANTY_STATUS(this.state.selected_warranty_status);
        this.props.UPDATE_SELECTED_EXPIRATION_START_DATE(this.state.selected_expiration_start_date);
        this.props.UPDATE_SELECTED_EXPIRATION_END_DATE(this.state.selected_expiration_end_date);
        this.props.UPDATE_SELECTED_TENANT_ID(this.state.selected_tenant);
        this.props.UPDATE_SELECTED_ASSIGNED_TO_USER(this.state.selected_assigned_to_user);
        this.props.UPDATE_SELECTED_SERIAL_NUMBER(this.state.selected_serial_number);
        this.props.UPDATE_SELECTED_ASSET_TAG(this.state.selected_asset_tag);

        setTimeout(() => {      // wait for global state to update before getting the next batch of tickets
            this.setState({
                assetOffsetValue: 0
            }, () => this.getAssets());
            //this.getAssets();
        }, 500);

        this.setState({
            show_filter_modal: false
        });
    }

    handleWarrantyStatusFilterChange(e) {
        console.log(e.target.value);
        this.setState({
            selected_warranty_status: e.target.value
        });
    }

    handleWarrantyStartDateChange(e) {
        console.log(e.target.value);
        this.setState({
            selected_expiration_start_date: e.target.value
        });
    }

    handleWarrantyEndDateChange(e) {
        console.log(e.target.value);
        this.setState({
            selected_expiration_end_date: e.target.value
        });
    }

    handleSelectedTypeChange(id) {
        console.log(id);
        this.setState({
            selected_type: id
        });
    }

    handleSelectedTenantChange(id) {
        console.log(id);
        this.setState({
            selected_tenant: id
        });

        // call for new user list with tenant change
        this.getUserList(this.props.loggedStatus.accessToken, this.props.loggedStatus.id, id).then(response => {
            // repopulate the user list
            this.setState({
                user_list: [{ id: 0, full_name: "All", name_of_tenant: "" }, { id: -1, full_name: "Unassigned", name_of_tenant: "" }, ...response.results],
                selected_assigned_to_user: 0
            });
        })
            .catch(e => {
                console.log(e);
            });
    }

    handleSelectedAssignedUserChange(id) {
        console.log(id);
        this.setState({
            selected_assigned_to_user: id
        });
    }

    handleSerialNumberChange(e) {
        this.setState({
            selected_serial_number: e.target.value
        }, () => console.log(this.state.selected_serial_number));

    }

    handleAssetTagChange(e) {
        this.setState({
            selected_asset_tag: e.target.value
        }, () => console.log(this.state.selected_asset_tag));
    }

    handleColumnClick(columnName) {
        // switch the asc, desc value
        if (this.state.sort_order === "ASC") {
            this.setState({
                sort_order: "DESC",
                sort_by: columnName
            });

            this.props.UPDATE_SORT_ORDER("DESC");
            this.props.UPDATE_SORT_BY(columnName);

            //this.getAssets();
        }

        else {
            this.setState({
                sort_order: "ASC",
                sort_by: columnName
            });

            this.props.UPDATE_SORT_ORDER("ASC");
            this.props.UPDATE_SORT_BY(columnName);

            //this.getAssets();
        }

        setTimeout(() => {
            this.setState({
                assetOffsetValue: 0
            }, () => this.getAssets());      // wait for global state to update before getting the next batch of tickets
            //this.getAssets();
        }, 500);
    }

    calculateWarrantyDaysRemaining(expiration_date) {
        if (expiration_date && expiration_date !== null && expiration_date !== "") {
            // create a date object with today's date
            var today = new Date();

            // compare today's date with the date of warranty expiration
            var expiration = new Date(expiration_date.split("T")[0]);

            //console.log(today);
            //console.log(today);
            //console.log(expiration);

            // To calculate the time difference of two dates
            var Difference_In_Time = expiration.getTime() - today.getTime();

            // To calculate the no. of days between two dates
            var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

            //console.log("Difference in days is: ", Math.round(Difference_In_Days));

            return Math.ceil(Difference_In_Days);

        }

        else {
            return;
        }
    }

    goToAssetDetails(id) {
        this.props.history.push(`/hardwareAssetDetails/${id}`);
    }

    render() {

        if (!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_assets && !this.props.userPermissions.can_edit_assets && !this.props.userPermissions.can_add_assets && !this.props.userPermissions.can_delete_assets)) {
            return (
                <Redirect to='/' />
            );
        }

        const rows = this.state.assets.map((asset) => (
            <Table.Tr key={`assetRow${asset.id}`} className="hardwareAssetTableRow" onClick={() => this.goToAssetDetails(asset.id)}>
                <Table.Td w="25%">{asset.name_of_asset}</Table.Td>
                <Table.Td w="18%">{asset.name_of_type}</Table.Td>
                <Table.Td w="25%">
                    {
                        (asset.first_name !== null && asset.middle_name !== null && asset.last_name !== null) ? `${asset.first_name} ${asset.middle_name} ${asset.last_name}` :
                            (asset.first_name !== null && asset.last_name !== null) ? `${asset.first_name} ${asset.last_name}` :
                                `${"Unassigned"}`
                    }
                </Table.Td>
                <Table.Td w="17%">{asset.asset_serial_number}</Table.Td>
                {asset.warranty_expiration_date && this.calculateWarrantyDaysRemaining(asset.warranty_expiration_date) <= 0 ?
                    <Table.Td w="15%" style={{ color: "red" }}>{asset.warranty_expiration_date ? `${asset.warranty_expiration_date.split("T")[0]}` : ""}</Table.Td>
                    :
                    asset.warranty_expiration_date && this.calculateWarrantyDaysRemaining(asset.warranty_expiration_date) <= 30 ?
                        <Table.Td w="15%" style={{ color: "orangered" }}>{asset.warranty_expiration_date ? `${asset.warranty_expiration_date.split("T")[0]}` : ""}</Table.Td>
                        :
                        <Table.Td w="15%" style={{ color: "green" }}>{asset.warranty_expiration_date ? `${asset.warranty_expiration_date.split("T")[0]}` : ""}</Table.Td>
                }
            </Table.Tr>
        ))


        return (
            <>
                <Navbar pageTitle={"Hardware Assets"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                <Container fluid >
                    <Paper withBorder p="md" radius="md" display="block" m="5px" ref={this.scrollerRef} onScroll={this.handleAssetTableScroll}>
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                            h="50px"
                        >
                            <Button h="36px" onClick={() => this.openAddModal()}>+ Add New Asset</Button>
                            <Button h="36px" onClick={() => this.openFilterModal()}>Filter</Button>
                        </Flex>
                        <TextInput
                            placeholder="Search"
                            mb="md"
                            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            value={this.state.searchText}
                            onChange={this.onChangeSearchText}
                        />
                        <Table miw={700}>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Th
                                        w="25%"
                                        sorted={this.state.sort_by === 'Name'}
                                        reversed={this.state.sort_order === "ASC"}
                                        onSort={() => this.handleColumnClick("Name")}
                                    >
                                        Name
                                    </Th>
                                    <Th
                                        w="18%"
                                        sorted={this.state.sort_by === 'Type'}
                                        reversed={this.state.sort_order === "ASC"}
                                        onSort={() => this.handleColumnClick('Type')}
                                    >
                                        Type
                                    </Th>
                                    <Th
                                        w="25%"
                                        sorted={this.state.sort_by === 'Assigned To'}
                                        reversed={this.state.sort_order === "ASC"}
                                        onSort={() => this.handleColumnClick('Assigned To')}
                                    >
                                        Assigned To
                                    </Th>
                                    <Th
                                        w="17%"
                                        sorted={this.state.sort_by === 'Serial Number'}
                                        reversed={this.state.sort_order === "ASC"}
                                        onSort={() => this.handleColumnClick('Serial Number')}
                                    >
                                        Serial Number
                                    </Th>
                                    <Th
                                        w="15%"
                                        sorted={this.state.sort_by === 'Warranty Expires'}
                                        reversed={this.state.sort_order === "ASC"}
                                        onSort={() => this.handleColumnClick('Warranty Expires')}
                                    >
                                        Warranty Expires
                                    </Th>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>
                        <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                            <Table.Tbody>
                                {rows.length > 0 ? (
                                    rows
                                ) : (
                                    <Table.Tr>
                                        <Table.Td>
                                            <Text fw={500} ta="center">
                                                Nothing found
                                            </Text>
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </Container>

                {/* This modal is for filtering contents of the hardware asset table */}
                <Modal
                    show={this.state.show_filter_modal}
                    onHide={() => this.closeFilterModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Apply Filters to Assets</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="filterWarrantyStatus" className="mb-3">
                                <Form.Label>Warranty Status</Form.Label>
                                <div className="mb-3">
                                    <Form.Check
                                        inline
                                        checked={this.state.selected_warranty_status === "All"}
                                        label="All"
                                        name="waranty radio"
                                        type="radio"
                                        id="All Warranty Radio"
                                        value="All"
                                        onChange={e => { this.toggleFilterWarrantyDateInputVisible(false); this.handleWarrantyStatusFilterChange(e) }}
                                    />
                                    <Form.Check
                                        inline
                                        checked={this.state.selected_warranty_status === "In Date"}
                                        label="In Date"
                                        name="waranty radio"
                                        type="radio"
                                        id="In Date Warranty Radio"
                                        value="In Date"
                                        onChange={e => { this.toggleFilterWarrantyDateInputVisible(false); this.handleWarrantyStatusFilterChange(e) }}
                                    />
                                    <Form.Check
                                        inline
                                        checked={this.state.selected_warranty_status === "Expired"}
                                        label="Expired"
                                        name="waranty radio"
                                        type="radio"
                                        id="Expired Warranty Radio"
                                        value="Expired"
                                        onChange={e => { this.toggleFilterWarrantyDateInputVisible(false); this.handleWarrantyStatusFilterChange(e) }}
                                    />
                                    <Form.Check
                                        inline
                                        checked={this.state.selected_warranty_status === "Expires Between"}
                                        label="Expires Between"
                                        name="waranty radio"
                                        type="radio"
                                        id="Specific Date Warranty Radio"
                                        value="Expires Between"
                                        onChange={e => { this.toggleFilterWarrantyDateInputVisible(true); this.handleWarrantyStatusFilterChange(e) }}
                                    />
                                    <span style={{ display: "inline-block" }}>
                                        {this.state.enable_warranty_date_input ?
                                            <Form.Control type="date" name="warrantyExpiresDateStart" onChange={e => this.handleWarrantyStartDateChange(e)} defaultValue={this.state.selected_expiration_start_date}></Form.Control> :
                                            <Form.Control disabled type="date" name="warrantyExpiresDateStart" defaultValue={this.state.selected_expiration_start_date}></Form.Control>
                                        }
                                    </span>
                                    <span style={{ display: "inline-block", marginLeft: "5px", marginRight: "5px" }}>
                                        <p className="gothamNarrowLightFont">and</p>
                                    </span>
                                    <span style={{ display: "inline-block" }}>
                                        {this.state.enable_warranty_date_input ?
                                            <Form.Control type="date" name="warrantyExpiresDateEnd" onChange={e => this.handleWarrantyEndDateChange(e)} defaultValue={this.state.selected_expiration_end_date}></Form.Control> :
                                            <Form.Control disabled type="date" name="warrantyExpiresDateEnd" defaultValue={this.state.selected_expiration_end_date}></Form.Control>
                                        }
                                    </span>
                                </div>
                            </Form.Group>
                            <Form.Group controlId="filterAssetType" className="mb-3">
                                <Form.Label>Asset Type</Form.Label>
                                <Form.Control as="select">
                                    {this.state.types_list && this.state.types_list.map((type) => (
                                        this.state.selected_type === type.id ?
                                            <option key={`type_${type.id}`} onClick={() => this.handleSelectedTypeChange(type.id)} selected>{type.name_of_type}</option>
                                            :
                                            <option key={`type_${type.id}`} onClick={() => this.handleSelectedTypeChange(type.id)}>{type.name_of_type}</option>

                                    ))}
                                    {/* <option>All</option>
                                        <option>Printer</option>
                                        <option>Workstation</option> */}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="filterTenantName" className="mb-3">
                                <Form.Label>Company</Form.Label>
                                <Form.Control as="select">
                                    {this.state.tenant_list && this.state.tenant_list.map((tenant) => (
                                        this.state.selected_tenant === tenant.id ?
                                            <option key={`tenant_${tenant.id}`} onClick={() => this.handleSelectedTenantChange(tenant.id)} selected>{`${tenant.name_of_tenant}`}</option>
                                            :
                                            <option key={`tenant_${tenant.id}`} onClick={() => this.handleSelectedTenantChange(tenant.id)}>{`${tenant.name_of_tenant}`}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="filterAssetOwner" className="mb-3">
                                <Form.Label>{`Assigned To (based on Company selected)`}</Form.Label>
                                <Form.Control as="select">
                                    {this.state.user_list && this.state.user_list.map((user) => (
                                        this.state.selected_assigned_to_user === user.id && (user.id !== 0 && user.id !== -1) ?
                                            <option key={`user_${user.id}`} onClick={() => this.handleSelectedAssignedUserChange(user.id)} selected>{`${user.full_name} - (${user.name_of_tenant})`}</option>
                                            :
                                            this.state.selected_assigned_to_user === user.id && (user.id === 0 || user.id === -1) ?
                                                <option key={`user_${user.id}`} onClick={() => this.handleSelectedAssignedUserChange(user.id)} selected>{`${user.full_name}`}</option>
                                                :
                                                this.state.selected_assigned_to_user !== user.id && (user.id !== 0 && user.id !== -1) ?
                                                    <option key={`user_${user.id}`} onClick={() => this.handleSelectedAssignedUserChange(user.id)}>{`${user.full_name} - (${user.name_of_tenant})`}</option>
                                                    :
                                                    <option key={`user_${user.id}`} onClick={() => this.handleSelectedAssignedUserChange(user.id)}>{`${user.full_name}`}</option>
                                    ))}
                                    {/* <option>All</option>
                                        <option>Test McTesterson</option>
                                        <option>Michael Childress</option> */}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="filterSerialNumber" className="mb-3">
                                <Form.Label>Serial Number</Form.Label>
                                <Form.Control type="text" placeholder="ex: 123abc758" value={this.state.selected_serial_number} onChange={e => this.handleSerialNumberChange(e)} />
                            </Form.Group>
                            <Form.Group controlId="filterAssetTag" className="mb-3">
                                <Form.Label>Asset Tag</Form.Label>
                                <Form.Control type="text" placeholder="ex: 123abc758" value={this.state.selected_asset_tag} onChange={e => this.handleAssetTagChange(e)} />
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <div className="assetFilterResetButtonContainer">
                                <Button variant="danger" onClick={() => this.resetFiltersToDefaultValues()} className="gothamNarrowFont">Reset</Button>
                            </div> */}
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeFilterModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.applyFilters() }} className="gothamNarrowFont">Apply</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for adding a new hardware asset to the system - NOT CURRENTLY USING, see Create Hardware Asset.js */}
                <Modal
                    show={this.state.show_add_modal}
                    onHide={() => this.closeAddModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Add A New Hardware Asset</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {/* 
                                1. Asset type (drop down) required
                                2. Name of asset (text) required - must be unique
                                3. Model name (drop down) required
                                4. Vendor (drop down)
                                5. Asset tag (text)
                                6. Serial Number (text)
                                7. Bar code (text)
                                8. Acquisition date (date)
                                9. Warranty start date (date)
                                10. Warranty expiration date (date)
                                11. Site (drop down) required
                                12. Assigned person (drop down)
                                13. Assigned location at site (drop down)
                                14. Lease Start Date (date)
                                15. Lease End Date (date)
                                16. Manufacturer Website (text)
                                17. Asset Category (drop down) required
                                18. Is Tracked (radio) required
                                19. Core Asset Assigned To (drop down)
                            */}
                            <div className="addHardwareFormOuterWrapper" style={{ width: "100%" }}>
                                <Form.Group controlId="addFormAssetName" className="mb-3">
                                    <Form.Label>Asset Name<span className="requiredStar">*</span></Form.Label>
                                    <Form.Control type="text" placeholder="ex: Dell 3600c" required />
                                </Form.Group>

                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormAssetCategory" className="mb-3">
                                            <Form.Label>Asset Category<span className="requiredStar">*</span></Form.Label>
                                            <Form.Control as="select" required>
                                                <option>IT</option>
                                                <option>Non-IT</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>


                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormAssetType" className="mb-3">
                                            <Form.Label>Asset Type<span className="requiredStar">*</span></Form.Label>
                                            <Form.Control as="select" required>
                                                <option>Printer</option>
                                                <option>Workstation</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormAssetModel" className="mb-3">
                                            <Form.Label>Model Name<span className="requiredStar">*</span></Form.Label>
                                            <Form.Control as="select" required>
                                                <option>Thinkpad 2100</option>
                                                <option>PureVision 1080p</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>
                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormSite" className="mb-3">
                                            <Form.Label>Assigned Site<span className="requiredStar">*</span></Form.Label>
                                            <Form.Control as="select" required>
                                                <option>Irvine Office</option>
                                                <option>ProGroup Irvine</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormLocationAtSite" className="mb-3">
                                            <Form.Label>Location at Site</Form.Label>
                                            <Form.Control as="select">
                                                <option>None</option>
                                                <option>1F Aisle 3 Desk 5</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormAssignedPerson" className="mb-3">
                                            <Form.Label>Person Assigned To</Form.Label>
                                            <Form.Control as="select">
                                                <option>None</option>
                                                <option>Test McTesterson</option>
                                                <option>Techy Test-Tech</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>

                                <div style={{ width: "100%" }}>
                                    <span style={{ display: "inline-block", width: "90%" }}>
                                        <Form.Group controlId="addFormAssetVendor" className="mb-3">
                                            <Form.Label>Vendor</Form.Label>
                                            <Form.Control as="select">
                                                <option>None</option>
                                                <option>McKesson</option>
                                                <option>ProGroup</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </span>
                                    <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                        <OverlayTrigger
                                            placement="top"
                                            overlay={
                                                <Tooltip>Click to add a new item</Tooltip>
                                            }
                                        >
                                            <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} />
                                        </OverlayTrigger>
                                    </span>
                                </div>
                                <Form.Group controlId="addFormAssetTag" className="mb-3">
                                    <Form.Label>Asset Tag</Form.Label>
                                    <Form.Control type="text" placeholder="ex: John Smith's Laptop" />
                                </Form.Group>
                                <Form.Group controlId="addFormAssetSerialNumber" className="mb-3">
                                    <Form.Label>Serial Number</Form.Label>
                                    <Form.Control type="text" placeholder="ex: 1234abc789" />
                                </Form.Group>
                                <Form.Group controlId="addFormAssetBarCode" className="mb-3">
                                    <Form.Label>Bar Code</Form.Label>
                                    <Form.Control type="text" placeholder="ex: 0447789" />
                                </Form.Group>
                                <Form.Group controlId="addFormAcquisitionDate" className="mb-3">
                                    <Form.Label>Acquistion Date</Form.Label>
                                    <Form.Control type="date" name="addFormAcquisitionDate"></Form.Control>
                                </Form.Group>

                                <Form.Group controlId="addFormWarrantyDates" className="mb-3">
                                    <Form.Label>Warranty Start and End Dates</Form.Label>
                                    <div>
                                        <span style={{ display: "inline-block" }}>
                                            <Form.Control type="date" name="addFormWarrantyStartDate"></Form.Control>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
                                            to
                                        </span>
                                        <span style={{ display: "inline-block" }}>
                                            <Form.Control type="date" name="addFormWarrantyEndDate"></Form.Control>
                                        </span>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="addFormLeaseDates" className="mb-3">
                                    <Form.Label>Lease Start and End Dates</Form.Label>
                                    <div>
                                        <span style={{ display: "inline-block" }}>
                                            <Form.Control type="date" name="addFormLeaseStartDate"></Form.Control>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
                                            to
                                        </span>
                                        <span style={{ display: "inline-block" }}>
                                            <Form.Control type="date" name="addFormLeaseEndDate"></Form.Control>
                                        </span>
                                    </div>
                                </Form.Group>

                                <Form.Group controlId="addFormManufacturerWebsite" className="mb-3">
                                    <Form.Label>Manufacturer Website</Form.Label>
                                    <Form.Control type="text" placeholder="ex: https://www.dell.com" />
                                </Form.Group>

                                <Form.Group controlId="addFormTrackAsset" className="mb-3">
                                    <Form.Label>Track Asset</Form.Label>
                                    <div className="mb-3">
                                        <Form.Check
                                            inline
                                            defaultChecked
                                            label="Yes"
                                            name="track asset radio"
                                            type="radio"
                                            id="Yes Track Asset Radio"
                                        />
                                        <Form.Check
                                            inline
                                            label="No"
                                            name="track asset radio"
                                            type="radio"
                                            id="No Do Not Track Asset Radio"
                                        />
                                    </div>
                                </Form.Group>

                                {/* NOTE: This field is used only for front end. It's not stored in the backend */}
                                <Form.Group controlId="addFormIsPeripheral" className="mb-3">
                                    <Form.Label>Is Peripheral</Form.Label>
                                    <div className="mb-3">
                                        <Form.Check
                                            inline
                                            label="Yes"
                                            name="peripheral radio"
                                            type="radio"
                                            id="Yes Is Peripheral Radio"
                                            onChange={() => this.toggleAddFormAssignedAssetInputVisible(true)}
                                        />
                                        <Form.Check
                                            inline
                                            defaultChecked
                                            label="No"
                                            name="peripheral radio"
                                            type="radio"
                                            id="Not Peripheral Radio"
                                            onChange={() => this.toggleAddFormAssignedAssetInputVisible(false)}
                                        />
                                    </div>
                                </Form.Group>

                                {this.state.enable_assigned_asset_input ?
                                    <div className="mb-3" style={{ width: "100%" }}>
                                        <span style={{ display: "inline-block", width: "33%" }}>
                                            <Form.Group controlId="addFormAssignedAssetCategory">
                                                <Form.Label>Assigned Asset Category</Form.Label>
                                                <Form.Control as="select">
                                                    <option>IT</option>
                                                    <option>Non-IT</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", width: "33%" }}>
                                            <Form.Group controlId="addFormAssignedAssetType">
                                                <Form.Label>Assigned Asset Type</Form.Label>
                                                <Form.Control as="select">
                                                    <option>Printer</option>
                                                    <option>Workstation</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", width: "33%" }}>
                                            <Form.Group controlId="addFormAssignedAssetName">
                                                <Form.Label>Assigned Asset Name</Form.Label>
                                                <Form.Control as="select">
                                                    <option>Lenovo Server 12345</option>
                                                    <option>Lenovo Monitor 6789</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                    </div> :

                                    <div className="mb-3" style={{ width: "100%" }}>
                                        <span style={{ display: "inline-block", width: "25%" }}>
                                            <Form.Group controlId="addFormAssignedAssetCategory">
                                                <Form.Label>Assigned Asset Category</Form.Label>
                                                <Form.Control as="select" disabled>
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", width: "25%" }}>
                                            <Form.Group controlId="addFormAssignedAssetType">
                                                <Form.Label>Assigned Asset Type</Form.Label>
                                                <Form.Control as="select" disabled>
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", width: "50%" }}>
                                            <Form.Group controlId="addFormAssignedAssetName">
                                                <Form.Label>Assigned Asset Name</Form.Label>
                                                <Form.Control as="select" disabled>
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                    </div>
                                }

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeAddModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.closeAddModal() }} className="gothamNarrowFont">Apply</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>

        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        hardwareAssetFilter: state.hardwareAssetFilter
    };
}

export default connect(mapStateToProps, {
    ACCESSTOKEN,
    LOGOUT,
    UPDATE_ACTIVE_LINK,
    UPDATE_SELECTED_TYPE,
    UPDATE_SELECTED_WARRANTY_STATUS,
    UPDATE_SELECTED_EXPIRATION_START_DATE,
    UPDATE_SELECTED_EXPIRATION_END_DATE,
    UPDATE_SELECTED_TENANT_ID,
    UPDATE_SELECTED_ASSIGNED_TO_USER,
    UPDATE_SELECTED_SERIAL_NUMBER,
    UPDATE_SELECTED_ASSET_TAG,
    UPDATE_SEARCH_TEXT_HARDWARE_ASSET,
    UPDATE_SORT_BY,
    UPDATE_SORT_ORDER
})(HardwareAssetList);