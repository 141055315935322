import React, { Component } from "react";
//import HardwareTicketDataService from "../services/hardware-ticket-activities";
//import { Link } from "react-router-dom";
import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import {Link} from 'react-router-dom';

import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

//import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';
import "bootstrap/dist/css/bootstrap.min.css";
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';

import server_image from '../images/server_image.jpeg';
import software_image from '../images/software_image.jpeg';



class TicketsHomepage extends Component {
    // constructor(props) {
    //     super(props);
    //     //this.retrieveTickets = this.retrieveTickets.bind(this);
    //     //this.setActiveTicket = this.setActiveTicket.bind(this);
    //     //this.onChangeSearchName = this.onChangeSearchName.bind(this);
    //     //this.searchName = this.searchName.bind(this);

    //     this.state = {
    //         tickets: [],
    //         //currentTicket: null,
    //         //currentIndex: -1,
    //         //searchName: ""
    //     };
    // }

    // componentDidMount() {           // token passed into props from the App.js file
        
    //     if(this.props.loggedStatus.loggedIn){
    //             this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //             this.props.ACCESSTOKEN(token);
    //             this.retrieveTickets(token);    // PASS TOKEN HERE
    //         });
    //     }

    // }

    // // onChangeSearchName(e) {
    // //     const searchName = e.target.value;

    // //     this.setState({
    // //         searchName: searchName
    // //     });
    // // }

    // retrieveTickets(token) {
    //     HardwareTicketDataService.getAll(token)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // setActiveTicket(ticket, index) {
    //     this.setState({
    //         currentTicket: ticket,
    //         currentIndex: index
    //     });
    // }

    // searchName() {
    //     TicketDataService.findByName(this.state.searchName)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }


    componentDidMount() {
        this.props.UPDATE_ACTIVE_LINK("ticketLanding");
    }


    render() {

        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }

        //const { searchName, accounts, currentAccount, currentIndex } = this.state;

        // const { tickets } = this.state;

        // const columns = [
        //     { title: "Date Created", data: "date_created" },
        //     { title: "Issue Text", data: "issue_text" }
        // ];

        // const click = (row) => {

        //     // SEND ACCESS TOKEN TO BACKEND TO GET REQUESTED INFO
            
        //     this.props.history.push({
        //         pathname: "/tickets/" + row.id,
        //         state: {
        //             accessToken: this.props.loggedStatus.accessToken
        //         }
        //     })
            
        // };

        return (
            <>
                <h1 style={{textAlign:"center", marginTop:"15px"}}>What Kind of Issue Do You Have?</h1>
                <h3 style={{textAlign:"center"}}>Select one below</h3>
                <CardDeck style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", marginTop:"125px"}}>
                    <Link className="ticketLandingLink" style={{ cursor: 'pointer', width: '25rem' }} to={"/hardwareTickets"}>
                        <Card className="ticketLandingCard">
                            <Card.Img variant="top" src={server_image} />
                            <Card.Body>
                            <Card.Title>Hardware</Card.Title>
                            <Card.Text>
                                Issues relating to physical components. 
                                For instance, your monitor won't display an image, 
                                or a printer won't print.
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                    <Link className="ticketLandingLink" style={{ cursor: 'pointer', width: '25rem' }} to={"/softwareTickets"}>
                        <Card className="ticketLandingCard">
                            <Card.Img variant="top" src={software_image} />
                            <Card.Body>
                            <Card.Title>Software</Card.Title>
                            <Card.Text>
                                Issues relating to software. For instance, licensing conerns, or a program keeps crashing.
                            </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </CardDeck>
            </>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_LINK})(TicketsHomepage);