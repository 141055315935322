import http from "../http-common";

class MyAssignedTicketsService {

    // get 10 assigned tickets fitting the search criteria (offset based on passed in value)
    getAssignedTickets(token, offset_value, status, type, assigned_tech_id, priority, search_text, sort_order, sort_by, user_id_asking) {
        //console.log('request backend token : ', token)
        return http.get(`/myAssignedTickets?offset=${offset_value}&status=${status}&type=${type}&assignedTechId=${assigned_tech_id}&priority=${priority}&searchText=${search_text}&sortOrder=${sort_order}&sortBy=${sort_by}&userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }
}

export default new MyAssignedTicketsService();