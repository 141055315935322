import http from "../http-common";

class UserDataService {

    checkStatus(token) {
        return http.get("/users/checkStatus", {headers: {Authorization: 'Bearer ' + token}});
    }

    registerNewUser(data, token) {
        return http.post("/users", data, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Returns a combination of both hardware and software tickets of specified user
    getAllMyTickets(id, token) {
        return http.get(`/users/${id}/tickets`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // Returns a combination of both hardware and software open tickets of specified user
    getMyOpenTickets(id, token) {
        return http.get(`/users/${id}/openTickets`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getMyOpenHardwareTickets(id, token) {
        return http.get(`/users/${id}/openHardwareTickets`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getMyOpenSoftwareTickets(id, token) {
        return http.get(`/users/${id}/openSoftwareTickets`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getAllMyHardwareTickets(id, token) {
        return http.get(`/users/${id}/hardwareTickets`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getAllMySoftwareTickets(id, token) {
        return http.get(`/users/${id}/softwareTickets`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getPermissions(id, token) {
        return http.get(`/users/${id}/permissions`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getRequesterList(tenant_id, user_id, token) {
        return http.get(`/users/requesterList?tenantId=${tenant_id}&userId=${user_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getUserList(tenantId, userIdAsking, roleId, offset, searchText, sortBy, sortOrder, token) {
        return http.get(`/users/userList?tenantId=${tenantId}&userIdAsking=${userIdAsking}&roleId=${roleId}&offset=${offset}&searchText=${searchText}&sortBy=${sortBy}&sortOrder=${sortOrder}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get the list of possible roles for display in the user list filter drop down
    getRoleList(token) {
        return http.get(`/users/roleList`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getUserProfileData(user_id, userIdAsking, token) {
        return http.get(`/users/${user_id}/userProfileData?userIdAsking=${userIdAsking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // get info on the ticket submitter for display in the information modal
    getTicketSubmitterDetails(submitter_id, token) {
        return http.get(`/users/ticketSubmitterDetails?submitterId=${submitter_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // edit a user record
    editUser(user_id, data, token) {
        return http.put(`/users/${user_id}/editUser`, data, {headers: {Authorization: 'Bearer ' + token}});
    }

    getTenantName(tenant_id, token) {
        return http.get(`/users/getTenantName?tenantId=${tenant_id}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    // getAll(token) {
    //     return http.get("/accounts", {headers: {Authorization: 'Bearer ' + token}});
    // }

    // create(data, token) {
    //     return http.post("/accounts", data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // get(id, token) {
    //     return http.get(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // update(id, data, token) {
    //     return http.put(`/accounts/${id}`, data, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // delete(id, token) {
    //     return http.delete(`/accounts/${id}`, {headers: {Authorization: 'Bearer ' + token}});
    // }

    // findByName(name) {
    //     return http.get(`/accounts?name=${name}`);
    // }
}


export default new UserDataService();