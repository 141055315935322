const initialState = {
    tenant_id_selected: 0,
    requester_id_selected: 0,
    requester_name_selected: "",
    requester_email_selected: "",

    requester_change_detected: false        // will be set to true if user selects different requester (in turn will lead to new asset data being pulled in)
};

function ticketRequesterReducer(state = initialState, action) {
    switch(action.type) {
        case "UPDATE_ACTIVE_REQUESTER":
            //console.log("Login event detected");
            //console.log(action.payload);
            if(action.payload) {
                return {
                    ...state,
                    tenant_id_selected: action.payload.tenantId,
                    requester_id_selected: action.payload.requesterId,
                    requester_name_selected: action.payload.requesterName,
                    requester_email_selected: action.payload.requesterEmail
                };
            }
            break;

        case "ALERT_REQUESTER_CHANGE_FROM_DROPDOWN":
            return {
                ...state,
                requester_change_detected: true     // will prompt hosting component to get updated asset info for new requester
            }

        case "REQUESTER_CHANGE_RESOLVED":
            return {
                ...state,
                requester_change_detected: false
            }

        default:
            return state;


    }
}

export default ticketRequesterReducer;