//import { LOGIN, LOGOUT } from "../actions/Is-Logged-Actions";

const initialState = {
    loggedIn: false,
    username: '',
    accessToken: '',
    id: 0,
    fullName: '',
    email: '',
    totalTickets: 0,
    totalOpenTickets: 0,

    getEmails: 0, // disable/enable global emails for hardware tickets
    getEmailsSoftware: 0, // disable/enable global emails for software tickets

    role_id: 0,      // role id = 1 is going to be forced as super admin

    // the number of hours to offset any time data retreived from backend database for proper display (based on browser location)
    // in form "-08:00", etc
    // gets set when user lands on the user home page
    timezone_adjustment_factor: '',
    primary_tenant_id: 0,        // the main tenant id where this user works
    report_tenant_id_selected: 0,       // the id of the tenant selected in the reports drop down
    report_tenant_name_selected: "All",     // the name of the tenant selected in the reports drop down
    report_tenant_change_detected: false,    // gets set to true if user clicks a different tenant in drop down - notifies the overdue tickets table to update on the reports screen
    report_tenant_change_detected_warning_tickets: false,    // notifies the warning / "hot" tickets table to update on the reports screen

    monitoring_tenant_id_selected: 0,       // the id of the tenant currently selected on the monitoring screen
    monitoring_tenant_name_selected: "",    // the name of the tenant currently selected on the monitoring screen

    dashboard_tenant_id_selected: 0,       // the id of the tenant currently selected on the dashboard screen
    dashboard_tenant_name_selected: ""    // the name of the tenant currently selected on the dashboard screen

};

function isLoggedReducer(state = initialState, action) {
    //console.log( 'IN REDUCER' );
    //console.log( action );
    switch (action.type) {
        case "LOGIN":
            //console.log("Login event detected");
            //console.log(action.payload);
            if (action.payload) {
                return {
                    ...state,
                    loggedIn: true,
                    username: action.payload,
                };
            }
            break;

        case "LOGOUT":
            return {
                ...state,
                loggedIn: false,
                username: '',
                accessToken: '',
                id: 0,
                fullName: '',
                email: '',
                totalTickets: 0,
                totalOpenTickets: 0,
                getEmails: 0,
                getEmailsSoftware: 0,
                role_id: 0,
                timezone_adjustment_factor: '',
                primary_tenant_id: 0,
                report_tenant_id_selected: 0,
                report_tenant_name_selected: "All",
                report_tenant_change_detected: false,   // notifies the overdue tickets table to update on the reports screen
                report_tenant_change_detected_warning_tickets: false,    // notifies the warning / "hot" tickets table to update on the reports screen
                monitoring_tenant_id_selected: 0,       // the id of the tenant currently selected on the monitoring screen
                monitoring_tenant_name_selected: "",    // the name of the tenant currently selected on the monitoring screen
                dashboard_tenant_id_selected: 0,       // the id of the tenant currently selected on the monitoring screen
                dashboard_tenant_name_selected: ""    // the name of the tenant currently selected on the monitoring screen
            };

        case "ACCESSTOKEN":
            //console.log("Access token update detected");
            if (action.payload) {
                return {
                    ...state,
                    accessToken: action.payload
                };
            }
            break;

        case "USER_DETAILS":
            if (action.payload) {
                //console.log("Payload received by reducer to set the initial global email status was: ", action.payload);
                return {
                    ...state,
                    id: action.payload.id,
                    fullName: action.payload.fullName,
                    email: action.payload.email,

                    getEmails: action.payload.getEmails,
                    getEmailsSoftware: action.payload.getEmailsSoftware,

                    role_id: action.payload.role_id,
                    primary_tenant_id: action.payload.primary_tenant_id
                }
            }
            break;

        case "SET_TIMEZONE_ADJUSTMENT_FACTOR":
            if (action.payload) {
                return {
                    ...state,
                    timezone_adjustment_factor: action.payload
                }
            }

            break;

        case "SET_REPORT_TENANT_VIEWING":
            if (action.payload) {
                return {
                    ...state,
                    report_tenant_id_selected: action.payload.report_tenant_id_selected,
                    report_tenant_name_selected: action.payload.report_tenant_name_selected
                }
            }

            break;

        case "SET_MONITORING_TENANT_VIEWING":
            if (action.payload) {
                return {
                    ...state,
                    monitoring_tenant_id_selected: action.payload.monitoring_tenant_id_selected,
                    monitoring_tenant_name_selected: action.payload.monitoring_tenant_name_selected
                }
            }

            break;

        case "SET_DASHBOARD_TENANT_VIEWING":
            if (action.payload) {
                return {
                    ...state,
                    dashboard_tenant_id_selected: action.payload.dashboard_tenant_id_selected,
                    dashboard_tenant_name_selected: action.payload.dashboard_tenant_name_selected
                }
            }

            break;

        case "ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN":
            return {
                ...state,
                report_tenant_change_detected: true
            }

        case "REPORT_TENANT_CHANGE_RESOLVED":
            return {
                ...state,
                report_tenant_change_detected: false
            }

        case "ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS":
            return {
                ...state,
                report_tenant_change_detected_warning_tickets: true
            }

        case "REPORT_TENANT_CHANGE_RESOLVED_WARNING_TICKETS":
            return {
                ...state,
                report_tenant_change_detected_warning_tickets: false
            }

        case "ENABLE_EMAILS":
            return {
                ...state,
                getEmails: true
            }


        case "DISABLE_EMAILS":
            return {
                ...state,
                getEmails: false
            }

        case "SOFTWARE_ENABLE_EMAILS":
            return {
                ...state,
                getEmailsSoftware: true
            }


        case "SOFTWARE_DISABLE_EMAILS":
            return {
                ...state,
                getEmailsSoftware: false
            }


        case "TOTAL_TICKET_COUNT":
            if (action.payload !== 0) {
                return {
                    ...state,
                    totalTickets: action.payload,
                }
            }

            else {
                return {
                    ...state,
                    totalTickets: 0
                }
            }
        //break;

        case "OPEN_TICKET_COUNT":
            if (action.payload !== 0) {
                return {
                    ...state,
                    totalOpenTickets: action.payload
                }
            }

            else {
                return {
                    ...state,
                    totalOpenTickets: 0
                }
            }
        //break;

        default:
            return state;


    }
}

export default isLoggedReducer;