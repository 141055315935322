// This screen will list all of the tickets that have been submitted, but have not had all of their details clarified

import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';

import HoldingTableDataService from '../../services/holding-table-activities';

import {Link} from 'react-router-dom';

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

const lateTicketThreshold = 15;     // (Currently represents number of minutes) Represents the number beyond which a ticket is considered to have a late response. determines color of text in time since created column.


class TicketHoldingArea extends Component {

    constructor(props) {
        super(props);

        this.retreiveNewEmailTickets = this.retreiveNewEmailTickets.bind(this);
        this.setTicketTimesSinceCreated = this.setTicketTimesSinceCreated.bind(this);
        this.handleCreationTimeSort = this.handleCreationTimeSort.bind(this);

        this.state = {
            ticketList: [],      // will be an array of objects returned from backend ex: {id: 1, requester_name: "Tom Johnson", ticket_title: "Sample title"}
            sortOrder: "asc"     // asc = oldest to newest, desc = newest to oldest
        }
    }

    componentDidMount() {
        if(this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    this.retreiveNewEmailTickets(token).then(response => {
                        this.setState({
                            ticketList: response.results
                        });

    console.log(response);
                    });
                }
            });

            this.props.UPDATE_ACTIVE_LINK("adminPanelTicketHoldingArea");  // Tell global state that this is the active page now (controls nav bar highlighting)
        }
    }

    // gets all of the tickets currently in the holding table and puts relevant info in the front end store
    retreiveNewEmailTickets(token) {
        return new Promise ((resolve, reject) => {

            HoldingTableDataService.getAll(token)
            .then(response => {
                resolve(response.data);
            })
            .catch(e => {
                console.log(e);
                reject(e);
            });
        });
    }

    // put the appropriate time since sent for each ticket returned
    setTicketTimesSinceCreated(tickets) {
        for(var item of tickets) {
            if(item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? " y" : " y");
            }

            else if(item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? " mon" : " mon");
            }

            else if(item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? " d" : " d");
            }

            else if(item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? " h" : " h");
            }

            else if(item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? " min" : " min");
            }

            else {
                item.time_since_sent = "just now";
            }
        }
    }

    createTicketsToDisplay() {
        let ticketsToDisplayTemp = [];

        for(let i =0; i < this.state.ticketList.length; i++) {
            if(this.state.ticketList.length > 0 && i < this.state.ticketList.length) { // make sure we're asking for a valid ticket
                ticketsToDisplayTemp.push(this.state.ticketList[i]);
            }
        }


        const tableRows = ticketsToDisplayTemp.map((ticket) => {
            return (
                <tr key={ticket.id}>
                    <td style={{width: "50%", cursor: "default"}}>
                        <div className="ticketTitleText gothamNarrowFont">
                            <Link to={`/clarifyTicket/${ticket.id}`} className="ticketTitleText">{ticket.ticket_title}</Link>
                        </div>
                    </td>
                    <td style={{width: "35%", cursor: "default"}} className="gothamNarrowFont">
                        {ticket.requester_name}
                    </td>
                    {ticket.minutes_since_sent >= lateTicketThreshold ?
                        <td style={{width: "15%", textAlign: "center", cursor: "default", color: "red"}} className="gothamNarrowFont">{ticket.time_since_sent}</td> :
                        <td style={{width: "15%", textAlign: "center", cursor: "default"}} className="gothamNarrowFont">{ticket.time_since_sent}</td>
                    }      
                </tr>
                
            )
        })

        return tableRows;
        
    }

    // gets called when user clicks the creation time table header flips between ascending and descending order
    handleCreationTimeSort() {
        if(this.state.sortOrder === "desc") {   // if currently ordered from oldest to newest, reverse order to newest (smallest min since sent) to oldest (greatest mins since sent)
            this.setState({
                sortOrder: "asc"
            });
            this.state.ticketList.sort((a,b) => (a.minutes_since_sent < b.minutes_since_sent) ? 1 : -1);
        }

        else {      // if currently ordered from newest to oldest, reverse order to oldest (greatest mins since sent) to newest (smallest min since sent)
            this.setState({
                sortOrder: "desc"
            });
            this.state.ticketList.sort((a,b) => (a.minutes_since_sent > b.minutes_since_sent) ? 1 : -1);
        }

    }

    render() {

        if(!this.props.loggedStatus.loggedIn || !this.props.userPermissions.can_process_tickets) {
            return (
                <Redirect to='/'/>
            );
        }

        const { ticketList } = this.state;

        // Figure out time to display for all the returned tickets
        if(ticketList) {
            this.setTicketTimesSinceCreated(ticketList);
        }

        return (
            <>
                <div className="container mt-3" style={{paddingTop: "75px"}}></div>
                <Container fluid>
                    <Row>
                        <Col md={{span: 8, offset: 2}}>
                            <h1 className="gothamNarrowFont" style={{textAlign: "center", width: "60%", marginLeft: "auto", marginRight: "auto"}}>Tickets Awaiting Processing</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={{span: 8, offset: 2}}>
                            <p className="gothamNarrowLightFont" style={{textAlign: "center", width: "60%", marginLeft: "auto", marginRight: "auto"}}>These tickets were received via email and await technician clarification. Click a ticket title to begin.</p>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col md={{span: 8, offset: 2}}>
                            <div className="scrollTableWrapper">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr className="ticketTableHead">
                                            <th className="ticketTableHeadNoHover" style={{position: "sticky", top: "0", width: "50%"}}>Ticket Title</th>
                                            <th className="ticketTableHeadNoHover" style={{position: "sticky", top: "0", width: "35%"}}>Creator</th>
                                            <th style={{position: "sticky", top: "0", width: "15%", textAlign: "center"}} onClick={() => this.handleCreationTimeSort()}>Time Since Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ticketList && this.createTicketsToDisplay()}
                                        {/* <tr>
                                            <td>Test title 1</td>
                                            <td>King George</td>
                                            <td>4h</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 2</td>
                                            <td>George Washington</td>
                                            <td>2h</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 3</td>
                                            <td>Alexander the Great</td>
                                            <td>30min</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 4</td>
                                            <td>King George</td>
                                            <td>20min</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 5</td>
                                            <td>George Washington</td>
                                            <td>10min</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 6</td>
                                            <td>Alexander the Great</td>
                                            <td>5min</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 7</td>
                                            <td>King George</td>
                                            <td>1min</td>
                                        </tr>
                                        <tr>
                                            <td>This is an incredibly long title. Very very long. Going to ramble for a long time. Testing limits of length for this ticket title column before ... needs to be substituted for more letters because it's gotten too long.</td>
                                            <td>George Washington</td>
                                            <td>Just now</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 9</td>
                                            <td>Alexander the Great</td>
                                            <td>Just now</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 10</td>
                                            <td>King George</td>
                                            <td>Just now</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 11</td>
                                            <td>George Washington</td>
                                            <td>Just now</td>
                                        </tr>
                                        <tr>
                                            <td>Test title 12</td>
                                            <td>Alexander the Great</td>
                                            <td>Just now</td>
                                        </tr> */}
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_LINK, ACCESSTOKEN, LOGOUT})(TicketHoldingArea);