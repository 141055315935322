import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Nav from 'react-bootstrap/Nav';
import { Doughnut, defaults } from 'react-chartjs-2';

// const chartData = {
//     labels: ['January', 'February', 'March',
//              'April', 'May'],
//     datasets: [
//       {
//         label: 'Rainfall',
//         backgroundColor: [
//           '#B21F00',
//           '#C9DE00',
//           '#2FDE00',
//           '#00A6B4',
//           '#6800B4'
//         ],
//         hoverBackgroundColor: [
//         '#501800',
//         '#4B5000',
//         '#175000',
//         '#003350',
//         '#35014F'
//         ],
//         data: [65, 59, 80, 81, 56]
//       }
//     ]
//   }

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

class ChartWidget extends Component {


    constructor(props) {
        super(props); 
 
        this.state = {
            selectedTicketTab: "unansweredTicketPercent",        // Will control the chart displayed
            totalTicketNumber: 0,
            totalOpenTickets: 0
        };

        // this.getMyOpenTickets = this.getMyOpenTickets.bind(this);
        // this.getAllMyTickets = this.getAllMyTickets.bind(this);

    }

    // componentDidMount() {
    //     // this.getMyOpenTicketsNumber();
    //     // this.getAllMyTicketsNumber();
    // }

    // changeActiveLink(pressedLink) {
    //     this.setState({
    //         activeLink: pressedLink
    //     });
    // }


    render() {
        //const isLoggedIn = this.props.loggedStatus.loggedIn;

        defaults.global.defaultFontFamily = 'Gotham Narrow';

        let chartToDisplay;

        let total_open_tickets;
        let total_tickets;

        if(this.props.loggedStatus.totalOpenTickets === null) {
            total_open_tickets = 0;
        }

        else {
            total_open_tickets = this.props.loggedStatus.totalOpenTickets;
        }

        if(this.props.loggedStatus.totalTickets === null) {
            total_tickets = 0;
        }

        else {
            total_tickets = this.props.loggedStatus.totalTickets;
        }

        const proportionUnansweredChart = {
            labels: ['Open', 'Resolved'],
            datasets: [
              {
                label: 'Proportion of Tickets',
                backgroundColor: [
                  '#FF6700',
                  '#19B5FE'
                ],
                hoverBackgroundColor: [
                '#501800',
                '#4B5000'
                ],
                data: [total_open_tickets, total_tickets - total_open_tickets]
              }
            ]
        }

        const rainfallChartData = {
            labels: ['January', 'February', 'March',
                    'April', 'May'],
            datasets: [
            {
                label: 'Rainfall',
                backgroundColor: [
                '#B21F00',
                '#C9DE00',
                '#2FDE00',
                '#00A6B4',
                '#6800B4'
                ],
                hoverBackgroundColor: [
                '#501800',
                '#4B5000',
                '#175000',
                '#003350',
                '#35014F'
                ],
                data: [65, 59, 80, 81, 56]
            }
            ]
        }

        switch (this.state.selectedTicketTab) {
            case "unansweredTicketPercent":
                chartToDisplay = <Doughnut
                data={proportionUnansweredChart}
                height={220}
                /* width={220} */
                options={{
                    title:{
                    display:true,
                    text:'Proportion of Open Tickets to Resolved',
                    fontSize:20
                    },
                    legend:{
                    display:true,
                    position:'chartArea'
                    },
                    maintainAspectRatio: false
                }}
                />;
            break;

            case "another":
                chartToDisplay = <Doughnut
                data={rainfallChartData}
                height={220}
                /* width={220} */
                options={{
                    title:{
                    display:true,
                    text:'Average Rainfall Per Month',
                    fontSize:20
                    },
                    legend:{
                    display:true,
                    position:'chartArea'
                    },
                    maintainAspectRatio: false
                }}
                />;

            break;

            default:
                chartToDisplay = <></>;

        }


        return (
            <Card /* className="text-center" */ style={{flex: "1", height: "300px"}}>
                <Card.Header style={{backgroundColor: "#343a40"}}>
                    <Nav variant="tabs" defaultActiveKey="#unansweredTicketPercent">
                        <Nav.Item>
                            <Nav.Link href="#unansweredTicketPercent" onClick={() => this.setState({selectedTicketTab: "unansweredTicketPercent"})} className="gothamNarrowFont">% Tickets Unanswered</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link href="#another" onClick={() => this.setState({selectedTicketTab: "another"})} className="gothamNarrowFont">Another</Nav.Link>
                        </Nav.Item>
                    </Nav>
                </Card.Header>
                <Card.Body>                  
                    <div style={{maxHeight: "230px", maxWidth: "100%", overflow: "auto"}}>
                        {chartToDisplay}
                    </div>
                </Card.Body>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav    // Use global state to control which link is highlighted
    };
}

export default connect(mapStateToProps)(ChartWidget);
