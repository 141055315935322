import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Navbar from '../../components/Navbar';
import {
    Button,
    Grid,
    Modal,
    Paper,
    Select,
    Table,
    Text,
    Textarea,
    TextInput,
    Tooltip
} from '@mantine/core';

import { IconPlus } from "@tabler/icons-react";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPlus } from "@fortawesome/free-solid-svg-icons";

import HardwareAssetDataService from '../../services/hardware-asset-activities';
import SoftwareAssetDataService from '../../services/software-asset-activities';
import UserDataService from '../../services/user-activities';
import { Form } from 'react-bootstrap';


class CreateSoftwareAsset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant_name: "",     // name of tenant we are adding this asset to
            tenant_id : 0,

            publishers: [{id: 0, name_of_manufacturer: "None"}],
            managedByList: [{id: 0, name: "None"}],

            software_name_entered: "",
            software_type_selected: "",
            publisher_id_selected: 0,
            publisher_name_selected: "",
            software_status_selected: "",
            managed_by_id_selected: 0,
            managed_by_name_selected: "",
            notes_entered: "",
            description_entered: "",


            canSeeErrorMessage: false,
            canSeeDuplicateNameError: false,


            // the following are for the create publisher modal
            show_publisher_modal: false,
            create_publisher_name_entered: "",
            canSeeErrorMessageCreatePublisher: false,
            canSeeDuplicateNameErrorCreatePublisher: false
        };
    }

    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    UserDataService.getTenantName(this.props.loggedStatus.primary_tenant_id, token).then(response => {
                        
                        if(response.data.results && response.data.results.length > 0) {
                            this.setState({
                                tenant_name: response.data.results[0].name_of_tenant,
                                tenant_id: response.data.results[0].id
                            });
                        }

                        // get the data for the publishers and managed by form drop downs
                        SoftwareAssetDataService.getDropdownValuesForSoftwareAssetFormAndFilters(token, this.props.loggedStatus.primary_tenant_id).then(response => {

                            this.setState({
                                publishers: [...this.state.publishers, ...response.data.publishers],
                                managedByList: [...this.state.managedByList, ...response.data.managers]
                            });
                        });
                    });
                }
            });
        }
    }

    handleSoftwareNameChange(newValue) {
        this.setState({
            software_name_entered: newValue
        });
    }

    handleSoftwareTypeChange(newValue) {
        this.setState({
            software_type_selected: newValue
        });
    }

    handlePublisherChange(newName, newId) {
        this.setState({
            publisher_id_selected: newId,
            publisher_name_selected: newName
        });
    }

    handleSoftwareStatusChange(newValue) {
        this.setState({
            software_status_selected: newValue
        });
    }

    handleManagedByChange(newName, newId) {
        this.setState({
            managed_by_id_selected: newId,
            managed_by_name_selected: newName
        });
    }

    handleNotesChange(newValue) {
        this.setState({
            notes_entered: newValue
        });
    }

    handleDescriptionChange(newValue) {
        this.setState({
            description_entered: newValue
        });
    }

    submitSoftwareAsset() {
        // console.log("Preparing to send the following to backend");
        // console.log("Tenant Id: ", this.state.tenant_id);
        // console.log("Tenant Name: ", this.state.tenant_name);
        // console.log("Software Name: ", this.state.software_name_entered);
        // console.log("Software Type: ", this.state.software_type_selected);
        // console.log("Publisher Id: ", this.state.publisher_id_selected);
        // console.log("Publisher Name: ", this.state.publisher_name_selected);
        // console.log("Software Status: ", this.state.software_status_selected);
        // console.log("Managed By Id: ", this.state.managed_by_id_selected);
        // console.log("Managed By Name: ", this.state.managed_by_name_selected);
        // console.log("Notes: ", this.state.notes_entered);
        // console.log("Description: ", this.state.description_entered);
        
        //make sure all required fields are filled out
        if (this.state.software_name_entered === "" || this.state.software_type_selected === "") {
            this.setState({
                canSeeErrorMessage: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessage: false
            });

            // get data ready for submission to backend


            let data = {
                tenantId: this.state.tenant_id,
                //console.log("Tenant Name: ", this.state.tenant_name);
                softwareName: this.state.software_name_entered,
                softwareType: this.state.software_type_selected,
                publisherId: this.state.publisher_id_selected,
                //console.log("Publisher Name: ", this.state.publisher_name_selected);
                softwareStatus: this.state.software_status_selected,
                managedById: this.state.managed_by_id_selected,
                //console.log("Managed By Name: ", this.state.managed_by_name_selected);
                notes: this.state.notes_entered,
                description: this.state.description_entered,
                creatorId: this.props.loggedStatus.id   // id of the user performing this action - used for history note generation
            };

            console.log(data);

            SoftwareAssetDataService.createNewSoftwareAsset(this.props.loggedStatus.accessToken, data).then(response => {
                if(response.data.results === "DUPLICATE FOUND!") {
                    this.setState({
                        canSeeDuplicateNameError: true
                    });
                }

                else {                
                    this.props.history.push('/softwareAssets');
                }
            })
            .catch(e => {
                console.log(e);
            });

        }

    }

    // open modal, reset defaults
    openPublisherModal() {
        this.setState({
            show_publisher_modal: true,
            create_publisher_name_entered: "",
            canSeeErrorMessageCreatePublisher: false,
            canSeeDuplicateNameErrorCreatePublisher: false
        });
    }

    // close modal, reset defaults
    closePublisherModal() {
        this.setState({
            show_publisher_modal: false,
            create_publisher_name_entered: "",
            canSeeErrorMessageCreatePublisher: false,
            canSeeDuplicateNameErrorCreatePublisher: false
        });
    }

    handleCreatePublisherNameChange(newValue) {
        this.setState({
            create_publisher_name_entered: newValue
        });
    }


    submitCreatePublisherForm() {
        if (this.state.create_publisher_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreatePublisher: false,
                canSeeErrorMessageCreatePublisher: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreatePublisher: false
            });

            //check for duplicate name / type combo
            HardwareAssetDataService.checkForDuplicateManufacturer(this.props.loggedStatus.accessToken, this.state.create_publisher_name_entered, "Software", this.props.loggedStatus.primary_tenant_id).then(response => {

                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreatePublisher: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreatePublisher: false
                    });

                    // send form data to backend

                    let data = {
                        manufacturerName: this.state.create_publisher_name_entered,
                        manufacturerType: "Software",
                        tenantId: this.props.loggedStatus.primary_tenant_id
                    };


                    HardwareAssetDataService.createManufacturer(this.props.loggedStatus.accessToken, data).then(response => {
                        SoftwareAssetDataService.getDropdownValuesForSoftwareAssetFormAndFilters(this.props.loggedStatus.accessToken, this.props.loggedStatus.primary_tenant_id).then(response => {

                            this.setState({
                                publishers: [{id: 0, name_of_manufacturer: "None"}, ...response.data.publishers],
                            });

                            this.closePublisherModal();     // close modal and reset form defaults
                        });
                        // this.getManufacturers(this.props.loggedStatus.accessToken).then(response => {
                        //     this.setState({
                        //         manufacturers: [{ id: 0, name_of_manufacturer: "None" }, ...response.results],
                        //         manufacturer_id_selected: 0,
                        //         manufacturer_name_entered: "",      // reset form fields
                        //         manufacturer_type_entered: "",
                        //         manufacturer_phone_number_entered: ""
                        //     });

                        //     this.closePublisherModal();
                        // })
                        //     .catch(e => {
                        //         console.log(e);
                        //     })
                    });

                }

            });

        }
    }


    render() {
        if (!this.props.loggedStatus.loggedIn || (/* !this.props.userPermissions.can_see_assets && !this.props.userPermissions.can_edit_assets &&  */ !this.props.userPermissions.can_add_assets /* && !this.props.userPermissions.can_delete_assets */)) {
            return (
                <Redirect to='/' />
            );
        }


        return (
            <>
                <Navbar pageTitle={"Create Software Asset"} />
                <div style={{ paddingTop: "15px" }} />
                <Container fluid pt="20px">
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Grid>
                            <Grid.Col span={6}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Tenant Adding Asset To</Table.Td>
                                        <Table.Td c="blue">{this.state.tenant_name}</Table.Td>
                                    </Table.Tr>
                                </Table>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px" style={{height: "450px"}}>
                        <div style={{marginTop: "15px"}}>
                            <Form>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="addSoftwareName">
                                        <span style={{ display: "inline-block", width: "95%" }}><TextInput label="Software Name" placeholder="Enter Name Here" value={this.state.software_name_entered} onChange={(e) => this.handleSoftwareNameChange(e.target.value)} required /></span>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="addSoftwareType">
                                        <span style={{ display: "inline-block", width: "95%" }}><Select 
                                            label="Software Type"
                                            placeholder="Pick a Value"
                                            data={["Desktop", "Mobile", "Saas"]}
                                            value={this.state.software_type_selected}
                                            onChange={(s) => {this.handleSoftwareTypeChange(s)}}
                                            allowDeselect={false}
                                            required
                                        /></span>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="addSoftwarePublisher">
                                        <div>
                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                label="Manufacturer"
                                                placeholder="Pick a Value"
                                                data={this.state.publishers && this.state.publishers.map((publisher) => (
                                                    publisher.name_of_manufacturer
                                                ))}
                                                value={
                                                        this.state.publisher_id_selected !== 0 && this.state.publishers.length > 0 && this.state.publishers && this.state.publishers.find((e) => e.id === this.state.publisher_id_selected) ?
                                                            this.state.publishers.find((e) => e.id === this.state.publisher_id_selected).name_of_manufacturer
                                                        :
                                                        ""  
                                                }
                                                onChange={(s) => {
                                                        var pubId = this.state.publishers.find((e) => e.name_of_manufacturer === s).id;
                                                        if(pubId !== null) {
                                                            this.handlePublisherChange(s, pubId);
                                                        }
                                                    }
                                                }
                                                allowDeselect={false}
                                                searchable
                                            /></span>
                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                <Tooltip label="Click to create a new manufacturer"><IconPlus color="#00abfb" onClick={() => this.openPublisherModal()}/></Tooltip>
                                            </span>
                                        </div>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="addSoftwareStatus">
                                        <span style={{ display: "inline-block", width: "95%" }}><Select 
                                            label="Status"
                                            placeholder="Pick a Value"
                                            data={["Discovered", "Disabled", "Ignored", "In Review", "Managed", "Restricted"]}
                                            value={this.state.software_status_selected}
                                            onChange={(s) => {this.handleSoftwareStatusChange(s)}}
                                            allowDeselect={false}
                                        /></span>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="addSoftwareManagedBy" xs={6}>
                                        <span style={{ display: "inline-block", width: "95%" }}><Select 
                                            label="Managed By"
                                            placeholder="Pick a Value"
                                            data={this.state.managedByList && this.state.managedByList.map((manager) => (
                                                manager.name
                                            ))}
                                            value={
                                                    this.state.managed_by_id_selected !== 0 && this.state.managedByList.length > 0 && this.state.managedByList && this.state.managedByList.find((e) => e.id === this.state.managed_by_id_selected) ?
                                                        this.state.managedByList.find((e) => e.id === this.state.managed_by_id_selected).name
                                                    :
                                                    ""  
                                            }
                                            onChange={(s) => {
                                                    var manId = this.state.managedByList.find((e) => e.name === s).id;
                                                    if(manId !== null) {
                                                        this.handleManagedByChange(s, manId);
                                                    }
                                                }
                                            }
                                            allowDeselect={false}
                                            searchable
                                        /></span>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="addSoftwareNotes">
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Textarea label="Notes" placeholder="Enter Notes Here" value={this.state.notes_entered} onChange={(e) => this.handleNotesChange(e.target.value)}/>
                                        </span>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="addSoftwareDescription" xs={6}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Textarea label="Description" placeholder="Enter Description Here" value={this.state.description_entered} onChange={(e) => this.handleDescriptionChange(e.target.value)}/>
                                        </span>
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                    </Paper>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.props.history.push(`/softwareAssets`)}>Cancel</Button></span>
                        <span><Button onClick={() => this.submitSoftwareAsset()}>Submit</Button></span>
                    </div>
                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                        {this.state.canSeeErrorMessage ?
                            <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields before submitting.</Text>
                            :
                            undefined
                        }
                        {this.state.canSeeDuplicateNameError ?
                            <Text c="red">Error: An asset with this name already exists. Please try a different name.</Text>
                            :
                            undefined
                        }
                    </div>
                </Container>

                <Modal
                    opened={this.state.show_publisher_modal}
                    onClose={() => this.closePublisherModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create a Manufacturer</Text></div>
                        <Form.Group as={Col} controlId="addSoftwareFormCreatePublisherName" required>
                            <TextInput label="Manufacturer Name" placeholder="Enter Value Here" value={this.state.create_publisher_name_entered} onChange={(e) => this.handleCreatePublisherNameChange(e.target.value)} />
                        </Form.Group>
                    </Form>
                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                        {this.state.canSeeErrorMessageCreatePublisher ?
                            <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                            :
                            undefined
                        }
                        {this.state.canSeeDuplicateNameErrorCreatePublisher ?
                            <Text c="red">Error: A publisher with this name already exists. Please try again.</Text>
                            :
                            undefined
                        }
                    </div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closePublisherModal()}>Cancel</Button></span>
                        <span><Button onClick={() => this.submitCreatePublisherForm()}>Submit</Button></span>
                    </div>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT })(CreateSoftwareAsset);

// import React, { Component } from 'react';
// import { Redirect } from 'react-router';
// import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
// import { connect } from 'react-redux';

// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';

// import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
// import Modal from 'react-bootstrap/Modal';
// import Navbar from '../../components/Navbar';
// import {
//     Paper,
// } from '@mantine/core';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import HardwareAssetDataService from '../../services/hardware-asset-activities';
// import SoftwareAssetDataService from '../../services/software-asset-activities';


// class CreateSoftwareAsset extends Component {

//     constructor(props) {
//         super(props);

//         //this.determineTimezoneOffset = this.determineTimezoneOffset.bind(this);
//         this.getTenantList = this.getTenantList.bind(this);
//         this.getSites = this.getSites.bind(this);
//         this.getManufacturers = this.getManufacturers.bind(this);
//         this.getVendors = this.getVendors.bind(this);
//         this.handleSelectedSiteChange = this.handleSelectedSiteChange.bind(this);
//         this.handleTenantChange = this.handleTenantChange.bind(this);
//         this.handleCreateModelManufacturerChange = this.handleCreateModelManufacturerChange.bind(this);
//         this.handleSelectedVendorChange = this.handleSelectedVendorChange.bind(this);
//         this.handleAssetNameEnteredChange = this.handleAssetNameEnteredChange.bind(this);
//         this.handleVersionNumberEnteredChange = this.handleVersionNumberEnteredChange.bind(this);
//         this.handleNumberDevicesAllowedChange = this.handleNumberDevicesAllowedChange.bind(this);
//         this.handleLicenseNumberEnteredChange = this.handleLicenseNumberEnteredChange.bind(this);
//         this.handleLicenseTypeEnteredChange = this.handleLicenseTypeEnteredChange.bind(this);
//         this.handleWarrantyStartDateChange = this.handleWarrantyStartDateChange.bind(this);
//         this.handleWarrantyEndDateChange = this.handleWarrantyEndDateChange.bind(this);
//         this.handleLicenseStartDateChange = this.handleLicenseStartDateChange.bind(this);
//         this.handleLicenseEndDateChange = this.handleLicenseEndDateChange.bind(this);
//         this.handleUseCaseEnteredChange = this.handleUseCaseEnteredChange.bind(this);
//         this.handleCreateModelCostChange = this.handleCreateModelCostChange.bind(this);

//         this.submitAddAssetForm = this.submitAddAssetForm.bind(this);

//         this.closeAddPage = this.closeAddPage.bind(this);

//         this.handleCreateManufacturerNameChange = this.handleCreateManufacturerNameChange.bind(this);
//         this.handleCreateManufacturerTypeChange = this.handleCreateManufacturerTypeChange.bind(this);
//         this.handleCreateManufacturerPhoneChange = this.handleCreateManufacturerPhoneChange.bind(this);
//         this.submitCreateManufacturerForm = this.submitCreateManufacturerForm.bind(this);

//         this.handleCreateSiteNameChange = this.handleCreateSiteNameChange.bind(this);
//         this.handleCreateSiteStreetAddressChange = this.handleCreateSiteStreetAddressChange.bind(this);
//         this.handleCreateSiteCityChange = this.handleCreateSiteCityChange.bind(this);
//         this.handleCreateSiteStateChange = this.handleCreateSiteStateChange.bind(this);
//         this.handleCreateSiteZipCodeChange = this.handleCreateSiteZipCodeChange.bind(this);
//         this.submitCreateSiteForm = this.submitCreateSiteForm.bind(this);

//         this.handleCreateVendorNameChange = this.handleCreateVendorNameChange.bind(this);
//         this.submitCreateVendorForm = this.submitCreateVendorForm.bind(this);

//         this.state = {
//             tenants: [],        // required field, so None is NOT a valid option here
//             sites: [{ id: 0, name_of_site: "None" }],      // used for site restriction drop down. none is a valid option
//             vendors: [{ id: 0, name_of_vendor: "None" }],        // {id: 1, name_of_vendor: "EMC"}
//             manufacturers: [{ id: 0, name_of_manufacturer: "None" }],      // {id: 1, name_of_manufacturer: "Dell"}

//             selected_tenant_id: 0,
//             selected_site_id: 0,
//             selected_vendor_id: 0,
//             manufacturer_id_selected: 0,        // naming borrowed from hardware asset code

//             asset_name_entered: "",
//             version_number_entered: "",
//             number_devices_allowed_entered: 0,
//             number_devices_used_entered: 0,
//             license_number_entered: "",
//             license_type_entered: "",
//             license_start_date_entered: "",
//             license_end_date_entered: "",
//             warranty_start_date_entered: "",
//             warranty_end_date_entered: "",
//             use_case_entered: "",
//             cost_entered: "",


//             // view modal controllers
//             show_manufacturer_modal: false,
//             show_site_modal: false,
//             show_vendor_modal: false,

//             // manufacturer form
//             manufacturer_name_entered: "",
//             manufacturer_type_entered: "",
//             manufacturer_phone_number_entered: "",

//             // site form
//             // tenant_id_selected is based on selected_tenant_id from core asset form above
//             site_name_entered: "",
//             site_street_address_entered: "",
//             site_city_entered: "",
//             site_state_entered: "",
//             site_zip_code_entered: "",

//             // vendor form
//             vendor_name_entered: "",

//             canSeeErrorMessage: false,

//             canSeeErrorMessageCreateManufacturer: false,
//             canSeeDuplicateNameErrorCreateManufacturer: false,

//             canSeeDuplicateNameErrorCreateSite: false,
//             canSeeErrorMessageCreateSite: false,

//             canSeeDuplicateNameErrorCreateVendor: false,
//             canSeeErrorMessageCreateVendor: false
//         };
//     }


//     componentDidMount() {
//         if (this.props.loggedStatus.loggedIn) {
//             this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
//                 // if the token can't be refreshed, force a logout
//                 if (token === undefined || token === null || token === "") {
//                     console.log("Token was expired and can't refresh, so force logging out this user!");
//                     this.props.LOGOUT();        // logout of global state
//                     this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
//                 }

//                 else {
//                     this.props.ACCESSTOKEN(token);

//                     this.getTenantList(token, this.props.loggedStatus.id).then(response => {
//                         this.setState({
//                             tenants: response.results
//                         });

//                         let selectedTenantTemp;     // will briefly contain the value of the tenant in the first position returned, or 0 if none

//                         if (response.results && response.results.length > 0) {
//                             this.setState({
//                                 selected_tenant_id: response.results[0].id
//                             });

//                             selectedTenantTemp = response.results[0].id;
//                         }

//                         else {
//                             selectedTenantTemp = 0;
//                         }

//                         this.getSites(token, selectedTenantTemp).then(response => {
//                             if (response.results && response.results.length > 0) {
//                                 /* this.setState({
//                                     selected_site_id: response.results[0].id
//                                 }); */
//                                 this.setState({
//                                     sites: [{ id: 0, name_of_site: "None" }, ...response.results],
//                                     selected_site_id: 0
//                                 });
//                             }

//                             else {
//                                 this.setState({
//                                     sites: [{ id: 0, name_of_site: "None" }],
//                                     selected_site_id: 0
//                                 });
//                             }

//                             this.getManufacturers(token).then(response => {
//                                 /* this.setState({
//                                     manufacturers: response.results
//                                 }); */

//                                 if (response.results && response.results.length > 0) {
//                                     this.setState({
//                                         manufacturers: [{ id: 0, name_of_manufacturer: "None" }, ...response.results],
//                                         manufacturer_id_selected: 0
//                                     });
//                                 }

//                                 else {
//                                     this.setState({
//                                         manufacturers: [{ id: 0, name_of_manufacturer: "None" }],
//                                         manufacturer_id_selected: 0
//                                     });
//                                 }

//                                 this.getVendors(token).then(response => {

//                                     if (response.results && response.results.length > 0) {
//                                         this.setState({
//                                             vendors: [{ id: 0, name_of_vendor: "None" }, ...response.results],
//                                             selected_vendor_id: 0
//                                         });
//                                     }

//                                     else {
//                                         this.setState({
//                                             vendors: [{ id: 0, name_of_vendor: "None" }],
//                                             selected_vendor_id: 0
//                                         });
//                                     }
//                                 })
//                                     .catch(e => {
//                                         console.log(e);
//                                     });
//                             })
//                                 .catch(e => {
//                                     console.log(e);
//                                 });
//                         })
//                             .catch(e => {
//                                 console.log(e);
//                             });

//                     })
//                         .catch(e => {
//                             console.log(e);
//                         });
//                 }
//             });

//             //this.props.UPDATE_ACTIVE_LINK("userHome");  // Tell global state that this is the active page now (controls nav bar highlighting)
//         }
//     }

//     // gets list of tenant names this tech is authorized to see for the filter drop down menu
//     getTenantList(token, asker_id) {
//         return new Promise((resolve, reject) => {

//             HardwareAssetDataService.getTenants(token, asker_id)
//                 .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
//                     resolve(response.data);
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     reject(e);
//                 });
//         });
//     }

//     // gets the list of sites based on company user has selected for company drop down menu
//     getSites(token, tenantId) {
//         return new Promise((resolve, reject) => {

//             HardwareAssetDataService.getSites(token, tenantId)
//                 .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
//                     resolve(response.data);
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     reject(e);
//                 });
//         });
//     }

//     // gets list of manufacturers for the add hardware asset drop down menu
//     getManufacturers(token) {
//         return new Promise((resolve, reject) => {

//             HardwareAssetDataService.getManufacturers(token)
//                 .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
//                     resolve(response.data);
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     reject(e);
//                 });
//         });
//     }

//     // gets list of vendors for the add hardware asset drop down menu
//     getVendors(token) {
//         return new Promise((resolve, reject) => {

//             HardwareAssetDataService.getVendors(token)
//                 .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
//                     resolve(response.data);
//                 })
//                 .catch(e => {
//                     console.log(e);
//                     reject(e);
//                 });
//         });
//     }

//     handleTenantChange(tenantId) {
//         this.setState({
//             selected_tenant_id: tenantId
//         });

//         // get new sites for this new tenant
//         this.getSites(this.props.loggedStatus.accessToken, tenantId).then(response => {
//             /* this.setState({
//                 sites: [{id: 0, name_of_site: "None"}, ...response.results]
//             }); */

//             if (response.results && response.results.length > 0) {
//                 /* this.setState({
//                     selected_site_id: response.results[0].id
//                 }); */
//                 this.setState({
//                     sites: [{ id: 0, name_of_site: "None" }, ...response.results],
//                     selected_site_id: 0
//                 });
//             }

//             else {
//                 this.setState({
//                     sites: [{ id: 0, name_of_site: "None" }],
//                     selected_site_id: 0
//                 });
//             }
//         })
//             .catch(e => {
//                 console.log(e);
//             });

//     }

//     handleSelectedSiteChange(siteId) {
//         this.setState({
//             selected_site_id: siteId
//         });
//     }

//     handleCreateModelManufacturerChange(manufacturerId) {
//         this.setState({
//             manufacturer_id_selected: manufacturerId
//         });
//     }

//     handleSelectedVendorChange(vendorId) {
//         this.setState({
//             selected_vendor_id: vendorId
//         });
//     }

//     handleAssetNameEnteredChange(e) {
//         this.setState({
//             asset_name_entered: e.target.value
//         });
//     }

//     handleVersionNumberEnteredChange(e) {
//         this.setState({
//             version_number_entered: e.target.value
//         });
//     }

//     handleNumberDevicesAllowedChange(e) {
//         // they have cleared the field
//         /* if(e.target.value === "") {
//             this.setState({
//                 number_devices_allowed_entered: ""
//             });
//         } */

//         if (Number.isInteger(Number(e.target.value))) {
//             this.setState({
//                 number_devices_allowed_entered: e.target.value
//             });
//         }

//         else {
//             this.setState({
//                 number_devices_allowed_entered: ""
//             });
//         }
//     }

//     handleLicenseNumberEnteredChange(e) {
//         this.setState({
//             license_number_entered: e.target.value
//         });
//     }

//     handleLicenseTypeEnteredChange(e) {
//         this.setState({
//             license_type_entered: e.target.value
//         });
//     }

//     handleWarrantyStartDateChange(e) {
//         this.setState({
//             warranty_start_date_entered: e.target.value
//         });
//     }

//     handleWarrantyEndDateChange(e) {
//         this.setState({
//             warranty_end_date_entered: e.target.value
//         });
//     }

//     handleLicenseStartDateChange(e) {
//         this.setState({
//             license_start_date_entered: e.target.value
//         });
//     }

//     handleLicenseEndDateChange(e) {
//         this.setState({
//             license_end_date_entered: e.target.value
//         });
//     }

//     handleUseCaseEnteredChange(e) {
//         this.setState({
//             use_case_entered: e.target.value
//         });
//     }

//     handleCreateModelCostChange(e) {

//         //console.log(!!e.target.value.match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/));

//         // they have cleared the field
//         if (e.target.value === "") {
//             this.setState({
//                 cost_entered: ""
//             });
//         }

//         // they input an integer
//         else if (Number.isInteger(Number(e.target.value))) {
//             this.setState({
//                 cost_entered: parseInt(e.target.value)
//             });
//         }

//         // they have input a float
//         else if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') !== -1) {

//             //let input = parseFloat(e.target.value);

//             this.setState({
//                 cost_entered: parseFloat(e.target.value).toFixed(2)
//                 //cost_entered: e.target.value
//             });
//         }

//         else {
//             console.log("Bad input");
//         }

//     }

//     submitAddAssetForm() {
//         console.log("Values Submitting to Backend...");
//         console.log("Tenant Id: ", this.state.selected_tenant_id);
//         console.log("Creator Id: ", this.props.loggedStatus.id);
//         console.log("Site Id: ", this.state.selected_site_id);
//         console.log("Vendor Id: ", this.state.selected_vendor_id);
//         console.log("Manufacturer Id: ", this.state.manufacturer_id_selected);

//         console.log("Asset Name: ", this.state.asset_name_entered);
//         console.log("Version Number: ", this.state.version_number_entered);
//         console.log("Number Devices Allowed: ", this.state.number_devices_allowed_entered);     // will need to parseInt before sending if string is not empty
//         console.log("Number Devices Used: ", this.state.number_devices_used_entered);
//         console.log("License Number: ", this.state.license_number_entered);
//         console.log("License Type: ", this.state.license_type_entered);
//         console.log("License Start Date: ", this.state.license_start_date_entered);
//         console.log("License End Date: ", this.state.license_end_date_entered);
//         console.log("Warranty Start Date: ", this.state.warranty_start_date_entered);
//         console.log("Warranty End Date: ", this.state.warranty_end_date_entered);
//         console.log("Use Case: ", this.state.use_case_entered);
//         console.log("Cost: ", this.state.cost_entered);

//         // make sure all required fields are filled out
//         if (this.state.selected_tenant_id === 0 || this.state.asset_name_entered === "" || this.state.number_devices_allowed_entered === "" || this.state.license_type_entered === "") {
//             this.setState({
//                 canSeeErrorMessage: true
//             });
//         }

//         else {
//             this.setState({
//                 canSeeErrorMessage: false
//             });

//             // get data ready for submission to backend
//             let data = {
//                 tenantId: this.state.selected_tenant_id,
//                 creatorId: this.props.loggedStatus.id,  // the id of the person creating the asset (for history note)
//                 siteId: this.state.selected_site_id,
//                 vendorId: this.state.selected_vendor_id,
//                 manufacturerId: this.state.manufacturer_id_selected,

//                 assetName: this.state.asset_name_entered,
//                 versionNumber: this.state.version_number_entered,
//                 numberDevicesAllowed: parseInt(this.state.number_devices_allowed_entered),
//                 numberDevicesUsed: this.state.number_devices_used_entered,
//                 licenseNumber: this.state.license_number_entered,
//                 licenseType: this.state.license_type_entered,
//                 licenseStartDate: this.state.license_start_date_entered,
//                 licenseEndDate: this.state.license_end_date_entered,
//                 warrantyStartDate: this.state.warranty_start_date_entered,
//                 warrantyEndDate: this.state.warranty_end_date_entered,
//                 useCase: this.state.use_case_entered,
//                 cost: this.state.cost_entered
//             };

//             SoftwareAssetDataService.createNewSoftwareAsset(this.props.loggedStatus.accessToken, data).then(response => {
//                 this.props.history.push('/softwareAssets');
//             })
//                 .catch(e => {
//                     console.log(e);
//                 })
//         }


//     }

//     closeAddPage() {
//         this.props.history.push('/softwareAssets');
//     }

//     openManufacturerModal() {
//         this.setState({
//             show_manufacturer_modal: true
//         });
//     }

//     closeManufacturerModal() {
//         this.setState({
//             show_manufacturer_modal: false,
//             manufacturer_name_entered: "",          // reset form fields
//             manufacturer_type_entered: "",
//             manufacturer_phone_number_entered: ""
//         });
//     }

//     openSiteModal() {
//         this.setState({
//             show_site_modal: true
//         });
//     }

//     closeSiteModal() {
//         this.setState({
//             show_site_modal: false,
//             site_name_entered: "",  // reset form fields
//             site_street_address_entered: "",
//             site_city_entered: "",
//             site_state_entered: "",
//             site_zip_code_entered: ""
//         });
//     }

//     openVendorModal() {
//         this.setState({
//             show_vendor_modal: true
//         });
//     }

//     closeVendorModal() {
//         this.setState({
//             show_vendor_modal: false,
//             vendor_name_entered: ""
//         });
//     }

//     handleCreateManufacturerNameChange(e) {
//         this.setState({
//             manufacturer_name_entered: e.target.value
//         });
//     }

//     handleCreateManufacturerTypeChange(e) {
//         this.setState({
//             manufacturer_type_entered: e.target.value
//         });
//     }

//     handleCreateManufacturerPhoneChange(e) {
//         this.setState({
//             manufacturer_phone_number_entered: e.target.value
//         });
//     }

//     handleCreateSiteNameChange(e) {
//         this.setState({
//             site_name_entered: e.target.value
//         });
//     }

//     handleCreateSiteStreetAddressChange(e) {
//         this.setState({
//             site_street_address_entered: e.target.value
//         });
//     }

//     handleCreateSiteCityChange(e) {
//         this.setState({
//             site_city_entered: e.target.value
//         });
//     }

//     handleCreateSiteStateChange(e) {
//         this.setState({
//             site_state_entered: e.target.value
//         });
//     }

//     handleCreateSiteZipCodeChange(e) {
//         this.setState({
//             site_zip_code_entered: e.target.value
//         });
//     }

//     handleCreateVendorNameChange(e) {
//         this.setState({
//             vendor_name_entered: e.target.value
//         });
//     }

//     submitCreateManufacturerForm() {
//         console.log("Values submitting to backend");
//         console.log("Manufacturer name: ", this.state.manufacturer_name_entered);
//         console.log("Manufacturer type: ", this.state.manufacturer_type_entered);
//         console.log("Phone number: ", this.state.manufacturer_phone_number_entered);

//         if (this.state.manufacturer_name_entered === "" || this.state.manufacturer_type_entered === "") {
//             this.setState({
//                 canSeeDuplicateNameErrorCreateManufacturer: false,
//                 canSeeErrorMessageCreateManufacturer: true
//             });
//         }

//         else {
//             this.setState({
//                 canSeeErrorMessageCreateManufacturer: false
//             });

//             // check for duplicate name / type combo
//             HardwareAssetDataService.checkForDuplicateManufacturer(this.props.loggedStatus.accessToken, this.state.manufacturer_name_entered, this.state.manufacturer_type_entered).then(response => {

//                 if (response.data.results === "DUPLICATE FOUND") {
//                     this.setState({
//                         canSeeDuplicateNameErrorCreateManufacturer: true
//                     });
//                 }

//                 else if (response.data.results === "NO DUPLICATE FOUND") {
//                     this.setState({
//                         canSeeDuplicateNameErrorCreateManufacturer: false
//                     });

//                     // send form data to backend

//                     let data = {
//                         manufacturerName: this.state.manufacturer_name_entered,
//                         manufacturerType: this.state.manufacturer_type_entered,
//                         phoneNumber: this.state.manufacturer_phone_number_entered
//                     };

//                     HardwareAssetDataService.createManufacturer(this.props.loggedStatus.accessToken, data).then(response => {
//                         this.getManufacturers(this.props.loggedStatus.accessToken).then(response => {
//                             this.setState({
//                                 manufacturers: [{ id: 0, name_of_manufacturer: "None" }, ...response.results],
//                                 manufacturer_id_selected: 0,
//                                 manufacturer_name_entered: "",      // reset form fields
//                                 manufacturer_type_entered: "",
//                                 manufacturer_phone_number_entered: ""
//                             });

//                             /* if(response.results && response.results.length > 0) {
//                                 this.setState({
//                                     manufacturer_id_selected: response.results[0].id
//                                 });
//                             }

//                             else {
//                                 this.setState({
//                                     manufacturer_id_selected: 0
//                                 });
//                             } */

//                             this.closeManufacturerModal();
//                         })
//                             .catch(e => {
//                                 console.log(e);
//                             })
//                     })
//                         .catch(e => {
//                             console.log(e);
//                         });

//                 }


//             })
//                 .catch(e => {
//                     console.log(e);
//                 });
//         }
//     }

//     submitCreateSiteForm() {
//         console.log("Values submitting to backend");
//         console.log("Tenant Id: ", this.state.selected_tenant_id);
//         console.log("Site Name: ", this.state.site_name_entered);
//         console.log("Street Address: ", this.state.site_street_address_entered);
//         console.log("City: ", this.state.site_city_entered);
//         console.log("State: ", this.state.site_state_entered);
//         console.log("Zip Code: ", this.state.site_zip_code_entered);

//         if (this.state.selected_tenant_id === 0 || this.state.site_name_entered === "") {
//             this.setState({
//                 canSeeDuplicateNameErrorCreateSite: false,
//                 canSeeErrorMessageCreateSite: true
//             });
//         }

//         else {
//             this.setState({
//                 canSeeErrorMessageCreateSite: false
//             });

//             // check for duplicate name
//             HardwareAssetDataService.checkForDuplicateSiteName(this.props.loggedStatus.accessToken, this.state.site_name_entered).then(response => {
//                 if (response.data.results === "DUPLICATE FOUND") {
//                     this.setState({
//                         canSeeDuplicateNameErrorCreateSite: true
//                     });
//                 }

//                 else if (response.data.results === "NO DUPLICATE FOUND") {
//                     this.setState({
//                         canSeeDuplicateNameErrorCreateSite: false
//                     });

//                     // send form data to backend

//                     let data = {
//                         tenantId: this.state.selected_tenant_id,
//                         siteName: this.state.site_name_entered,
//                         streetAddress: this.state.site_street_address_entered,
//                         city: this.state.site_city_entered,
//                         state: this.state.site_state_entered,
//                         zipCode: this.state.site_zip_code_entered
//                     };

//                     HardwareAssetDataService.createSite(this.props.loggedStatus.accessToken, data).then(response => {
//                         this.getSites(this.props.loggedStatus.accessToken, this.state.selected_tenant_id).then(response => {
//                             this.setState({
//                                 sites: [{ id: 0, name_of_site: "None" }, ...response.results],
//                                 selected_site_id: 0
//                             });

//                             /* if(response.results && response.results.length > 0) {
//                                 this.setState({
//                                     selected_site_id: response.results[0].id
//                                 });
//                             }

//                             else {
//                                 this.setState({
//                                     selected_site_id: 0
//                                 });
//                             } */

//                             this.closeSiteModal();

//                         })
//                             .catch(e => {
//                                 console.log(e);
//                             });
//                     })
//                         .catch(e => {
//                             console.log(e);
//                         });

//                 }
//             })
//                 .catch(e => {
//                     console.log(e);
//                 });

//         }

//     }

//     submitCreateVendorForm() {
//         console.log("Values submitting to backend")
//         console.log("Name: ", this.state.vendor_name_entered);

//         if (this.state.vendor_name_entered === "") {
//             this.setState({
//                 canSeeDuplicateNameErrorCreateVendor: false,
//                 canSeeErrorMessageCreateVendor: true
//             });
//         }

//         else {
//             this.setState({
//                 canSeeErrorMessageCreateVendor: false
//             });

//             // check for duplicate vendor
//             HardwareAssetDataService.checkForDuplicateVendor(this.props.loggedStatus.accessToken, this.state.vendor_name_entered).then(response => {
//                 if (response.data.results === "DUPLICATE FOUND") {
//                     this.setState({
//                         canSeeDuplicateNameErrorCreateVendor: true
//                     });
//                 }

//                 else if (response.data.results === "NO DUPLICATE FOUND") {
//                     this.setState({
//                         canSeeDuplicateNameErrorCreateVendor: false
//                     });

//                     // send data to backend for processing
//                     let data = {
//                         vendorName: this.state.vendor_name_entered
//                     };

//                     HardwareAssetDataService.createVendor(this.props.loggedStatus.accessToken, data).then(response => {
//                         this.getVendors(this.props.loggedStatus.accessToken).then(response => {
//                             this.setState({
//                                 vendors: [{ id: 0, name_of_vendor: "None" }, ...response.results],
//                                 selected_vendor_id: 0
//                             });

//                             this.closeVendorModal();
//                         })
//                             .catch(e => {
//                                 console.log(e);
//                             });
//                     })
//                         .catch(e => {
//                             console.log(e);
//                         })

//                 }
//             })
//                 .catch(e => {
//                     console.log(e);
//                 });

//         }
//     }


//     render() {

//         if (!this.props.loggedStatus.loggedIn) {
//             return (
//                 <Redirect to='/' />
//             );
//         }


//         return (
//             <>
//                 <Navbar pageTitle={"Add Software Asset"} />
//                 <div style={{ paddingTop: "15px" }} />
//                 <Container fluid>
//                     <Paper withBorder p="md" radius="md" display="block" m="5px">
//                         <Row>
//                             <Col md={{ span: 12 }} style={{ textAlign: "center" }}>
//                                 <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
//                             </Col>
//                         </Row>
//                         <Row style={{ marginTop: "20px" }}>
//                             <Col md={{ span: 12 }} style={{ textAlign: "center" }}>
//                                 <Form>

//                                     {/* 
//                                         1. Company (drop down) required --
//                                         2. Name of asset (text) required - must be unique --
//                                         3. License Start Date (date) --
//                                         4. License End Date (date) --
//                                         5. Cost (decimal)
//                                         6. Version Number (text) --
//                                         7. Manufacturer (drop down) --
//                                         8. Vendor (drop down) --
//                                         9. License Number (text) --
//                                         10. Warranty Start Date (date) --
//                                         11. Warranty Expiration Date (date) --
//                                         12. Number of Devices Allowed (int) required --
//                                         13. Number of Devices Used (int) required --
//                                         14. Site Restriction (drop down) - will include N/A option --
//                                         15. Type Of License (text) required --
//                                         16. Use Case (text) --
//                                     */}
//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormTenant" className="mb-3">
//                                                 <Form.Label>Company<span className="requiredStar">*</span></Form.Label>
//                                                 <Form.Control as="select" required>
//                                                     {this.state.tenants && this.state.tenants.map((tenant) => (
//                                                         <>
//                                                             {this.state.selected_tenant_id === tenant.id ?
//                                                                 <option key={`tenant_${tenant.id}`} onClick={() => this.handleTenantChange(tenant.id)} selected>{tenant.name_of_tenant}</option>
//                                                                 :
//                                                                 <option key={`tenant_${tenant.id}`} onClick={() => this.handleTenantChange(tenant.id)}>{tenant.name_of_tenant}</option>
//                                                             }
//                                                         </>
//                                                     ))}
//                                                 </Form.Control>
//                                             </Form.Group>
//                                         </span>
//                                     </div>
//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormAssetName" className="mb-3">
//                                                 <Form.Label>Asset Name<span className="requiredStar">*</span></Form.Label>
//                                                 <Form.Control type="text" placeholder="ex: Office 365" value={this.state.asset_name_entered} onChange={this.handleAssetNameEnteredChange} required />
//                                             </Form.Group>
//                                         </span>
//                                     </div>
//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormVersionNumber" className="mb-3">
//                                                 <Form.Label>Version Number</Form.Label>
//                                                 <Form.Control type="text" placeholder="ex: 2.5" value={this.state.version_number_entered} onChange={this.handleVersionNumberEnteredChange} />
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormSiteRestriction" className="mb-3">
//                                                 <Form.Label>Site Restriction</Form.Label>
//                                                 <Form.Control as="select" required>
//                                                     {this.state.sites && this.state.sites.map((site) => (
//                                                         <>
//                                                             {this.state.selected_site_id === site.id ?
//                                                                 <option key={`site_${site.id}`} onClick={() => this.handleSelectedSiteChange(site.id)} selected>{site.name_of_site}</option>
//                                                                 :
//                                                                 <option key={`site_${site.id}`} onClick={() => this.handleSelectedSiteChange(site.id)}>{site.name_of_site}</option>
//                                                             }
//                                                         </>
//                                                     ))}
//                                                 </Form.Control>
//                                             </Form.Group>
//                                         </span>
//                                         <span style={{ display: "inline-block", paddingLeft: "15px" }}>
//                                             <OverlayTrigger
//                                                 placement="top"
//                                                 overlay={
//                                                     <Tooltip>Click to add a new item</Tooltip>
//                                                 }
//                                             >
//                                                 <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openSiteModal()} />
//                                             </OverlayTrigger>
//                                         </span>
//                                     </div>


//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormManufacturer" className="mb-3">
//                                                 <Form.Label>Manufacturer</Form.Label>
//                                                 <Form.Control as="select" required>
//                                                     {this.state.manufacturers && this.state.manufacturers.map((manufacturer) => (
//                                                         <>
//                                                             {this.state.manufacturer_id_selected === manufacturer.id ?
//                                                                 <option key={`manufacturer_${manufacturer.id}`} onClick={() => this.handleCreateModelManufacturerChange(manufacturer.id)} selected>{manufacturer.name_of_manufacturer}</option>
//                                                                 :
//                                                                 <option key={`manufacturer_${manufacturer.id}`} onClick={() => this.handleCreateModelManufacturerChange(manufacturer.id)}>{manufacturer.name_of_manufacturer}</option>
//                                                             }
//                                                         </>
//                                                     ))}
//                                                 </Form.Control>
//                                             </Form.Group>
//                                         </span>
//                                         <span style={{ display: "inline-block", paddingLeft: "15px" }}>
//                                             <OverlayTrigger
//                                                 placement="top"
//                                                 overlay={
//                                                     <Tooltip>Click to add a new item</Tooltip>
//                                                 }
//                                             >
//                                                 <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openManufacturerModal()} />
//                                             </OverlayTrigger>
//                                         </span>
//                                     </div>
//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormVendor" className="mb-3">
//                                                 <Form.Label>Vendor</Form.Label>
//                                                 <Form.Control as="select" required>
//                                                     {this.state.vendors && this.state.vendors.map((vendor) => (
//                                                         <>
//                                                             {this.state.selected_vendor_id === vendor.id ?
//                                                                 <option key={`vendor_${vendor.id}`} onClick={() => this.handleSelectedVendorChange(vendor.id)} selected>{vendor.name_of_vendor}</option>
//                                                                 :
//                                                                 <option key={`vendor_${vendor.id}`} onClick={() => this.handleSelectedVendorChange(vendor.id)}>{vendor.name_of_vendor}</option>
//                                                             }
//                                                         </>
//                                                     ))}
//                                                 </Form.Control>
//                                                 {/* <Form.Text className="text-muted">
//                                                         Note: The values in this drop down are affected by which type you have selected in the Asset Type field above
//                                                     </Form.Text> */}
//                                             </Form.Group>
//                                         </span>
//                                         <span style={{ display: "inline-block", paddingLeft: "15px" }}>
//                                             <OverlayTrigger
//                                                 placement="top"
//                                                 overlay={
//                                                     <Tooltip>Click to add a new item</Tooltip>
//                                                 }
//                                             >
//                                                 <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openVendorModal()} />
//                                             </OverlayTrigger>
//                                         </span>
//                                     </div>
//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormNumDevicesAllowed" className="mb-3">
//                                                 <Form.Label>Number of Devices Allowed<span className="requiredStar">*</span></Form.Label>
//                                                 <Form.Control type="number" min="0" step="1" placeholder="ex: 10" value={parseInt(this.state.number_devices_allowed_entered).toString()} onChange={this.handleNumberDevicesAllowedChange} required></Form.Control>
//                                                 <Form.Text>Please enter a whole number here</Form.Text>
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     {/* <div style={{width: "100%", textAlign: "left"}}>
//                                             <span style={{display: "inline-block", width: "95%"}}>
//                                                 <Form.Group controlId="addFormNumDevicesUsed" className="mb-3">
//                                                     <Form.Label>Number of Devices Used<span className="requiredStar">*</span></Form.Label>
//                                                     <Form.Control type="number" required disabled></Form.Control>
//                                                 </Form.Group>
//                                             </span>
//                                         </div> */}

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormLicenseNumber" className="mb-3">
//                                                 <Form.Label>License Number</Form.Label>
//                                                 <Form.Control type="text" placeholder="ex: 2as54" value={this.state.license_number_entered} onChange={this.handleLicenseNumberEnteredChange} />
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormAssetName" className="mb-3">
//                                                 <Form.Label>License Type<span className="requiredStar">*</span></Form.Label>
//                                                 <Form.Control type="text" placeholder="ex: Individual" value={this.state.license_type_entered} onChange={this.handleLicenseTypeEnteredChange} required />
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormWarrantyDates" className="mb-3">
//                                                 <Form.Label>License Start and End Dates</Form.Label>
//                                                 <div>
//                                                     <span style={{ display: "inline-block" }}>
//                                                         <Form.Control type="date" name="addFormLicenseStartDate" onChange={e => this.handleLicenseStartDateChange(e)}></Form.Control>
//                                                     </span>
//                                                     <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
//                                                         to
//                                                     </span>
//                                                     <span style={{ display: "inline-block" }}>
//                                                         <Form.Control type="date" name="addFormLicenseEndDate" onChange={e => this.handleLicenseEndDateChange(e)}></Form.Control>
//                                                     </span>
//                                                 </div>
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormWarrantyDates" className="mb-3">
//                                                 <Form.Label>Warranty Start and End Dates</Form.Label>
//                                                 <div>
//                                                     <span style={{ display: "inline-block" }}>
//                                                         <Form.Control type="date" name="addFormWarrantyStartDate" onChange={e => this.handleWarrantyStartDateChange(e)}></Form.Control>
//                                                     </span>
//                                                     <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
//                                                         to
//                                                     </span>
//                                                     <span style={{ display: "inline-block" }}>
//                                                         <Form.Control type="date" name="addFormWarrantyEndDate" onChange={e => this.handleWarrantyEndDateChange(e)}></Form.Control>
//                                                     </span>
//                                                 </div>
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormUseCase" className="mb-3">
//                                                 <Form.Label>Use Case</Form.Label>
//                                                 <Form.Control type="text" placeholder="ex: Production" value={this.state.use_case_entered} onChange={this.handleUseCaseEnteredChange} />
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ width: "100%", textAlign: "left" }}>
//                                         <span style={{ display: "inline-block", width: "95%" }}>
//                                             <Form.Group controlId="addFormModelCost" className="mb-3">
//                                                 <Form.Label>Cost</Form.Label>
//                                                 <Form.Control type="number" min="0" step="0.01" placeholder="ex: 1250.59" value={parseFloat(this.state.cost_entered).toString()} onChange={this.handleCreateModelCostChange} />
//                                                 {/* Within the form's submit fxn use: Math.round(3.14159265359 * 100) / 100 to make sure value has 2 decimal places */}
//                                             </Form.Group>
//                                         </span>
//                                     </div>

//                                     <div style={{ textAlign: "center", marginTop: "30px" }}>
//                                         <span><Button variant="secondary" onClick={() => this.closeAddPage()} className="gothamNarrowFont">Cancel</Button></span>
//                                         <span style={{ paddingLeft: "55px" }}><Button id="newDepartmentFormOkButton" onClick={() => { this.submitAddAssetForm() }} className="gothamNarrowFont">Submit</Button></span>
//                                     </div>
//                                     <div style={{ textAlign: "center", marginTop: "15px" }}>
//                                         {this.state.canSeeErrorMessage ?
//                                             <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields before submitting.</p>
//                                             :
//                                             undefined
//                                         }
//                                         {/*this.state.canSeeDuplicateNameError ?
//                                         <p style={{color: "red"}}>Error: An asset with this name already exists. Please try a different name.</p>
//                                         :
//                                         undefined
//                                         */}
//                                     </div>

//                                 </Form>
//                             </Col>
//                         </Row>
//                     </Paper>
//                 </Container>

//                 {/* This modal is for creating a new manufacturer */}
//                 <Modal
//                     show={this.state.show_manufacturer_modal}
//                     onHide={() => this.closeManufacturerModal()}
//                     backdrop="static"
//                     keyboard={false}
//                     size="lg"
//                     centered
//                 >
//                     <Form>
//                         <Modal.Header closeButton>
//                             <Modal.Title>
//                                 <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Manufacturer</div>
//                                 <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
//                             </Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormManufacturerName" className="mb-3">
//                                         <Form.Label>Manufacturer Name<span className="requiredStar">*</span></Form.Label>
//                                         <Form.Control type="text" placeholder="ex: Lenovo" value={this.state.manufacturer_name_entered} onChange={this.handleCreateManufacturerNameChange} required />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormManufacturerType" className="mb-3">
//                                         <Form.Label>Manufacturer Type<span className="requiredStar">*</span></Form.Label>
//                                         <Form.Control type="text" placeholder="ex: Hardware" value={this.state.manufacturer_type_entered} onChange={this.handleCreateManufacturerTypeChange} required />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormManufacturerPhone" className="mb-3">
//                                         <Form.Label>Phone Number</Form.Label>
//                                         <Form.Control type="text" placeholder="ex: 555-555-5555" value={this.state.manufacturer_phone_number_entered} onChange={this.handleCreateManufacturerPhoneChange} />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ textAlign: "center", marginTop: "15px" }}>
//                                 {this.state.canSeeErrorMessageCreateManufacturer ?
//                                     <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
//                                     :
//                                     undefined
//                                 }
//                                 {this.state.canSeeDuplicateNameErrorCreateManufacturer ?
//                                     <p style={{ color: "red" }}>Error: A manufacturer with this name and type already exists. Please try again.</p>
//                                     :
//                                     undefined
//                                 }
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             <div style={{ paddingRight: "15px" }}>
//                                 <Button variant="secondary" onClick={() => this.closeManufacturerModal()} className="gothamNarrowFont">Cancel</Button>
//                             </div>
//                             <div>
//                                 <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateManufacturerForm() }} className="gothamNarrowFont">Submit</Button>
//                             </div>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal>

//                 {/* This modal is for adding sites */}
//                 <Modal
//                     show={this.state.show_site_modal}
//                     onHide={() => this.closeSiteModal()}
//                     backdrop="static"
//                     keyboard={false}
//                     size="lg"
//                     centered
//                 >
//                     <Form>
//                         <Modal.Header closeButton>
//                             <Modal.Title>
//                                 <div style={{ textAlign: "left", cursor: "default" }} className="gothamNarrowFont">Create Site</div>
//                                 <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
//                             </Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>
//                             <div style={{ width: "100%", textAlign: "left" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormSiteCompany" className="mb-3">
//                                         <Form.Label>Company<span className="requiredStar">*</span></Form.Label>
//                                         <Form.Control as="select" required disabled>
//                                             {this.state.tenants && this.state.tenants.map((tenant) => (
//                                                 <>
//                                                     {this.state.selected_tenant_id === tenant.id ?
//                                                         <option key={`add_site_tenant_${tenant.id}`} selected>{tenant.name_of_tenant}</option>
//                                                         :
//                                                         <option key={`add_site_tenant_${tenant.id}`}>{tenant.name_of_tenant}</option>
//                                                     }
//                                                 </>
//                                             ))}
//                                         </Form.Control>
//                                         <Form.Text className="text-muted">
//                                             Note: This value is controlled by which company you have selected on the add hardware asset screen
//                                         </Form.Text>
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormSiteName" className="mb-3">
//                                         <Form.Label>Site Name<span className="requiredStar">*</span></Form.Label>
//                                         <Form.Control type="text" placeholder="ex: Irvine Office" value={this.state.site_name_entered} onChange={this.handleCreateSiteNameChange} required />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormSiteStreetAddress" className="mb-3">
//                                         <Form.Label>Street Address</Form.Label>
//                                         <Form.Control type="text" placeholder="ex: 1111 Wilmington Pl" value={this.state.site_street_address_entered} onChange={this.handleCreateSiteStreetAddressChange} />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormSiteCity" className="mb-3">
//                                         <Form.Label>City</Form.Label>
//                                         <Form.Control type="text" placeholder="ex: Irvine" value={this.state.site_city_entered} onChange={this.handleCreateSiteCityChange} />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormSiteState" className="mb-3">
//                                         <Form.Label>State</Form.Label>
//                                         <Form.Control type="text" placeholder="ex: CA" value={this.state.site_state_entered} onChange={this.handleCreateSiteStateChange} />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormSiteZipCode" className="mb-3">
//                                         <Form.Label>Zip Code</Form.Label>
//                                         <Form.Control type="text" placeholder="ex: 99999" value={this.state.site_zip_code_entered} onChange={this.handleCreateSiteZipCodeChange} />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ textAlign: "center", marginTop: "15px" }}>
//                                 {this.state.canSeeErrorMessageCreateSite ?
//                                     <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
//                                     :
//                                     undefined
//                                 }
//                                 {this.state.canSeeDuplicateNameErrorCreateSite ?
//                                     <p style={{ color: "red" }}>Error: A site with this name already exists. Please try again.</p>
//                                     :
//                                     undefined
//                                 }
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             <div style={{ paddingRight: "15px" }}>
//                                 <Button variant="secondary" onClick={() => this.closeSiteModal()} className="gothamNarrowFont">Cancel</Button>
//                             </div>
//                             <div>
//                                 <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateSiteForm() }} className="gothamNarrowFont">Submit</Button>
//                             </div>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal>


//                 {/* This modal is for adding vendors */}
//                 <Modal
//                     show={this.state.show_vendor_modal}
//                     onHide={() => this.closeVendorModal()}
//                     backdrop="static"
//                     keyboard={false}
//                     size="lg"
//                     centered
//                 >
//                     <Form>
//                         <Modal.Header closeButton>
//                             <Modal.Title>
//                                 <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Vendor</div>
//                                 <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
//                             </Modal.Title>
//                         </Modal.Header>
//                         <Modal.Body>
//                             <div style={{ width: "100%" }}>
//                                 <span style={{ display: "inline-block", width: "90%" }}>
//                                     <Form.Group controlId="addFormVendorName" className="mb-3">
//                                         <Form.Label>Vendor Name<span className="requiredStar">*</span></Form.Label>
//                                         <Form.Control type="text" placeholder="ex: Dell EMC" required value={this.state.vendor_name_entered} onChange={this.handleCreateVendorNameChange} />
//                                     </Form.Group>
//                                 </span>
//                             </div>
//                             <div style={{ textAlign: "center", marginTop: "15px" }}>
//                                 {this.state.canSeeErrorMessageCreateVendor ?
//                                     <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
//                                     :
//                                     undefined
//                                 }
//                                 {this.state.canSeeDuplicateNameErrorCreateVendor ?
//                                     <p style={{ color: "red" }}>Error: A vendor with this name already exists. Please try again.</p>
//                                     :
//                                     undefined
//                                 }
//                             </div>
//                         </Modal.Body>
//                         <Modal.Footer>
//                             <div style={{ paddingRight: "15px" }}>
//                                 <Button variant="secondary" onClick={() => this.closeVendorModal()} className="gothamNarrowFont">Cancel</Button>
//                             </div>
//                             <div>
//                                 <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateVendorForm() }} className="gothamNarrowFont">Submit</Button>
//                             </div>
//                         </Modal.Footer>
//                     </Form>
//                 </Modal>
//             </>
//         );

//     }
// }

// const mapStateToProps = (state) => {
//     return {
//         loggedStatus: state.logged,
//         activeNavLinkStatus: state.activeNav,
//         userPermissions: state.userPermissions
//     };
// }

// export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT })(CreateSoftwareAsset);
