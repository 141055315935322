import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FormControl from "react-bootstrap/FormControl";
import "bootstrap/dist/css/bootstrap.min.css";
import { Select } from "@mantine/core";

import { connect, useSelector /*, useDispatch*/ } from 'react-redux';
import { store } from '../app/store';

import AssignTechsTicketsCalendarService from '../services/assign-techs_tickets-calendar-activities';

import { UPDATE_ACTIVE_ASSIGNMENT, ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN } from "../features/actions/My-Assigned-Tickets-Actions";
import { LOGOUT } from "../features/actions/Is-Logged-Actions";

//import { ADD_DEPARTMENT, SHOW_ADD_DEPARTMENT_FORM, CHANGE_ACTIVE_DEPARTMENT, RESET_DEPARTMENT, ALERT_DEPARTMENT_CHANGE_FROM_DROPDOWN } from '../features/actions/Dynamic-Table-Actions';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#assignedTechs"
    ref={ref}
    id="selectAssignedTechDropdownButton"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto gothamNarrowLightFont"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled" style={{ maxHeight: "400px", overflowX: "hidden", overflowY: "scroll" }}>
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().includes(value),
          )}
        </ul>
      </div>
    );
  },
);


// function resetDepartment() {
//   return {
//     type: 'RESET_DEPARTMENT'
//   }
// }

function tryToGetDataAgain(askerId, accessToken, techList) {
  console.log("ACCESS TOKEN BEING USED IN TRY TO GET DATA AGAIN IS: ", accessToken);
  return new Promise(resolve => {
    AssignTechsTicketsCalendarService.getTechsForMyTicketsTableDropdown(askerId, accessToken)
      .then(response => {
        //console.log("Response from backend for tech list was: ");
        //console.log(response);

        let updatedTechList = [...techList]

        response.data.results.map((tech) => {
          updatedTechList.push(tech);
          return updatedTechList;   // included to appease compile warning. not actually needed
        });

        resolve(updatedTechList);

      })
      .catch(e => {
        console.log("The second attempt for the assigned techs list Failed...");
        console.log(e);
      });
  });
}

function updateActiveAssignment(assigned_to_name, assigned_to_id) {

  console.log("Changing to tech with name: ", assigned_to_name, " and id: ", assigned_to_id);
  return {
    type: 'UPDATE_ACTIVE_ASSIGNMENT',
    payload: { name: assigned_to_name, id: assigned_to_id, type: "Assigned Tickets Filter" }
  }
}

function alertAboutAssignmentChange() {
  return {
    type: 'ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN'
  }
}

function handleAssignmentChange(assignment_to_change_to) {
  let state = store.getState();

  console.log("Assignment to change to from the assigned tech selector component is: ", assignment_to_change_to);

  // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
  if (assignment_to_change_to.id !== state.myAssignedTickets.active_assignment_id_assigned_tickets_filter) {

    //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
    store.dispatch(updateActiveAssignment(assignment_to_change_to.name, assignment_to_change_to.id));
    store.dispatch(alertAboutAssignmentChange()); // tell My-Assigned-Tickets.js to run it's componentDidUpdate fxn and get new assigned ticket data
  }


}

function AssignedTechSelector(props) {
  let state = store.getState();
  //let dispatch = useDispatch();
  const accessToken = useSelector((state) => state.logged.accessToken);

  const username = useSelector((state) => state.logged.username);

  const askerId = useSelector((state) => state.logged.id);  // id of the user who is asking to get list of techs (used to control what tenants' results are returned)

  const [techList, setTechList] = React.useState([]);   // create the empty tech array in the functional component's state

  React.useEffect(() => {

    if (techList.length === 0) {

      props.onAccessTokenRequest(username).then((token) => {
        // if the token can't be refreshed, force a logout
        if (token === undefined || token === null || token === "") {
          console.log("Token was expired and can't refresh, so force logging out this user!");
          this.props.LOGOUT();        // logout of global state
          this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
        }

        else {

          // put in the "All default information" into drop down
          //setTechList([...techList, {tech_full_name: "All", id: 0}]);
          if (techList.length === 0) {
            techList.push({ tech_full_name: "All", id: 0 });
            techList.push({ tech_full_name: "Unassigned", id: -1 });    // backend will recognize -1 as meaning where assigned tech id is null
          }

          // fetch the list of techs from the backend
          //console.log("Trying to print access token from assigned to drop down:");
          //console.log(loggedStatus.accessToken);

          //console.log("ACCESS TOKEN BEING USED FOR TECH SELECTOR MENU IS: ", token);

          AssignTechsTicketsCalendarService.getTechsForMyTicketsTableDropdown(askerId, token)
            .then(response => {
              //console.log("Response from backend for tech list was: ");
              //console.log(response);

              let updatedTechList = [...techList]

              response.data.results.map((tech) => {
                updatedTechList.push(tech);
                return updatedTechList;   // included to appease compile warning. not actually needed
              });

              setTechList(updatedTechList);

            })
            .catch(e => {
              console.log(e);
              console.log("You're in the CATCH clause of the assigned tech selector component. The line printed above should be the error text");
              console.log("Should hopefully contain the status code of the failed attempt: ", e.response.status);

              if (e.response.status === 401) {   // the token was stale, so try again after the state updates with the new token
                console.log("The assigned tech selector is asking the API again since the token was stale");
                setTimeout(() => {
                  tryToGetDataAgain(askerId, token, techList).then(response => {
                    setTechList(response);
                    console.log("Getting data again succeeded");
                  })
                    .catch(e => {
                      console.log(e);
                      console.log("Trying to get data again failed");
                    });
                }, 2000);   // wait 2 seconds for that next call to the API and hopefully the global state has been updated by then
              }
            });
        }

      });
    }

  }, [techList, setTechList, accessToken]);    // any state changes now have the tech list as a dependency to watch out for

  const [techSelected, setTechSelected] = useState("Unassigned");

  return (
    <>
      <Select
        label="Assigned To"
        placeholder="Pick value"
        data={techList.map((tech) => (tech.tech_full_name))}
        display="inline-block"
        onChange={(s) => {
          var tech = techList.find((e) => e.tech_full_name == s);
          if (tech) handleAssignmentChange({ name: tech.tech_full_name, id: tech.id })
          setTechSelected(s)
        }}
        value={state.myAssignedTickets.active_assignment_name_assigned_tickets_filter}
        w="200px"
        searchable
        allowDeselect={false}
      />
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    loggedStatus: state.logged,
    myAssignedTickets: state.myAssignedTickets
  };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_ASSIGNMENT, ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN, LOGOUT })(AssignedTechSelector);