import React, { Component, Fragment } from "react";

import SoftwareTicketDataService from "../services/software-ticket-activities";

import ReassignTechSelector from './Reassign-Tech-Selector';
import { UPDATE_ACTIVE_ASSIGNMENT, ASSIGNMENT_CHANGE_RESOLVED } from '../features/actions/Reassign-Tech-Actions';

import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';

// import HardwareTicketDataService from "../services/hardware-ticket-activities";     // REMOVE WHEN CONVERTED TO SOFTWARE

import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import Switch from "react-switch";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { ListGroup } from "react-bootstrap";

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faEye, faEdit } from '@fortawesome/free-regular-svg-icons';


class SoftwareTicketDetails extends Component {
    constructor(props) {
        super(props);
        this.getTicket = this.getTicket.bind(this);
        this.getTicketComments = this.getTicketComments.bind(this);
        this.getLikesDis = this.getLikesDis.bind(this);
        this.getBoolIndividualSoftwareTicketEmails = this.getBoolIndividualSoftwareTicketEmails.bind(this);
        this.addALike = this.addALike.bind(this);
        this.addADislike = this.addADislike.bind(this);
        this.setTicketTimeSinceCreated = this.setTicketTimeSinceCreated.bind(this);
        this.setCommentsTimeSinceCreated = this.setCommentsTimeSinceCreated.bind(this);
        this.replyToOriginalPost = this.replyToOriginalPost.bind(this);
        this.replyToComment = this.replyToComment.bind(this);
        this.cancelReply = this.cancelReply.bind(this);
        this.selectSolution = this.selectSolution.bind(this);
        this.removeSolution = this.removeSolution.bind(this);
        this.handleIndivdualSoftwareChange = this.handleIndivdualSoftwareChange.bind(this);
        this.openEditSoftwareTicket = this.openEditSoftwareTicket.bind(this);
        this.updateTechAssignment = this.updateTechAssignment.bind(this);


        this.state = {
            currentTicket: {
                id: null,
                name_of_asset: "",
                name_of_requester: "",
                date_created: "",
                resolution_status: "",
                ticket_category: "",
                ticket_priority: "",
                up_votes: 0,
                down_votes: 0,
                // button_liked: 0,
                // button_disliked: 0,
                total_clicks_all_time: 0,
                ticket_title: "",
                issue_text: "",
                solution_text: "",
                years_since_sent: 0,
                days_since_sent: 0,
                hours_since_sent: 0,
                minutes_since_sent: 0,
                time_since_sent: "",
                solver_id: null,
                solver_name: "",
                solver_solution_count: 0
            },

            comments: [],

            accessToken: this.props.loggedStatus.accessToken,
            //accessToken: this.props.location.state.accessToken,

            // Elemetns for the reply form
            show_reply_form: false,
            ticket_id_responding_to: 0,
            name_replying_to: "",
            issue_text_responding_to: "",
            comment_id_responding_to: null,
            button_liked: 0,
            button_disliked: 0,
            individual_software_checked: this.props.loggedStatus.getEmailsSoftware,
            editSoftwareButtonEnabled: true,
            msg: ""
        };
    }

    componentDidMount() {

        if(this.props.loggedStatus.loggedIn){
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);
                    // GET THE ACCESS TOKEN SENT FROM ACCOUNTS LIST PAGE WITH
                    //const location = useLocation();
                    //const accessToken = this.props.location.state.accessToken;
                    const accessToken = token; //this.props.loggedStatus.accessToken;

                    this.getTicket(this.props.match.params.id, accessToken);
                    this.getTicketComments(this.props.match.params.id, accessToken);    
                    this.getLikesDis(this.props.match.params.id, this.props.loggedStatus.id, accessToken);    
                    this.getBoolIndividualSoftwareTicketEmails(this.props.match.params.id, this.props.loggedStatus.id, true /*this.props.loggedStatus.getEmailsSoftware*/, accessToken);
                }
            });
        }
    }

    componentDidUpdate() {
        if(this.props.reassignTech.assignment_change_detected) {
            console.log("We have picked up the assignment change. The value you chose was: ", this.props.reassignTech.active_assignment_name);

            this.props.ASSIGNMENT_CHANGE_RESOLVED();

            this.updateTechAssignment(this.props.match.params.id, this.props.reassignTech.active_assignment_id, this.props.loggedStatus.accessToken);
        }
    }


    // This will also add a view to the ticket retreived
    getTicket(id, accessToken) {
        SoftwareTicketDataService.get(id, accessToken)
        .then(response => {
            // console.log("getTicket");
console.log(response.data.results[0]);
            this.setState({
                currentTicket: response.data.results[0]
            });
            // console.log(this.state.currentTicket);

            if(response.data.results[0] && response.data.results[0].tech_assigned_id) {
                this.props.UPDATE_ACTIVE_ASSIGNMENT({name: response.data.results[0].assigned_tech_name, id: response.data.results[0].tech_assigned_id});
            }

            else {
                this.props.UPDATE_ACTIVE_ASSIGNMENT({name: "Unassigned", id: 0});
            }
        })
        .catch(e => {
            console.log(e);
        });
    }

    // this will get the ticket's button liked or disliked based on user 
    getLikesDis(id, uid, accessToken){
        SoftwareTicketDataService.getLikesDislikes(id, uid, accessToken)
        .then(response => {
            // console.log("likesdislikes");
            // console.log(response.data.results);
            this.setState({
                button_liked: response.data.results[0].button_liked,
                button_disliked: response.data.results[0].button_disliked
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

     // this will get the ticket's email notification status based on user 
     getBoolIndividualSoftwareTicketEmails(id, uid, globalSoftwareEmailStatus, accessToken) {
        // console.log(globalSoftwareEmailStatus);
        let numBoolGlobalSoftwareEmailStatus = (globalSoftwareEmailStatus === true) ? 1 : 0;
        SoftwareTicketDataService.getBoolIndividualSoftwareTicketEmails(id, uid, numBoolGlobalSoftwareEmailStatus, accessToken)
        .then(response => {
            console.log("getting email status");
            console.log(response.data.results);
            this.setState({
                individual_software_checked: response.data.results[0].get_ind_software_asset_emails
            });
        })
        .catch(e => {
            console.log(e);
        });

    }

    // NOTE: comment_id that is returned within the comments object is just a placeholder - it was needed for the left join to work
    // If need to refer to a comment's id number, just use the id column in the comments object
    getTicketComments(id, accessToken) {
        SoftwareTicketDataService.getComments(id, accessToken)
        .then(response => {
            this.setState({
                comments: response.data.results
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    // Create copy of original state, but update the up votes to the incremented value from database
    addALike(id, uid, accessToken) {
        SoftwareTicketDataService.addLike(id, uid, accessToken)
        .then(response => {
            // console.log("liked");
            // console.log(response.data.results)
            this.setState(prevState => ({
                currentTicket: {
                    ...prevState.currentTicket,
                    up_votes: response.data.results[0].up_votes,
                    down_votes: response.data.results[0].down_votes, 
                    // button_liked: response.data.results[0].button_liked,
                    // button_disliked: response.data.results[0].button_disliked
                },
                button_liked: response.data.results[0].button_liked,
                button_disliked: response.data.results[0].button_disliked
            }));
        })
        .catch(e => {
            console.log(e);
        });
    }


    // Create copy of original state, but update the down votes to the incremented value from database
    addADislike(id, uid, accessToken) {

        SoftwareTicketDataService.addDislike(id, uid, accessToken)
        .then(response => {
            // console.log("disliked");
            // console.log(response.data.results);
            this.setState(prevState => ({
                currentTicket: {
                    ...prevState.currentTicket,
                    up_votes: response.data.results[0].up_votes,
                    down_votes: response.data.results[0].down_votes,
                    // button_liked: response.data.results[0].button_liked,
                    // button_disliked: response.data.results[0].button_disliked
                },
                button_liked: response.data.results[0].button_liked,
                button_disliked: response.data.results[0].button_disliked
            }));
        })
        .catch(e => {
            console.log(e);
        });
    }

    // change the local software ticket email status 
    handleIndivdualSoftwareChange(individual_software_checked) {
        this.setState({ individual_software_checked });
        let ind_SW_EmailNotif_Status_obj = {}
        ind_SW_EmailNotif_Status_obj["IND_SoftwareTicketEmailNotif_status"] = individual_software_checked ? 1 : 0;
        setTimeout(() => {
            // changing email status for software tickets
            SoftwareTicketDataService.changeIndividualSoftwareTicketEmailStatus(this.props.match.params.id, this.props.loggedStatus.id, ind_SW_EmailNotif_Status_obj, this.props.loggedStatus.accessToken)
            .then(response => {
                console.log("in promise, change ind sw tick");
            })
            .catch(e => {
                console.log(e);
            });
        }, 1000);
    }

    setTicketTimeSinceCreated(currentTicket) {
        if(currentTicket.years_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.years_since_sent + (currentTicket.years_since_sent > 1 ? " years ago" : " year ago");
        }

        else if(currentTicket.months_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.months_since_sent + (currentTicket.months_since_sent > 1 ? " months ago" : " month ago");
        }

        else if(currentTicket.days_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.days_since_sent + (currentTicket.days_since_sent > 1 ? " days ago" : " day ago");
        }

        else if(currentTicket.hours_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.hours_since_sent + (currentTicket.hours_since_sent > 1 ? " hours ago" : " hour ago");
        }

        else if(currentTicket.minutes_since_sent > 0) {
            currentTicket.time_since_sent = currentTicket.minutes_since_sent + (currentTicket.minutes_since_sent > 1 ? " minutes ago" : " minute ago");
        }

        else {
            currentTicket.time_since_sent = "moments ago";
        }
    }

    setCommentsTimeSinceCreated(comments) {
        for(var item of comments) {
            if(item.years_since_sent > 0) {
                item.time_since_sent = item.years_since_sent + (item.years_since_sent > 1 ? " years ago" : " year ago");
            }

            else if(item.months_since_sent > 0) {
                item.time_since_sent = item.months_since_sent + (item.months_since_sent > 1 ? " months ago" : " month ago");
            }

            else if(item.days_since_sent > 0) {
                item.time_since_sent = item.days_since_sent + (item.days_since_sent > 1 ? " days ago" : " day ago");
            }

            else if(item.hours_since_sent > 0) {
                item.time_since_sent = item.hours_since_sent + (item.hours_since_sent > 1 ? " hours ago" : " hour ago");
            }

            else if(item.minutes_since_sent > 0) {
                item.time_since_sent = item.minutes_since_sent + (item.minutes_since_sent > 1 ? " minutes ago" : " minute ago");
            }

            else {
                item.time_since_sent = "moments ago";
            }
        }
    }

    replyToOriginalPost(name_of_requester, issue_text, ticket_id) {     // User pushed reply button on the orignal post at the top
        this.setState({
            show_reply_form: true,   // Display the modal with the form
            ticket_id_responding_to: ticket_id,
            name_replying_to: name_of_requester,
            issue_text_responding_to: issue_text,
            comment_id_responding_to: null     // Just making extra sure to say we're not responding to a comment
        });
    }

    replyToComment(name_of_commenter, comment_text, ticket_id, comment_id) {      // User pushed reply button on a comment down in the replies section
        this.setState({
            show_reply_form: true,   // Display the modal with the form
            ticket_id_responding_to: ticket_id,
            name_replying_to: name_of_commenter,
            issue_text_responding_to: comment_text,
            comment_id_responding_to: comment_id
        });
    }

    submitReply = e => {
        e.preventDefault();
        const formData = new FormData(e.target);
        let formDataObj = Object.fromEntries(formData.entries());
        formDataObj["getSoftwareEmailComments"] = this.props.loggedStatus.getEmailsSoftware;
        formDataObj["IND_SoftwareTicketEmailsStatus"] = this.state.individual_software_checked;
        console.log(formDataObj);

        //formDataObj.responseText = "@ " + this.state.name_replying_to + "\n\n" + formDataObj.responseText

        SoftwareTicketDataService.createSoftwareTicketComment(formDataObj, this.props.loggedStatus.accessToken)
        .then(response => {
            this.setState({show_reply_form: false});    // Hide the form
            this.getTicketComments(this.state.currentTicket.id, this.props.loggedStatus.accessToken);   // update the tickets
        })
        .catch(e => {
            console.log(e);
        });
    }

    cancelReply() {         // User pushed the cancel button on the reply form, put everything back to default
        this.setState({
            show_reply_form: false,
            ticket_id_responding_to: 0,
            name_replying_to: "",
            issue_text_responding_to: "",
            comment_id_responding_to: null
        });
    }

    selectSolution(solutionText, ticketId, commenterId, commenterName, commenterSolutionCount) {
        // Send an update to the backend with the new solution text and resolved status so it gets saved for future use
        SoftwareTicketDataService.addSolution(ticketId, {solutionText: solutionText, commenterId: commenterId}, this.state.accessToken)
        .then(response => {
            // Set the solution text on the front end so we see it right away
            this.setState(prevState => {
                let currentTicket = Object.assign({}, prevState.currentTicket); // create a copy of state variable currentTicket
                currentTicket.solution_text = solutionText; // update the copy's property
                currentTicket.resolution_status = "Resolved";
                currentTicket.solver_id = commenterId;
                currentTicket.solver_name = commenterName;
                currentTicket.solver_solution_count = commenterSolutionCount++; // increment on front end for immediate viewing (already updating permanently on backend)
                return { currentTicket };   // return updated object so it gets saved in the state
            });

            let commentsList = this.state.comments;

            for(var comment of commentsList) {
                if(comment.commenter_id === commenterId) {
                    comment.accepted_solution_count++;
                }
            }

            this.setState({             // Update comments list with new decremented value (already saved on backend)
                comments: commentsList
            });
        })
        .catch(e => {
            console.log(e);
        });
        
    }

    // Called if the ticket owner clicks the select different solution button after previously picking a comment as the solution
    removeSolution(ticketId) {
        // Send an update to the backend with the blank solution text and open resolved status so it gets saved for future use
        // (all data is hardcoded on backend as null, but PUT expects a data object so send an empty one)
        
        // Grab solver id for later updates
        let solver_id = this.state.currentTicket.solver_id;
        
        SoftwareTicketDataService.removeSolution(ticketId, {}, this.state.accessToken)
        .then(response => {
            // Set the solution text on the front end so we see it right away
            this.setState(prevState => {
                let currentTicket = Object.assign({}, prevState.currentTicket); // create a copy of state variable currentTicket
                currentTicket.solution_text = null; // update the copy's property (back to blank)
                currentTicket.resolution_status = "Open";
                currentTicket.solver_id = null;
                currentTicket.solver_name = "";
                currentTicket.solver_solution_count = 0;
                return { currentTicket };   // return updated object so it gets saved in the state
            });

            let commentsList = this.state.comments;

            for(var comment of commentsList) {
                if(comment.commenter_id === solver_id) {
                    comment.accepted_solution_count--;
                }
            }

            this.setState({             // Update comments list with new decremented value (already saved on backend)
                comments: commentsList
            });
        })
        .catch(e => {
            console.log(e);
        });
        

    }

    // calls the backend to change the designated ticket's assigned tech id to the one passed in as an argument
    updateTechAssignment(ticket_id, assigned_tech_id, token) {
        SoftwareTicketDataService.updateTechAssignment(ticket_id, {techAssignedId: assigned_tech_id}, token)
        .then(response => {
            console.log("Tech successfully updated!");
        })
        .catch(e => {
            console.log(e);
        });
    }

    openEditSoftwareTicket(e, currentSoftwareTicketId, currentSoftwareTicketTitle, currentSoftwareTicketIssue, currentSoftwareTicketCategoryTypeIssue, currentSoftwareTicketAsset, currentSoftwareTicketPriority) {
        
        this.setState({
            editSoftwareButtonEnabled: (this.state.editSoftwareButtonEnabled === true) ? false: true,
            msg: "this was passed"
        }); 
        if(this.state.editSoftwareButtonEnabled) {
            this.props.history.push('/createSoftwareTicket', {
                SoftwareTicketId: currentSoftwareTicketId,
                SoftwareTicketTitle: currentSoftwareTicketTitle,
                SoftwareTicketIssue: currentSoftwareTicketIssue,
                SoftwareTicketCategoryTypeIssue: currentSoftwareTicketCategoryTypeIssue,
                SoftwareTicketAsset: currentSoftwareTicketAsset,
                SoftwareTicketPriority: currentSoftwareTicketPriority,
                enableEditSoftwareTicket: true                
            } );
        }
    }

    render() {

        if(!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/'/>
            );
        }

        const { currentTicket, comments, accessToken } = this.state;

        // Figure out time to display for current ticket

        this.setTicketTimeSinceCreated(currentTicket);
        
        
        

        // Figure out time to display for all the comments to this ticket
        this.setCommentsTimeSinceCreated(comments);
        
        
        return (
            <>
                <div className="container mt-3" style={{paddingTop: "75px"}}>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="SoftwareTicketEditButtonSection gothamNarrowLightFont">
                                <label id="EditSoftwareLabel">
                                    <h1 id="SoftwareTitle" className="gothamNarrowFont">{currentTicket.ticket_title}</h1> 
                                    <span id="SoftwareEditButton"> <FontAwesomeIcon icon={faEdit} onClick={(e) => {this.openEditSoftwareTicket(e, currentTicket.id, currentTicket.ticket_title, currentTicket.issue_text, currentTicket.ticket_category, currentTicket.name_of_asset, currentTicket.ticket_priority);}} /> </span>
                                </label>
                            </div>
                            <div className="emailDisableIndSoftwareSwitch gothamNarrowLightFont">
                                <label>
                                    <span id="SoftwareIndLabel">{this.state.individual_software_checked ? "Emails Enabled: " : "Emails Disabled: "}</span>
                                    <Switch id="SoftwareIndSwitch" onChange={this.handleIndivdualSoftwareChange} checked={this.state.individual_software_checked}/>
                                </label>
                            </div>
                            <div className="ticketCategoryName gothamNarrowLightFont">{currentTicket.ticket_category}</div>
                            {
                                currentTicket.resolution_status === "Resolved" ? 
                                <div style={{display: "flex", justifyContent: "flex-end"}} className="gothamNarrowLightFont">Status: <span style={{color: "green", fontWeight: "bold", paddingLeft: "10px"}} className="gothamNarrowFont">{currentTicket.resolution_status}</span></div> :
                                <div style={{display: "flex", justifyContent: "flex-end"}} className="gothamNarrowLightFont">Status: <span style={{color: "orange", fontWeight: "bold", paddingLeft: "10px"}} className="gothamNarrowFont">{currentTicket.resolution_status}</span></div>
                            }
                            {this.props.userPermissions.can_manage_assignments ?
                                <div className="gothamNarrowLightFont">Technician Assigned: <div><ReassignTechSelector/></div></div> 
                                :
                                <div className="gothamNarrowLightFont">Technician Assigned: <div>{currentTicket.assigned_tech_name}</div></div>
                            }                           
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{color: "rgba(75,75,75,1)"}}>
                            <div className="gothamNarrowFont"><b>{currentTicket.name_of_requester}</b></div>
                        </Col>
                        <Col style={{display: "flex", justifyContent: "flex-end", marginBottom: "25px"}}>
                            <div className="gothamNarrowLightFont">{currentTicket.time_since_sent}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className="gothamNarrowLightFont">{currentTicket.issue_text.split('\n').map(str => <p>{str}</p>)}</div>
                        </Col>
                    </Row>
                    { /*If the ticket has a solution currently selected display it here */
                    currentTicket.solution_text !== null ? 
                    <Row>
                        <Col>
                            <div className="acceptedSolutionWrapper">
                                <div>
                                    <div className="gothamNarrowFont" style={{paddingLeft: "5px"}}>Accepted Solution:</div>
                                    <div className="gothamNarrowLightFont" style={{marginTop: "5px", paddingLeft: "25px"}}>{currentTicket.solution_text.split('\n').map(str => <p>{str}</p>)}</div>
                                    {/* Display information about the person who solved it */
                                        currentTicket.solver_id !== null ?
                                        <div>
                                            <div className="gothamNarrowLightFont" style={{paddingLeft: "5px", marginTop: "5px"}}>Solved by: {currentTicket.solver_name}</div>
                                            <div className="gothamNarrowLightFont" style={{paddingLeft: "5px"}}>User's solve count: {currentTicket.solver_solution_count}</div>
                                        </div> :
                                        undefined
                                    }
                                    {/*If the user is the owner, give them the option to select a different solution */
                                        currentTicket.isTicketOwner || this.props.userPermissions.can_add_solutions ? 
                                        <div style={{textAlign: "right", marginRight: "15px", paddingBottom: "15px"}}>
                                            <Button variant="outline-secondary" onClick={() => this.removeSolution(currentTicket.id)}>Pick A Different Solution</Button>
                                        </div> : 
                                        undefined
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row> :
                    undefined
                    }
                    <Row style={{marginTop: "30px"}}>
                        <Col>
                            <ListGroup horizontal style={{textAlign: "center"}}>
                                <ListGroup.Item>
                                    <div className="gothamNarrowLightFont"><FontAwesomeIcon style={{color: "gray"}} icon={faEye} /> <b>{currentTicket.total_clicks_all_time}</b></div>
                                    {/* <div className="gothamNarrowLightFont">{currentTicket.total_clicks_all_time}</div> */}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="gothamNarrowLightFont" style={{color: "green"}}><FontAwesomeIcon style={{color: "gray"}} icon={faThumbsUp} /> <b>{currentTicket.up_votes}</b></div>
                                    {/* <div className="gothamNarrowLightFont">{currentTicket.up_votes}</div> */}
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <div className="gothamNarrowLightFont" style={{color: "red"}}><FontAwesomeIcon style={{color: "gray"}} icon={faThumbsDown} /> <b>{currentTicket.down_votes}</b></div>
                                    {/* <div className="gothamNarrowLightFont">{currentTicket.down_votes}</div> */}
                                </ListGroup.Item>
                            </ListGroup>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "15px"}}>
                        <Col>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <Button variant="outline-success" onClick={() => this.addALike(currentTicket.id, this.props.loggedStatus.id, accessToken)} className="gothamNarrowFont"style={{
                                    backgroundColor: this.state.button_liked ? 'green':'white',
                                    color: this.state.button_liked ? 'white' : 'green' }}>Like</Button>{' '}
                                <Button variant="outline-danger" onClick={() => this.addADislike(currentTicket.id, this.props.loggedStatus.id, accessToken)} className="gothamNarrowFont" style={{
                                    marginLeft: '5px',
                                    backgroundColor: this.state.button_disliked ? 'red': 'white',
                                    color: this.state.button_disliked ? 'white' : 'red' }}>Dislike</Button>{' '}
                            </div>
                        </Col>
                        <Col>
                            <div style={{textAlign: "right"}}>
                                {currentTicket.resolution_status === "Open" ? 
                                    <Button variant="outline-secondary" onClick={() => this.replyToOriginalPost(currentTicket.name_of_requester, currentTicket.issue_text, currentTicket.id)} className="gothamNarrowFont">Reply</Button>
                                    : undefined
                                }
                            </div>    
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{marginBottom: "40px"}}>
                            <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{marginBottom: "10px", color: "rgba(75,75,75,1)"}} className="gothamNarrowFont"><b>Replies</b></div>
                            <Table>
                                <tbody>
                                    {comments && comments.map((comment) => {
                                        return (
                                            <Fragment key={comment.id}>
                                                <tr>
                                                    <td>
                                                        <div style={{color: "rgba(75,75,75,1)"}} className="gothamNarrowFont"><b>{comment.name_of_commenter}</b></div>
                                                        <div>(Solution count: {comment.accepted_solution_count})</div>
                                                    </td>
                                                    {/* <td style={{display: "flex", justifyContent: "flex-end"}}> */}
                                                    <td>
                                                        <div style={{textAlign: "right"}} className="gothamNarrowLightFont">{comment.time_since_sent}</div>
                                                    </td>
                                                    {/* {comment.associated_comment_id === null ? <td>{comment.comment_text}</td> : <td>{comment.comment_text}</td>} */}
                                                </tr>
                                                <tr>
                                                    <td colSpan="2" className="commentReplyingToTableRow">
                                                        <div className="commentReplyingToWrapper"> {/*If associated comment id is null, it means they replied to base ticket, if not null, they replied to another commment */}
                                                            {comment.associated_comment_id !== null ? 
                                                            <div>
                                                                <div className="gothamNarrowLightFont">Replying to: {comment.name_of_reply_commenter} (comment)</div>
                                                                <div className="gothamNarrowLightFont" style={{marginTop: "5px", paddingLeft: "25px"}}>{comment.comment_text_replying_to.split('\n').map(str => <p>{str}</p>)}</div>
                                                            </div>
                                                             :
                                                            <div>
                                                                <div className="gothamNarrowLightFont">Replying to: {comment.name_of_ticket_creator} (base ticket)</div>
                                                                <div className="gothamNarrowLightFont" style={{marginTop: "5px", paddingLeft: "25px"}}>{comment.ticket_text_replying_to.split('\n').map(str => <p>{str}</p>)}</div>
                                                            </div>    
                                                            }
                                                            
                                                        </div>
                                                        <div className="gothamNarrowLightFont" style={{marginTop: "25px"}}>{comment.comment_text.split('\n').map(str => <p>{str}</p>)}</div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className="replyButtonTableRow">
                                                        {
                                                            currentTicket.solution_text === null && (currentTicket.isTicketOwner || this.props.userPermissions.can_add_solutions) ?
                                                            <div style={{textAlign: "left"}}><Button variant="outline-success" onClick={() => this.selectSolution(comment.comment_text, currentTicket.id, comment.commenter_id, comment.name_of_commenter, comment.accepted_solution_count)}>Mark As Solution</Button></div> :
                                                            <div></div>
                                                        }
                                                    </td>
                                                    <td className="replyButtonTableRow">
                                                        <div style={{textAlign: "right"}}>
                                                            {
                                                                currentTicket.resolution_status === "Open" ?
                                                                <Button variant="outline-secondary" onClick={() => this.replyToComment(comment.name_of_commenter, comment.comment_text, currentTicket.id, comment.id)} className="gothamNarrowFont">Reply</Button>
                                                                : undefined
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            </Fragment>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
                </div>
                <Modal
                    show={this.state.show_reply_form}
                    onHide={() => this.cancelReply()}
                    backdrop="static"
                    keyboard={false}
                    size="xl"
                    centered
                >
                    <Modal.Header closeButton>
                    <Modal.Title className="gothamNarrowFont">Reply to {this.state.name_replying_to}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <Container style={{overflow: "scroll", height: "150px"}}>
                                <Row>
                                    <Col>
                                        <div style={{textAlign: "left"}} className="gothamNarrowFont">Ticket / Comment Replying To: </div>
                                    </Col>
                                    <Col>
                                        <div style={{textAlign: "left"}} className="gothamNarrowLightFont">{this.state.issue_text_responding_to.split('\n').map(str => <p>{str}</p>)}</div>
                                    </Col>
                                </Row>
                            </Container>
                            <hr/>
                            <Form onSubmit={this.submitReply}>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridCommenterId">
                                        <Form.Control type="hidden" value={this.props.loggedStatus.id} name="commenterId"/> 
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAssociatedTicketId">
                                        <Form.Control type="hidden" value={this.state.currentTicket.id} name="associatedTicketId"/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formGridAssociatedCommentId">
                                        <Form.Control type="hidden" value={this.state.comment_id_responding_to} name="associatedCommentId"/>
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formGridReplyText">
                                        <Form.Label className="gothamNarrowFont">Your Response</Form.Label>
                                        <Form.Control as="textarea" style={{height: "250px"}} className="gothamNarrowLightFont" placeholder="Type your reply here" name="responseText" required/>
                                        <Form.Text className="text-muted gothamNarrowLightFont">
                                            Note: If you are referencing a specific part of the user's comment, consider copy/pasting the relevant section from the issue text above into this box for others' reference.
                                        </Form.Text>
                                    </Form.Group>
                                </Form.Row>
                                
                                <div style={{textAlign: "center"}}>
                                <Button variant="primary" type="submit" className="gothamNarrowFont">
                                    Submit
                                </Button>
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                </Modal>

            </>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        userPermissions: state.userPermissions,
        reassignTech: state.reassignTech
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_ASSIGNMENT, ASSIGNMENT_CHANGE_RESOLVED, ACCESSTOKEN, LOGOUT})(SoftwareTicketDetails);