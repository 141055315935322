// this component receives a list of a ticket creator's last logged in devices and generates a display with tooltips to view details of those devices
// currently going to be in use on clarify form and ticket details screen

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import { useDisclosure } from '@mantine/hooks';

import {
    HoverCard,
    Group,
    Text,
    Drawer,
    Button,
    ScrollArea,
    Table
} from '@mantine/core';

function PossiblyAffectedDevices(props) {

    const deviceList = props.deviceList;

    React.useEffect(() => {
        console.log("Props received by affected devices: ", props.deviceList);
        console.log("Deskop List = ", props.deviceList.desktops);
        
    }, [deviceList, props.deviceList]);

    const [opened, { open, close }] = useDisclosure(false);


    return (
        <>
            <Button onClick={open} className="gothamNarrowFont">Possibly Affected Devices</Button>
            {/* <Text style={{cursor: "default"}}>Possibly Affected Devices:</Text> */}
            <Drawer
                opened={opened}
                onClose={close}
                title="Devices Last Used By Ticket Creator"
                overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
                //scrollAreaComponent={ScrollArea.Autosize}
                transitionProps={{ transition: 'slide-right', duration: 150, timingFunction: 'linear' }}
                offset={8} 
                radius="md"
                className="affectedDevicesHoverCardBackground, affectedDevicesHoverCardText"
            >
                {deviceList && deviceList.desktops.length > 0 ? <Text style={{cursor: "default", marginTop: "25px"}}>Desktops</Text> : undefined}
                {deviceList && deviceList.desktops.map((device) => (
                    <Group justify="left" key={device.taniumId}>
                        <HoverCard width={600} withArrow arrowSize={20} position="right" offset={30}>
                            <HoverCard.Target>
                                <Text c="blue" style={{cursor: "default"}}>{device.name}</Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown className="affectedDevicesHoverCardBackground">
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Name</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.name}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Chassis Type</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.chassisType}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Local IP Address</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.localIpAddress}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">NAT IP Address</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.natIpAddress}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Last Seen</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.lastSeen}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Last User</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.lastUser}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Serial Number</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.serialNumber}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Free Disk Space</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.freeDiskSpace}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Used Disk %</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.usedDiskPercent}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Manufacturer</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.manufacturer}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Model</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.model}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">OS Name</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.osName}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">OS Platform</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.osPlatform}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Tanium ID</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.taniumId}</Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </HoverCard.Dropdown>
                        </HoverCard>
                    </Group>
                ))}
                {deviceList && deviceList.notebooks.length > 0 ? <Text style={{cursor: "default", marginTop: "25px"}}>Notebooks</Text> : undefined}
                {deviceList && deviceList.notebooks.map((device) => (
                    <Group justify="left" key={device.taniumId}>
                        <HoverCard width={600} withArrow arrowSize={20} position="right" offset={30}>
                            <HoverCard.Target>
                                <Text c="blue" style={{cursor: "default"}}>{device.name}</Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown className="affectedDevicesHoverCardBackground">
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Name</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.name}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Chassis Type</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.chassisType}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Local IP Address</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.localIpAddress}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">NAT IP Address</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.natIpAddress}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Last Seen</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.lastSeen}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Last User</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.lastUser}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Serial Number</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.serialNumber}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Free Disk Space</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.freeDiskSpace}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Used Disk %</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.usedDiskPercent}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Manufacturer</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.manufacturer}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Model</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.model}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">OS Name</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.osName}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">OS Platform</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.osPlatform}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Tanium ID</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.taniumId}</Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </HoverCard.Dropdown>
                        </HoverCard>
                    </Group>
                ))}
                {deviceList && deviceList.other.length > 0 ? <Text style={{cursor: "default", marginTop: "25px"}}>Other</Text> : undefined}
                {deviceList && deviceList.other.map((device) => (
                    <Group justify="left" key={device.taniumId}>
                        <HoverCard width={600} withArrow arrowSize={20} position="right" offset={30}>
                            <HoverCard.Target>
                                <Text c="blue" style={{cursor: "default"}}>{device.name}</Text>
                            </HoverCard.Target>
                            <HoverCard.Dropdown className="affectedDevicesHoverCardBackground">
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tbody>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Name</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.name}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Chassis Type</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.chassisType}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Local IP Address</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.localIpAddress}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">NAT IP Address</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.natIpAddress}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Last Seen</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.lastSeen}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Last User</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.lastUser}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Serial Number</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.serialNumber}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Free Disk Space</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.freeDiskSpace}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Used Disk %</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.usedDiskPercent}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Manufacturer</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.manufacturer}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Model</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.model}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">OS Name</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.osName}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">OS Platform</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.osPlatform}</Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "50%"}} className="affectedDevicesHoverCardText">Tanium ID</Table.Td>
                                            <Table.Td style={{width: "50%"}} className="roleYesText">{device.taniumId}</Table.Td>
                                        </Table.Tr>
                                    </Table.Tbody>
                                </Table>
                            </HoverCard.Dropdown>
                        </HoverCard>
                    </Group>
                ))}
            </Drawer>
        </>
    );

}

export default PossiblyAffectedDevices;