import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
//import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';
//import Modal from 'react-bootstrap/Modal';
import Navbar from '../../components/Navbar';
import {
    Box,
    Button,
    FileInput,
    Grid,
    LoadingOverlay,
    Modal,
    NumberInput,
    Paper,
    Table,
    Tabs,
    Text,
    Textarea,
    TextInput,
    Tooltip,
    Select,
    Switch
} from '@mantine/core';

import {
    DateInput,
    DateTimePicker
} from '@mantine/dates';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { IconEdit, IconPlus } from "@tabler/icons-react";

import HardwareAssetDataService from '../../services/hardware-asset-activities';

import { UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE } from '../../features/actions/Hardware-Asset-Filter-Actions';
//import { Table } from 'react-bootstrap';

class CreateHardwareAsset extends Component {

    constructor(props) {
        super(props);

        this.toggleAddFormAssignedAssetInputVisible = this.toggleAddFormAssignedAssetInputVisible.bind(this);
        this.closeAddPage = this.closeAddPage.bind(this);
        this.closeCategoryModal = this.closeCategoryModal.bind(this);
        this.openCategoryModal = this.openCategoryModal.bind(this);
        this.closeTypeModal = this.closeTypeModal.bind(this);
        this.openTypeModal = this.openTypeModal.bind(this);
        this.closeModelModal = this.closeModelModal.bind(this);
        this.openModelModal = this.openModelModal.bind(this);
        this.closeManufacturerModal = this.closeManufacturerModal.bind(this);
        this.openManufacturerModal = this.openManufacturerModal.bind(this);
        this.closeSiteModal = this.closeSiteModal.bind(this);
        this.openSiteModal = this.openSiteModal.bind(this);
        this.closeLocationModal = this.closeLocationModal.bind(this);
        this.openLocationModal = this.openLocationModal.bind(this);
        this.closeVendorModal = this.closeVendorModal.bind(this);
        this.openVendorModal = this.openVendorModal.bind(this);

        this.getHardwareAssetTypes = this.getHardwareAssetTypes.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.getTenantList = this.getTenantList.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getManufacturers = this.getManufacturers.bind(this);
        this.getModels = this.getModels.bind(this);
        this.getSites = this.getSites.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getAssignableAssets = this.getAssignableAssets.bind(this);


        this.handleIsCoreChange = this.handleIsCoreChange.bind(this);
        this.handleTenantChange = this.handleTenantChange.bind(this);
        this.handleAssetNameEnteredChange = this.handleAssetNameEnteredChange.bind(this);
        this.handleSelectedCategoryChange = this.handleSelectedCategoryChange.bind(this);
        this.handleSelectedTypeChange = this.handleSelectedTypeChange.bind(this);
        this.handleSelectedModelChange = this.handleSelectedModelChange.bind(this);
        this.handleSelectedSiteChange = this.handleSelectedSiteChange.bind(this);
        this.handleSelectedLocationChange = this.handleSelectedLocationChange.bind(this);
        this.handleSelectedAssignedPersonChange = this.handleSelectedAssignedPersonChange.bind(this);
        this.handleSelectedVendorChange = this.handleSelectedVendorChange.bind(this);
        this.handleAssetTagEnteredChange = this.handleAssetTagEnteredChange.bind(this);
        this.handleSerialNumberEnteredChange = this.handleSerialNumberEnteredChange.bind(this);
        this.handleBarcodeEnteredChange = this.handleBarcodeEnteredChange.bind(this);
        this.handleIPAddressEnteredChange = this.handleIPAddressEnteredChange.bind(this);
        this.handleMACAddressEnteredChange = this.handleMACAddressEnteredChange.bind(this);
        this.handleRAMEnteredChange = this.handleRAMEnteredChange.bind(this);
        this.handleCPUEnteredChange = this.handleCPUEnteredChange.bind(this);
        this.handleAcquisitionDateChange = this.handleAcquisitionDateChange.bind(this);
        this.handleWebsiteEnteredChange = this.handleWebsiteEnteredChange.bind(this);
        this.handleWarrantyStartDateChange = this.handleWarrantyStartDateChange.bind(this);
        this.handleWarrantyEndDateChange = this.handleWarrantyEndDateChange.bind(this);
        this.handleLeaseStartDateChange = this.handleLeaseStartDateChange.bind(this);
        this.handleLeaseEndDateChange = this.handleLeaseEndDateChange.bind(this);
        this.handleTrackAssetChange = this.handleTrackAssetChange.bind(this);
        this.handleIsPeripheralChange = this.handleIsPeripheralChange.bind(this);
        this.handleAssignedAssetCategoryChange = this.handleAssignedAssetCategoryChange.bind(this);
        this.handleAssignedAssetTypeChange = this.handleAssignedAssetTypeChange.bind(this);
        this.handleContractNumberEnteredChange = this.handleContractNumberEnteredChange.bind(this);


        this.submitAddAssetForm = this.submitAddAssetForm.bind(this);


        this.handleCreateCategoryNameChange = this.handleCreateCategoryNameChange.bind(this);
        this.submitCreateCategoryForm = this.submitCreateCategoryForm.bind(this);

        this.handleCreateTypeNameChange = this.handleCreateTypeNameChange.bind(this);
        this.submitCreateTypeForm = this.submitCreateTypeForm.bind(this);

        this.handleCreateModelNameChange = this.handleCreateModelNameChange.bind(this);
        this.handleCreateModelTypeChange = this.handleCreateModelTypeChange.bind(this);
        this.handleCreateModelCostChange = this.handleCreateModelCostChange.bind(this);
        this.handleCreateModelManufacturerChange = this.handleCreateModelManufacturerChange.bind(this);
        this.submitCreateModelForm = this.submitCreateModelForm.bind(this);

        this.handleCreateManufacturerNameChange = this.handleCreateManufacturerNameChange.bind(this);
        this.handleCreateManufacturerTypeChange = this.handleCreateManufacturerTypeChange.bind(this);
        this.handleCreateManufacturerPhoneChange = this.handleCreateManufacturerPhoneChange.bind(this);
        this.submitCreateManufacturerForm = this.submitCreateManufacturerForm.bind(this);

        this.handleCreateSiteNameChange = this.handleCreateSiteNameChange.bind(this);
        this.handleCreateSiteStreetAddressChange = this.handleCreateSiteStreetAddressChange.bind(this);
        this.handleCreateSiteCityChange = this.handleCreateSiteCityChange.bind(this);
        this.handleCreateSiteStateChange = this.handleCreateSiteStateChange.bind(this);
        this.handleCreateSiteZipCodeChange = this.handleCreateSiteZipCodeChange.bind(this);
        this.submitCreateSiteForm = this.submitCreateSiteForm.bind(this);

        this.handleCreateLocationFloorChange = this.handleCreateLocationFloorChange.bind(this);
        this.handleCreateLocationAisleChange = this.handleCreateLocationAisleChange.bind(this);
        this.handleCreateLocationDeskChange = this.handleCreateLocationDeskChange.bind(this);
        this.submitCreateLocationForm = this.submitCreateLocationForm.bind(this);

        this.handleCreateVendorNameChange = this.handleCreateVendorNameChange.bind(this);
        this.submitCreateVendorForm = this.submitCreateVendorForm.bind(this);


        this.state = {
            enable_assigned_asset_input: false,

            // drop down menu items
            asset_categories: [],       // {id: 1, name_of_category: "IT"}
            asset_types: [],    // can reuse code from asset list   {id: 1, name_of_type: "Printer"}
            model_names: [],    // {id: 1, name_of_model: "Think Pad"}
            sites: [{id: 0, name_of_site: "None"}],      // {id: 1, name_of_site: "Irvine Office"}
            locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }],      // contains data for the currently selected site  {id: 1, floor_number: 1, aisle_number: 2, desk_number: 5"}
            people: [{ id: 0, full_name: "None", name_of_tenant: "All" }],         // for the assigned to drop down menu   // can reuse code from asset list {id: 1, full_name: "Tom Johnson", name_of_tenant: "ProGroup"}
            vendors: [{ id: 0, name_of_vendor: "None" }],        // {id: 1, name_of_vendor: "EMC"}
            tenants: [],    // can reuse code from asset list   {id: 1, name_of_tenant: "ProGroup"}
            assigned_asset_names: [],   // gets populated with values of assets with matching type and company info from assigned to drop downs

            // core add asset form variables
            selected_tenant_id: 0,
            asset_name_entered: "",
            selected_category_id: 0,
            selected_type_id: 0,
            selected_type_name: "",     // this is used to determine what tabs to show in the asset form - gets set on component mount + whenever user changes selected type
            selected_model_id: 0,
            selected_site_id: 0,
            selected_location_in_building_id: 0,
            selected_person_id: 0,
            selected_vendor_id: 0,
            asset_tag_entered: "",
            serial_number_entered: "",
            barcode_entered: "",
            ip_address_entered: "",
            mac_address_entered: "",
            ram_entered: "",
            cpu_entered: "",
            aquisition_date_entered: "",
            website_entered: "",
            warranty_start_date_entered: "",
            warranty_end_date_entered: "",
            lease_start_date_entered: "",
            lease_end_date_entered: "",
            track_asset: true,
            is_peripheral: false,
            contract_number_entered: "",

            // variables for assigning peripheral to core asset (3 drop downs at end of form)
            selected_assigned_asset_category_id: 0,
            selected_assigned_asset_type_id: 0,
            selected_assigned_asset_name_id: 0,

            // view modal controllers
            show_category_modal: false,
            show_type_modal: false,
            show_model_modal: false,
            show_manufacturer_modal: false,
            show_site_modal: false,
            show_location_modal: false,
            show_vendor_modal: false,
            show_welcome_modal: false,

            show_loading_overlay: true,     // shows loading screen while form fields load. turns off when page fully loaded

            from_model_modal: false,    // determines if user came from model or core form (affects where they get sent back to after closing)

            selected_form_tab: "Basic Info",      // will determine what form section actually renders (to optomize text input) - starts on Basic Info


            // modal form values

            // add category form
            createCategory_category_name_entered: "",

            // Add type form
            type_name_entered: "",
            selected_is_core_value: true,

            // Model form
            model_name_entered: "",
            model_type_id_selected: 0,
            cost_entered: "",
            manufacturer_id_selected: 0,        // this one is now being used by the welcome modal
            manufacturer_id_selected_create_model_form: 0,  // this one is now used by the actual model form
            manufacturers: [],      // {id: 1, name_of_manufacturer: "Dell"}

            // manufacturer form
            manufacturer_name_entered: "",
            manufacturer_type_entered: "",
            manufacturer_phone_number_entered: "",

            // site form
            // tenant_id_selected is based on selected_tenant_id from core asset form above
            site_name_entered: "",
            site_street_address_entered: "",
            site_city_entered: "",
            site_state_entered: "",
            site_zip_code_entered: "",

            // location at site form
            location_site_id_selected: 0,
            location_floor_entered: "",
            location_aisle_entered: "",
            location_desk_entered: "",

            // vendor form
            vendor_name_entered: "",


            canSeeErrorMessage: false,       // gets set to true if user tries to submit and incomplete form
            canSeeDuplicateNameError: false,     // gets set to true if user tries to submit a duplicate asset name

            canSeeErrorMessageCreateCategory: false,
            canSeeDuplicateNameErrorCreateCategory: false,

            canSeeErrorMessageCreateType: false,
            canSeeDuplicateNameErrorCreateType: false,

            canSeeErrorMessageCreateModel: false,
            canSeeDuplicateNameErrorCreateModel: false,

            canSeeErrorMessageCreateManufacturer: false,
            canSeeDuplicateNameErrorCreateManufacturer: false,

            canSeeDuplicateNameErrorCreateSite: false,
            canSeeErrorMessageCreateSite: false,

            canSeeDuplicateNameErrorCreateLocation: false,
            canSeeErrorMessageCreateLocation: false,

            canSeeDuplicateNameErrorCreateVendor: false,
            canSeeErrorMessageCreateVendor: false

        };
    }

    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // fetch a new token every 5 minutes to prevent stale token error
                    this.interval = setInterval(() => {
                        this.fetchNewToken();
                      }, 300000);

                    // make the intro modal appear
                    this.setState({
                        show_welcome_modal: true
                    });

                    // call the GET functions to populate the dropdowns throughout screen
                    this.getHardwareAssetTypes(token).then(response => {
                        this.setState({
                            asset_types: response.results
                        });

                        if (response.results && response.results.length > 0) {
                            this.setState({
                                selected_type_id: response.results[0].id,
                                selected_type_name: response.results[0].name_of_type,
                                selected_assigned_asset_type_id: response.results[0].id,
                                model_type_id_selected: response.results[0].id
                            });
                        }

                        this.getTenantList(token, this.props.loggedStatus.id).then(response => {
                            this.setState({
                                tenants: response.results
                            });

                            let selectedTenantTemp;     // will briefly contain the value of the tenant in the first position returned, or 0 if none

                            // if they had previously selected a tenant, use that one as the default to return to
                            if(this.props.hardwareAssetFilter.selectedCompanyInHardwareAssetAddForm !== 0) {

                                this.setState({
                                    selected_tenant_id: this.props.hardwareAssetFilter.selectedCompanyInHardwareAssetAddForm
                                });

                                selectedTenantTemp = this.props.hardwareAssetFilter.selectedCompanyInHardwareAssetAddForm;
                            }

                            // we showed no previous tenant selected, so default to the first one in the returned list if possible
                            else if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_tenant_id: response.results[0].id
                                });

                                selectedTenantTemp = response.results[0].id;

                                // update the global state with this new value
                                this.props.UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE(response.results[0].id);
                            }

                            // there was no previously selected tenant AND there were no tenants returned in the list from the backend
                            else {
                                selectedTenantTemp = 0;
                            }

                            this.getManufacturers(token).then(response => {
                                this.setState({
                                    manufacturers: response.results,
                                    show_loading_overlay: false
                                });

                                if (response.results && response.results.length > 0) {
                                    this.setState({
                                        manufacturer_id_selected: response.results[0].id
                                    });
                                }

                                this.getUserList(token, this.props.loggedStatus.id, selectedTenantTemp).then(response => {
                                    this.setState({
                                        people: [...this.state.people, ...response.results],
                                        selected_person_id: 0   // we have hardcoded the "None" option so we know that's going to be the iniital value automatically
                                    });

                                    // this.getTenantList(token, this.props.loggedStatus.id).then(response => {
                                    //     this.setState({
                                    //         tenants: response.results
                                    //     });

                                    //     if(response.results && response.results.length > 0) {
                                    //         this.setState({
                                    //             selected_tenant_id: response.results[0].id
                                    //         });
                                    //     }

                                    this.getCategories(token).then(response => {
                                        this.setState({
                                            asset_categories: response.results
                                        });

                                        if (response.results && response.results.length > 0) {
                                            this.setState({
                                                selected_assigned_asset_category_id: response.results[0].id,
                                                selected_category_id: response.results[0].id
                                            });
                                        }

                                        this.getVendors(token, this.props.loggedStatus.primary_tenant_id).then(response => {
                                            this.setState({
                                                vendors: [...this.state.vendors, ...response.results],
                                                selected_vendor_id: 0   // we have hardcoded "None" value so we know it's automatically 0 to start
                                            });

                                            // get model names based on the selected type in the types drop down menu
                                            this.getModels(token, this.state.selected_type_id).then(response => {
                                                this.setState({
                                                    model_names: response.results
                                                });

                                                if (response.results && response.results.length > 0) {
                                                    this.setState({
                                                        selected_model_id: response.results[0].id
                                                    });
                                                }

                                                this.getAssignableAssets(token, this.state.selected_tenant_id, this.state.selected_assigned_asset_type_id, this.state.selected_assigned_asset_category_id).then(response => {

                                                    this.setState({
                                                        assigned_asset_names: response.results
                                                    });

                                                    if (response.results && response.results.length > 0) {
                                                        this.setState({
                                                            selected_assigned_asset_name_id: response.results[0].id
                                                        });
                                                    }

                                                    this.getSites(token, this.state.selected_tenant_id).then(response => {

                                                        this.setState({
                                                            sites: [...this.state.sites, ...response.results]
                                                        }, () => {
                                                            if (this.state.sites && this.state.sites.length > 0) {
                                                                this.setState({
                                                                    selected_site_id: this.state.sites[0].id
                                                                });

                                                                // get locations in building based on that site id
                                                                this.getLocations(token, this.state.sites[0].id).then(response => {
                                                                    this.setState({
                                                                        locations_at_site: [...this.state.locations_at_site, ...response.results],
                                                                        selected_location_in_building_id: 0 // we have hardcoded "None" option that drop down starts at
                                                                    });

                                                                    // remove the loading overlay
                                                                    this.setState({
                                                                        show_loading_overlay: false
                                                                    });


                                                                })
                                                                    .catch(e => {
                                                                        console.log(e);
                                                                    });
                                                            }
                                                        });

                                                    })
                                                        .catch(e => {
                                                            console.log(e);
                                                        });
                                                })
                                                    .catch(e => {
                                                        console.log(e);
                                                    });

                                            })
                                                .catch(e => {
                                                    console.log(e);
                                                });

                                        })
                                            .catch(e => {
                                                console.log(e);
                                            });

                                    })
                                        .catch(e => {
                                            console.log(e);
                                        });

                                })
                                    .catch(e => {
                                        console.log(e);
                                    });

                            })
                                .catch(e => {
                                    console.log(e);
                                });

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e)
                        });
                }
            });
        }
    }

    componentWillUnmount() {
        // Clean up the interval when the component unmounts
        clearInterval(this.interval);
    }

    fetchNewToken = async () => {

        console.log("Checking for new token now...");
        console.log("Selected tab is: ", this.state.selected_form_tab);

        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
            // if the token can't be refreshed, force a logout
            if (token === undefined || token === null || token === "") {
                console.log("Token was expired and can't refresh, so force logging out this user!");
                this.props.LOGOUT();        // logout of global state
                this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
            }

            else {
                this.props.ACCESSTOKEN(token);
            }
        });
    }


    // determines whether user sees form field for specific asset a peripheral is assigned to
    toggleAddFormAssignedAssetInputVisible(newValue) {

        if (this.state.enable_assigned_asset_input && newValue === false) {
            this.setState({
                enable_assigned_asset_input: false
            });
        }

        else if (!this.state.enable_assigned_asset_input && newValue === true) {
            this.setState({
                enable_assigned_asset_input: true
            });
        }

    }


    closeAddPage() {
        this.props.history.push('/hardwareAssets');
    }

    openCategoryModal() {
        this.setState({
            show_category_modal: true
        });
    }

    closeCategoryModal() {
        this.setState({
            show_category_modal: false,
            createCategory_category_name_entered: "",
            canSeeDuplicateNameErrorCreateCategory: false,
            canSeeErrorMessageCreateCategory: false
        });
    }

    openTypeModal(fromModel) {

        if (fromModel) {     // if coming from the model modal, hide model and show type
            this.setState({
                show_model_modal: false,
                show_type_modal: true,
                from_model_modal: true
            });
        }

        else {
            this.setState({
                show_type_modal: true
            });
        }
    }

    closeTypeModal() {

        if (this.state.from_model_modal) {
            this.setState({
                show_type_modal: false,
                show_model_modal: true,
                from_model_modal: false,
                type_name_entered: "",
                selected_is_core_value: true,
                canSeeDuplicateNameErrorCreateType: false,
                canSeeErrorMessageCreateType: false
            });
        }

        else {

            this.setState({
                show_type_modal: false,
                type_name_entered: "",
                selected_is_core_value: true,
                canSeeDuplicateNameErrorCreateType: false,
                canSeeErrorMessageCreateType: false
            });
        }
    }

    openModelModal() {
        this.setState({
            show_model_modal: true,
            model_name_entered: "",
            cost_entered: 0
        });

        if (this.state.asset_types && this.state.asset_types.length > 0) {
            this.setState({
                model_type_id_selected: this.state.asset_types[0].id
            });
        }

        else {
            this.setState({
                model_type_id_selected: 0
            });
        }

        if (this.state.manufacturers && this.state.manufacturers.length > 0) {
            this.setState({
                manufacturer_id_selected_create_model_form: this.state.manufacturers[0].id
            });
        }

        else {
            this.setState({
                manufacturer_id_selected_create_model_form: 0
            });
        }
    }

    closeModelModal() {
        this.setState({
            show_model_modal: false
        });
    }

    openManufacturerModal() {
        this.setState({
            show_manufacturer_modal: true,
            show_model_modal: false
        });
    }

    closeManufacturerModal() {
        this.setState({
            show_manufacturer_modal: false,
            show_model_modal: true,
            manufacturer_name_entered: "",          // reset form fields
            manufacturer_type_entered: "",
            manufacturer_phone_number_entered: ""
        });
    }

    openSiteModal() {
        this.setState({
            show_site_modal: true
        });
    }

    closeSiteModal() {
        this.setState({
            show_site_modal: false,
            site_name_entered: "",  // reset form fields
            site_street_address_entered: "",
            site_city_entered: "",
            site_state_entered: "",
            site_zip_code_entered: ""
        });
    }

    openLocationModal() {
        this.setState({
            show_location_modal: true
        });
    }

    closeLocationModal() {
        this.setState({
            show_location_modal: false,
            location_floor_entered: "",
            location_aisle_entered: "",
            location_desk_entered: ""
        });
    }

    openVendorModal() {
        this.setState({
            show_vendor_modal: true
        });
    }

    closeVendorModal() {
        this.setState({
            show_vendor_modal: false,
            vendor_name_entered: ""
        });
    }


    openWelcomeModal() {
        this.setState({
            show_welcome_modal: true
        });
    }

    closeWelcomeModal() {
        this.setState({
            show_welcome_modal: false
        });
    }


    handleIsCoreChange(newValue) {
        this.setState({
            selected_is_core_value: newValue    // will be either true or false
        });
    }

    handleTenantChange(tenantId) {
        this.setState({
            selected_tenant_id: tenantId
        });

        // update the global state with this new value
        this.props.UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE(tenantId);

        // get new sites for this new tenant
        this.getSites(this.props.loggedStatus.accessToken, tenantId).then(response => {
            this.setState({
                sites: [{ id: 0, name_of_site: "None"}, ...response.results]
            }, () => {
                if (this.state.sites && this.state.sites.length > 0) {
                    this.setState({
                        selected_site_id: this.state.sites[0].id
                    });

                    // get locations in building based on that site id
                    this.getLocations(this.props.loggedStatus.accessToken, this.state.sites[0].id).then(response => {
                        this.setState({
                            locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }, ...response.results],
                            selected_location_in_building_id: 0 // we have hardcoded "None" option that drop down starts at
                        });


                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

                else {      // there were no sites entered for that tenant, so we just have empty lists there and reset to default 0 selected
                    this.setState({
                        sites: [{id: 0, name_of_site: "None"}],
                        selected_site_id: 0,
                        locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }],
                        selected_location_in_building_id: 0
                    });
                }


            });

            // get the new user list for that tenant for the assigned to drop down menu
            this.getUserList(this.props.loggedStatus.accessToken, this.props.loggedStatus.id, tenantId).then(response => {
                this.setState({
                    people: [{ id: 0, full_name: "None", name_of_tenant: "All" }, ...response.results],
                    selected_person_id: 0   // we have hardcoded the "None" option so we know that's going to be the iniital value automatically
                });

                // get the new assignable asset list for this tenant (the drop down at bottom of form)
                // get new values for the assignable assets drop down menu
                this.getAssignableAssets(this.props.loggedStatus.accessToken, tenantId, this.state.selected_assigned_asset_type_id, this.state.selected_assigned_asset_category_id).then(response => {

                    this.setState({
                        assigned_asset_names: response.results
                    });

                    if (response.results && response.results.length > 0) {
                        this.setState({
                            selected_assigned_asset_name_id: response.results[0].id
                        });
                    }

                    else {
                        this.setState({
                            selected_assigned_asset_name_id: 0
                        });
                    }

                })
                    .catch(e => {
                        console.log(e);
                    });
            })
                .catch(e => {
                    console.log(e);
                });
        })
            .catch(e => {
                console.log(e);
            });

    }

    handleAssetNameEnteredChange(e) {
        this.setState({
            asset_name_entered: e.target.value
        });
    }

    handleSelectedCategoryChange(categoryId) {
        this.setState({
            selected_category_id: categoryId
        });
    }

    handleSelectedTypeChange(typeId) {
        this.setState({
            selected_type_id: typeId,
            selected_type_name: this.state.asset_types.find((e) => e.id === typeId).name_of_type
        });

        // changed type, so update the values in the model drop down menu
        this.getModels(this.props.loggedStatus.accessToken, typeId).then(response => {
            this.setState({
                model_names: response.results
            });

            if (response.results && response.results.length > 0) {
                this.setState({
                    selected_model_id: response.results[0].id
                });
            }

            else {
                this.setState({
                    selected_model_id: 0        // if nothing was returned, we go back to default 0 value
                });
            }
        });
    }

    handleSelectedModelChange(modelId) {
        this.setState({
            selected_model_id: modelId
        });
    }

    handleSelectedSiteChange(siteId) {
        this.setState({
            selected_site_id: siteId
        });

        // now get a new list of locations based on site selected
        // get locations in building based on that site id
        this.getLocations(this.props.loggedStatus.accessToken, siteId).then(response => {
            this.setState({
                locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }, ...response.results],
                selected_location_in_building_id: 0 // we have hardcoded "None" option that drop down starts at
            });


        })
            .catch(e => {
                console.log(e);
            });
    }

    handleSelectedLocationChange(locationId) {
        this.setState({
            selected_location_in_building_id: locationId
        });
    }

    handleSelectedAssignedPersonChange(personId) {
        this.setState({
            selected_person_id: personId
        });
    }

    handleSelectedVendorChange(vendorId) {
        this.setState({
            selected_vendor_id: vendorId
        });
    }

    handleAssetTagEnteredChange(e) {
        this.setState({
            asset_tag_entered: e.target.value
        });
    }

    handleSerialNumberEnteredChange(e) {
        this.setState({
            serial_number_entered: e.target.value
        });
    }

    handleBarcodeEnteredChange(e) {
        this.setState({
            barcode_entered: e.target.value
        });
    }

    handleIPAddressEnteredChange(e) {
        this.setState({
            ip_address_entered: e.target.value
        });
    }

    handleMACAddressEnteredChange(e) {
        this.setState({
            mac_address_entered: e.target.value
        });
    }

    handleRAMEnteredChange(e) {
        this.setState({
            ram_entered: e.target.value
        });
    }

    handleCPUEnteredChange(e) {
        this.setState({
            cpu_entered: e.target.value
        });
    }

    // convert the date to MySQL format before updating the state
    handleAcquisitionDateChange(e) {
        this.setState({
            aquisition_date_entered: this.convertDateToSQLFormat(e)//e.target.value
        });
    }

    handleWebsiteEnteredChange(e) {
        this.setState({
            website_entered: e.target.value
        });
    }

    handleWarrantyStartDateChange(e) {
        this.setState({
            warranty_start_date_entered: this.convertDateToSQLFormat(e)
        });
    }

    handleWarrantyEndDateChange(e) {
        this.setState({
            warranty_end_date_entered: this.convertDateToSQLFormat(e)
        });
    }

    handleLeaseStartDateChange(e) {
        this.setState({
            lease_start_date_entered: this.convertDateToSQLFormat(e)
        });
    }

    handleLeaseEndDateChange(e) {
        this.setState({
            lease_end_date_entered: this.convertDateToSQLFormat(e)
        });
    }

    handleTrackAssetChange(e) {
        if (e.target.checked === true) {
            this.setState({
                track_asset: true
            });
        }

        else {
            this.setState({
                track_asset: false
            });
        }
    }

    handleIsPeripheralChange(e) {
        if (e.target.checked === true) {
            this.setState({
                is_peripheral: true
            });
        }

        else {
            this.setState({
                is_peripheral: false
            });
        }
    }

    handleAssignedAssetCategoryChange(categoryId) {
        this.setState({
            selected_assigned_asset_category_id: categoryId
        });

        console.log("Received a category id of: ", categoryId);

        // get new values for the assignable assets drop down menu
        this.getAssignableAssets(this.props.loggedStatus.accessToken, this.state.selected_tenant_id, this.state.selected_assigned_asset_type_id, categoryId).then(response => {

            this.setState({
                assigned_asset_names: response.results
            });

            if (response.results && response.results.length > 0) {
                this.setState({
                    selected_assigned_asset_name_id: response.results[0].id
                });
            }

            else {
                this.setState({
                    selected_assigned_asset_name_id: 0
                });
            }

        })
            .catch(e => {
                console.log(e);
            });
    }

    handleAssignedAssetTypeChange(typeId) {
        this.setState({
            selected_assigned_asset_type_id: typeId
        });

        // get new values for the assignable assets drop down menu
        this.getAssignableAssets(this.props.loggedStatus.accessToken, this.state.selected_tenant_id, typeId, this.state.selected_assigned_asset_category_id).then(response => {

            this.setState({
                assigned_asset_names: response.results
            });

            if (response.results && response.results.length > 0) {
                this.setState({
                    selected_assigned_asset_name_id: response.results[0].id
                });
            }

            else {
                this.setState({
                    selected_assigned_asset_name_id: 0
                });
            }

        })
            .catch(e => {
                console.log(e);
            });

    }

    handleAssignedAssetNameChange(assetId) {
        this.setState({
            selected_assigned_asset_name_id: assetId
        });
    }

    handleCreateCategoryNameChange(e) {
        this.setState({
            createCategory_category_name_entered: e.target.value
        });
    }

    handleCreateTypeNameChange(e) {
        this.setState({
            type_name_entered: e.target.value
        });
    }

    handleCreateModelNameChange(e) {
        this.setState({
            model_name_entered: e.target.value
        });
    }

    handleCreateModelTypeChange(typeId) {
        this.setState({
            model_type_id_selected: typeId
        });
    }

    handleCreateModelCostChange(e) {

        console.log(typeof(e));

        // if(e !== "") {
        //     console.log(e.toFixed(2));
        //     this.setState({
        //         cost_entered: e.toFixed(2)
        //     });
        // }

        // else {
        //     this.setState({
        //         cost_entered: ""
        //     });
        // }

        // they have cleared the field
        if (e/* .target.value */ === "") {
            this.setState({
                cost_entered: ""
            });
        }

        // they input an integer
        else if (Number.isInteger(Number(e/* .target.value */))) {
            this.setState({
                cost_entered: parseInt(e/* .target.value */)
            });
        }

        // they have input a float
        else if (!isNaN(e/* .target.value */) && e/* .target.value */.toString().indexOf('.') !== -1) {

            console.log("Float condition caught");

            this.setState({
                //cost_entered: parseFloat(e/* .target.value */).toFixed(2)
                cost_entered: e
                
            });
        }

        else {
            console.log("Bad input");
        }

    }

    handleCreateModelManufacturerChange(manufacturerId) {
        this.setState({
            manufacturer_id_selected: manufacturerId
        });
    }

    handleCreateModelManufacturerChangeCreateModelModalForm(manufacturerId) {
        this.setState({
            manufacturer_id_selected_create_model_form: manufacturerId
        });
    }

    handleCreateManufacturerNameChange(e) {
        this.setState({
            manufacturer_name_entered: e.target.value
        });
    }

    handleCreateManufacturerTypeChange(e) {
        this.setState({
            manufacturer_type_entered: e.target.value
        });
    }

    handleCreateManufacturerPhoneChange(e) {
        this.setState({
            manufacturer_phone_number_entered: e.target.value
        });
    }

    handleCreateSiteNameChange(e) {
        this.setState({
            site_name_entered: e.target.value
        });
    }

    handleCreateSiteStreetAddressChange(e) {
        this.setState({
            site_street_address_entered: e.target.value
        });
    }

    handleCreateSiteCityChange(e) {
        this.setState({
            site_city_entered: e.target.value
        });
    }

    handleCreateSiteStateChange(e) {
        this.setState({
            site_state_entered: e.target.value
        });
    }

    handleCreateSiteZipCodeChange(e) {
        this.setState({
            site_zip_code_entered: e.target.value
        });
    }

    handleCreateLocationFloorChange(e) {
        this.setState({
            location_floor_entered: e.target.value
        });
    }

    handleCreateLocationAisleChange(e) {
        this.setState({
            location_aisle_entered: e.target.value
        });
    }

    handleCreateLocationDeskChange(e) {
        this.setState({
            location_desk_entered: e.target.value
        });
    }

    handleCreateVendorNameChange(e) {
        this.setState({
            vendor_name_entered: e.target.value
        });
    }

    handleContractNumberEnteredChange(e) {
        this.setState({
            contract_number_entered: e.target.value
        });
    }

    submitCreateVendorForm() {
        console.log("Values submitting to backend")
        console.log("Name: ", this.state.vendor_name_entered);

        if (this.state.vendor_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateVendor: false,
                canSeeErrorMessageCreateVendor: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateVendor: false
            });

            // check for duplicate vendor
            HardwareAssetDataService.checkForDuplicateVendor(this.props.loggedStatus.accessToken, this.state.vendor_name_entered, this.props.loggedStatus.primary_tenant_id).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateVendor: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateVendor: false
                    });

                    // send data to backend for processing
                    let data = {
                        vendorName: this.state.vendor_name_entered,
                        tenantId: this.props.loggedStatus.primary_tenant_id     // we're now saving vendors by tenant for better multitenancy
                    };

                    HardwareAssetDataService.createVendor(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getVendors(this.props.loggedStatus.accessToken, this.props.loggedStatus.primary_tenant_id).then(response => {
                            this.setState({
                                vendors: [{ id: 0, name_of_vendor: "None" }, ...response.results],
                                selected_vendor_id: 0
                            });

                            this.closeVendorModal();
                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        })

                }
            })
                .catch(e => {
                    console.log(e);
                });

        }
    }

    submitCreateLocationForm() {
        console.log("Values submitting to backend");
        console.log("Site Id: ", this.state.selected_site_id);
        console.log("Floor: ", this.state.location_floor_entered);
        console.log("Aisle: ", this.state.location_aisle_entered);
        console.log("Desk: ", this.state.location_desk_entered);

        if (this.state.selected_site_id === 0 || this.state.location_floor_entered === "" || this.state.location_aisle_entered === "" || this.state.location_desk_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateLocation: false,
                canSeeErrorMessageCreateLocation: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateLocation: false
            });

            // check for duplicate entry
            HardwareAssetDataService.checkForDuplicateLocation(this.props.loggedStatus.accessToken, this.state.location_floor_entered, this.state.location_aisle_entered, this.state.location_desk_entered, this.state.selected_site_id).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateLocation: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateLocation: false
                    });

                    // send data to backend for processing
                    let data = {
                        siteId: this.state.selected_site_id,
                        floorNumber: this.state.location_floor_entered,
                        aisleNumber: this.state.location_aisle_entered,
                        deskNumber: this.state.location_desk_entered
                    };

                    HardwareAssetDataService.createLocation(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getLocations(this.props.loggedStatus.accessToken, this.state.selected_site_id).then(response => {
                            this.setState({
                                locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }, ...response.results],
                                selected_location_in_building_id: 0
                            });

                            this.closeLocationModal();
                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    submitCreateSiteForm() {
        console.log("Values submitting to backend");
        console.log("Tenant Id: ", this.state.selected_tenant_id);
        console.log("Site Name: ", this.state.site_name_entered);
        console.log("Street Address: ", this.state.site_street_address_entered);
        console.log("City: ", this.state.site_city_entered);
        console.log("State: ", this.state.site_state_entered);
        console.log("Zip Code: ", this.state.site_zip_code_entered);

        if (this.state.selected_tenant_id === 0 || this.state.site_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateSite: false,
                canSeeErrorMessageCreateSite: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateSite: false
            });

            // check for duplicate name
            HardwareAssetDataService.checkForDuplicateSiteName(this.props.loggedStatus.accessToken, this.state.site_name_entered).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateSite: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateSite: false
                    });

                    // send form data to backend

                    let data = {
                        tenantId: this.state.selected_tenant_id,
                        siteName: this.state.site_name_entered,
                        streetAddress: this.state.site_street_address_entered,
                        city: this.state.site_city_entered,
                        state: this.state.site_state_entered,
                        zipCode: this.state.site_zip_code_entered
                    };

                    HardwareAssetDataService.createSite(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getSites(this.props.loggedStatus.accessToken, this.state.selected_tenant_id).then(response => {
                            this.setState({
                                sites: [{id: 0, name_of_site: "None"}, ...response.results]
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_site_id: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    selected_site_id: 0
                                });
                            }

                            this.closeSiteModal();

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
                .catch(e => {
                    console.log(e);
                });

        }

    }

    submitCreateManufacturerForm() {
        console.log("Values submitting to backend");
        console.log("Manufacturer name: ", this.state.manufacturer_name_entered);
        console.log("Manufacturer type: ", this.state.manufacturer_type_entered);
        console.log("Phone number: ", this.state.manufacturer_phone_number_entered);

        if (this.state.manufacturer_name_entered === "" || this.state.manufacturer_type_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateManufacturer: false,
                canSeeErrorMessageCreateManufacturer: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateManufacturer: false
            });

            // check for duplicate name / type combo
            HardwareAssetDataService.checkForDuplicateManufacturer(this.props.loggedStatus.accessToken, this.state.manufacturer_name_entered, this.state.manufacturer_type_entered).then(response => {

                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateManufacturer: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateManufacturer: false
                    });

                    // send form data to backend

                    let data = {
                        manufacturerName: this.state.manufacturer_name_entered,
                        manufacturerType: this.state.manufacturer_type_entered,
                        phoneNumber: this.state.manufacturer_phone_number_entered
                    };

                    HardwareAssetDataService.createManufacturer(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getManufacturers(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                manufacturers: response.results,
                                manufacturer_name_entered: "",      // reset form fields
                                manufacturer_type_entered: "",
                                manufacturer_phone_number_entered: ""
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    manufacturer_id_selected: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    manufacturer_id_selected: 0
                                });
                            }

                            this.closeManufacturerModal();
                        })
                            .catch(e => {
                                console.log(e);
                            })
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }


            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    submitCreateModelForm() {
        console.log("Values submitting to backend");
        console.log("Model Name: ", this.state.model_name_entered);
        console.log("Type id: ", this.state.selected_type_id);
        console.log("Cost: ", this.state.cost_entered);
        console.log("Manufacturer id: ", this.state.manufacturer_id_selected_create_model_form);

        if (this.state.model_name_entered === "" || this.state.selected_type_id === 0 || this.state.manufacturer_id_selected_create_model_form === 0) {
            this.setState({
                canSeeDuplicateNameErrorCreateModel: false,
                canSeeErrorMessageCreateModel: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateModel: false
            });


            // Check for duplicate model name
            HardwareAssetDataService.checkForDuplicateModelName(this.props.loggedStatus.accessToken, this.state.model_name_entered).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateModel: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateModel: false
                    });

                    // submit form data to backend for add

                    let data = {
                        modelName: this.state.model_name_entered,
                        typeId: this.state.selected_type_id,
                        cost: this.state.cost_entered,
                        manufacturerId: this.state.manufacturer_id_selected_create_model_form
                    };

                    HardwareAssetDataService.createModel(this.props.loggedStatus.accessToken, data).then(response => {
                        // update the model list
                        this.getModels(this.props.loggedStatus.accessToken, this.state.selected_type_id).then(response => {
                            this.setState({
                                model_names: response.results,
                                model_name_entered: "",         // reset form fields
                                cost_entered: "",
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_model_id: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    selected_model_id: 0
                                });
                            }

                            // reset the type id selected field of model form
                            if (this.state.asset_types && this.state.asset_types.length > 0) {
                                this.setState({
                                    model_type_id_selected: this.state.asset_types[0].id
                                });
                            }

                            else {
                                this.setState({
                                    model_type_id_selected: 0
                                });
                            }

                            // reset the manufacturer id selected field of model form
                            if (this.state.manufacturers && this.state.manufacturers.length > 0) {
                                this.setState({
                                    manufacturer_id_selected_create_model_form: this.state.manufacturers[0].id
                                });
                            }

                            else {
                                this.setState({
                                    manufacturer_id_selected_create_model_form: 0
                                });
                            }

                            this.closeModelModal();
                        })
                            .catch(e => {
                                console.log(e);
                            })
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
                .catch(e => {
                    console.log(e);
                });
        }

    }

    submitCreateTypeForm() {
        console.log("Values submitting to backend");
        console.log("Type Name: ", this.state.type_name_entered);
        console.log("Is Core: ", this.state.selected_is_core_value);

        // First, ensure that all required form fields are filled out
        if (this.state.type_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateType: false,
                canSeeErrorMessageCreateType: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateType: false
            });

            // Check for duplicate type name
            HardwareAssetDataService.checkForDuplicateTypeName(this.props.loggedStatus.accessToken, this.state.type_name_entered).then(response => {
                // If duplicate, show an error message
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateType: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateType: false
                    });

                    // submit form to backend for add

                    let data = {
                        typeName: this.state.type_name_entered,
                        isCore: this.state.selected_is_core_value
                    };

                    HardwareAssetDataService.createType(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getHardwareAssetTypes(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                asset_types: response.results,
                                type_name_entered: "",              // reset form fields
                                selected_is_core_value: true
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_type_id: response.results[0].id,
                                    selected_assigned_asset_type_id: response.results[0].id,
                                    model_type_id_selected: response.results[0].id
                                });

                                // Get the models for that type
                                this.getModels(this.props.loggedStatus.accessToken, response.results[0].id).then(response => {
                                    if (response.results && response.results.length > 0) {
                                        this.setState({
                                            model_names: response.results,
                                            selected_model_id: response.results[0].id
                                        });
                                    }

                                    else {
                                        this.setState({
                                            model_names: response.results,
                                            selected_model_id: 0
                                        });
                                    }
                                })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }

                            else {
                                this.setState({
                                    asset_types: response.results,
                                    selected_type_id: 0,
                                    selected_assigned_asset_type_id: 0,
                                    model_type_id_selected: 0,
                                    model_names: []
                                });
                            }

                            // if(response.results && response.results.length > 0) {
                            //     this.setState({
                            //         selected_type_id: response.results[0].id,
                            //         selected_assigned_asset_type_id: response.results[0].id
                            //     }, () => {
                            //         this.closeTypeModal();
                            //     });
                            // }

                            //else {
                            this.closeTypeModal();
                            //}

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }
            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    submitCreateCategoryForm() {
        console.log("Values submitting to backend");
        console.log("Category Name: ", this.state.createCategory_category_name_entered);

        // First, ensure that all required form fields are filled out
        if (this.state.createCategory_category_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateCategory: false,
                canSeeErrorMessageCreateCategory: true
            });
        }

        else {

            this.setState({
                canSeeErrorMessageCreateCategory: false
            });

            // Check for duplicate category name
            HardwareAssetDataService.checkForDuplicateCategoryName(this.props.loggedStatus.accessToken, this.state.createCategory_category_name_entered).then(response => {

                // If duplicate, show an error message
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateCategory: true
                    });
                }

                // If not duplicate, submit entire form for asset add
                else if (response.data.results === "NO DUPLICATE FOUND") {

                    this.setState({
                        canSeeDuplicateNameErrorCreateCategory: false
                    });

                    // submit form to backend for add

                    let data = {
                        categoryName: this.state.createCategory_category_name_entered
                    };

                    HardwareAssetDataService.createCategory(this.props.loggedStatus.accessToken, data).then(response => {
                        // fetch the category names again for drop down so they update
                        this.getCategories(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                asset_categories: response.results,
                                createCategory_category_name_entered: ""    // reset form fields
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_assigned_asset_category_id: response.results[0].id,
                                    selected_category_id: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    selected_assigned_asset_category_id: 0,
                                    selected_category_id: 0
                                });
                            }

                            // if(response.results && response.results.length > 0) {
                            //     this.setState({
                            //         selected_assigned_asset_category_id: response.results[0].id,
                            //         selected_category_id: response.results[0].id
                            //     }, () => {
                            //         // close the category modal
                            //         this.closeCategoryModal();
                            //     });
                            // }

                            //else {
                            this.closeCategoryModal();
                            //}

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }

            })
                .catch(e => {
                    console.log(e);
                });

        }

    }



    submitAddAssetForm() {
        console.log("Values submitting to backend");
        console.log("Tenant id: ", this.state.selected_tenant_id);
        console.log("Asset Name Entered: ", this.state.asset_name_entered);
        console.log("Category Id: ", this.state.selected_category_id);
        console.log("Type id: ", this.state.selected_type_id);
        console.log("Model id: ", this.state.selected_model_id);
        console.log("Site id: ", this.state.selected_site_id);
        console.log("Location at site id: ", this.state.selected_location_in_building_id);
        console.log("Person id: ", this.state.selected_person_id);
        console.log("Vendor id: ", this.state.selected_vendor_id);
        console.log("Asset tag: ", this.state.asset_tag_entered);
        console.log("Serial Number: ", this.state.serial_number_entered);
        console.log("Barcode: ", this.state.barcode_entered);
        console.log("IP Address: ", this.state.ip_address_entered);
        console.log("MAC Address: ", this.state.mac_address_entered);
        console.log("RAM: ", this.state.ram_entered);
        console.log("CPU: ", this.state.cpu_entered);
        console.log("Aquisition Date: ", this.state.aquisition_date_entered);
        console.log("Website: ", this.state.website_entered);
        console.log("Warranty Start: ", this.state.warranty_start_date_entered);
        console.log("Warranty end: ", this.state.warranty_end_date_entered);
        console.log("Lease Start: ", this.state.lease_start_date_entered);
        console.log("Lease End: ", this.state.lease_end_date_entered);
        console.log("Track Asset: ", this.state.track_asset);
        console.log("Is Peripheral: ", this.state.is_peripheral);
        console.log("Contract Number: ", this.state.contract_number_entered);


        console.log("Selected assigned asset category id: ", this.state.selected_assigned_asset_category_id);
        console.log("Selected assigned asset type id: ", this.state.selected_assigned_asset_type_id);
        console.log("Selected assigned asset name id: ", this.state.selected_assigned_asset_name_id);


        // First, ensure that all required form fields are filled out
        if (this.state.selected_tenant_id === 0 || this.state.asset_name_entered === "" || this.state.selected_category_id === 0 || this.state.selected_type_id === 0 || this.state.selected_model_id === 0 /* || this.state.selected_site_id === 0  */|| (this.state.is_peripheral && this.state.selected_assigned_asset_name_id === 0)) {
            this.setState({
                canSeeDuplicateNameError: false,
                canSeeErrorMessage: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessage: false
            });


            // Then, send entered asset name through to backend to check for duplicate
            HardwareAssetDataService.checkForDuplicateAssetName(this.props.loggedStatus.accessToken, this.state.asset_name_entered).then(response => {

                // If duplicate, show an error message
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameError: true
                    });
                }

                // If not duplicate, submit entire form for asset add
                else if (response.data.results === "NO DUPLICATE FOUND") {
                    console.log("this name is good");
                    this.setState({
                        canSeeDuplicateNameError: false
                    });

                    // submit form to backend for add
                    let data = {
                        tenantId: this.state.selected_tenant_id,
                        assetName: this.state.asset_name_entered,
                        categoryId: this.state.selected_category_id,
                        typeId: this.state.selected_type_id,
                        modelId: this.state.selected_model_id,
                        siteId: this.state.selected_site_id,
                        locationId: this.state.selected_location_in_building_id,
                        personId: this.state.selected_person_id,
                        vendorId: this.state.selected_vendor_id,
                        assetTag: this.state.asset_tag_entered,
                        serialNumber: this.state.serial_number_entered,
                        barcode: this.state.barcode_entered,
                        ipAddress: this.state.ip_address_entered,
                        macAddress: this.state.mac_address_entered,
                        ram: this.state.ram_entered,
                        cpu: this.state.cpu_entered,
                        aquisitionDate: this.state.aquisition_date_entered,
                        website: this.state.website_entered,
                        warrantyStartDate: this.state.warranty_start_date_entered,
                        warrantyEndDate: this.state.warranty_end_date_entered,
                        leaseStartDate: this.state.lease_start_date_entered,
                        leaseEndDate: this.state.lease_end_date_entered,
                        trackAsset: this.state.track_asset,
                        isPeripheral: this.state.is_peripheral,
                        assignedAssetId: this.state.selected_assigned_asset_name_id,
                        creatorId: this.props.loggedStatus.id,
                        contractNumber: this.state.contract_number_entered
                    };

                    /* TURN THIS BACK ON IN FINAL CODE !!!!!! */
                    HardwareAssetDataService.createNewHardwareAsset(this.props.loggedStatus.accessToken, data).then(response => {
                        console.log("Returned from create hardware asset endpoint");
                        this.props.history.push('/hardwareAssets');
                    })
                    .catch(e => {
                        console.log(e);
                    });


                }

            })
                .catch(e => {
                    console.log(e);
                });


        }
    }


    getHardwareAssetTypes(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTypes(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of users this tech is authorized to see for the filter drop down menu
    getUserList(token, asker_id, tenant_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getUsersAddAssetVersion(token, asker_id, tenant_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of tenant names this tech is authorized to see for the filter drop down menu
    getTenantList(token, asker_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTenants(token, asker_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of categories for the add hardware asset drop down menu
    getCategories(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getCategories(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of vendors for the add hardware asset drop down menu
    getVendors(token, tenantId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getVendors(token, tenantId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of manufacturers for the add hardware asset drop down menu
    getManufacturers(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getManufacturers(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of models based on type user has selected for add hardware asset drop down menu
    getModels(token, typeId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getModels(token, typeId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of sites based on company user has selected for company drop down menu
    getSites(token, tenantId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getSites(token, tenantId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of locations in building based on site selected
    getLocations(token, siteId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getLocations(token, siteId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of assignable assets based on selected type and company
    getAssignableAssets(token, tenantId, typeId, categoryId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getAssignableAssets(token, tenantId, typeId, categoryId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }


    // convert a string from Mantine date to MySQL format for saving
    convertDateToSQLFormat(dateString) {
        if(dateString === "" || dateString === null) {
            return "";
        }

        else {
            let dateArray = dateString.split(" ");
            console.log(dateArray);
            //['Fri', 'Jan', '05', '2024', '00:00:00', 'GMT+0000', '(Coordinated', 'Universal', 'Time)']

            let month;
            let day = dateArray[2];
            let year = dateArray[3];
            // MySQL expects date in format YYYY-MM-DD
            switch(dateArray[1]) {
                case "Jan":
                    month = "01";
                    break;

                case "Feb":
                    month = "02";
                    break;

                case "Mar":
                    month = "03";
                    break;

                case "Apr":
                    month = "04";
                    break;

                case "May":
                    month = "05";
                    break;

                case "Jun":
                    month = "06";
                    break;

                case "Jul":
                    month = "07";
                    break;

                case "Aug":
                    month = "08";
                    break;

                case "Sep":
                    month = "09";
                    break;

                case "Oct":
                    month = "10";
                    break;

                case "Nov":
                    month = "11";
                    break;

                default:
                    month = "12";
            }

            console.log("Month value = ", month);

            // construct the date string in the proper format
            console.log(year + "-" + month + "-" + day);

            let formattedString = year + "-" + month + "-" + day;

            return formattedString;
        }
    }


    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }



        return (
            <>
            
                <Navbar pageTitle={"Add Hardware Asset"} />
                <div style={{ paddingTop: "15px" }} />
                <Box pos="relative">
                    <LoadingOverlay visible={this.state.show_loading_overlay} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                    <Container fluid>
                        <Paper withBorder p="md" radius="md" display="block" m="5px">
                            <Grid>
                                <Grid.Col span={6}>
                                    <Table withRowBorders={false} style={{cursor: "default"}}>
                                        <Table.Tr>
                                            <Table.Td style={{width: "20%"}}>Company</Table.Td>
                                            <Table.Td c="blue">
                                                {
                                                    this.state.tenants && this.state.tenants.find((e) => e.id === this.state.selected_tenant_id) ? 
                                                        this.state.tenants.find((e) => e.id === this.state.selected_tenant_id).name_of_tenant
                                                    :
                                                        ""
                                                }
                                            </Table.Td>
                                        </Table.Tr>
                                        <Table.Tr>
                                            <Table.Td style={{width: "20%"}}>Manufacturer</Table.Td>
                                            <Table.Td c="blue">
                                                {
                                                    this.state.manufacturers && this.state.manufacturers.find((e) => e.id === this.state.manufacturer_id_selected) ? 
                                                        this.state.manufacturers.find((e) => e.id === this.state.manufacturer_id_selected).name_of_manufacturer
                                                    :
                                                        ""
                                                }
                                            </Table.Td>
                                        </Table.Tr>
                                    </Table>
                                </Grid.Col>
                                <Grid.Col span={6} align="right">
                                    <div><Button onClick={() => this.openWelcomeModal()}><IconEdit/></Button></div>
                                </Grid.Col>
                            </Grid>
                        </Paper>
                        <div style={{marginTop: "25px"}}></div>
                        <Paper withBorder p="md" radius="md" display="block" m="5px" style={{height: "425px"}}>
                            <Tabs radius="md" defaultValue="Basic Info">
                                <Tabs.List>
                                    <Tabs.Tab value="Basic Info" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Basic Info</Tabs.Tab>
                                    <Tabs.Tab value="Assignments" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Assignments</Tabs.Tab>
                                    <Tabs.Tab value="Identifying Details" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Identifying Details</Tabs.Tab>
                                    <Tabs.Tab value="Purchase Info" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Purchase Info</Tabs.Tab>
                                    {/* <Tabs.Tab value="Internal Specs">Internal Specs</Tabs.Tab> */}
                                    {this.state.selected_type_name === "Services" ?
                                        <Tabs.Tab value="Services" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Services</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Cloud" || 
                                    this.state.selected_type_name === "Virtual Machine" ||
                                    this.state.selected_type_name === "Volume" ||
                                    this.state.selected_type_name === "Host" || 
                                    this.state.selected_type_name === "Datacenter" || 
                                    this.state.selected_type_name === "Datastore" || 
                                    this.state.selected_type_name === "Image" || 
                                    this.state.selected_type_name === "Load Balancer" || 
                                    this.state.selected_type_name === "Security Group" || 
                                    this.state.selected_type_name === "Network" || 
                                    this.state.selected_type_name === "Resource Pool" || 
                                    this.state.selected_type_name === "Network Interface" || 
                                    this.state.selected_type_name === "Public IP Address" || 
                                    this.state.selected_type_name === "VPC" || 
                                    this.state.selected_type_name === "Subnet" || 
                                    this.state.selected_type_name === "Database" || 
                                    this.state.selected_type_name === "Key Pair" || 
                                    this.state.selected_type_name === "Object Storage" || 
                                    this.state.selected_type_name === "Key Vault" || 
                                    this.state.selected_type_name === "Subscription" || 
                                    this.state.selected_type_name === "Resource Group" || 
                                    this.state.selected_type_name === "Application Gateway" ?
                                        <Tabs.Tab value="Cloud" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Cloud</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Virtual Machine" ?
                                        <Tabs.Tab value="Virtual Machine" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Virtual Machine</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Volume" ? 
                                        <Tabs.Tab value="Volume" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Volume</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Host" ? 
                                        <Tabs.Tab value="Host" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Host</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Datacenter" ? 
                                        <Tabs.Tab value="Datacenter" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Datacenter</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Datastore" ? 
                                        <Tabs.Tab value="Datastore" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Datastore</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Image" ? 
                                        <Tabs.Tab value="Image" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Image</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Load Balancer" ? 
                                        <Tabs.Tab value="Load Balancer" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Load Balancer</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Security Group" ? 
                                        <Tabs.Tab value="Security Group" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Security Group</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Network" ? 
                                        <Tabs.Tab value="Network" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Network</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Resource Pool" ? 
                                        <Tabs.Tab value="Resource Pool" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Resource Pool</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Network Interface" ? 
                                        <Tabs.Tab value="Network Interface" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Network Interface</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Public IP Address" ? 
                                        <Tabs.Tab value="Public IP Address" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Public IP Address</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "VPC" ? 
                                        <Tabs.Tab value="VPC" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>VPC</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Subnet" ? 
                                        <Tabs.Tab value="Subnet" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Subnet</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Database" ? 
                                        <Tabs.Tab value="Database" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Database</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Key Pair" ? 
                                        <Tabs.Tab value="Key Pair" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Key Pair</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Object Storage" ? 
                                        <Tabs.Tab value="Object Storage" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Object Storage</Tabs.Tab> :undefined
                                    }
                                    {this.state.selected_type_name === "Key Vault" ? 
                                        <Tabs.Tab value="Key Vault" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Key Vault</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Subscription" ? 
                                        <Tabs.Tab value="Subscription" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Subscription</Tabs.Tab> :undefined
                                    }
                                    {this.state.selected_type_name === "Resource Group" ? 
                                        <Tabs.Tab value="Resource Group" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Resource Group</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Application Gateway" ? 
                                        <Tabs.Tab value="Application Gateway" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}> Application Gateway</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Hardware" || 
                                    this.state.selected_type_name === "Computer" || 
                                    this.state.selected_type_name === "Desktop" || 
                                    this.state.selected_type_name === "Laptop" || 
                                    this.state.selected_type_name === "Server" || 
                                    this.state.selected_type_name === "Storage" || 
                                    this.state.selected_type_name === "Disk" || 
                                    this.state.selected_type_name === "Datacenter" || 
                                    this.state.selected_type_name === "Rack" || 
                                    this.state.selected_type_name === "UPS" || 
                                    this.state.selected_type_name === "Mobile Devices" || 
                                    this.state.selected_type_name === "Monitor" || 
                                    this.state.selected_type_name === "Printer" || 
                                    this.state.selected_type_name === "Projector" || 
                                    this.state.selected_type_name === "Scanner" || 
                                    this.state.selected_type_name === "Router" || 
                                    this.state.selected_type_name === "Switch" || 
                                    this.state.selected_type_name === "Access Point" || 
                                    this.state.selected_type_name === "Firewall" || 
                                    this.state.selected_type_name === "Other Devices" || 
                                    this.state.selected_type_name === "SNMP" ? 
                                        <Tabs.Tab value="Hardware" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Hardware</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Hardware" || 
                                    this.state.selected_type_name === "Computer" || 
                                    this.state.selected_type_name === "Desktop" || 
                                    this.state.selected_type_name === "Laptop" || 
                                    this.state.selected_type_name === "Server" || 
                                    this.state.selected_type_name === "Storage" || 
                                    this.state.selected_type_name === "Disk" || 
                                    this.state.selected_type_name === "Datacenter" || 
                                    this.state.selected_type_name === "Rack" || 
                                    this.state.selected_type_name === "UPS" || 
                                    this.state.selected_type_name === "Mobile Devices" || 
                                    this.state.selected_type_name === "Monitor" || 
                                    this.state.selected_type_name === "Printer" || 
                                    this.state.selected_type_name === "Projector" || 
                                    this.state.selected_type_name === "Scanner" || 
                                    this.state.selected_type_name === "Router" || 
                                    this.state.selected_type_name === "Switch" || 
                                    this.state.selected_type_name === "Access Point" || 
                                    this.state.selected_type_name === "Firewall" || 
                                    this.state.selected_type_name === "Other Devices" || 
                                    this.state.selected_type_name === "SNMP" ? 
                                        <Tabs.Tab value="Cost" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Cost</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Computer" || 
                                    this.state.selected_type_name === "Desktop" || 
                                    this.state.selected_type_name === "Laptop" || 
                                    this.state.selected_type_name === "Server" ? 
                                        <Tabs.Tab value="Computer" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Computer</Tabs.Tab> : undefined
                                    }
                                    {/* <Tabs.Tab value="Desktop">Desktop</Tabs.Tab>
                                    <Tabs.Tab value="Laptop">Laptop</Tabs.Tab>
                                    <Tabs.Tab value="Server">Server</Tabs.Tab>
                                    <Tabs.Tab value="Storage">Storage</Tabs.Tab> */}
                                    {this.state.selected_type_name === "Disk" ? 
                                        <Tabs.Tab value="Disk" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Disk</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Rack" ? 
                                        <Tabs.Tab value="Rack" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Rack</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "UPS" ? 
                                        <Tabs.Tab value="UPS" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>UPS</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Mobile Devices" ? 
                                        <Tabs.Tab value="Mobile Devices" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Mobile Devices</Tabs.Tab> : undefined
                                    }
                                    {/* <Tabs.Tab value="Monitor">Monitor</Tabs.Tab> */}
                                    {this.state.selected_type_name === "Printer" ? 
                                        <Tabs.Tab value="Printer" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Printer</Tabs.Tab> : undefined
                                    }
                                    {/* <Tabs.Tab value="Projector">Projector</Tabs.Tab>
                                    <Tabs.Tab value="Scanner">Scanner</Tabs.Tab> */}
                                    {this.state.selected_type_name === "Router" ? 
                                        <Tabs.Tab value="Router" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Router</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Switch" ? 
                                        <Tabs.Tab value="Switch" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Switch</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Access Point" ? 
                                        <Tabs.Tab value="Access Point" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Access Point</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Firewall" ? 
                                        <Tabs.Tab value="Firewall" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Firewall</Tabs.Tab> : undefined
                                    }
                                    {/* <Tabs.Tab value="Other Devices">Other Devices</Tabs.Tab> */}
                                    {this.state.selected_type_name === "SNMP" ? 
                                        <Tabs.Tab value="SNMP" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>SNMP</Tabs.Tab> : undefined
                                    }
                                    {this.state.selected_type_name === "Consumable" ? 
                                        <Tabs.Tab value="Consumable" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Consumable</Tabs.Tab> : undefined
                                    }
                                    {/* {this.state.selected_type_name === "Network" ? 
                                        <Tabs.Tab value="Network">Network</Tabs.Tab> : undefined
                                    } */}
                                    {this.state.selected_type_name === "Document" ? 
                                        <Tabs.Tab value="Document" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Document</Tabs.Tab> : undefined
                                    }
                                    {/* <Tabs.Tab value="Others">Others</Tabs.Tab> */}
                                    <Tabs.Tab value="Warranty Lease and Maintenance" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Warranty Lease and Maintenance</Tabs.Tab>
                                    <Tabs.Tab value="Misc" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Misc</Tabs.Tab>
                                </Tabs.List>
                                <Tabs.Panel value="Basic Info">
                                    {this.state.selected_form_tab === "Basic Info" ?
                                        <div style={{marginTop: "30px"}}>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAssetName">
                                                    <span style={{ display: "inline-block", width: "95%" }}><TextInput label="What is this asset going to be called?" placeholder="Enter Name Here" value={this.state.asset_name_entered} onChange={this.handleAssetNameEnteredChange} required /></span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormAssetCategory">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="What category does this asset belong to?"
                                                                placeholder="Pick a Value"
                                                                data={this.state.asset_categories && this.state.asset_categories.map((category) => (
                                                                    category.name_of_category
                                                                ))}
                                                                value={
                                                                        this.state.selected_category_id !== 0 && this.state.asset_categories.length > 0 && this.state.asset_categories && this.state.asset_categories.find((e) => e.id === this.state.selected_category_id) ?
                                                                            this.state.asset_categories.find((e) => e.id === this.state.selected_category_id).name_of_category
                                                                        :
                                                                        ""  
                                                                }
                                                                onChange={(s) => {
                                                                        var catId = this.state.asset_categories.find((e) => e.name_of_category === s).id;
                                                                        if(catId !== null) {
                                                                            this.handleSelectedCategoryChange(catId);
                                                                        }
                                                                    }
                                                                }
                                                                allowDeselect={false}
                                                                searchable
                                                                required
                                                            /></span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new category"><IconPlus color="#00abfb" onClick={() => this.openCategoryModal()}/></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAssetType">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                    label="What type of asset is this?"
                                                                    placeholder="Pick a Value"
                                                                    data={this.state.asset_types && this.state.asset_types.map((type) => (
                                                                        type.name_of_type
                                                                    ))}
                                                                    value={
                                                                        this.state.selected_type_id !== 0 && this.state.asset_types && this.state.asset_types.length > 0 && this.state.asset_types.find((e) => e.id === this.state.selected_type_id) ? 
                                                                            this.state.asset_types.find((e) => e.id === this.state.selected_type_id).name_of_type
                                                                            :
                                                                            ""
                                                                    }
                                                                    onChange={(s) => {
                                                                        var typeId = this.state.asset_types.find((e) => e.name_of_type === s).id;
                                                                        if(typeId !== null) {
                                                                            this.handleSelectedTypeChange(typeId);
                                                                        }
                                                                    }}
                                                                    allowDeselect={false}
                                                                    searchable
                                                                    required
                                                                />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new type"><IconPlus color="#00abfb" onClick={() => this.openTypeModal(false)} /></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormAssetModel">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                    label="What is the model name?"
                                                                    placeholder="Pick a Value"
                                                                    data={this.state.model_names && this.state.model_names.length > 0 && this.state.model_names.map((model) => (
                                                                        model.name_of_model !== null ? model.name_of_model : []
                                                                    ))
                                                                    }
                                                                    value={
                                                                        this.state.selected_model_id !== 0 && this.state.model_names && this.state.model_names.length > 0 && this.state.model_names.find((e) => e.id === this.state.selected_model_id) ? 
                                                                            this.state.model_names.find((e) => e.id === this.state.selected_model_id).name_of_model
                                                                            :
                                                                            ""
                                                                    }
                                                                    onChange={(s) => {
                                                                        var modelId = this.state.model_names.find((e) => e.name_of_model === s).id;
                                                                        if(modelId !== null) {
                                                                            this.handleSelectedModelChange(modelId);
                                                                        }
                                                                    }}
                                                                    allowDeselect={false}
                                                                    searchable
                                                                    required
                                                                />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new model"><IconPlus color="#00abfb" onClick={() => this.openModelModal()}/></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormImpact">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="What is the asset's impact level?"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormEndOfLife" xs={6}>
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <DateInput 
                                                                    label="End of Life Date"
                                                                    placeholder="Not final"
                                                                    /* onChange={(s) => {
                                                                        let dateString;
                                                                        if(s === null) {
                                                                            dateString = "";
                                                                        }

                                                                        else {
                                                                            dateString = s.toString();
                                                                        }
                                                                        this.handleAcquisitionDateChange(dateString);
                                                                    }} */
                                                                    //value={}
                                                                    clearable
                                                                />
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    {/* <Form.Group as={Col} controlId="addFormHealth">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="What is the asset's health?"
                                                                placeholder="Not final"
                                                            />
                                                            </span>
                                                        </div>
                                                    </Form.Group> */}
                                                </Form.Row>
                                            </Form>
                                        </div>
                                        :
                                        undefined
                                    }
                                </Tabs.Panel>
                                <Tabs.Panel value="Assignments">
                                    {this.state.selected_form_tab === "Assignments" ? 
                                        <div style={{marginTop: "30px"}}>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAssignedPerson">
                                                    <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                            label="Who will be assigned to this asset?"
                                                            placeholder="Pick a Value"
                                                            data={this.state.people && this.state.people.map((person) => (
                                                                `${person.full_name} - (${person.email})`
                                                            ))}
                                                            value={
                                                                this.state.selected_person_id !== 0 && this.state.people && this.state.people.length > 0 && this.state.people.find((e) => e.id === this.state.selected_person_id) ? 
                                                                    `${this.state.people.find((e) => e.id === this.state.selected_person_id).full_name} - (${this.state.people.find((e) => e.id === this.state.selected_person_id).email})`
                                                                    :
                                                                    ""
                                                            }
                                                            onChange={(s) => {
                                                                
                                                                var fullNameChosen = s.split(' - (')[0];
                                                                var personId;

                                                                if(fullNameChosen !== "None") {
                                                                    var emailChosenTemp = s.split(' - (')[1];
                                                                    var emailChosen = emailChosenTemp.split(')')[0];
                                                                    // first get the list of records that match the full name provided
                                                                    let matchingList = this.state.people.filter(item => item.full_name === fullNameChosen);

                                                                    //console.log("Full Name Chosen = ", fullNameChosen);
                                                                    //console.log("Email Chosen Temp = ", emailChosenTemp);
                                                                    //console.log("Email Chosen = ", emailChosen);

                                                                    // now find the record in that list that matches the email address provided
                                                                    personId = matchingList.find((e) => e.email === emailChosen).id;
                                                                }

                                                                else {
                                                                    personId = 0;
                                                                }

                                                                if(personId !== null) {
                                                                    //console.log("Match found!", personId);
                                                                    this.handleSelectedAssignedPersonChange(personId)
                                                                }
                                                            }}
                                                            allowDeselect={false}
                                                            searchable
                                                        />
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormSite">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Where will this asset be used?"
                                                                placeholder="Pick a Value"
                                                                data={this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))}
                                                                value={
                                                                    this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""
                                                                }
                                                                onChange={(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new site"><IconPlus color="#00abfb" onClick={() => this.openSiteModal()}/></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormLocationAtSite" /* xs={6} */>
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><TextInput label="Describe where this asset will be located within the building" placeholder="Enter Text Here" />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new location"><IconPlus color="#00abfb" onClick={() => this.openLocationModal()}/></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormDepartmentAssigned">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="What department is this asset assigned to?"
                                                                placeholder="Not final"
                                                            />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new department"><IconPlus color="#00abfb" /* onClick={() => this.openSiteModal()} *//></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group> 
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormManagingGroup">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="What group is managing this asset?"
                                                                placeholder="Not final"
                                                            />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new group"><IconPlus color="#00abfb" /* onClick={() => this.openSiteModal()} *//></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormManagingPerson">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="What person will be managing this asset?"
                                                                placeholder="Not final"
                                                            />
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormDateTimeAssigned" xs={6}>
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <DateTimePicker label="When was this asset assigned" placeholder="Not final" clearable/>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>
                                        </div>
                                        :
                                        undefined
                                    }
                                </Tabs.Panel>
                                <Tabs.Panel value="Identifying Details">
                                    {this.state.selected_form_tab === "Identifying Details" ? 
                                        <div style={{marginTop: "30px"}}>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAssetTag">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Asset Tag" placeholder="Enter Text Here" value={this.state.asset_tag_entered} onChange={this.handleAssetTagEnteredChange} />
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormAssetSerialNumber">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Serial Number" placeholder="Enter Text Here" value={this.state.serial_number_entered} onChange={this.handleSerialNumberEnteredChange} />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAssetBarCode" xs={6}>
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Bar Code" placeholder="Enter Text Here" value={this.state.barcode_entered} onChange={this.handleBarcodeEnteredChange} />
                                                        </span>
                                                    </Form.Group>
                                                    {/* <Form.Group as={Col} controlId="addFormIPAddress">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                        </span>
                                                    </Form.Group> */}
                                                </Form.Row>
                                                {/* <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormMACAddress" xs={6}>
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row> */}
                                            </Form>
                                        </div>
                                        :
                                        undefined
                                    }
                                </Tabs.Panel>
                                <Tabs.Panel value="Purchase Info">
                                    {this.state.selected_form_tab === "Purchase Info" ? 
                                        <div style={{marginTop: "30px"}}>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAssetVendor">
                                                        <div>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                    label="Who did you purchase the asset from?"
                                                                    placeholder="Pick a Value"
                                                                    data={this.state.vendors && this.state.vendors.map((vendor) => (
                                                                        vendor.name_of_vendor
                                                                    ))}
                                                                    value={
                                                                        // this.state.selected_model_id !== 0 && this.state.model_names && this.state.model_names.length > 0 && this.state.model_names.find((e) => e.id === this.state.selected_model_id) ? 
                                                                        //     this.state.model_names.find((e) => e.id === this.state.selected_model_id).name_of_model
                                                                        //     :
                                                                        //     ""
                                                                        this.state.selected_vendor_id !== 0 && this.state.vendors && this.state.vendors.length > 0 && this.state.vendors.find((e) => e.id === this.state.selected_vendor_id) ?
                                                                            this.state.vendors.find((e) => e.id === this.state.selected_vendor_id).name_of_vendor
                                                                            :
                                                                            ""
                                                                    }
                                                                    onChange={(s) => {
                                                                        // var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                        // if(siteId !== null) {
                                                                        //     this.handleSelectedSiteChange(siteId);
                                                                        // }
                                                                        var vendorId = this.state.vendors.find((e) => e.name_of_vendor === s).id;
                                                                        if(vendorId !== null) {
                                                                            this.handleSelectedVendorChange(vendorId);
                                                                        }
                                                                    }}
                                                                    allowDeselect={false}
                                                                    searchable
                                                                />
                                                            </span>
                                                            <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                                                <Tooltip label="Click to create a new vendor"><IconPlus color="#00abfb" onClick={() => this.openVendorModal()}/></Tooltip>
                                                            </span>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormAcquisitionDate">
                                                        <span style={{ display: "inline-block", width: "95%" }}><DateInput 
                                                            label="When did you purchase the asset?"
                                                            placeholder="Pick a Date"
                                                            onChange={(s) => {
                                                                let dateString;
                                                                if(s === null) {
                                                                    dateString = "";
                                                                }

                                                                else {
                                                                    dateString = s.toString();
                                                                }
                                                                this.handleAcquisitionDateChange(dateString);
                                                            }}
                                                            //value={}
                                                            clearable
                                                        />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormContractNumber">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Contract Number" placeholder="Enter Text Here" value={this.state.contract_number_entered} onChange={this.handleContractNumberEnteredChange}/>
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormQuoteStatus">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Quote Status" placeholder="Enter Text Here" />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormRenewalStatus">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Renewal Status" placeholder="Enter Text Here" />
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormManufacturerWebsite">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Manufacturer Website" placeholder="Enter Text Here" value={this.state.website_entered} onChange={this.handleWebsiteEnteredChange} />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>
                                        </div>
                                        :
                                        undefined
                                    }
                                </Tabs.Panel>
                                {/* <Tabs.Panel value="Internal Specs">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="addFormRAM">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="RAM" placeholder="Enter Text Here" value={this.state.ram_entered} onChange={this.handleRAMEnteredChange} />
                                                    </span>
                                                </Form.Group>
                                                <Form.Group as={Col} controlId="addFormCPU">
                                                    <span style={{ display: "inline-block", width: "95%" }}>
                                                        <TextInput label="CPU" placeholder="Enter Text Here" value={this.state.cpu_entered} onChange={this.handleCPUEnteredChange} />
                                                    </span>
                                                </Form.Group>
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                                <Tabs.Panel value="Warranty Lease and Maintenance">
                                    {this.state.selected_form_tab === "Warranty Lease and Maintenance" ? 
                                        <div style={{marginTop: "30px"}}>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormWarrantyStartDate">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <DateInput 
                                                                label="Warranty Start Date"
                                                                placeholder="Pick a Date"
                                                                //value={}
                                                                onChange={(s) => {
                                                                    let dateString;
                                                                    if(s === null) {
                                                                        dateString = "";
                                                                    }

                                                                    else {
                                                                        dateString = s.toString();
                                                                    }
                                                                    this.handleWarrantyStartDateChange(dateString);
                                                                }}
                                                                clearable
                                                            />
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormWarrantyEndDate">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <DateInput 
                                                                label="Warranty End Date"
                                                                placeholder="Pick a Date"
                                                                //value={}
                                                                onChange={(s) => {
                                                                    let dateString;
                                                                    if(s === null) {
                                                                        dateString = "";
                                                                    }

                                                                    else {
                                                                        dateString = s.toString();
                                                                    }
                                                                    this.handleWarrantyEndDateChange(dateString);
                                                                }}
                                                                clearable
                                                            />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormLeaseStartDate">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <DateInput 
                                                                label="Lease Start Date"
                                                                placeholder="Pick a Date"
                                                                //value={}
                                                                onChange={(s) => {
                                                                    let dateString;
                                                                    if(s === null) {
                                                                        dateString = "";
                                                                    }

                                                                    else {
                                                                        dateString = s.toString();
                                                                    }
                                                                    this.handleLeaseStartDateChange(dateString);
                                                                }}
                                                                clearable
                                                            />
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group as={Col} controlId="addFormLeaseEndDate">
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <DateInput 
                                                                label="Lease End Date"
                                                                placeholder="Pick a Date"
                                                                //value={}
                                                                onChange={(s) => {
                                                                    let dateString;
                                                                    if(s === null) {
                                                                        dateString = "";
                                                                    }

                                                                    else {
                                                                        dateString = s.toString();
                                                                    }
                                                                    this.handleLeaseEndDateChange(dateString);
                                                                }}
                                                                clearable
                                                            />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormMaintenanceDates" xs={6}>
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Maintenance Dates" placeholder="Enter Text Here" />
                                                        </span>
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>
                                        </div>
                                        :
                                        undefined
                                    }
                                </Tabs.Panel>
                                <Tabs.Panel value="Misc">
                                    {this.state.selected_form_tab === "Misc" ? 
                                        <div style={{marginTop: "30px"}}>
                                            <Form>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormTrackAsset">
                                                        <Form.Label><Text size="sm">Should we track location and ownership history of this asset?</Text></Form.Label>
                                                        <Switch size="lg" onLabel="YES" offLabel="NO" checked={this.state.track_asset} onChange={(e) => this.handleTrackAssetChange(e)}/>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormIsPeripheral">
                                                        <Form.Label><Text size="sm">Is this asset a peripheral of another one?</Text></Form.Label>
                                                        <Switch size="lg" onLabel="YES" offLabel="NO" checked={this.state.is_peripheral} onChange={(e) => { this.toggleAddFormAssignedAssetInputVisible(e.target.checked); this.handleIsPeripheralChange(e) }}/>
                                                    </Form.Group>
                                                </Form.Row>
                                                {this.state.enable_assigned_asset_input ?
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormAssignedAssetCategory">
                                                            <Select 
                                                                label="What category does the asset this peripheral is assigned to belong to?"
                                                                placeholder="Pick a Value"
                                                                data={this.state.asset_categories && this.state.asset_categories.map((category) => (
                                                                    category.name_of_category
                                                                ))}
                                                                value={
                                                                    this.state.selected_assigned_asset_category_id !== 0 && this.state.asset_categories && this.state.asset_categories.length > 0 && this.state.asset_categories.find((e) => e.id === this.state.selected_assigned_asset_category_id) ?
                                                                        this.state.asset_categories.find((e) => e.id === this.state.selected_assigned_asset_category_id).name_of_category
                                                                        :
                                                                        ""
                                                                }
                                                                onChange={(s) => {

                                                                    var categoryId = this.state.asset_categories.find((e) => e.name_of_category === s).id;
                                                                    if(categoryId !== null) {
                                                                        this.handleAssignedAssetCategoryChange(categoryId);
                                                                    }
                                                                }}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormAssignedAssetType">
                                                            <Select
                                                                label="What type of asset is the asset this peripheral is assigned to?"
                                                                placeholder='Pick a Value'
                                                                data={
                                                                    this.state.asset_types && this.state.asset_types.map((type) => (
                                                                        type.name_of_type
                                                                    ))
                                                                }
                                                                value={
                                                                    this.state.selected_assigned_asset_type_id !== 0 && this.state.asset_types && this.state.asset_types.length > 0 && this.state.asset_types.find((e) => e.id === this.state.selected_assigned_asset_type_id) ? 
                                                                        this.state.asset_types.find((e) => e.id === this.state.selected_assigned_asset_type_id).name_of_type
                                                                        :
                                                                        ""
                                                                }
                                                                onChange={(s) => {
                                                                    var typeId = this.state.asset_types.find((e) => e.name_of_type === s).id;
                                                                    if(typeId !== null) {
                                                                        this.handleAssignedAssetTypeChange(typeId);
                                                                    }
                                                                }}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormAssignedAssetName">
                                                            <Select
                                                                label="What is the name of the asset this peripheral is assigned to?"
                                                                placeholder='Pick a Value'
                                                                data={
                                                                    this.state.assigned_asset_names && this.state.assigned_asset_names.map((asset) => (
                                                                        asset.name_of_asset
                                                                    ))
                                                                }
                                                                value={
                                                                    // this.state.selected_model_id !== 0 && this.state.model_names && this.state.model_names.length > 0 && this.state.model_names.find((e) => e.id === this.state.selected_model_id) ? 
                                                                    //     this.state.model_names.find((e) => e.id === this.state.selected_model_id).name_of_model
                                                                    //     :
                                                                    //     ""
                                                                    this.state.selected_assigned_asset_name_id !== 0 && this.state.assigned_asset_names && this.state.assigned_asset_names.length > 0 && this.state.assigned_asset_names.find((e) => e.id === this.state.selected_assigned_asset_name_id) ? 
                                                                        this.state.assigned_asset_names.find((e) => e.id === this.state.selected_assigned_asset_name_id).name_of_asset
                                                                        :
                                                                        ""
                                                                }
                                                                onChange={(s) => {
                                                                    // var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    // if(siteId !== null) {
                                                                    //     this.handleSelectedSiteChange(siteId);
                                                                    // }

                                                                    var assetId = this.state.assigned_asset_names.find((e) => e.name_of_asset === s).id;
                                                                    if(assetId !== null) {
                                                                        this.handleAssignedAssetNameChange(assetId);
                                                                    }
                                                                }}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                        </Form.Group>
                                                    </Form.Row>
                                                    :
                                                    undefined
                                                }
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormFileInput" xs={6}>
                                                        <FileInput clearable label="Upload a file" placeholder="Not final"/>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormDescription">
                                                        <Textarea label="Description" placeholder="Not final"/>
                                                    </Form.Group>
                                                </Form.Row> 
                                            </Form>
                                        </div>
                                        :
                                        undefined
                                    }
                                </Tabs.Panel>
                                {this.state.selected_type_name === "Services" ?
                                    <Tabs.Panel value="Services">
                                        {this.state.selected_form_tab === "Services" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormHealth">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Health"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSLAAvailability">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="SLA Availability (in %)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormServiceCost">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Cost"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormServiceSupportHours">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Service Support Hours" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Cloud" || 
                                    this.state.selected_type_name === "Virtual Machine" ||
                                    this.state.selected_type_name === "Volume" ||
                                    this.state.selected_type_name === "Host" || 
                                    this.state.selected_type_name === "Datacenter" || 
                                    this.state.selected_type_name === "Datastore" || 
                                    this.state.selected_type_name === "Image" || 
                                    this.state.selected_type_name === "Load Balancer" || 
                                    this.state.selected_type_name === "Security Group" || 
                                    this.state.selected_type_name === "Network" || 
                                    this.state.selected_type_name === "Resource Pool" || 
                                    this.state.selected_type_name === "Network Interface" || 
                                    this.state.selected_type_name === "Public IP Address" || 
                                    this.state.selected_type_name === "VPC" || 
                                    this.state.selected_type_name === "Subnet" || 
                                    this.state.selected_type_name === "Database" || 
                                    this.state.selected_type_name === "Key Pair" || 
                                    this.state.selected_type_name === "Object Storage" || 
                                    this.state.selected_type_name === "Key Vault" || 
                                    this.state.selected_type_name === "Subscription" || 
                                    this.state.selected_type_name === "Resource Group" || 
                                    this.state.selected_type_name === "Application Gateway" ? 
                                    <Tabs.Panel value="Cloud">
                                        {this.state.selected_form_tab === "Cloud" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormCloudCost">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Cost"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormLastAuditDate">
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Last Audit Date" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormRegion">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Region"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                            <Form.Group as={Col} controlId="addFormAvailabilityZone">
                                                                <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                    label="Availability Zone"
                                                                    placeholder="Not final"
                                                                    data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                        site.name_of_site
                                                                    ))*/}
                                                                    value={""
                                                                        /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                            this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                            :
                                                                            ""*/
                                                                    }
                                                                    onChange={""/*(s) => {
                                                                        var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                        if(siteId !== null) {
                                                                            this.handleSelectedSiteChange(siteId);
                                                                        }
                                                                    }*/}
                                                                    allowDeselect={false}
                                                                    searchable
                                                                />
                                                                </span> 
                                                            </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormProvider" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Provider"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Virtual Machine" ? 
                                    <Tabs.Panel value="Virtual Machine">
                                        {this.state.selected_form_tab === "Virtual Machine" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVMItemId">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Id" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormVMItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormInstanceType">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Instance Type"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPrivateAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Private Address" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPublicAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Public Address" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormVMState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormOSName" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="OS Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Volume" ? 
                                    <Tabs.Panel value="Volume">
                                        {this.state.selected_form_tab === "Volume" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVolumeItemId">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Id" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormVolumeItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVolumeType">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Volume Type" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormVolumeSize">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Size(GB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVolumeState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormIOPS">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IOPS" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVolumeCreationTimestamp" xs={6}>
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Creation Timestamp" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Host" ? 
                                    <Tabs.Panel value="Host">
                                        {this.state.selected_form_tab === "Host" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormHostItemId">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Id" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormHostItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormHostMemorySize">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Memory Size(GB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormHostCPUCoreCount">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="CPU Core Count"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormTotalDiskSize">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Total Disk Size(GB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormHostState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormHostIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormHostCreationTimestamp">
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Creation Timestamp" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Datacenter" ? 
                                    <Tabs.Panel value="Datacenter">
                                        {this.state.selected_form_tab === "Datacenter" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDatacenterItemId">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatacenterItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDatacenterState" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Datastore" ? 
                                    <Tabs.Panel value="Datastore">
                                        {this.state.selected_form_tab === "Datastore" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDatastoreItemId">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatastoreItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDatastoreState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatastoreSize">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Size(GB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDatastoreFreeSpace">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Free Space(GB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatastoreConnectionState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Connection State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Image" ? 
                                    <Tabs.Panel value="Image">
                                        {this.state.selected_form_tab === "Image" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormImageItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormImageItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormImageSource">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Image Source" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormImageGuestOS">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Guest OS"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Load Balancer" ? 
                                    <Tabs.Panel value="Load Balancer">
                                        {this.state.selected_form_tab === "Load Balancer" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormLoadBalancerItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormLoadBalancerItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormDNSName" xs={6}>
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="DNS Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                        </span>
                                                    </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Security Group" ? 
                                    <Tabs.Panel value="Security Group">
                                        {this.state.selected_form_tab === "Security Group" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormSecurityGroupItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSecurityGroupItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormInboundRuleCount">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Inbound Rule Count"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormOutboundRuleCount">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Outbound Rule Count"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Network" ? 
                                    <Tabs.Panel value="Network">
                                        {this.state.selected_form_tab === "Network" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormNetworkItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormNetworkItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormNetworkState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormNetworkConnectionState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Connection State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormBandwidth">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Bandwidth" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormNetworkRange">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Network Range" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Resource Pool" ? 
                                    <Tabs.Panel value="Resource Pool">
                                        {this.state.selected_form_tab === "Resource Pool" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormResourcePoolItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormResourcePoolItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormResourcePoolState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormLastUpdated">
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Last Updated" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Network Interface" ? 
                                    <Tabs.Panel value="Network Interface">
                                        {this.state.selected_form_tab === "Network Interface" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormNetworkInterfaceItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormNetworkInterfaceItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormNetworkInterfaceMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormNetworkInterfacePublicDNSName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Public DNS Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPrivateIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormNetworkInterfaceState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Public IP Address" ? 
                                    <Tabs.Panel value="Public IP Address">
                                        {this.state.selected_form_tab === "Public IP Address" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPublicIPItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPublicIPItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPublicIP">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Public IP" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPublicIPState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPublicDNS" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Public DNS" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "VPC" ? 
                                    <Tabs.Panel value="VPC">
                                        {this.state.selected_form_tab === "VPC" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVPCItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormVPCItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormVPCCIDRBlock" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="CIDR Block" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Subnet" ? 
                                    <Tabs.Panel value="Subnet">
                                        {this.state.selected_form_tab === "Subnet" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormSubnetItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSubnetItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormSubnetCIDRBlock" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="CIDR Block" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Database" ? 
                                    <Tabs.Panel value="Database">
                                        {this.state.selected_form_tab === "Database" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDatabaseItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatabaseItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPort">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Port" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormEngineVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Engine Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormAllocatedStorage">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Allocated Storage (GiB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormMaxAllocatedStorage">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Max Allocated Storage (GiB)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormEndpoint">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Endpoint" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormCreatedTime">
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Created Time" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormMultipleAvailabilityZone-Not final">
                                                            <Form.Label><Text size="sm">Multiple Availability Zone</Text></Form.Label>
                                                            <Switch size="lg" onLabel="YES" offLabel="NO" checked={""/*this.state.track_asset*/} onChange={""/*(e) => this.handleTrackAssetChange(e)*/}/>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormLicenseModel">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="License Model" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatabaseClass">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Database Class"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDatabaseStatus">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Status"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Key Pair" ? 
                                    <Tabs.Panel value="Key Pair">
                                        {this.state.selected_form_tab === "Key Pair" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormKeyPairItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormKeyPairItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Object Storage" ? 
                                    <Tabs.Panel value="Object Storage">
                                        {this.state.selected_form_tab === "Object Storage" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormObjectStorageItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormObjectStorageItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Key Vault" ? 
                                    <Tabs.Panel value="Key Vault">
                                        {this.state.selected_form_tab === "Key Vault" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormKeyVaultItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormKeyVaultItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Subscription" ? 
                                    <Tabs.Panel value="Subscription">
                                        {this.state.selected_form_tab === "Subscription" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormSubscriptionItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSubscriptionItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Resource Group" ? 
                                    <Tabs.Panel value="Resource Group">
                                        {this.state.selected_form_tab === "Resource Group" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormResourceGroupItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormResourceGroupItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Application Gateway" ? 
                                    <Tabs.Panel value="Application Gateway">
                                        {this.state.selected_form_tab === "Application Gateway" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormApplicationGatewayItemID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item ID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormApplicationGatewayItemName">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Item Name" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Hardware" || 
                                    this.state.selected_type_name === "Computer" || 
                                    this.state.selected_type_name === "Desktop" || 
                                    this.state.selected_type_name === "Laptop" || 
                                    this.state.selected_type_name === "Server" || 
                                    this.state.selected_type_name === "Storage" || 
                                    this.state.selected_type_name === "Disk" || 
                                    this.state.selected_type_name === "Datacenter" || 
                                    this.state.selected_type_name === "Rack" || 
                                    this.state.selected_type_name === "UPS" || 
                                    this.state.selected_type_name === "Mobile Devices" || 
                                    this.state.selected_type_name === "Monitor" || 
                                    this.state.selected_type_name === "Printer" || 
                                    this.state.selected_type_name === "Projector" || 
                                    this.state.selected_type_name === "Scanner" || 
                                    this.state.selected_type_name === "Router" || 
                                    this.state.selected_type_name === "Switch" || 
                                    this.state.selected_type_name === "Access Point" || 
                                    this.state.selected_type_name === "Firewall" || 
                                    this.state.selected_type_name === "Other Devices" || 
                                    this.state.selected_type_name === "SNMP" ? 
                                    <Tabs.Panel value="Hardware">
                                        {this.state.selected_form_tab === "Hardware" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormUsageType">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Usage Type"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormHardwareDomain">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Domain"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormHardwareAssetState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Asset State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormHardwareLastAuditDate">
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Last Audit Date" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Hardware" || 
                                    this.state.selected_type_name === "Computer" || 
                                    this.state.selected_type_name === "Desktop" || 
                                    this.state.selected_type_name === "Laptop" || 
                                    this.state.selected_type_name === "Server" || 
                                    this.state.selected_type_name === "Storage" || 
                                    this.state.selected_type_name === "Disk" || 
                                    this.state.selected_type_name === "Datacenter" || 
                                    this.state.selected_type_name === "Rack" || 
                                    this.state.selected_type_name === "UPS" || 
                                    this.state.selected_type_name === "Mobile Devices" || 
                                    this.state.selected_type_name === "Monitor" || 
                                    this.state.selected_type_name === "Printer" || 
                                    this.state.selected_type_name === "Projector" || 
                                    this.state.selected_type_name === "Scanner" || 
                                    this.state.selected_type_name === "Router" || 
                                    this.state.selected_type_name === "Switch" || 
                                    this.state.selected_type_name === "Access Point" || 
                                    this.state.selected_type_name === "Firewall" || 
                                    this.state.selected_type_name === "Other Devices" || 
                                    this.state.selected_type_name === "SNMP" ? 
                                    <Tabs.Panel value="Cost">
                                        {this.state.selected_form_tab === "Cost" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormHardwareCost">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Cost"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSalvage">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Salvage ($)"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDepreciationType" xs={6}>
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Depreciation Type"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Computer" || 
                                    this.state.selected_type_name === "Desktop" || 
                                    this.state.selected_type_name === "Laptop" || 
                                    this.state.selected_type_name === "Server" ? 
                                    <Tabs.Panel value="Computer">
                                        {this.state.selected_form_tab === "Computer" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormComputerOS">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="OS"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormComputerOSVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="OS Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormOSServicePack">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="OS Service Pack" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormComputerMemory">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Memory (GB)" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormComputerDiskSpace">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Disk Space (GB)" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormCPUSpeed">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="CPU Speed (GHz)" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormComputerCPUCoreCount">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="CPU Core Count"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormComputerMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormUUID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="UUID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormComputerHostname">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Hostname" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormComputerIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormLastLoginBy">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Last Login By" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {/* <Tabs.Panel value="Desktop">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel>
                                <Tabs.Panel value="Laptop">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel>
                                <Tabs.Panel value="Server">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel>
                                <Tabs.Panel value="Storage">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                                {this.state.selected_type_name === "Disk" ? 
                                    <Tabs.Panel value="Disk">
                                        {this.state.selected_form_tab === "Disk" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDriveType">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Drive Type"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDiskDiskSpace">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Disk Space (GB)" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDiskFreeSpace">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Free Space (GB)" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormFreeSpaceCheckedOn">
                                                            <div>
                                                                <span style={{ display: "inline-block", width: "95%" }}>
                                                                    <DateTimePicker label="Free Space Checked On" placeholder="Not final" clearable/>
                                                                </span>
                                                            </div>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Rack" ? 
                                    <Tabs.Panel value="Rack">
                                        {this.state.selected_form_tab === "Rack" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormFootprint">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Footprint" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPowerConsumption">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Power Consumption" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormRackUnits">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Rack Units" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormRackUnitsInUse">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Rack Units in Use" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "UPS" ? 
                                    <Tabs.Panel value="UPS">
                                        {this.state.selected_form_tab === "UPS" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormUPSIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormBatteryStatus">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Battery Status"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormBatteryVoltage">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Battery Voltage" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormBatteryCurrent">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Battery Current" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Mobile Devices" ? 
                                    <Tabs.Panel value="Mobile Devices">
                                        {this.state.selected_form_tab === "Mobile Devices" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormMobileDeviceOS">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="OS"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormMobileDeviceOSVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="OS Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormMobileDeviceMemory">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Memory (GB)" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormIMEINumber">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IMEI Number" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {/* <Tabs.Panel value="Monitor">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                                {this.state.selected_type_name === "Printer" ? 
                                    <Tabs.Panel value="Printer">
                                        {this.state.selected_form_tab === "Printer" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPrinterType">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Printer Type"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPrinterIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormPrinterMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormPrinterSubnetMask">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Subnet Mask" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {/* <Tabs.Panel value="Projector">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel>
                                <Tabs.Panel value="Scanner">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                                {this.state.selected_type_name === "Router" ? 
                                    <Tabs.Panel value="Router">
                                        {this.state.selected_form_tab === "Router" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormRouterFirmware">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormRouterFirmwareVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormRouterIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormRouterPorts">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Ports" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormRouterMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormRouterSubnetMask">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Subnet Mask" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Switch" ? 
                                    <Tabs.Panel value="Switch">
                                        {this.state.selected_form_tab === "Switch" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormSwitchFirmware">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSwitchFirmwareVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormSwitchIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSwitchPorts">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Ports" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormSwitchMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSwitchSubnetMask">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Subnet Mask" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Access Point" ? 
                                    <Tabs.Panel value="Access Point">
                                        {this.state.selected_form_tab === "Access Point" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormAccessPointFirmware">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormAccessPointFirmwareVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormAccessPointIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormAccessPointPorts">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Ports" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormAccessPointMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormAccessPointSubnetMask">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Subnet Mask" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Firewall" ?  
                                    <Tabs.Panel value="Firewall">
                                        {this.state.selected_form_tab === "Firewall" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormFirewallFirmware">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormFirewallFirmwareVersion">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Firmware Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormFirewallIPAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="IP Address" placeholder="Enter Text Here" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormFirewallPorts">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Ports" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormFirewallMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormFirewallSubnetMask">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="Subnet Mask" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {/* <Tabs.Panel value="Other Devices">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                                {this.state.selected_type_name === "SNMP" ? 
                                    <Tabs.Panel value="SNMP">
                                        {this.state.selected_form_tab === "SNMP" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormSystemOID">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="System OID" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                            </span>
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormSNMPMACAddress">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <TextInput label="MAC Address" placeholder="Enter Text Here" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {this.state.selected_type_name === "Consumable" ? 
                                    <Tabs.Panel value="Consumable">
                                        {this.state.selected_form_tab === "Consumable" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormConsumableState">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="State"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormConsumableQuantity">
                                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                                <NumberInput
                                                                    label="Quantity"
                                                                    placeholder="Not final"
                                                                    /*allowNegative={false}
                                                                    decimalScale={2}
                                                                    fixedDecimalScale
                                                                    step={0.01}
                                                                    prefix="$"
                                                                    hideControls
                                                                    value={this.state.cost_entered}
                                                                    onChange={this.handleCreateModelCostChange}*/
                                                                />
                                                            </span>
                                                        </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {/* <Tabs.Panel value="Network">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                                {this.state.selected_type_name === "Document" ? 
                                    <Tabs.Panel value="Document">
                                        {this.state.selected_form_tab === "Document" ? 
                                            <div style={{marginTop: "30px"}}>
                                                <Form>
                                                    <Form.Row>
                                                        <Form.Group as={Col} controlId="addFormDocumentStatus">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Status"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                        <Form.Group as={Col} controlId="addFormDocumentType">
                                                            <span style={{ display: "inline-block", width: "95%" }}><Select 
                                                                label="Type"
                                                                placeholder="Not final"
                                                                data={""/*this.state.sites && this.state.sites.map((site) => (
                                                                    site.name_of_site
                                                                ))*/}
                                                                value={""
                                                                    /*this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                                        this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                                        :
                                                                        ""*/
                                                                }
                                                                onChange={""/*(s) => {
                                                                    var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                                    if(siteId !== null) {
                                                                        this.handleSelectedSiteChange(siteId);
                                                                    }
                                                                }*/}
                                                                allowDeselect={false}
                                                                searchable
                                                            />
                                                            </span> 
                                                        </Form.Group>
                                                    </Form.Row>
                                                    <Form.Row>
                                                    <Form.Group as={Col} controlId="addFormDocumentVersion" xs={6}>
                                                        <span style={{ display: "inline-block", width: "95%" }}>
                                                            <TextInput label="Version" placeholder="Not final" value={""/*this.state.asset_tag_entered*/} onChange={""/*this.handleAssetTagEnteredChange*/} />
                                                        </span>
                                                    </Form.Group>
                                                    </Form.Row>
                                                </Form>
                                            </div>
                                            :
                                            undefined
                                        }
                                    </Tabs.Panel>
                                    :
                                    undefined
                                }
                                {/* <Tabs.Panel value="Others">
                                    <div style={{marginTop: "30px"}}>
                                        <Form>
                                            <Form.Row>
                                                
                                            </Form.Row>
                                        </Form>
                                    </div>
                                </Tabs.Panel> */}
                            </Tabs>
                        </Paper>
                        <div style={{textAlign: 'center'}}><Text c="dimmed" size="sm" style={{cursor: "default"}}>Note: Only the fields in the basic info tab are required</Text></div>
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeAddPage()}>Cancel</Button></span>
                            <span><Button /* onClick={() => this.submitAddAssetForm()} */>Submit</Button></span>
                        </div>
                        <div style={{ textAlign: "center", marginTop: "15px" }}>
                            {this.state.canSeeErrorMessage ?
                                <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields before submitting.</Text>
                                :
                                undefined
                            }
                            {this.state.canSeeDuplicateNameError ?
                                <Text c="red">Error: An asset with this name already exists. Please try a different name.</Text>
                                :
                                undefined
                            }
                        </div>
                            
                            {/* <Row>
                                <Col md={{ span: 12 }} style={{ textAlign: "center" }}>
                                    <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "20px" }}>
                                <Col md={{ span: 12 }} style={{ textAlign: "center" }}>
                                    <Form> */}

                                        {/* 
                                            1. Asset type (drop down) required
                                            2. Name of asset (text) required - must be unique
                                            3. Model name (drop down) required
                                            4. Vendor (drop down)
                                            5. Asset tag (text)
                                            6. Serial Number (text)
                                            7. Bar code (text)
                                            8. Acquisition date (date)
                                            9. Warranty start date (date)
                                            10. Warranty expiration date (date)
                                            11. Site (drop down) required
                                            12. Assigned person (drop down)
                                            13. Assigned location at site (drop down)
                                            14. Lease Start Date (date)
                                            15. Lease End Date (date)
                                            16. Manufacturer Website (text)
                                            17. Asset Category (drop down) required
                                            18. Is Tracked (radio) required
                                            19. Core Asset Assigned To (drop down)
                                        */}
                                        {/* <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormTenant" className="mb-3">
                                                    <Form.Label>Company<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control as="select" required>
                                                        {this.state.tenants && this.state.tenants.map((tenant) => (
                                                            <>
                                                                {this.state.selected_tenant_id === tenant.id ?
                                                                    <option key={`tenant_${tenant.id}`} onClick={() => this.handleTenantChange(tenant.id)} selected>{tenant.name_of_tenant}</option>
                                                                    :
                                                                    <option key={`tenant_${tenant.id}`} onClick={() => this.handleTenantChange(tenant.id)}>{tenant.name_of_tenant}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                        </div>
                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetName" className="mb-3">
                                                    <Form.Label>Asset Name<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control type="text" placeholder="ex: Dell 3600c" value={this.state.asset_name_entered} onChange={this.handleAssetNameEnteredChange} required />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetCategory" className="mb-3">
                                                    <Form.Label>Asset Category<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control as="select" required>
                                                        {this.state.asset_categories && this.state.asset_categories.map((category) => (
                                                            <>
                                                                {this.state.selected_category_id === category.id ?
                                                                    <option key={`category_${category.id}`} onClick={() => this.handleSelectedCategoryChange(category.id)} selected>{category.name_of_category}</option>
                                                                    :
                                                                    <option key={`category_${category.id}`} onClick={() => this.handleSelectedCategoryChange(category.id)}>{category.name_of_category}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openCategoryModal()} />
                                                </OverlayTrigger>
                                            </span>
                                        </div>


                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetType" className="mb-3">
                                                    <Form.Label>Asset Type<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control as="select" required>
                                                        {this.state.asset_types && this.state.asset_types.map((type) => (
                                                            <>
                                                                {this.state.selected_type_id === type.id ?
                                                                    <option key={`type_${type.id}`} onClick={() => this.handleSelectedTypeChange(type.id)} selected>{type.name_of_type}</option>
                                                                    :
                                                                    <option key={`type_${type.id}`} onClick={() => this.handleSelectedTypeChange(type.id)}>{type.name_of_type}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openTypeModal(false)} />
                                                </OverlayTrigger>
                                            </span>
                                        </div>
                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetModel" className="mb-3">
                                                    <Form.Label>{`Model Name (based on Type selected)`}<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control as="select" required>
                                                        {this.state.model_names && this.state.model_names.map((model) => (
                                                            <>
                                                                {this.state.selected_model_id === model.id ?
                                                                    <option key={`model_${model.id}`} onClick={() => this.handleSelectedModelChange(model.id)} selected>{model.name_of_model}</option>
                                                                    :
                                                                    <option key={`model_${model.id}`} onClick={() => this.handleSelectedModelChange(model.id)}>{model.name_of_model}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control> */}
                                                {/* </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openModelModal()} />
                                                </OverlayTrigger>
                                            </span>
                                        </div>
                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormSite" className="mb-3">
                                                    <Form.Label>{`Assigned Site (based on Company selected)`}<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control as="select" required>
                                                        {this.state.sites && this.state.sites.map((site) => (
                                                            <>
                                                                {this.state.selected_site_id === site.id ?
                                                                    <option key={`site_${site.id}`} onClick={() => this.handleSelectedSiteChange(site.id)} selected>{site.name_of_site}</option>
                                                                    :
                                                                    <option key={`site_${site.id}`} onClick={() => this.handleSelectedSiteChange(site.id)}>{site.name_of_site}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openSiteModal()} />
                                                </OverlayTrigger>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormLocationAtSite" className="mb-3">
                                                    <Form.Label>{`Location at Site (based on Site selected)`}</Form.Label>
                                                    <Form.Control as="select">
                                                        {this.state.locations_at_site && this.state.locations_at_site.map((location) => (
                                                            <>
                                                                {this.state.selected_location_in_building_id === location.id ?
                                                                    <option key={`location_${location.id}`} onClick={() => this.handleSelectedLocationChange(location.id)} selected>{`Floor: (${location.floor_number}), Aisle: (${location.aisle_number}), Desk: (${location.desk_number})`}</option>
                                                                    :
                                                                    <option key={`location_${location.id}`} onClick={() => this.handleSelectedLocationChange(location.id)}>{`Floor: (${location.floor_number}), Aisle: (${location.aisle_number}), Desk: (${location.desk_number})`}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openLocationModal()} />
                                                </OverlayTrigger>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssignedPerson" className="mb-3">
                                                    <Form.Label>{`Person Assigned To (based on Company selected)`}</Form.Label>
                                                    <Form.Control as="select">
                                                        {this.state.people && this.state.people.map((person) => (
                                                            person.id === 0 && this.state.selected_person_id === person.id ?
                                                                <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)} selected>{`${person.full_name}`}</option>
                                                                :
                                                                person.id === 0 && this.state.selected_person_id !== person.id ?
                                                                    <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)}>{`${person.full_name}`}</option>
                                                                    :
                                                                    person.id !== 0 && this.state.selected_person_id === person.id ?
                                                                        <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)} selected>{`${person.full_name} - (${person.name_of_tenant})`}</option>
                                                                        :
                                                                        <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)}>{`${person.full_name} - (${person.name_of_tenant})`}</option>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span> */}
                                        {/* </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetVendor" className="mb-3">
                                                    <Form.Label>Vendor</Form.Label>
                                                    <Form.Control as="select">
                                                        {this.state.vendors && this.state.vendors.map((vendor) => (
                                                            <>
                                                                {this.state.selected_vendor_id === vendor.id ?
                                                                    <option key={`vendor_${vendor.id}`} onClick={() => this.handleSelectedVendorChange(vendor.id)} selected>{vendor.name_of_vendor}</option>
                                                                    :
                                                                    <option key={`vendor_${vendor.id}`} onClick={() => this.handleSelectedVendorChange(vendor.id)}>{vendor.name_of_vendor}</option>
                                                                }
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openVendorModal()} />
                                                </OverlayTrigger>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetTag" className="mb-3">
                                                    <Form.Label>Asset Tag</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: John Smith's Laptop" value={this.state.asset_tag_entered} onChange={this.handleAssetTagEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetSerialNumber" className="mb-3">
                                                    <Form.Label>Serial Number</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: 1234abc789" value={this.state.serial_number_entered} onChange={this.handleSerialNumberEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAssetBarCode" className="mb-3">
                                                    <Form.Label>Bar Code</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: 0447789" value={this.state.barcode_entered} onChange={this.handleBarcodeEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormIPAddress" className="mb-3">
                                                    <Form.Label>IP Address</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: 192.158.1.38" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormMACAddress" className="mb-3">
                                                    <Form.Label>MAC Address</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: 00:00:5e:00:53:af" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormRAM" className="mb-3">
                                                    <Form.Label>RAM</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: 8GB" value={this.state.ram_entered} onChange={this.handleRAMEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormCPU" className="mb-3">
                                                    <Form.Label>CPU</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: Intel Core i7" value={this.state.cpu_entered} onChange={this.handleCPUEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormAcquisitionDate" className="mb-3">
                                                    <Form.Label>Acquistion Date</Form.Label>
                                                    <Form.Control type="date" name="addFormAcquisitionDate" onChange={e => this.handleAcquisitionDateChange(e)}></Form.Control>
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormManufacturerWebsite" className="mb-3">
                                                    <Form.Label>Manufacturer Website</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: https://www.dell.com" value={this.state.website_entered} onChange={this.handleWebsiteEnteredChange} />
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormWarrantyDates" className="mb-3">
                                                    <Form.Label>Warranty Start and End Dates</Form.Label>
                                                    <div>
                                                        <span style={{ display: "inline-block" }}>
                                                            <Form.Control type="date" name="addFormWarrantyStartDate" onChange={e => this.handleWarrantyStartDateChange(e)}></Form.Control>
                                                        </span>
                                                        <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
                                                            to
                                                        </span>
                                                        <span style={{ display: "inline-block" }}>
                                                            <Form.Control type="date" name="addFormWarrantyEndDate" onChange={e => this.handleWarrantyEndDateChange(e)}></Form.Control>
                                                        </span>
                                                    </div>
                                                </Form.Group>
                                            </span>
                                        </div>

                                        <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormLeaseDates" className="mb-3">
                                                    <Form.Label>Lease Start and End Dates</Form.Label>
                                                    <div>
                                                        <span style={{ display: "inline-block" }}>
                                                            <Form.Control type="date" name="addFormLeaseStartDate" onChange={e => this.handleLeaseStartDateChange(e)}></Form.Control>
                                                        </span>
                                                        <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
                                                            to
                                                        </span>
                                                        <span style={{ display: "inline-block" }}>
                                                            <Form.Control type="date" name="addFormLeaseEndDate" onChange={e => this.handleLeaseEndDateChange(e)}></Form.Control>
                                                        </span>
                                                    </div>
                                                </Form.Group>
                                            </span>
                                        </div> */}

                                        {/* <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormTrackAsset" className="mb-3">
                                                    <Form.Label>Track Asset</Form.Label>
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            checked={this.state.track_asset}
                                                            label="Yes"
                                                            name="track asset radio"
                                                            type="radio"
                                                            id="Yes Track Asset Radio"
                                                            value="true"
                                                            onChange={e => this.handleTrackAssetChange(e)}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            checked={!this.state.track_asset}
                                                            label="No"
                                                            name="track asset radio"
                                                            type="radio"
                                                            id="No Do Not Track Asset Radio"
                                                            value="false"
                                                            onChange={e => this.handleTrackAssetChange(e)}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </span>
                                        </div> */}

                                        {/* NOTE: This field is used only for front end. It's not stored in the backend */}
                                        {/* <div style={{ width: "100%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "95%" }}>
                                                <Form.Group controlId="addFormIsPeripheral" className="mb-3">
                                                    <Form.Label>Is Peripheral</Form.Label>
                                                    <div className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            checked={this.state.is_peripheral}
                                                            label="Yes"
                                                            name="peripheral radio"
                                                            type="radio"
                                                            id="Yes Is Peripheral Radio"
                                                            value="true"
                                                            onChange={e => { this.toggleAddFormAssignedAssetInputVisible(true); this.handleIsPeripheralChange(e) }}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            checked={!this.state.is_peripheral}
                                                            label="No"
                                                            name="peripheral radio"
                                                            type="radio"
                                                            id="Not Peripheral Radio"
                                                            value="false"
                                                            onChange={e => { this.toggleAddFormAssignedAssetInputVisible(false); this.handleIsPeripheralChange(e) }}
                                                        />
                                                    </div>
                                                </Form.Group>
                                            </span>
                                        </div>

                                        {this.state.enable_assigned_asset_input ?
                                            <div className="mb-3" style={{ width: "95%", textAlign: "left" }}>
                                                <span style={{ display: "inline-block", width: "33%" }}>
                                                    <Form.Group controlId="addFormAssignedAssetCategory">
                                                        <Form.Label>Assigned Asset Category</Form.Label>
                                                        <Form.Control as="select">
                                                            {this.state.asset_categories && this.state.asset_categories.map((category) => (
                                                                <>
                                                                    {this.state.selected_assigned_asset_category_id === category.id ?
                                                                        <option key={`assigned_category_${category.id}`} onClick={() => this.handleAssignedAssetCategoryChange(category.id)} selected>{category.name_of_category}</option>
                                                                        :
                                                                        <option key={`assigned_category_${category.id}`} onClick={() => this.handleAssignedAssetCategoryChange(category.id)}>{category.name_of_category}</option>
                                                                    }
                                                                </>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </span>
                                                <span style={{ display: "inline-block", width: "33%" }}>
                                                    <Form.Group controlId="addFormAssignedAssetType">
                                                        <Form.Label>Assigned Asset Type</Form.Label>
                                                        <Form.Control as="select">
                                                            {this.state.asset_types && this.state.asset_types.map((type) => (
                                                                <>
                                                                    {this.state.selected_assigned_asset_type_id === type.id ?
                                                                        <option key={`assigned_type_${type.id}`} onClick={() => this.handleAssignedAssetTypeChange(type.id)} selected>{type.name_of_type}</option>
                                                                        :
                                                                        <option key={`assigned_type_${type.id}`} onClick={() => this.handleAssignedAssetTypeChange(type.id)}>{type.name_of_type}</option>
                                                                    }
                                                                </>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </span>
                                                <span style={{ display: "inline-block", width: "33%" }}>
                                                    <Form.Group controlId="addFormAssignedAssetName">
                                                        <Form.Label>Assigned Asset Name<span className="requiredStar">*</span></Form.Label>
                                                        <Form.Control as="select">
                                                            {this.state.assigned_asset_names && this.state.assigned_asset_names.map((asset) => (
                                                                <>
                                                                    {this.state.selected_assigned_asset_name_id === asset.id ?
                                                                        <option key={`assigned_asset_${asset.id}`} onClick={() => this.handleAssignedAssetNameChange(asset.id)} selected>{asset.name_of_asset}</option>
                                                                        :
                                                                        <option key={`assigned_asset_${asset.id}`} onClick={() => this.handleAssignedAssetNameChange(asset.id)}>{asset.name_of_asset}</option>
                                                                    }
                                                                </>
                                                            ))}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </span>
                                            </div> :

                                            <div className="mb-3" style={{ width: "95%", textAlign: "left" }}>
                                                <span style={{ display: "inline-block", width: "33%" }}>
                                                    <Form.Group controlId="addFormAssignedAssetCategory">
                                                        <Form.Label>Assigned Asset Category</Form.Label>
                                                        <Form.Control as="select" disabled>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </span>
                                                <span style={{ display: "inline-block", width: "33%" }}>
                                                    <Form.Group controlId="addFormAssignedAssetType">
                                                        <Form.Label>Assigned Asset Type</Form.Label>
                                                        <Form.Control as="select" disabled>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </span>
                                                <span style={{ display: "inline-block", width: "33%" }}>
                                                    <Form.Group controlId="addFormAssignedAssetName">
                                                        <Form.Label>Assigned Asset Name</Form.Label>
                                                        <Form.Control as="select" disabled>
                                                        </Form.Control>
                                                    </Form.Group>
                                                </span>
                                            </div>
                                        }

                                        <div style={{ textAlign: "center", marginTop: "30px" }}>
                                            <span><Button variant="secondary" onClick={() => this.closeAddPage()} className="gothamNarrowFont">Cancel</Button></span>
                                            <span style={{ paddingLeft: "55px" }}><Button id="newDepartmentFormOkButton" onClick={() => { this.submitAddAssetForm() }} className="gothamNarrowFont">Submit</Button></span>
                                        </div>
                                        <div style={{ textAlign: "center", marginTop: "15px" }}>
                                            {this.state.canSeeErrorMessage ?
                                                <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields before submitting.</p>
                                                :
                                                undefined
                                            }
                                            {this.state.canSeeDuplicateNameError ?
                                                <p style={{ color: "red" }}>Error: An asset with this name already exists. Please try a different name.</p>
                                                :
                                                undefined
                                            }
                                        </div>

                                    </Form>
                                </Col>
                            </Row> */}
                    </Container>
                </Box>

                {/* This modal is the welcome modal that controls company and manufacturer choice */}
                <Modal
                    opened={this.state.show_welcome_modal}
                    onClose={() => this.closeWelcomeModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <Form>
                        
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Let's Start With the Following Data</Text></div>
                        {/* THE HIDDEN ELEMENT WAS NEEDED AT THE TOP OF THE FORM TO ALLOW THE FIRST ELEMENT TO BE SEARCHABLE - WAS CAUSING ERROR OTHERWISE */}
                        <Form.Group as={Col} controlId="hiddenElementWelcomModal"><TextInput hidden></TextInput></Form.Group>
                        <Form.Group as={Col} controlId="addFormTenant">
                            <Select 
                                label="What company does this asset belong to?"
                                placeholder="Pick a Value"
                                data={this.state.tenants && this.state.tenants.map((tenant) => (
                                    tenant.name_of_tenant
                                ))}
                                value={
                                    this.state.selected_tenant_id !== 0 && this.state.tenants && this.state.tenants.length > 0 && this.state.tenants.find((e) => e.id === this.state.selected_tenant_id) ? 
                                        this.state.tenants.find((e) => e.id === this.state.selected_tenant_id).name_of_tenant
                                        :
                                        ""
                                }
                                onChange={(s) => {
                                    var tenantId = this.state.tenants.find((e) => e.name_of_tenant === s).id;
                                    if(tenantId !== null) {
                                        this.handleTenantChange(tenantId);
                                    }
                                }}
                                allowDeselect={false}
                                searchable
                            />
                        </Form.Group>
                        <Form.Group as={Col} controlId="addFormModelManufacturer">
                            <Select
                                label="Who made this asset?"
                                placeholder="Pick a Value"
                                data={
                                    this.state.manufacturers && this.state.manufacturers.map((manufacturer) => (
                                        manufacturer.name_of_manufacturer
                                    ))
                                }
                                value={
                                    this.state.manufacturer_id_selected !== 0 && this.state.manufacturers && this.state.manufacturers.length > 0 && this.state.manufacturers.find((e) => e.id === this.state.manufacturer_id_selected) ? 
                                        this.state.manufacturers.find((e) => e.id === this.state.manufacturer_id_selected).name_of_manufacturer
                                        :
                                        ""
                                }
                                onChange={(s) => {
                                    var manufacturerId = this.state.manufacturers.find((e) => e.name_of_manufacturer === s).id;
                                    if(manufacturerId !== null) {
                                        this.handleCreateModelManufacturerChange(manufacturerId)
                                    }
                                }}
                                allowDeselect={false}
                                searchable
                            />
                        </Form.Group>
                        <div style={{textAlign: 'center', marginTop: '50px'}}><Text c="dimmed" size="sm">Note: Changing these values will change options available in other form fields</Text></div>
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span><Button onClick={() => this.closeWelcomeModal()}>Ok</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for adding asset categories */}
                <Modal
                    opened={this.state.show_category_modal}
                    onClose={() => this.closeCategoryModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create A Category</Text></div>
                        {/* <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormCategoryName" className="mb-3">
                                        {/* <Form.Label>Category Name</Form.Label> */}
                                        <TextInput label="Category Name" placeholder="ex: IT" value={this.state.createCategory_category_name_entered} onChange={this.handleCreateCategoryNameChange} required />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateCategory ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateCategory ?
                                    <Text c="red">Error: A category with this name already exists. Please try a different name.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeCategoryModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateCategoryForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for adding an asset type */}
                <Modal
                    opened={this.state.show_type_modal}
                    onClose={() => this.closeTypeModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create Asset Type</Text></div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Asset Type</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormTypeName" className="mb-3">
                                        {/* <Form.Label>Type Name<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Type Name" placeholder="ex: Printer" value={this.state.createType_type_name} onChange={this.handleCreateTypeNameChange} required />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "95%" }}>
                                    <Form.Group controlId="addFormIsCoreAsset" className="mb-3">
                                        <Form.Label><Text size="sm">Can this asset have peripherals assigned to it?</Text></Form.Label>
                                        <Switch size="md" onLabel="YES" offLabel="NO" checked={this.state.selected_is_core_value} onChange={(e) => this.handleIsCoreChange(e.target.checked)} required/>
                                        {/* <Form.Label>Is Core Asset<span className="requiredStar">*</span></Form.Label>
                                        <div className="mb-3">
                                            <Form.Check
                                                inline
                                                checked={this.state.selected_is_core_value}
                                                label="Yes"
                                                name="peripheral radio"
                                                type="radio"
                                                id="Yes Is Core Radio"
                                                value="true"
                                                onChange={() => this.handleIsCoreChange(true)}
                                            />
                                            <Form.Check
                                                inline
                                                checked={!this.state.selected_is_core_value}
                                                label="No"
                                                name="peripheral radio"
                                                type="radio"
                                                id="Not Core Radio"
                                                value="false"
                                                onChange={() => this.handleIsCoreChange(false)}
                                            />
                                        </div> */}
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateType ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateType ?
                                    <Text c="red">Error: A type with this name already exists. Please try a different name.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeTypeModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateTypeForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for creating asset models */}
                <Modal
                    opened={this.state.show_model_modal}
                    onClose={() => this.closeModelModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create Asset Model</Text></div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Asset Model</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                            <Modal.Body>*/}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormModelName" className="mb-3">
                                        {/* <Form.Label>Model Name<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Model Name" placeholder="ex: Lenovo Thinkpad x51" value={this.state.model_name_entered} onChange={this.handleCreateModelNameChange} required />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormModelType" className="mb-3">
                                        {/* <Form.Label>{`Asset Type (based on type selected in outer form)`}<span className="requiredStar">*</span></Form.Label> */}
                                        <Select
                                            label="Asset Type (based on type selected in outer form)"
                                            placeholder="Pick a Value"
                                            data={
                                                this.state.asset_types && this.state.asset_types.map((type) => (
                                                    type.name_of_type
                                                ))
                                            }
                                            value={
                                                this.state.selected_type_id !== 0 && this.state.asset_types && this.state.asset_types.length > 0 && this.state.asset_types.find((e) => e.id === this.state.selected_type_id) ?
                                                    this.state.asset_types.find((e) => e.id === this.state.selected_type_id).name_of_type
                                                    :
                                                    ""
                                            }
                                            disabled
                                        />
                                        {/* <Form.Control as="select" required disabled>
                                            {this.state.asset_types && this.state.asset_types.map((type) => (
                                                <>
                                                    {this.state.selected_type_id === type.id ?
                                                        <option key={`modelType_${type.id}`} onClick={() => this.handleCreateModelTypeChange(type.id)} selected>{type.name_of_type}</option>
                                                        :
                                                        <option key={`modelType_${type.id}`} onClick={() => this.handleCreateModelTypeChange(type.id)}>{type.name_of_type}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control> */}
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormModelCost" className="mb-3">
                                        {/* <Form.Label>Cost</Form.Label> */}
                                        <NumberInput
                                            label="Cost"
                                            placeholder="Enter cost here"
                                            allowNegative={false}
                                            decimalScale={2}
                                            fixedDecimalScale
                                            step={0.01}
                                            prefix="$"
                                            hideControls
                                            value={this.state.cost_entered/* parseFloat(this.state.cost_entered).toString() */}
                                            onChange={this.handleCreateModelCostChange}
                                        />
                                        {/* <Form.Control type="number" min="0" step="0.01" placeholder="ex: 1250.59" value={parseFloat(this.state.cost_entered).toString()} onChange={(e)=> this.handleCreateModelCostChange(e)} /> */}
                                        {/* Within the form's submit fxn use: Math.round(3.14159265359 * 100) / 100 to make sure value has 2 decimal places */}
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "95%" }}>
                                    <Form.Group controlId="addFormModelManufacturer" className="mb-3">
                                        {/* <Form.Label>Manufacturer<span className="requiredStar">*</span></Form.Label> */}
                                        <Select 
                                            label="Manufacturer"
                                            placeholder="Pick a Value"
                                            data={
                                                this.state.manufacturers && this.state.manufacturers.map((manufacturer) => (
                                                    manufacturer.name_of_manufacturer
                                                ))
                                            }
                                            value={
                                                this.state.manufacturer_id_selected_create_model_form !== 0 && this.state.manufacturers && this.state.manufacturers.length > 0 && this.state.manufacturers.find((e) => e.id === this.state.manufacturer_id_selected_create_model_form) ? 
                                                    this.state.manufacturers.find((e) => e.id === this.state.manufacturer_id_selected_create_model_form).name_of_manufacturer
                                                    :
                                                    ""
                                            }
                                            onChange={(s) => {
                                                // var siteId = this.state.sites.find((e) => e.name_of_site === s).id;
                                                // if(siteId !== null) {
                                                //     this.handleSelectedSiteChange(siteId);
                                                // }

                                                var manufacturerId = this.state.manufacturers.find((e) => e.name_of_manufacturer === s).id;
                                                if(manufacturerId !== null) {
                                                    this.handleCreateModelManufacturerChangeCreateModelModalForm(manufacturerId);
                                                }
                                            }}
                                            allowDeselect={false}
                                            searchable
                                            required
                                        />
                                        {/* <Form.Control as="select" required>
                                            {this.state.manufacturers && this.state.manufacturers.map((manufacturer) => (
                                                <>
                                                    {this.state.manufacturer_id_selected === manufacturer.id ?
                                                        <option key={`manufacturer_${manufacturer.id}`} onClick={() => this.handleCreateModelManufacturerChange(manufacturer.id)} selected>{manufacturer.name_of_manufacturer}</option>
                                                        :
                                                        <option key={`manufacturer_${manufacturer.id}`} onClick={() => this.handleCreateModelManufacturerChange(manufacturer.id)}>{manufacturer.name_of_manufacturer}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control> */}
                                    </Form.Group>
                                </span>
                                {/* <span style={{ display: "inline-block", paddingLeft: "15px" }}> */}
                                <span style={{ display: "inline-block", paddingLeft: "5px", cursor: "pointer" }}>
                                    <Tooltip label="Click to create a new manufacturer"><IconPlus color="#00abfb" onClick={() => this.openManufacturerModal()}/></Tooltip>
                                </span>
                                    {/* <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>Click to add a new item</Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openManufacturerModal()} />
                                    </OverlayTrigger> */}
                                {/* </span> */}
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateModel ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateModel ?
                                    <Text c="red">Error: A model with this name already exists. Please try a different name.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeModelModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateModelForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for creating a new manufacturer */}
                <Modal
                    opened={this.state.show_manufacturer_modal}
                    onClose={() => this.closeManufacturerModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create Manufacturer</Text></div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Manufacturer</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormManufacturerName" className="mb-3">
                                        {/* <Form.Label>Manufacturer Name<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Manufacturer Name" placeholder="ex: Lenovo" value={this.state.manufacturer_name_entered} onChange={this.handleCreateManufacturerNameChange} required />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormManufacturerType" className="mb-3">
                                        {/* <Form.Label>Manufacturer Type<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Manufacturer Type" placeholder="ex: Hardware" value={this.state.manufacturer_type_entered} onChange={this.handleCreateManufacturerTypeChange} required />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormManufacturerPhone" className="mb-3">
                                        {/* <Form.Label>Phone Number</Form.Label> */}
                                        <TextInput label="Phone Number" placeholder="ex: 555-555-5555" value={this.state.manufacturer_phone_number_entered} onChange={this.handleCreateManufacturerPhoneChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateManufacturer ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateManufacturer ?
                                    <Text c="red">Error: A manufacturer with this name and type already exists. Please try again.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeManufacturerModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateManufacturerForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for adding sites */}
                <Modal
                    opened={this.state.show_site_modal}
                    onClose={() => this.closeSiteModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create Site</Text></div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "left", cursor: "default" }} className="gothamNarrowFont">Create Site</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormSiteCompany" className="mb-3">
                                        <Select 
                                            label="Company"
                                            placeholder="Pick a Value"
                                            data={
                                                this.state.tenants && this.state.tenants.map((tenant) => (
                                                    tenant.name_of_tenant
                                                ))
                                            }
                                            value={
                                                this.state.selected_tenant_id !== 0 && this.state.tenants && this.state.tenants.length > 0 && this.state.tenants.find((e) => e.id === this.state.selected_tenant_id) ? 
                                                    this.state.tenants.find((e) => e.id === this.state.selected_tenant_id).name_of_tenant
                                                    :
                                                    ""
                                            }
                                            disabled
                                            required
                                        />
                                        {/* <Form.Label>Company<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control as="select" required disabled>
                                            {this.state.tenants && this.state.tenants.map((tenant) => (
                                                <>
                                                    {this.state.selected_tenant_id === tenant.id ?
                                                        <option key={`add_site_tenant_${tenant.id}`} selected>{tenant.name_of_tenant}</option>
                                                        :
                                                        <option key={`add_site_tenant_${tenant.id}`}>{tenant.name_of_tenant}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control> */}
                                        <Form.Text className="text-muted">
                                            Note: This value is controlled by which company you have selected on the add hardware asset screen
                                        </Form.Text>
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormSiteName" className="mb-3">
                                        {/* <Form.Label>Site Name<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Site Name" placeholder="ex: Irvine Office" value={this.state.site_name_entered} onChange={this.handleCreateSiteNameChange} required />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormSiteStreetAddress" className="mb-3">
                                        {/* <Form.Label>Street Address</Form.Label> */}
                                        <TextInput label="Street Address" placeholder="ex: 1111 Wilmington Pl" value={this.state.site_street_address_entered} onChange={this.handleCreateSiteStreetAddressChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormSiteCity" className="mb-3">
                                        {/* <Form.Label>City</Form.Label> */}
                                        <TextInput label="City" placeholder="ex: Irvine" value={this.state.site_city_entered} onChange={this.handleCreateSiteCityChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormSiteState" className="mb-3">
                                        {/* <Form.Label>State</Form.Label> */}
                                        <TextInput label="State" placeholder="ex: CA" value={this.state.site_state_entered} onChange={this.handleCreateSiteStateChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormSiteZipCode" className="mb-3">
                                        {/* <Form.Label>Zip Code</Form.Label> */}
                                        <TextInput label="Zip Code" placeholder="ex: 99999" value={this.state.site_zip_code_entered} onChange={this.handleCreateSiteZipCodeChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateSite ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateSite ?
                                    <Text c="red">Error: A site with this name already exists. Please try again.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeSiteModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateSiteForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for adding locations at sites */}
                <Modal
                    opened={this.state.show_location_modal}
                    onClose={() => this.closeLocationModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create Location at Site</Text></div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Location at Site</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormLocationSite" className="mb-3">
                                        <Select 
                                            label="Site (based on selection in outer form)"
                                            placeholder="Pick a Value"
                                            data={
                                                this.state.sites && this.state.sites.map((site) => (
                                                    site.name_of_site
                                                ))
                                            }
                                            value={
                                                // this.state.selected_model_id !== 0 && this.state.model_names && this.state.model_names.length > 0 && this.state.model_names.find((e) => e.id === this.state.selected_model_id) ? 
                                                //     this.state.model_names.find((e) => e.id === this.state.selected_model_id).name_of_model
                                                //     :
                                                //     ""
                                                this.state.selected_site_id !== 0 && this.state.sites && this.state.sites.length > 0 && this.state.sites.find((e) => e.id === this.state.selected_site_id) ? 
                                                    this.state.sites.find((e) => e.id === this.state.selected_site_id).name_of_site
                                                    :
                                                    ""
                                            }
                                            required
                                            disabled
                                        />
                                        {/* <Form.Label>{`Site (based on selection in outer form)`}<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control as="select" required disabled>
                                            {this.state.sites && this.state.sites.map((site) => (
                                                <>
                                                    {this.state.selected_site_id === site.id ?
                                                        <option key={`add_site_${site.id}`} selected>{site.name_of_site}</option>
                                                        :
                                                        <option key={`add_site_${site.id}`}>{site.name_of_site}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control> */}
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormLocationFloor" className="mb-3">
                                        {/* <Form.Label>Floor<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Floor" placeholder="ex: 1" required value={this.state.location_floor_entered} onChange={this.handleCreateLocationFloorChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormLocationAisle" className="mb-3">
                                        {/* <Form.Label>Aisle<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Aisle" placeholder="ex: 1" required value={this.state.location_aisle_entered} onChange={this.handleCreateLocationAisleChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            {/* <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormLocationDesk" className="mb-3">
                                        {/* <Form.Label>Desk<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Desk" placeholder="ex: 1" required value={this.state.location_desk_entered} onChange={this.handleCreateLocationDeskChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateLocation ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateLocation ?
                                    <Text c="red">Error: A location with this information already exists. Please try again.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeLocationModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateLocationForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

                {/* This modal is for adding vendors */}
                <Modal
                    opened={this.state.show_vendor_modal}
                    onClose={() => this.closeVendorModal()}
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    // backdrop="static"
                    // keyboard={false}
                    // size="lg"
                    // centered
                >
                    <Form>
                        <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="xl">Create Vendor</Text></div>
                        {/* <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Vendor</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}> */}
                                    <Form.Group controlId="addFormVendorName" className="mb-3">
                                        {/* <Form.Label>Vendor Name<span className="requiredStar">*</span></Form.Label> */}
                                        <TextInput label="Vendor Name" placeholder="ex: Dell EMC" required value={this.state.vendor_name_entered} onChange={this.handleCreateVendorNameChange} />
                                    </Form.Group>
                                {/* </span>
                            </div> */}
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateVendor ?
                                    <Text c="red">Error: One or more required form fields has not been filled out. Please complete required fields.</Text>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateVendor ?
                                    <Text c="red">Error: A vendor with this name already exists. Please try again.</Text>
                                    :
                                    undefined
                                }
                            </div>
                        {/* </Modal.Body> */}
                        <div style={{textAlign: 'center', marginTop: "50px"}}>
                            <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeVendorModal()} className="gothamNarrowFont">Cancel</Button></span>
                            <span><Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateVendorForm() }} className="gothamNarrowFont">Submit</Button></span>
                        </div>
                    </Form>
                </Modal>

            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        hardwareAssetFilter: state.hardwareAssetFilter
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT, UPDATE_SELECTED_COMPANY_IN_ADD_ASSET_FORM_HARDWARE })(CreateHardwareAsset);