import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';

import Navbar from '../../components/Navbar';

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import UserDataService from "../../services/user-activities";
import HardwareAssetDataService from "../../services/hardware-asset-activities";

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

import { UPDATE_SELECTED_TENANT_USER_LIST_FILTER, UPDATE_SELECTED_ROLE_USER_LIST_FILTER, UPDATE_SEARCH_TEXT_USER_LIST_FILTER, RESET_USER_LIST_FILTERS } from '../../features/actions/User-List-Filter-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';
import "bootstrap/dist/css/bootstrap.min.css";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Table,
    //ScrollArea,
    //UnstyledButton,
    //Group,
    Text,
    //Center,
    TextInput,
    rem,
    //keys,
    Button,
    Paper,
    Select,
    Grid,
    Flex,
    Tooltip
} from '@mantine/core';
import { /* IconSelector, IconChevronDown, IconChevronUp, */ IconSearch, /* IconMenu2, */ IconFilterX } from '@tabler/icons-react';

//import { faSearch, faFilter, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';


class EditUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            user_details: {},      // will contain the specifics for this user in object format
            role_list: [],       // list of role names and id numbers [{id: 2, name_of_role: "General User"}]
            selected_role_id: 0,     // id of role selected in drop down (will be sent to backend upon update)
            selected_role_name: "",  // name of the role to display in the drop down

            first_name_entered: "",
            middle_name_entered: "",
            last_name_entered: "",
            email_to_display: ""       // this form field is going to be read only to avoid issues with duplicate account creation
        }
    }

    componentDidMount() {
        if(this.props.loggedStatus.loggedIn){
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // get basic user details for all form fields
                    UserDataService.getUserProfileData(this.props.match.params.id, this.props.loggedStatus.id, token).then(response => {
                        console.log(response);
                        if(response && response.data.user_details && response.data.user_details.length > 0) {

                            this.setState({
                                user_details: response.data.user_details[0],
                                first_name_entered: response.data.user_details[0].first_name,
                                middle_name_entered: response.data.user_details[0].middle_name,
                                last_name_entered: response.data.user_details[0].last_name,
                                email_to_display: response.data.user_details[0].email_address
                            }, () => {
                                // get the list of roles and id numbers, then update the selected one accordingly
                                UserDataService.getRoleList(token).then(response => {
                                    console.log("Role List: ", response);
                                    this.setState({
                                        role_list: response.data.results
                                    }, () => {
                                        this.changeRoleSelection(this.state.role_list, this.state.user_details.name_of_role);
                                    });
                                })
                                .catch(e => {
                                    console.log(e);
                                });
                            });
 
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }

            });

        }
    }

    sendEditDetailsToBackend = e => {

        e.preventDefault();

        console.log("You'll send here");

        // let middleName;

        // if(this.state.middle_name_entered === null) {
        //     middleName = "";
        // }

        // else {
        //     middleName = this.state.middle_name_entered;
        // }

        let formDataObj = {
            roleId: this.state.selected_role_id,
            tenantId: this.state.user_details.primary_tenant_id,
            userIdAsking: this.props.loggedStatus.id,        // id of the techncian making the edit
            firstName: this.state.first_name_entered,
            middleName: this.state.middle_name_entered,
            lastName: this.state.last_name_entered
        };     

        console.log("Form data to send: ", formDataObj);

        UserDataService.editUser(this.props.match.params.id, formDataObj, this.props.loggedStatus.accessToken).then(response => {
            console.log("User was edited");

            // return back to that user's details page
            this.props.history.push({pathname: "/userProfile/" + this.props.match.params.id})
        })
        .catch(e => {
            console.log(e);
        });
    }

    // give this function the list of roles possible and the name of the role you want selected, and it will set the state accordingly
    changeRoleSelection(roleList, roleNameSelected) {

        console.log("Role List received: ", roleList);
        console.log("Role Name received: ", roleNameSelected);

        let roleId = roleList.find((e) => e.name_of_role === roleNameSelected).id

        this.setState({
            selected_role_id: roleId,
            selected_role_name: roleNameSelected
        })
    }

    handleFirstNameChange(e) {
        this.setState({
            first_name_entered: e.target.value
        });
    }

    handleMiddleNameChange(e) {
        console.log(e.target.value);
        this.setState({
            middle_name_entered: e.target.value
        });
    }

    handleLastNameChange(e) {
        this.setState({
            last_name_entered: e.target.value
        });
    }

    render() {

        if(!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_edit_users /* && !this.props.userPermissions.can_delete_users */)) {
            return (
                <Redirect to='/'/>
            );
        }

        return (
            <>
                <Navbar pageTitle={"Edit User"} />
                <div className="container mt-3" style={{paddingTop: "15px"}}></div>
                <Container fluid >
                <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Grid>
                            <Grid.Col span={6}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Name</Table.Td>
                                        <Table.Td c="blue">
                                            {this.state.user_details.first_name ? 
                                                this.state.user_details.middle_name ? 
                                                `${this.state.user_details.first_name} ${this.state.user_details.middle_name} ${this.state.user_details.last_name}` 
                                                : 
                                                `${this.state.user_details.first_name} ${this.state.user_details.last_name}`
                                                :
                                                undefined
                                            }
                                        </Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Email</Table.Td>
                                        <Table.Td c="blue">{this.state.user_details.email_address && this.state.user_details.email_address}</Table.Td>
                                    </Table.Tr>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Role</Table.Td>
                                        <Table.Td c="blue">{this.state.user_details.name_of_role && this.state.user_details.name_of_role}</Table.Td>
                                    </Table.Tr>
                                </Table>
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Form onSubmit={this.sendEditDetailsToBackend}>
                            <Form.Row>
                                <Form.Group as={Col} controlId="editUserFirstName">
                                    <TextInput label="First Name" placeholder="Enter First Name" value={this.state.first_name_entered} onChange={(e) => this.handleFirstNameChange(e)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="editUserMiddleName">
                                    <TextInput label="Middle Name" placeholder="Enter Middle Name" value={this.state.middle_name_entered} onChange={(e) => this.handleMiddleNameChange(e)}/>
                                </Form.Group>
                                <Form.Group as={Col} controlId="editUserLastName">
                                    <TextInput label="Last Name" placeholder="Enter Last Name" value={this.state.last_name_entered} onChange={(e) => this.handleLastNameChange(e)}/>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="editUserEmail">
                                    <TextInput label="Email Address" placeholder="Enter Email Here" defaultValue={this.state.email_to_display} readOnly disabled />
                                    <Form.Text className="text-muted gothamNarrowLightFont">
                                        Note: Please <a href='mailto:cumulussupport@progroupco.com?subject=Request To Update User Email Address In Cumulus'>contact ProGroup</a> if this needs to be changed
                                    </Form.Text>
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <Form.Group as={Col} controlId="editUserRoleId">
                                    <Select 
                                        label="Role"
                                        placeholder="Pick A Value"
                                        data={this.state.role_list && this.state.role_list.map((role) => (role.name_of_role))}
                                        value={this.state.selected_role_name}
                                        onChange={(s) => this.changeRoleSelection(this.state.role_list, s)}
                                        allowDeselect={false}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row>
                                <div style={{margin: "auto", paddingTop: "25px"}}>
                                    <Button variant="primary" type="submit" className="gothamNarrowFont">Save</Button>
                                </div>
                            </Form.Row>
                        </Form>
                    </Paper>
                    
                </Container>
                
            </>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        userListFilter: state.userListFilter
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_LINK, ACCESSTOKEN, LOGOUT})(EditUser);