export const UPDATE_ACTIVE_ASSIGNMENT = content => ({
    type: "UPDATE_ACTIVE_ASSIGNMENT",
    payload: content
});

export const UPDATE_ACTIVE_STATUS = content => ({
    type: "UPDATE_ACTIVE_STATUS",
    payload: content
});

export const UPDATE_ACTIVE_TYPE = content => ({
    type: "UPDATE_ACTIVE_TYPE",
    payload: content
});

export const UPDATE_ACTIVE_PRIORITY = content => ({
    type: "UPDATE_ACTIVE_PRIORITY",
    payload: content
});

export const ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN = content => ({
    type: "ALERT_ASSIGNMENT_CHANGE_FROM_DROPDOWN"
});

export const ASSIGNMENT_CHANGE_RESOLVED = content => ({
    type: "ASSIGNMENT_CHANGE_RESOLVED"
});

export const ALERT_STATUS_CHANGE_FROM_DROPDOWN = content => ({
    type: "ALERT_STATUS_CHANGE_FROM_DROPDOWN"
});

export const STATUS_CHANGE_RESOLVED = content => ({
    type: "STATUS_CHANGE_RESOLVED"
});

export const ALERT_TYPE_CHANGE_FROM_DROPDOWN = content => ({
    type: "ALERT_TYPE_CHANGE_FROM_DROPDOWN"
});

export const TYPE_CHANGE_RESOLVED = content => ({
    type: "TYPE_CHANGE_RESOLVED"
});

export const ALERT_PRIORITY_CHANGE_FROM_DROPDOWN = content => ({
    type: "ALERT_PRIORITY_CHANGE_FROM_DROPDOWN"
});

export const PRIORITY_CHANGE_RESOLVED = content => ({
    type: "PRIORITY_CHANGE_RESOLVED"
});

export const RESET_FILTERS = content => ({
    type: "RESET_FILTERS"
});