import React, { Component } from "react";
// import AccountDataService from "../services/account-activities";
// import { Redirect } from "react-router-dom";

import { Redirect } from 'react-router';

import { connect } from 'react-redux';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

import { SET_REPORT_TENANT_VIEWING, ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN, ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS } from "../../features/actions/Is-Logged-Actions";

import Container from 'react-bootstrap/Container';

import Navbar from '../../components/Navbar';

import TimespanTickets from "../Report-Components/Timespan-Tickets";
import OverdueTickets from "../Report-Components/Overdue-Tickets";
import WarningTickets from "../Report-Components/Warning-Tickets";
import {
    Paper,
    Select,
    Button,
    Flex,
    Grid,
    Center
} from '@mantine/core';

import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import ListGroup from 'react-bootstrap/ListGroup';
import { Form } from "react-bootstrap";

import HardwareAssetDataService from '../../services/hardware-asset-activities';

import NumberOfTicketsLast24Hours from "../Dashboard-Widgets/Number-of-Tickets-Last-24-Hours";
import NumberOfTicketsLastWeek from "../Dashboard-Widgets/Number-of-Tickets-Last-Week";

import PercentChangeTicketsLast24Hours from "../Dashboard-Widgets/Percent-Change-Number-Tickets-24-Hours";
import PercentChangeTicketsLastWeek from "../Dashboard-Widgets/Percent-Change-Number-Tickets-Last-Week";

import DashboardDataService from '../../services/dashboard-activities';     // DELETE THIS!!!



class Reports extends Component {

    constructor(props) {
        super(props);

        this.setReportTenant = this.setReportTenant.bind(this);
        this.handleReportTenantChange = this.handleReportTenantChange.bind(this);

        this.state = {
            tenants: [{ id: 0, name_of_tenant: "All" }],     // the list of tenants the asking tech is authorized to see [{id: 1, name_of_tenant: "ProGroup"}]
            selectedTenant: "All",
        }
    }

    componentDidMount() {

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);


                    HardwareAssetDataService.getTenants(token, this.props.loggedStatus.id).then(response => {
                        console.log(response);
                        this.setState({
                            tenants: [...this.state.tenants, ...response.data.results]
                        }, () => {
                            //this.setReportTenant(this.state.tenants);   // update the global state with the tenant selected

                            /************* * DELETE THIS (and import line above) !!!! ***********/

                            // DashboardDataService.getTicketsIndividualMonth(token, this.props.loggedStatus.id, 2, "2023", this.props.loggedStatus.timezone_adjustment_factor).then(response => {
                            //     console.log("RESPONSE FROM GET TICKETS BY MONTH: ", response);
                            // });

                            // DashboardDataService.getTicketsByDayOfWeek(token, this.props.loggedStatus.id, 2, "4", "2023", this.props.loggedStatus.timezone_adjustment_factor).then(response => {
                            //     console.log("RESPONSE FROM GET TICKETS BY DAY OF WEEK: ", response);
                            // });

                            // DashboardDataService.getTicketsByHourOfDay(token, this.props.loggedStatus.id, 2, "0", "4", "2023", this.props.loggedStatus.timezone_adjustment_factor).then(response => {
                            //     console.log("RESPONSE FROM GET TICKETS BY HOUR OF DAY: ", response);
                            // });

                            // DashboardDataService.getTicketsBySLA(token, this.props.loggedStatus.id, 2, "", "", "2022-2024", this.props.loggedStatus.timezone_adjustment_factor).then(response => {
                            //     console.log("RESPONSE FROM GET TICKETS BY SLA: ", response);
                            // });

                            DashboardDataService.getSLAPerformance(token, this.props.loggedStatus.id, 2, "", "", "2023", this.props.loggedStatus.timezone_adjustment_factor).then(response => {
                                console.log("RESPONSE FROM GET SLA PERFORMANCE: ", response);
                            });


                            /************************************************************** **** */

                        });
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

            });

            this.props.UPDATE_ACTIVE_LINK("reports");
        }
    }

    setReportTenant(tenantList) {

        if (tenantList.length > 1) {     // if there was at least one tenant besides All in the list, pick the first one in the list that's not All as the default
            this.props.SET_REPORT_TENANT_VIEWING({ report_tenant_id_selected: tenantList[1].id, report_tenant_name_selected: tenantList[1].name_of_tenant });
            this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN();
            this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS();
        }

        else {      // just use the only available item in the list (the "All" selection)
            this.props.SET_REPORT_TENANT_VIEWING({ report_tenant_id_selected: 0, report_tenant_name_selected: "All" });
            this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN();
            this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS();
        }
    }

    // if user chooses different option from dropdown menu, go ahead and update the global state
    handleReportTenantChange(tenant_name) {
        this.setState({ selectedTenant: tenant_name });

        //console.log(e.target.value);
        if (tenant_name !== this.props.loggedStatus.report_tenant_name_selected) {
            let newTenantId = this.state.tenants.find(o => o.name_of_tenant === tenant_name).id;

            this.props.SET_REPORT_TENANT_VIEWING({ report_tenant_id_selected: newTenantId, report_tenant_name_selected: tenant_name });

            this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN();
            this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS();
        }

        // if(tenant_id !== this.props.loggedStatus.report_tenant_id_selected) {
        //     this.props.SET_REPORT_TENANT_VIEWING({report_tenant_id_selected: tenant_id, report_tenant_name_selected: tenant_name});

        //     this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN();
        //     this.props.ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS();
        // }
    }

    // onChangeName(e) {
    //     this.setState({
    //         name: e.target.value
    //     });
    // }

    // saveAccount() {
    //     var data = {
    //         name: this.state.name
    //     };

    //     if(this.props.loggedStatus.loggedIn){
    //         this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //         this.props.ACCESSTOKEN(token);
    //         AccountDataService.create(data, token)
    //         .then(response => {
    //             this.props.history.push('/accounts');
    //         //console.log(response.data);
    //         // this.setState({
    //         //     redirect: true
    //         // });
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //         });
    //     }



    // AccountDataService.create(data)
    // .then(response => {
    //     //console.log(response.data);
    //     // this.setState({
    //     //     redirect: true
    //     // });
    // })
    // .catch(e => {
    //     console.log(e);
    // });
    //}


    render() {
        if (!this.props.loggedStatus.loggedIn || !this.props.userPermissions.can_process_tickets) {
            return (
                <Redirect to='/' />
            );
        }

        // const { redirect } = this.state;

        // if(redirect) {
        //     return <Redirect to='/accounts'/>   // To redirect back after submission
        // }

        return (
            <>
                <Navbar pageTitle="Reports" />
                <div style={{ paddingTop: "15px" }} />
                <Container fluid>
                    <Paper withBorder p="md" radius="md" display="block" m="5px" style={{ verticalAlign: "top" }}>
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                        >
                            {this.state.tenants && this.state.tenants.map((tenant) => (
                                <Button
                                    h="36px"
                                    variant={this.props.loggedStatus.report_tenant_name_selected === tenant.name_of_tenant ? "filled" : "default"}
                                    onClick={() => this.handleReportTenantChange(tenant.name_of_tenant)}
                                >
                                    {tenant.name_of_tenant}
                                </Button>))}
                        </Flex>
                    </Paper>
                    <Grid>
                        <Grid.Col span={3}>
                            <Center>
                                <NumberOfTicketsLast24Hours onAccessTokenRequest={this.props.onAccessTokenRequest} />
                            </Center>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Center>
                                <PercentChangeTicketsLast24Hours onAccessTokenRequest={this.props.onAccessTokenRequest} />
                            </Center>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Center>
                                <NumberOfTicketsLastWeek onAccessTokenRequest={this.props.onAccessTokenRequest} />
                            </Center>
                        </Grid.Col>
                        <Grid.Col span={3}>
                            <Center>
                                <PercentChangeTicketsLastWeek onAccessTokenRequest={this.props.onAccessTokenRequest} />
                            </Center>
                        </Grid.Col>
                    </Grid>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <TimespanTickets history={this.props.history} />
                    </Paper>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <OverdueTickets history={this.props.history} />
                    </Paper>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <WarningTickets history={this.props.history} />
                    </Paper>
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_LINK, SET_REPORT_TENANT_VIEWING, ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN, ALERT_REPORT_TENANT_CHANGE_FROM_DROPDOWN_WARNING_TICKETS, ACCESSTOKEN, LOGOUT })(Reports);
