import React, { Component } from "react";

import { connect } from 'react-redux';

import { Redirect } from 'react-router';

import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
//import Col from 'react-bootstrap/Col';

import Navbar from '../../components/Navbar';

//import {Link} from 'react-router-dom';

//import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import UserDataService from "../../services/user-activities";
import HardwareAssetDataService from "../../services/hardware-asset-activities";

import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';

import { UPDATE_SELECTED_TENANT_USER_LIST_FILTER, UPDATE_SELECTED_ROLE_USER_LIST_FILTER, UPDATE_SEARCH_TEXT_USER_LIST_FILTER, RESET_USER_LIST_FILTERS } from '../../features/actions/User-List-Filter-Actions';

//import { Container } from 'reactstrap';
//import DataTable from '@bit/adeoy.utils.data-table';
import "bootstrap/dist/css/bootstrap.min.css";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Table,
    //ScrollArea,
    //UnstyledButton,
    //Group,
    Text,
    //Center,
    TextInput,
    rem,
    //keys,
    Button,
    Paper,
    Select,
    //Grid,
    Flex,
    Tooltip
} from '@mantine/core';
import { /* IconSelector, IconChevronDown, IconChevronUp, */ IconSearch, /* IconMenu2, */ IconFilterX } from '@tabler/icons-react';

//import { faSearch, faFilter, faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';

import Form from 'react-bootstrap/Form';

import { UPDATE_ACTIVE_LINK } from '../../features/actions/Active-Nav-Link-Actions';

class CurrentUserList extends Component {
    _scroll = false;
    constructor(props) {
        super(props);
        //this.retrieveTickets = this.retrieveTickets.bind(this);
        //this.setActiveTicket = this.setActiveTicket.bind(this);
        //this.onChangeSearchName = this.onChangeSearchName.bind(this);
        //this.searchName = this.searchName.bind(this);

        this.onChangeSearchText = this.onChangeSearchText.bind(this);

        this.state = {
            searchText: "",
            typingTimeout: 0,       // We need to wait for delay before sending search query to backend
            resetOffsetTimeout: 0,  // handles the delay in changing ticket offset to 0 while user is typing
            show_filter_modal: false,
            show_add_modal: false,
            sort_by: "last_name",       // what column we're sorting on - going to default to last_name - may not need customization
            sort_order: "ASC",      // may not need to allow customization here, but just in case
            rowCount: 0,    // the number of data rows possible based on the filter criteria entered
            offset: 0,      // the offset of number of records we have gotten from the backend - it increases as user triggers new pulls through scrolls (50 units each scroll to bottom)
            allUsersShown: false,   // gets set to true if there are no more users to show based on filter criteria
            scrolled: false,
            userList: [],
            roleList: [{ id: 0, name_of_role: "All" }],        // for displaying in the filter modal - list of roles with their id numbers
            tenant_list: [{ id: 0, name_of_tenant: "All" }]         // for displaying in the filter modal - list of tenants the asking user has access to see
        };
    }

    // gets called after 1.5 second timeout after user types something into the search bar
    // since search criteria has changed, user gets a new list with a starting offset value
    // resetOffset() {
    //     this.setState({
    //         offset: 0
    //     }, () => )
    // }

    // called after a typing timeout to get new records
    // manually calling with offset of 0 (since new pull), and setting offset to 50 afterward
    grabUpdatedUserList() {
        UserDataService.getUserList(this.props.userListFilter.tenant_id_selected_user_list_filter, this.props.loggedStatus.id, this.props.userListFilter.role_id_selected_user_list_filter, 0, this.state.searchText, this.state.sort_by, this.state.sort_order, this.props.loggedStatus.accessToken).then(response => {
            this.setState({
                userList: response.data.results,
                rowCount: response.data.row_count,
                offset: 50
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    onChangeSearchText = (e) => {
        //console.log(e.target.value);
        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
        }

        if (this.state.resetOffsetTimeout) {
            clearTimeout(this.state.resetOffsetTimeout);
        }

        //console.log(e.target.value);

        //const searchText = e.target.value;

        this.setState({
            searchText: e.target.value,

            // resetOffsetTimeout: setTimeout(() => {
            //     this.resetOffset();   // reset the offset in state back to 0 (they have changed their search criteria so new list) - grabbing new tickets w/in this fxn
            // }, 1500),

            typingTimeout: setTimeout(() => {
                // call backend with updated filters for new results (this is also a first pull, so offset will go back to 0)
                this.grabUpdatedUserList();

            }, 1500)
        }, () => this.props.UPDATE_SEARCH_TEXT_USER_LIST_FILTER(e.target.value));
    }

    // called if user clicks the filter icon. opens filter modal
    openFilterModal() {
        this.setState({
            show_filter_modal: true
        });
    }

    closeFilterModal() {
        this.setState({
            show_filter_modal: false
        });
    }

    openAddModal() {
        this.setState({
            show_add_modal: true
        });
    }

    closeAddModal() {
        this.setState({
            show_add_modal: false
        });
    }

    componentDidMount() {
        if(this.props.loggedStatus.loggedIn){
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if(token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // get the user list from backend API
                    
                    UserDataService.getUserList(this.props.userListFilter.tenant_id_selected_user_list_filter, this.props.loggedStatus.id, this.props.userListFilter.role_id_selected_user_list_filter, this.state.offset, this.props.userListFilter.search_text_entered_user_list_filter, this.state.sort_by, this.state.sort_order, this.props.loggedStatus.accessToken).then(response => {
                        console.log("Response from user endpoint was: ", response);
                        this.setState({
                            userList: response.data.results,
                            offset: this.state.offset + 50,
                            searchText: this.props.userListFilter.search_text_entered_user_list_filter,
                            rowCount: response.data.row_count
                        });

                        // get the list of tenants this logged in user is allowed to see for the tenant drop down filter
                        this.getTenantList(this.props.loggedStatus.accessToken, this.props.loggedStatus.id).then(response => {

                            console.log("Tenant list returned was: ", response.results);
                            this.setState({
                                tenant_list: [...this.state.tenant_list, ...response.results]
                            });

                            // get the role list for the filter drop down
                            UserDataService.getRoleList(this.props.loggedStatus.accessToken).then(response => {

                                console.log("Role list response was: ", response);

                                this.setState({
                                    roleList: [...this.state.roleList, ...response.data.results]
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                        })
                        .catch(e => {
                            console.log(e);
                        });
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
            });

            
            this.props.UPDATE_ACTIVE_LINK("adminPanelUserList");  // Tell global state that this is the active page now (controls nav bar highlighting)

            window.addEventListener('scroll', this.handleScroll);

        }
    }

    componentWillUnmount() {

        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = e => {       // Fetch the next results if you reach the bottom of the page
        setTimeout(() => {
            if (!this._scroll) {
                this._scroll = true;

                if ((window.innerHeight + window.pageYOffset) >= document.body.scrollHeight) {  // User at bottom, load more items

                    console.log("Bottom of page reached!");

                    this._isMounted = true;

                    if(this.state.offset >= this.state.rowCount) {      // The number we've tried to return already exceeds the max
                        console.log("We've already gotten all the users");
                        this.setState({ allUsersShown: true });     // Don't need to show more
                    }

                    // if we previously had all users shown and we reset the search criteria, we need to let system know we no longer have all users shown
                    else if (this.state.offset < this.state.rowCount && this.state.allUsersShown) {
                        console.log("This was new filter criteria so reset the allUsersShown value");
                        this.setState({ allUsersShown: false });
                    }

                    if (!this.state.allUsersShown) {   // Only ask for more records if we're not at the end of the list yet

                        console.log("Asking for next set of users here!");

                        UserDataService.getUserList(this.props.userListFilter.tenant_id_selected_user_list_filter, this.props.loggedStatus.id, this.props.userListFilter.role_id_selected_user_list_filter, this.state.offset, this.state.searchText, this.state.sort_by, this.state.sort_order, this.props.loggedStatus.accessToken).then(response => {
                            this.setState(prevState => ({
                                userList: [...prevState.userList, ...response.data.results]
                            }));

                            this.setState({
                                offset: this.state.offset + 50      // we grabbed 50 more users so go ahead and increment this value
                            })
                        })
                        .catch(e=> {
                            console.log(e);
                        });

                        // this.setState({ 
                        //     offset: this.state.offset + 50
                        // });
                    }
                }

                setTimeout(() => {
                    this._scroll = false;
                }, 500);
            }
        }, 500);
    }

    // gets list of tenant names this tech is authorized to see for the filter drop down menu
    getTenantList(token, asker_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTenants(token, asker_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    handleSelectedTenantChange(tenantList, tenantName) {
        // update the global state with the newly selected value

        let newTenantId = tenantList.find(ten => ten.name_of_tenant === tenantName).id;

        this.props.UPDATE_SELECTED_TENANT_USER_LIST_FILTER({id: newTenantId, name: tenantName});

        // get the new data from the backend (this is a new pull, so send an offset of 0 through - and then set to 50 after returning)
        UserDataService.getUserList(newTenantId, this.props.loggedStatus.id, this.props.userListFilter.role_id_selected_user_list_filter, 0, this.props.userListFilter.search_text_entered_user_list_filter, this.state.sort_by, this.state.sort_order, this.props.loggedStatus.accessToken).then(response => {
            
            console.log("Tenant was changed, here's the return result", response.data.results);
            this.setState({
                userList: response.data.results,
                rowCount: response.data.row_count,
                offset: 50      // we just pulled up to 50 items, so update the value in local state
            });
        })
        .catch(e => {
            console.log(e);
        });

        // this.setState({
        //     selected_vendor_id: vendorList.find(ven => ven.name_of_vendor === e.target.value).id,
        //     selected_vendor_name: e.target.value
        // });
    }

    handleSelectedRoleChange(roleList, roleName) {
        // update the global state with the newly selected value

        let newRoleId = roleList.find(role => role.name_of_role === roleName).id;

        this.props.UPDATE_SELECTED_ROLE_USER_LIST_FILTER({id: newRoleId, name: roleName});

        // get the new data from the backend (this is a new pull, so send an offset of 0 through - and then set to 50 after returning)
        UserDataService.getUserList(this.props.userListFilter.tenant_id_selected_user_list_filter, this.props.loggedStatus.id, newRoleId, 0, this.props.userListFilter.search_text_entered_user_list_filter, this.state.sort_by, this.state.sort_order, this.props.loggedStatus.accessToken).then(response => {
            this.setState({
                userList: response.data.results,
                rowCount: response.data.row_count,
                offset: 50      // we just pulled up to 50 items, so update the value in local state
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    resetFilters() {
        this.props.RESET_USER_LIST_FILTERS();

        // Get the user list again by manually sending over the default values
        UserDataService.getUserList(0, this.props.loggedStatus.id, 0, 0, "", this.state.sort_by, this.state.sort_order, this.props.loggedStatus.accessToken).then(response => {
            this.setState({
                userList: response.data.results,
                rowCount: response.data.row_count,
                offset: 50,      // we just pulled up to 50 items, so update the value in local state
                searchText: ""
            });
        })
        .catch(e => {
            console.log(e);
        });
    }


    // componentDidMount() {           // token passed into props from the App.js file
        
    //     if(this.props.loggedStatus.loggedIn){
    //             this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //             this.props.ACCESSTOKEN(token);
    //             this.retrieveTickets(token);    // PASS TOKEN HERE
    //         });
    //     }

    // }

    // // onChangeSearchName(e) {
    // //     const searchName = e.target.value;

    // //     this.setState({
    // //         searchName: searchName
    // //     });
    // // }

    // retrieveTickets(token) {
    //     HardwareTicketDataService.getAll(token)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }

    // setActiveTicket(ticket, index) {
    //     this.setState({
    //         currentTicket: ticket,
    //         currentIndex: index
    //     });
    // }

    // searchName() {
    //     TicketDataService.findByName(this.state.searchName)
    //     .then(response => {
    //         this.setState({
    //             tickets: response.data.results
    //         });
    //         //console.log(response.data.results);
    //     })
    //     .catch(e => {
    //         console.log(e);
    //     });
    // }


    render() {

        if(!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_users && !this.props.userPermissions.can_edit_users && !this.props.userPermissions.can_add_users && !this.props.userPermissions.can_delete_users)) {
            return (
                <Redirect to='/'/>
            );
        }

        //const { searchText } = this.state;

        //const { searchName, accounts, currentAccount, currentIndex } = this.state;

        // const { tickets } = this.state;

        // const columns = [
        //     { title: "Date Created", data: "date_created" },
        //     { title: "Issue Text", data: "issue_text" }
        // ];

        // const click = (row) => {

        //     // SEND ACCESS TOKEN TO BACKEND TO GET REQUESTED INFO
            
        //     this.props.history.push({
        //         pathname: "/tickets/" + row.id,
        //         state: {
        //             accessToken: this.props.loggedStatus.accessToken
        //         }
        //     })
            
        // };

        // const data = [
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        //     {lastName: "Kelley", firstName: "Maevi", role: "Awesome"},
        // ]

        // const rows = data.map((data) => (
        //     <Table.Tr key={`userRow${data.firstName}${data.lastName}`} onClick={() => console.log("row clicked!")}>
        //         <Table.Td w="33%">{data.lastName}</Table.Td>
        //         <Table.Td w="33%">{data.firstName}</Table.Td>
        //         <Table.Td w="33%">{data.role}</Table.Td>
        //     </Table.Tr>
        // ));

        const rows = this.state.userList.map((user) => (
            <Table.Tr key={`userRow${user.id}`} onClick={() => this.props.history.push({pathname: "/userProfile/" + user.id})}>
                <Table.Td w="25%">{user.last_name}</Table.Td>
                <Table.Td w="25%">{user.first_name}</Table.Td>
                <Table.Td w="25%">{user.email_address}</Table.Td>
                <Table.Td w="25%">{user.name_of_role}</Table.Td>
            </Table.Tr>
        ));

        return (
            <>
            <Navbar pageTitle={"Current User List / Assignments"} />
                <div className="container mt-3" style={{paddingTop: "15px"}}></div>
                <Container fluid >
                    <Paper withBorder p="md" radius="md" display="block" m="5px" ref={this.scrollerRef} onScroll={this.handleAssetTableScroll}>
                        <Flex
                            justify="flex-start"
                            align="flex-start"
                            direction="row"
                            gap="md"
                            h="90px"
                        >
                            <Button style={{marginTop: "31px"}} h="36px" onClick={() => this.openAddModal()}>+ Add New User</Button>
                            <Select
                                label="Company"
                                placeholder="Pick value"
                                data={this.state.tenant_list.map((tenant) => tenant.name_of_tenant)}
                                value={this.props.userListFilter.tenant_name_selected_user_list_filter}
                                onChange={(s) => this.handleSelectedTenantChange(this.state.tenant_list, s)}
                                allowDeselect={false}
                            />
                            <Select
                                label="Role"
                                placeholder="Pick value"
                                data={this.state.roleList.map((role) => role.name_of_role)}
                                value={this.props.userListFilter.role_name_selected_user_list_filter}
                                onChange={(s) => this.handleSelectedRoleChange(this.state.roleList, s)}
                                allowDeselect={false}
                            />
                            {/* <Button h="36px" onClick={() => this.openFilterModal()}>Filter</Button> */}
                            <div style={{marginTop: "31px"}}><Tooltip label="Reset Filters"><Button variant="light" color="red" onClick={() => this.resetFilters()}><IconFilterX /></Button></Tooltip></div>
                        </Flex>
                        <TextInput
                            placeholder="Search"
                            mb="md"
                            leftSection={<IconSearch style={{ width: rem(16), height: rem(16) }} stroke={1.5} />}
                            value={this.state.searchText}
                            onChange={this.onChangeSearchText}
                        />
                        <Table miw={700}>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Table.Td fw="bold" w="25%">Last Name</Table.Td>
                                    <Table.Td fw="bold" w="25%">First Name</Table.Td>
                                    <Table.Td fw="bold" w="25%">Email</Table.Td>
                                    <Table.Td fw="bold" w="25%">Role</Table.Td>
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>
                        <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                            <Table.Tbody>
                                {rows.length > 0 ? (
                                    rows
                                ) : (
                                    <Table.Tr>
                                        <Table.Td>
                                            <Text fw={500} ta="center">
                                                Nothing found
                                            </Text>
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>
                    </Paper>
                </Container>

                {/* This modal is for filtering contents of the user asset table */}
                <Modal
                        show={this.state.show_filter_modal}
                        onHide={() => this.closeFilterModal()}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        centered
                >
                    <Form>
                        <Modal.Header closeButton>
                                <Modal.Title>
                                    <div style={{textAlign: "center", cursor: "default"}} className="gothamNarrowFont">Apply Filters to Assets</div>
                                </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Group controlId="filterByTenant" className="mb-3">
                                <Form.Label>Company</Form.Label>
                                <Form.Control value={this.props.userListFilter.tenant_name_selected_user_list_filter} onChange={(e) => this.handleSelectedTenantChange(this.state.tenant_list, e)} as="select">
                                    {this.state.tenant_list && this.state.tenant_list.map((tenant) => (
                                        <option key={`tenant_${tenant.id}`}>{tenant.name_of_tenant}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="filterByRole" className="mb-3">
                                <Form.Label>Role</Form.Label>
                                <Form.Control value={this.props.userListFilter.role_name_selected_user_list_filter} onChange={(e) => this.handleSelectedRoleChange(this.state.roleList, e)}as="select">
                                        {this.state.roleList && this.state.roleList.map((role) => (
                                            <option key={`role_${role.id}`}>{role.name_of_role}</option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                        </Modal.Body>
                        <Modal.Footer>
                            {/* <div style={{paddingRight: "15px"}}>
                                <Button variant="secondary" onClick={() => this.closeFilterModal()} className="gothamNarrowFont">Cancel</Button>
                            </div> */}
                            <div style={{paddingRight: "15px"}}>     
                                <Button id="newDepartmentFormOkButton" onClick= {() => {this.closeFilterModal()}} className="gothamNarrowFont">Ok</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for adding a new user to the system */}
                <Modal
                        show={this.state.show_add_modal}
                        onHide={() => this.closeAddModal()}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{textAlign: "center", cursor: "default"}} className="gothamNarrowFont">How to Add a New User</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="gothamNarrowFont">To add a new user, add their information to your company's Azure Active Directory. Then have that user log into Cumulus with their credentials, and have them confirm their name to submit their account for creation. You can then come here to edit their details further as an admin.</div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div>     
                                <Button id="newDepartmentFormOkButton" onClick= {() => {this.closeAddModal()}} className="gothamNarrowFont">Ok</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </>
            
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions,
        userListFilter: state.userListFilter
    };
}

export default connect(mapStateToProps, {UPDATE_ACTIVE_LINK, ACCESSTOKEN, LOGOUT, UPDATE_SELECTED_TENANT_USER_LIST_FILTER, UPDATE_SELECTED_ROLE_USER_LIST_FILTER, UPDATE_SEARCH_TEXT_USER_LIST_FILTER, RESET_USER_LIST_FILTERS})(CurrentUserList);