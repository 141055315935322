import React, { Component } from 'react';
//import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
//import Card from 'react-bootstrap/Card';
//import Nav from 'react-bootstrap/Nav';
import {
    Table,
    Text,
    keys,
    Group,
    UnstyledButton,
    rem,
    Center
} from '@mantine/core';

import classes from '../TableSort.module.css';
import { IconSelector, IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import HardwareTicketDataService from '../../services/hardware-ticket-activities';

import { REPORT_TENANT_CHANGE_RESOLVED } from '../../features/actions/Is-Logged-Actions';


// const chartData = {
//     labels: ['January', 'February', 'March',
//              'April', 'May'],
//     datasets: [
//       {
//         label: 'Rainfall',
//         backgroundColor: [
//           '#B21F00',
//           '#C9DE00',
//           '#2FDE00',
//           '#00A6B4',
//           '#6800B4'
//         ],
//         hoverBackgroundColor: [
//         '#501800',
//         '#4B5000',
//         '#175000',
//         '#003350',
//         '#35014F'
//         ],
//         data: [65, 59, 80, 81, 56]
//       }
//     ]
//   }

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        keys(data[0]).some((key) => item[key].toLowerCase().includes(query))
    );
}

function sortData(
    data,
    payload
) {
    const { sortBy } = payload;

    if (!sortBy) {
        return filterData(data, payload.search);
    }

    return filterData(
        [...data].sort((a, b) => {
            if (payload.reversed) {
                return b[sortBy].localeCompare(a[sortBy]);
            }

            return a[sortBy].localeCompare(b[sortBy]);
        }),
        payload.search
    );
}

class OverdueTickets extends Component {


    constructor(props) {
        super(props);

        this.getOverdueTickets = this.getOverdueTickets.bind(this);
        this.goToHardwareTicketDetails = this.goToHardwareTicketDetails.bind(this);
        this.goToHoldingTicketDetails = this.goToHoldingTicketDetails.bind(this);

        this.state = {
            overdueTickets: [],
            itemCount: 0
        };

    }

    setSorting(field) {
        const reversed = field === this.state.sortBy ? !this.state.reverseSortDirection : false;
        const search = this.state.search;
        this.setState({
            reverseSortDirection: reversed,
            sortBy: field,
            sortedData: sortData(this.state.data, { sortBy: field, reversed, search })
        });
    };

    componentDidMount() {
        this.getOverdueTickets();
    }


    componentDidUpdate() {
        if (this.props.loggedStatus.report_tenant_change_detected) {
            this.props.REPORT_TENANT_CHANGE_RESOLVED();

            // get the new list of overdue tickets
            this.getOverdueTickets();
        }
    }


    // use the global state value for the selected tenant from the report component to get the necessary overdue ticket info
    getOverdueTickets() {
        HardwareTicketDataService.getOverdueTickets(this.props.loggedStatus.report_tenant_id_selected, this.props.loggedStatus.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken)
            .then(response => {
                console.log("Overdue ticket list returned: ", response);
                this.setState({
                    overdueTickets: response.data.results,
                    itemCount: response.data.rowCount
                });
            })
            .catch(e => {
                console.log(e);
            });
    }

    goToHardwareTicketDetails(id) {
        this.props.history.push(`/hardwareTickets/${id}`);
    }

    goToHoldingTicketDetails(id) {
        this.props.history.push(`/clarifyTicket/${id}`);
    }


    render() {
        const rows = this.state.overdueTickets.map((ticket) => {
            if (ticket.source === "Hardware") {
                return <Table.Tr key={`hardware_${ticket.id}`} onClick={() => this.goToHardwareTicketDetails(ticket.id)}>
                    <Table.Td w="14%">{`# ${ticket.id}`}</Table.Td>
                    <Table.Td w="14%">{ticket.sla_name ? ticket.sla_name : "Not Yet Set"}</Table.Td>
                    <Table.Td w="16%">{ticket.asker_name}</Table.Td>
                    <Table.Td w="16%">{ticket.assigned_tech_name ? ticket.assigned_tech_name : "Unassigned"}</Table.Td>
                    <Table.Td w="10%">{ticket.time_of_response === null ? "Response" : "Resolution"}</Table.Td>
                    <Table.Td w="15%">{ticket.formatted_date_time_created}</Table.Td>
                    <Table.Td w="15%">{ticket.late_text ? ticket.late_text : "TBD"}</Table.Td>
                </Table.Tr>
            }

            else {
                return <Table.Tr key={`unclarified_${ticket.id}`} onClick={() => this.goToHoldingTicketDetails(ticket.id)}>
                    <Table.Td w="14%">{`Temp# ${ticket.id}`}</Table.Td>
                    <Table.Td w="14%">{ticket.sla_name ? ticket.sla_name : "Not Yet Set"}</Table.Td>
                    <Table.Td w="16%">{ticket.asker_name}</Table.Td>
                    <Table.Td w="16%">{ticket.assigned_tech_name ? ticket.assigned_tech_name : "Unassigned"}</Table.Td>
                    <Table.Td w="10%">{ticket.time_of_response === null ? "Response" : "Clarification"}</Table.Td>
                    <Table.Td w="15%">{ticket.formatted_date_time_created}</Table.Td>
                    <Table.Td w="15%">{ticket.late_text ? ticket.late_text : "TBD"}</Table.Td>
                </Table.Tr>
            }
        })


        return (
            <>
                <div /*style={{textAlign:"center"}}*/ className="gothamNarrowFont">{`Overdue Tickets ( ${this.state.itemCount} )`}</div>
                <Table>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td fw="bold" w="14%">Title ID</Table.Td>
                            <Table.Td fw="bold" w="14%">Priority</Table.Td>
                            <Table.Td fw="bold" w="16%">Requester</Table.Td>
                            <Table.Td fw="bold" w="16%">Assigned To</Table.Td>
                            <Table.Td fw="bold" w="10%">Awaiting</Table.Td>
                            <Table.Td fw="bold" w="15%">Time Created</Table.Td>
                            <Table.Td fw="bold" w="15%">Overdue By</Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
                <Table highlightOnHover="true" style={{ cursor: "pointer" }}>
                    <Table.Tbody>
                        {rows.length > 0 ? (
                            rows
                        ) : (
                            <Table.Tr>
                                <Table.Td>
                                    <Text fw={500} ta="center">
                                        Nothing found
                                    </Text>
                                </Table.Td>
                            </Table.Tr>
                        )}
                    </Table.Tbody>
                </Table>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav    // Use global state to control which link is highlighted
    };
}

export default connect(mapStateToProps, { REPORT_TENANT_CHANGE_RESOLVED })(OverdueTickets);