// this widget displays a modal with further details about the ticket submitter (like job title and site name)

import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

//import { useDisclosure } from '@mantine/hooks';

import {
    Group,
    HoverCard,
    Table
} from '@mantine/core';

import {
    IconInfoCircle
} from '@tabler/icons-react';

import UserDataService from '../../services/user-activities';

function TicketSubmitterDetails(props) {

    const submitterId = props.submitterId;
    const accessToken = props.token;

    const [userDetails, setUserDetails] = useState([]);

    React.useEffect(() => {
        console.log("Props received by submitter details: ", props.submitterId);
        console.log("Starting value of userDetails = ", userDetails);

        //const tempDetails = userDetails;


        // if(/* props.submitterId !== null && props.token !== null && userDetails !== undefined &&  */tempDetails.length === 0) {
            UserDataService.getTicketSubmitterDetails(props.submitterId, props.token).then(response => {
                console.log("Response returned from get submitter details endpoint: ", response);

                setUserDetails(response.data.results);      // this will be array [{job_title: "Manager", name_of_site: "Irvine Office"}]
            })
            .catch(e => {
                console.log(e);
            });
        // }
        
    }, [submitterId, accessToken, props.submitterId, props.token/* , userDetails, setUserDetails */]);

    //const [opened, { open, close }] = useDisclosure(false);


    return (
        <>
            <Group justify="left">
                <HoverCard width={400} withArrow arrowSize={20} position="top" offset={30}>
                    <HoverCard.Target>
                        <IconInfoCircle 
                            className="submitterDetailsIcon"
                            size="20px"
                        />
                    </HoverCard.Target>
                    <HoverCard.Dropdown className="affectedDevicesHoverCardBackground">
                        <Table withRowBorders={false} style={{cursor: "default"}}>
                            <Table.Tbody>
                                <Table.Tr>
                                    <Table.Td style={{width: "30%"}} className="affectedDevicesHoverCardText">Job Title</Table.Td>
                                    {userDetails && userDetails.length > 0 && userDetails[0].job_title !== null ?
                                        <Table.Td style={{width: "70%"}} className="roleYesText">{userDetails[0].job_title}</Table.Td>
                                        :
                                        <Table.Td style={{width: "70%"}} className="roleYesText">Unset</Table.Td>
                                    }
                                </Table.Tr>
                                <Table.Tr>
                                    <Table.Td style={{width: "30%"}} className="affectedDevicesHoverCardText">Location</Table.Td>
                                    {userDetails && userDetails.length > 0 && userDetails[0].name_of_site !== null ?
                                        <Table.Td style={{width: "70%"}} className="roleYesText">{userDetails[0].name_of_site}</Table.Td>
                                        :
                                        <Table.Td style={{width: "70%"}} className="roleYesText">Unset</Table.Td>
                                    }
                                </Table.Tr>
                            </Table.Tbody>
                        </Table>
                    </HoverCard.Dropdown>
                </HoverCard>
            </Group>
        </>
    );

}

export default TicketSubmitterDetails;