import http from "../http-common";

class SettingsDataService {
        // Returns the bool value of if user wants to get emails or not
        getEmailsStatus(user_id, token) {
            return http.get(`/settings/${user_id}/emailStatus`, {headers: {Authorization: 'Bearer ' + token}});
        }
    
        // changes  disabling or enabling of emails based on user setting for email status for hardware tickets 
        changeEmailsStatus(user_id, data, token) {
            return http.put(`/settings/${user_id}/updateEmailStatus`, data, {headers: {Authorization: 'Bearer ' + token}});
        }
    
        // changes  disabling or enabling of emails based on user setting for email status for software tickets 
        changeSoftwareEmailsStatus(user_id, data, token) {
            return http.put(`/settings/${user_id}/updateSoftwareEmailStatus`, data, {headers: {Authorization: 'Bearer ' + token}});
        }
}


export default new SettingsDataService();
