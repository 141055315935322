import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';

import { LOGIN } from '../features/actions/Is-Logged-Actions';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
//import isLoggedReducer from '../features/reducers/Is-Logged-Reducers';

import ReactVivus from 'react-vivus';

import splash from "../images/Cumulus_Logo.svg";

import logo from "../images/Cumulus_Logo-Black.png";

//let loggedIn;

class LandingPage extends Component {
    
    constructor(props) {
       super(props); 
       this.handleLogin = this.handleLogin.bind(this);

       this.state = {
        loggedIn: false
       };
    }

    // updateLoginStatus (username) {
    //     this.props.dispatch({ type: "LOGIN", payload: username});
    // }

    handleLogin() {
        this.props.onLogin()
        .then((usernameReturned) => {
            if(usernameReturned !== null && usernameReturned !== "") {
                this.props.LOGIN(usernameReturned);
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }
        //new Vivus("serverRoomImage", { delay: 50, duration: 200, file: splash, pathTimingFunction: Vivus.EASE });
    
    render() {
        //console.log(this.props.loggedStatus);
        if(this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/userHome'/>
            );
        }
        
        else {
            //console.log(this.props.loggedStatus);
            var color2 = "21, 139, 68";
            var color1 = "0, 76, 102";
            var color3 = "70, 80, 90";
            return (
                <>
                    <div id="topBar" style={{ width: "100%", height: "90px", backgroundColor: "white", top: 0, left: 0 }}>
                        <img src={logo} alt="Progroup Symbol" style={{ padding: "20px", height: "90px" }} />
                        <div id="landingPageLoginButtonContainer">
                            <Button id="landingPageLoginButton" variant="outline-secondary" onClick={() => { this.handleLogin() }} className="gothamNarrowFont">Login</Button>
                        </div>
                    </div>
                    <div id="serverRoomImageContainer" style={{ height: "65vmin", zIndex: -1, background: "linear-gradient(rgb(" + color3 + ") -17.19%, rgba(" + color3 + ", 0) 111.4%) center top / 100% 100% no-repeat, linear-gradient(86.97deg, rgba(" + color1 + ", 1.0) 0%, rgba(" + color2 + ", 1.0) 100%) center top / 100% 100% no-repeat, linear-gradient(0deg, transparent 0%, rgb(" + color3 + ") 0%)" }}>
                        <ReactVivus
                            id="serverRoomImage"
                            option={{
                              file: splash,
                              pathTimingFunction: 'EASE',
                              delay: 50,
                              duration: 200
                            }}
                            style={{ position: "relative", margin: "auto", height: "100%", maxWidth: "50vmax" }}
                        />
                        <div id="infobox" style={{ position: "relative", margin: "auto", width: "130vmin", maxWidth: "95vw", height: "40vh", background: "white", top: "-7vh" }}>
                            <h3 style={{ textAlign: "center", paddingTop: "20px", paddingBottom: "20px", color: "#808080" }} className="gothamNarrowFont">A Better Way To Manage Your Company's IT Assets and Tickets</h3>
                            <div style={{ paddingTop: "10px", maxWidth: "100%", backgroundColor: "rgba(211,211,211, 1)" }}>
                                <CardDeck style={{ width: "100%" }}>
                                    <Card className="landingPageInfoCard">
                                        <Card.Body>
                                            <Card.Title style={{ textAlign: "center", color: "#505050" }} className="gothamNarrowFont">Store Your Assets</Card.Title>
                                            <Card.Text style={{ color: "#505050" }} className="gothamNarrowLightFont">
                                                Enter your company's IT asset information into a personal and secure database. Access this information anywhere
                                                you have an internet connection.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className="landingPageInfoCard">
                                        <Card.Body>
                                            <Card.Title style={{ textAlign: "center", color: "#505050" }} className="gothamNarrowFont">Tie Tickets to Assets</Card.Title>
                                            <Card.Text style={{ color: "#505050" }} className="gothamNarrowLightFont">
                                                When submitting a ticket, you can tie your issue to the specific asset within your database.
                                                This gives your IT team data about the asset involved, from the manufacturer, to the model, even
                                                all the way to the asset's specific serial number.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                    <Card className="landingPageInfoCard">
                                        <Card.Body>
                                            <Card.Title style={{ textAlign: "center", color: "#505050" }} className="gothamNarrowFont">Gets More Useful the More You Use It</Card.Title>
                                            <Card.Text style={{ color: "#505050" }} className="gothamNarrowLightFont">
                                                When tickets are submitted, they are immediately included in your company's personalized knowledgebase
                                                which can be searched by others in your organization. Prevent duplicate tickets by having employees search the knowledgebase
                                                before submitting their ticket.
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </CardDeck>
                            </div>
                        </div>
                    </div>
                    {/* <div className="boarder-descp-login" style={ {textAlign: "center"} } >
                        <h1 className="land-header">Find A Better Way to Organize Assets</h1>
                        <button className="gradient-button gradient-button-2" onClick={() => {this.handleLogin()}}>Login</button>
                    </div>
    
                    <div className="clr"></div>
    
                    <div id="land-page-main-block-descp">
                        <h3>Heading</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        </p>
                    </div>
    
                    <div id="landpge-sidebar-ex">
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                        </p>
                    </div>  */}
                </>
            );
        }
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged
    };
}

export default connect(mapStateToProps, {LOGIN})(LandingPage);

// import React from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { logIn, selectLogInStatus } from '../features/isLogged/isLoggedSlice';
// import { Redirect } from 'react-router';
// //import Button from 'react-bootstrap/Button';

// // export let loggedIn;

// export function LandingPage(props) {
//     const loggedIn = useSelector(selectLogInStatus);
//     //const username = useSelector(selectUsername);
//     const dispatch = useDispatch();


//     function handleLogin() {
//         props.onLogin()
//         .then((usernameReturned) => {
//             dispatch(logIn(usernameReturned));
//         })
//         .catch((error) => {
//             console.log(error);
//         });
//     }

//     if(loggedIn) {
//         return (
//             <Redirect to='/userHome'/>
//         );
//     }

//     else {
//         return (
//             <>
//                 <div className="boarder-descp-login" style={ {textAlign: "center"} } >
//                     <h1 className="land-header">Find A Better Way to Organize Assets</h1>
//                     <button className="gradient-button gradient-button-2" onClick={() => {handleLogin()}}>Login</button>
//                     {/* <Button class="btn btn-primary" onClick={handleLogin}>Login</Button> */}
//                 </div>

//                 <div className="clr"></div>

//                 <div id="land-page-main-block-descp">
//                     <h3>Heading</h3>
//                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
//                     tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
//                     </p>
//                 </div>

//                 <div id="landpge-sidebar-ex">
//                     <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
//                     tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
//                     </p>
//                 </div> 
//             </>
//         );
//     }

// };

