import React, { Component } from "react";
import HardwareTicketDataService from "../services/hardware-ticket-activities";
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { ACCESSTOKEN, LOGOUT } from '../features/actions/Is-Logged-Actions';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Navbar from '../components/Navbar';

import { cleanHTML } from '../html-cleaner';
import parse from 'html-react-parser';

//import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import {
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    Table,
    rem,
    Modal,
    keys,
    Paper,
    Select,
    Grid,
    Flex,
} from '@mantine/core';
import RichTextInput from './RichTextInput';
import { Link } from 'react-router-dom';

import RequesterSelector from './Ticket-Requester-Selector';

//import oneDriveConfig from "../onedrive-info";
//import * as msal from "@azure/msal-browser";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import MAX_ATTACHMENT_SIZE from "../max-attachment-size";

//import onedriveAxios from "../onedrive-axios";

import { UPDATE_ACTIVE_REQUESTER, REQUESTER_CHANGE_RESOLVED } from '../features/actions/Ticket-Requester-Actions';
import { removeTags } from "../remove-html-tags";

class CreateHardwareTicket extends Component {
    constructor(props) {
        super(props);
        // this.onChangeName = this.onChangeName.bind(this);
        // this.saveAccount = this.saveAccount.bind(this);

        this.state = {
            assets_assigned: [],
            issue_types: [],
            ticket_slas: [],    // these values will populate the "Priority" drop down menu [{id: 0, sla_name: "Priority 1", target_resolution_time_unit: "Hours", target_resolution_time_value: 4, target_response_time_unit: "Business Days", target_response_time_value: 2}]
            site_name: "",
            site_id: 0,
            requester_name: "",
            asset_id_selected: 0,   // Will change based on dropdown menu choice
            asset_name_selected: "",
            ticket_category_id_selected: 0, // Will change based on dropdown menu choice
            ticket_category_selected: "",
            ticket_priority_selected: "Low",
            ticket_sla_id_selected: 0,      // Will change based on "Priority" dropdown menu choice
            ticket_sla_name_selected: "",   // Will change based on "Priority" dropdown menu choice
            entered_title_text: "",
            typing_timeout: 0,  // controls when the backend search based on typed title occurs
            access_denied: false,  // If they don't have a site, or they don't have hardware assets, they can't ask a question
            edit_ticket: false,
            HardwareTicketAsset: "", //used as default value on load and when changing 
            HardwareTicketTypeIssue: "", //used as default value on load and when changing

            attachmentFileNameList: [],

            showSaving: false,       // controls whether the saving modal is displayed or not
            showStaleTokenError: false,         // controls whether the stale token error modal appears

            issue_text: "",      // the value the user has typed into the issue text form field
            showNoResponseError: false,  // gets set to true if form submission failed because user did not enter anything for issue text
            showImageDetectedError: false   // gets set to true if system detects an image pasted into the response text box
            // HardwareTicketPriority: "" //used as default value on load and when changing 
        };

        this.goBackToDashboard = this.goBackToDashboard.bind(this);
        this.onSelectTicketCategory = this.onSelectTicketCategory.bind(this);
        this.onSelectAssetInvolved = this.onSelectAssetInvolved.bind(this);
        this.setEditTicket = this.setEditTicket.bind(this);
        this.setEditAssetId = this.setEditAssetId.bind(this);
        this.setEditTicketCategoryId = this.setEditTicketCategoryId.bind(this);
        this.handleEditAssetChange = this.handleEditAssetChange.bind(this);
        this.handleEditTypeIssueChange = this.handleEditTypeIssueChange.bind(this);

        this.hideStaleTokenError = this.hideStaleTokenError.bind(this);

        this.handleAttachmentUploadChange = this.handleAttachmentUploadChange.bind(this);
        this.handleUploadButtonClick = this.handleUploadButtonClick.bind(this);

        this.handleAttachmentDelete = this.handleAttachmentDelete.bind(this);

        this.onSelectTicketSla = this.onSelectTicketSla.bind(this);
        //this.handleUploadFileToOneDrive = this.handleUploadFileToOneDrive.bind(this);
    }


    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {

                    this.props.ACCESSTOKEN(token);

                    // if we're in edit mode, first grab the details of the current ticket (trying to see requester id to later get their name and asset involved primarily)

                    if (this.props.location.state && this.props.location.state.enableEditHardwareTicket) {        // if this is an EDIT, we need to pull in specifc details about the requester
                        console.log("Going to try to get ticket details");
                        console.log("HELLO THERE!");

                        HardwareTicketDataService.get(this.props.location.state.HardwareTicketId, this.props.loggedStatus.timezone_adjustment_factor, token)
                            .then(response => {
                                console.log("Specific ticket data: ", response);
                                this.setState({
                                    requester_name: response.data.results[0].name_of_requester,
                                    ticket_sla_id_selected: response.data.results[0].ticket_sla_id,
                                    issue_text: this.props.location.state.HardwareTicketIssue,
                                    entered_title_text: this.props.location.state.HardwareTicketTitle
                                });

                                this.props.UPDATE_ACTIVE_REQUESTER({ tenantId: response.data.results[0].tenant_id, requesterId: response.data.results[0].requester_id, requesterName: response.data.results[0].name_of_requester, requesterEmail: response.data.results[0].requester_email });

                                HardwareTicketDataService.getNewTicketFormData(response.data.results[0].requester_id, token)
                                    .then(response => {
                                        //console.log("Value of the global setting for get hardware emails is currently: ", this.props.loggedStatus.getEmails);
                                        if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                                            /* this.setState({
                                                site_name: "Unknown",
                                                access_denied: true
                                            }); */
                                            console.log("Error retrieving site data");
                                        }

                                        else {
                                            this.setState({
                                                site_name: response.data.siteData[0].name_of_site,
                                                site_id: response.data.siteData[0].site_id
                                            });
                                        }

                                        if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                                            /* this.setState({
                                                access_denied: true
                                            }); */
                                            this.setState({
                                                assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                                asset_id_selected: 1,
                                                asset_name_selected: "Not Applicable"
                                            });

                                        }

                                        else {

                                            let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                                            let assignedAssets = response.data.assignedHardwareAssets;

                                            this.setState({
                                                assets_assigned: [...basicArray, ...assignedAssets],
                                                asset_id_selected: 1,
                                                asset_name_selected: "Not Applicable"
                                            }, () => {
                                                if (this.props.location.state) {
                                                    this.setEditAssetId(this.props.location.state.HardwareTicketAsset);
                                                }
                                                // assets_assigned: response.data.assignedHardwareAssets,
                                                // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                                                // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                                            });
                                        }

                                        if (response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                                            console.log("Error getting ticket categories from database");
                                            /* this.setState({
                                                access_denied: true
                                            }); */
                                        }

                                        else {
                                            this.setState({
                                                issue_types: response.data.ticketCategories,
                                                ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                                                ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                                            }, () => {
                                                if (this.props.location.state) {
                                                    this.setEditTicketCategoryId(this.props.location.state.HardwareTicketCategoryTypeIssue);
                                                }
                                            });
                                        }

                                        // check for the priorities array here!
                                        if (response.data.ticketSlas && response.data.ticketSlas.length === 0) {     // Company has no slas in their system
                                            console.log("This company either has no SLAs or there was an error retrieving them");
                                        }

                                        else if (response.data.ticketSlas) {
                                            console.log("TICKET SLAS RETURNED: ", response.data.ticketSlas);
                                            this.setState({
                                                ticket_slas: response.data.ticketSlas,
                                                // set the active selected values here!
                                                //asset_id_selected: (this.state.assets_assigned.find(o => o.name_of_asset === edit_asset_name)).id
                                                ticket_sla_name_selected: (response.data.ticketSlas.find(s => s.id === this.state.ticket_sla_id_selected)).sla_name

                                            });
                                        }

                                        //console.log(this.state);
                                    })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                    }

                    else {      // If we reach here it means that this a create ticket operation, NOT edit

                        this.props.UPDATE_ACTIVE_REQUESTER({ tenantId: this.props.loggedStatus.primary_tenant_id, requesterId: this.props.loggedStatus.id, requesterName: this.props.loggedStatus.fullName, requesterEmail: this.props.loggedStatus.email });

                        HardwareTicketDataService.getNewTicketFormData(this.props.loggedStatus.id, token)
                            .then(response => {
                                console.log("New ticket form data response = ", response);
                                if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                                    /* this.setState({
                                        site_name: "Unknown",
                                        access_denied: true
                                    }); */
                                    console.log("Error retrieving site data");
                                }

                                else {
                                    this.setState({
                                        site_name: response.data.siteData[0].name_of_site,
                                        site_id: response.data.siteData[0].site_id
                                    });
                                }

                                if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                                    /* this.setState({
                                        access_denied: true
                                    }); */
                                    this.setState({
                                        assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                        asset_id_selected: 1,
                                        asset_name_selected: "Not Applicable"
                                    });

                                }

                                else {

                                    let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                                    let assignedAssets = response.data.assignedHardwareAssets;

                                    this.setState({
                                        assets_assigned: [...basicArray, ...assignedAssets],
                                        asset_id_selected: 1,
                                        asset_name_selected: "Not Applicable"
                                    }, () => {
                                        if (this.props.location.state) {
                                            this.setEditAssetId(this.props.location.state.HardwareTicketAsset);
                                        }
                                        // assets_assigned: response.data.assignedHardwareAssets,
                                        // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                                        // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                                    });
                                }

                                if (response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                                    console.log("Error getting ticket categories from database");
                                    /* this.setState({
                                        access_denied: true
                                    }); */
                                }

                                else {
                                    this.setState({
                                        issue_types: response.data.ticketCategories,
                                        ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                                        ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                                    }, () => {
                                        if (this.props.location.state) {
                                            this.setEditTicketCategoryId(this.props.location.state.HardwareTicketCategoryTypeIssue);
                                        }
                                    });
                                }

                                // check for priority data here!
                                if (response.data.ticketSlas && response.data.ticketSlas.length === 0) {     // Company has no slas in their system
                                    console.log("This company either has no SLAs or there was an error retrieving them");
                                }

                                else if (response.data.ticketSlas) {
                                    this.setState({
                                        ticket_slas: response.data.ticketSlas,
                                        // set the active selected values here!
                                        ticket_sla_id_selected: response.data.ticketSlas[0].id,         // landed on page for first time, so no need to change this until user does
                                        ticket_sla_name_selected: response.data.ticketSlas[0].sla_name

                                    });
                                }


                                //console.log(this.state);
                            })
                            .catch(e => {
                                console.log(e);
                            });

                    }
                }
            })

            // console.log(this.props.location.state.HardwareTicketCategoryTypeIssue);
            if (this.props.location.state) {
                this.setEditTicket(this.props.location.state.enableEditHardwareTicket, this.props.location.state.HardwareTicketAsset, this.props.location.state.HardwareTicketCategoryTypeIssue, this.props.location.state.HardwareTicketPriority);
            }

        }
    }

    componentDidUpdate() {
        // if user clicked a new requester, immediately let global state know the main component received message
        if (this.props.ticketRequester.requester_change_detected) {
            this.props.REQUESTER_CHANGE_RESOLVED();

            // call on backend to get the assigned assets for this newly selected user here
            console.log("Main component detected that a new requester was selected");

            if (this.props.location.state && this.props.location.state.enableEditHardwareTicket) {   // if this is on the edit ticket screen
                HardwareTicketDataService.getNewTicketFormData(this.props.ticketRequester.requester_id_selected, this.props.loggedStatus.accessToken)
                    .then(response => {
                        console.log("Data returned after edit ticket requester switch: ", response);
                        if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                            /* this.setState({
                                site_name: "Unknown",
                                access_denied: true
                            }); */
                            console.log("Error retrieving site data");
                        }

                        else {
                            this.setState({
                                site_name: response.data.siteData[0].name_of_site,
                                site_id: response.data.siteData[0].site_id
                            });
                        }

                        if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                            /* this.setState({
                                access_denied: true
                            }); */
                            this.setState({
                                assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                asset_id_selected: 1,
                                asset_name_selected: "Not Applicable"
                            });

                        }

                        else {

                            let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                            let assignedAssets = response.data.assignedHardwareAssets;

                            this.setState({
                                assets_assigned: [...basicArray, ...assignedAssets],
                                asset_id_selected: 1,
                                asset_name_selected: "Not Applicable"
                            }, () => {
                                if (this.props.location.state) {
                                    this.setEditAssetId("Not Applicable");
                                }
                                // assets_assigned: response.data.assignedHardwareAssets,
                                // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                                // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                            });
                        }

                        // if(response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                        //     console.log("Error getting ticket categories from database");
                        //     /* this.setState({
                        //         access_denied: true
                        //     }); */
                        // }

                        // else {
                        //     this.setState({
                        //         issue_types: response.data.ticketCategories,
                        //         ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                        //         ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                        //     }, () => {
                        //         if ( this.props.location.state ){
                        //             this.setEditTicketCategoryId(this.props.location.state.HardwareTicketCategoryTypeIssue);
                        //         }
                        //     });
                        // }
                        //console.log(this.state);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }

            else {      // we are on the create ticket screen

                HardwareTicketDataService.getNewTicketFormData(this.props.ticketRequester.requester_id_selected, this.props.loggedStatus.accessToken)
                    .then(response => {
                        if (response.data.siteData.length === 0) {   // User doesn't have their site assigned yet
                            /* this.setState({
                                site_name: "Unknown",
                                access_denied: true
                            }); */
                            console.log("Error retrieving site data");
                        }

                        else {
                            this.setState({
                                site_name: response.data.siteData[0].name_of_site,
                                site_id: response.data.siteData[0].site_id
                            });
                        }

                        if (response.data.assignedHardwareAssets.length === 0) { // User has no hardware assets assigned to them
                            /* this.setState({
                                access_denied: true
                            }); */
                            this.setState({
                                assets_assigned: [{ id: 1, name_of_asset: "Not Applicable" }],    // this is hardcoded in the database and is available to all users
                                asset_id_selected: 1,
                                asset_name_selected: "Not Applicable"
                            });

                        }

                        else {

                            let basicArray = [{ id: 1, name_of_asset: "Not Applicable" }];
                            let assignedAssets = response.data.assignedHardwareAssets;

                            this.setState({
                                assets_assigned: [...basicArray, ...assignedAssets],
                                asset_id_selected: 1,
                                asset_name_selected: "Not Applicable"
                            }/*, () => {
                                if ( this.props.location.state ){
                                    this.setEditAssetId(this.props.location.state.HardwareTicketAsset);
                                }
                            // assets_assigned: response.data.assignedHardwareAssets,
                            // asset_id_selected: response.data.assignedHardwareAssets[0].id,  // This will account for the case where user just picks first value without clicking
                            // asset_name_selected: response.data.assignedHardwareAssets[0].name_of_asset  // This will account for the case where user just picks first value without clicking
                        }*/);
                        }

                        // if(response.data.ticketCategories.length === 0) {   // Company has no ticket categories in their system
                        //     console.log("Error getting ticket categories from database");
                        //     /* this.setState({
                        //         access_denied: true
                        //     }); */
                        // }

                        // else {
                        //     this.setState({
                        //         issue_types: response.data.ticketCategories,
                        //         ticket_category_id_selected: response.data.ticketCategories[0].id,  // This will account for the case where user just picks first value without clicking
                        //         ticket_category_selected: response.data.ticketCategories[0].name_of_category // This will account for the case where user just picks first value without clicking
                        //     }, () => {
                        //         if ( this.props.location.state ){
                        //             this.setEditTicketCategoryId(this.props.location.state.HardwareTicketCategoryTypeIssue);
                        //         }
                        //     });
                        // }
                        //console.log(this.state);
                    })
                    .catch(e => {
                        console.log(e);
                    });

            }
        }
    }

    onSelectAssetInvolved(asset_id, asset_name) {
        this.setState({
            asset_id_selected: asset_id,
            asset_name_selected: asset_name,
            HardwareTicketAsset: asset_name
        });
    }

    onSelectTicketCategory(category_id, category_name) {
        this.setState({
            ticket_category_id_selected: category_id,
            ticket_category_selected: category_name
        });
    }

    onSelectTicketSla(id, sla_name) {
        console.log("Hello from onSelectTicketSla!");
        this.setState({
            ticket_sla_id_selected: id,
            ticket_sla_name_selected: sla_name
        });
    }

    onChangeTicketTitle = (e) => {

        if (this.state.typing_timeout) {
            clearTimeout(this.state.typing_timeout);
        }

        this.setState({
            entered_title_text: e.target.value,
            typing_timeout: setTimeout(() => { // wait some time before sending the typed text to the backend (prevent having a bunch of calls)
                HardwareTicketDataService.findRelatedTickets(this.state.entered_title_text, this.props.loggedStatus.accessToken, this.props.loggedStatus.id)
                    .then(response => {
                        console.log(response);
                    })
                    .catch(e => {
                        console.log(e);
                    });
            }, 1500)
        });

    }


    createHardwareTicket = e => {

        e.preventDefault();


        if (this.state.issue_text !== null && removeTags(this.state.issue_text) !== "") {

            // as long as you don't find the image tag you're ok to continue, otherwise, they tried to paste in an image into their direct reply so we're going to reject it
            if (!this.state.issue_text.toLowerCase().includes("<img")) {

                // show the saving modal
                this.setState({
                    showSaving: true
                });

                //e.preventDefault();
                const formDataObj = new FormData(e.target);


                for (let z = 0; z < this.state.attachmentFileNameList.length; z++) {
                    formDataObj.append('attachmentList', this.state.attachmentFileNameList[z]);
                }
                formDataObj.append('getEmails', this.props.loggedStatus.getEmails);
                formDataObj.append('selectedSlaId', this.state.ticket_sla_id_selected);
                formDataObj.append('issueText', this.state.issue_text);
                formDataObj.append('ticketTitle', this.state.entered_title_text);
                console.log("FORM DATA BEFORE PROCESSING: ", formDataObj);
                //let formDataObj = Object.fromEntries(formData.entries());
                //formDataObj["getEmails"] = this.props.loggedStatus.getEmails;
                //formDataObj["attachmentList"] = this.state.attachmentFileNameList;
                console.log("HERE'S THE FORM DATA BEFORE SENDING: ", formDataObj);
                //console.log(this.props.loggedStatus.getEmails);
                HardwareTicketDataService.createHardwareTicket(formDataObj, this.props.loggedStatus.accessToken)
                    .then(response => {
                        this.props.history.push('/userHome');   // return user back to user home upon success
                    })
                    .catch(e => {
                        console.log(e);

                        // Show stale token modal HERE !!!
                        this.setState({
                            showSaving: false,
                            showStaleTokenError: true
                        });
                    })

                console.log(formDataObj);
            }

            else {  // caught case where image was pasted directly into reply form, so reject

                this.setState({
                    showImageDetectedError: true,
                    showNoResponseError: false
                });
            }

        }

        else {
            this.setState({
                showNoResponseError: true,
                showImageDetectedError: false
            });
        }
    }

    editHardwareTicket = e => {
        e.preventDefault();

        if (this.state.issue_text !== null && removeTags(this.state.issue_text) !== "") {

            const formData = new FormData(e.target);
            formData.append('selectedSlaId', this.state.ticket_sla_id_selected);
            formData.append('issueText', this.state.issue_text);
            formData.append('editorId', this.props.loggedStatus.id);
            formData.append('ticketTitle', this.state.entered_title_text);
            let formDataObj = Object.fromEntries(formData.entries());

            console.log(formDataObj);
            //console.log(this.props.loggedStatus.getEmails);
            HardwareTicketDataService.editHardwareTicket(this.props.location.state.HardwareTicketId, formDataObj, this.props.loggedStatus.accessToken)
                .then(response => {
                    this.props.history.push("/hardwareTickets/" + this.props.location.state.HardwareTicketId);   // return user back to user home upon success
                })
                .catch(e => {
                    console.log(e);

                    // Show stale token modal HERE !!! NOTE: don't need to set showSaving to false here
                    this.setState({
                        //showSaving: false,
                        showStaleTokenError: true
                    });
                })

            console.log(formDataObj);

        }

        else {
            this.setState({
                showNoResponseError: true
            });
        }
    }

    goBackToDashboard() {
        this.props.history.push('/userHome');
    }

    hideStaleTokenError() {
        this.setState({
            showStaleTokenError: false
        });
    }

    setEditTicket(editTicket, HardwareTicket_Asset, HardwareTicket_TypeIssue, HardwareTicket_Priority) {
        this.setState({
            edit_ticket: editTicket,
            HardwareTicketAsset: HardwareTicket_Asset,
            asset_name_selected: HardwareTicket_Asset,
            ticket_category_selected: HardwareTicket_TypeIssue,
            ticket_priority_selected: HardwareTicket_Priority,
            HardwareTicketPriority: HardwareTicket_Priority,
            // asset_id_selected: (this.state.assets_assigned.find(o => o.name_of_asset === HardwareTicket_Asset)).id,
            HardwareTicketTypeIssue: HardwareTicket_TypeIssue
        });
    }

    setEditAssetId(edit_asset_name) {
        this.setState({
            asset_id_selected: (this.state.assets_assigned.find(o => o.name_of_asset === edit_asset_name)).id
        });
    }

    setEditTicketCategoryId(edit_issue_name) {
        this.setState({
            ticket_category_id_selected: (this.state.issue_types.find(o => o.name_of_category === edit_issue_name)).id
        });
    }

    handleEditAssetChange(assets_assigned, asset_name) {
        console.log("Fruit Selected!!");
        console.log(asset_name);
        console.log(assets_assigned);
        console.log(assets_assigned.indexOf(assets_assigned.find(o => o.name_of_asset === asset_name)) + 1);
        this.setState({
            HardwareTicketAsset: asset_name,
            asset_id_selected: (assets_assigned.find(o => o.name_of_asset === asset_name)).id,
            asset_name_selected: asset_name
        });
    }

    handleEditTypeIssueChange(issue_types, category_name) {
        console.log("Veggies Selected!!");
        console.log(category_name);
        console.log(issue_types);
        console.log(issue_types.find(o => o.name_of_category === category_name));
        this.setState({
            HardwareTicketTypeIssue: category_name,
            ticket_category_id_selected: (issue_types.find(o => o.name_of_category === category_name)).id,
            ticket_category_selected: category_name
        });
    }

    handleTicketPriorityChange(ticket_slas, sla_name) {
        console.log("Priority Changed!");
        this.setState({
            // HardwareTicketPriority: e.target.value,
            ticket_sla_name_selected: sla_name,
            ticket_sla_id_selected: (ticket_slas.find(o => o.sla_name === sla_name)).id
        });
    }


    handleAttachmentUploadChange(e) {
        console.log(e.target.files)

        try {

            let newAttachmentList = this.state.attachmentFileNameList;

            for (let i = 0; i < e.target.files.length; i++) {

                let file_extension = e.target.files[i].name.substring(e.target.files[i].name.lastIndexOf('.') + 1, e.target.files[i].name.length) || e.target.files[i].name;

                if (e.target.files[i].size <= MAX_ATTACHMENT_SIZE && (file_extension.toLowerCase() === "jpeg" || file_extension.toLowerCase() === "png" || file_extension.toLowerCase() === "jpg" || file_extension.toLowerCase() === "bmp" || file_extension.toLowerCase() === "heic" || file_extension.toLowerCase() === "pdf" || file_extension.toLowerCase() === "doc" || file_extension.toLowerCase() === "docx" || file_extension.toLowerCase() === "xls" || file_extension.toLowerCase() === "xlsx" || file_extension.toLowerCase() === "ppt" || file_extension.toLowerCase() === "pptx" || file_extension.toLowerCase() === "dwg" || file_extension.toLowerCase() === "txt")) {

                    console.log(e.target.files[i].name);

                    let filename = e.target.files[i].name

                    newAttachmentList.push(e.target.files[i]);

                    // let reader = new FileReader();
                    // reader.readAsDataURL(e.target.files[i]);
                    // reader.onloadend = (event) => {
                    //     // The contents of the BLOB are in reader.result:
                    //     console.log(reader.result);
                    //     newAttachmentList.push({filename: filename, contents: reader.result});
                    // }

                }

                else {
                    console.log("This file was either too big or an incorrect file type");
                }

            }

            this.setState({
                attachmentFileNameList: newAttachmentList
            }, () => console.log(this.state.attachmentFileNameList));

        }

        catch {
            console.log("No files seen")
        }
    }

    handleUploadButtonClick() {
        document.getElementById('hiddenUploadButton').click();
    }


    // this fxn removes the specified attachment from the list of attachments to send to backend for saving
    handleAttachmentDelete(fileInfo) {
        console.log(fileInfo);

        let newAttachmentList = [];

        let foundDuplicate = false;     // if there's another instance of that one in the list, only remove one of them

        for (let i = 0; i < this.state.attachmentFileNameList.length; i++) {
            if (!foundDuplicate && this.state.attachmentFileNameList[i].lastModified === fileInfo.lastModified && this.state.attachmentFileNameList[i].name === fileInfo.name && this.state.attachmentFileNameList[i].size === fileInfo.size && this.state.attachmentFileNameList[i].type === fileInfo.type) {
                // if you found the match of the one to delete, don't include it in the new attachment array
                foundDuplicate = true;
                continue;
            }

            else {
                newAttachmentList.push(this.state.attachmentFileNameList[i]);
            }
        }

        this.setState({
            attachmentFileNameList: newAttachmentList
        });
    }

    onChangeIssueText = (value) => {
        if (value !== this.state.issue_text) {
            this.setState({
                issue_text: value
            });
        }
    }


    // handleUploadFileToOneDrive(attachmentList) {

    //     for(let i=0; i < attachmentList.length; i++) {
    //         let reader = new FileReader();
    //         reader.readAsBinaryString(attachmentList[i]);
    //         reader.onloadend = (event) => {
    //             // The contents of the BLOB are in reader.result:
    //             console.log(reader.result);
    //         }
    //     }
    // }


    // onChangeName(e) {
    //     this.setState({
    //         name: e.target.value
    //     });
    // }

    // saveAccount() {
    //     var data = {
    //         name: this.state.name
    //     };

    //     if(this.props.loggedStatus.loggedIn){
    //         this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //         this.props.ACCESSTOKEN(token);
    //         AccountDataService.create(data, token)
    //         .then(response => {
    //             this.props.history.push('/accounts');
    //         //console.log(response.data);
    //         // this.setState({
    //         //     redirect: true
    //         // });
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //         });
    //     }



    //     // AccountDataService.create(data)
    //     // .then(response => {
    //     //     //console.log(response.data);
    //     //     // this.setState({
    //     //     //     redirect: true
    //     //     // });
    //     // })
    //     // .catch(e => {
    //     //     console.log(e);
    //     // });
    // }

    // <Form.Group as={Col} controlId="formGridState">
    //   <Form.Label>State</Form.Label>
    //   <Form.Control as="select" defaultValue="Choose...">
    //     <option>Choose...</option>
    //     <option>...</option>
    //   </Form.Control>
    // </Form.Group>


    render() {
        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }

        const { assets_assigned, issue_types, ticket_slas } = this.state;
        // console.log(this.props.location.state.HardwareTicketId);
        // console.log(this.props.location.state.HardwareTicketTitle);
        // console.log(this.props.location.state.HardwareTicketIssue);
        // console.log(this.props.location.state.HardwareTicketCategoryTypeIssue);
        // console.log(this.props.location.state.HardwareTicketAsset);
        // console.log(this.props.location.state.HardwareTicketPriority)
        //console.log(this.state.ticket_priority_selected);
        // console.log(this.props.location.state.enableEditHardwareTicket);
        // this.setState({
        //     edit_ticket: this.props.location.state.enableEditHardwareTicket ? true : false
        // });


        // const { redirect } = this.state;

        // if(redirect) {
        //     return <Redirect to='/accounts'/>   // To redirect back after submission
        // }
        // console.log((issue_types.find(o => o.name_of_category === this.state.HardwareTicketTypeIssue)).id);

        return (
            <>
                <Navbar pageTitle={this.state.edit_ticket ? "Edit Ticket" : "Create Ticket"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                {/* <div className="miniKnowledgebaseWrapper showHideMiniKnowledgebase"> */}
                {/* <p className="suggestedPostsTitle gothamNarrowFont">Your topic might be similar to...</p> */}
                {/* <span className="miniKnowledgebase showHideMiniKnowledgebase"> */}
                {/* <p className="suggestedPostsTitle gothamNarrowFont">Suggested Related Posts</p> */}
                {/* <Table className="miniKnowledgebaseTable" borderless>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Monitor will not turn on</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Desktop Hardware</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">My monitor will not turn on this morning.</div> */}
                {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Contrast on monitor appears off</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Desktop Hardware</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">There seems to be too much red tinge in the current display.</div> */}
                {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Monitor warranty is expiring</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Warranty</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">We need to discuss whether to renew the warranty for Steve's monitor. The system shows that it is set to expire in March. Who do I call...(more)</div> */}
                {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">Display on monitor is cracked</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Desktop Hardware</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">Just received a new monitor from IT, but it arrived with a cracked display.</div> */}
                {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                {/* </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="ticketTitleText gothamNarrowFont"><Link to="" className="ticketTitleText">How to setup dual monitors?</Link></div>
                                            <div className="ticketCategoryName gothamNarrowFont">Desktop Hardware</div>
                                            <div className="miniKnowledgebaseIssuePreview gothamNarrowLightFont">I'd like to have two working monitors in my setup. How do I do that?</div> */}
                {/* <div className="ticketIssuePreview gothamNarrowLightFont">{ticket.issue_text.length > 150 ? `${ticket.issue_text.substring(0, 150)}...(more)` : ticket.issue_text}</div> */}
                {/* </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </span>
                    </div> */}
                <Container fluid>
                    <Paper withBorder p="md" radius="md" m="5px">
                        <Row style={{ marginTop: "40px" }}>
                            <Col>
                                <div style={{ textAlign: "left" }}>
                                    {this.state.edit_ticket ?
                                        this.props.userPermissions.can_edit_tickets ?
                                            <div className="gothamNarrowFont">Requester: <RequesterSelector /></div>
                                            :
                                            <div className="gothamNarrowFont">Requester: <b>{this.state.requester_name}</b></div>
                                        :
                                        this.props.userPermissions.can_edit_tickets ?
                                            <div className="gothamNarrowFont">Requester: <RequesterSelector /></div>
                                            :
                                            <div className="gothamNarrowFont">Requester: <b>{this.props.loggedStatus.fullName}</b></div>
                                    }
                                </div>
                            </Col>
                            <Col md={{ span: 5 }}>
                                <div style={{ textAlign: "right" }}>
                                    <p className="gothamNarrowFont">Site: <b>{this.state.site_name}</b></p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ marginTop: "10px" }}>
                                    <Form onSubmit={this.state.edit_ticket ? this.editHardwareTicket : this.createHardwareTicket}>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridRequesterId">
                                                <Form.Control type="hidden" value={this.props.ticketRequester.requester_id_selected} name="requesterId" />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridSiteId">
                                                <Form.Control type="hidden" value={this.state.site_id} name="siteId" />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridAssetId">
                                                <Form.Control type="hidden" value={this.state.asset_id_selected} name="assetId" />
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridCategoryId">
                                                <Form.Control type="hidden" value={this.state.ticket_category_id_selected} name="categoryId" />
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridAssetInvolved">
                                                {this.state.edit_ticket ?
                                                    <Select
                                                        label="Asset Involved"
                                                        placeholder="Pick value"
                                                        data={assets_assigned.map((asset) => (asset.name_of_asset))}
                                                        //defaultValue={"Unassigned"}
                                                        value={this.state.HardwareTicketAsset}
                                                        onChange={(s) => {
                                                            var asset = assets_assigned.find((e) => e.name_of_asset === s);
                                                            this.handleEditAssetChange(assets_assigned, s);
                                                            if (asset) {
                                                                this.onSelectAssetInvolved(asset.id, asset.name_of_asset);
                                                            }
                                                        }}
                                                        allowDeselect={false}
                                                    /> :
                                                    <Select
                                                        label="Asset Involved"
                                                        placeholder="Pick value"
                                                        data={assets_assigned.map((asset) => (asset.name_of_asset))}
                                                        //defaultValue={"Unassigned"}
                                                        value={this.state.asset_name_selected}
                                                        onChange={(s) => {
                                                            var asset = assets_assigned.find((e) => e.name_of_asset === s);
                                                            this.handleEditAssetChange(assets_assigned, s);
                                                            if (asset) {
                                                                this.onSelectAssetInvolved(asset.id, asset.name_of_asset);
                                                            }
                                                        }}
                                                        allowDeselect={false}
                                                    />
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridTypeOfIssue">
                                                {/* value={this.state.edit_ticket ? this.state.HardwareTicketTypeIssue : ""} onChange={ (e) => this.onSelectTicketCategory(issue_types.indexOf(e.target.value), e.target.value)} */}
                                                {this.state.edit_ticket ?
                                                    <Select
                                                        label="Type of Issue"
                                                        placeholder="Pick value"
                                                        data={issue_types.map((category) => (category.name_of_category))}
                                                        //defaultValue={"Unassigned"}
                                                        value={this.state.HardwareTicketTypeIssue}
                                                        onChange={(s) => {
                                                            var category = issue_types.find((e) => e.name_of_category === s);
                                                            this.handleEditTypeIssueChange(issue_types, s);
                                                            if (category) {
                                                                this.onSelectTicketCategory(category.id, category.name_of_category);
                                                            }
                                                        }}
                                                        allowDeselect={false}
                                                    /> :
                                                    <Select
                                                        label="Type of Issue"
                                                        placeholder="Pick value"
                                                        data={issue_types.map((category) => (category.name_of_category))}
                                                        //defaultValue={"Unassigned"}
                                                        //value={this.state.HardwareTicketTypeIssue}
                                                        value={this.state.ticket_category_selected}
                                                        onChange={(s) => {
                                                            var category = issue_types.find((e) => e.name_of_category === s);
                                                            this.handleEditTypeIssueChange(issue_types, s);
                                                            if (category) {
                                                                this.onSelectTicketCategory(category.id, category.name_of_category);
                                                            }
                                                        }}
                                                        allowDeselect={false}
                                                    />
                                                }
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formGridPriority">
                                                {this.state.edit_ticket ?
                                                    <Select
                                                        label="Priority"
                                                        placeholder="Pick value"
                                                        data={ticket_slas.map((sla) => (sla.sla_name))}
                                                        //defaultValue={"Unassigned"}
                                                        value={this.state.ticket_sla_name_selected}
                                                        onChange={(s) => {
                                                            var sla = ticket_slas.find((e) => e.sla_name === s);
                                                            this.handleTicketPriorityChange(ticket_slas, s);
                                                            if (sla) {
                                                                this.onSelectTicketSla(sla.id, sla.sla_name);
                                                            }
                                                        }}
                                                        allowDeselect={false}
                                                    /> :
                                                    <Select
                                                        label="Priority"
                                                        placeholder="Pick value"
                                                        data={ticket_slas.map((sla) => (sla.sla_name))}
                                                        //defaultValue={"Unassigned"}
                                                        value={this.state.ticket_sla_name_selected}
                                                        onChange={(s) => {
                                                            var sla = ticket_slas.find((e) => e.sla_name === s);
                                                            this.handleTicketPriorityChange(ticket_slas, s);
                                                            if (sla) {
                                                                this.onSelectTicketSla(sla.id, sla.sla_name);
                                                            }
                                                        }}
                                                        allowDeselect={false}
                                                    />
                                                }

                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridTicketTitle">
                                                <TextInput label="Title" placeholder="Type ticket title here" defaultValue={this.state.edit_ticket ? this.props.location.state.HardwareTicketTitle : ""} onChange={this.onChangeTicketTitle} required />
                                                <Form.Text className="text-muted gothamNarrowLightFont">
                                                    Note: This will be used as the email subject sent to the technician.
                                                </Form.Text>
                                            </Form.Group>
                                        </Form.Row>
                                        <Form.Row>
                                            <Form.Group as={Col} controlId="formGridIssueText">
                                                <Form.Label className="gothamNarrowFont">Describe Your Issue</Form.Label>
                                                {this.state.edit_ticket ?
                                                    <div data-text-editor="name">
                                                        <RichTextInput defaultValue={cleanHTML(this.state.issue_text)} readOnly onChange={e => this.onChangeIssueText(e)} />
                                                        {/* <ReactQuill id="reactQuillBoxDisabled" bounds={`[data-text-editor="name"]`} modules={{toolbar: false}} value={this.state.issue_text} readOnly onChange={e => this.onChangeIssueText(e)} />  */}
                                                    </div>
                                                    :
                                                    <div data-text-editor="name">
                                                        <RichTextInput defaultValue={cleanHTML(this.state.issue_text)} onChange={e => this.onChangeIssueText(e)} />
                                                    </div>

                                                }
                                                {/* <Form.Control as="textarea" style={{height: "150px"}} placeholder="Type issue text here" defaultValue={this.state.edit_ticket ? this.props.location.state.HardwareTicketIssue : ""} name="issueText" className="gothamNarrowLightFont" required/> */}
                                                {this.state.edit_ticket ?
                                                    <Form.Text className="text-muted gothamNarrowLightFont">
                                                        Note: This can only be changed if you have special permissions.
                                                    </Form.Text>
                                                    :
                                                    <Form.Text className="text-muted gothamNarrowLightFont">
                                                        Note: This will be used as the email body sent to the technician.
                                                    </Form.Text>

                                                }
                                                {this.state.showNoResponseError ?
                                                    <Form.Text style={{ textAlign: "center", color: "red" }}>Error: No Response Provided</Form.Text>
                                                    :
                                                    undefined
                                                }
                                            </Form.Group>
                                        </Form.Row>
                                        {this.state.edit_ticket ? undefined :
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="attachmentUpload">
                                                    <Button variant="info" onClick={() => this.handleUploadButtonClick()}>Upload Attachments</Button>
                                                    {/* <Form.Label className="gothamNarrowLightFont">Attachment(s) (optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, or .txt and less than 50MB)</Form.Label> */}
                                                    <Form.Text className="gothamNarrowLightFont">(optional - must be .jpg, .jpeg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, or .txt and less than 50MB)</Form.Text>
                                                    <Form.Control className="gothamNarrowLightFont" type="file" accept=".jpeg, .jpg, .png, .bmp, .heic, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .dwg, .txt" multiple onChange={(e) => this.handleAttachmentUploadChange(e)} style={{ display: "none" }} id="hiddenUploadButton" />
                                                </Form.Group>

                                            </Form.Row>
                                        }
                                        {this.state.attachmentFileNameList && this.state.attachmentFileNameList.length > 0 ?
                                            <div style={{ paddingBottom: "20px" }}>
                                                <div className="gothamNarrowFont">Included Attachments:</div>
                                                {this.state.attachmentFileNameList && this.state.attachmentFileNameList.map(file => (
                                                    <div key={`${file.name}`} className="gothamNarrowLightFont">
                                                        <span style={{ paddingRight: "10px" }}><FontAwesomeIcon style={{ color: "rgba(139, 24, 27, 1)" }} icon={faTimes} onClick={() => this.handleAttachmentDelete(file)} /></span>{file.name}
                                                    </div>
                                                ))

                                                }
                                            </div>
                                            :
                                            undefined
                                        }

                                        {this.state.showImageDetectedError ?
                                            <Form.Text style={{ textAlign: "center", color: "red" }}>Error: Image detected in response. Please remove the image from the text box and use the Upload Attachments button below</Form.Text>
                                            :
                                            undefined
                                        }
                                        <Form.Row>
                                            <div style={{ margin: "auto" }}>
                                                <span style={{ marginRight: "200px" }}>
                                                    <Link to={this.state.edit_ticket ? ("/hardwareTickets/" + this.props.location.state.HardwareTicketId) : "/hardwareTickets"}>
                                                        <Button variant="secondary" className="knowledgebaseButton, gothamNarrowFont">
                                                            Cancel
                                                        </Button>
                                                    </Link>
                                                </span>
                                                {/* <div style={{textAlign: "center"}}> */}
                                                <span>
                                                    <Button variant="primary" type="submit" className="gothamNarrowFont">
                                                        Submit
                                                    </Button>
                                                </span>
                                            </div>
                                            {/* </div> */}
                                        </Form.Row>
                                    </Form>
                                </div>
                            </Col>
                        </Row>
                    </Paper>
                </Container>

                <Modal
                    opened={this.state.access_denied}
                    onClose={() => { this.setState({ access_denied: false }) }}
                    size="xl"
                    centered
                    title="You can't ask a question yet"
                >
                    <Modal.Body>
                        <p className="gothamNarrowLightFont">You either haven't specified your work site, your administrator has not input any possible ticket categories, or there are no hardware assets assigned to you.</p>
                        <p className="gothamNarrowLightFont">To specify your work site, please visit the "Update My Account Information" page.</p>
                        <p className="gothamNarrowLightFont">Otherwise, ask an administrator to assign your hardware assets in the system, and/or check that ticket categories have been input.</p>
                        <p className="gothamNarrowLightFont">Click the button below to return to the dashboard.</p>
                    </Modal.Body>
                    <Button variant="primary" onClick={() => this.goBackToDashboard()} className="gothamNarrowFont">Understood</Button>
                </Modal>

                {/* This modal is for the stale token warning */}
                <Modal
                    opened={this.state.showStaleTokenError}
                    onClose={() => this.hideStaleTokenError()}
                    size="xl"
                    centered
                    title="Operation failed due to stale token"
                >
                    <Modal.Body>
                        <span>
                            <p className="gothamNarrowLightFont">Error: The token used to authenticate with Microsoft has become stale.</p>
                            <p className="gothamNarrowLightFont">Please copy any information needed on the form, refresh the page, and try submitting again.</p>
                        </span>
                    </Modal.Body>
                    <Button variant="primary" onClick={() => this.hideStaleTokenError()} className="gothamNarrowFont">Ok</Button>
                </Modal>

                {/* This modal is for the loading screen */}
                <Modal
                    opened={this.state.showSaving}
                    onClose={() => { this.setState({ showSaving: false }) }}
                    size="xl"
                    centered
                >
                    <Modal.Body>
                        <div className="gothamNarrowLightFont" style={{ cursor: "default", textAlign: "center" }}>Saving... Please wait.</div>
                    </Modal.Body>

                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        ticketRequester: state.ticketRequester,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, UPDATE_ACTIVE_REQUESTER, REQUESTER_CHANGE_RESOLVED, LOGOUT })(CreateHardwareTicket);