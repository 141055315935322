import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import Navbar from '../../components/Navbar';
import {
    Paper,
} from '@mantine/core';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import HardwareAssetDataService from '../../services/hardware-asset-activities';

class EditHardwareAsset extends Component {

    constructor(props) {
        super(props);

        this.toggleAddFormAssignedAssetInputVisible = this.toggleAddFormAssignedAssetInputVisible.bind(this);
        this.closeAddPage = this.closeAddPage.bind(this);
        this.closeCategoryModal = this.closeCategoryModal.bind(this);
        this.openCategoryModal = this.openCategoryModal.bind(this);
        this.closeTypeModal = this.closeTypeModal.bind(this);
        this.openTypeModal = this.openTypeModal.bind(this);
        this.closeModelModal = this.closeModelModal.bind(this);
        this.openModelModal = this.openModelModal.bind(this);
        this.closeManufacturerModal = this.closeManufacturerModal.bind(this);
        this.openManufacturerModal = this.openManufacturerModal.bind(this);
        this.closeSiteModal = this.closeSiteModal.bind(this);
        this.openSiteModal = this.openSiteModal.bind(this);
        this.closeLocationModal = this.closeLocationModal.bind(this);
        this.openLocationModal = this.openLocationModal.bind(this);
        this.closeVendorModal = this.closeVendorModal.bind(this);
        this.openVendorModal = this.openVendorModal.bind(this);

        this.getHardwareAssetTypes = this.getHardwareAssetTypes.bind(this);
        this.getUserList = this.getUserList.bind(this);
        this.getTenantList = this.getTenantList.bind(this);
        this.getCategories = this.getCategories.bind(this);
        this.getManufacturers = this.getManufacturers.bind(this);
        this.getModels = this.getModels.bind(this);
        this.getSites = this.getSites.bind(this);
        this.getLocations = this.getLocations.bind(this);
        this.getAssignableAssets = this.getAssignableAssets.bind(this);


        this.handleIsCoreChange = this.handleIsCoreChange.bind(this);
        this.handleTenantChange = this.handleTenantChange.bind(this);
        this.handleAssetNameEnteredChange = this.handleAssetNameEnteredChange.bind(this);
        this.handleSelectedCategoryChange = this.handleSelectedCategoryChange.bind(this);
        this.handleSelectedTypeChange = this.handleSelectedTypeChange.bind(this);
        this.handleSelectedModelChange = this.handleSelectedModelChange.bind(this);
        this.handleSelectedSiteChange = this.handleSelectedSiteChange.bind(this);
        this.handleSelectedLocationChange = this.handleSelectedLocationChange.bind(this);
        this.handleSelectedAssignedPersonChange = this.handleSelectedAssignedPersonChange.bind(this);
        this.handleSelectedVendorChange = this.handleSelectedVendorChange.bind(this);
        this.handleAssetTagEnteredChange = this.handleAssetTagEnteredChange.bind(this);
        this.handleSerialNumberEnteredChange = this.handleSerialNumberEnteredChange.bind(this);
        this.handleBarcodeEnteredChange = this.handleBarcodeEnteredChange.bind(this);
        this.handleIPAddressEnteredChange = this.handleIPAddressEnteredChange.bind(this);
        this.handleMACAddressEnteredChange = this.handleMACAddressEnteredChange.bind(this);
        this.handleRAMEnteredChange = this.handleRAMEnteredChange.bind(this);
        this.handleCPUEnteredChange = this.handleCPUEnteredChange.bind(this);
        this.handleAcquisitionDateChange = this.handleAcquisitionDateChange.bind(this);
        this.handleWebsiteEnteredChange = this.handleWebsiteEnteredChange.bind(this);
        this.handleWarrantyStartDateChange = this.handleWarrantyStartDateChange.bind(this);
        this.handleWarrantyEndDateChange = this.handleWarrantyEndDateChange.bind(this);
        this.handleLeaseStartDateChange = this.handleLeaseStartDateChange.bind(this);
        this.handleLeaseEndDateChange = this.handleLeaseEndDateChange.bind(this);
        this.handleTrackAssetChange = this.handleTrackAssetChange.bind(this);
        this.handleIsPeripheralChange = this.handleIsPeripheralChange.bind(this);
        this.handleAssignedAssetCategoryChange = this.handleAssignedAssetCategoryChange.bind(this);
        this.handleAssignedAssetTypeChange = this.handleAssignedAssetTypeChange.bind(this);


        this.submitEditAssetForm = this.submitEditAssetForm.bind(this);


        this.handleCreateCategoryNameChange = this.handleCreateCategoryNameChange.bind(this);
        this.submitCreateCategoryForm = this.submitCreateCategoryForm.bind(this);

        this.handleCreateTypeNameChange = this.handleCreateTypeNameChange.bind(this);
        this.submitCreateTypeForm = this.submitCreateTypeForm.bind(this);

        this.handleCreateModelNameChange = this.handleCreateModelNameChange.bind(this);
        this.handleCreateModelTypeChange = this.handleCreateModelTypeChange.bind(this);
        this.handleCreateModelCostChange = this.handleCreateModelCostChange.bind(this);
        this.handleCreateModelManufacturerChange = this.handleCreateModelManufacturerChange.bind(this);
        this.submitCreateModelForm = this.submitCreateModelForm.bind(this);

        this.handleCreateManufacturerNameChange = this.handleCreateManufacturerNameChange.bind(this);
        this.handleCreateManufacturerTypeChange = this.handleCreateManufacturerTypeChange.bind(this);
        this.handleCreateManufacturerPhoneChange = this.handleCreateManufacturerPhoneChange.bind(this);
        this.submitCreateManufacturerForm = this.submitCreateManufacturerForm.bind(this);

        this.handleCreateSiteNameChange = this.handleCreateSiteNameChange.bind(this);
        this.handleCreateSiteStreetAddressChange = this.handleCreateSiteStreetAddressChange.bind(this);
        this.handleCreateSiteCityChange = this.handleCreateSiteCityChange.bind(this);
        this.handleCreateSiteStateChange = this.handleCreateSiteStateChange.bind(this);
        this.handleCreateSiteZipCodeChange = this.handleCreateSiteZipCodeChange.bind(this);
        this.submitCreateSiteForm = this.submitCreateSiteForm.bind(this);

        this.handleCreateLocationFloorChange = this.handleCreateLocationFloorChange.bind(this);
        this.handleCreateLocationAisleChange = this.handleCreateLocationAisleChange.bind(this);
        this.handleCreateLocationDeskChange = this.handleCreateLocationDeskChange.bind(this);
        this.submitCreateLocationForm = this.submitCreateLocationForm.bind(this);

        this.handleCreateVendorNameChange = this.handleCreateVendorNameChange.bind(this);
        this.submitCreateVendorForm = this.submitCreateVendorForm.bind(this);

        this.getAssetDetails = this.getAssetDetails.bind(this);
        this.populateDefaultValues = this.populateDefaultValues.bind(this);


        this.state = {
            enable_assigned_asset_input: false,

            // drop down menu items
            asset_categories: [],       // {id: 1, name_of_category: "IT"}
            asset_types: [],    // can reuse code from asset list   {id: 1, name_of_type: "Printer"}
            model_names: [],    // {id: 1, name_of_model: "Think Pad"}
            sites: [],      // {id: 1, name_of_site: "Irvine Office"}
            locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }],      // contains data for the currently selected site  {id: 1, floor_number: 1, aisle_number: 2, desk_number: 5"}
            people: [{ id: 0, full_name: "None", name_of_tenant: "All" }],         // for the assigned to drop down menu   // can reuse code from asset list {id: 1, full_name: "Tom Johnson", name_of_tenant: "ProGroup"}
            vendors: [{ id: 0, name_of_vendor: "None" }],        // {id: 1, name_of_vendor: "EMC"}
            tenants: [],    // can reuse code from asset list   {id: 1, name_of_tenant: "ProGroup"}
            assigned_asset_names: [],   // gets populated with values of assets with matching type and company info from assigned to drop downs

            // core add asset form variables
            selected_tenant_id: 0,
            selected_tenant_name: "",       // used for display in the company drop down menu (backend actually receives the id)
            asset_name_entered: "",
            selected_category_id: 0,
            selected_category_name: "",     // used for display in the category drop down menu (backend actually receives the id)
            selected_type_id: 0,
            selected_type_name: "",         // used for display in the type drop down menu (backend actually receives the id)
            selected_model_id: 0,
            selected_model_name: "",        // used for display in the model drop down menu (backend actually receives the id)
            selected_site_id: 0,
            selected_site_name: "",         // used for display in the site drop down menu (backend actually receives the id)
            selected_location_in_building_id: 0,
            selected_location_in_building_name: "",     // used for display in the location drop down menu (backend actually receives the id)
            selected_person_id: 0,
            selected_person_name: "",        // used for display in the person drop down menu (backend actually receives the id)
            selected_vendor_id: 0,
            selected_vendor_name: "",       // used for display in the vendor drop down menu (backend actually receives the id)
            asset_tag_entered: "",
            serial_number_entered: "",
            barcode_entered: "",
            ip_address_entered: "",
            mac_address_entered: "",
            ram_entered: "",
            cpu_entered: "",
            aquisition_date_entered: "",
            website_entered: "",
            warranty_start_date_entered: "",
            warranty_end_date_entered: "",
            lease_start_date_entered: "",
            lease_end_date_entered: "",
            track_asset: true,
            is_peripheral: false,

            // variables for assigning peripheral to core asset (3 drop downs at end of form)
            selected_assigned_asset_category_id: 0,
            selected_assigned_asset_category_name: "",
            selected_assigned_asset_type_id: 0,
            selected_assigned_asset_type_name: "",
            selected_assigned_asset_name_id: 0,
            selected_assigned_asset_name_name: "",

            // view modal controllers
            show_category_modal: false,
            show_type_modal: false,
            show_model_modal: false,
            show_manufacturer_modal: false,
            show_site_modal: false,
            show_location_modal: false,
            show_vendor_modal: false,

            from_model_modal: false,    // determines if user came from model or core form (affects where they get sent back to after closing)


            // modal form values

            // add category form
            createCategory_category_name_entered: "",

            // Add type form
            type_name_entered: "",
            selected_is_core_value: true,

            // Model form
            model_name_entered: "",
            model_type_id_selected: 0,
            cost_entered: "",
            manufacturer_id_selected: 0,
            manufacturers: [],      // {id: 1, name_of_manufacturer: "Dell"}

            // manufacturer form
            manufacturer_name_entered: "",
            manufacturer_type_entered: "",
            manufacturer_phone_number_entered: "",

            // site form
            // tenant_id_selected is based on selected_tenant_id from core asset form above
            site_name_entered: "",
            site_street_address_entered: "",
            site_city_entered: "",
            site_state_entered: "",
            site_zip_code_entered: "",

            // location at site form
            location_site_id_selected: 0,
            location_floor_entered: "",
            location_aisle_entered: "",
            location_desk_entered: "",

            // vendor form
            vendor_name_entered: "",


            canSeeErrorMessage: false,       // gets set to true if user tries to submit and incomplete form
            canSeeDuplicateNameError: false,     // gets set to true if user tries to submit a duplicate asset name

            canSeeErrorMessageCreateCategory: false,
            canSeeDuplicateNameErrorCreateCategory: false,

            canSeeErrorMessageCreateType: false,
            canSeeDuplicateNameErrorCreateType: false,

            canSeeErrorMessageCreateModel: false,
            canSeeDuplicateNameErrorCreateModel: false,

            canSeeErrorMessageCreateManufacturer: false,
            canSeeDuplicateNameErrorCreateManufacturer: false,

            canSeeDuplicateNameErrorCreateSite: false,
            canSeeErrorMessageCreateSite: false,

            canSeeDuplicateNameErrorCreateLocation: false,
            canSeeErrorMessageCreateLocation: false,

            canSeeDuplicateNameErrorCreateVendor: false,
            canSeeErrorMessageCreateVendor: false

        };
    }

    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    // get the asset details so we can populate the drop downs, etc with selected values
                    this.getAssetDetails(this.props.match.params.id, token).then(response => {
                        this.populateDefaultValues(response.results[0]).then(response => {

                            // call the GET functions to populate the dropdowns throughout screen
                            this.getHardwareAssetTypes(token).then(response => {
                                this.setState({
                                    asset_types: response.results
                                });

                                // if(response.results && response.results.length > 0) {
                                //     this.setState({
                                //         selected_type_id: response.results[0].id,
                                //         selected_assigned_asset_type_id: response.results[0].id,
                                //         model_type_id_selected: response.results[0].id
                                //     });
                                // }

                                this.getTenantList(token, this.props.loggedStatus.id).then(response => {
                                    this.setState({
                                        tenants: response.results
                                    });

                                    //let selectedTenantTemp;     // will briefly contain the value of the tenant in the first position returned, or 0 if none

                                    // if(response.results && response.results.length > 0) {
                                    //     this.setState({
                                    //         selected_tenant_id: response.results[0].id
                                    //     });

                                    //     selectedTenantTemp = response.results[0].id;
                                    // }

                                    // else {
                                    //     selectedTenantTemp = 0;
                                    // }

                                    this.getUserList(token, this.props.loggedStatus.id, this.state.selected_tenant_id).then(response => {
                                        this.setState({
                                            people: [...this.state.people, ...response.results]
                                            //selected_person_id: 0   // we have hardcoded the "None" option so we know that's going to be the iniital value automatically
                                        });

                                        // this.getTenantList(token, this.props.loggedStatus.id).then(response => {
                                        //     this.setState({
                                        //         tenants: response.results
                                        //     });

                                        //     if(response.results && response.results.length > 0) {
                                        //         this.setState({
                                        //             selected_tenant_id: response.results[0].id
                                        //         });
                                        //     }

                                        this.getCategories(token).then(response => {
                                            this.setState({
                                                asset_categories: response.results
                                            });

                                            // if(response.results && response.results.length > 0) {
                                            //     this.setState({
                                            //         selected_assigned_asset_category_id: response.results[0].id,
                                            //         selected_category_id: response.results[0].id
                                            //     });
                                            // }

                                            this.getVendors(token).then(response => {
                                                this.setState({
                                                    vendors: [...this.state.vendors, ...response.results],
                                                    //selected_vendor_id: 0   // we have hardcoded "None" value so we know it's automatically 0 to start
                                                });

                                                this.getManufacturers(token).then(response => {
                                                    this.setState({
                                                        manufacturers: response.results
                                                    });

                                                    // if(response.results && response.results.length > 0) {
                                                    //     this.setState({
                                                    //         manufacturer_id_selected: response.results[0].id
                                                    //     });
                                                    // }

                                                    // get model names based on the selected type in the types drop down menu
                                                    this.getModels(token, this.state.selected_type_id).then(response => {
                                                        this.setState({
                                                            model_names: response.results
                                                        });

                                                        // if(response.results && response.results.length > 0) {
                                                        //     this.setState({
                                                        //         selected_model_id: response.results[0].id
                                                        //     });
                                                        // }

                                                        this.getAssignableAssets(token, this.state.selected_tenant_id, this.state.selected_assigned_asset_type_id, this.state.selected_assigned_asset_category_id).then(response => {

                                                            this.setState({
                                                                assigned_asset_names: response.results
                                                            });

                                                            // if(response.results && response.results.length > 0) {
                                                            //     this.setState({
                                                            //         selected_assigned_asset_name_id: response.results[0].id
                                                            //     });
                                                            // }

                                                            this.getSites(token, this.state.selected_tenant_id).then(response => {

                                                                console.log("RESPONSE RETURNED FROM SITES ENDPOINT = ", response);
                                                                console.log("SELECTED SITE ID: ", this.state.selected_site_id);
                                                                this.setState({
                                                                    sites: [{id: 0, name_of_site: "None"}, ...response.results]
                                                                }, () => {
                                                                    if (this.state.sites && this.state.sites.length > 0) {
                                                                        // this.setState({
                                                                        //     selected_site_id: this.state.sites[0].id
                                                                        // });

                                                                        // get locations in building based on that site id
                                                                        this.getLocations(token, this.state.selected_site_id).then(response => {
                                                                            this.setState({
                                                                                locations_at_site: [...this.state.locations_at_site, ...response.results]
                                                                                //selected_location_in_building_id: 0 // we have hardcoded "None" option that drop down starts at
                                                                            }, () => {
                                                                                this.setDropdownDefaults();     // set the correct text values to display by default in drop down menus
                                                                            });


                                                                        })
                                                                            .catch(e => {
                                                                                console.log(e);
                                                                            });
                                                                    }
                                                                });

                                                            })
                                                                .catch(e => {
                                                                    console.log(e);
                                                                });
                                                        })
                                                            .catch(e => {
                                                                console.log(e);
                                                            });

                                                    })
                                                        .catch(e => {
                                                            console.log(e);
                                                        });

                                                })
                                                    .catch(e => {
                                                        console.log(e);
                                                    });

                                            })
                                                .catch(e => {
                                                    console.log(e);
                                                });

                                        })
                                            .catch(e => {
                                                console.log(e);
                                            });

                                    })
                                        .catch(e => {
                                            console.log(e);
                                        });

                                })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            })
                                .catch(e => {
                                    console.log(e)
                                });

                        })

                    })
                }

            });
        }
    }

    getAssetDetails(id, token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getSpecificAsset(token, id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // takes in asset object returned from getAssetDetails and populates state with required values for form
    populateDefaultValues(asset) {
        return new Promise((resolve, reject) => {


            console.log(asset);

            let selected_tenant_id = 0;
            let asset_name_entered = "";
            let selected_category_id = 0;
            let selected_type_id = 0;
            let selected_model_id = 0;
            let selected_site_id = 0;
            let selected_location_in_building_id = 0;
            let selected_person_id = 0;
            let selected_vendor_id = 0;
            let asset_tag_entered = "";
            let serial_number_entered = "";
            let barcode_entered = "";
            let ip_address_entered = "";
            let mac_address_entered = "";
            let ram_entered = "";
            let cpu_entered = "";

            let website_entered = "";


            let track_asset = true;
            let is_peripheral = false;
            let enable_assigned_asset_input = false;

            // variables for assigning peripheral to core asset (3 drop downs at end of form)
            let selected_assigned_asset_category_id = 0;
            let selected_assigned_asset_type_id = 0;
            let selected_assigned_asset_name_id = 0;

            let acquisition_date = "";
            let warranty_start_date = "";
            let warranty_end_date = "";
            let lease_start_date = "";
            let lease_end_date = "";

            if (asset.acquisition_date !== null) {
                acquisition_date = asset.acquisition_date;
                //acquisition_date = acquisition_date.getFullYear().toString().padStart(4, '0') + '-' + (acquisition_date.getMonth()+1).toString().padStart(2, '0') + '-' + acquisition_date.getDate().toString().padStart(2, '0');
            }

            if (asset.warranty_start_date !== null) {
                warranty_start_date = asset.warranty_start_date;
                //warranty_start_date = warranty_start_date.getFullYear().toString().padStart(4, '0') + '-' + (warranty_start_date.getMonth()+1).toString().padStart(2, '0') + '-' + warranty_start_date.getDate().toString().padStart(2, '0');
            }

            if (asset.warranty_expiration_date !== null) {
                warranty_end_date = asset.warranty_expiration_date;
                //warranty_end_date = warranty_end_date.getFullYear().toString().padStart(4, '0') + '-' + (warranty_end_date.getMonth()+1).toString().padStart(2, '0') + '-' + warranty_end_date.getDate().toString().padStart(2, '0');
            }

            if (asset.lease_start_date !== null) {
                lease_start_date = asset.lease_start_date;
                //lease_start_date = lease_start_date.getFullYear().toString().padStart(4, '0') + '-' + (lease_start_date.getMonth()+1).toString().padStart(2, '0') + '-' + lease_start_date.getDate().toString().padStart(2, '0');
            }

            if (asset.lease_end_date !== null) {
                lease_end_date = asset.lease_end_date;
                //lease_end_date = lease_end_date.getFullYear().toString().padStart(4, '0') + '-' + (lease_end_date.getMonth()+1).toString().padStart(2, '0') + '-' + lease_end_date.getDate().toString().padStart(2, '0');
            }

            if (asset.tenant_id !== null) {
                selected_tenant_id = asset.tenant_id;
            }

            if (asset.name_of_asset !== null) {
                asset_name_entered = asset.name_of_asset;
            }

            if (asset.asset_category_id !== null) {
                selected_category_id = asset.asset_category_id;
            }

            if (asset.asset_type_id !== null) {
                selected_type_id = asset.asset_type_id;
            }

            if (asset.model_id !== null) {
                selected_model_id = asset.model_id;
            }

            if (asset.site_id !== null) {
                selected_site_id = asset.site_id;
            }

            if (asset.assigned_location_at_site_id !== null) {
                selected_location_in_building_id = asset.assigned_location_at_site_id;
            }

            if (asset.assigned_person_id !== null) {
                selected_person_id = asset.assigned_person_id;
            }

            if (asset.vendor_id !== null) {
                selected_vendor_id = asset.vendor_id;
            }

            if (asset.asset_tag !== null) {
                asset_tag_entered = asset.asset_tag;
            }

            if (asset.asset_serial_number !== null) {
                serial_number_entered = asset.asset_serial_number;
            }

            if (asset.asset_bar_code !== null) {
                barcode_entered = asset.asset_bar_code;
            }

            if (asset.ip_address !== null) {
                ip_address_entered = asset.ip_address;
            }

            if (asset.mac_address !== null) {
                mac_address_entered = asset.mac_address;
            }

            if (asset.ram !== null) {
                ram_entered = asset.ram;
            }

            if (asset.cpu !== null) {
                cpu_entered = asset.cpu;
            }

            if (asset.manufacturer_website !== null) {
                website_entered = asset.manufacturer_website;
            }

            if (asset.isTracked !== null) {
                if (asset.isTracked === 1) {
                    track_asset = true;
                }

                else {
                    track_asset = false;
                }
            }

            if (asset.core_asset_assigned_to_id !== null) {      // this is a peripheral asset
                is_peripheral = true;
                enable_assigned_asset_input = true;
                selected_assigned_asset_category_id = asset.core_asset_assigned_to_category_id;
                selected_assigned_asset_type_id = asset.core_asset_assigned_to_type_id;
                selected_assigned_asset_name_id = asset.core_asset_assigned_to_id;
            }

            this.setState({
                selected_tenant_id: selected_tenant_id,
                asset_name_entered: asset_name_entered,
                selected_category_id: selected_category_id,
                selected_type_id: selected_type_id,
                selected_model_id: selected_model_id,
                selected_site_id: selected_site_id,
                selected_location_in_building_id: selected_location_in_building_id,
                selected_person_id: selected_person_id,
                selected_vendor_id: selected_vendor_id,
                asset_tag_entered: asset_tag_entered,
                serial_number_entered: serial_number_entered,
                barcode_entered: barcode_entered,
                ip_address_entered: ip_address_entered,
                mac_address_entered: mac_address_entered,
                ram_entered: ram_entered,
                cpu_entered: cpu_entered,
                aquisition_date_entered: acquisition_date,
                website_entered: website_entered,
                warranty_start_date_entered: warranty_start_date,
                warranty_end_date_entered: warranty_end_date,
                lease_start_date_entered: lease_start_date,
                lease_end_date_entered: lease_end_date,
                track_asset: track_asset,
                is_peripheral: is_peripheral,
                selected_assigned_asset_category_id: selected_assigned_asset_category_id,
                selected_assigned_asset_type_id: selected_assigned_asset_type_id,
                selected_assigned_asset_name_id: selected_assigned_asset_name_id,
                enable_assigned_asset_input: enable_assigned_asset_input
            }, () => resolve());

        })
    }

    setDropdownDefaults() {
        // sometimes site is null, so check before setting state

        console.log(this.state.sites);
        let siteName;
        if (this.state.sites && this.state.sites.length > 0 && this.state.selected_site_id !== 1 && this.state.selected_site_id !== null /* && this.state.selected_site_id !== 0 */) {
            siteName = this.state.sites.find(site => site.id === this.state.selected_site_id).name_of_site;
        }

        else {
            siteName = "None"
        }

        let personName;

        if (this.state.selected_person_id === 0) {
            personName = "None"
        }

        else {
            let personObj = this.state.people.find(person => person.id === this.state.selected_person_id);

            personName = `${personObj.full_name} - (${personObj.name_of_tenant})`;

            //console.log(personObj);
            //console.log(personName);
        }

        let assignedAssetCategoryName;

        if (this.state.selected_assigned_asset_category_id === 0) {
            assignedAssetCategoryName = "";
        }

        else {
            assignedAssetCategoryName = this.state.asset_categories.find(cat => cat.id === this.state.selected_assigned_asset_category_id).name_of_category;
        }

        let assignedAssetTypeName;

        if (this.state.selected_assigned_asset_type_id === 0) {
            assignedAssetTypeName = "";
        }

        else {
            assignedAssetTypeName = this.state.asset_types.find(type => type.id === this.state.selected_assigned_asset_type_id).name_of_type;
        }

        let assignedAssetNameName;

        if (this.state.selected_assigned_asset_name_id === 0) {
            assignedAssetNameName = "";
        }

        else {
            assignedAssetNameName = this.state.assigned_asset_names.find(asset => asset.id === this.state.selected_assigned_asset_name_id).name_of_asset;
        }

        let floorNumber = this.state.locations_at_site.find(loc => loc.id === this.state.selected_location_in_building_id).floor_number;
        let aisleNumber = this.state.locations_at_site.find(loc => loc.id === this.state.selected_location_in_building_id).aisle_number;
        let deskNumber = this.state.locations_at_site.find(loc => loc.id === this.state.selected_location_in_building_id).desk_number;

        this.setState({
            selected_tenant_name: this.state.tenants.find(ten => ten.id === this.state.selected_tenant_id).name_of_tenant,
            selected_category_name: this.state.asset_categories.find(cat => cat.id === this.state.selected_category_id).name_of_category,
            selected_type_name: this.state.asset_types.find(type => type.id === this.state.selected_type_id).name_of_type,
            selected_model_name: this.state.model_names.find(mod => mod.id === this.state.selected_model_id).name_of_model,
            selected_site_name: siteName,
            selected_location_in_building_name: `Floor: (${floorNumber}), Aisle: (${aisleNumber}), Desk: (${deskNumber})`,
            selected_person_name: personName,
            selected_vendor_name: this.state.vendors.find(ven => ven.id === this.state.selected_vendor_id).name_of_vendor,

            selected_assigned_asset_category_name: assignedAssetCategoryName,
            selected_assigned_asset_type_name: assignedAssetTypeName,
            selected_assigned_asset_name_name: assignedAssetNameName
        });
    }


    // determines whether user sees form field for specific asset a peripheral is assigned to
    toggleAddFormAssignedAssetInputVisible(newValue) {

        if (this.state.enable_assigned_asset_input && newValue === false) {
            this.setState({
                enable_assigned_asset_input: false
            });
        }

        else if (!this.state.enable_assigned_asset_input && newValue === true) {
            this.setState({
                enable_assigned_asset_input: true
            });
        }

    }


    closeAddPage() {
        this.props.history.push(`/hardwareAssetDetails/${this.props.match.params.id}`);
    }

    openCategoryModal() {
        this.setState({
            show_category_modal: true
        });
    }

    closeCategoryModal() {
        this.setState({
            show_category_modal: false,
            createCategory_category_name_entered: "",
            canSeeDuplicateNameErrorCreateCategory: false,
            canSeeErrorMessageCreateCategory: false
        });
    }

    openTypeModal(fromModel) {

        if (fromModel) {     // if coming from the model modal, hide model and show type
            this.setState({
                show_model_modal: false,
                show_type_modal: true,
                from_model_modal: true
            });
        }

        else {
            this.setState({
                show_type_modal: true
            });
        }
    }

    closeTypeModal() {

        if (this.state.from_model_modal) {
            this.setState({
                show_type_modal: false,
                show_model_modal: true,
                from_model_modal: false,
                type_name_entered: "",
                selected_is_core_value: true,
                canSeeDuplicateNameErrorCreateType: false,
                canSeeErrorMessageCreateType: false
            });
        }

        else {

            this.setState({
                show_type_modal: false,
                type_name_entered: "",
                selected_is_core_value: true,
                canSeeDuplicateNameErrorCreateType: false,
                canSeeErrorMessageCreateType: false
            });
        }
    }

    openModelModal() {
        this.setState({
            show_model_modal: true,
            model_name_entered: "",
            cost_entered: 0
        });

        if (this.state.asset_types && this.state.asset_types.length > 0) {
            this.setState({
                model_type_id_selected: this.state.asset_types[0].id
            });
        }

        else {
            this.setState({
                model_type_id_selected: 0
            });
        }

        if (this.state.manufacturers && this.state.manufacturers.length > 0) {
            this.setState({
                manufacturer_id_selected: this.state.manufacturers[0].id
            });
        }

        else {
            this.setState({
                manufacturer_id_selected: 0
            });
        }
    }

    closeModelModal() {
        this.setState({
            show_model_modal: false
        });
    }

    openManufacturerModal() {
        this.setState({
            show_manufacturer_modal: true,
            show_model_modal: false
        });
    }

    closeManufacturerModal() {
        this.setState({
            show_manufacturer_modal: false,
            show_model_modal: true,
            manufacturer_name_entered: "",          // reset form fields
            manufacturer_type_entered: "",
            manufacturer_phone_number_entered: ""
        });
    }

    openSiteModal() {
        this.setState({
            show_site_modal: true
        });
    }

    closeSiteModal() {
        this.setState({
            show_site_modal: false,
            site_name_entered: "",  // reset form fields
            site_street_address_entered: "",
            site_city_entered: "",
            site_state_entered: "",
            site_zip_code_entered: ""
        });
    }

    openLocationModal() {
        this.setState({
            show_location_modal: true
        });
    }

    closeLocationModal() {
        this.setState({
            show_location_modal: false,
            location_floor_entered: "",
            location_aisle_entered: "",
            location_desk_entered: ""
        });
    }

    openVendorModal() {
        this.setState({
            show_vendor_modal: true
        });
    }

    closeVendorModal() {
        this.setState({
            show_vendor_modal: false,
            vendor_name_entered: ""
        });
    }


    handleIsCoreChange(newValue) {
        this.setState({
            selected_is_core_value: newValue    // will be either true or false
        });
    }

    handleTenantChange(tenantList, e) {
        let tenantId = tenantList.find(ten => ten.name_of_tenant === e.target.value).id;
        this.setState({
            selected_tenant_id: tenantId,
            selected_tenant_name: e.target.value
        });

        // get new sites for this new tenant
        this.getSites(this.props.loggedStatus.accessToken, tenantId).then(response => {
            this.setState({
                sites: [{id: 0, name_of_site: "None"}, ...response.results]
            }, () => {
                if (this.state.sites && this.state.sites.length > 0) {
                    this.setState({
                        selected_site_id: this.state.sites[0].id,
                        selected_site_name: this.state.sites[0].name_of_site
                    });

                    // get locations in building based on that site id
                    this.getLocations(this.props.loggedStatus.accessToken, this.state.sites[0].id).then(response => {
                        this.setState({
                            locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }, ...response.results],
                            selected_location_in_building_id: 0, // we have hardcoded "None" option that drop down starts at
                            selected_location_in_building_name: `Floor: (None), Aisle: (None), Desk: (None)`
                        });


                    })
                        .catch(e => {
                            console.log(e);
                        });
                }

                else {      // there were no sites entered for that tenant, so we just have empty lists there and reset to default 0 selected
                    this.setState({
                        sites: [],
                        selected_site_id: 0,
                        selected_site_name: "",
                        locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }],
                        selected_location_in_building_id: 0,
                        selected_location_in_building_name: `Floor: (None), Aisle: (None), Desk: (None)`
                    });
                }


            });

            // get the new user list for that tenant for the assigned to drop down menu
            this.getUserList(this.props.loggedStatus.accessToken, this.props.loggedStatus.id, tenantId).then(response => {
                this.setState({
                    people: [{ id: 0, full_name: "None", name_of_tenant: "All" }, ...response.results],
                    selected_person_id: 0,   // we have hardcoded the "None" option so we know that's going to be the iniital value automatically
                    selected_person_name: "None"
                });

                // get the new assignable asset list for this tenant (the drop down at bottom of form)
                // get new values for the assignable assets drop down menu
                this.getAssignableAssets(this.props.loggedStatus.accessToken, tenantId, this.state.selected_assigned_asset_type_id, this.state.selected_assigned_asset_category_id).then(response => {

                    this.setState({
                        assigned_asset_names: response.results
                    });

                    if (response.results && response.results.length > 0) {
                        this.setState({
                            selected_assigned_asset_name_id: response.results[0].id,
                            selected_assigned_asset_name_name: response.results[0].name_of_asset
                        });
                    }

                    else {
                        this.setState({
                            selected_assigned_asset_name_id: 0,
                            selected_assigned_asset_name_name: ""
                        });
                    }

                })
                    .catch(e => {
                        console.log(e);
                    });
            })
                .catch(e => {
                    console.log(e);
                });
        })
            .catch(e => {
                console.log(e);
            });

    }

    handleAssetNameEnteredChange(e) {
        this.setState({
            asset_name_entered: e.target.value
        });
    }

    handleSelectedCategoryChange(categoryList, e) {
        console.log(e.target.value);
        this.setState({
            selected_category_id: categoryList.find(cat => cat.name_of_category === e.target.value).id,
            selected_category_name: e.target.value
        });
    }

    handleSelectedTypeChange(typeList, e) {
        let typeId = typeList.find(type => type.name_of_type === e.target.value).id;
        this.setState({
            selected_type_id: typeId,
            selected_type_name: e.target.value
        });

        // changed type, so update the values in the model drop down menu
        this.getModels(this.props.loggedStatus.accessToken, typeId).then(response => {
            this.setState({
                model_names: response.results
            });

            if (response.results && response.results.length > 0) {
                this.setState({
                    selected_model_id: response.results[0].id,
                    selected_model_name: response.results[0].name_of_model
                });
            }

            else {
                this.setState({
                    selected_model_id: 0,        // if nothing was returned, we go back to default 0 value
                    selected_model_name: ""
                });
            }
        });
    }

    handleSelectedModelChange(modelList, e) {
        this.setState({
            selected_model_id: modelList.find(mod => mod.name_of_model === e.target.value).id,
            selected_model_name: e.target.value
        });
    }

    handleSelectedSiteChange(siteList, e) {
        let siteId = siteList.find(site => site.name_of_site === e.target.value).id;
        this.setState({
            selected_site_id: siteId,
            selected_site_name: e.target.value
        });

        // now get a new list of locations based on site selected
        // get locations in building based on that site id
        this.getLocations(this.props.loggedStatus.accessToken, siteId).then(response => {
            this.setState({
                locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }, ...response.results],
                selected_location_in_building_id: 0, // we have hardcoded "None" option that drop down starts at
                selected_location_in_building_name: "None"
            });


        })
            .catch(e => {
                console.log(e);
            });
    }

    handleSelectedLocationChange(locationList, e) {
        console.log(e.target.value);
        console.log(locationList)
        let locationString = e.target.value;    //`Floor: (${location.floor_number}), Aisle: (${location.aisle_number}), Desk: (${location.desk_number})`
        let floorNumber = locationString.split("Floor: (")[1];
        floorNumber = floorNumber.split(")")[0];

        let aisleNumber = locationString.split("Aisle: (")[1];
        aisleNumber = aisleNumber.split(")")[0];

        let deskNumber = locationString.split("Desk: (")[1];
        deskNumber = deskNumber.split(")")[0];


        console.log(floorNumber);
        console.log(aisleNumber);
        console.log(deskNumber);

        // if(floorNumber !== "None") {
        //     floorNumber = parseInt(floorNumber);
        // }

        // if(aisleNumber !== "None") {
        //     aisleNumber = parseInt(aisleNumber);
        // }

        // if(deskNumber !== "None") {
        //     deskNumber = parseInt(deskNumber);
        // }

        this.setState({
            selected_location_in_building_id: locationList.find(loc => loc.floor_number === floorNumber && loc.aisle_number === aisleNumber && loc.desk_number === deskNumber).id,
            selected_location_in_building_name: `Floor: (${floorNumber}), Aisle: (${aisleNumber}), Desk: (${deskNumber})`
        });
    }

    handleSelectedAssignedPersonChange(personList, e) {
        console.log(personList);
        console.log(e.target.value);

        let personName = e.target.value.split("-")[0];
        personName = personName.trim();
        console.log(personName);

        this.setState({
            selected_person_id: personList.find(per => per.full_name === personName).id,
            selected_person_name: e.target.value
        });
    }

    handleSelectedVendorChange(vendorList, e) {
        this.setState({
            selected_vendor_id: vendorList.find(ven => ven.name_of_vendor === e.target.value).id,
            selected_vendor_name: e.target.value
        });
    }

    handleAssetTagEnteredChange(e) {
        this.setState({
            asset_tag_entered: e.target.value
        });
    }

    handleSerialNumberEnteredChange(e) {
        this.setState({
            serial_number_entered: e.target.value
        });
    }

    handleBarcodeEnteredChange(e) {
        this.setState({
            barcode_entered: e.target.value
        });
    }

    handleIPAddressEnteredChange(e) {
        this.setState({
            ip_address_entered: e.target.value
        });
    }

    handleMACAddressEnteredChange(e) {
        this.setState({
            mac_address_entered: e.target.value
        });
    }

    handleRAMEnteredChange(e) {
        this.setState({
            ram_entered: e.target.value
        });
    }

    handleCPUEnteredChange(e) {
        this.setState({
            cpu_entered: e.target.value
        });
    }

    handleAcquisitionDateChange(e) {
        this.setState({
            aquisition_date_entered: e.target.value
        });
    }

    handleWebsiteEnteredChange(e) {
        this.setState({
            website_entered: e.target.value
        });
    }

    handleWarrantyStartDateChange(e) {
        this.setState({
            warranty_start_date_entered: e.target.value
        });
    }

    handleWarrantyEndDateChange(e) {
        this.setState({
            warranty_end_date_entered: e.target.value
        });
    }

    handleLeaseStartDateChange(e) {
        this.setState({
            lease_start_date_entered: e.target.value
        });
    }

    handleLeaseEndDateChange(e) {
        this.setState({
            lease_end_date_entered: e.target.value
        });
    }

    handleTrackAssetChange(e) {
        if (e.target.value === "true") {
            this.setState({
                track_asset: true
            });
        }

        else {
            this.setState({
                track_asset: false
            });
        }
    }

    handleIsPeripheralChange(e) {
        if (e.target.value === "true") {
            // if changed to true, set updated values for selected assigned asset drop downs
            let categoryToUse = { id: 0, name_of_category: "" };
            let typeToUse = { id: 0, name_of_type: "" };
            let assetNameToUse = { id: 0, name_of_asset: "" };

            if (/* this.state.selected_assigned_asset_category_id === 0 && */ this.state.asset_categories.length > 0) {
                categoryToUse = this.state.asset_categories[0];
            }

            if (/* this.state.selected_assigned_asset_type_id === 0 && */ this.state.asset_types.length > 0) {
                typeToUse = this.state.asset_types[0];
            }

            // if(/* this.state.selected_assigned_asset_name_id === 0 && */ this.state.assigned_asset_names.length > 0) {
            //     assetNameToUse = this.state.assigned_asset_names[0];
            // }

            console.log(categoryToUse);
            console.log(typeToUse);

            // get the list of assignable assets using the chosen category and type
            this.getAssignableAssets(this.props.loggedStatus.accessToken, this.state.selected_tenant_id, typeToUse.id, categoryToUse.id).then(response => {

                console.log("Asset list returned: ", response);
                if (response && response.results.length > 0) {
                    assetNameToUse = response.results[0];
                }

                console.log(assetNameToUse);

                this.setState({
                    is_peripheral: true,
                    assigned_asset_names: response.results,
                    selected_assigned_asset_category_id: categoryToUse.id,
                    selected_assigned_asset_type_id: typeToUse.id,
                    selected_assigned_asset_name_id: assetNameToUse.id,

                    selected_assigned_asset_category_name: categoryToUse.name_of_category,
                    selected_assigned_asset_type_name: typeToUse.name_of_type,
                    selected_assigned_asset_name_name: assetNameToUse.name_of_asset
                });
            });
        }

        else {
            this.setState({
                is_peripheral: false,

                selected_assigned_asset_category_id: 0,
                selected_assigned_asset_type_id: 0,
                selected_assigned_asset_name_id: 0,

                selected_assigned_asset_category_name: "",
                selected_assigned_asset_type_name: "",
                selected_assigned_asset_name_name: ""
            });
        }
    }

    handleAssignedAssetCategoryChange(categoryList, e) {
        let categoryId = categoryList.find(cat => cat.name_of_category === e.target.value).id;

        this.setState({
            selected_assigned_asset_category_id: categoryId,
            selected_assigned_asset_category_name: e.target.value
        });

        // get new values for the assignable assets drop down menu
        this.getAssignableAssets(this.props.loggedStatus.accessToken, this.state.selected_tenant_id, this.state.selected_assigned_asset_type_id, categoryId).then(response => {

            this.setState({
                assigned_asset_names: response.results
            });

            if (response.results && response.results.length > 0) {
                this.setState({
                    selected_assigned_asset_name_id: response.results[0].id,
                    selected_assigned_asset_name_name: response.results[0].name_of_asset
                });
            }

            else {
                this.setState({
                    selected_assigned_asset_name_id: 0,
                    selected_assigned_asset_name_name: ""
                });
            }

        })
            .catch(e => {
                console.log(e);
            });
    }

    handleAssignedAssetTypeChange(typeList, e) {

        let typeId = typeList.find(type => type.name_of_type === e.target.value).id;
        this.setState({
            selected_assigned_asset_type_id: typeId,
            selected_assigned_asset_type_name: e.target.value
        });

        // get new values for the assignable assets drop down menu
        this.getAssignableAssets(this.props.loggedStatus.accessToken, this.state.selected_tenant_id, typeId, this.state.selected_assigned_asset_category_id).then(response => {

            this.setState({
                assigned_asset_names: response.results
            });

            if (response.results && response.results.length > 0) {
                this.setState({
                    selected_assigned_asset_name_id: response.results[0].id,
                    selected_assigned_asset_name_name: response.results[0].name_of_asset
                });
            }

            else {
                this.setState({
                    selected_assigned_asset_name_id: 0,
                    selected_assigned_asset_name_name: ""
                });
            }

        })
            .catch(e => {
                console.log(e);
            });

    }

    handleAssignedAssetNameChange(assetList, e) {
        this.setState({
            selected_assigned_asset_name_id: assetList.find(asset => asset.name_of_asset === e.target.value).id,
            selected_assigned_asset_name_name: e.target.value
        });
    }

    handleCreateCategoryNameChange(e) {
        this.setState({
            createCategory_category_name_entered: e.target.value
        });
    }

    handleCreateTypeNameChange(e) {
        this.setState({
            type_name_entered: e.target.value
        });
    }

    handleCreateModelNameChange(e) {
        this.setState({
            model_name_entered: e.target.value
        });
    }

    handleCreateModelTypeChange(typeId) {
        this.setState({
            model_type_id_selected: typeId
        });
    }

    handleCreateModelCostChange(e) {

        //console.log(!!e.target.value.match(/(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/));

        // they have cleared the field
        if (e.target.value === "") {
            this.setState({
                cost_entered: ""
            });
        }

        // they input an integer
        else if (Number.isInteger(Number(e.target.value))) {
            this.setState({
                cost_entered: parseInt(e.target.value)
            });
        }

        // they have input a float
        else if (!isNaN(e.target.value) && e.target.value.toString().indexOf('.') !== -1) {

            //let input = parseFloat(e.target.value);

            this.setState({
                cost_entered: parseFloat(e.target.value).toFixed(2)
                //cost_entered: e.target.value
            });
        }

        else {
            console.log("Bad input");
        }

    }

    handleCreateModelManufacturerChange(manufacturerId) {
        this.setState({
            manufacturer_id_selected: manufacturerId
        });
    }

    handleCreateManufacturerNameChange(e) {
        this.setState({
            manufacturer_name_entered: e.target.value
        });
    }

    handleCreateManufacturerTypeChange(e) {
        this.setState({
            manufacturer_type_entered: e.target.value
        });
    }

    handleCreateManufacturerPhoneChange(e) {
        this.setState({
            manufacturer_phone_number_entered: e.target.value
        });
    }

    handleCreateSiteNameChange(e) {
        this.setState({
            site_name_entered: e.target.value
        });
    }

    handleCreateSiteStreetAddressChange(e) {
        this.setState({
            site_street_address_entered: e.target.value
        });
    }

    handleCreateSiteCityChange(e) {
        this.setState({
            site_city_entered: e.target.value
        });
    }

    handleCreateSiteStateChange(e) {
        this.setState({
            site_state_entered: e.target.value
        });
    }

    handleCreateSiteZipCodeChange(e) {
        this.setState({
            site_zip_code_entered: e.target.value
        });
    }

    handleCreateLocationFloorChange(e) {
        this.setState({
            location_floor_entered: e.target.value
        });
    }

    handleCreateLocationAisleChange(e) {
        this.setState({
            location_aisle_entered: e.target.value
        });
    }

    handleCreateLocationDeskChange(e) {
        this.setState({
            location_desk_entered: e.target.value
        });
    }

    handleCreateVendorNameChange(e) {
        this.setState({
            vendor_name_entered: e.target.value
        });
    }

    submitCreateVendorForm() {
        console.log("Values submitting to backend")
        console.log("Name: ", this.state.vendor_name_entered);

        if (this.state.vendor_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateVendor: false,
                canSeeErrorMessageCreateVendor: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateVendor: false
            });

            // check for duplicate vendor
            HardwareAssetDataService.checkForDuplicateVendor(this.props.loggedStatus.accessToken, this.state.vendor_name_entered).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateVendor: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateVendor: false
                    });

                    // send data to backend for processing
                    let data = {
                        vendorName: this.state.vendor_name_entered
                    };

                    HardwareAssetDataService.createVendor(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getVendors(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                vendors: [{ id: 0, name_of_vendor: "None" }, ...response.results],
                                selected_vendor_id: 0
                            });

                            this.closeVendorModal();
                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        })

                }
            })
                .catch(e => {
                    console.log(e);
                });

        }
    }

    submitCreateLocationForm() {
        console.log("Values submitting to backend");
        console.log("Site Id: ", this.state.selected_site_id);
        console.log("Floor: ", this.state.location_floor_entered);
        console.log("Aisle: ", this.state.location_aisle_entered);
        console.log("Desk: ", this.state.location_desk_entered);

        if (this.state.selected_site_id === 0 || this.state.location_floor_entered === "" || this.state.location_aisle_entered === "" || this.state.location_desk_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateLocation: false,
                canSeeErrorMessageCreateLocation: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateLocation: false
            });

            // check for duplicate entry
            HardwareAssetDataService.checkForDuplicateLocation(this.props.loggedStatus.accessToken, this.state.location_floor_entered, this.state.location_aisle_entered, this.state.location_desk_entered, this.state.selected_site_id).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateLocation: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateLocation: false
                    });

                    // send data to backend for processing
                    let data = {
                        siteId: this.state.selected_site_id,
                        floorNumber: this.state.location_floor_entered,
                        aisleNumber: this.state.location_aisle_entered,
                        deskNumber: this.state.location_desk_entered
                    };

                    HardwareAssetDataService.createLocation(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getLocations(this.props.loggedStatus.accessToken, this.state.selected_site_id).then(response => {
                            this.setState({
                                locations_at_site: [{ id: 0, floor_number: "None", aisle_number: "None", desk_number: "None" }, ...response.results],
                                selected_location_in_building_id: 0
                            });

                            this.closeLocationModal();
                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    submitCreateSiteForm() {
        console.log("Values submitting to backend");
        console.log("Tenant Id: ", this.state.selected_tenant_id);
        console.log("Site Name: ", this.state.site_name_entered);
        console.log("Street Address: ", this.state.site_street_address_entered);
        console.log("City: ", this.state.site_city_entered);
        console.log("State: ", this.state.site_state_entered);
        console.log("Zip Code: ", this.state.site_zip_code_entered);

        if (this.state.selected_tenant_id === 0 || this.state.site_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateSite: false,
                canSeeErrorMessageCreateSite: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateSite: false
            });

            // check for duplicate name
            HardwareAssetDataService.checkForDuplicateSiteName(this.props.loggedStatus.accessToken, this.state.site_name_entered).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateSite: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateSite: false
                    });

                    // send form data to backend

                    let data = {
                        tenantId: this.state.selected_tenant_id,
                        siteName: this.state.site_name_entered,
                        streetAddress: this.state.site_street_address_entered,
                        city: this.state.site_city_entered,
                        state: this.state.site_state_entered,
                        zipCode: this.state.site_zip_code_entered
                    };

                    HardwareAssetDataService.createSite(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getSites(this.props.loggedStatus.accessToken, this.state.selected_tenant_id).then(response => {
                            this.setState({
                                sites: response.results
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_site_id: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    selected_site_id: 0
                                });
                            }

                            this.closeSiteModal();

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
                .catch(e => {
                    console.log(e);
                });

        }

    }

    submitCreateManufacturerForm() {
        console.log("Values submitting to backend");
        console.log("Manufacturer name: ", this.state.manufacturer_name_entered);
        console.log("Manufacturer type: ", this.state.manufacturer_type_entered);
        console.log("Phone number: ", this.state.manufacturer_phone_number_entered);

        if (this.state.manufacturer_name_entered === "" || this.state.manufacturer_type_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateManufacturer: false,
                canSeeErrorMessageCreateManufacturer: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateManufacturer: false
            });

            // check for duplicate name / type combo
            HardwareAssetDataService.checkForDuplicateManufacturer(this.props.loggedStatus.accessToken, this.state.manufacturer_name_entered, this.state.manufacturer_type_entered).then(response => {

                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateManufacturer: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateManufacturer: false
                    });

                    // send form data to backend

                    let data = {
                        manufacturerName: this.state.manufacturer_name_entered,
                        manufacturerType: this.state.manufacturer_type_entered,
                        phoneNumber: this.state.manufacturer_phone_number_entered
                    };

                    HardwareAssetDataService.createManufacturer(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getManufacturers(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                manufacturers: response.results,
                                manufacturer_name_entered: "",      // reset form fields
                                manufacturer_type_entered: "",
                                manufacturer_phone_number_entered: ""
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    manufacturer_id_selected: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    manufacturer_id_selected: 0
                                });
                            }

                            this.closeManufacturerModal();
                        })
                            .catch(e => {
                                console.log(e);
                            })
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }


            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    submitCreateModelForm() {
        console.log("Values submitting to backend");
        console.log("Model Name: ", this.state.model_name_entered);
        console.log("Type id: ", this.state.selected_type_id);
        console.log("Cost: ", this.state.cost_entered);
        console.log("Manufacturer id: ", this.state.manufacturer_id_selected);

        if (this.state.model_name_entered === "" || this.state.selected_type_id === 0 || this.state.manufacturer_id_selected === 0) {
            this.setState({
                canSeeDuplicateNameErrorCreateModel: false,
                canSeeErrorMessageCreateModel: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateModel: false
            });


            // Check for duplicate model name
            HardwareAssetDataService.checkForDuplicateModelName(this.props.loggedStatus.accessToken, this.state.model_name_entered).then(response => {
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateModel: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateModel: false
                    });

                    // submit form data to backend for add

                    let data = {
                        modelName: this.state.model_name_entered,
                        typeId: this.state.selected_type_id,
                        cost: this.state.cost_entered,
                        manufacturerId: this.state.manufacturer_id_selected
                    };

                    HardwareAssetDataService.createModel(this.props.loggedStatus.accessToken, data).then(response => {
                        // update the model list
                        this.getModels(this.props.loggedStatus.accessToken, this.state.selected_type_id).then(response => {
                            this.setState({
                                model_names: response.results,
                                model_name_entered: "",         // reset form fields
                                cost_entered: "",
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_model_id: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    selected_model_id: 0
                                });
                            }

                            // reset the type id selected field of model form
                            if (this.state.asset_types && this.state.asset_types.length > 0) {
                                this.setState({
                                    model_type_id_selected: this.state.asset_types[0].id
                                });
                            }

                            else {
                                this.setState({
                                    model_type_id_selected: 0
                                });
                            }

                            // reset the manufacturer id selected field of model form
                            if (this.state.manufacturers && this.state.manufacturers.length > 0) {
                                this.setState({
                                    manufacturer_id_selected: this.state.manufacturers[0].id
                                });
                            }

                            else {
                                this.setState({
                                    manufacturer_id_selected: 0
                                });
                            }

                            this.closeModelModal();
                        })
                            .catch(e => {
                                console.log(e);
                            })
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            })
                .catch(e => {
                    console.log(e);
                });
        }

    }

    submitCreateTypeForm() {
        console.log("Values submitting to backend");
        console.log("Type Name: ", this.state.type_name_entered);
        console.log("Is Core: ", this.state.selected_is_core_value);

        // First, ensure that all required form fields are filled out
        if (this.state.type_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateType: false,
                canSeeErrorMessageCreateType: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessageCreateType: false
            });

            // Check for duplicate type name
            HardwareAssetDataService.checkForDuplicateTypeName(this.props.loggedStatus.accessToken, this.state.type_name_entered).then(response => {
                // If duplicate, show an error message
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateType: true
                    });
                }

                else if (response.data.results === "NO DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateType: false
                    });

                    // submit form to backend for add

                    let data = {
                        typeName: this.state.type_name_entered,
                        isCore: this.state.selected_is_core_value
                    };

                    HardwareAssetDataService.createType(this.props.loggedStatus.accessToken, data).then(response => {
                        this.getHardwareAssetTypes(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                asset_types: response.results,
                                type_name_entered: "",              // reset form fields
                                selected_is_core_value: true
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_type_id: response.results[0].id,
                                    selected_assigned_asset_type_id: response.results[0].id,
                                    model_type_id_selected: response.results[0].id
                                });

                                // Get the models for that type
                                this.getModels(this.props.loggedStatus.accessToken, response.results[0].id).then(response => {
                                    if (response.results && response.results.length > 0) {
                                        this.setState({
                                            model_names: response.results,
                                            selected_model_id: response.results[0].id
                                        });
                                    }

                                    else {
                                        this.setState({
                                            model_names: response.results,
                                            selected_model_id: 0
                                        });
                                    }
                                })
                                    .catch(e => {
                                        console.log(e);
                                    });
                            }

                            else {
                                this.setState({
                                    asset_types: response.results,
                                    selected_type_id: 0,
                                    selected_assigned_asset_type_id: 0,
                                    model_type_id_selected: 0,
                                    model_names: []
                                });
                            }

                            // if(response.results && response.results.length > 0) {
                            //     this.setState({
                            //         selected_type_id: response.results[0].id,
                            //         selected_assigned_asset_type_id: response.results[0].id
                            //     }, () => {
                            //         this.closeTypeModal();
                            //     });
                            // }

                            //else {
                            this.closeTypeModal();
                            //}

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });
                }
            })
                .catch(e => {
                    console.log(e);
                });
        }
    }

    submitCreateCategoryForm() {
        console.log("Values submitting to backend");
        console.log("Category Name: ", this.state.createCategory_category_name_entered);

        // First, ensure that all required form fields are filled out
        if (this.state.createCategory_category_name_entered === "") {
            this.setState({
                canSeeDuplicateNameErrorCreateCategory: false,
                canSeeErrorMessageCreateCategory: true
            });
        }

        else {

            this.setState({
                canSeeErrorMessageCreateCategory: false
            });

            // Check for duplicate category name
            HardwareAssetDataService.checkForDuplicateCategoryName(this.props.loggedStatus.accessToken, this.state.createCategory_category_name_entered).then(response => {

                // If duplicate, show an error message
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameErrorCreateCategory: true
                    });
                }

                // If not duplicate, submit entire form for asset add
                else if (response.data.results === "NO DUPLICATE FOUND") {

                    this.setState({
                        canSeeDuplicateNameErrorCreateCategory: false
                    });

                    // submit form to backend for add

                    let data = {
                        categoryName: this.state.createCategory_category_name_entered
                    };

                    HardwareAssetDataService.createCategory(this.props.loggedStatus.accessToken, data).then(response => {
                        // fetch the category names again for drop down so they update
                        this.getCategories(this.props.loggedStatus.accessToken).then(response => {
                            this.setState({
                                asset_categories: response.results,
                                createCategory_category_name_entered: ""    // reset form fields
                            });

                            if (response.results && response.results.length > 0) {
                                this.setState({
                                    selected_assigned_asset_category_id: response.results[0].id,
                                    selected_category_id: response.results[0].id
                                });
                            }

                            else {
                                this.setState({
                                    selected_assigned_asset_category_id: 0,
                                    selected_category_id: 0
                                });
                            }

                            // if(response.results && response.results.length > 0) {
                            //     this.setState({
                            //         selected_assigned_asset_category_id: response.results[0].id,
                            //         selected_category_id: response.results[0].id
                            //     }, () => {
                            //         // close the category modal
                            //         this.closeCategoryModal();
                            //     });
                            // }

                            //else {
                            this.closeCategoryModal();
                            //}

                        })
                            .catch(e => {
                                console.log(e);
                            });
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }

            })
                .catch(e => {
                    console.log(e);
                });

        }

    }



    submitEditAssetForm() {
        console.log("Values submitting to backend");
        console.log("Tenant id: ", this.state.selected_tenant_id);
        console.log("Asset Name Entered: ", this.state.asset_name_entered);
        console.log("Category Id: ", this.state.selected_category_id);
        console.log("Type id: ", this.state.selected_type_id);
        console.log("Model id: ", this.state.selected_model_id);
        console.log("Site id: ", this.state.selected_site_id);
        console.log("Location at site id: ", this.state.selected_location_in_building_id);
        console.log("Person id: ", this.state.selected_person_id);
        console.log("Vendor id: ", this.state.selected_vendor_id);
        console.log("Asset tag: ", this.state.asset_tag_entered);
        console.log("Serial Number: ", this.state.serial_number_entered);
        console.log("Barcode: ", this.state.barcode_entered);
        console.log("IP Address: ", this.state.ip_address_entered);
        console.log("MAC Address: ", this.state.mac_address_entered);
        console.log("RAM: ", this.state.ram_entered);
        console.log("CPU: ", this.state.cpu_entered);
        console.log("Aquisition Date: ", this.state.aquisition_date_entered);
        console.log("Website: ", this.state.website_entered);
        console.log("Warranty Start: ", this.state.warranty_start_date_entered);
        console.log("Warranty end: ", this.state.warranty_end_date_entered);
        console.log("Lease Start: ", this.state.lease_start_date_entered);
        console.log("Lease End: ", this.state.lease_end_date_entered);
        console.log("Track Asset: ", this.state.track_asset);
        console.log("Is Peripheral: ", this.state.is_peripheral);


        console.log("Selected assigned asset category id: ", this.state.selected_assigned_asset_category_id);
        console.log("Selected assigned asset type id: ", this.state.selected_assigned_asset_type_id);
        console.log("Selected assigned asset name id: ", this.state.selected_assigned_asset_name_id);


        // First, ensure that all required form fields are filled out
        if (this.state.selected_tenant_id === 0 || this.state.asset_name_entered === "" || this.state.selected_category_id === 0 || this.state.selected_type_id === 0 || this.state.selected_model_id === 0 /* || this.state.selected_site_id === 0 */ || (this.state.is_peripheral && this.state.selected_assigned_asset_name_id === 0)) {
            this.setState({
                canSeeDuplicateNameError: false,
                canSeeErrorMessage: true
            });
        }

        else {
            this.setState({
                canSeeErrorMessage: false
            });


            // Then, send entered asset name through to backend to check for duplicate
            HardwareAssetDataService.checkForDuplicateAssetNameEditVersion(this.props.loggedStatus.accessToken, this.state.asset_name_entered, this.props.match.params.id).then(response => {

                // If duplicate, show an error message
                if (response.data.results === "DUPLICATE FOUND") {
                    this.setState({
                        canSeeDuplicateNameError: true
                    });
                }

                // If not duplicate, submit entire form for asset add
                else if (response.data.results === "NO DUPLICATE FOUND") {
                    console.log("this name is good");
                    this.setState({
                        canSeeDuplicateNameError: false
                    });

                    // submit form to backend for add
                    let data = {
                        tenantId: this.state.selected_tenant_id,
                        assetName: this.state.asset_name_entered,
                        categoryId: this.state.selected_category_id,
                        typeId: this.state.selected_type_id,
                        modelId: this.state.selected_model_id,
                        siteId: this.state.selected_site_id,
                        locationId: this.state.selected_location_in_building_id,
                        personId: this.state.selected_person_id,
                        vendorId: this.state.selected_vendor_id,
                        assetTag: this.state.asset_tag_entered,
                        serialNumber: this.state.serial_number_entered,
                        barcode: this.state.barcode_entered,
                        ipAddress: this.state.ip_address_entered,
                        macAddress: this.state.mac_address_entered,
                        ram: this.state.ram_entered,
                        cpu: this.state.cpu_entered,
                        aquisitionDate: this.state.aquisition_date_entered,
                        website: this.state.website_entered,
                        warrantyStartDate: this.state.warranty_start_date_entered,
                        warrantyEndDate: this.state.warranty_end_date_entered,
                        leaseStartDate: this.state.lease_start_date_entered,
                        leaseEndDate: this.state.lease_end_date_entered,
                        trackAsset: this.state.track_asset,
                        isPeripheral: this.state.is_peripheral,
                        assignedAssetId: this.state.selected_assigned_asset_name_id,
                        creatorId: this.props.loggedStatus.id
                        //assetId: this.props.match.params.id       SEND THIS THROUGH AS PART OF THE URL
                    };

                    HardwareAssetDataService.editHardwareAsset(this.props.loggedStatus.accessToken, data, this.props.match.params.id).then(response => {
                        console.log("Returned from edit hardware asset endpoint");
                        this.props.history.push('/hardwareAssets');
                    })
                        .catch(e => {
                            console.log(e);
                        });


                }

            })
                .catch(e => {
                    console.log(e);
                });


        }
    }


    getHardwareAssetTypes(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTypes(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of users this tech is authorized to see for the filter drop down menu
    getUserList(token, asker_id, tenant_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getUsersAddAssetVersion(token, asker_id, tenant_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of tenant names this tech is authorized to see for the filter drop down menu
    getTenantList(token, asker_id) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getTenants(token, asker_id)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of categories for the add hardware asset drop down menu
    getCategories(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getCategories(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of vendors for the add hardware asset drop down menu
    getVendors(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getVendors(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets list of manufacturers for the add hardware asset drop down menu
    getManufacturers(token) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getManufacturers(token)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of models based on type user has selected for add hardware asset drop down menu
    getModels(token, typeId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getModels(token, typeId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of sites based on company user has selected for company drop down menu
    getSites(token, tenantId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getSites(token, tenantId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of locations in building based on site selected
    getLocations(token, siteId) {
        return new Promise((resolve, reject) => {

            HardwareAssetDataService.getLocations(token, siteId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }

    // gets the list of assignable assets based on selected type and company
    getAssignableAssets(token, tenantId, typeId, categoryId) {
        return new Promise((resolve, reject) => {

            console.log("Sending the following data to get assignable assets backend: ");
            console.log("Tenant id: ", tenantId);
            console.log("typeId", typeId);
            console.log("categoryId", categoryId);

            HardwareAssetDataService.getAssignableAssets(token, tenantId, typeId, categoryId)
                .then(response => { // take response (next chunk of tickets) and add it to the back of the current state array
                    resolve(response.data);
                })
                .catch(e => {
                    console.log(e);
                    reject(e);
                });
        });
    }


    render() {

        if (!this.props.loggedStatus.loggedIn) {
            return (
                <Redirect to='/' />
            );
        }



        return (
            <>
                <Navbar pageTitle={"Edit Hardware Asset"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }} />
                <Container fluid>
                    <Paper withBorder p="md" radius="md" display="block" m="5px">
                        <Row>
                            <Col md={{ span: 12 }} style={{ textAlign: "center" }}>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: "20px" }}>
                            <Col md={{ span: 12 }} style={{ textAlign: "center" }}>
                                <Form>

                                    {/* 
                                        1. Asset type (drop down) required
                                        2. Name of asset (text) required - must be unique
                                        3. Model name (drop down) required
                                        4. Vendor (drop down)
                                        5. Asset tag (text)
                                        6. Serial Number (text)
                                        7. Bar code (text)
                                        8. Acquisition date (date)
                                        9. Warranty start date (date)
                                        10. Warranty expiration date (date)
                                        11. Site (drop down) required
                                        12. Assigned person (drop down)
                                        13. Assigned location at site (drop down)
                                        14. Lease Start Date (date)
                                        15. Lease End Date (date)
                                        16. Manufacturer Website (text)
                                        17. Asset Category (drop down) required
                                        18. Is Tracked (radio) required
                                        19. Core Asset Assigned To (drop down)
                                    */}
                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormTenant" className="mb-3">
                                                <Form.Label>Company<span className="requiredStar">*</span></Form.Label>
                                                <Form.Control as="select" value={this.state.selected_tenant_name} onChange={(e) => this.handleTenantChange(this.state.tenants, e)} required>

                                                    {this.state.tenants && this.state.tenants.map((tenant) => (
                                                        <>
                                                            <option key={`tenant_${tenant.id}`} onClick={(e) => this.handleTenantChange(this.state.tenants, e)}>{tenant.name_of_tenant}</option>
                                                            {/*this.state.selected_tenant_id === tenant.id ?
                                                                    <option key={`tenant_${tenant.id}`} onClick={() => this.handleTenantChange(tenant.id)} selected>{tenant.name_of_tenant}</option>
                                                                    :
                                                                    <option key={`tenant_${tenant.id}`} onClick={() => this.handleTenantChange(tenant.id)}>{tenant.name_of_tenant}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                    </div>
                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetName" className="mb-3">
                                                <Form.Label>Asset Name<span className="requiredStar">*</span></Form.Label>
                                                <Form.Control type="text" placeholder="ex: Dell 3600c" value={this.state.asset_name_entered} onChange={this.handleAssetNameEnteredChange} required />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetCategory" className="mb-3">
                                                <Form.Label>Asset Category<span className="requiredStar">*</span></Form.Label>
                                                <Form.Control as="select" value={this.state.selected_category_name} onChange={(e) => this.handleSelectedCategoryChange(this.state.asset_categories, e)} required>
                                                    {this.state.asset_categories && this.state.asset_categories.map((category) => (
                                                        <>
                                                            <option key={`category_${category.id}`} onClick={(e) => this.handleSelectedCategoryChange(this.state.asset_categories, e)}>{category.name_of_category}</option>
                                                            {/*this.state.selected_category_id === category.id ?
                                                                    <option key={`category_${category.id}`} onClick={() => this.handleSelectedCategoryChange(category.id)} selected>{category.name_of_category}</option>
                                                                    :
                                                                    <option key={`category_${category.id}`} onClick={() => this.handleSelectedCategoryChange(category.id)}>{category.name_of_category}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Click to add a new item</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openCategoryModal()} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>


                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetType" className="mb-3">
                                                <Form.Label>Asset Type<span className="requiredStar">*</span></Form.Label>
                                                <Form.Control as="select" value={this.state.selected_type_name} onChange={(e) => this.handleSelectedTypeChange(this.state.asset_types, e)} required>
                                                    {this.state.asset_types && this.state.asset_types.map((type) => (
                                                        <>
                                                            <option key={`type_${type.id}`} onClick={(e) => this.handleSelectedTypeChange(this.state.asset_types, e)}>{type.name_of_type}</option>
                                                            {/*this.state.selected_type_id === type.id ?
                                                                    <option key={`type_${type.id}`} onClick={() => this.handleSelectedTypeChange(type.id)} selected>{type.name_of_type}</option>
                                                                    :
                                                                    <option key={`type_${type.id}`} onClick={() => this.handleSelectedTypeChange(type.id)}>{type.name_of_type}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Click to add a new item</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openTypeModal(false)} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetModel" className="mb-3">
                                                <Form.Label>{`Model Name (based on Type selected)`}<span className="requiredStar">*</span></Form.Label>
                                                <Form.Control as="select" value={this.state.selected_model_name} onChange={(e) => this.handleSelectedModelChange(this.state.model_names, e)} required>
                                                    {this.state.model_names && this.state.model_names.map((model) => (
                                                        <>
                                                            <option key={`model_${model.id}`} onClick={(e) => this.handleSelectedModelChange(this.state.model_names, e)}>{model.name_of_model}</option>
                                                            {/*this.state.selected_model_id === model.id ?
                                                                    <option key={`model_${model.id}`} onClick={() => this.handleSelectedModelChange(model.id)} selected>{model.name_of_model}</option>
                                                                    :
                                                                    <option key={`model_${model.id}`} onClick={() => this.handleSelectedModelChange(model.id)}>{model.name_of_model}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                                {/* <Form.Text className="text-muted">
                                                        Note: The values in this drop down are affected by which type you have selected in the Asset Type field above
                                                    </Form.Text> */}
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Click to add a new item</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openModelModal()} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormSite" className="mb-3">
                                                <Form.Label>{`Assigned Site (based on Company selected)`}<span className="requiredStar">*</span></Form.Label>
                                                <Form.Control as="select" value={this.state.selected_site_name} onChange={(e) => this.handleSelectedSiteChange(this.state.sites, e)} required>
                                                    {this.state.sites && this.state.sites.map((site) => (
                                                        <>
                                                            <option key={`site_${site.id}`} onClick={(e) => this.handleSelectedSiteChange(this.state.sites, e)}>{site.name_of_site}</option>
                                                            {/*this.state.selected_site_id === site.id ?
                                                                    <option key={`site_${site.id}`} onClick={() => this.handleSelectedSiteChange(site.id)} selected>{site.name_of_site}</option>
                                                                    :
                                                                    <option key={`site_${site.id}`} onClick={() => this.handleSelectedSiteChange(site.id)}>{site.name_of_site}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Click to add a new item</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openSiteModal()} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormLocationAtSite" className="mb-3">
                                                <Form.Label>{`Location at Site (based on Site selected)`}</Form.Label>
                                                <Form.Control value={this.state.selected_location_in_building_name} onChange={(e) => this.handleSelectedLocationChange(this.state.locations_at_site, e)} as="select">
                                                    {this.state.locations_at_site && this.state.locations_at_site.map((location) => (
                                                        <>
                                                            <option key={`location_${location.id}`} onClick={(e) => this.handleSelectedLocationChange(this.state.locations_at_site, e)}>{`Floor: (${location.floor_number}), Aisle: (${location.aisle_number}), Desk: (${location.desk_number})`}</option>
                                                            {/*this.state.selected_location_in_building_id === location.id ?
                                                                    <option key={`location_${location.id}`} onClick={() => this.handleSelectedLocationChange(location.id)} selected>{`Floor: (${location.floor_number}), Aisle: (${location.aisle_number}), Desk: (${location.desk_number})`}</option>
                                                                    :
                                                                    <option key={`location_${location.id}`} onClick={() => this.handleSelectedLocationChange(location.id)}>{`Floor: (${location.floor_number}), Aisle: (${location.aisle_number}), Desk: (${location.desk_number})`}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Click to add a new item</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openLocationModal()} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssignedPerson" className="mb-3">
                                                <Form.Label>{`Person Assigned To (based on Company selected)`}</Form.Label>
                                                <Form.Control value={this.state.selected_person_name} onChange={(e) => this.handleSelectedAssignedPersonChange(this.state.people, e)} as="select">
                                                    {this.state.people && this.state.people.map((person) => (
                                                        //person.id === 0 ? 
                                                        <option key={`person_${person.id}`} onClick={(e) => this.handleSelectedAssignedPersonChange(this.state.people, e)}>{person.id === 0 ? `${person.full_name}` : `${person.full_name} - (${person.name_of_tenant})`}</option>
                                                        //:
                                                        //<option key={`person_${person.id}`} onClick={(e) => this.handleSelectedAssignedPersonChange(this.state.people, e)}>{`${person.full_name} - (${person.name_of_tenant})`}</option>
                                                        /*person.id === 0 && this.state.selected_person_id === person.id ?
                                                        <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)} selected>{`${person.full_name}`}</option>
                                                        :
                                                        person.id === 0 && this.state.selected_person_id !== person.id ?
                                                        <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)}>{`${person.full_name}`}</option>
                                                        :
                                                        person.id !== 0 && this.state.selected_person_id === person.id ?
                                                        <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)} selected>{`${person.full_name} - (${person.name_of_tenant})`}</option>
                                                        :
                                                        <option key={`person_${person.id}`} onClick={() => this.handleSelectedAssignedPersonChange(person.id)}>{`${person.full_name} - (${person.name_of_tenant})`}</option>*/
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        {/* <span style={{display: "inline-block", paddingLeft: "15px"}}>
                                                <OverlayTrigger
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip>Click to add a new item</Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon className="addFormPlusIcon" style={{color: "black", cursor: "pointer"}} icon={faPlus}/>
                                                </OverlayTrigger>
                                            </span> */}
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetVendor" className="mb-3">
                                                <Form.Label>Vendor</Form.Label>
                                                <Form.Control value={this.state.selected_vendor_name} onChange={(e) => this.handleSelectedVendorChange(this.state.vendors, e)} as="select">
                                                    {this.state.vendors && this.state.vendors.map((vendor) => (
                                                        <>
                                                            <option key={`vendor_${vendor.id}`} onClick={(e) => this.handleSelectedVendorChange(this.state.vendors, e)}>{vendor.name_of_vendor}</option>
                                                            {/*this.state.selected_vendor_id === vendor.id ?
                                                                    <option key={`vendor_${vendor.id}`} onClick={() => this.handleSelectedVendorChange(vendor.id)} selected>{vendor.name_of_vendor}</option>
                                                                    :
                                                                    <option key={`vendor_${vendor.id}`} onClick={() => this.handleSelectedVendorChange(vendor.id)}>{vendor.name_of_vendor}</option>
                                                                */}
                                                        </>
                                                    ))}
                                                </Form.Control>
                                            </Form.Group>
                                        </span>
                                        <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>Click to add a new item</Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openVendorModal()} />
                                            </OverlayTrigger>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetTag" className="mb-3">
                                                <Form.Label>Asset Tag</Form.Label>
                                                <Form.Control type="text" placeholder="ex: John Smith's Laptop" value={this.state.asset_tag_entered} onChange={this.handleAssetTagEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetSerialNumber" className="mb-3">
                                                <Form.Label>Serial Number</Form.Label>
                                                <Form.Control type="text" placeholder="ex: 1234abc789" value={this.state.serial_number_entered} onChange={this.handleSerialNumberEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAssetBarCode" className="mb-3">
                                                <Form.Label>Bar Code</Form.Label>
                                                <Form.Control type="text" placeholder="ex: 0447789" value={this.state.barcode_entered} onChange={this.handleBarcodeEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormIPAddress" className="mb-3">
                                                <Form.Label>IP Address</Form.Label>
                                                <Form.Control type="text" placeholder="ex: 192.158.1.38" value={this.state.ip_address_entered} onChange={this.handleIPAddressEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormMACAddress" className="mb-3">
                                                <Form.Label>MAC Address</Form.Label>
                                                <Form.Control type="text" placeholder="ex: 00:00:5e:00:53:af" value={this.state.mac_address_entered} onChange={this.handleMACAddressEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormRAM" className="mb-3">
                                                <Form.Label>RAM</Form.Label>
                                                <Form.Control type="text" placeholder="ex: 8GB" value={this.state.ram_entered} onChange={this.handleRAMEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormCPU" className="mb-3">
                                                <Form.Label>CPU</Form.Label>
                                                <Form.Control type="text" placeholder="ex: Intel Core i7" value={this.state.cpu_entered} onChange={this.handleCPUEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormAcquisitionDate" className="mb-3">
                                                <Form.Label>Acquistion Date</Form.Label>
                                                <Form.Control type="date" name="addFormAcquisitionDate" value={this.state.aquisition_date_entered} onChange={e => this.handleAcquisitionDateChange(e)}></Form.Control>
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormManufacturerWebsite" className="mb-3">
                                                <Form.Label>Manufacturer Website</Form.Label>
                                                <Form.Control type="text" placeholder="ex: https://www.dell.com" value={this.state.website_entered} onChange={this.handleWebsiteEnteredChange} />
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormWarrantyDates" className="mb-3">
                                                <Form.Label>Warranty Start and End Dates</Form.Label>
                                                <div>
                                                    <span style={{ display: "inline-block" }}>
                                                        <Form.Control type="date" name="addFormWarrantyStartDate" value={this.state.warranty_start_date_entered} onChange={e => this.handleWarrantyStartDateChange(e)}></Form.Control>
                                                    </span>
                                                    <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
                                                        to
                                                    </span>
                                                    <span style={{ display: "inline-block" }}>
                                                        <Form.Control type="date" name="addFormWarrantyEndDate" value={this.state.warranty_end_date_entered} onChange={e => this.handleWarrantyEndDateChange(e)}></Form.Control>
                                                    </span>
                                                </div>
                                            </Form.Group>
                                        </span>
                                    </div>

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormLeaseDates" className="mb-3">
                                                <Form.Label>Lease Start and End Dates</Form.Label>
                                                <div>
                                                    <span style={{ display: "inline-block" }}>
                                                        <Form.Control type="date" name="addFormLeaseStartDate" value={this.state.lease_start_date_entered} onChange={e => this.handleLeaseStartDateChange(e)}></Form.Control>
                                                    </span>
                                                    <span style={{ display: "inline-block", paddingLeft: "15px", paddingRight: "15px" }}>
                                                        to
                                                    </span>
                                                    <span style={{ display: "inline-block" }}>
                                                        <Form.Control type="date" name="addFormLeaseEndDate" value={this.state.lease_end_date_entered} onChange={e => this.handleLeaseEndDateChange(e)}></Form.Control>
                                                    </span>
                                                </div>
                                            </Form.Group>
                                        </span>
                                    </div>

                                    {/* <div style={{width: "100%", textAlign: "left"}}>
                                            <span style={{display: "inline-block", width: "95%"}}>
                                                <Form.Group controlId="addFormManufacturerWebsite" className="mb-3">
                                                    <Form.Label>Manufacturer Website</Form.Label>
                                                    <Form.Control type="text" placeholder="ex: https://www.dell.com" />
                                                </Form.Group>
                                            </span>
                                        </div> */}

                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormTrackAsset" className="mb-3">
                                                <Form.Label>Track Asset</Form.Label>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        checked={this.state.track_asset}
                                                        label="Yes"
                                                        name="track asset radio"
                                                        type="radio"
                                                        id="Yes Track Asset Radio"
                                                        value="true"
                                                        onChange={e => this.handleTrackAssetChange(e)}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        checked={!this.state.track_asset}
                                                        label="No"
                                                        name="track asset radio"
                                                        type="radio"
                                                        id="No Do Not Track Asset Radio"
                                                        value="false"
                                                        onChange={e => this.handleTrackAssetChange(e)}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </span>
                                    </div>

                                    {/* NOTE: This field is used only for front end. It's not stored in the backend */}
                                    <div style={{ width: "100%", textAlign: "left" }}>
                                        <span style={{ display: "inline-block", width: "95%" }}>
                                            <Form.Group controlId="addFormIsPeripheral" className="mb-3">
                                                <Form.Label>Is Peripheral</Form.Label>
                                                <div className="mb-3">
                                                    <Form.Check
                                                        inline
                                                        checked={this.state.is_peripheral}
                                                        label="Yes"
                                                        name="peripheral radio"
                                                        type="radio"
                                                        id="Yes Is Peripheral Radio"
                                                        value="true"
                                                        onChange={e => { this.toggleAddFormAssignedAssetInputVisible(true); this.handleIsPeripheralChange(e) }}
                                                    />
                                                    <Form.Check
                                                        inline
                                                        checked={!this.state.is_peripheral}
                                                        label="No"
                                                        name="peripheral radio"
                                                        type="radio"
                                                        id="Not Peripheral Radio"
                                                        value="false"
                                                        onChange={e => { this.toggleAddFormAssignedAssetInputVisible(false); this.handleIsPeripheralChange(e) }}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </span>
                                    </div>

                                    {this.state.enable_assigned_asset_input ?
                                        <div className="mb-3" style={{ width: "95%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "33%" }}>
                                                <Form.Group controlId="addFormAssignedAssetCategory">
                                                    <Form.Label>Assigned Asset Category</Form.Label>
                                                    <Form.Control value={this.state.selected_assigned_asset_category_name} onChange={(e) => this.handleAssignedAssetCategoryChange(this.state.asset_categories, e)} as="select">
                                                        {this.state.asset_categories && this.state.asset_categories.map((category) => (
                                                            <>
                                                                <option key={`assigned_category_${category.id}`} onClick={(e) => this.handleAssignedAssetCategoryChange(this.state.asset_categories, e)}>{category.name_of_category}</option>
                                                                {/*this.state.selected_assigned_asset_category_id === category.id ?
                                                                        <option key={`assigned_category_${category.id}`} onClick={() => this.handleAssignedAssetCategoryChange(category.id)} selected>{category.name_of_category}</option>
                                                                        :
                                                                        <option key={`assigned_category_${category.id}`} onClick={() => this.handleAssignedAssetCategoryChange(category.id)}>{category.name_of_category}</option>
                                                                    */}
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", width: "33%" }}>
                                                <Form.Group controlId="addFormAssignedAssetType">
                                                    <Form.Label>Assigned Asset Type</Form.Label>
                                                    <Form.Control value={this.state.selected_assigned_asset_type_name} onChange={(e) => this.handleAssignedAssetTypeChange(this.state.asset_types, e)} as="select">
                                                        {this.state.asset_types && this.state.asset_types.map((type) => (
                                                            <>
                                                                <option key={`assigned_type_${type.id}`} onClick={(e) => this.handleAssignedAssetTypeChange(this.state.asset_types, e)}>{type.name_of_type}</option>
                                                                {/*this.state.selected_assigned_asset_type_id === type.id ?
                                                                        <option key={`assigned_type_${type.id}`} onClick={() => this.handleAssignedAssetTypeChange(type.id)} selected>{type.name_of_type}</option>
                                                                        :
                                                                        <option key={`assigned_type_${type.id}`} onClick={() => this.handleAssignedAssetTypeChange(type.id)}>{type.name_of_type}</option>
                                                                    */}
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", width: "33%" }}>
                                                <Form.Group controlId="addFormAssignedAssetName">
                                                    <Form.Label>Assigned Asset Name<span className="requiredStar">*</span></Form.Label>
                                                    <Form.Control value={this.state.selected_assigned_asset_name_name} onChange={(e) => this.handleAssignedAssetNameChange(this.state.assigned_asset_names, e)} as="select">
                                                        {this.state.assigned_asset_names && this.state.assigned_asset_names.map((asset) => (
                                                            <>
                                                                <option key={`assigned_asset_${asset.id}`} onClick={(e) => this.handleAssignedAssetNameChange(this.state.assigned_asset_names, e)}>{asset.name_of_asset}</option>
                                                                {/*this.state.selected_assigned_asset_name_id === asset.id ?
                                                                        <option key={`assigned_asset_${asset.id}`} onClick={() => this.handleAssignedAssetNameChange(asset.id)} selected>{asset.name_of_asset}</option>
                                                                        :
                                                                        <option key={`assigned_asset_${asset.id}`} onClick={() => this.handleAssignedAssetNameChange(asset.id)}>{asset.name_of_asset}</option>
                                                                    */}
                                                            </>
                                                        ))}
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                        </div> :

                                        <div className="mb-3" style={{ width: "95%", textAlign: "left" }}>
                                            <span style={{ display: "inline-block", width: "33%" }}>
                                                <Form.Group controlId="addFormAssignedAssetCategory">
                                                    <Form.Label>Assigned Asset Category</Form.Label>
                                                    <Form.Control as="select" disabled>
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", width: "33%" }}>
                                                <Form.Group controlId="addFormAssignedAssetType">
                                                    <Form.Label>Assigned Asset Type</Form.Label>
                                                    <Form.Control as="select" disabled>
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                            <span style={{ display: "inline-block", width: "33%" }}>
                                                <Form.Group controlId="addFormAssignedAssetName">
                                                    <Form.Label>Assigned Asset Name</Form.Label>
                                                    <Form.Control as="select" disabled>
                                                    </Form.Control>
                                                </Form.Group>
                                            </span>
                                        </div>
                                    }

                                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                                        <span><Button variant="secondary" onClick={() => this.closeAddPage()} className="gothamNarrowFont">Cancel</Button></span>
                                        <span style={{ paddingLeft: "55px" }}><Button id="newDepartmentFormOkButton" onClick={() => { this.submitEditAssetForm() }} className="gothamNarrowFont">Submit</Button></span>
                                    </div>
                                    <div style={{ textAlign: "center", marginTop: "15px" }}>
                                        {this.state.canSeeErrorMessage ?
                                            <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields before submitting.</p>
                                            :
                                            undefined
                                        }
                                        {this.state.canSeeDuplicateNameError ?
                                            <p style={{ color: "red" }}>Error: An asset with this name already exists. Please try a different name.</p>
                                            :
                                            undefined
                                        }
                                    </div>

                                </Form>
                            </Col>
                        </Row>
                    </Paper>
                </Container>

                {/* This modal is for adding asset categories */}
                <Modal
                    show={this.state.show_category_modal}
                    onHide={() => this.closeCategoryModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Asset Category</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormCategoryName" className="mb-3">
                                        <Form.Label>Category Name<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: IT" value={this.state.createCategory_category_name_entered} onChange={this.handleCreateCategoryNameChange} required />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateCategory ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateCategory ?
                                    <p style={{ color: "red" }}>Error: A category with this name already exists. Please try a different name.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeCategoryModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateCategoryForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for adding an asset type */}
                <Modal
                    show={this.state.show_type_modal}
                    onHide={() => this.closeTypeModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Asset Type</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormTypeName" className="mb-3">
                                        <Form.Label>Type Name<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Printer" value={this.state.createType_type_name} onChange={this.handleCreateTypeNameChange} required />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "95%" }}>
                                    <Form.Group controlId="addFormIsCoreAsset" className="mb-3">
                                        <Form.Label>Is Core Asset<span className="requiredStar">*</span></Form.Label>
                                        <div className="mb-3">
                                            <Form.Check
                                                inline
                                                checked={this.state.selected_is_core_value}
                                                label="Yes"
                                                name="peripheral radio"
                                                type="radio"
                                                id="Yes Is Core Radio"
                                                value="true"
                                                onChange={() => this.handleIsCoreChange(true)}
                                            />
                                            <Form.Check
                                                inline
                                                checked={!this.state.selected_is_core_value}
                                                label="No"
                                                name="peripheral radio"
                                                type="radio"
                                                id="Not Core Radio"
                                                value="false"
                                                onChange={() => this.handleIsCoreChange(false)}
                                            />
                                        </div>
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateType ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateType ?
                                    <p style={{ color: "red" }}>Error: A type with this name already exists. Please try a different name.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeTypeModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateTypeForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for creating asset models */}
                <Modal
                    show={this.state.show_model_modal}
                    onHide={() => this.closeModelModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Asset Model</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormModelName" className="mb-3">
                                        <Form.Label>Model Name<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Lenovo Thinkpad x51" value={this.state.model_name_entered} onChange={this.handleCreateModelNameChange} required />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormModelType" className="mb-3">
                                        <Form.Label>{`Asset Type (based on type selected in outer form)`}<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control as="select" required disabled>
                                            {this.state.asset_types && this.state.asset_types.map((type) => (
                                                <>
                                                    {this.state.selected_type_id === type.id ?
                                                        <option key={`modelType_${type.id}`} onClick={() => this.handleCreateModelTypeChange(type.id)} selected>{type.name_of_type}</option>
                                                        :
                                                        <option key={`modelType_${type.id}`} onClick={() => this.handleCreateModelTypeChange(type.id)}>{type.name_of_type}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </span>
                                {/* <span style={{display: "inline-block", paddingLeft: "15px"}}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>Click to add a new item</Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon className="addFormPlusIcon" style={{color: "black", cursor: "pointer"}} icon={faPlus} onClick={() => this.openTypeModal(true)}/>
                                    </OverlayTrigger>
                                </span> */}
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormModelCost" className="mb-3">
                                        <Form.Label>Cost</Form.Label>
                                        <Form.Control type="number" min="0" step="0.01" placeholder="ex: 1250.59" value={parseFloat(this.state.cost_entered).toString()} onChange={this.handleCreateModelCostChange} />
                                        {/* Within the form's submit fxn use: Math.round(3.14159265359 * 100) / 100 to make sure value has 2 decimal places */}
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormModelManufacturer" className="mb-3">
                                        <Form.Label>Manufacturer<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control as="select" required>
                                            {this.state.manufacturers && this.state.manufacturers.map((manufacturer) => (
                                                <>
                                                    {this.state.manufacturer_id_selected === manufacturer.id ?
                                                        <option key={`manufacturer_${manufacturer.id}`} onClick={() => this.handleCreateModelManufacturerChange(manufacturer.id)} selected>{manufacturer.name_of_manufacturer}</option>
                                                        :
                                                        <option key={`manufacturer_${manufacturer.id}`} onClick={() => this.handleCreateModelManufacturerChange(manufacturer.id)}>{manufacturer.name_of_manufacturer}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </span>
                                <span style={{ display: "inline-block", paddingLeft: "15px" }}>
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip>Click to add a new item</Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon className="addFormPlusIcon" style={{ color: "black", cursor: "pointer" }} icon={faPlus} onClick={() => this.openManufacturerModal()} />
                                    </OverlayTrigger>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateModel ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateModel ?
                                    <p style={{ color: "red" }}>Error: A model with this name already exists. Please try a different name.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeModelModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateModelForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for creating a new manufacturer */}
                <Modal
                    show={this.state.show_manufacturer_modal}
                    onHide={() => this.closeManufacturerModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Manufacturer</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormManufacturerName" className="mb-3">
                                        <Form.Label>Manufacturer Name<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Lenovo" value={this.state.manufacturer_name_entered} onChange={this.handleCreateManufacturerNameChange} required />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormManufacturerType" className="mb-3">
                                        <Form.Label>Manufacturer Type<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Hardware" value={this.state.manufacturer_type_entered} onChange={this.handleCreateManufacturerTypeChange} required />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormManufacturerPhone" className="mb-3">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control type="text" placeholder="ex: 555-555-5555" value={this.state.manufacturer_phone_number_entered} onChange={this.handleCreateManufacturerPhoneChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateManufacturer ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateManufacturer ?
                                    <p style={{ color: "red" }}>Error: A manufacturer with this name and type already exists. Please try again.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeManufacturerModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateManufacturerForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for adding sites */}
                <Modal
                    show={this.state.show_site_modal}
                    onHide={() => this.closeSiteModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "left", cursor: "default" }} className="gothamNarrowFont">Create Site</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormSiteCompany" className="mb-3">
                                        <Form.Label>Company<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control as="select" required disabled>
                                            {this.state.tenants && this.state.tenants.map((tenant) => (
                                                <>
                                                    {this.state.selected_tenant_id === tenant.id ?
                                                        <option key={`add_site_tenant_${tenant.id}`} selected>{tenant.name_of_tenant}</option>
                                                        :
                                                        <option key={`add_site_tenant_${tenant.id}`}>{tenant.name_of_tenant}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control>
                                        <Form.Text className="text-muted">
                                            Note: This value is controlled by which company you have selected on the add hardware asset screen
                                        </Form.Text>
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormSiteName" className="mb-3">
                                        <Form.Label>Site Name<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Irvine Office" value={this.state.site_name_entered} onChange={this.handleCreateSiteNameChange} required />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormSiteStreetAddress" className="mb-3">
                                        <Form.Label>Street Address</Form.Label>
                                        <Form.Control type="text" placeholder="ex: 1111 Wilmington Pl" value={this.state.site_street_address_entered} onChange={this.handleCreateSiteStreetAddressChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormSiteCity" className="mb-3">
                                        <Form.Label>City</Form.Label>
                                        <Form.Control type="text" placeholder="ex: Irvine" value={this.state.site_city_entered} onChange={this.handleCreateSiteCityChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormSiteState" className="mb-3">
                                        <Form.Label>State</Form.Label>
                                        <Form.Control type="text" placeholder="ex: CA" value={this.state.site_state_entered} onChange={this.handleCreateSiteStateChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormSiteZipCode" className="mb-3">
                                        <Form.Label>Zip Code</Form.Label>
                                        <Form.Control type="text" placeholder="ex: 99999" value={this.state.site_zip_code_entered} onChange={this.handleCreateSiteZipCodeChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateSite ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateSite ?
                                    <p style={{ color: "red" }}>Error: A site with this name already exists. Please try again.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeSiteModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateSiteForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for adding locations at sites */}
                <Modal
                    show={this.state.show_location_modal}
                    onHide={() => this.closeLocationModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Location at Site</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%", textAlign: "left" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormLocationSite" className="mb-3">
                                        <Form.Label>{`Site (based on selection in outer form)`}<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control as="select" required disabled>
                                            {this.state.sites && this.state.sites.map((site) => (
                                                <>
                                                    {this.state.selected_site_id === site.id ?
                                                        <option key={`add_site_${site.id}`} selected>{site.name_of_site}</option>
                                                        :
                                                        <option key={`add_site_${site.id}`}>{site.name_of_site}</option>
                                                    }
                                                </>
                                            ))}
                                        </Form.Control>
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormLocationFloor" className="mb-3">
                                        <Form.Label>Floor<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: 1" required value={this.state.location_floor_entered} onChange={this.handleCreateLocationFloorChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormLocationAisle" className="mb-3">
                                        <Form.Label>Aisle<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: 1" required value={this.state.location_aisle_entered} onChange={this.handleCreateLocationAisleChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormLocationDesk" className="mb-3">
                                        <Form.Label>Desk<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: 1" required value={this.state.location_desk_entered} onChange={this.handleCreateLocationDeskChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateLocation ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateLocation ?
                                    <p style={{ color: "red" }}>Error: A location with this information already exists. Please try again.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeLocationModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateLocationForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

                {/* This modal is for adding vendors */}
                <Modal
                    show={this.state.show_vendor_modal}
                    onHide={() => this.closeVendorModal()}
                    backdrop="static"
                    keyboard={false}
                    size="lg"
                    centered
                >
                    <Form>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                <div style={{ textAlign: "center", cursor: "default" }} className="gothamNarrowFont">Create Vendor</div>
                                <div style={{ cursor: "default", fontSize: "15px" }} className="gothamNarrowLightFont"><span className="requiredStar">*</span> indicates a required field</div>
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div style={{ width: "100%" }}>
                                <span style={{ display: "inline-block", width: "90%" }}>
                                    <Form.Group controlId="addFormVendorName" className="mb-3">
                                        <Form.Label>Vendor Name<span className="requiredStar">*</span></Form.Label>
                                        <Form.Control type="text" placeholder="ex: Dell EMC" required value={this.state.vendor_name_entered} onChange={this.handleCreateVendorNameChange} />
                                    </Form.Group>
                                </span>
                            </div>
                            <div style={{ textAlign: "center", marginTop: "15px" }}>
                                {this.state.canSeeErrorMessageCreateVendor ?
                                    <p style={{ color: "red" }}>Error: One or more required form fields has not been filled out. Please complete required fields.</p>
                                    :
                                    undefined
                                }
                                {this.state.canSeeDuplicateNameErrorCreateVendor ?
                                    <p style={{ color: "red" }}>Error: A vendor with this name already exists. Please try again.</p>
                                    :
                                    undefined
                                }
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div style={{ paddingRight: "15px" }}>
                                <Button variant="secondary" onClick={() => this.closeVendorModal()} className="gothamNarrowFont">Cancel</Button>
                            </div>
                            <div>
                                <Button id="newDepartmentFormOkButton" onClick={() => { this.submitCreateVendorForm() }} className="gothamNarrowFont">Submit</Button>
                            </div>
                        </Modal.Footer>
                    </Form>
                </Modal>

            </>
        );

    }

}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT })(EditHardwareAsset);