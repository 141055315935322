import React, { useEffect, useState } from 'react';
import {
    Paper,
    Group,
    Progress,
    Box,
    SimpleGrid,
    Text
} from '@mantine/core';
import MonitoringDataService from '../../../../services/monitoring-activities';

function TaniumOSCount(props) {
    const [total, setTotal] = useState(0);
    const [segments, setSegments] = useState([]);
    const [descriptions, setDescriptions] = useState([]);

    useEffect(() => {
        if (props.loggedStatus.loggedIn) {
            props.onAccessTokenRequest(props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token !== undefined && token !== null && token !== "") {
                    MonitoringDataService.getOSCounts(props.selectedTenantId, props.selectedTenant, props.loggedStatus.id, token).then(response => {
                        // data returned as [{osPlatform: "Windows", count: 4},  {osPlatform: "Linux", count: 14}, {...}]
                        console.log(response);
                        const colors = ["blue", "red", "green", "cyan"];
                        var colorIndex = 0;
                        var total = 0;
                        response.data.forEach((r) => { total += r.count; });

                        var s = [];
                        var d = [];
                        response.data.forEach((r) => {
                            var color = colors[colorIndex++];
                            var part = (r.count / total) * 100;

                            s.push(<Progress.Section value={part} color={color} key={color}>
                                {part > 10 && <Progress.Label>{Math.round(part)}%</Progress.Label>}
                            </Progress.Section>);

                            d.push(
                                <Box key={r.osPlatform} style={{ borderBottomColor: color }}>
                                    <Text tt="uppercase" fz="xs" c="dimmed" fw={700}>
                                        {r.osPlatform}
                                    </Text>

                                    <Group justify="space-between" align="flex-end" gap={0}>
                                        <Text fw={700}>{r.count}</Text>
                                        <Text c={color} fw={700} size="sm">
                                            {Math.round(part)}%
                                        </Text>
                                    </Group>
                                </Box>);
                        })

                        setTotal(total);
                        setSegments(s);
                        setDescriptions(d);
                    })
                        .catch(e => {
                            console.log(e);
                        });

                }
            });
        }
    }, []);

    return (
        <Paper withBorder radius="md" p="10px" h="100%" w="100%" style={{ verticalAlign: "top" }}>
            <Group justify="space-between">
                <Group align="flex-end" gap="xs">
                    <Text fz="xl" fw={700}>
                        {total} Endpoints
                    </Text>
                </Group>
            </Group>

            <Text c="dimmed" fz="sm">
                OS Type
            </Text>

            <Progress.Root size={34} mt={40}>
                {segments}
            </Progress.Root>
            <SimpleGrid cols={{ base: 1, xs: 3 }} mt="xl">
                {descriptions}
            </SimpleGrid>
        </Paper>
    );
}

export default TaniumOSCount;