import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import UserDataService from '../services/user-activities';
import { ACCESSTOKEN, USER_DETAILS, LOGOUT } from '../features/actions/Is-Logged-Actions';
import { IconChevronsDown } from '@tabler/icons-react';
import {
    Text,
    Switch,
    Flex,
    Badge,
    Drawer,
    Table,
    ScrollArea
} from '@mantine/core';

import ToggleColorSchemeSwitch from "./Sidebar/ToggleColorSchemeSwitch";

import { store } from '../app/store';

import { withRouter } from 'react-router-dom';

import logo from "../images/Cumulus_Logo-Gray.png";

import { TextInput, ActionIcon, useMantineTheme, rem } from '@mantine/core';
import { IconSearch, IconArrowRight, IconBell, IconBellFilled } from '@tabler/icons-react';

import HardwareTicketDataService from '../services/hardware-ticket-activities';
import SoftwareAssetDataService from '../services/software-asset-activities';

export function InputWithButton(props) {
    const theme = useMantineTheme();

    return (
        <TextInput
            radius="xl"
            size="md"
            mt="18px"
            mr="18px"
            ml="auto"
            placeholder="Search questions"
            rightSectionWidth={42}
            leftSection={<IconSearch style={{ width: rem(18), height: rem(18) }} stroke={1.5} />}
            rightSection={
                <ActionIcon size={32} radius="xl" color={theme.primaryColor} variant="filled">
                    <IconArrowRight style={{ width: rem(18), height: rem(18) }} stroke={1.5} />
                </ActionIcon>
            }
            {...props}
        />
    );
}

//import { LOGOUT } from '../features/actions/Is-Logged-Actions';

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.intervalId = null;

        this.state = {
            userExists: "",
            userDetails: {},
            errorMessage: "",        // Will display any errors returned by the API call

            contract_warnings: [
                // {
                //     id: 2,
                //     company: "W&A",
                //     name: "Huntress",
                //     days_until_due: -5,
                //     days_overdue: 5
                // },
                // {
                //     id: 1,                   // this is the id of the contract - used for hyperlink
                //     company: "LPA",
                //     name: "Proton",
                //     days_until_due: 4,
                //     days_overdue: 0         // if overdue is anything over 0, it will take precident over until due
                // }
            ],
            ticket_warnings: [
                // {
                //     id: 2,
                //     title: "Email is down",
                //     time_until_due_value: 0,
                //     time_until_due_units: "",
                //     time_overdue_value: 2,
                //     time_overdue_units: "hours"
                // },
                // {
                //     id: 1,                   // id of the ticket - used for hyperlink
                //     title: "Help with my monitor",
                //     time_until_due_value: 30,       // can use the overdue tickets endpoint for this I think
                //     time_until_due_units: "minutes",
                //     time_overdue_value: 0,
                //     time_overdue_units: ""
                // }
            ],

            show_notification_drawer: false
        };
    }

    componentDidMount() {

        // run the check for notification items when the component first loads
        this.getNotificationItems();

        this.getContractNotificationItems();

        // setup a check for the notification items at a regular interval
        // this.intervalId = setInterval(() => {
        //     this.getNotificationItems();
            
        // }, 300000);     // number of minutes in between runs in milliseconds (300000 = 5 minutes)
        
    }

    componentWillUnmount() {
        clearInterval(this.intervalId);
    }

    // this function gets CONTRACT related info (there's a separate one for tickets)
    getContractNotificationItems() {
        SoftwareAssetDataService.getDangerContracts(this.props.loggedStatus.accessToken, this.props.loggedStatus.id, 0).then(response => {
            console.log("response from get danger contracts", response);
            this.setState({
                contract_warnings: response.data.results
            }, () => {
                // now get the contracts in the yellow zone
                SoftwareAssetDataService.getWarningContracts(this.props.loggedStatus.accessToken, this.props.loggedStatus.id, 0).then(response => {
                    this.setState({
                        contract_warnings: [...this.state.contract_warnings, ...response.data.results]
                    });
                })
                .catch(e => {
                    console.log(e);
                })
            });
        })
        .catch(e => {
            console.log(e);
        });
    }

    // this function gets TICKET related info (there's a separate one for software contracts)
    getNotificationItems() {
        //this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
            // if the token can't be refreshed, force a logout
            // if (token === undefined || token === null || token === "") {
            //     console.log("Token was expired and can't refresh, so force logging out this user!");
            //     this.props.LOGOUT();        // logout of global state
            //     this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
            // }

            // else {
            //     this.props.ACCESSTOKEN(token);
                // if the person calling in is an admin, we're going to give them everyone's ticket data
                if(this.props.userPermissions.can_manage_assignments) {
                    HardwareTicketDataService.getOverdueTickets(0, this.props.loggedStatus.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken).then(response => {
                        console.log("This is overdue tickets coming from navbar", response);
                        let ticketNotificationList = [];
                        for(let i = 0; i < response.data.results.length; i++) {

                            let notificationObject = {}

                            if(response.data.results[i].late_text !== undefined && response.data.results[i].late_text !== null && response.data.results[i].late_text !== "") {
                                let infoArray = response.data.results[i].late_text.split(" ");

                                notificationObject.time_overdue_value = parseInt(infoArray[0]);

                                if(infoArray[1] === "Business") {
                                    notificationObject.time_overdue_units = `${infoArray[1]} ${infoArray[2]}`
                                }

                                else {
                                    notificationObject.time_overdue_units  = infoArray[1];
                                }
                            }

                            notificationObject.title = response.data.results[i].ticket_title;
                            notificationObject.time_until_due_value = 0;
                            notificationObject.time_until_due_units = "";
                            notificationObject.id = response.data.results[i].id;
                            notificationObject.source = response.data.results[i].source;

                            ticketNotificationList.push(notificationObject);

                            
                        }

                        this.setState({
                            ticket_warnings: ticketNotificationList
                        }, () => {
                            HardwareTicketDataService.getWarningTickets(0, this.props.loggedStatus.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken).then(response => {
                                console.log("This is the warning tickets coming from navbar", response);

                                let ticketNotificationList = [];
                                for(let i = 0; i < response.data.results.length; i++) {

                                    let notificationObject = {}

                                    if(response.data.results[i].late_text !== undefined && response.data.results[i].late_text !== null && response.data.results[i].late_text !== "") {
                                        let infoArray = response.data.results[i].late_text.split(" ");

                                        notificationObject.time_until_due_value = parseInt(infoArray[0]);
                                        if(infoArray[1] === "Business") {
                                            notificationObject.time_until_due_units = `${infoArray[1]} ${infoArray[2]}`
                                        }

                                        else {
                                            notificationObject.time_until_due_units  = infoArray[1];
                                        }
                                    }

                                    notificationObject.title = response.data.results[i].ticket_title;
                                    notificationObject.time_overdue_value = 0;
                                    notificationObject.time_overdue_units = "";
                                    notificationObject.id = response.data.results[i].id;
                                    notificationObject.source = response.data.results[i].source;

                                    ticketNotificationList.push(notificationObject);
                                    
                                }

                                this.setState({
                                    ticket_warnings: [...this.state.ticket_warnings, ...ticketNotificationList]
                                });
                            })
                            .catch(e => {
                                console.log(e);
                            });
                        });

                        //console.log("Here's the notification object list", ticketNotificationList);

                        
                    })
                    .catch(e => {
                        console.log(e);
                    });

                }

                // if they are NOT an admin, we're going to call a special version of the ticket endpoints that only returns their own tickets
                else {
                    HardwareTicketDataService.getOverdueTicketsRegularTechNotificationCenter(0, this.props.loggedStatus.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken).then(response => {
                        console.log("Here is the response for a regular tech's tickets from navbar: ", response);
                        let ticketNotificationList = [];
                        for(let i = 0; i < response.data.results.length; i++) {

                            let notificationObject = {}

                            if(response.data.results[i].late_text !== undefined && response.data.results[i].late_text !== null && response.data.results[i].late_text !== "") {
                                let infoArray = response.data.results[i].late_text.split(" ");

                                notificationObject.time_overdue_value = parseInt(infoArray[0]);
                                if(infoArray[1] === "Business") {
                                    notificationObject.time_overdue_units = `${infoArray[1]} ${infoArray[2]}`
                                }

                                else {
                                    notificationObject.time_overdue_units  = infoArray[1];
                                }
                            }

                            notificationObject.title = response.data.results[i].ticket_title;
                            notificationObject.time_until_due_value = 0;
                            notificationObject.time_until_due_units = "";
                            notificationObject.id = response.data.results[i].id;
                            notificationObject.source = response.data.results[i].source;

                            ticketNotificationList.push(notificationObject);

                            
                        }

                        this.setState({
                            ticket_warnings: ticketNotificationList
                        }, () => {
                            //console.log("get warning tickets here");
                            HardwareTicketDataService.getWarningTicketsRegularTechNotificationCenter(0, this.props.loggedStatus.id, this.props.loggedStatus.timezone_adjustment_factor, this.props.loggedStatus.accessToken).then(response => {
                                console.log("Response from warning tickets regular tech notification center: ", response);
                                let ticketNotificationList = [];
                                for(let i = 0; i < response.data.results.length; i++) {

                                    let notificationObject = {}

                                    if(response.data.results[i].late_text !== undefined && response.data.results[i].late_text !== null && response.data.results[i].late_text !== "") {
                                        let infoArray = response.data.results[i].late_text.split(" ");

                                        notificationObject.time_until_due_value = parseInt(infoArray[0]);
                                        if(infoArray[1] === "Business") {
                                            notificationObject.time_until_due_units = `${infoArray[1]} ${infoArray[2]}`
                                        }

                                        else {
                                            notificationObject.time_until_due_units  = infoArray[1];
                                        }
                                    }

                                    notificationObject.title = response.data.results[i].ticket_title;
                                    notificationObject.time_overdue_value = 0;
                                    notificationObject.time_overdue_units = "";
                                    notificationObject.id = response.data.results[i].id;
                                    notificationObject.source = response.data.results[i].source;

                                    ticketNotificationList.push(notificationObject);
                                    
                                }

                                this.setState({
                                    ticket_warnings: [...this.state.ticket_warnings, ...ticketNotificationList]
                                });

                            })
                            .catch(e => {
                                console.log(e);
                            });
                        })
                    })
                    .catch(e => {
                        console.log(e);
                    });
                }
            //}
        //})
    }

    convertPageTitle(windowLocation) {
        var formattedLocation = "";
        console.log(windowLocation);

        windowLocation = windowLocation.replace("hardwareTickets", "knowledgebase");
        windowLocation = windowLocation.replace("userHome", "dashboard");

        // Ignore first character because its always /
        for (var i = 1; i < windowLocation.length; i++) {
            var letter = windowLocation[i];
            if (i === 1) {
                letter = letter.toUpperCase();
            }
            if (letter == letter.toUpperCase() && letter != letter.toLowerCase()) {
                formattedLocation += " ";
            }
            if (letter == "/") {
                letter = " - ";
            }
            formattedLocation += letter;
        }
        return formattedLocation;
    }

    openNotificationCenter() {
        this.setState({
            show_notification_drawer: true
        });
    }

    closeNotificationCenter() {
        this.setState({
            show_notification_drawer: false
        });
    }

    goToTicket(type, id) {
        console.log("type: ", type);
        console.log("id: ", id);
        if(type === "Hardware") {
            this.props.history.push(`/hardwareTickets/${id}`)
        }
        else {
            this.props.history.push(`/clarifyTicket/${id}`)
        }
    }

    render() {
        const isLoggedIn = this.props.loggedStatus.loggedIn;
        let state = store.getState();

        let username;

        //if(!this.state.userExists) {
        if (this.props.loggedStatus.fullName === 'undefined undefined' || this.props.loggedStatus.fullName === '') {
            username = "Account";
        }

        else {
            username = this.props.loggedStatus.fullName;
            //    if(this.state.userDetails.middle_name === null) {
            //         //username = this.props.userDetails.first_name + " " + this.props.userDetails.last_name;
            //    }

            //    else {
            //         username = this.state.userDetails.first_name + " " + this.state.userDetails.middle_name + " " + this.state.userDetails.last_name;
            //    }
        }

        const location = this.convertPageTitle(window.location.pathname);
        console.log(state.dashboard);

        return (
            <>
                <div style={{
                    position: "fixed",
                    top: "0",
                    left: "0",
                    width: "100%",
                    zIndex: "5",
                    height: "80px",
                    paddingLeft: "80px",
                    backgroundColor: "var(--mantine-color-default)",
                    display: "flex",
                    borderBottom: "1px solid var(--mantine-color-blue-6)",
                    color: "var(--mantine-color-text)"
                }}>
                    <IconChevronsDown style={{ height: "40px", margin: "20px" }} />
                    <Text lh="77px" fz="20px">{this.props.pageTitle ? this.props.pageTitle : location}</Text>
                    {this.props.children}
                    <Flex
                    justify="flex-end"
                    align="center" 
                    ml="auto"
                    gap="xl"
                    >
                        {this.state.contract_warnings.length === 0 && this.state.ticket_warnings.length === 0 ?
                            <IconBell style={{cursor: "pointer"}} onClick={() => this.openNotificationCenter()}/>
                            :
                            <span><IconBellFilled style={{cursor: "pointer"}} onClick={() => this.openNotificationCenter()}/><Badge size="sm" color="red" circle style={{marginLeft: "5px"}}>{this.state.contract_warnings.length + this.state.ticket_warnings.length}</Badge></span>
                        }
                        <ToggleColorSchemeSwitch />
                    </Flex>
                    
                </div>

                <Drawer 
                    offset={8} 
                    radius="md" 
                    position="right" 
                    opened={this.state.show_notification_drawer} 
                    onClose={() => this.closeNotificationCenter()} 
                    title="Notification Center"
                    overlayProps={{ backgroundOpacity: 0.5, blur: 4 }}
                    size="lg"
                >
                    {this.state.contract_warnings.length > 0 && (this.props.userPermissions.can_see_assets || this.props.userPermissions.can_edit_assets || this.props.userPermissions.can_add_assets || this.props.userPermissions.can_delete_assets) ?
                        <>
                        
                            
                            <Table highlightOnHover stickyHeader style={{marginBottom: "20px"}}>
                                <Table.Thead>
                                    <Table.Tr style={{position: "sticky", top: 0, zIndex: 999}}>
                                        <Table.Th>Contracts</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <ScrollArea h={332}/* style={{height: "350px"}} */>
                                <Table.Tbody>
                                    {this.state.contract_warnings && this.state.contract_warnings.map((warning) => (
                                        <Table.Tr style={{cursor: "pointer"}} onClick={() => this.props.history.push(`/contracts/${warning.id}`)}>
                                            <Table.Td>
                                                <div style={{position: "relative"}}>
                                                    {warning.color === "red" ?
                                                        <span style={{position: "absolute", top: "12px"/* , left: "-15px" */}}><Badge color="red" size='xs'></Badge></span>
                                                        :
                                                        <span style={{position: "absolute", top: "12px"/* , left: "-15px" */}}><Badge color="yellow" size='xs'></Badge></span>
                                                    }
                                                    <span>
                                                        <div style={{paddingLeft: "25px"}} className="mantineBlue">
                                                            {warning.name}
                                                        </div>
                                                        <div style={{fontSize: "10px", paddingLeft: "25px"}}>
                                                            <i>{warning.company}</i>
                                                        </div>
                                                        <div style={{fontSize: "10px", paddingLeft: "25px"}}>
                                                            <i>{warning.days_overdue > 0 ? `Days Overdue: ${warning.days_overdue}` : `Days Unitl Due: ${warning.days_until_due}`}</i>
                                                        </div>
                                                    </span>
                                                </div>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                                    
                                </Table.Tbody>
                                </ScrollArea>
                            </Table>
                        
                        </>
                        :
                        undefined
                    }

                    {this.state.ticket_warnings.length > 0 ? 
                        
                            <>
                            <Table highlightOnHover stickyHeader /* style={{overflowY: "scroll"}} */>
                                <Table.Thead>
                                    <Table.Tr style={{position: "sticky", top: 0, zIndex: 999}}>
                                        <Table.Th>Tickets</Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <ScrollArea h={306}/* style={{height: "350px"}} */>
                                <Table.Tbody>
                                    {this.state.ticket_warnings && this.state.ticket_warnings.map((warning)=> (
                                        <Table.Tr style={{cursor: "pointer"}} onClick={() => this.goToTicket(warning.source, warning.id)}>
                                            <Table.Td>
                                                <div style={{position: "relative"}}>
                                                    {warning.time_overdue_value > 0 ? 
                                                        <span style={{position: "absolute", top: "4px"/* , left: "-15px" */}}><Badge color="red" size='xs'></Badge></span>
                                                        :
                                                        <span style={{position: "absolute", top: "4px"/* , left: "-15px" */}}><Badge color="yellow" size='xs'></Badge></span>
                                                    }
                                                    <span>
                                                        <div style={{paddingLeft: "25px"}} className="mantineBlue">
                                                            {warning.title}
                                                        </div>
                                                        <div style={{paddingLeft: "25px", fontSize: "10px"}}>
                                                            <i>{warning.time_overdue_value > 0 ? `Overdue By: ${warning.time_overdue_value} ${warning.time_overdue_units}`: `Due In: ${warning.time_until_due_value} ${warning.time_until_due_units}`}</i>
                                                        </div>
                                                    </span>
                                                </div>
                                            </Table.Td>
                                        </Table.Tr>
                                    ))}
                                </Table.Tbody>
                                </ScrollArea>
                            </Table>
                            </>
                            :
                            undefined
                    }
                </Drawer>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        userPermissions: state.userPermissions
    };
}

export default withRouter(connect(mapStateToProps, { ACCESSTOKEN, USER_DETAILS, LOGOUT })(Navbar));