const initialState = {
    tenant_id_selected_user_list_filter: 0,
    tenant_name_selected_user_list_filter: "All",
    role_id_selected_user_list_filter: 0,
    role_name_selected_user_list_filter: "All",
    search_text_entered_user_list_filter: ""
};

function userListFilterReducer(state = initialState, action) {
    switch(action.type) {
        case "UPDATE_SELECTED_TENANT_USER_LIST_FILTER":
            //console.log("Login event detected");
            //console.log(action.payload);
            if(action.payload) {
                return {
                    ...state,
                    tenant_id_selected_user_list_filter: action.payload.id,
                    tenant_name_selected_user_list_filter: action.payload.name 
                };
            }
            break;

        case "UPDATE_SELECTED_ROLE_USER_LIST_FILTER":
            if(action.payload) {
                return {
                    ...state,
                    role_id_selected_user_list_filter: action.payload.id,
                    role_name_selected_user_list_filter: action.payload.name
                };
            }
            break;

        case "UPDATE_SEARCH_TEXT_USER_LIST_FILTER":
            if(action.payload === null || action.payload === "") {
                return {
                    ...state,
                    search_text_entered_user_list_filter: ""
                }
            }
            else {
                return {
                    ...state,
                    search_text_entered_user_list_filter: action.payload
                }
            }

        case "RESET_USER_LIST_FILTERS":
            return {
                ...state,
                tenant_id_selected_user_list_filter: 0,
                tenant_name_selected_user_list_filter: "All",
                role_id_selected_user_list_filter: 0,
                role_name_selected_user_list_filter: "All",
                search_text_entered_user_list_filter: ""
            }


        default:
            return state;


    }
}

export default userListFilterReducer;