export const UPDATE_ACTIVE_REQUESTER = content => ({
    type: "UPDATE_ACTIVE_REQUESTER",
    payload: content
});

export const ALERT_REQUESTER_CHANGE_FROM_DROPDOWN = content => ({
    type: "ALERT_REQUESTER_CHANGE_FROM_DROPDOWN"
});

export const REQUESTER_CHANGE_RESOLVED = content => ({
    type: "REQUESTER_CHANGE_RESOLVED"
});