import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import FormControl from "react-bootstrap/FormControl";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Select,
} from '@mantine/core';

import {
  connect, /*, useDispatch*/
  useSelector
} from 'react-redux';
import { store } from '../app/store';

import HardwareTicketDataService from '../services/hardware-ticket-activities';

import { UPDATE_ACTIVE_PRIORITY, ALERT_PRIORITY_CHANGE_FROM_DROPDOWN } from "../features/actions/My-Assigned-Tickets-Actions";

//import { ADD_DEPARTMENT, SHOW_ADD_DEPARTMENT_FORM, CHANGE_ACTIVE_DEPARTMENT, RESET_DEPARTMENT, ALERT_DEPARTMENT_CHANGE_FROM_DROPDOWN } from '../features/actions/Dynamic-Table-Actions';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <a
    href="#chosenPriority"
    ref={ref}
    id="selectPriorityDropdownButton"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    &#x25bc;
  </a>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <FormControl
          autoFocus
          className="mx-3 my-2 w-auto gothamNarrowLightFont"
          placeholder="Type to filter..."
          onChange={(e) => setValue(e.target.value)}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children).filter(
            (child) =>
              !value || child.props.children.toLowerCase().startsWith(value),
          )}
        </ul>
      </div>
    );
  },
);


// function resetDepartment() {
//   return {
//     type: 'RESET_DEPARTMENT'
//   }
// }

function updateActivePriority(priority_name, priority_id) {
  return {
    type: 'UPDATE_ACTIVE_PRIORITY',
    payload: { name: priority_name, id: priority_id }
  }
}

function alertAboutPriorityChange() {
  return {
    type: 'ALERT_PRIORITY_CHANGE_FROM_DROPDOWN'
  }
}

function handlePriorityChange(priority_name, priority_id) {
  let state = store.getState();

  // If they changed to a different person than what is currently selected, do all this stuff, otherwise do nothing
  if (priority_id !== state.myAssignedTickets.priority_id) {

    //store.dispatch(resetDepartment());  // remove the old data so we free up space in the front end data store
    store.dispatch(updateActivePriority(priority_name, priority_id));
    store.dispatch(alertAboutPriorityChange()); // tell My-Assigned-Tickets.js to run it's componentDidUpdate fxn and get new assigned ticket data
  }


}

function PrioritySelector(props) {
  let state = store.getState();
  //let dispatch = useDispatch();
  const accessToken = useSelector((state) => state.logged.accessToken);
  const username = useSelector((state) => state.logged.username);
  const accessTokenRequest = props.onAccessTokenRequest
  const [priorityList, setPriorityList] = React.useState([]);   // create the empty priority array in the functional component's state

  React.useEffect(() => {

    if (priorityList.length === 0) {
      accessTokenRequest(username).then((token) => {

        // Put in the "All default information" into drop down
        if (priorityList.length === 0) {
          priorityList.push({ sla_name: "All", id: 0 });
        }

        console.log("Hello from PRIORITY DROP DOWN!");

        HardwareTicketDataService.getSlas(token)
          .then(response => {
            console.log("Hello from get slas!");
            console.log(response);

            let updatedPriorityList = [...priorityList];

            response.data.results.map((sla) => {
              updatedPriorityList.push(sla);
              return updatedPriorityList;
            });

            setPriorityList(updatedPriorityList);
          })
          .catch(e => {
            console.log(e);
          });

      })
    }

  }, [priorityList, setPriorityList, accessTokenRequest, username]);    // any state changes now have the priority list as a dependency to watch out for

  const [prioritySelected, setPrioritySelected] = useState("All");
  console.log("Priority Name = ", state.myAssignedTickets.priority_name);


  return (
    <>
      <Select
        label="Priority"
        placeholder="Pick value"
        data={priorityList.map((sla) => (sla.sla_name))}
        display="inline-block"
        onChange={(s) => {
          var sla = priorityList.find((e) => e.sla_name == s);
          if (sla) handlePriorityChange(sla.sla_name, sla.id);
          setPrioritySelected(s);
        }}
        //value={prioritySelected}
        //defaultValue={state.myAssignedTickets.priority_name}
        value={state.myAssignedTickets.priority_name}
        w="200px"
        allowDeselect={false}
      />
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    loggedStatus: state.logged,
    myAssignedTickets: state.myAssignedTickets
  };
}

export default connect(mapStateToProps, { UPDATE_ACTIVE_PRIORITY, ALERT_PRIORITY_CHANGE_FROM_DROPDOWN })(PrioritySelector);