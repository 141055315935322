import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { ACCESSTOKEN, LOGOUT } from '../../features/actions/Is-Logged-Actions';
import { connect } from 'react-redux';

import Container from 'react-bootstrap/Container';
//import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Navbar from '../../components/Navbar';
import {
    Button,
    Grid,
    Group,
    Modal,
    Paper,
    Select,
    Table,
    Tabs,
    Text,
    Textarea,
    TextInput,
    Tooltip
} from '@mantine/core';

import { IconEdit, IconX } from "@tabler/icons-react";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faPlus } from "@fortawesome/free-solid-svg-icons";

import HardwareAssetDataService from '../../services/hardware-asset-activities';
import SoftwareAssetDataService from '../../services/software-asset-activities';
import UserDataService from '../../services/user-activities';
import { Form } from 'react-bootstrap';

class ViewSpecificSoftwareAsset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant_name: "",     // the name of the company who is managing this instance of Cumulus (ie: the MSP) - this is tied to the person logged in using the app
            tenant_id: 0,

            selected_form_tab: "Overview",

            softwareAsset: {},   // {name_of_software: "", publisher: "", status: "", type: "", manager_first_name: "", manager_middle_name: "", manager_last_name: "", description: "", notes: "", tenant_id: 1}
        
            contracts: [],       // contracts associated with this tenant / MSP + software id

            show_delete_modal: false
        }
    }


    componentDidMount() {
        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
                // if the token can't be refreshed, force a logout
                if (token === undefined || token === null || token === "") {
                    console.log("Token was expired and can't refresh, so force logging out this user!");
                    this.props.LOGOUT();        // logout of global state
                    this.props.onLogout(this.props.loggedStatus.username);      // logout from Microsoft
                }

                else {
                    this.props.ACCESSTOKEN(token);

                    UserDataService.getTenantName(this.props.loggedStatus.primary_tenant_id, token).then(response => {
                        this.setState({
                            tenant_name: response.data.results[0].name_of_tenant,
                            tenant_id: response.data.results[0].id
                        });
                    });

                    SoftwareAssetDataService.getSpecificAsset(token, this.props.match.params.id, this.props.loggedStatus.primary_tenant_id, this.props.loggedStatus.id).then(response => {
                        console.log(response);

                        if(response && response.data && response.data.results && response.data.results.length > 0) {                           
                            this.setState({
                                softwareAsset: response.data.results[0],
                                contracts: response.data.contracts
                            });
                        }

                        else {
                            console.log("No item received");
                        }
                    });
                }

            });
        }
    }


    closeDeleteModal() {
        this.setState({
            show_delete_modal: false
        });
    }

    openDeleteModal() {
        this.setState({
            show_delete_modal: true
        });
    }

    deleteSoftwareAsset() {
        console.log("Call delete endpoint here!");
        SoftwareAssetDataService.deleteSoftwareAssetUpdatedVersion(this.props.loggedStatus.accessToken, this.props.match.params.id, this.props.loggedStatus.id, this.state.softwareAsset.tenant_id).then(response => {
            // navigate back to software asset list here
            this.props.history.push("/softwareAssets");
        });
    }

    navigateToEditScreen() {
        this.props.history.push(`/editSoftwareAsset/${this.props.match.params.id}`);
    }


    render() {
        if (!this.props.loggedStatus.loggedIn || (!this.props.userPermissions.can_see_assets /* && !this.props.userPermissions.can_edit_assets && !this.props.userPermissions.can_add_assets && !this.props.userPermissions.can_delete_assets */)) {
            return (
                <Redirect to='/' />
            );
        }

        return (
            <>
                <Navbar pageTitle={`View Specific Software Asset - ${this.state.softwareAsset.name_of_software}`} />
                <div style={{ paddingTop: "15px" }} />
                <Container fluid pt="20px">
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block">
                        <Grid>
                            <Grid.Col span={6}>
                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                    <Table.Tr>
                                        <Table.Td style={{width: "20%"}}>Managing Tenant</Table.Td>
                                        <Table.Td c="blue">{this.state.tenant_name}</Table.Td>
                                    </Table.Tr>
                                </Table>
                            </Grid.Col>
                            <Grid.Col span={6} align="right">
                                <Group justify="flex-end" gap="xl">
                                    {this.props.userPermissions.can_edit_assets &&
                                        <Button onClick={() => this.navigateToEditScreen()}><IconEdit/></Button>
                                    }
                                    {this.props.userPermissions.can_delete_assets &&
                                        <Button color="red" onClick={() => this.openDeleteModal()}><IconX/></Button>
                                    }
                                </Group>                          
                            </Grid.Col>
                        </Grid>
                    </Paper>
                    <div style={{marginTop: "25px"}}></div>
                    <Paper withBorder p="md" radius="md" w="100%" mb="sm" display="inline-block" /* style={{height: "425px"}} */>
                        <Tabs radius="md" defaultValue="Overview">
                            <Tabs.List>
                                <Tabs.Tab value="Overview" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Overview</Tabs.Tab>
                                <Tabs.Tab value="Users" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Users</Tabs.Tab>
                                <Tabs.Tab value="Contracts" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Contracts</Tabs.Tab>
                                <Tabs.Tab value="Relationships" onClick={(e) => this.setState({selected_form_tab: e.target.innerText})}>Relationships</Tabs.Tab>
                            </Tabs.List>
                            <Tabs.Panel value="Overview">
                                {this.state.selected_form_tab === "Overview" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Grid>
                                            <Grid.Col span={6}>
                                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "20%"}}>Name</Table.Td>
                                                        <Table.Td c="blue">{this.state.softwareAsset.name_of_software !== null ? this.state.softwareAsset.name_of_software : '-'}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "20%"}}>Publisher</Table.Td>
                                                        <Table.Td c="blue">{this.state.softwareAsset.publisher !== null ? this.state.softwareAsset.publisher : '-'}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "20%"}}>Status</Table.Td>
                                                        <Table.Td c="blue">{this.state.softwareAsset.status !== null ? this.state.softwareAsset.status : '-'}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "20%"}}>Type</Table.Td>
                                                        <Table.Td c="blue">{this.state.softwareAsset.type !== null ? this.state.softwareAsset.type : '-'}</Table.Td>
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td style={{width: "20%"}}>Managed By</Table.Td>
                                                        <Table.Td c="blue">
                                                            {this.state.softwareAsset.manager_first_name !== null ? 
                                                                this.state.softwareAsset.manager_middle_name !== null ?
                                                                `${this.state.softwareAsset.manager_first_name} ${this.state.softwareAsset.manager_middle_name} ${this.state.softwareAsset.manager_last_name}`
                                                                :
                                                                `${this.state.softwareAsset.manager_first_name} ${this.state.softwareAsset.manager_last_name}`
                                                                :
                                                                '-'
                                                            }
                                                        </Table.Td>
                                                    </Table.Tr>
                                                </Table>
                                            </Grid.Col>
                                            <Grid.Col span={6}>                                               
                                                <Table withRowBorders={false} style={{cursor: "default"}}>
                                                    <Table.Tr>
                                                        <Table.Td><div>Description</div><div style={{height: "85px"}} className="mantineBlue">{this.state.softwareAsset.description !== null ? this.state.softwareAsset.description : '-'}</div></Table.Td>
                                                        {/* <Table.Td><div style={{height: "213px"}}>Description goes here</div></Table.Td> */}
                                                    </Table.Tr>
                                                    <Table.Tr>
                                                        <Table.Td><div>Notes</div><div style={{height: "85px"}} className="mantineBlue">{this.state.softwareAsset.notes !== null ? this.state.softwareAsset.notes : '-'}</div></Table.Td>
                                                        {/* <Table.Td rowSpan={"212px"} c="blue">Notes go here</Table.Td> */}
                                                    </Table.Tr>
                                                </Table>
                                            </Grid.Col>
                                        </Grid>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="Users">
                                {this.state.selected_form_tab === "Users" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Text>Coming Soon</Text>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="Contracts">
                                {this.state.selected_form_tab === "Contracts" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Table highlightOnHover>
                                            <Table.Thead>
                                                <Table.Th>Company</Table.Th>
                                                <Table.Th>Contract Name</Table.Th>
                                                <Table.Th>Status</Table.Th>
                                                <Table.Th>Contract Value</Table.Th>
                                                <Table.Th>Users</Table.Th>
                                                <Table.Th>Installs</Table.Th>
                                            </Table.Thead>
                                            <Table.Tbody>
                                                {this.state.contracts && this.state.contracts.map((contract) => (
                                                    <Table.Tr style={{cursor: "pointer"}} onClick={() => this.props.history.push(`/contracts/${contract.contract_id}`)}>
                                                        <Table.Td>{contract.company_name !== null ? contract.company_name : '-'}</Table.Td>
                                                        <Table.Td>{contract.contract_name !== null ? contract.contract_name : '-'}</Table.Td>
                                                        <Table.Td>{contract.status !== null ? contract.status : '-'}</Table.Td>
                                                        <Table.Td>{contract.contract_value !== null ? contract.contract_value : '-'}</Table.Td>
                                                        <Table.Td>-</Table.Td>
                                                        <Table.Td>-</Table.Td>
                                                    </Table.Tr>
                                                ))

                                                }
                                            </Table.Tbody>
                                        </Table>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                            <Tabs.Panel value="Relationships">
                                {this.state.selected_form_tab === "Relationships" ?
                                    <div style={{marginTop: "30px"}}>
                                        <Text>Coming Soon</Text>
                                    </div>
                                    :
                                    undefined
                                }
                            </Tabs.Panel>
                        </Tabs>
                    </Paper>
                </Container>
                <Modal
                    opened={this.state.show_delete_modal}
                    onClose={() => this.closeDeleteModal()}
                    //title="Let's Start With the Following Data"
                    size="lg"
                    centered
                    closeOnClickOutside={false}
                    withCloseButton={false}
                >
                    <div style={{textAlign: "center", marginBottom: '15px'}}><Text size="xl">Are you sure you want to delete this software asset?</Text></div>
                    <div style={{textAlign: "center", marginBottom: '30px'}}><Text size="md"><span style={{color: 'red'}}>WARNING:</span> Deleting this asset will also delete any contracts it is associated with</Text></div>
                    <div style={{textAlign: 'center', marginTop: "50px"}}>
                        <span style={{marginRight: "150px"}}><Button variant="filled" color="gray" onClick={() => this.closeDeleteModal()}>Cancel</Button></span>
                        <span><Button color="red" onClick={() => this.deleteSoftwareAsset()}>Delete</Button></span>
                    </div>
                </Modal>
            </>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { ACCESSTOKEN, LOGOUT })(ViewSpecificSoftwareAsset);