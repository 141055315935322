import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useState, useEffect } from 'react';

function RichTextInput(props) {
    const [startingValueSet, setStartingValueSet] = useState(false);
    const editor = useEditor({
        extensions: [
            StarterKit,
            Link,
        ],
        onUpdate({ editor }) {
            if (props.onChange) {
                props.onChange(editor.getHTML());
            }
        },
        editable: props.readOnly ? false : true,
    });

    useEffect(() => {
        if (!startingValueSet && editor && props.defaultValue && props.defaultValue.length > 0) {
            editor.commands.setContent(props.defaultValue);
            setStartingValueSet(true);
        }
        if (editor && props.readOnly) {
            editor.setEditable(!props.readOnly, false);
        }
    });

    return (
        <RichTextEditor editor={editor}>
            <RichTextEditor.Toolbar sticky stickyOffset={60}>
                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Bold />
                    <RichTextEditor.Italic />
                    <RichTextEditor.Strikethrough />
                    <RichTextEditor.ClearFormatting />
                    <RichTextEditor.Code />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.H1 />
                    <RichTextEditor.H2 />
                    <RichTextEditor.H3 />
                    <RichTextEditor.H4 />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Blockquote />
                    <RichTextEditor.Hr />
                    <RichTextEditor.BulletList />
                    <RichTextEditor.OrderedList />
                </RichTextEditor.ControlsGroup>

                <RichTextEditor.ControlsGroup>
                    <RichTextEditor.Link />
                    <RichTextEditor.Unlink />
                </RichTextEditor.ControlsGroup>
            </RichTextEditor.Toolbar>

            <RichTextEditor.Content />
        </RichTextEditor>
    );
}

export default RichTextInput;